import React, { useState, useEffect, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faSearch } from '@fortawesome/free-solid-svg-icons';
import Config from "../../../config"; 
import { useAuth } from '../../Common/InitialGears/AuthContext'; 
import * as style from "../../../GlobalStyles";
import { getApi} from "../../Common/BackendCalls/apiUtils";

const CompletedGroupWishModal = ({ isOpen, onClose }) => {
  const { queryLoginParam, idTokenLoggedIn } = useAuth();
  const [completedWishes, setCompletedWishes] = useState([]);
  const [filteredWishes, setFilteredWishes] = useState([]);
  const [refreshing, setRefreshing] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [filterCategory, setFilterCategory] = useState('');
  const [filterProductType, setFilterProductType] = useState('');
  const [error, setError] = useState(null);

  const fetchCompletedWishes = useCallback(async () => {
    try {
      setRefreshing(true);
      setError(null);
      const completedWishesUrl = `${Config.apiUrl}myGroupWishes?${queryLoginParam}&type=completed`;
      
      const data = await getApi(completedWishesUrl);
       
      
      if (Array.isArray(data)) {
        setCompletedWishes(data);
        setFilteredWishes(data);
      } else if (data && Array.isArray(data.data)) {
        // Handle case where data might be wrapped in a 'data' property
        setCompletedWishes(data.data);
        setFilteredWishes(data.data);
      } else { 
        throw new Error("Received data is not in the expected format");
      }
    } catch (error) { 
      setError(error.message || 'An error occurred while fetching completed wishes');
    } finally {
      setRefreshing(false);
    }
  }, [queryLoginParam, getApi]);

  useEffect(() => {
    if (isOpen) {
      fetchCompletedWishes();
    }
  }, [isOpen, fetchCompletedWishes]);

  const filterWishes = useCallback((query, category, productType) => {
    if (!Array.isArray(completedWishes)) {
      setFilteredWishes([]);
      return;
    }

    const normalize = (str) => str.toLowerCase().replace(/[^a-z0-9]/g, '');
    const partialMatch = (full, partial) => {
      if (!full || !partial) return false;
      const normalizedFull = normalize(full);
      const normalizedPartial = normalize(partial);
      return normalizedFull.includes(normalizedPartial);
    };

    const filtered = completedWishes.filter(item => {
      const wish = item.wish;
      const matchesQuery = !query || 
        partialMatch(wish.deal_title, query) ||
        (wish.category && wish.category.some(cat => partialMatch(cat, query))) ||
        (wish.product_type && wish.product_type.some(type => partialMatch(type, productType)));

      const matchesCategory = !category || 
        (wish.category && wish.category.some(cat => partialMatch(cat, category)));

      const matchesProductType = !productType || 
        (wish.product_type && wish.product_type.some(type => partialMatch(type, productType)));

      return matchesQuery && matchesCategory && matchesProductType;
    });

    setFilteredWishes(filtered);
  }, [completedWishes]);

  useEffect(() => {
    filterWishes(searchQuery, filterCategory, filterProductType);
  }, [completedWishes, searchQuery, filterCategory, filterProductType, filterWishes]);

  const handleSearch = (event) => {
    const query = event.target.value.trim();
    setSearchQuery(query);
    filterWishes(query, filterCategory, filterProductType);
  };

  const handleCategoryFilter = (event) => {
    const category = event.target.value.trim();
    setFilterCategory(category);
    filterWishes(searchQuery, category, filterProductType);
  };

  const handleProductTypeFilter = (event) => {
    const productType = event.target.value.trim();
    setFilterProductType(productType);
    filterWishes(searchQuery, filterCategory, productType);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', { 
      year: 'numeric', 
      month: 'short', 
      day: '2-digit' 
    });
  };

  const WishItem = ({ item }) => {
    const [expanded, setExpanded] = useState(false);
    const savings = item.wish.quantity_purchased * (item.wish.list_price - item.wish.total_price_per_item);
    const savingsPercentage = ((item.wish.list_price - item.wish.total_price_per_item) / item.wish.list_price) * 100;

    return (
      <div style={styles.wishItem}>
        <div style={styles.wishHeader} onClick={() => setExpanded(!expanded)}>
          <div style={styles.wishImage}>
            {item.wish.image ? (
              <img src={item.wish.image} alt={item.wish.deal_title} style={styles.image} />
            ) : (
              <div style={styles.placeholderImage}>No Image</div>
            )}
          </div>
          <div style={styles.wishSummary}>
            <h3 style={styles.wishTitle}>{item.wish.deal_title}</h3>
            <h4 style={styles.wishSavings}>
              Total Savings: ${savings.toFixed(2)} ({savingsPercentage.toFixed(2)}%)
            </h4>
            <p style={styles.wishInfo}>Quantity Purchased: {item.wish.quantity_purchased}</p>
            <p style={styles.wishInfo}>Price Purchased: ${item.wish.total_price_per_item}</p>
          </div>
          <div style={styles.expandIcon}>{expanded ? '▲' : '▼'}</div>
        </div>
        {expanded && (
          <div style={styles.wishDetails}>
            <p style={styles.wishInfo}>By then Market Price: ${item.wish.list_price}</p>
            <p style={styles.wishInfo}>Category: {item.wish.category.join(', ')}</p>
            <p style={styles.wishInfo}>Number of Orders: {item.wish.closing_order_size} by {item.totalJoined} BacksApp Users</p>
            <p style={styles.wishInfo}>Group Closed Date: {formatDate(item.wish.expires_at)}</p> 
            <p style={styles.wishInfo}>Average Rating: {item.averageRating.toFixed(2)}</p>
          </div>
        )}
      </div>
    );
  };

  if (!isOpen) return null;

  return (
    <div style={styles.modalOverlay}>
      <div style={styles.modalContent}>
        <div style={styles.modalHeader}>
          <h2 style={styles.modalTitle}>Completed Group Wishes</h2>
          <button onClick={onClose} style={styles.closeButton}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>
        <div style={styles.filterContainer}>
          <div style={styles.searchContainer}>
            <FontAwesomeIcon icon={faSearch} style={styles.searchIcon} />
            <input type="text" placeholder="Search deals..." value={searchQuery} onChange={handleSearch} style={styles.input}/>
          </div>
          <input type="text" placeholder="Filter by category" value={filterCategory} onChange={handleCategoryFilter} style={styles.input} />
          <input type="text" placeholder="Filter by product type" value={filterProductType} onChange={handleProductTypeFilter} style={styles.input}/>
        </div>
        <div style={styles.wishList}>
          {refreshing && <p style={styles.loadingText}>Loading...</p>}
          {error && <p style={styles.errorText}>Error: {error}</p>}
          {filteredWishes.length > 0 ? (
            filteredWishes.map((item) => (
              <WishItem key={item.wish.uuid} item={item} />
            ))
          ) : (
            <p style={styles.emptyText}>No completed wishes</p>
          )}
        </div>
      </div>
    </div>
  );
};

const styles = {
  modalOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  },
  modalContent: {
    backgroundColor: style.Color.colorLightestBeige,
    borderRadius: '8px',
    padding: '20px',
    width: '90%',
    maxWidth: '800px',
    maxHeight: '90vh',
    overflowY: 'auto',
  },
  modalHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '20px',
  },
  modalTitle: {
    fontSize: '24px',
    fontWeight: 'bold',
    color: style.Color.colorRoyalBlueText,
    margin: 0,
  },
  closeButton: {
    background: 'none',
    border: 'none',
    fontSize: '24px',
    borderRadius: '8px',
    cursor: 'pointer',
    color: style.Color.colorDarkGrey,
  },
  filterContainer: {
    marginBottom: '20px',borderRadius: '8px',
  },
  searchContainer: {
    position: 'relative',
    marginBottom: '10px',borderRadius: '8px',
  },
  searchIcon: {
    position: 'absolute',
    left: '10px',
    top: '50%',
    transform: 'translateY(-50%)',
    color: style.Color.colorDarkGrey,
  },
  input: {
    width: '100%',
    padding: '10px',
    paddingLeft: '35px',
    marginBottom: '10px',
    border: '1px solid #ccc',
    borderRadius: '8px',
    boxSizing: 'border-box',
  },
  wishList: {
    maxHeight: 'calc(90vh - 200px)',
    overflowY: 'auto',
  },
  wishItem: {
    backgroundColor: style.Color.colorWhite,
    padding: '15px',
    borderRadius: '8px',
    marginBottom: '15px',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
  },
  wishHeader: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  wishImage: {
    width: '100px',
    height: '100px',
    marginRight: '15px',
    borderRadius: '8px',
    overflow: 'hidden',
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  placeholderImage: {
    width: '100%',
    height: '100%',
    backgroundColor: '#f0f0f0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#999',
    fontSize: '12px',
  },
  wishSummary: {
    flex: 1,
  },
  wishTitle: {
    fontSize: '18px',
    fontWeight: 'bold',
    marginBottom: '5px',borderRadius: '8px',
    color: style.Color.colorRoyalBlueText,
  },
  wishSavings: {
    fontSize: '16px',
    color: style.Color.colorGreen,
    marginBottom: '5px',
  },
  wishInfo: {
    fontSize: '14px',
    color: style.Color.colorDarkGrey,
    marginBottom: '3px',
  },
  expandIcon: {
    fontSize: '20px',
    color: style.Color.colorRoyalBlueText,
  },
  wishDetails: {
    marginTop: '15px',
    paddingTop: '15px',
    borderTop: `1px solid ${style.Color.colorLightGrey}`,
  },
  loadingText: {
    textAlign: 'center',
    color: style.Color.colorDarkGrey,
  },
  errorText: {
    textAlign: 'center',
    color: style.Color.colorVibrantRed,
  },
  emptyText: {
    textAlign: 'center',
    color: style.Color.colorDarkGrey,
    marginTop: '20px',
  },
};

export default CompletedGroupWishModal;