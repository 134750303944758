import React, { useState, useEffect } from 'react';
import { Package2, Save, Rotate3d, Eye } from 'lucide-react';
import { useAuth } from '../../Common/InitialGears/AuthContext';
import { postApi, buildUrl } from "../../Common/BackendCalls/apiUtils";
import * as style from '../../../GlobalStyles';   
import ShippingOptions from './Components/ShippingOptions';
import BoxPreview3D from './Components/BoxPreview3D'; 
import WeightOptimizationAlert from './Components/WeightOptimizationAlert';
import { DEFAULT_BOXES } from './Utils/Constants';
import { formatMeasurement, convertDimension, convertWeight } from './Utils/Conversions';
import BulkImportModal from './BulkImportModal';
import { 
  convertToItems, 
  convertManualToItems, 
  getZoneFromZip,
  updateWeightAlert
} from './Utils/CalculationHelpers';

const PackagingOptimizer = ({ selectedWishes, comboId, onCalculationComplete, isFromGroupWishCombo = false }) => { 
  const { queryLoginParam } = useAuth();
  const [manualItems, setManualItems] = useState([{ name: '', length: '', width: '', height: '', weight: '' }]);
  const [showManualInput, setShowManualInput] = useState(false);
  const [packagingSolution, setPackagingSolution] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [isCalculating, setIsCalculating] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [selectedZone, setSelectedZone] = useState('zone1');
  const [destinationZip, setDestinationZip] = useState('');
  const [saveError, setSaveError] = useState(null);
  const [calculationError, setCalculationError] = useState(null);
  const [show3DPreview, setShow3DPreview] = useState({});
  const [currentItems, setCurrentItems] = useState([]);
  const [quantity, setQuantity] = useState(1);
  const [useCase, setUseCase] = useState('shipping');
  const [showBulkImport, setShowBulkImport] = useState(false);
  const [weightAlertData, setWeightAlertData] = useState({
    showAlert: true,
    actualWeight: 0,
    billableWeight: 0,
    remainingWeight: 0
  });
  const [isLoadingRates, setIsLoadingRates] = useState(false);
  const [rateErrors, setRateErrors] = useState(null);
  const [allZoneRates, setAllZoneRates] = useState(null);
   
  const [units, setUnits] = useState({ 
    dimension: 'inch',   
    weight: 'lb'        
  }); 
  const [availableBoxes] = useState(DEFAULT_BOXES);
 
  // Reset calculation state when selectedWishes changes
  useEffect(() => {
    // Clear any previous calculation results to prepare for new calculation
    setPackagingSolution(null);
    setCalculationError(null);
    setSaveSuccess(false);
    setSaveError(null);
    setShow3DPreview({});
    setAllZoneRates(null);
    setRateErrors(null);
    setWeightAlertData({
      showAlert: false,
      actualWeight: 0,
      billableWeight: 0,
      remainingWeight: 0, 
      boxCount:0, 
      volumeUtilization:0,
      boxDimensions:{
            length: 0,
            width: 0,
            height: 0
          }
    });
    
    // Wait for user to click Calculate button - no automatic calculation
  }, [selectedWishes]);

  useEffect(() => { 
    if (destinationZip && destinationZip.length >= 5) {
      const zoneInfo = getZoneFromZip(destinationZip);
      if (zoneInfo) {
        setSelectedZone(zoneInfo.zone);
        if (packagingSolution && packagingSolution.length > 0) {
          fetchAllZoneRates(packagingSolution[0]);
        }
      }
    }
  }, [destinationZip]);
 
  useEffect(() => {
    if (packagingSolution) {
      const alertData = updateWeightAlert(packagingSolution);
      setWeightAlertData(alertData);
    } else {
      setWeightAlertData({
        showAlert: false,
        actualWeight: 0,
        billableWeight: 0,
        remainingWeight: 0,
        boxCount: 0,  
        volumeUtilization:0,
        boxDimensions:{
              length: 0,
              width: 0,
              height: 0
            }
      });
    }
  }, [packagingSolution]);
 
  const fetchAllZoneRates = async (box) => {
    try {
      setIsLoadingRates(true);
      setRateErrors(null); 
      const packageDetails = {
        weight: box.weightUnit === 'lb' ? box.totalWeight.toFixed(2) : convertWeight(box.totalWeight, 'kg', 'lb'),
        length: box.dimensionUnit === 'inch' ? box.length.toFixed(2) : convertDimension(box.length, 'cm', 'inch'),
        width: box.dimensionUnit === 'inch' ? box.width.toFixed(2) : convertDimension(box.width, 'cm', 'inch'),
        height: box.dimensionUnit === 'inch' ? box.height.toFixed(2) : convertDimension(box.height, 'cm', 'inch'),
        unit_length: "inch",
        unit_weight: "lb"
      };       
      const requestData = {
        package_details: packageDetails
      }; 
      const zoneRatesURL = buildUrl('getShippingOptions', queryLoginParam); 
      const response = await postApi(zoneRatesURL, requestData);       
      if (response ) { 
        setAllZoneRates(response.data.zone_rates);                 
      } else { 
        setRateErrors(response.error || "Failed to get shipping rates");
      }
    } catch (error) { 
      setRateErrors("An error occurred while fetching shipping rates");
    } finally {
      setIsLoadingRates(false);
    }
  };
 

const parseDimensionString = (dimString) => {
  if (!dimString) return { length: '', width: '', height: '' }; 
  const dimensions = dimString.split(/[xX×]/); 
  if (dimensions.length === 3) {
    return {
      length: dimensions[0].trim(),
      width: dimensions[1].trim(),
      height: dimensions[2].trim()
    };
  } 
  return { 
    length: dimString,
    width: '',
    height: ''
  };
};

   /**
 * Enhanced handleCalculate function for PackagingOptimizer
 * This approach:
 * 1. Sorts each product's dimensions from largest to smallest
 * 2. Finds the maximum dimensions needed across all products
 * 3. Chooses the smallest box that can fit these dimensions
 * 4. Ensures the total weight is within the box's capacity
 */
const handleCalculate = () => {
  try {
    setCalculationError(null);
    setIsSaving(false);
    setSaveSuccess(false);
    setSaveError(null);
    setIsCalculating(true);
    setAllZoneRates(null);
    setRateErrors(null);
    let itemsToPlace;
    console.log("manualItems=", manualItems) 
    if (showManualInput) {
      itemsToPlace = convertManualToItems(manualItems, units);
      setCurrentItems(itemsToPlace);
    } else {
      const validWishes = selectedWishes.filter(wish => wish !== null);      
      itemsToPlace = convertToItems(validWishes);
      setCurrentItems(itemsToPlace);
    }
    console.log("itemsToPlace=", itemsToPlace) 
    if (itemsToPlace.length === 0) {
      setCalculationError("No valid items to calculate");
      setIsCalculating(false);
      return;
    }  
    
    const invalidItems = itemsToPlace.filter(item => 
      !item.length || !item.width || !item.height || !item.weight
    );
    console.log("invalidItems=", invalidItems) 
    if (invalidItems.length > 0) { 
      setCalculationError(`${invalidItems.length} items are missing dimension information. Please check product specifications.`);
      setIsCalculating(false);
      return;
    }
    
    // Convert all dimensions to inches and weights to pounds for consistent calculation
    const standardizedItems = itemsToPlace.map(item => {
      const dimensionFactor = units.dimension === 'cm' ? 0.393701 : 1; // cm to inch
      const weightFactor = units.weight === 'kg' ? 2.20462 : 1; // kg to lb
      console.log("dimensionFactor=", dimensionFactor) 
      return {
        ...item,
        length: parseFloat(item.length) * dimensionFactor,
        width: parseFloat(item.width) * dimensionFactor,
        height: parseFloat(item.height) * dimensionFactor,
        weight: parseFloat(item.weight) * weightFactor,
        originalDimensions: {
          length: parseFloat(item.length),
          width: parseFloat(item.width),
          height: parseFloat(item.height),
          weight: parseFloat(item.weight),
        },
        dimensionUnit: 'inch',
        weightUnit: 'lb'
      };
    });
    console.log("standardizedItems=", standardizedItems) 
    // For each item, sort dimensions from largest to smallest
    const itemsWithSortedDimensions = standardizedItems.map(item => {
      const dimensions = [item.length, item.width, item.height].sort((a, b) => b - a);
      return {
        ...item,
        sortedDimensions: dimensions
      };
    });
    console.log("itemsWithSortedDimensions=", itemsWithSortedDimensions) 
    // Find the maximum dimension needed in each direction
    let maxD1 = 0, maxD2 = 0, maxD3 = 0;
    
    itemsWithSortedDimensions.forEach(item => {
      maxD1 = Math.max(maxD1, item.sortedDimensions[0]);
      maxD2 = Math.max(maxD2, item.sortedDimensions[1]);
      maxD3 = Math.max(maxD3, item.sortedDimensions[2]);
    });
    console.log("itemsWithSortedDimensions=", itemsWithSortedDimensions)
    // Calculate total weight
    const totalWeight = standardizedItems.reduce((sum, item) => sum + item.weight, 0);
    
    // Convert boxes to standard units (inches and pounds)
    const standardizedBoxes = availableBoxes.map(box => {
      const dimensionFactor = box.dimensionUnit === 'cm' ? 0.393701 : 1;
      const weightFactor = box.weightUnit === 'kg' ? 2.20462 : 1;
      
      return {
        ...box,
        length: box.length * dimensionFactor,
        width: box.width * dimensionFactor,
        height: box.height * dimensionFactor,
        maxWeight: box.maxWeight * weightFactor,
        dimensionUnit: 'inch',
        weightUnit: 'lb'
      };
    });
    
    // Find boxes that can fit the maximum dimensions
    const compatibleBoxes = standardizedBoxes.filter(box => {
      // Sort box dimensions from largest to smallest
      const boxDimensions = [box.length, box.width, box.height].sort((a, b) => b - a);
      
      // Check if box can fit the maximum dimensions needed
      const canFitDimensions = boxDimensions[0] >= maxD1 && 
                              boxDimensions[1] >= maxD2 && 
                              boxDimensions[2] >= maxD3;
      
      // Check weight constraint
      const canHoldWeight = box.maxWeight >= totalWeight;
      
      return canFitDimensions && canHoldWeight;
    });
    
    // If no compatible box is found
    if (compatibleBoxes.length === 0) {
      setCalculationError("No standard box can fit these items. Consider splitting into multiple shipments.");
      setIsCalculating(false);
      return;
    }
    
    // Choose the smallest compatible box to maximize utilization
    const optimalBox = compatibleBoxes.reduce((smallest, current) => {
      const smallestVolume = smallest.length * smallest.width * smallest.height;
      const currentVolume = current.length * current.width * current.height;
      return currentVolume < smallestVolume ? current : smallest;
    }, compatibleBoxes[0]);
    
    // Calculate volume utilization
    const boxVolume = optimalBox.length * optimalBox.width * optimalBox.height;
    const itemsVolume = standardizedItems.reduce((sum, item) => {
      return sum + (item.length * item.width * item.height);
    }, 0);
    
    const volumeUtilization = (itemsVolume / boxVolume * 100).toFixed(1);
    
    // Position items in the box (simplified for visualization)
    // In a real 3D bin packing algorithm, this would be more sophisticated
    const positionedItems = standardizedItems.map((item, index) => {
      // Create a simple grid layout
      const gridSize = Math.ceil(Math.sqrt(standardizedItems.length));
      const row = Math.floor(index / gridSize);
      const col = index % gridSize;
      
      // Space items evenly in the box
      const xStep = optimalBox.length / gridSize;
      const yStep = optimalBox.width / gridSize;
      
      return {
        ...item,
        position: {
          x: col * xStep,
          y: row * yStep,
          z: 0
        },
        rotation: 0 // No rotation in this simplified version
      };
    });
    
    // Calculate dimensional weight
    const dimensionalFactor = 139; // Industry standard divisor
    const dimensionalWeight = boxVolume / dimensionalFactor;
    const billableWeight = Math.max(totalWeight, dimensionalWeight);
    const remainingWeight = billableWeight > totalWeight ? billableWeight - totalWeight : 0;
    
    // Convert back to original units for display if needed
    const finalSolution = [];
    const solution = {
      name: optimalBox.name,
      length: optimalBox.length,
      width: optimalBox.width,
      height: optimalBox.height,
      maxWeight: optimalBox.maxWeight,
      totalWeight: totalWeight,
      volumeUtilization: volumeUtilization,
      billableWeight: billableWeight,
      remainingWeight: remainingWeight,
      dimensionUnit: 'inch',
      weightUnit: 'lb',
      items: positionedItems
    };
    
    // Convert solution back to original units if needed
    if (units.dimension !== 'inch' || units.weight !== 'lb') {
      const convertedSolution = {
        ...solution,
        dimensionUnit: units.dimension,
        weightUnit: units.weight
      };
      
      if (units.dimension !== 'inch') {
        const factor = units.dimension === 'cm' ? 2.54 : 1; // inch to cm
        convertedSolution.length = solution.length * factor;
        convertedSolution.width = solution.width * factor;
        convertedSolution.height = solution.height * factor;
        
        convertedSolution.items = solution.items.map(item => {
          return {
            ...item,
            length: item.originalDimensions.length,
            width: item.originalDimensions.width,
            height: item.originalDimensions.height,
            dimensionUnit: units.dimension,
            position: {
              x: item.position.x * factor,
              y: item.position.y * factor,
              z: item.position.z * factor
            }
          };
        });
      }
      
      if (units.weight !== 'lb') {
        const factor = units.weight === 'kg' ? 0.453592 : 1; // lb to kg
        convertedSolution.maxWeight = solution.maxWeight * factor;
        convertedSolution.totalWeight = solution.totalWeight * factor;
        convertedSolution.billableWeight = solution.billableWeight * factor;
        convertedSolution.remainingWeight = solution.remainingWeight * factor;
        
        convertedSolution.items = convertedSolution.items.map(item => {
          return {
            ...item,
            weight: item.originalDimensions.weight,
            weightUnit: units.weight
          };
        });
      }
      
      finalSolution.push(convertedSolution);
    } else {
      finalSolution.push(solution);
    }
    
    setPackagingSolution(finalSolution);
    setSaveSuccess(false);
    setSaveError(null);
    setShow3DPreview({});
    
    // Update weight alert data
    const alertData = {
      showAlert: remainingWeight > 0,
      actualWeight: totalWeight,
      billableWeight: billableWeight,
      remainingWeight: remainingWeight,
      boxCount: 1,
      volumeUtilization: volumeUtilization,
      length: optimalBox.length,
      width: optimalBox.width,
      height: optimalBox.height
    };
    
    setWeightAlertData(alertData);
    
    // Fetch shipping rates if solution found
    if (finalSolution.length > 0) {
      fetchAllZoneRates(finalSolution[0]);
    }
    
    // Notify parent component if needed
    if (onCalculationComplete) {
      onCalculationComplete(finalSolution, selectedZone);
    }
    
  } catch (error) {
    console.error("Error in handleCalculate:", error);
    setCalculationError("Error calculating packaging solution: " + error.message);
    setWeightAlertData({
      showAlert: false,
      actualWeight: 0,
      billableWeight: 0,
      remainingWeight: 0,
      boxCount: 0,
      volumeUtilization: 0,
      boxDimensions: {
        length: 0,
        width: 0,
        height: 0
      }
    });
  } finally {
    setIsCalculating(false);
  }
};
  const handleSaveSolution = async () => {
    if (!packagingSolution || !comboId) { 
      setSaveError("No packaging solution to save or combo ID is missing");
      return;
    }

    setIsSaving(true);
    setSaveSuccess(false);
    setSaveError(null);

    try { 
      const solutionWithShippingCosts = packagingSolution.map(box => { 
        const boxVolume = box.length * box.width * box.height;
        const shippingCost = (box.totalWeight * 0.5) + (boxVolume * 0.001) + 5.99;
        
        return {
          ...box,
          shipping_cost: shippingCost.toFixed(2)
        };
      });
 
      const requestData = {
        combo_id: comboId,
        name: "3D Optimized Packaging Solution",
        dimension_unit: units.dimension,
        weight_unit: units.weight,
        solution: solutionWithShippingCosts.map(box => ({
          name: box.name,
          length: box.length,
          width: box.width,
          height: box.height,
          max_weight: box.maxWeight,
          total_weight: box.totalWeight,
          shipping_cost: box.shipping_cost,
          volume_utilization: box.volumeUtilization,
          items: box.items.map(item => ({
            group_wish_item_id: item.group_wish_id,
            name: item.name,
            length: item.length,
            width: item.width,
            height: item.height,
            weight: item.weight,
            is_fragile: item.isFragile,
            has_batteries: item.hasBatteries,
            is_hazardous: item.isHazardous,
            storage_requirements: item.storageRequirements,
            position: item.position,
            rotation: item.rotation
          }))
        }))
      };
 
      const saveSolutionURL = buildUrl('addPackagingSolutions', queryLoginParam);
      const response = await postApi(saveSolutionURL, requestData);
      
      if (response.ok) { 
        setSaveSuccess(true); 
      } else { 
        setSaveError(response || "Failed to save packaging solution");
        console.error("Error saving packaging solution:", response);
      }
    } catch (error) {
      setSaveError("An error occurred while saving the packaging solution");
      console.error("Exception saving packaging solution:", error);
    } finally {
      setIsSaving(false);
    }
  };
 
  const handleAddManualItem = () => {
    setManualItems([...manualItems, { name: '', length: '', width: '', height: '', weight: '' }]);
  };
 
  const handleManualItemChange = (index, field, value) => {
    const updatedItems = [...manualItems]; 
    if (field === 'length' && (value.includes('x') || value.includes('X') || value.includes('×'))) {
      const { length, width, height } = parseDimensionString(value);
      updatedItems[index].length = length;
      updatedItems[index].width = width;
      updatedItems[index].height = height;
    } else {
      updatedItems[index][field] = value;
    } 
    setManualItems(updatedItems);
  };

  const handleBulkImport = (bulkText) => {
    const lines = bulkText.split('\n').filter(line => line.trim());
    const newItems = [];    
    lines.forEach(line => { 
      const parts = line.trim().split(/\s+/);      
      if (parts.length >= 2) { 
        const dimensionIndex = parts.findIndex(part => 
          part.includes('X') || part.includes('x') || part.includes('×')
        );        
        if (dimensionIndex > 0) {
          const name = parts.slice(0, dimensionIndex).join(' ');
          const dimensionStr = parts[dimensionIndex];
          const { length, width, height } = parseDimensionString(dimensionStr); 
          const weightIndex = parts.findIndex(part => 
            !isNaN(parseFloat(part)) && 
            (parts[parts.indexOf(part) + 1] === 'kg' || parts[parts.indexOf(part) + 1] === 'lb')
          );          
          const weight = weightIndex >= 0 ? parts[weightIndex] : '';          
          newItems.push({ name, length, width, height,  weight });
        }
      }
    });
    if (newItems.length > 0) {
      setManualItems(newItems);
      setShowManualInput(true);
    }
  };

  const toggle3DPreview = (boxIndex) => {
    setShow3DPreview(prev => ({
      ...prev,
      [boxIndex]: !prev[boxIndex]
    }));
  };
  
  return ( 
    <div style={containerStyle}>
      <div style={headerStyle}>
        <Package2 style={iconStyle} />
        <h2 style={titleStyle}>3D Packaging Optimizer</h2>
      </div>
   
      {/* Enhanced Weight Optimization Alert */}
      {weightAlertData.showAlert && (
        <div style={alertContainerStyle}>
         <WeightOptimizationAlert
          actualWeight={weightAlertData.actualWeight}
          billableWeight={weightAlertData.billableWeight}
          remainingWeight={weightAlertData.remainingWeight}
          boxCount={weightAlertData.boxCount}
          volumeUtilization={weightAlertData.volumeUtilization}
          boxDimensions={{
            length: weightAlertData.length,
            width: weightAlertData.width,
            height: weightAlertData.height
          }}
        />
        </div>
      )}
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '15px' }}>
        <h3 style={{ fontSize: '16px', fontWeight: 'bold' }}>Manual Item Entry</h3>
        <button 
          style={{
            padding: '8px 12px',
            backgroundColor: style.Color.colorRoyalBlueText,
            color: style.Color.colorWhite,
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer',
            fontSize: '13px'
          }}
          onClick={() => setShowBulkImport(true)}
        >
          Bulk Import
        </button>
      </div>
      
      <div style={contentStyle}> 
        <div style={unitSelectionStyle}>
          <div>
            <label>Dimension Unit: </label>
            <select 
              value={units.dimension}
              onChange={(e) => setUnits(prev => ({ ...prev, dimension: e.target.value }))}
              style={selectStyle}>
              <option value="cm">Centimeters (cm)</option>
              <option value="inch">Inches (in)</option>
            </select>
          </div>
          <div>
            <label>Weight Unit: </label>
            <select 
              value={units.weight}
              onChange={(e) => setUnits(prev => ({ ...prev, weight: e.target.value }))}
              style={selectStyle}
            >
              <option value="kg">Kilograms (kg)</option>
              <option value="lb">Pounds (lb)</option>
            </select>
          </div>
        </div>
         
        {showManualInput && (
          <div style={manualInputContainerStyle}>
            {manualItems.map((item, index) => ( 
              <div style={inputRowStyle}>
                <input 
                  style={inputStyle} 
                  placeholder="Item name" 
                  value={item.name} 
                  onChange={(e) => handleManualItemChange(index, 'name', e.target.value)}
                />
                <input 
                  style={inputStyle} 
                  type="number" 
                  placeholder={`Length (${units.dimension})`}
                  value={item.length} 
                  onChange={(e) => handleManualItemChange(index, 'length', e.target.value)} 
                />
                <input 
                  style={inputStyle} 
                  type="number" 
                  placeholder={`Width (${units.dimension})`}
                  value={item.width} 
                  onChange={(e) => handleManualItemChange(index, 'width', e.target.value)} 
                />
                <input 
                  style={inputStyle} 
                  type="number" 
                  placeholder={`Height (${units.dimension})`}
                  value={item.height} 
                  onChange={(e) => handleManualItemChange(index, 'height', e.target.value)} 
                />
                <input 
                  style={inputStyle} 
                  type="number" 
                  placeholder={`Weight (${units.weight})`}
                  value={item.weight} 
                  onChange={(e) => handleManualItemChange(index, 'weight', e.target.value)} 
                />
              </div>

            ))}
            <button style={addItemButtonStyle} onClick={handleAddManualItem}>
              Add Item
            </button>
          </div>
        )}

        {/* Always show Calculate button since calculation is manual */}
        <button 
          style={calculateButtonStyle} 
          onClick={handleCalculate}
          disabled={isCalculating}
        >
          {isCalculating ? 'Calculating...' : 'Calculate 3D Optimal Packaging'}
        </button>

        {calculationError && (
          <div style={errorMessageStyle}>
            {calculationError}
          </div>
        )}

        {packagingSolution && (
          <div style={solutionContainerStyle}> 
            <div style={saveStatusContainerStyle}>
              {saveSuccess && (
                <div style={successMessageStyle}>
                  3D packaging solution saved successfully!
                </div>
              )}
              {saveError && (
                <div style={errorMessageStyle}>
                  {saveError}
                </div>
              )}
              {isLoadingRates && (
                <div style={{
                  padding: '10px',
                  borderRadius: '4px',
                  backgroundColor: '#e2f0fd',
                  color: '#0c5460',
                  textAlign: 'center'
                }}>
                  Loading shipping rates...
                </div>
              )}
            </div>
             
            {packagingSolution.map((box, boxIndex) => (
              <div key={boxIndex} style={boxContainerStyle}>
                <div style={boxHeaderStyle}>
                  <div>
                    <h4 style={boxTitleStyle}>{box.name}</h4>
                    <p style={boxDetailsStyle}>
                      Dimensions: {formatMeasurement(box.length, units.dimension)} × 
                      {formatMeasurement(box.width, units.dimension)} × 
                      {formatMeasurement(box.height, units.dimension)}
                    </p>
                    <p style={boxDetailsStyle}>
                      Total Weight: {formatMeasurement(box.totalWeight, units.weight)}
                    </p>
                    <p style={boxDetailsStyle}>
                      Volume Utilization: {box.volumeUtilization}%
                    </p>
                  </div>
                  <button style={previewButtonStyle} onClick={() => toggle3DPreview(boxIndex)} >
                    {show3DPreview[boxIndex] ? (
                      <>
                        <Eye size={16} style={{ marginRight: '8px' }} />
                        Hide 3D Preview
                      </>
                    ) : (
                      <>
                        <Rotate3d size={16} style={{ marginRight: '8px' }} />
                        Show 3D Preview
                      </>
                    )}
                  </button>
                </div>                  
                {show3DPreview[boxIndex] && (
                  <BoxPreview3D box={box} boxIndex={boxIndex} />
                )}                  
                <div style={itemsContainerStyle}>
                  <p style={itemsTitleStyle}>Items:</p>
                  <ul style={itemListStyle}>
                  {box.items.map((item, itemIndex) => (
                    <li key={itemIndex} style={itemStyle}>
                      <strong>{itemIndex + 1}.</strong> {item.name} (
                      {formatMeasurement(item.length, units.dimension)} × 
                      {formatMeasurement(item.width, units.dimension)} × 
                      {formatMeasurement(item.height, units.dimension)}, 
                      {formatMeasurement(item.weight, units.weight)})
                      
                      {item.position && (
                        <div style={positionDetailsStyle}>
                          Position: x: {formatMeasurement(item.position.x, units.dimension)}, 
                          y: {formatMeasurement(item.position.y, units.dimension)}, 
                          z: {formatMeasurement(item.position.z, units.dimension)}
                          {item.rotation !== undefined && ` (Rotation: ${item.rotation})`}
                        </div>
                      )}
                      
                      {(item.isFragile || item.hasBatteries || item.isHazardous || item.storageRequirements) && (
                        <div style={specialRequirementsStyle}>
                          Special Handling:
                          {item.isFragile && <span style={warningStyle}>Fragile</span>}
                          {item.hasBatteries && <span style={warningStyle}>Contains Batteries</span>}
                          {item.isHazardous && <span style={warningStyle}>Hazardous Material</span>}
                          {item.storageRequirements && <span style={warningStyle}>{item.storageRequirements}</span>}
                        </div>
                      )}
                    </li>
                  ))}
                  </ul>
                  <div style={shippingCalculatorContainerStyle}>
                    <ShippingOptions 
                      box={box} 
                      selectedZone={selectedZone} 
                      allZoneRates={allZoneRates}
                      rateErrors={rateErrors}
                      isLoadingRates={isLoadingRates}
                    />
                  </div>                     
                </div>
              </div>                
            ))}
            
            {/* Only show Save button if NOT from GroupWishCombo */}
            {!isFromGroupWishCombo && (
              <button 
                style={saveButtonStyle} 
                onClick={handleSaveSolution} 
                disabled={isSaving}
              >
                <Save size={16} style={{ marginRight: '8px' }} />
                {isSaving ? 'Saving...' : 'Save 3D Packaging Solution'}
              </button>
            )}
          </div>
        )}
      </div> 
      {showBulkImport && (
        <BulkImportModal 
          onImport={handleBulkImport}
          onClose={() => setShowBulkImport(false)}
        />
      )}
    </div>
  );
};

// Styles
const containerStyle = {
  backgroundColor: style.Color.colorWhite,
  borderRadius: '8px',
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  margin: '20px 20px',
  padding: '20px',
  paddingBottom: '60px',
  width: '100%',
  maxWidth: '1500px',
};

const alertContainerStyle = {
  margin: '0 0 20px 0',
};

const headerStyle = {
  display: 'flex',
  alignItems: 'center',
  marginBottom: '20px',
};

const iconStyle = {
  width: '24px',
  height: '24px',
  marginRight: '10px',
};

const titleStyle = {
  fontSize: '20px',
  fontWeight: 'bold',
  color: style.Color.colorRoyalBlueText,
};

const contentStyle = {
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
};

const unitSelectionStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  gap: '20px',
  marginBottom: '20px',
  padding: '15px',
  backgroundColor: '#f8f9fa',
  borderRadius: '4px',
};

const selectStyle = {
  padding: '8px',
  borderRadius: '4px',
  border: `1px solid ${style.Color.colorGray}`,
  marginLeft: '10px',
  fontSize: '14px',
};  

const inputStyle = {
  padding: '8px',
  border: `1px solid ${style.Color.colorGray}`,
  borderRadius: '4px',
  fontSize: '14px',
};

const manualInputContainerStyle = {
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
};

const inputRowStyle = {
  display: 'grid',
  gridTemplateColumns: 'repeat(5, 1fr)',
  gap: '10px',
};

const addItemButtonStyle = {
  padding: '10px',
  backgroundColor: style.Color.colorMintGreen,
  color: style.Color.colorWhite,
  border: 'none',
  borderRadius: '4px',
  cursor: 'pointer',
  fontWeight: 'bold',
  alignSelf: 'flex-start',
};

const calculateButtonStyle = {
  padding: '12px',
  backgroundColor: style.Color.colorMintGreen,
  color: style.Color.colorWhite,
  border: 'none',
  borderRadius: '4px',
  cursor: 'pointer',
  fontWeight: 'bold',
  width: '40%',
  marginLeft: '30%',
};

const solutionContainerStyle = {
  marginTop: '20px',
};

const boxContainerStyle = {
  border: `1px solid ${style.Color.colorGray}`,
  borderRadius: '4px',
  padding: '15px',
  marginBottom: '15px',
};

const boxHeaderStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  marginBottom: '15px',
};

const boxTitleStyle = {
  fontSize: '16px',
  fontWeight: 'bold',
  marginBottom: '10px',
  color: style.Color.colorDarkGrey,
};

const boxDetailsStyle = {
  fontSize: '14px',
  color: style.Color.colorDarkGrey,
  marginBottom: '5px',
};

const itemsContainerStyle = {
  marginTop: '10px',
};

const itemsTitleStyle = {
  fontSize: '14px',
  fontWeight: 'bold',
  marginBottom: '5px',
};

const itemListStyle = {
  listStyle: 'disc',
  paddingLeft: '20px',
};

const itemStyle = {
  fontSize: '14px',
  color: style.Color.colorDarkGrey,
  marginBottom: '10px',
};

const positionDetailsStyle = {
  fontSize: '12px',
  color: style.Color.colorDarkGrey,
  marginTop: '3px',
  fontStyle: 'italic',
};
  
const specialRequirementsStyle = {
  fontSize: '12px',
  fontStyle: 'italic',
  marginTop: '5px',
  color: style.Color.colorDarkGrey,
  display: 'flex',
  flexWrap: 'wrap',
  gap: '5px',
};

const warningStyle = {
  backgroundColor: '#fff3cd',
  color: '#856404',
  padding: '2px 6px',
  borderRadius: '4px',
  marginLeft: '5px',
  fontSize: '11px',
};

const saveStatusContainerStyle = {
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  marginBottom: '20px',
  padding: '15px',
  backgroundColor: '#f8f9fa',
  borderRadius: '4px',
  border: `1px solid ${style.Color.colorLightGrey}`,
};

const saveButtonStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '12px',
  backgroundColor: style.Color.colorPurple,
  color: style.Color.colorWhite,
  border: 'none',
  borderRadius: '4px',
  cursor: 'pointer',
  fontWeight: 'bold',
  transition: 'background-color 0.3s ease',
  width: "30%",
  marginLeft: '30%',
};

const successMessageStyle = {
  backgroundColor: '#d4edda',
  color: '#155724',
  padding: '10px',
  borderRadius: '4px',
  fontWeight: 'bold',
  textAlign: 'center',
};

const errorMessageStyle = {
  backgroundColor: '#f8d7da',
  color: '#721c24',
  padding: '10px',
  borderRadius: '4px',
  fontWeight: 'bold',
  textAlign: 'center',
};

const previewButtonStyle = {
  display: 'flex',
  alignItems: 'center',
  padding: '8px 12px',
  backgroundColor: style.Color.colorMintGreen,
  color: style.Color.colorWhite,
  border: 'none',
  borderRadius: '4px',
  cursor: 'pointer',
  fontSize: '13px',
  fontWeight: 'bold',
};

const shippingCalculatorContainerStyle = {
  marginTop: '20px',
  borderTop: `1px solid ${style.Color.colorGray}`,
  paddingTop: '20px'
};

export default PackagingOptimizer;