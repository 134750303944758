import React from 'react';
import { Info } from 'lucide-react';

// Styles
const optimizationAlertContainerStyle = {
  marginTop: '15px',
  marginBottom: '15px',
  padding: '12px',
  backgroundColor: '#fff3cd',
  border: '1px solid #ffeeba',
  borderRadius: '4px',
};

const optimizationAlertContentStyle = {
  display: 'flex',
  alignItems: 'flex-start',
  gap: '10px',
};

const alertIconStyle = {
  marginTop: '2px',
  flexShrink: 0,
};

const alertTextContainerStyle = {
  flex: 1,
};

const alertTitleStyle = {
  fontSize: '14px',
  fontWeight: 'bold',
  color: '#856404',
  marginBottom: '4px',
};

const alertMessageStyle = {
  fontSize: '13px',
  color: '#856404',
};

const highlightStyle = {
  fontWeight: 'bold',
};

const suggestionStyle = {
  marginTop: '8px',
  fontSize: '13px',
  fontStyle: 'italic',
};

const volumeInfoStyle = {
  marginTop: '6px',
  fontSize: '13px',
  color: '#856404',
};

/**
 * Enhanced Weight Optimization Alert component
 * - Shows only when there's meaningful optimization potential
 * - Provides specific suggestions based on the shipping context
 * - Updates dynamically with each calculation
 * - Includes volume utilization guidance
 */
const WeightOptimizationAlert = ({ 
  actualWeight, 
  billableWeight, 
  remainingWeight, 
  boxCount, 
  volumeUtilization, 
  boxDimensions 
}) => { 
  // Ensure values are numbers and handle undefined/null values
  const actualWeightNum = parseFloat(actualWeight || 0);
  const billableWeightNum = parseFloat(billableWeight || 0);
  const remainingWeightNum = parseFloat(remainingWeight || 0);
  const volumeUtilizationNum = parseFloat(volumeUtilization || 0);
  
  // Don't show alert if there's no meaningful optimization potential
  if (isNaN(actualWeightNum) || 
      isNaN(billableWeightNum) || 
      isNaN(remainingWeightNum) || 
      billableWeightNum <= actualWeightNum + 0.1 || // Add a small threshold
      remainingWeightNum <= 0.1) { // Only show if there's at least 0.1 units remaining
    return null;
  }
  
  // Calculate how much of the billable weight is actually being used (as a percentage)
  const utilizationPercentage = (actualWeightNum / billableWeightNum) * 100;
  
  // Calculate additional volume information if box dimensions are provided
  let volumeInfo = "";
  let remainingVolumeInCubicInches = 0;
  
  if (boxDimensions && volumeUtilizationNum < 100) {
    const totalBoxVolume = boxDimensions.length * boxDimensions.width * boxDimensions.height;
    remainingVolumeInCubicInches = totalBoxVolume * (1 - (volumeUtilizationNum / 100));
    
    // Format the remaining volume (round to whole number for easier comprehension)
    const roundedRemainingVolume = Math.floor(remainingVolumeInCubicInches);
    
    volumeInfo = `You're currently using only ${volumeUtilizationNum.toFixed(1)}% of the box volume - you could add items up to approximately ${roundedRemainingVolume} cubic inches in size while still using the same box and shipping cost.`;
  }
  
  // Determine suggestion based on utilization and box count
  let suggestion = "";
  if (utilizationPercentage < 50) {
    suggestion = "Consider adding more items to maximize shipping value.";
    
    // Add size examples if volume info is available
    if (remainingVolumeInCubicInches > 100) {
      // Calculate rough dimensions for example items that would fit
      // Using cubic root for a cube-like object
      const cubeSide = Math.floor(Math.cbrt(remainingVolumeInCubicInches));
      
      // Or calculate dimensions for a flatter item
      const flatItemLength = Math.min(boxDimensions.length, 10);
      const flatItemWidth = Math.min(boxDimensions.width, 10);
      const flatItemHeight = Math.floor(remainingVolumeInCubicInches / (flatItemLength * flatItemWidth));
      
      if (flatItemHeight >= 1) {
        suggestion += ` For example, you could add a flat item of about ${flatItemLength}" × ${flatItemWidth}" × ${flatItemHeight}" or a cube-shaped item of about ${cubeSide}" per side.`;
      }
    }
  } else if (utilizationPercentage < 70) {
    suggestion = `You're using only about ${Math.round(utilizationPercentage)}% of your billable weight.`;
  }
  
  if (boxCount > 1) {
    suggestion += " Also consider if items could be consolidated into fewer boxes.";
  }

  // Add volume utilization suggestion when it's very low
  if (boxCount === 1 && volumeUtilizationNum < 30) {
    suggestion += " Consider using a smaller box for better space efficiency.";
  }
  
  return (
    <div style={optimizationAlertContainerStyle}>
      <div style={optimizationAlertContentStyle}>
        <div style={alertIconStyle}>
          <Info size={16} style={{ color: '#856404' }} />
        </div>
        <div style={alertTextContainerStyle}>
          <div style={alertTitleStyle}>Shipping Cost Optimization Available</div>
          <div style={alertMessageStyle}>
            You're being charged for dimensional weight ({billableWeightNum.toFixed(2)} lbs) rather than actual weight ({actualWeightNum.toFixed(2)} lbs).
            You could add up to <span style={highlightStyle}>{remainingWeightNum.toFixed(2)} lbs</span> of additional items without increasing shipping costs.
          </div>
          
          {volumeInfo && (
            <div style={volumeInfoStyle}>
              {volumeInfo}
            </div>
          )}
          
          {suggestion && (
            <div style={suggestionStyle}>{suggestion}</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default WeightOptimizationAlert;