import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faStar, faImage } from '@fortawesome/free-solid-svg-icons';
import { Color } from "../../../GlobalStyles";
import styled, { keyframes } from 'styled-components';

const fadeInOut = keyframes`
  0% { opacity: 0; transform: scale(0.5); }
  50% { opacity: 1; transform: scale(1.2); }
  100% { opacity: 0; transform: scale(1); }
`;

const AnimatedStarWrapper = styled.div`
  position: absolute;
  bottom: 10px;
  right: 10px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: ${fadeInOut} 2s ease-in-out;
`;

const CategoryItem = ({ item, onAddWish, existingWishes, onWishAdded }) => { 
  const [isExistingWish, setIsExistingWish] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const [imageError, setImageError] = useState(false);
  
  useEffect(() => { 
    const isWished = Array.isArray(existingWishes) && 
                    existingWishes.some(wish => 
                      wish.toLowerCase() === item.name.toLowerCase()
                    );
    setIsExistingWish(isWished);
  }, [existingWishes, item.name]);

  const getImageUrl = (imageUrl) => {
    if (!imageUrl) return null;
    if (imageUrl.startsWith('http')) return imageUrl;
    return `${process.env.REACT_APP_API_URL}${imageUrl}`;
  };

  const handleAddWish = async () => {
    if (!isExistingWish) {
      setIsAnimating(true);
      try {
        await onAddWish(item.name);
      } catch (error) {
        setIsAnimating(false);
      }
    }
  };

  const handleImageError = () => {
    setImageError(true);
  };

  const handleAnimationEnd = () => {
    setIsAnimating(false);
    setIsExistingWish(true);
    if (onWishAdded) onWishAdded(item.name);
  };

  return (
    <div className="bg-white rounded-lg p-4 shadow-md flex flex-col items-center justify-between relative h-40 cursor-pointer">
      <h3 className="text-sm mb-2"  style={{ color: Color.colorRoseGoldText }}>{item.name}</h3>
      
      {!imageError ? (
        <img
          src={item.image_url}
          alt={item.name}  onError={handleImageError}  className="w-24 h-24 object-cover rounded-lg mb-2"/>
      ) : (
        <div className="w-24 h-24 bg-gray-200 rounded-lg flex items-center justify-center mb-2">
          <FontAwesomeIcon icon={faImage} className="text-gray-400 text-2xl"  />
        </div>
      )}

      {isExistingWish ? (
        <div className="absolute bottom-2 right-2 bg-blue-900 text-white w-6 h-6 rounded-full flex items-center justify-center" style={{ backgroundColor: Color.colorYellow }}>
          <FontAwesomeIcon icon={faCheck} className="text-xs"  />
        </div>
      ) : isAnimating ? (
        <AnimatedStarWrapper onAnimationEnd={handleAnimationEnd}>
          <FontAwesomeIcon icon={faStar} className="text-yellow-400 text-sm"/>
        </AnimatedStarWrapper>
      ) : (
        <button onClick={handleAddWish} style={{ backgroundColor: Color.colorLightBeige }}
          className="absolute bottom-2 right-2 bg-blue-900 w-8 h-8 rounded-full flex items-center justify-center hover:bg-blue-800 transition-colors"
        >
          <FontAwesomeIcon icon={faStar} className="text-yellow-400 text-base" />
        </button>
      )}
    </div>
  );
};
export default CategoryItem;