import React, { useState, useEffect, useRef, memo } from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer, Sector } from 'recharts';
import { Users, Infinity, Clock, TrendingUp, Star, Package, ShoppingBag } from 'lucide-react';
import { Color } from "../../../GlobalStyles";

// Debug function to log props and state
const debugLog = (message, data) => {
  if (process.env.NODE_ENV !== 'production') {
    console.log(`[ComboProgressChart] ${message}:`, data);
  }
};

// Use React.memo to memoize the component
const ComboProgressChart = memo(({ 
  progressStats = { 
    verifiedUsers: 0, 
    unverifiedUsers: 0, 
    totalJoined: 0, 
    totalWaitListed: 0,
    verifiedQty: 0,
    unverifiedQty: 0,
    totalQty: 6,
    progress: 0, 
    averageRating: 0 
  },
  comboData = {
    joined_users: 0,
    min_combo_size: 0,
    min_combo_order_qty: 2,
    combo_expiry: null,
    expires_at: null
  }
}) => {  
  const firstRender = useRef(true);
  const [activeIndex, setActiveIndex] = useState(0); 
  const chartValues = useRef({
    verifiedQty: 0,
    unverifiedQty: 0,
    totalQty: 20,
    verifiedUsers: 0,
    unverifiedUsers: 0,
    totalJoined: 0,
    totalWaitListed: 0,
    averageRating: 0,
    progress: 0
  });

  // Calculate progress percentage
  const calculateProgressPercentage = () => {
    const totalQty = progressStats?.totalQty ?? progressStats?.verifiedUsers ?? 0; 
    const targetQty = comboData?.min_combo_order_qty ?? 50;      
    if (targetQty <= 0) return 0;
    const progressPercentCalculated = Math.min(100, Math.floor((totalQty / targetQty) * 100));  
    return progressPercentCalculated;
  };

  useEffect(() => { 
    if (firstRender.current) {
      firstRender.current = false;
      return;
    } 
    const ProgressPercentage = calculateProgressPercentage(); 
    chartValues.current = {
      verifiedQty: progressStats?.verifiedQty ?? progressStats?.verifiedUsers ?? 0,
      unverifiedQty: progressStats?.unverifiedQty ?? progressStats?.unverifiedUsers ?? 0,
      totalQty: progressStats?.totalQty ?? progressStats?.verifiedUsers ?? 0,
      verifiedUsers: progressStats?.verifiedUsers ?? 0,
      unverifiedUsers: progressStats?.unverifiedUsers ?? 0,
      totalJoined: progressStats?.totalJoined ?? 0,
      totalWaitListed: progressStats?.totalWaitListed ?? 0,
      averageRating: progressStats?.averageRating ?? 0,
      progress: ProgressPercentage
    }; 
  }, [progressStats]);

  // Create chart data that correctly represents the progress percentage
  const prepareChartData = () => {
    const progress = chartValues.current.progress;
    const remaining = 100 - progress;
    
    return [
      { 
        name: 'Progress', 
        value: progress, 
        color: '#34D399' // Mint green
      },
      { 
        name: 'Remaining', 
        value: remaining > 0 ? remaining : 0, 
        color: '#E5E7EB' // Light gray
      }
    ];
  };
  
  // Get chart data for the ring
  const progressRingData = prepareChartData();
  
  // Create data for the quantity breakdown (for hover/details)
  const quantityData = [
    { 
      name: 'Verified Quantity', 
      value: chartValues.current.verifiedQty, 
      color: '#34D399' // Mint green
    },
    { 
      name: 'Unverified Quantity', 
      value: chartValues.current.unverifiedQty, 
      color: '#F59E0B' // Amber
    }
  ];

  // Filter out zero values for quantity data
  const displayQuantityData = quantityData.filter(item => item.value > 0);
  
  // Fallback to placeholder if no data
  const finalQuantityData = displayQuantityData.length > 0 
    ? displayQuantityData 
    : [{ name: 'No Items', value: 1, color: '#E5E7EB' }]; 

  // Render the active slice with details
  const renderActiveShape = (props) => {
    const { 
      cx, cy, innerRadius, outerRadius, 
      startAngle, endAngle, fill, payload, value 
    } = props;

    // Different display logic based on the slice type
    const isProgressSlice = payload.name === 'Progress';
    const isRemainingSlice = payload.name === 'Remaining';
    const isNoItems = payload.name === 'No Items';
    
    let displayText = '';
    let displayValue = '';
    
    if (isProgressSlice) {
      displayText = 'Completed';
      displayValue = `${value}%`;
    } else if (isRemainingSlice) {
      displayText = 'Remaining';
      displayValue = `${value}%`;
    } else if (isNoItems) {
      displayText = 'No Items';
      displayValue = 'No data yet';
    } else {
      // For quantity data (when hovering over the detailed quantity breakdown)
      const totalValue = quantityData.reduce((sum, item) => sum + item.value, 0);
      const percentage = ((value / totalValue) * 100).toFixed(0);
      displayText = payload.name;
      displayValue = `${value} (${percentage}%)`;
    }

    return (
      <g>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <text 
          x={cx} 
          y={cy - 10} 
          textAnchor="middle" 
          fill="#1F2937"
          style={styles.activeShapeTitle}
        >
          {displayText}
        </text>
        <text 
          x={cx} 
          y={cy + 10} 
          textAnchor="middle" 
          fill="#4B5563"
          style={styles.activeShapeValue}
        >
          {displayValue}
        </text>
      </g>
    );
  };

  // Calculate remaining days until expiry
  const calculateRemainingDays = () => {
    try {
      const expiryString = comboData?.combo_expiry || comboData?.expires_at;
      if (!expiryString) return "9430 days";
      
      const expiryDate = new Date(expiryString);
      if (isNaN(expiryDate.getTime())) {
        return "9430 days";
      }
      
      const today = new Date();
      const diffTime = expiryDate - today;
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      
      return `${diffDays} days`;
    } catch (error) {
      return "9430 days";
    }
  }; 
  
  const minRequiredQty = comboData?.min_combo_order_qty ?? 50; 
  const isGoalReached = chartValues.current.progress >= 100; 
  
  return (
    <div style={styles.container}>
      <div style={styles.header}>
        <h3 style={styles.title}>Group Progress</h3>
        <div style={styles.expiryInfo}>
          <Clock size={16} color="#6B7280" />
          <span style={styles.expiryText}>
            {calculateRemainingDays()} remaining
          </span>
        </div>
      </div>
      
      <div style={styles.chartContainer}>
        <ResponsiveContainer width="100%" height={300}>
          <PieChart>
            <Pie
              activeIndex={activeIndex}
              activeShape={renderActiveShape}
              data={progressRingData}
              cx="50%"
              cy="50%"
              innerRadius={120}
              outerRadius={150}
              dataKey="value"
              onMouseEnter={(_, index) => setActiveIndex(index)}
              startAngle={90}
              endAngle={-270}
            >
              {progressRingData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={entry.color} />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </div>
      
      <div style={styles.statsContainer}>
        <div style={styles.statsGrid}>
          <div style={styles.statItem}>
            <Package color="#3B82F6" size={20} />
            <div>
              <p style={styles.statLabel}>Total Quantity</p>
              <p style={styles.statValue}>{chartValues.current.totalQty}</p>
            </div>
          </div>
          
          <div style={styles.statItem}>
            <TrendingUp color="#3B82F6" size={20} />
            <div>
              <p style={styles.statLabel}>Progress</p>
              <p style={styles.statValue}>{chartValues.current.progress}%</p>
            </div>
          </div>
        </div>

        <div style={styles.statsGrid}>
          <div style={styles.statItem}>
            <ShoppingBag color="#34D399" size={20} />
            <div>
              <p style={styles.statLabel}>Target Quantity</p>
              <p style={styles.statValue}>{minRequiredQty}</p>
            </div>
          </div>
          
          <div style={styles.statItem}>
            <Star color="#FBBF24" size={20} />
            <div>
              <p style={styles.statLabel}>Avg Rating</p>
              <p style={styles.statValue}>{chartValues.current.averageRating.toFixed(1)}</p>
            </div>
          </div>
        </div>
        
        <div style={styles.userStatsSection}>
          <h4 style={styles.userStatsTitle}>User Stats</h4>
          <div style={styles.statsGrid}>
            <div style={styles.statItem}>
              <Users color="#34D399" size={20} />
              <div>
                <p style={styles.statLabel}>Total Joined</p>
                <p style={styles.statValue}>{chartValues.current.totalJoined}</p>
              </div>
            </div>
            
            <div style={styles.statItem}>
              <Users color="#F59E0B" size={20} />
              <div>
                <p style={styles.statLabel}>Verified Users</p>
                <p style={styles.statValue}>{chartValues.current.verifiedUsers}</p>
              </div>
            </div>
          </div>
        </div>
        
        <div style={styles.progressSection}>
          <div style={styles.progressBarContainer}>
            <div 
              style={{
                ...styles.progressBar,
                width: `${Math.min(chartValues.current.progress, 100)}%`
              }}
            />
          </div>
          
          <div style={styles.progressStatus}>
            {isGoalReached ? (
              <div style={styles.completedStatus}>
                <Infinity size={20} />
                <span style={styles.completedText}>Minimum Required Quantity Reached!</span>
              </div>
            ) : (
              <p style={styles.waitlistText}>
                {chartValues.current.totalWaitListed > 0 
                  ? `${chartValues.current.totalWaitListed} users in waitlist` 
                  : `${Math.max(0, minRequiredQty - chartValues.current.totalQty)} more items needed to complete the group`
                }
              </p>
            )}
          </div>
        </div> 
      </div>
    </div>
  );
});

const styles = {
  container: {
    backgroundColor: '#ffffff',
    borderRadius: '8px',
    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
    padding: '24px',
    maxHeight: '1000px',
  },
  header: {
    marginBottom: '16px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  title: {
    fontSize: '20px',
    fontWeight: '600',
    color: Color.colorPeacockBlueDark,
    margin: 0
  },
  expiryInfo: {
    display: 'flex',
    alignItems: 'center',
    gap: '6px',
    padding: '4px 8px',
    backgroundColor: '#F3F4F6',
    borderRadius: '6px'
  },
  expiryText: {
    fontSize: '14px',
    color: '#6B7280'
  },
  chartContainer: {
    height: '300px',
    width: '100%'
  },
  activeShapeTitle: {
    fontSize: '14px',
    fontWeight: '500'
  },
  activeShapeValue: {
    fontSize: '12px'
  },
  statsContainer: {
    marginTop: '24px',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px'
  },
  statsGrid: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '16px'
  },
  statItem: {
    display: 'flex',
    alignItems: 'center',
    gap: '12px'
  },
  statLabel: {
    fontSize: '14px',
    color: '#4B5563',
    margin: '0'
  },
  statValue: {
    fontSize: '16px',
    fontWeight: '500',
    color: '#111827',
    margin: '0'
  },
  userStatsSection: {
    marginTop: '8px',
    padding: '16px',
    backgroundColor: '#F9FAFB',
    borderRadius: '8px',
    border: '1px solid #E5E7EB'
  },
  userStatsTitle: {
    fontSize: '16px',
    fontWeight: '500',
    color: '#374151',
    marginTop: '0',
    marginBottom: '12px'
  },
  progressSection: {
    marginTop: '16px'
  },
  progressBarContainer: {
    height: '8px',
    backgroundColor: '#E5E7EB',
    borderRadius: '9999px',
    overflow: 'hidden'
  },
  progressBar: {
    height: '100%',
    backgroundColor: '#34D399',
    borderRadius: '9999px',
    transition: 'width 300ms'
  },
  progressStatus: {
    marginTop: '8px',
    textAlign: 'center'
  },
  completedStatus: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '8px',
    color: '#3B82F6'
  },
  completedText: {
    fontSize: '14px',
    fontWeight: '500'
  },
  waitlistText: {
    fontSize: '14px',
    color: '#4B5563'
  }
};

export default ComboProgressChart;