import React from 'react';
import { GroupWishCacheProvider } from './components/Buyers/GroupWishes/GroupWishCache'; 
const WithWishCache = ({ children }) => {
  return (
    <GroupWishCacheProvider>
      {children}
    </GroupWishCacheProvider>
  );
};

export default WithWishCache;