import React, { useState, useEffect } from 'react';  
import { useNavigate } from 'react-router-dom';
import * as style from "../../../GlobalStyles";
import { Heart, Clock, Users, ThumbsUp, ThumbsDown, Activity, Package, ShoppingBag, Tag } from 'lucide-react';
import { useAuth } from '../../../components/Common/InitialGears/AuthContext'; 
import { getApi, postApi, buildUrl } from "../../Common/BackendCalls/apiUtils";
import { TopMenuBar } from '../DashbordComponents/TopMenuBar';

const FavouriteGroupWishes = () => {
  const { queryLoginParam } = useAuth();
  const navigate = useNavigate();
  const [favorites, setFavorites] = useState([]);
  const [filteredFavorites, setFilteredFavorites] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [sortOrder, setSortOrder] = useState('popularity-desc');
  const [expandedItemId, setExpandedItemId] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isProcessing, setIsProcessing] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    fetchFavorites();
  }, []);

  useEffect(() => {
    filterFavorites();
  }, [searchText, favorites, sortOrder]);

  const fetchFavorites = async () => {
    try {
      const url = buildUrl('getUserFavorites', queryLoginParam);
      const response = await getApi(url);
      
      if (response.ok) {
        // Create a unified list of favorites with item type identifier
        const groupWishes = (response.data.user_group_wishes || []).map(item => ({
          ...item,
          itemType: 'group-wish',
          id: item.wish.id,
          title: item.wish.product_name,
          description: item.wish.description,
          expiresAt: item.wish.expires_at,
          totalInterested: item.totalJoined || 0,
          image: item.wish.image,
          listPrice: item.wish.list_price,
          currency: item.wish.currency,
          verifiedUsers: item.verifiedUsers || 0,
          upvotes: item.wish.upvotes,
          downvotes: item.wish.downvotes
        }));
        
        const combos = (response.data.user_favorite_combos || []).map(item => ({
          ...item,
          itemType: 'combo',
          id: item.combo.id || item.combo.uuid,
          title: item.combo.title || "Bundle Deal",
          description: item.combo.description,
          expiresAt: item.combo.expires_at,
          rating: item.combo.progress_stats?.rating_counts ? item.combo.progress_stats.rating_counts[0] : "N/A",
          items: item.combo.combo_items || [],
          bundleMrp: item.combo.bundle_mrp,
          bundleRate: item.combo.bundle_rate,
          packagingDetails: item.combo.packaging_details
        }));
        
        // Combine both types into a single array
        const allFavorites = [...groupWishes, ...combos];
        setFavorites(allFavorites);
        setFilteredFavorites(allFavorites);
      } else {
        setError('Failed to fetch favorites');
      }
    } catch (error) {
      setError('Failed to fetch favorites');
    } finally {
      setIsLoading(false);
    }
  };

  const filterFavorites = () => {
    if (favorites.length > 0) {
      let filtered = favorites.filter(item => {
        const searchFields = item.itemType === 'group-wish' 
          ? [item.title, item.description].filter(Boolean)
          : [item.title, item.description].filter(Boolean);
          
        return searchFields.some(field => 
          field.toLowerCase().includes(searchText.toLowerCase())
        );
      });

      filtered.sort((a, b) => {
        switch (sortOrder) {
          case 'popularity-desc':
            // For group wishes, use totalInterested; for combos, maybe use another metric
            const aPopularity = a.itemType === 'group-wish' ? a.totalInterested : (a.rating !== "N/A" ? a.rating : 0);
            const bPopularity = b.itemType === 'group-wish' ? b.totalInterested : (b.rating !== "N/A" ? b.rating : 0);
            return bPopularity - aPopularity;
          case 'popularity-asc':
            const aPopAsc = a.itemType === 'group-wish' ? a.totalInterested : (a.rating !== "N/A" ? a.rating : 0);
            const bPopAsc = b.itemType === 'group-wish' ? b.totalInterested : (b.rating !== "N/A" ? b.rating : 0);
            return aPopAsc - bPopAsc;
          case 'date-desc':
            return new Date(b.expiresAt) - new Date(a.expiresAt);
          case 'date-asc':
            return new Date(a.expiresAt) - new Date(b.expiresAt);
          default:
            return 0;
        }
      });

      setFilteredFavorites(filtered);
    }
  };

  const toggleSortOrder = () => {
    const orders = ['popularity-desc', 'popularity-asc', 'date-desc', 'date-asc'];
    const currentIndex = orders.indexOf(sortOrder);
    setSortOrder(orders[(currentIndex + 1) % orders.length]);
  };

  const renderFavoriteItem = (item) => {
    const isExpanded = expandedItemId === item.id;
    const isGroupWish = item.itemType === 'group-wish';

    const formatDate = (dateString) => {
      return new Date(dateString).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      });
    };

    return (
      <div key={item.id} style={styles.favoriteItem}>
        <button onClick={() => setExpandedItemId(isExpanded ? null : item.id)} style={styles.favoriteItemButton}>
          <div style={styles.favoriteItemHeader}>
            <div style={styles.titleContainer}>
              <div style={styles.itemTypeIndicator}>
                {isGroupWish ? (
                  <Users size={16} style={styles.itemTypeIcon} />
                ) : (
                  <ShoppingBag size={16} style={styles.itemTypeIcon} />
                )}
                <span style={styles.itemType}>{isGroupWish ? "Group Wish" : "Bundle Deal"}</span>
              </div>
              <h3 style={styles.favoriteItemTitle}>{item.title}</h3>
            </div>
            <span style={styles.metricValue}>
              {isGroupWish 
                ? `${item.totalInterested} Interested` 
                : `Rating: ${item.rating}`}
            </span>
          </div>
        </button>

        {isExpanded && (
          <div style={styles.favoriteItemDetails}>
            {/* Common Expandable Content */}
            {item.image && (
              <div style={styles.imageContainer}>
                <img 
                  src={item.image} 
                  alt={item.title} 
                  style={styles.productImage} 
                  onError={(e) => {
                    e.target.src = "/api/placeholder/400/320";
                  }}
                />
              </div>
            )}
            
            {/* Render description */}
            {renderDescription(item.description, isExpanded)}
            
            {/* Stats Container - common to both types but with different content */}
            <div style={styles.statsContainer}>
              {isGroupWish ? (
                <>
                  <div style={styles.statItem}>
                    <Users className="w-4 h-4" style={styles.statIcon} />
                    <span style={styles.statText}>{item.verifiedUsers} Verified Buyers</span>
                  </div>
                </>
              ) : (
                <>
                  <div style={styles.statItem}>
                    <Package className="w-4 h-4" style={styles.statIcon} />
                    <span style={styles.statText}>
                      {item.packagingDetails?.total_boxes 
                        ? `${item.packagingDetails.total_boxes} boxes` 
                        : "Standard Package"}
                    </span>
                  </div>
                </>
              )}
              <div style={styles.statItem}>
                <Clock className="w-4 h-4" style={styles.statIcon} />
                <span style={styles.statText}>Expires: {formatDate(item.expiresAt)}</span>
              </div>
            </div>
            
            {/* Type-specific content */}
            {isGroupWish ? (
              <>
                {/* Group Wish specific details */}
                <div style={styles.ratingContainer}>
                  <div style={styles.ratingItem}>
                    <ThumbsUp className="w-4 h-4" style={styles.ratingIcon} />
                    <span style={styles.ratingText}>{item.upvotes}</span>
                  </div>
                  <div style={styles.ratingItem}>
                    <ThumbsDown className="w-4 h-4" style={styles.ratingIcon} />
                    <span style={styles.ratingText}>{item.downvotes}</span>
                  </div>
                  {item.listPrice && (
                    <div style={styles.priceTag}>
                      <span style={styles.priceLabel}>List Price:</span>
                      <span style={styles.priceValue}>
                        {item.currency} {item.listPrice}
                      </span>
                    </div>
                  )}
                </div>
              </>
            ) : (
              <>
                {/* Combo specific details */}
                {/* Combo Items Section */}
                {item.items && item.items.length > 0 && (
                  <div style={styles.comboItemsContainer}>
                    <h4 style={styles.comboItemsTitle}>Bundle Items</h4>
                    {item.items.map((comboItem, index) => (
                      <div key={index} style={styles.comboItem}>
                        <div style={styles.comboItemImageContainer}>
                          {comboItem.group_wish.image_url && (
                            <img 
                              src={comboItem.group_wish.image_url} 
                              alt={comboItem.group_wish.product_name} 
                              style={styles.comboItemImage}
                              onError={(e) => {
                                e.target.src = "/api/placeholder/400/320";
                              }}
                            />
                          )}
                        </div>
                        <div style={styles.comboItemDetails}>
                          <h5 style={styles.comboItemTitle}>{comboItem.group_wish.product_name}</h5>
                          <div style={styles.comboItemPrice}>
                            <span style={styles.comboItemPriceLabel}>List Price:</span>
                            <span style={styles.comboItemPriceValue}>
                              ${comboItem.group_wish.list_price ? comboItem.group_wish.list_price.toFixed(2) : "N/A"}
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
                
                {/* Price Information */}
                {(item.bundleMrp || item.bundleRate) && (
                  <div style={styles.comboPriceContainer}>
                    <div style={styles.comboPriceItem}>
                      <span style={styles.comboPriceLabel}>Bundle MRP:</span>
                      <span style={styles.comboPriceValue}>
                        ${item.bundleMrp?.toFixed(2) || "N/A"}
                      </span>
                    </div>
                    <div style={styles.comboPriceItem}>
                      <span style={styles.comboPriceLabel}>Bundle Rate:</span>
                      <span style={styles.comboPriceValue}>
                        ${item.bundleRate?.toFixed(2) || "N/A"}
                      </span>
                    </div>
                    {item.bundleMrp && item.bundleRate && (
                      <div style={styles.comboPriceItemHighlight}>
                        <span style={styles.comboPriceLabel}>You Save:</span>
                        <span style={styles.savingsValue}>
                          ${(item.bundleMrp - item.bundleRate).toFixed(2)} 
                          ({Math.round((1 - item.bundleRate / item.bundleMrp) * 100)}%)
                        </span>
                      </div>
                    )}
                  </div>
                )}
              </>
            )}
            
            {/* Action buttons */}
            <div style={styles.actionButtons}>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  removeFromFavorites(item);
                }}
                disabled={isProcessing}
                style={styles.actionButton}
              >
                <Heart className="w-4 h-4 mr-2" />
                Remove
              </button>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  viewDetails(item);
                }}
                style={{
                  ...styles.actionButton,
                  backgroundColor: style.Color.colorRoyalBlueText,
                }}
              >
                {isGroupWish ? (
                  <>
                    <Activity className="w-4 h-4 mr-2" />
                    View Progress
                  </>
                ) : (
                  <>
                    <ShoppingBag className="w-4 h-4 mr-2" />
                    View Bundle
                  </>
                )}
              </button>
            </div>

            {errorMessage && (
              <div style={styles.errorMessage}>
                {errorMessage}
              </div>
            )}
          </div>
        )}
      </div>
    );
  };

  const renderDescription = (description, isExpanded) => {
    if (!description) return null;
  
    const formatDescription = (text) => {
      const sections = text.split(/\r\n\r\n/);
      
      return sections.map((section, index) => {
        if (section.includes(':')) {
          const [title, ...points] = section.split(/\r\n/);
          return (
            <div key={index} style={styles.descriptionSection}>
              <h4 style={styles.sectionTitle}>{title}</h4>
              <ul style={styles.sectionPoints}>
                {points.map((point, i) => {
                  const trimmedPoint = point.replace(/^[-•]?\s*/, '');
                  if (trimmedPoint) {
                    return <li key={i}>{trimmedPoint}</li>;
                  }
                  return null;
                })}
              </ul>
            </div>
          );
        } else {
          return <p key={index} style={styles.descriptionParagraph}>{section}</p>;
        }
      });
    };
  
    return (
      <div style={styles.descriptionContainer}>
        <div style={styles.descriptionContent}>
          {formatDescription(description)}
        </div>
      </div>
    );
  };

  const removeFromFavorites = async (item) => {
    if (isProcessing || !item.id) return;
    setIsProcessing(true);
    
    try {
      const isGroupWish = item.itemType === 'group-wish';
      const favoriteUrl = buildUrl(
        isGroupWish ? 'removeFromfavoriteGroupWish' : 'removeFromFavoriteCombo', 
        queryLoginParam
      );
      
      const requestBody = isGroupWish 
        ? { group_wish_id: item.id }
        : { combo_id: item.id };
        
      const response = await postApi(favoriteUrl, requestBody);

      if (response.ok) {
        await fetchFavorites();
      } else {
        setErrorMessage('Failed to remove from favorites.');
      }
    } catch (error) {
      setErrorMessage('Error updating favorites. Please try again.');
    } finally {
      setTimeout(() => {
        setIsProcessing(false);
      }, 500);
    }
  };

  const viewDetails = (item) => {
    const isGroupWish = item.itemType === 'group-wish';
    
    if (isGroupWish) {
      // For group wish
      navigate(`/groupWishProgress`, {
        state: {
          wish: item.wish,
          progressData: { 
            ...item, 
            is_favorite: true,
            wish: {
              ...item.wish,
              is_favorite: true
            }
          },
          joined: item.user_already_joined || false,
          is_favorite: true
        }
      });
    } else {
      // For combo
      navigate(`/comboProgress`, {
        state: {
          comboData: item.combo
        }
      });
    }
  };

  const getSortButtonText = () => {
    switch (sortOrder) {
      case 'popularity-desc':
        return 'Most Popular ↓';
      case 'popularity-asc':
        return 'Least Popular ↑';
      case 'date-desc':
        return 'Newest First ↓';
      case 'date-asc':
        return 'Oldest First ↑';
      default:
        return 'Sort';
    }
  };

  if (error) {
    return <div style={styles.error}>{error}</div>;
  }

  return (
    <div style={styles.container}>
      <TopMenuBar 
        showBackButton={false} 
        showHomeButton={true}  
        showMenuButton={true}  
        onBackClick={() => navigate(-1)}  
        title={'My Favorites'}  
        showSearch={false}  
        showAddress={false}  
      />  
      
      {isLoading ? (
        <div style={styles.loading}>Loading...</div>
      ) : (
        <div style={styles.content}>
          {favorites.length > 0 && (
            <div style={styles.searchContainer}>
              <input  
                type="text"  
                placeholder="Search favorites..."  
                value={searchText}  
                onChange={(e) => setSearchText(e.target.value)}  
                style={styles.searchInput} 
              />
              <button onClick={toggleSortOrder} style={styles.sortButton}>
                {getSortButtonText()}
              </button>
            </div>
          )}
          
          <div style={styles.favoritesList}>
            {favorites.length > 0 ? (
              filteredFavorites.map(renderFavoriteItem)
            ) : (
              <div style={styles.emptyState}>
                <p>You don't have any favorites yet.</p>
                <button 
                  style={styles.browseButton}
                  onClick={() => navigate('/discover')}
                >
                  Browse Products
                </button>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
 
const styles = {
  container: {
    margin: '4% auto',
    minHeight: '100vh',
    padding: '20px', 
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '20px',
    backgroundColor: style.Color.colorLightBeigeStrong,
    marginBottom: '20px',
    borderRadius: '10px',
  },
  pageTitle: {
    fontSize: '24px',
    fontWeight: 'bold',    
    color: style.Color.colorPeacockBlueDark,
  },
  content: {
    backgroundColor: style.Color.colorLightBeigeStrong,
    borderRadius: '10px',
    padding: '20px',
  },
  searchContainer: {
    display: 'flex',
    gap: '10px',
    marginBottom: '20px',
  },
  searchInput: {
    flexGrow: 1,
    padding: '10px',
    borderRadius: '8px',
    border: '1px solid #ddd',
    fontSize: '16px',
  },
  sortButton: {
    padding: '10px 20px',
    backgroundColor: style.Color.colorGreenGrey,
    color: style.Color.colorWhite,
    border: 'none',
    borderRadius: '8px',
    cursor: 'pointer',
  },
  favoritesList: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
  favoriteItem: {
    backgroundColor: '#FFFFFF',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  },
  favoriteItemButton: {
    width: '100%',
    background: 'none',
    border: 'none',
    padding: 0,
    cursor: 'pointer',
    textAlign: 'left',
  },
  favoriteItemHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  favoriteItemTitle: {
    fontSize: '18px',
    fontWeight: 'bold',
    color: style.Color.colorPeacockBlueDark,
    marginTop: '6px',
  },
  itemTypeIndicator: {
    display: 'flex',
    alignItems: 'center',
    gap: '6px',
  },
  itemTypeIcon: {
    color: style.Color.colorRoyalBlueText,
  },
  itemType: {
    fontSize: '12px',
    color: style.Color.colorRoyalBlueText,
    fontWeight: '500',
    padding: '2px 8px',
    backgroundColor: style.Color.colorLightestBeige,
    borderRadius: '12px',
  },
  metricValue: {
    color: style.Color.colorRoyalBlueText,
    fontWeight: '500',
  },
  favoriteItemDetails: {
    marginTop: '16px',
  },
  // Description styles
  descriptionContainer: {
    marginTop: '15px',
    padding: '15px',
    backgroundColor: style.Color.colorWhite,
    borderRadius: '8px',
  },
  descriptionContent: {
    fontSize: '12px',
    lineHeight: '1.6',
    color: style.Color.colorDarkBlue,
  },
  descriptionSection: {
    marginBottom: '20px',
  },
  sectionTitle: {
    fontSize: '12px',
    fontWeight: 'bold',
    color: style.Color.colorDarkslategray,
    marginBottom: '10px',
  },
  sectionPoints: {
    fontSize: '12px',
    listStyleType: 'none',
    paddingLeft: '20px',
    color: style.Color.colorRoyalBlueText,
    margin: 0,
  },
  descriptionParagraph: {
    marginBottom: '15px',
    textAlign: 'justify',
    fontSize: '12px',
    color: style.Color.colorDarkBlue,
  },
  // Stats container
  statsContainer: {
    display: 'flex',
    gap: '20px',
    marginBottom: '16px',
    padding: '12px',
    backgroundColor: style.Color.colorLightestBeige,
    borderRadius: '8px',
  },
  statItem: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },
  statIcon: {
    color: style.Color.colorPurple,
    width: '16px',
    height: '16px',
  },
  statText: {
    fontSize: '14px',
    color: style.Color.colorDarkBlue,
    fontWeight: '500',
  },
  // Rating container
  ratingContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
    marginTop: '12px',
    padding: '8px 0',
    borderTop: '1px solid #eee',
  },
  ratingItem: {
    display: 'flex',
    alignItems: 'center',
    color: '#666',
  },
  ratingIcon: {
    color: style.Color.colorMintGreen,
    width: '16px',
    height: '16px',
  },
  ratingText: {
    fontSize: '14px',
    color: style.Color.colorDarkBlue,
    fontWeight: '500',
  },
  // Price tag
  priceTag: {
    marginLeft: 'auto',
    padding: '4px 8px',
    backgroundColor: '#E5E7EB',
    borderRadius: '4px',
    fontSize: '14px',
    color: '#374151',
  },
  priceLabel: {
    fontSize: '12px',
    color: style.Color.colorDarkGrey,
  },
  priceValue: {
    marginLeft: '5px',
    fontSize: '14px',
    fontWeight: 'bold',
    color: style.Color.colorDarkBlue,
  },
  // Image container
  imageContainer: {
    width: '50%',
    height:'50%',
    marginBottom: '16px',
    borderRadius: '8px',
    overflow: 'hidden',
  },
  productImage: {
    width: '100%',
    maxHeight: '200px',
    objectFit: 'cover',
  },
  // Action buttons
  actionButtons: {
    display: 'flex',
    gap: '12px',
    marginTop: '16px',
    borderTop: '1px solid #eee',
    paddingTop: '16px',
  },
  actionButton: {
    display: 'flex',
    alignItems: 'center',
    padding: '8px 16px',
    backgroundColor: style.Color.colorGreenGrey,
    color: style.Color.colorWhite, 
    border: 'none',
    borderRadius: '8px',
    cursor: 'pointer',
    fontSize: '14px',
  },
  // Combo Item styles
  comboItemsContainer: {
    marginBottom: '16px',
    padding: '12px',
    backgroundColor: style.Color.colorWhite,
    borderRadius: '8px',
  },
  comboItemsTitle: {
    fontSize: '16px',
    fontWeight: 'bold',
    color: style.Color.colorDarkBlue,
    marginBottom: '12px',
  },
  comboItem: {
    display: 'flex',
    padding: '10px',
    marginBottom: '10px',
    borderBottom: `1px solid ${style.Color.colorLightGrey}`,
    alignItems: 'center',
  },
  comboItemImageContainer: {
    width: '60px',
    height: '60px',
    marginRight: '12px',
    borderRadius: '4px',
    overflow: 'hidden',
    backgroundColor: style.Color.colorLightestBeige,
  },
  comboItemImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  comboItemDetails: {
    flex: 1,
  },
  comboItemTitle: {
    fontSize: '14px',
    fontWeight: '500',
    color: style.Color.colorDarkBlue,
    marginBottom: '4px',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  comboItemPrice: {
    display: 'flex',
    alignItems: 'center',
    gap: '6px',
  },
  comboItemPriceLabel: {
    fontSize: '12px',
    color: style.Color.colorDarkGrey,
  },
  comboItemPriceValue: {
    fontSize: '14px',
    fontWeight: 'bold',
    color: style.Color.colorDarkBlue,
  },
  // Combo price container
  comboPriceContainer: {
    marginTop: '16px',
    padding: '16px',
    backgroundColor: style.Color.colorLightestBeige,
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
  comboPriceItem: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '8px 0',
    borderBottom: `1px solid ${style.Color.colorLightGrey}`,
  },
  comboPriceItemHighlight: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '8px 0',
    backgroundColor: 'rgba(0, 128, 0, 0.1)',
    borderRadius: '4px',
    padding: '10px',
    marginTop: '5px',
  },
  comboPriceLabel: {
    fontSize: '14px',
    color: style.Color.colorDarkGrey,
  },
  comboPriceValue: {
    fontSize: '16px',
    fontWeight: 'bold',
    color: style.Color.colorDarkBlue,
  },
  savingsValue: {
    fontSize: '16px',
    fontWeight: 'bold',
    color: 'green',
  },
  // States
  emptyState: {
    padding: '24px',
    textAlign: 'center',
    backgroundColor: style.Color.colorWhite,
    borderRadius: '8px',
    color: style.Color.colorDarkGrey,
  },
  browseButton: {
    marginTop: '16px',
    padding: '10px 24px',
    backgroundColor: style.Color.colorPeacockBlueDark,
    color: style.Color.colorWhite,
    border: 'none',
    borderRadius: '8px',
    cursor: 'pointer',
    fontSize: '16px',
  },
  errorMessage: {
    color: '#DC2626',
    fontSize: '14px',
    marginTop: '8px',
    padding: '8px',
    backgroundColor: '#FEE2E2',
    borderRadius: '4px',
  },
  loading: {
    padding: '20px',
    textAlign: 'center',
    color: '#666',
  },
  error: {
    padding: '16px',
    color: '#DC2626',
  },
  titleContainer: {
    flex: 1,
    marginRight: '16px',
  },
};

export default FavouriteGroupWishes;