import React from 'react';
import { Edit2 } from 'lucide-react';
import { Color, RoseGoldButton } from "../../../GlobalStyles";

const ComboPreferences = ({ 
  comboData, 
  userJoined, 
  userPreferences, 
  onEditPreferences,
  styles 
}) => { 
  const shouldShowPreferences = comboData?.combo_items && comboData.combo_items.length > 0; 
  if (!shouldShowPreferences) return null;  
  const totalPrefCount = userPreferences?.length || 0; 
  const itemMap = {};
  comboData.combo_items.forEach((item, index) => {
    if (item.group_wish && item.group_wish.product_name) {
      itemMap[index + 1] = item.group_wish.product_name;
    }
  });
  comboData.combo_items.forEach((item) => {
    if (item.group_wish && item.group_wish.product_name) {
      itemMap[item.group_wish.id] = item.group_wish.product_name;
    }
  });
  const getItemName = (preference) => { 
    if (preference.item_index && itemMap[preference.item_index]) {
      return itemMap[preference.item_index];
    } 
    for (const item of comboData.combo_items) {
      if (item.group_wish && item.group_wish.id === preference.item_index) {
        return item.group_wish.product_name;
      }
    } 
    return `Item ${preference.item_index || 'Unknown'}`;
  };

  return (
    <div style={styles.preferencesCard}>
      <h2 style={styles.preferencesTitle}>
        {userJoined ? `Your Selected Preferences (${totalPrefCount})` : 'Available Item Preferences'}
      </h2>
       
      {userJoined && Array.isArray(userPreferences) && userPreferences.length > 0 ? (
        <div style={styles.userPreferencesContainer}>
          {userPreferences.map((pref, prefIndex) => { 
            const itemName = getItemName(pref);
              
            return (
              <div key={`preference-${prefIndex}`} style={styles.userPreferenceItem}>
                <div style={styles.preferenceItemName}>
                  {itemName}
                </div>
                <div style={styles.preferenceDetails}>
                  <span style={styles.preferenceLabel}>
                    {pref.preference_name?.name || 'Preference'}:
                  </span>
                  <span style={styles.preferenceValue}>
                    {pref.custom_preference && pref.custom_value 
                      ? pref.custom_value 
                      : pref.preference_value_text || 'Custom'}
                  </span>
                  <span style={styles.preferenceQuantity}>
                    Qty: {pref.quantity || 1}
                  </span>
                </div>
              </div>
            );
          })}
        </div>
      ) : userJoined ? (
        <p style={styles.noPreferenceSelected}>No preferences selected</p>
      ) : ( 
        comboData.combo_items.map((item, index) => {
          if (!item.group_wish || !item.group_wish.preferences || item.group_wish.preferences.length === 0) {
            return null;
          }

          const itemPreferences = item.group_wish.preferences;
          
          return (
            <div key={`item-${item.group_wish.id}`} style={styles.preferencesItemCard}>
              <h3 style={styles.itemPreferencesTitle}>
                {item.group_wish.product_name}
              </h3>
              
              <div style={styles.availablePreferencesContainer}>
                {itemPreferences.map((pref) => {
                  if (!pref.name || pref.name === "Custom") return null;
                  
                  return (
                    <div key={`avail-pref-${pref.uuid}`} style={styles.preferenceTypeContainer}>
                      <span style={styles.preferenceName}>{pref.name}:</span>
                      <div style={styles.preferenceValuesList}>
                        {pref.values && pref.values.map((val, valIndex) => (
                          <span key={`val-${val.uuid}`} style={styles.preferenceValueChip}>
                            {val.value}
                            {valIndex < pref.values.length - 1 ? '' : ''}
                          </span>
                        ))}
                        {pref.is_custom_allowed && (
                          <span style={{...styles.preferenceValueChip, ...styles.customValueChip}}>
                            Custom
                          </span>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })
      )}
      
      {userJoined && (
        <button onClick={onEditPreferences} style={{...RoseGoldButton, ...styles.editPreferencesButton}} >
          <Edit2 size={18} />
          <span>Edit Preferences</span>
        </button>
      )}
    </div>
  );
};
 
const getPreferencesStyles = () => ({
  preferencesCard: {
    backgroundColor: '#ffffff',
    borderRadius: '8px', 
    padding: '20px',
    marginBottom: '10px',
    maxHeight: '800px',
    overflowY: 'auto',
    scrollbarWidth: 'thin',
    '&::-webkit-scrollbar': {
      width: '6px',
    },
    '&::-webkit-scrollbar-track': {
      background: Color.colorPeacockBlueDark,
      borderRadius: '8px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#888',
      borderRadius: '8px',
    },
  },
  preferencesTitle: {
    fontSize: '20px',
    fontWeight: '600',
    color: Color.colorPeacockBlueDark,
    marginBottom: '20px',
  },
  preferencesItemCard: {
    padding: '16px', 
    backgroundColor: Color.colorLightestBeige,
    borderRadius: '8px',
    marginBottom: '16px',
    border: '1px solid #e5e7eb',
  },
  itemPreferencesTitle: {
    fontSize: '16px',
    fontWeight: '500',
    color: Color.colorPeacockBlueDark,
    marginBottom: '12px',
    borderBottom: `1px solid ${Color.colorLightBeige}`,
    paddingBottom: '18px',
  },
  availablePreferencesContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    maxHeight: '100px',
    overflowY: 'auto',
  },
  preferenceTypeContainer: {
    marginBottom: '8px',
  },
  preferenceName: {
    fontSize: '14px',
    fontWeight: '500',
    color: Color.colorDarkBlue,
    marginRight: '8px',
    display: 'block',
    marginBottom: '4px',
  },
  preferenceValuesList: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '8px',
  },
  preferenceValueChip: {
    display: 'inline-block',
    padding: '4px 10px',
    backgroundColor: '#f3f4f6',
    borderRadius: '16px',
    fontSize: '13px',
    color: Color.colorDarkBlue,
    border: '1px solid #e5e7eb',
  },
  customValueChip: {
    backgroundColor: '#e0f2fe',
    borderColor: '#bae6fd',
  },
  userPreferencesContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    maxHeight: '500px',
    overflowY: 'auto',
    paddingRight: '8px',
    scrollbarWidth: 'thin',
    '&::-webkit-scrollbar': {
      width: '6px',
    },
    '&::-webkit-scrollbar-track': {
      background: '#f1f1f1',
      borderRadius: '8px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#888',
      borderRadius: '8px',
    },
  },
  userPreferenceItem: {
    display: 'flex',
    flexDirection: 'column',
    padding: '12px',
    paddingTop: '25px',
    backgroundColor: Color.colorLightBeige,
    borderRadius: '6px',
    gap: '18px',
    border: '1px solid #e5e7eb',
  },
  preferenceItemName: {
    fontSize: '14px',
    fontWeight: '600',
    color: Color.colorPeacockBlueDark,
    borderBottom: '1px solid #e5e7eb',
    paddingBottom: '6px',
  },
  preferenceDetails: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    gap: '25px',
  },
  preferenceLabel: {
    fontSize: '14px',
    fontWeight: '500',
    color: Color.colorDarkGrey,
  },
  preferenceValue: {
    fontSize: '14px',
    fontWeight: '500',
    color: Color.colorDarkBlue,
    flex: '1',
    backgroundColor: '#ffffff',
    padding: '3px 8px',
    borderRadius: '4px',
    border: '1px solid #e5e7eb',
  },
  preferenceQuantity: {
    fontSize: '13px',
    color: Color.colorDarkGrey,
    backgroundColor: '#f3f4f6',
    padding: '2px 8px',
    borderRadius: '12px',
  },
  noPreferenceSelected: {
    fontSize: '14px',
    color: Color.colorDarkGrey,
    fontStyle: 'italic',
  },
  editPreferencesButton: {
    marginTop: '16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '8px',
    padding: '10px 16px',
    width: '100%',
  },
});

export { ComboPreferences, getPreferencesStyles };