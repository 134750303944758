import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Config from "../../../config"; 
import { useAuth } from '../../../components/Common/InitialGears/AuthContext'; 
import * as style from "../../../GlobalStyles";

const SentInvites = () => {
  const navigate = useNavigate();
  const { queryLoginParam, idTokenLoggedIn } = useAuth();
  const [loading, setLoading] = useState(true);
  const [loadingError, setLoadingError] = useState(false);
  const [groupWishBasedData, setGroupWishBasedData] = useState([]);
  const [userBasedData, setUserBasedData] = useState([]);
  const [expandedItemId, setExpandedItemId] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [viewMode, setViewMode] = useState('group_wish');

  useEffect(() => {
    fetchInvites();
  }, [idTokenLoggedIn]);

  const fetchInvites = async () => {
    setLoading(true);
    let apiUrl = `${Config.apiUrl}externalInvitesSent?${queryLoginParam}`;
    const headers = {
      'Authorization': idTokenLoggedIn,
      'Content-Type': 'application/json',
    };
  
    try {
      const response = await fetch(apiUrl, { headers });
      const fetchedData = await response.json(); 
      if (!response.ok) {
        throw new Error('Failed to fetch invites');
      }

      setGroupWishBasedData(fetchedData.group_wish_based_data || []);
      setUserBasedData(fetchedData.user_based_data || []);
      setLoading(false);
    } catch (error) {
      setLoadingError(true);
      setLoading(false);
    }
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const toggleItem = (itemId) => {
    setExpandedItemId(expandedItemId === itemId ? null : itemId);
  };

  const parseAndFormatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' });
  };

  const renderStatus = (item) => {
    let statusText = '';
    let StatusIcon = null;

    switch (item.recipient_status) {
      case "ON_THE_WAY":
        statusText = "On the way";
        StatusIcon = () => <span style={styles.statusIcon}>📤</span>;
        break;
      case "READ":
        statusText = "Read";
        StatusIcon = () => <span style={styles.statusIcon}>📖</span>;
        break;
      case "DELIVERED":
        statusText = "Delivered";
        StatusIcon = () => <span style={styles.statusIcon}>✅</span>;
        break;
      case "DELIVERY_FAILED":
        statusText = "Delivery Failed";
        StatusIcon = () => <span style={styles.statusIcon}>❌</span>;
        break;
      case "ACCEPTED":
        statusText = "Accepted";
        StatusIcon = () => <span style={styles.statusIcon}>👍</span>;
        break;
      default:
        statusText = item.recipient_status;
        StatusIcon = () => <span style={styles.statusIcon}>❓</span>;
    }

    return (
      <div style={styles.statusContainer}>
        <StatusIcon />
        <span style={styles.statusText}>
          {statusText}
        </span>
      </div>
    );
  };

  const renderGroupWishView = () => (
    <div style={styles.viewContainer}>
      {groupWishBasedData.map((groupWish) => (
        <div key={groupWish.group_wish_id} style={styles.itemWrapper}>
          <button 
            onClick={() => toggleItem(groupWish.group_wish_id)}
            style={styles.itemButton}
          >
            <div style={styles.itemHeader}>
              <span style={styles.itemTitle}>
                {groupWish.group_wish_name}
              </span>
              <span style={styles.expandIcon}>
                {expandedItemId === groupWish.group_wish_id ? '▲' : '▼'}
              </span>
            </div>
          </button>
          
          {expandedItemId === groupWish.group_wish_id && (
            <div style={styles.expandedContent}>
              {groupWish.receivers.map((receiver) => (
                <div key={receiver.user_id} style={styles.subItem}>
                  <div style={styles.subItemContent}>
                    <span style={styles.receiverName}>
                      {receiver.name}
                    </span>
                    <div style={styles.detailsContainer}>
                      <div style={styles.dateContainer}>
                        <span style={styles.dateIcon}>📅</span>
                        <span style={styles.dateText}>
                          {parseAndFormatDate(receiver.invited_date)}
                        </span>
                      </div>
                      {renderStatus(receiver)}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  );

  const renderUserView = () => (
    <div style={styles.viewContainer}>
      {userBasedData.map((user) => (
        <div key={user.user_id} style={styles.itemWrapper}>
          <button 
            onClick={() => toggleItem(user.user_id)}
            style={styles.itemButton}
          >
            <div style={styles.itemHeader}>
              <span style={styles.itemTitle}>
                {user.name}
              </span>
              <span style={styles.expandIcon}>
                {expandedItemId === user.user_id ? '▲' : '▼'}
              </span>
            </div>
          </button>
          
          {expandedItemId === user.user_id && (
            <div style={styles.expandedContent}>
              {/* General Invites */}
              {user.general_invites.map((invite) => (
                <div key={`general-${invite.invited_date}`} style={styles.subItem}>
                  <div style={styles.subItemContent}>
                    <span style={styles.receiverName}>
                      General Invite
                    </span>
                    <div style={styles.detailsContainer}>
                      <div style={styles.dateContainer}>
                        <span style={styles.dateIcon}>📅</span>
                        <span style={styles.dateText}>
                          {parseAndFormatDate(invite.invited_date)}
                        </span>
                      </div>
                      {renderStatus(invite)}
                    </div>
                  </div>
                </div>
              ))}
              
              {/* Group Wish Invites */}
              {user.group_wish_invites.map((invite) => (
                <div key={`wish-${invite.group_wish_id}-${invite.invited_date}`} style={styles.subItem}>
                  <div style={styles.subItemContent}>
                    <span style={styles.receiverName}>
                      {invite.group_wish_name}
                    </span>
                    <div style={styles.detailsContainer}>
                      <div style={styles.dateContainer}>
                        <span style={styles.dateIcon}>📅</span>
                        <span style={styles.dateText}>
                          {parseAndFormatDate(invite.invited_date)}
                        </span>
                      </div>
                      {renderStatus(invite)}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  );

  return (
    <div style={styles.container}>
      <h1 style={styles.heading}>
        Invites I have Sent
      </h1>
      
      {loading ? (
        <div style={styles.loadingContainer}>
          <div style={styles.spinner}></div>
        </div>
      ) : loadingError ? (
        <div style={styles.errorMessage}>
         No data found.
        </div>
      ) : (
        <>
          <div style={styles.toggleContainer}>
            <button onClick={() => setViewMode('group_wish')}  style={{...style.RoseGoldButton,...viewMode === 'group_wish' ? styles.activeToggleButton : styles.toggleButton}} >
              Deals View
            </button>
            <button  onClick={() => setViewMode('user')} style={{...style.RoseGoldButton,...viewMode === 'user' ? styles.activeToggleButton : styles.toggleButton}} >
              User View
            </button>
          </div>

          <div style={styles.searchContainer}>
            <input  type="text" 
              placeholder={viewMode === 'group_wish' ? "Search by group wish name" : "Search by user name"}
              value={searchTerm} 
              onChange={handleSearch} 
              style={styles.searchInput} />
            <button onClick={fetchInvites} style={styles.searchButton}>
              🔍
            </button>
          </div>
          {viewMode === 'group_wish' ? renderGroupWishView() : renderUserView()}
        </>
      )}
    </div>
  );
};

const styles = {
  container: {
    width: '100%',
    padding: '16px',
    borderRadius: '8px',
    backgroundColor: style.Color.colorLightBeige
  },
  heading: {
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: '24px',
    color: style.Color.colorPeacockBlueDark
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: '32px 0'
  },
  spinner: {
    width: '32px',
    height: '32px',
    borderWidth: '4px',
    borderRadius: '50%',
    borderStyle: 'solid',
    borderColor: style.Color.colorMidBeige,
    borderTopColor: style.Color.colorDarkBeige,
    animation: 'spin 1s linear infinite'
  },
  errorMessage: {
    textAlign: 'center',
    padding: '16px 0',
    color: style.Color.colorRed
  },
  toggleContainer: {
    display: 'flex',
    justifyContent: 'center',
    gap: '16px',
    marginBottom: '24px'
  },
  toggleButton: {
    padding: '8px 16px',
    borderRadius: '8px',
    fontWeight: '500',
    transition: 'all 0.3s',
    backgroundColor: style.Color.colorLightBeige,
    color: style.Color.colorDarkBrown,
    border: 'none',
    cursor: 'pointer'
  },
  activeToggleButton: {
    padding: '8px 16px',
    borderRadius: '8px',
    fontWeight: '500',
    transition: 'all 0.3s',
    backgroundColor: style.Color.colorPurple,
    color: 'white',
    border: 'none',
    cursor: 'pointer'
  },
  searchContainer: {
    display: 'flex',
    gap: '8px',
    marginBottom: '24px'
  },
  searchInput: {
    flex: 1,
    padding: '8px 16px',
    borderRadius: '8px',
    border: '1px solid',
    borderColor: style.Color.colorMidBeige,
    backgroundColor: 'white'
  },
  searchButton: {
    padding: '8px 16px',
    borderRadius: '8px',
    transition: 'all 0.3s',
    backgroundColor: style.Color.colorWhite,
    color: 'white',
    border: 'none',
    cursor: 'pointer'
  },
  viewContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px'
  },
  itemWrapper: {
    borderRadius: '8px',
    padding: '16px',
    backgroundColor: style.Color.colorLightBeige
  },
  itemButton: {
    width: '100%',
    textAlign: 'left',
    background: 'none',
    border: 'none',
    padding: 0,
    cursor: 'pointer'
  },
  itemHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '16px',
    borderRadius: '8px',
    backgroundColor: style.Color.colorWhite
  },
  itemTitle: {
    fontSize: '18px',
    fontWeight: '500',
    color: style.Color.colorRoyalBlueText
  },
  expandIcon: {
    color: style.Color.colorPurpleBlue
  },
  expandedContent: {
    marginTop: '16px'
  },
  subItem: {
    marginBottom: '16px',
    padding: '16px',
    borderRadius: '8px',
    backgroundColor: style.Color.colorWhite
  },
  subItemContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  receiverName: {
    fontSize: '18px',
    fontWeight: '500',
    color: style.Color.colorDarkBrown
  },
  detailsContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '16px'
  },
  dateContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px'
  },
  dateIcon: {
    fontSize: '18px'
  },
  dateText: {
    fontSize: '14px',
    color: style.Color.colorDarkBeige
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px'
  },
  statusIcon: {
    fontSize: '18px'
  },
  statusText: {
    fontSize: '14px',
    color: style.Color.colorDarkBeige
  }
};

export default SentInvites;