import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../Common/InitialGears/AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

const VerificationAlert = () => {
  const { userVerified } = useAuth();
  const navigate = useNavigate();

  if (userVerified) {
    return null;
  }

  const handleClick = () => {
    navigate('/settings');
  };

  const styles = {
    alertContainer: {
      position: 'fixed',
      top: '9%',
      left: 0,
      right: 0,
      zIndex: 50,
      backgroundColor: '#FEF3C7', 
      borderBottom: '1px solid #F59E0B',  
      cursor: 'pointer',
      padding: '12px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      transition: 'background-color 0.2s ease',
    },
    alertContent: {
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
      color: '#92400E',  
      fontWeight: 500,
      fontSize: '14px',
    },
    icon: {
      color: '#92400E',  
      fontSize: '16px',
    }
  };

  return (
    <div 
      style={styles.alertContainer} 
      onClick={handleClick}
      onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#FEF9C3'} // Lighter yellow on hover
      onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#FEF3C7'}
    >
      <div style={styles.alertContent}>
        <FontAwesomeIcon icon={faExclamationTriangle} style={styles.icon} />
        Please verify your account for a seamless experience. Click here to verify.
      </div>
    </div>
  );
};

export default VerificationAlert;