import React from 'react';
import { Package2, AlertTriangle, TrendingUp, TrendingDown } from 'lucide-react';

const ShippingOptions = ({ 
  box, 
  selectedZone, 
  allZoneRates, 
  rateErrors, 
  isLoadingRates,
  showRanges = true 
}) => {
  if (isLoadingRates) {
    return (
      <div style={shippingOptionsContainerStyle}>
        <h4 style={shippingOptionsHeaderStyle}>
          <Package2 style={iconStyle} /> Shipping Options
        </h4>
        <div style={loadingStyle}>
          <div className="spinner"></div>
          <p>Loading shipping rates...</p>
        </div>
      </div>
    );
  }

  // If no box or rates, or error occurred
  if (!box || (!allZoneRates && rateErrors)) {
    return (
      <div style={shippingOptionsContainerStyle}>
        <h4 style={shippingOptionsHeaderStyle}>
          <AlertTriangle style={{...iconStyle, color: '#ffc107'}} /> Shipping Options
        </h4>
        <div style={errorMessageStyle}>
          <p>
            {rateErrors ? 
              `Unable to retrieve shipping rates: ${rateErrors}` : 
              'No shipping options available.'
            }
          </p>
          <p style={errorSubtextStyle}>
            Please check your package details or try again later.
          </p>
        </div>
      </div>
    );
  }
 
  const processShippingOptions = (zoneData) => {
    if (!zoneData || !zoneData.shipping_options || !zoneData.shipping_options.all_rates) {
      return [];
    }

    const options = zoneData.shipping_options.all_rates;
    return [
      ...(options.ground || []),
      ...(options.priority || []),
      ...(options.express || [])
    ].map(rate => ({
      ...rate,
      zoneName: zoneData.name
    }));
  };
 
  const allOptions = allZoneRates ? 
    Object.entries(allZoneRates).flatMap(([zoneKey, zoneData]) => 
      processShippingOptions(zoneData).map(option => ({
        ...option,
        zoneKey
      }))
    ) : 
    [];
 
  const sortedOptions = allOptions.sort((a, b) => a.rate - b.rate); 
  if (sortedOptions.length === 0) {
    return (
      <div style={shippingOptionsContainerStyle}>
        <h4 style={shippingOptionsHeaderStyle}>
          <AlertTriangle style={{...iconStyle, color: '#ffc107'}} /> Shipping Options
        </h4>
        <div style={errorMessageStyle}>
          <p>No shipping options available for this package.</p>
          <p style={errorSubtextStyle}>
            Please verify package details or contact support.
          </p>
        </div>
      </div>
    );
  }

  return (
    <div style={shippingOptionsContainerStyle}>
      <h4 style={shippingOptionsHeaderStyle}>
        <Package2 style={iconStyle} /> Shipping Options
      </h4>
      
      <div style={topOptionsContainerStyle}> 
        {sortedOptions.slice(0, 3).map((option, index) => (
          <div 
            key={index} 
            style={{
              ...topOptionItemStyle,
              backgroundColor: index === 0 ? '#d4edda' : '#f8f9fa',
              border: index === 0 ? '1px solid #c3e6cb' : '1px solid #dee2e6'
            }}
          >
            <div style={optionRankStyle}>#{index + 1}</div>
            <div style={optionDetailsStyle}>
              <div style={optionNameStyle}>
                {option.service_type} ({option.zoneName})
              </div>
              <div style={optionInfoStyle}>
                Transit: {option.delivery_days} day{option.delivery_days !== 1 ? 's' : ''} • 
                Carrier: {option.carrier}
              </div>
            </div>
            <div style={optionPriceStyle}>${option.rate.toFixed(2)}</div>
          </div>
        ))}
      </div>
      
      <div style={allOptionsContainerStyle}>
        <h5 style={zoneOptionsTitleStyle}>All Options by Zone:</h5>
        {Object.entries(allZoneRates || {}).map(([zoneKey, zoneData]) => (
          <div key={zoneKey} style={zoneOptionsGroupStyle}>
            <h6 style={zoneOptionsHeaderStyle}>
              {zoneData.name}
              <span style={zoneHighlightStyle}>{zoneKey === selectedZone ? ' (Selected Zone)' : ''}</span>
            </h6>
            
            {/* Display price ranges for employees if available and showRanges is true */}
            {showRanges && zoneData.shipping_options.rate_ranges && (
              <div style={rangeContainerStyle}>
                {Object.entries(zoneData.shipping_options.rate_ranges).map(([serviceType, rangeData]) => (
                  <div key={serviceType} style={rangeItemStyle}>
                    <div style={rangeHeaderStyle}>
                      <span style={rangeServiceTypeStyle}>{serviceType.charAt(0).toUpperCase() + serviceType.slice(1)}</span>
                      <span style={rangeDeliveryStyle}>Transit: {rangeData.delivery_days_range} days</span>
                    </div>
                    <div style={rangePriceContainerStyle}>
                      <div style={rangeMinStyle}>
                        <TrendingDown size={16} style={{color: '#28a745', marginRight: '4px'}} />
                        <span>${parseFloat(rangeData.min_rate).toFixed(2)}</span>
                        <span style={rangeCarrierStyle}>{rangeData.min_carrier}</span>
                      </div>
                      <div style={rangeMaxStyle}>
                        <TrendingUp size={16} style={{color: '#dc3545', marginRight: '4px'}} />
                        <span>${parseFloat(rangeData.max_rate).toFixed(2)}</span>
                        <span style={rangeCarrierStyle}>{rangeData.max_carrier}</span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
            
            <div style={zoneOptionsItemsStyle}>
              {processShippingOptions(zoneData).map((option, index) => (
                <div 
                  key={index} 
                  style={{
                    ...zoneOptionItemStyle,
                    backgroundColor: zoneKey === selectedZone ? '#f0f8ff' : '#ffffff',
                    border: zoneKey === selectedZone ? '1px solid #b8daff' : '1px solid #dee2e6'
                  }}
                >
                  <div style={zoneOptionMethodStyle}>{option.service_type}</div>
                  <div style={zoneOptionTransitStyle}>
                    Transit: {option.delivery_days} day{option.delivery_days !== 1 ? 's' : ''}
                  </div>
                  <div style={zoneOptionCarrierStyle}>
                    Carrier: {option.carrier}
                  </div>                     
                  <div style={zoneOptionPriceStyle}>${option.rate.toFixed(2)}</div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

// Original Styles
const shippingOptionsContainerStyle = {
  marginTop: '20px',
  padding: '15px',
  backgroundColor: '#f8f9fa',
  borderRadius: '4px',
  border: '1px solid #dee2e6',
};

const iconStyle = {
  marginRight: '10px',
  width: '20px',
  height: '20px',
  verticalAlign: 'middle',
};

const loadingStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '20px',
};

const shippingOptionsHeaderStyle = {
  fontSize: '16px',
  fontWeight: 'bold',
  marginBottom: '15px',
  color: '#0056b3',
  display: 'flex',
  alignItems: 'center',
};

const errorMessageStyle = {
  backgroundColor: '#fff3cd',
  color: '#856404',
  padding: '15px',
  borderRadius: '4px',
  textAlign: 'center',
};

const errorSubtextStyle = {
  fontSize: '12px',
  marginTop: '10px',
  color: '#6c757d',
};

const topOptionsContainerStyle = {
  marginBottom: '20px',
};

const topOptionItemStyle = {
  display: 'flex',
  alignItems: 'center',
  padding: '10px 15px',
  borderRadius: '4px',
  marginBottom: '8px',
};

const optionRankStyle = {
  width: '30px',
  height: '30px',
  borderRadius: '50%',
  backgroundColor: '#28a745',
  color: 'white',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontWeight: 'bold',
  marginRight: '12px',
  flexShrink: 0,
};

const optionDetailsStyle = {
  flex: 1,
};

const optionNameStyle = {
  fontSize: '15px',
  fontWeight: 'bold',
  marginBottom: '3px',
};

const optionInfoStyle = {
  fontSize: '13px',
  color: '#6c757d',
};

const optionPriceStyle = {
  fontSize: '18px',
  fontWeight: 'bold',
  color: '#28a745',
};

const allOptionsContainerStyle = {
  borderTop: '1px solid #dee2e6',
  paddingTop: '15px',
};

const zoneOptionsTitleStyle = {
  fontSize: '15px',
  fontWeight: 'bold',
  marginBottom: '10px',
  color: '#343a40',
};

const zoneOptionsGroupStyle = {
  marginBottom: '15px',
};

const zoneOptionsHeaderStyle = {
  fontSize: '14px',
  fontWeight: 'bold',
  marginBottom: '8px',
  color: '#343a40',
};

const zoneHighlightStyle = {
  color: '#28a745',
  fontWeight: 'normal',
};

const zoneOptionsItemsStyle = {
  display: 'flex',
  gap: '10px',
  flexWrap: 'wrap',
};

const zoneOptionItemStyle = {
  padding: '10px',
  borderRadius: '4px',
  width: 'calc(33.33% - 8px)',
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'column',
};

const zoneOptionMethodStyle = {
  fontSize: '14px',
  fontWeight: 'bold',
  marginBottom: '5px',
};

const zoneOptionTransitStyle = {
  fontSize: '12px',
  marginBottom: '3px',
};

const zoneOptionCarrierStyle = {
  fontSize: '12px',
  marginBottom: '5px',
};

const zoneOptionPriceStyle = {
  fontSize: '16px',
  fontWeight: 'bold',
  marginTop: 'auto',
  color: '#28a745',
};

// New styles for price ranges
const rangeContainerStyle = {
  marginBottom: '10px',
  backgroundColor: '#f0f8ff',
  border: '1px solid #cce5ff',
  borderRadius: '4px',
  padding: '10px',
};

const rangeItemStyle = {
  marginBottom: '10px',
  borderBottom: '1px solid #e9ecef',
  paddingBottom: '10px',
};

const rangeHeaderStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '5px',
};

const rangeServiceTypeStyle = {
  fontWeight: 'bold',
  color: '#0056b3',
};

const rangeDeliveryStyle = {
  fontSize: '12px',
  color: '#6c757d',
};

const rangePriceContainerStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
};

const rangeMinStyle = {
  display: 'flex',
  alignItems: 'center',
  fontSize: '14px',
  color: '#28a745',
};

const rangeMaxStyle = {
  display: 'flex',
  alignItems: 'center',
  fontSize: '14px',
  color: '#dc3545',
};

const rangeCarrierStyle = {
  marginLeft: '5px',
  fontSize: '12px',
  color: '#6c757d',
  fontStyle: 'italic',
};

export default ShippingOptions;