// ProductDetailsCard.js
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MediaGallery from './MediaGallery';
import { faImage, faTag, faDollarSign, faUsers } from '@fortawesome/free-solid-svg-icons';
import * as style from "../../../GlobalStyles";

const ProductDetailsCard = ({ 
  productName, 
  imageUrl, 
  category, 
  marketPrice, 
  groupSize, 
  expiryDate, 
  daysLeft,
  additionalMedia 
}) => {
  return (
    <div style={styles.cardContainer}> 
      <h2 style={styles.productTitle}>{productName}</h2>
      
      {/* Main Content Grid */}
      <div style={styles.mainGrid}> 
        <div style={styles.mediaContainer}>
          {imageUrl || (additionalMedia && additionalMedia.length > 0) ? (
            <MediaGallery  mainImageUrl={imageUrl}  additionalMedia={additionalMedia || []}  />
          ) : (
            <div style={styles.placeholderContainer}>
              <FontAwesomeIcon icon={faImage} size="3x" style={styles.placeholderIcon} />
              <p style={styles.placeholderText}>Image not available</p>
            </div>
          )}
        </div>
 
        <div style={styles.detailsContainer}>
          {/* Time Information */}
          <div style={styles.timeContainer}>
            <div style={styles.expiryDate}>
              Valid until {expiryDate}
            </div>
            
            {/* Days Left Circle */}
            <div style={styles.daysLeftCircle}>
              <span style={styles.daysNumber}>{daysLeft}</span>
              <span style={styles.daysText}>Days Left</span>
            </div>
          </div>
 
          <div style={styles.detailsList}> 
            <DetailItem  icon={faTag}  label="Category"   value={category}  /> 
            <DetailItem  icon={faDollarSign}  label="Market Price Per Item"  value={`$${marketPrice}`}  />
            <DetailItem icon={faUsers}  label="Minimum Group Size"  value={groupSize} />
          </div>
        </div>
      </div>
    </div>
  );
};

const DetailItem = ({ icon, label, value }) => (
  <div style={styles.detailItem}>
    <div style={styles.iconContainer}>
      <FontAwesomeIcon icon={icon} size="lg" style={styles.icon} />
    </div>
    <div style={styles.detailContent}>
      <p style={styles.detailLabel}>{label}</p>
      <p style={styles.detailValue}>{value}</p>
    </div>
  </div>
);

const styles = {
  cardContainer: {
    backgroundColor: style.Color.colorWhite,
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    padding: '24px',
    margin: '10px'
  },
  productTitle: {
    fontSize: '20px',
    fontWeight: 'bold',
    color: style.Color.colorPeacockBlueDark,
    marginBottom: '24px'
  },
  mainGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: '32px',
    '@media (max-width: 768px)': {
      gridTemplateColumns: '1fr'
    }
  },
  mediaContainer: { 
    borderRadius: '8px', 
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '240px'
  },
  placeholderContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: '#9CA3AF'
  },
  placeholderIcon: {
    marginBottom: '8px'
  },
  placeholderText: {
    marginTop: '8px'
  },
  detailsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px'
  },
  timeContainer: {
    position: 'relative',
    marginBottom: '20px',
  },
  expiryDate: {    
    backgroundColor: style.Color.colorWhite, 
    color: style.Color.colorPeacockBlueDark,
    border: `2px solid ${style.Color.colorPeacockBlueDark}`,
    borderRadius: '8px',
    padding: '12px', 
    fontSize: '16px',
    animation: 'pulse 2s infinite'
  },
  daysLeftCircle: {
    position: 'absolute',
    top: '-46px',
    right: '16px',
    width: '64px',
    height: '64px',
    backgroundColor: style.Color.colorPeacockBlueDark,
    borderRadius: '50%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: '0 2px 4px ${style.Color.colorPeacockBlueDark}',
    border: '2px solid ${style.Color.colorLimeGreen}',
    animation: 'pulse 2s infinite'
  },
  daysNumber: {
    fontSize: '20px',
    fontWeight: 'bold',
    color: style.Color.colorWhite
  },
  daysText: {
    fontSize: '10px',
    color: style.Color.colorWhite
  },
  detailsList: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px'
  },
  detailItem: {
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
    padding: '12px',
    backgroundColor: style.Color.colorLightBeige,
    borderRadius: '8px'
  },
  iconContainer: {
    width: '40px',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    backgroundColor: '#FFFFFF'
  },
  icon: {
    color: style.Color.colorRoseGoldText
  },
  detailContent: {
    flex: 1
  },
  detailLabel: {
    fontSize: '14px',
    color: '#6B7280',
    marginBottom: '4px'
  },
  detailValue: {
    fontSize: '16px',
    fontWeight: '600',
    color: '#1E3A8A'
  }
};

// MediaGallery.js
const mediaGalleryStyles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '42rem',
    margin: '0 auto'
  },
  mainDisplay: {
    width: '100%',
    height: '384px',
    backgroundColor: '#FFFFFF',
    borderRadius: '8px',
    marginBottom: '16px',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid #E5E7EB',
    boxShadow: '0 1px 2px rgba(0, 0, 0, 0.05)'
  },
  mainImage: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    padding: '16px'
  },
  video: {
    width: '100%',
    height: '100%',
    objectFit: 'contain'
  },
  thumbnailStrip: {
    width: '100%',
    overflowX: 'auto'
  },
  thumbnailContainer: {
    display: 'flex',
    gap: '16px',
    padding: '8px',
    justifyContent: 'center'
  },
  thumbnail: {
    flexShrink: 0,
    width: '80px',
    height: '80px',
    borderRadius: '6px',
    overflow: 'hidden',
    cursor: 'pointer',
    border: '2px solid #E5E7EB',
    transition: 'all 0.2s'
  },
  thumbnailActive: {
    border: '2px solid #2563EB',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
  },
  thumbnailImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    padding: '4px'
  },
  videoThumbnailContainer: {
    position: 'relative',
    width: '100%',
    height: '100%'
  },
  videoOverlay: {
    position: 'absolute',
    inset: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.3)'
  },
  playButton: {
    width: '32px',
    height: '32px',
    borderRadius: '50%',
    border: '2px solid #FFFFFF',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  playIcon: {
    width: 0,
    height: 0,
    borderTop: '6px solid transparent',
    borderLeft: '12px solid #FFFFFF',
    borderBottom: '6px solid transparent',
    marginLeft: '4px'
  }
};

// Add styles to the document
const styleSheet = document.createElement("style");
styleSheet.type = "text/css";
styleSheet.innerText = `
  @keyframes pulse {
    0% { transform: scale(1); }
    50% { transform: scale(1.05); }
    100% { transform: scale(1); }
  }
`;
document.head.appendChild(styleSheet);

export { ProductDetailsCard, MediaGallery, styles, mediaGalleryStyles }