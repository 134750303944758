import React, { useState, useEffect } from 'react';   
import * as style from "../../../GlobalStyles";  
import JoinGroupWishModal from './JoinGroupWishModal';

const calculateTotalQuantity = (preferences) => {
  if (!preferences || !Array.isArray(preferences)) {
    return 0;
  } 
  const uniqueIndices = new Set(preferences.map(p => p.item_index));
  return uniqueIndices.size;
};

const UserPreferencesDisplay = ({ preferences = [], onEditPreferences, isJoined = false, wish }) => { 
  const [expandedGroup, setExpandedGroup] = useState(null);
  const [displayPreferences, setDisplayPreferences] = useState(preferences);
  const totalQuantity = calculateTotalQuantity(preferences);
  const [showEditModal, setShowEditModal] = useState(false);
  
  useEffect(() => {
    setDisplayPreferences(preferences);
  }, [preferences]);

  const handleEditClick = () => { 
    if (wish?.id && preferences) { 
      setShowEditModal(true);
    }  
  };

  const handleEditClose = () => {
    setShowEditModal(false);
    if (onEditPreferences) {
      onEditPreferences();
    }
  };

  const toggleGroup = (index) => {
    setExpandedGroup(expandedGroup === index ? null : index);
  };  
  
  const groupedPreferences = preferences.reduce((acc, pref) => {
    const index = pref.item_index || 0;
    if (!acc[index]) {
      acc[index] = {
        preferences: {},
        quantity: pref.quantity
      };
    }
    if (!acc[index].preferences[pref.name]) {
      acc[index].preferences[pref.name] = [];
    }
    acc[index].preferences[pref.name].push(pref);
    return acc;
  }, {});
 
  
  return (
    <div className="preferences-card">
      <div className="preferences-header">
        <h2 className="preferences-title">My Preferences</h2>
        <button onClick={handleEditClick} className="edit-button">
          Edit Preferences
        </button>
      </div>
      <div className="preferences-content">
        <div className="preferences-groups">
        {Object.entries(groupedPreferences).map(([groupIndex, group], index) => (
            <div key={groupIndex} className="preference-group">
              <div className="group-header" onClick={() => toggleGroup(index)}>
                <h3 className="group-title">My Choice  set: {groupIndex}</h3>
                <div className="quantity-info">
                  For Quantity: {group.quantity}
                </div>
                <span className="expand-icon">
                  {expandedGroup === index ? '▼' : '▶'}
                </span>
              </div>
              
              {expandedGroup === index && (
                <div className="group-content">
                  <div className="preferences-list">
                    {Object.entries(group.preferences).map(([name, items]) => (
                      <div key={name} className="preference-item">
                        <div className="preference-details">
                          <span className="preference-name">{name}:</span>
                          <div className="preference-values">
                            {items.map((item, i) => (
                              <div key={i} className="preference-value">
                                {item.selectedValue.value}
                                {item.selectedValue.hasAdditionalCharges && (
                                  <span className="additional-charges">
                                    +${item.selectedValue.additionalChargesAmount}
                                  </span>
                                )}
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
        {showEditModal && wish && (
        <JoinGroupWishModal
          isOpen={true}
          onClose={handleEditClose}
          wish={{
            wish: {
              id: wish.id,
              product_name: wish.product_name,
              expires_at: wish.expires_at
            }
          }}
          existingPreferences={preferences}
          defaultQuantity={totalQuantity}
          isEditMode={true}
          onJoinSuccess={() => {
            handleEditClose(); 
          }}
        />
      )}
    </div>
        
    <div className="preferences-note">
          <p>Note: Your preferences are subject to availability and will be accommodated to the best possible extent.</p>
    </div>
</div> 
  );
};
 
const styles = `
  .preferences-card {
    background-color: ${style.Color.colorWhite};
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    overflow: hidden;
  }

  .preferences-header {
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: ${style.Color.colorWhite};
    border-bottom: 1px solid ${style.Color.colorLightestGrey};
  }

  .preferences-title {
    font-size: 18px;
    font-weight: 600;
    color: ${style.Color.colorRoyalBlueText};
    margin: 0;
  }

  .edit-button {
    background-color: ${style.Color.colorWhite}; 
    border: '2px solid ${style.Color.colorPeacockBlue}',
    padding: 8px 16px;
    border-radius: 8px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.2s;
  }
 

  .preferences-content {
    padding: 16px;
  }

  .empty-preferences {
    display: flex;
    align-items: center;
    gap: 8px;
    color: ${style.Color.colorGray};
    font-size: 14px;
    padding: 16px;
    background-color: ${style.Color.colorLightestBeige};
    border-radius: 4px;
    margin-bottom: 16px;
  }

  .alert-icon {
    font-size: 20px;
  }

  .preferences-groups {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  .preference-group {
    border: 1px solid ${style.Color.colorWhite};
    border-radius: 6px;
    overflow: hidden;
  }

  .group-header {
    padding: 12px;
    background-color: ${style.Color.colorWhite};
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.2s;
  }

  .group-header:hover {
    background-color: ${style.Color.colorWhite};
  }

  .group-title {
    font-size: 14px;
    font-weight: 600;
    color: ${style.Color.colorGray};
    margin: 0;
  }

  .expand-icon {
    color: ${style.Color.colorPurple};
  }

  .group-content {
    padding: 12px;
    background-color: ${style.Color.colorWhite};
    border-top: 1px solid ${style.Color.colorWhite};
  }

  .preferences-list {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .preference-item {
    padding: 12px;
    background-color: ${style.Color.colorWhite};
    border-radius: 8px;
    border-color: ${style.Color.colorLightestGrey};
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .preference-details {
    display: flex;
    gap: 8px;
    align-items: left;
    padding-left: 30px;
  }

  .preference-name { 
    flex: 1;
    font-weight: bold;
    font-size: 14px;
    text-align: left;
    color: #555;
  }

  .preference-value {
    color: ${style.Color.colorPurpleBlue};
    flex: 1; 
    font-size: 14px;
    text-align: left;
    border-radius: 8px;
  }

  .additional-charges {
    color: ${style.Color.colorVibrantRed}; 
    padding: 6px 3px; 
    border-radius: 8px;
    flex: 1;
    font-weight: bold;
    font-size: 14px;
    text-align: right;
    background-color: transparent;
  } 

  .quantity-info {
    margin-top: 12px;
    padding: 8px; 
    border: '2px solid ${style.Color.colorGray}',
    border-radius: 8px;
    font-size: 14px;
    color: ${style.Color.colorGray};
  }

  .preferences-note {
    margin-top: 16px;
    padding: 12px;
    background-color: ${style.Color.colorLightBeige};
    border-radius: 4px;
    font-size: 14px;
    color: ${style.Color.colorDarkGrey};
    border-left: 4px solid ${style.Color.colorMintGreen};
  }
`;

// Add styles to document
const styleSheet = document.createElement("style");
styleSheet.type = "text/css";
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);
export default UserPreferencesDisplay;