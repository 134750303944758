import React, { useState, useEffect, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCreditCardAlt } from '@fortawesome/free-solid-svg-icons';
import { Calculator, Package } from 'lucide-react';
import { Link } from 'react-router-dom';
import { Color, RoseGoldButton } from "../../../GlobalStyles";
import { getApi, postApi, buildUrl } from "../../Common/BackendCalls/apiUtils";
import AddressSection from '../../Common/AddressSection';

const ComboJoinModal = ({ isOpen,onClose, comboData, onCalculate,onJoin, queryLoginParam, userRejoining, callJoinApis,referralCode = '', }) => { 
  const [comboQuantity, setComboQuantity] = useState(1);
  const [addresses, setAddresses] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [selectedAddressId, setSelectedAddressId] = useState(null);
  const [showAddressSection, setShowAddressSection] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [useRedeemCredit, setUseRedeemCredit] = useState(false);
  const [availableCredit, setAvailableCredit] = useState(0);
  const [calculatedCosts, setCalculatedCosts] = useState(null);
  const [quantities, setQuantities] = useState({});
  const [isCalculating, setIsCalculating] = useState(false);
  const [referralCodeValue, setReferralCodeValue] = useState(referralCode || '');

  useEffect(() => {
    if (isOpen) {
      fetchAddresses();
      fetchAvailableCredit(); 
      if (comboData?.combo_items && !Object.keys(quantities).length) {
        const initialQuantities = {};
        comboData.combo_items.forEach(item => {
          initialQuantities[item.id] = 1;
        });
        setQuantities(initialQuantities);
        if (referralCode) {
          setReferralCodeValue(referralCode);
        }
      }
    }
  }, [isOpen, comboData]);

  const fetchAddresses = useCallback(async () => {
    setIsLoading(true);
    try {
      const addressesUrl = buildUrl('address', `${queryLoginParam}&type=completed`);
      const response = await getApi(addressesUrl);      
      if (response.ok) {
        const data = response.data;
        const primaryAddress = data.primary_address ? [data.primary_address] : [];
        const otherAddresses = Array.isArray(data.other_addresses) ? data.other_addresses : []; 
        setAddresses([...primaryAddress, ...otherAddresses]); 
        if (data.primary_address) {
          setSelectedAddress(data.primary_address);
          setSelectedAddressId(data.id);
        } else if (otherAddresses.length > 0) {
          setSelectedAddress(otherAddresses[0]);
          setSelectedAddressId(otherAddresses[0].id);
        }
      } else { 
        setAddresses([]);
      }
    } catch (error) { 
      setAddresses([]);
    } finally {
      setIsLoading(false);
    }
  }, [queryLoginParam]);

  const fetchAvailableCredit = useCallback(async () => {
    try {
      const creditUrl = buildUrl('creditDetails', queryLoginParam);
      const response = await getApi(creditUrl);      
      if (response.ok) {
        setAvailableCredit(response.data.availableCredit);
      } else {
        setAvailableCredit(0);
      }
    } catch (error) {
      setAvailableCredit(0);
    }
  }, [queryLoginParam]);

  const formatAddress = (address) => {
    if (!address) return '';
    return `${address.address1}, ${address.city}, ${address.state} ${address.postal_code}`;
  };

  const handleAddressChange = (e) => {
    const value = e.target.value;
    if (value === 'add_new') {
      setShowAddressSection(true);
      setSelectedAddress(null);
      setSelectedAddressId(null);
    } else if (value) {
      setSelectedAddress(addresses.find(addr => addr.id && addr.id.toString() === value));
      setSelectedAddressId(addresses.find(addr => addr.id && addr.id.toString()));
      setShowAddressSection(false);
    } else {
      setSelectedAddress(null);
      setSelectedAddressId(null);
      setShowAddressSection(false);
    }
  };

  const handleAddressSave = useCallback(async (newAddress) => { 
    setIsLoading(true);
    const addressUrl = buildUrl('address', queryLoginParam);
    const requestBody = {
      address1: newAddress.address1,
      address2: newAddress.address2,
      city: newAddress.city,
      state: newAddress.state,
      country: newAddress.country,
      postal_code: newAddress.postal_code,
      primary_residence: false,
    };     
    try {
      const response = await postApi(addressUrl, requestBody);
      if (response.ok) {
        setAddresses(prevAddresses => [...prevAddresses, response.data]);  
        setSelectedAddress(response.data);
        setSelectedAddressId(response.data.id);
        setShowAddressSection(false);
        await fetchAddresses();
      } else {
        setErrorMessage('Failed to update address. Please try again.');
      }
    } catch (error) { 
      setErrorMessage('Failed to update address. Please try again.');
    } finally {
      setIsLoading(false);
    }
  }, [queryLoginParam]);

  const calculateTotalItems = () => {
    return Object.values(quantities).reduce((sum, qty) => sum + qty, 0) * comboQuantity;
  };

  const calculateItemTotal = (item, quantity) => { 
    if (!item || !item.group_wish) return 0;    
    const details = item.group_wish;
    const itemDiscount = parseFloat(item.item_discount || 0);
    const comboPrice = details.list_price - itemDiscount;
    return comboPrice * quantity * comboQuantity; 
  };

  const calculateShippingCost = async (quantity, address) => {
    setIsLoading(true);    
    try { 
      const packagingDetails = [];
      if (comboData?.packaging_details && comboData.packaging_details.box_details) { 
        packagingDetails.push(...comboData.packaging_details.box_details.map(box => ({
          ...box,
          product_id: comboData.id
        })));
      }         
      const total_boxes = comboData?.packaging_details?.total_boxes || packagingDetails.length;         
      const box_details = packagingDetails.map(box => ({
        length: box.dimensions.length,
        width: box.dimensions.width,
        height: box.dimensions.height,
        weight: box.weight.total_weight,
        unit_length: box.dimensions.unit || 'inch',
        unit_weight: box.weight.unit || 'lb'
      }));        
      const shippingCalculateUrl = buildUrl('getComboShippingRate', queryLoginParam);
      const requestBody = {
        grp_wish_id: comboData.id,
        comboShipping: true,
        destination_zip: address.postal_code,
        quantity: quantity,
        total_boxes: total_boxes,
        box_details: box_details
      };        
      const response = await postApi(shippingCalculateUrl, requestBody);        
      if (response && response.data) {
        return {
          rate: parseFloat(response.data.rate),
          delivery_days: response.data.delivery_days,
          service_type: response.data.service_type
        };
      } 
      return {
        rate: 20.00,
        delivery_days: "5 days",
        service_type: "Standard Ground"
      };
    } catch (error) { 
      return {
        rate: 20.00,
        delivery_days: "5 days",
        service_type: "Standard Ground"
      };
    } finally {
      setIsLoading(false);
    }
  };
 

  const handleComboQuantityChange = (e) => {
    const value = Math.max(1, parseInt(e.target.value) || 1);
    setComboQuantity(value);
  };

  const joinComboApiActions = async (userRejoining,) => {
    try {  
      if(userRejoining){
        const rejoinComboUrl = buildUrl('reJoinCombo', queryLoginParam);
        const comboRequestBody = {
          combo_id: comboData.id,
          quantity: comboQuantity,
          total_cost: calculatedCosts?.total || 0,
          address_id: selectedAddressId,
          use_redeem_credit: useRedeemCredit,
          referral_code: referralCodeValue
        };
        const comboResponse = await postApi(rejoinComboUrl, comboRequestBody);          
        if (!comboResponse.ok) {
          throw new Error('Failed to re-join combo');
        }                   
    }else{
      const joinComboUrl = buildUrl('joinCombo', queryLoginParam);
      const comboRequestBody = {
        combo_id: comboData.id,
        quantity: comboQuantity,
        total_cost: calculatedCosts?.total || 0,
        address_id: selectedAddressId,
        use_redeem_credit: useRedeemCredit,
        referral_code: referralCodeValue
      };
  
      const comboResponse = await postApi(joinComboUrl, comboRequestBody);          
      if (!comboResponse.ok) {
        throw new Error('Failed to join combo');
      } 
    }
    } catch (error) { 
      setErrorMessage('Failed to join combo. Please try again.');
    } finally {
      setIsLoading(false);
    }
  }


  const handleJoin = async() => {
    if (!selectedAddress) {
      setErrorMessage('Please select a delivery address first.');
      return;
    }    
    if (!calculatedCosts) {
      setErrorMessage('Please calculate your total costs before proceeding.');
      return;
    }   
    if (callJoinApis){ 
      setIsLoading(true);
      await joinComboApiActions(userRejoining);
    }  
    if (onJoin) {
      onJoin(calculatedCosts, selectedAddress, comboQuantity, useRedeemCredit, referralCodeValue);
    }    
    onClose();
  };

  const calculateTotalSavings = () => {
    if (!comboData) return 0;
    let totalSavings = 0; 
    totalSavings += (comboData.bundle_mrp || 0) - (comboData.bundle_rate || 0);
    if (comboData?.combo_items && Array.isArray(comboData.combo_items)) {
        comboData.combo_items.forEach(item => {
            if (item && item.group_wish && item.item_discount) { 
                const itemDiscount = parseFloat(item.item_discount || 0);
                if (itemDiscount > 0) {
                    totalSavings += itemDiscount;
                }
            }
        });
    } 
    totalSavings += parseFloat(comboData?.shipping_discount || 0);
    
    return totalSavings;
};
 
const calculateTotalSavingsForQty = (qty = comboQuantity) => {
    if (!comboData) return 0; 
    const baseSavings = calculateTotalSavings(); 
    return baseSavings * qty;
};
 
const handleCalculate = async () => {
  if (!selectedAddress) {   
      setErrorMessage("Please select a delivery address");
      return;
  }
  setIsCalculating(true);            
  try {
      // Calculate the base product cost correctly with combo quantity
      let productCost = comboData.bundle_rate * comboQuantity;
      
      let totalSavings = 0;        
      if (comboData?.combo_items && Array.isArray(comboData.combo_items)) {
          comboData.combo_items.forEach(item => {
              if (!item || !item.group_wish) return; // Skip invalid items                
              const quantity = quantities[item.id] || 1;
              const marketPrice = item.group_wish.list_price;
              const itemTotal = calculateItemTotal(item, quantity);                
              // Note: The commented line below is removed as bundle_rate already includes all items
              // productCost += itemTotal;
              totalSavings += ((marketPrice * quantity * comboQuantity) - itemTotal);
          });
      } 
      
      totalSavings += parseFloat(comboData?.shipping_discount || 0);
      totalSavings += parseFloat(comboData?.bundle_discount || 0);  
      const bundleSavings = calculateTotalSavingsForQty(); 
      totalSavings = Math.max(totalSavings, bundleSavings);        
      
      const shippingResponse = await calculateShippingCost(
        calculateTotalItems(),
        selectedAddress
    );
      
      const estimatedShipping = shippingResponse.rate - parseFloat(comboData?.shipping_discount || 0);
      let estimatedTax = productCost * 0.08; 
      
      const taxCalculateUrl = buildUrl('calculateComboTax', queryLoginParam);
      const taxRequestBody = {
          combo_id: comboData.id,
          zipCode: selectedAddress.postal_code,
          to_state: selectedAddress.state,
          quantity: comboQuantity,
          customer_delivery_charges: estimatedShipping
      };        
      
      const taxResponse = await postApi(taxCalculateUrl, taxRequestBody);
      if (taxResponse && taxResponse.ok && taxResponse.data) {
          estimatedTax = parseFloat(taxResponse.data.tax_amount);
      }
      
      let creditDiscount = 0;
      const totalBeforeCredit = productCost + estimatedShipping + estimatedTax;        
      
      if (useRedeemCredit && availableCredit > 0) {
          const maxCreditDiscount = totalBeforeCredit * 0.1;  
          creditDiscount = Math.min(availableCredit, maxCreditDiscount);
      }        
      
      const finalTotal = totalBeforeCredit - creditDiscount;        
      
      const calculatedCostsResult = {
          productCost,
          shipping: estimatedShipping,
          tax: estimatedTax,
          creditDiscount,
          total: finalTotal,
          savings: bundleSavings, 
          estimatedDelivery: shippingResponse.delivery_days,
          serviceType: shippingResponse.service_type
      };
      
      setCalculatedCosts(calculatedCostsResult); 
      if (onCalculate) {
          onCalculate(calculatedCostsResult, selectedAddress, comboQuantity, useRedeemCredit);
      }
  } catch (error) {  
      setErrorMessage('Error calculating costs. Please try again.');
  } finally {
      setIsCalculating(false);
  }
};
  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    }).format(amount);
  };

  if (!isOpen) return null;

  return (
    <div style={styles.modalOverlay}>
      <div style={styles.modalContent}>
        <div style={styles.modalHeader}>
          <h2 style={styles.modalTitle}>Join Combo</h2>
          <button onClick={onClose} style={styles.closeButton}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>
        
        <div style={styles.comboInfo}>
          <h3 style={styles.comboTitle}>{comboData?.title || 'Combo'}</h3>
        </div>
        
        <div style={styles.formContent}>
          <div style={styles.formSection}>
            <label style={styles.label}>Referral Code (optional):</label>
            <input 
              type="text" 
              value={referralCodeValue} 
              onChange={(e) => setReferralCodeValue(e.target.value)} 
              style={styles.referralInput} 
            />
          </div>
          {/* Quantity Section */}
          <div style={styles.formSection}>
            <div style={styles.sectionTitleWrapper}>
              <Package size={18} color={Color.colorDarkBlue} />
              <h3 style={styles.sectionTitle}>Combo Quantity</h3>
            </div>
            <div style={styles.quantityInputWrapper}>
              <input 
                type="number" 
                min="1" 
                value={comboQuantity} 
                onChange={handleComboQuantityChange} 
                style={styles.quantityInput} 
              />
            </div>
          </div>
           
          <div style={styles.formSection}>
            <label style={styles.label}>Delivery Address:</label>
            {!showAddressSection && (
              <div style={styles.selectWrapper}>
                <select
                  value={selectedAddress ? selectedAddress.id : ""}
                  onChange={handleAddressChange}
                  style={styles.select}
                >
                  <option value="" style={styles.placeholderOption}>Select delivery address</option>
                  {addresses.map((address, index) => (
                    address && address.id ? (
                      <option 
                        key={index} 
                        value={address.id}
                        style={styles.addressOption}
                      >
                        {formatAddress(address)}
                      </option>
                    ) : null
                  ))}
                  <option value="add_new" style={styles.addNewOption}>+ Add New Address</option>
                </select>
              </div>
            )}
            
            {showAddressSection && (
              <AddressSection 
                address={{}} 
                handleAddressSave={handleAddressSave}
                onCancel={() => {
                  setShowAddressSection(false);
                  if (!selectedAddress && addresses.length > 0) {
                    setSelectedAddress(addresses[0]);
                  }
                }} 
                isNewAddress={true} 
                callFromProfiles={false} 
              />
            )}
          </div>
          
          {/* Redeem Credit Section */}
          <div style={styles.redeemCreditSection}>
            <label style={styles.checkboxLabel}>
              <input  type="checkbox"  checked={useRedeemCredit}  onChange={(e) => setUseRedeemCredit(e.target.checked)} style={styles.checkbox} />
              Use Redeem Credit points (max 10% of total cost)
            </label>
            <div style={styles.creditLinkContainer}>
              <Link to="/creditDetails" style={styles.creditLink}>
                <FontAwesomeIcon icon={faCreditCardAlt} />
                <span>View Credit Details</span>
              </Link>
            </div>
          </div>
          
          {/* Calculate Button */}
          <button 
            onClick={handleCalculate} 
            disabled={isCalculating || !selectedAddress} 
            style={{...styles.calculateButton,...(isCalculating || !selectedAddress ? styles.calculateButtonDisabled : {}) }} >
            <Calculator size={16} />
            {isCalculating ? 'Calculating...' : 'Calculate Total'}
          </button>
          
          {/* Cost Breakdown */}
          {calculatedCosts && (
            <div style={styles.costBreakdown}>
              <div style={styles.costRow}>
                <span style={styles.costLabel}>Product Cost:</span>
                <span style={styles.costValue}>{formatCurrency(calculatedCosts.productCost)}</span>
              </div>
              
              <div style={styles.costRow}>
                <span style={styles.costLabel}>Shipping({calculatedCosts.serviceType}):</span>
                <span style={styles.costValue}>{formatCurrency(calculatedCosts.shipping)}</span>
              </div>
              
              <div style={styles.costRow}>
                <span style={styles.costLabel}>Estimated Tax:</span>
                <span style={styles.costValue}>{formatCurrency(calculatedCosts.tax)}</span>
              </div>

              {useRedeemCredit && calculatedCosts.creditDiscount > 0 && (
                <div style={styles.creditRow}>
                  <span>Redeem Credit Applied:</span>
                  <span>-{formatCurrency(calculatedCosts.creditDiscount)}</span>
                </div>
              )}
              
              <div style={styles.totalRow}>
                <span>Total:</span>
                <span>{formatCurrency(calculatedCosts.total)}</span>
              </div>
              
              <div style={styles.savingsRow}>
                <span>Total Savings:</span>
                <span>{formatCurrency(calculatedCosts.savings)}</span>
              </div>
              
              <p style={styles.deliveryEstimate}>
                Estimated Delivery: {calculatedCosts.estimatedDelivery}
              </p>
            </div>
          )}
          
          {errorMessage && <p style={styles.error}>{errorMessage}</p>}
          {isLoading && (
              <div style={styles.loadingContainer}>
                <div style={styles.spinner} />
              </div>
          )}
          {calculatedCosts && (
            <button onClick={handleJoin} disabled={!selectedAddress || isLoading} style={{...RoseGoldButton,...styles.joinButton,...(!selectedAddress || isLoading ? styles.joinButtonDisabled : {})}}>            
              {isLoading ? 'Processing...' : 'Proceed to Join'}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

const styles = {
  modalOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  },
  modalContent: {
    backgroundColor: Color.colorLightBeige,
    borderRadius: '10px',
    padding: '24px',
    width: '90%',
    maxWidth: '500px',
    maxHeight: '90vh',
    overflowY: 'auto',
    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.15)',
  },
  modalHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '20px',
    borderBottom: `2px solid ${Color.colorPeacockBlue}20`,
    paddingBottom: '15px',
  },
  modalTitle: {
    fontSize: '24px',
    fontWeight: 'bold',
    color: Color.colorPeacockBlue,
    margin: 0,
  },
  closeButton: {
    background: 'none',
    border: 'none',
    fontSize: '24px',
    cursor: 'pointer',
    color: Color.colorPeacockBlue,
  },
  comboInfo: {
    marginBottom: '24px',
  },
  comboTitle: {
    fontSize: '20px',
    fontWeight: 'bold',
    color: Color.colorDarkBlue,
    margin: 0,
  },
  formContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
  },
  formSection: {
    backgroundColor: '#ffffff',
    padding: '16px',
    borderRadius: '8px',
    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
  },
  sectionTitleWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    marginBottom: '12px',
  },
  sectionTitle: {
    fontSize: '16px',
    fontWeight: '600',
    color: Color.colorDarkBlue,
    margin: 0,
  },
  label: {
    fontSize: '16px',
    fontWeight: '500',
    color: Color.colorDarkBlue,
    marginBottom: '8px',
    display: 'block',
  },
  selectWrapper: {
    position: 'relative',
    width: '100%',
  },
  select: {
    width: '100%',
    padding: '12px 16px',
    fontSize: '15px',
    fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif',
    color: Color.colorPeacockBlue,
    backgroundColor: '#ffffff',
    border: '1px solid #cbd5e1',
    borderRadius: '8px',
    appearance: 'none',
    cursor: 'pointer',
    fontWeight: '500',
    transition: 'all 0.2s ease',
    boxShadow: '0 1px 2px rgba(0, 0, 0, 0.05)',
    backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%2364748b' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E")`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right 12px center',
    backgroundSize: '16px',
  },
  placeholderOption: {
    color: '#94a3b8',
    fontSize: '15px',
    fontWeight: '400',
  },
  addressOption: {
    color: '#1e293b',
    fontSize: '15px',
    padding: '12px',
    fontWeight: '400',
  },
  addNewOption: {
    color: '#2563eb',
    fontSize: '15px',
    fontWeight: '500',
    padding: '12px',
    borderTop: '1px solid #e2e8f0',
  },
  referralInput: {
    width: '100%',
    padding: '10px 12px',
    border: `1px solid ${Color.colorDarkBlue}33`,   
    borderRadius: '6px',
    fontSize: '16px',
    color: Color.colorDarkBlue,
    backgroundColor: '#ffffff',
    fontWeight: '400',
  },
  quantityInputWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  quantityInput: {
    width: '80px',
    padding: '8px 12px',
    border: `1px solid ${Color.colorDarkBlue}33`,   
    borderRadius: '6px',
    fontSize: '16px',
    color: Color.colorDarkBlue,
    backgroundColor: '#ffffff',
    textAlign: 'center',
    fontWeight: '500',
  },
  redeemCreditSection: {
    padding: '16px',
    backgroundColor: '#ffffff',
    borderRadius: '8px',
    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
  },
  checkboxLabel: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    fontSize: '14px',
    color: Color.colorDarkBlue,
    marginBottom: '8px',
  },
  checkbox: {
    width: '16px',
    height: '16px',
    cursor: 'pointer',
  },
  creditLinkContainer: {
    marginTop: '8px',
  },
  creditLink: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    fontSize: '14px',
    color: Color.colorPeacockBlue,
    textDecoration: 'none',
  },
  calculateButton: {
    width: '100%',
    padding: '12px',
    backgroundColor: Color.colorPeacockBlue,
    color: '#ffffff',
    border: 'none',
    borderRadius: '8px',
    fontSize: '16px',
    fontWeight: '500',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '8px',
  },
  calculateButtonDisabled: {
    backgroundColor: '#9ca3af',
    cursor: 'not-allowed',
  },
  costBreakdown: {
    padding: '16px',
    backgroundColor: '#ffffff',
    borderRadius: '8px',
    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
  },
  costRow: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '8px',
  },
  costLabel: {
    fontSize: '14px',
    color: '#6b7280',
  },
  costValue: {
    fontSize: '14px',
    color: '#111827',
  },
  creditRow: {
    display: 'flex',
    justifyContent: 'space-between',
    color: '#059669',
    marginBottom: '8px',
  },
  totalRow: {
    display: 'flex',
    justifyContent: 'space-between',
    borderTop: '1px solid #e5e7eb',
    paddingTop: '8px',
    marginTop: '8px',
    fontWeight: '600',
  },
  savingsRow: {
    display: 'flex',
    justifyContent: 'space-between',
    color: '#059669',
    fontWeight: '500',
    marginTop: '8px',
  },
  deliveryEstimate: {
    fontSize: '14px',
    color: '#6b7280',
    textAlign: 'center',
    marginTop: '16px',
  },
  error: {
    color: '#ef4444',
    textAlign: 'center',
    fontWeight: '500',
  },
  joinButton: {
    width: '100%',
    padding: '14px',
    fontSize: '16px',
    fontWeight: '600',
    borderRadius: '8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '8px',
  },
  joinButtonDisabled: {
    opacity: 0.6,
    cursor: 'not-allowed',
  },
};

export default ComboJoinModal;