import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faUsers, faSearch, faTimes, faFilter, faChevronLeft, faChevronRight, faHeartCircleCheck, faHeart, faSpinner, faClock, faFire } from '@fortawesome/free-solid-svg-icons';
import { format, parseISO } from 'date-fns';
import { useAuth } from '../../Common/InitialGears/AuthContext';
import * as style from "../../../GlobalStyles";
import TermsAndConditionsModal from '../GroupWishes/JoiningTermsAndConditions'; 
import UserPreferencesScreen from '../GroupWishes/UserPreferenceScreen';
import JoinGroupWishModal from '../GroupWishes/JoinGroupWishModal';
import { buildUrl, postApi, getApi } from "../../Common/BackendCalls/apiUtils"; 
import NoDataMessage from '../GroupWishes/NoDataMessage';
import Config from "../../../config";
import LoadingSpinnerSection from "../GroupWishes/LoadingSpinnerSection"; 
import ComboJoinModal from '../Combo/ComboJoinModal'; 

const DealsDashboard = ({ groupWishes, combos, onRefresh,isGroupWishesLoading, isCombosLoading  }) => {
  const navigate = useNavigate();
  const { queryLoginParam, userVerified } = useAuth();
  const [deals, setDeals] = useState([]);
  const [filteredDeals, setFilteredDeals] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedFilter, setSelectedFilter] = useState(''); 
  const [errorMessage, setErrorMessage] = useState(null);
  const [loadingState, setLoadingState] = useState({
    isProcessingData: true,
    isSearchLoading: false,
    debug: 'initial'
  }); 
  const isDataLoading = isGroupWishesLoading || isCombosLoading || loadingState.isProcessingData;
  const [isSearching, setIsSearching] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isFavoriteProcessing, setIsFavoriteProcessing] = useState(false);
  const [showComboJoinModal, setShowComboJoinModal] = useState(false);
  const [selectedComboForJoin, setSelectedComboForJoin] = useState(null);
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [selectedWishForJoin, setSelectedWishForJoin] = useState(null); 
  const [userRejoining, setUserRejoining] = useState(false); 
  const [showJoinModal, setShowJoinModal] = useState(false); 
  const [hasNoPreferences, setHasNoPreferences] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null); 
  const [showPreferencesModal, setShowPreferencesModal] = useState(false);
  const [calculatedCosts, setCalculatedCosts] = useState(null);
  const [comboQuantity, setComboQuantity] = useState(1);
  const [useRedeemCredit, setUseRedeemCredit] = useState(false);
  const [isJoiningCombo, setIsJoiningCombo] = useState(false); 
  const [joinComboError, setJoinComboError] = useState(null);
  const [modalData, setModalData] = useState({selectedRange: null,selectedQuantity: '',selectedAddress: null,calculatedCosts: null,showAddressSection: false,externalUnitPrice: combos?.list_price || 0});       
     
  const itemsPerPage = 8;
 
  const filterOptions = [
    { value: 'highestsavings', label: 'Highest Savings' },
    { value: 'fastapproaching', label: 'Fastest Approaching' },
    { value: 'mostpopular', label: 'Most Popular' },
    { value: 'newestdeals', label: 'Newest Deals' }
  ];  

useEffect(() => { 
  setLoadingState(prev => ({ 
    ...prev, 
    isProcessingData: true,
    debug: 'data props changed'
  }));
  const processGroupWishes = () => {
    if (!groupWishes || !groupWishes.userGroupWishes || !groupWishes.connectionGroupWishes) {
      return [];
    } 
    const filterJoinedWishes = (wishes) => {
      if (!wishes || !Array.isArray(wishes)) return [];        
      return wishes.filter(item => {
        if (!item || !item.wish) return false;
        return item.group_wish_buddy_status === null ||
              item.group_wish_buddy_status === 'WITHDRAWN' ||
              item.group_wish_buddy_status === 'WAITLISTED';
      });
    }; 
    const userWishes = filterJoinedWishes(groupWishes.userGroupWishes || []);
    const recommendedWishes = filterJoinedWishes(groupWishes.connectionGroupWishes || []);
    const latestWishes = filterJoinedWishes(groupWishes.latestGroupWishes || []);
    const additionalWishes = filterJoinedWishes(groupWishes.additional_group_wishes || []);     
    return [...userWishes, ...recommendedWishes, ...latestWishes, ...additionalWishes].map(item => ({
        ...item,
        dealType: 'groupWish',
        displayData: {
          id: item.wish?.id || null,
          title: item.wish?.product_name || 'No Title',
          image: item.wish?.image_url || null,
          expires: item.wish?.expires_at || null,
          minSize: item.wish?.group_size_min || 0,
          totalJoined: item.totalJoined || 0,
          isFavorite: item.is_favorite || false,
          priceRanges: item.wish?.price_ranges || [],
          status: item.group_wish_buddy_status || null,
          createdAt: item.wish?.created_at || null,
          verificationScore: item.wish?.verification_score || 0
        }        
      }));
  };
  
  const processCombos = () => {
    if (!combos || !combos.combos || !Array.isArray(combos.combos)) { return [];}
    return combos.combos.map(comboData => ({
      combo: comboData.combo,
      dealType: 'combo',
      displayData: {
        id: comboData.combo?.id || null,
        title: comboData.combo?.title || 'No Title',
        image: getComboFirstImage(comboData.combo),
        expires: comboData.combo?.expires_at || null,
        minSize: comboData.combo?.min_combo_size || 0,
        totalJoined: comboData.combo?.progress_stats?.verifiedUsers + comboData.combo?.progress_stats?.unverifiedUsers || 0,
        isFavorite: comboData.combo?.progress_stats?.is_favorite || false,
        originalPrice: comboData.combo?.bundle_mrp || 0,
        salePrice: comboData.combo?.bundle_rate || 0,
        itemCount: comboData.combo?.combo_items?.length || 0,
        createdAt: comboData.combo?.created_at || null,
        status: comboData.combo?.group_wish_buddy_status || null,
        comboItems: comboData.combo?.combo_items || []
      }
    }));
  };  
  
  const getComboFirstImage = (combo) => {
    if (!combo || !combo.combo_items || !Array.isArray(combo.combo_items)) {return null;}      
    const firstItem = combo.combo_items.find(item => item.group_wish && item.group_wish.image_url);      
    return firstItem?.group_wish?.image_url || null;
  }; 
   
  if (!isGroupWishesLoading && !isCombosLoading) {
    try {
      const groupWishesData = processGroupWishes();
      const combosData = processCombos();
      const allDeals = [...groupWishesData, ...combosData];    
      setDeals(allDeals);
      setFilteredDeals(allDeals);
      setLoadingState(prev => ({ 
        ...prev, 
        isProcessingData: false,
        debug: 'data processing complete' 
      })); 
    } catch (error) {
      setLoadingState(prev => ({ 
        ...prev, 
        isProcessingData: false,
        debug: 'data processing error' 
      })); 
    }  
  }
}, [groupWishes, combos, isGroupWishesLoading, isCombosLoading]);
 
  const handleSearch = async () => {
    if (!searchQuery.trim()) {
      setIsSearching(false);
      setSearchResults([]);
      setFilteredDeals(deals);
      return;
    } 
    setIsSearching(true);
    setLoadingState(prev => ({ 
      ...prev, 
      isProcessingData: true,
      debug: 'search started' 
    }));
    setErrorMessage(null); 
    try {  
      const results = deals.filter(deal => {
        const title = deal.dealType === 'groupWish' 
          ? deal.displayData.title?.toLowerCase() 
          : deal.displayData.title?.toLowerCase();
        
        return title && title.includes(searchQuery.toLowerCase());
      });      
      setSearchResults(results);
      setFilteredDeals(results);
      setCurrentPage(1);
    } catch (error) {
      setErrorMessage('Failed to search. Please try again.');
      setFilteredDeals(deals);
    } finally {
      setLoadingState(prev => ({ 
        ...prev, 
        isProcessingData: false,
        debug: 'search completed' 
      }));
    }
  };

  const handleSearchInputKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };
 
  const handleFilterChange = async (event) => {
    const filterValue = event.target.value;
    setSelectedFilter(filterValue);
    setLoadingState(prev => ({ 
      ...prev, 
      isProcessingData: false,
      debug: 'filter started ' 
    }));

    try {
      let filteredResults = [...deals];
      
      switch(filterValue) {
        case 'highestsavings':
          filteredResults.sort((a, b) => { 
            const getMaxSavings = (deal) => {
              if (deal.dealType === 'groupWish') {
                const priceRanges = deal.displayData.priceRanges || [];
                return Math.max(...priceRanges.map(range => parseFloat(range.savings_percentage) || 0), 0);
              } else { 
                const original = deal.displayData.originalPrice;
                const sale = deal.displayData.salePrice;
                return original > 0 ? ((original - sale) / original) * 100 : 0;
              }
            };
            
            return getMaxSavings(b) - getMaxSavings(a);
          });
          break;
          
        case 'fastapproaching':
          filteredResults.sort((a, b) => {
            const dateA = a.displayData.expires ? new Date(a.displayData.expires) : new Date(9999, 11, 31);
            const dateB = b.displayData.expires ? new Date(b.displayData.expires) : new Date(9999, 11, 31);
            return dateA - dateB;
          });
          break;
          
        case 'mostpopular':
          filteredResults.sort((a, b) => {
            return b.displayData.totalJoined - a.displayData.totalJoined;
          });
          break;
          
        case 'newestdeals':
          filteredResults.sort((a, b) => {
            const dateA = a.displayData.createdAt ? new Date(a.displayData.createdAt) : new Date(0);
            const dateB = b.displayData.createdAt ? new Date(b.displayData.createdAt) : new Date(0);
            return dateB - dateA;
          });
          break;
          
        default: 
          break;
      }
      
      setFilteredDeals(filteredResults);
      setCurrentPage(1);
    } catch (error) {
      setErrorMessage('Failed to apply filter. Please try again.');
    } finally {
      setLoadingState(prev => ({ 
        ...prev, 
        isProcessingData: false,
        debug: 'filter completed' 
      }));
    }
  };

  const clearFilter = () => {
    setSelectedFilter('');
    setSearchQuery('');
    setIsSearching(false);
    setFilteredDeals(deals);
    setCurrentPage(1);
  };
 
  const toggleFavorite = async (deal) => {
    if (isFavoriteProcessing) return;
    
    setIsFavoriteProcessing(true);
    const isFavorite = deal.displayData.isFavorite;
    const dealId = deal.displayData.id;
    
    try {
      if (deal.dealType === 'groupWish') {
        const favoriteUrl = buildUrl(`${isFavorite ? 'removeFromfavoriteGroupWish' : 'addTofavoriteGroupWish'}`, queryLoginParam);        
        await postApi(favoriteUrl, {group_wish_id: dealId });
      } else { 
        const favoriteUrl = buildUrl(`${isFavorite ? 'removeFromfavoriteCombos' : 'addTofavoriteCombos'}`, queryLoginParam);        
        await postApi(favoriteUrl, {combo_id: dealId });
      }
       
      const updatedDeals = deals.map(d => {
        if (d.displayData.id === dealId && d.dealType === deal.dealType) {
          return {
            ...d,
            displayData: {
              ...d.displayData,
              isFavorite: !isFavorite
            }
          };
        }
        return d;
      });
      
      setDeals(updatedDeals);
      setFilteredDeals(prevFiltered => {
        return prevFiltered.map(d => {
          if (d.displayData.id === dealId && d.dealType === deal.dealType) {
            return {
              ...d,
              displayData: {
                ...d.displayData,
                isFavorite: !isFavorite
              }
            };
          }
          return d;
        });
      }); 
      if (onRefresh) {
        onRefresh();
      } 
      return !isFavorite;
    } catch (error) {
      setErrorMessage('Error updating favorites. Please try again.');
      return isFavorite;
    } finally {
      setTimeout(() => {
        setIsFavoriteProcessing(false);
      }, 500);
    }
  };

  const SuccessMessageComponent = ({ message, onClose }) => {
    if (!message) return null;    
    return (
      <div style={styles.successMessageContainer}>
        <div style={styles.successMessage}>           
          <div style={styles.successMessageText}>
            {message}
          </div>
          <button onClick={onClose} style={styles.closeSuccessButton}>
            <FontAwesomeIcon icon={faTimes} style={styles.closeIcon}/>
          </button>
        </div>
      </div>
    );
  };
  
  const ErrorMessageComponent = ({ message, onClose }) => {
    if (!message) return null;    
    return ( 
       <div style={styles.errorMessageContainer}>
       <div style={styles.errorMessage}>           
         <div style={styles.errorMessageText}>
           {message}
         </div>
         <button onClick={onClose} style={styles.closeErrorButton}>
           <FontAwesomeIcon icon={faTimes} style={styles.closeIcon}/>
         </button>
       </div>
     </div>
    );
  };

  const handleViewDealDetails = (deal) => {
    if (deal.dealType === 'groupWish') {
      navigate('/groupWishProgress', { 
        state: { 
          wish: deal.wish,
          progressData: {
            wish: deal.wish,
            is_favorite: deal.displayData.isFavorite,
            totalJoined: deal.totalJoined || 0,
            totalWaitListed: deal.totalWaitListed || 0,
            verifiedUsers: deal.verifiedUsers || 0,
            unverifiedUsers: deal.unverifiedUsers || 0,
            upvotes: deal.upvotes || 0,
            downvotes: deal.downvotes || 0,
            progress: deal.progress || 0,
            averageRating: deal.averageRating || 0,
            user_already_joined: deal.user_already_joined || false,
            price_ranges: deal.price_ranges || [],
            rating_counts: deal.rating_counts || [],
            group_wish_buddy_status: deal.group_wish_buddy_status || null,
            delivery_address: deal.delivery_address || null
          },
          joined: deal.user_already_joined || false,
          is_favorite: deal.displayData.isFavorite, 
        } 
      });
    } else {
      navigate('/comboProgress', {
        state: {
          comboData: deal.combo
        }
      });
    }
  }; 

  const handleReferAFriend = (deal, e) => {
    e.stopPropagation();
    if (deal.dealType === 'groupWish') {
      navigate("/invites", { state: { grp_wish: deal } });
    } else {
      navigate("/invites", { state: { comboData: deal } });
    }
  };
 
  const handleAgreeTerms = async () => {  
    if (showComboJoinModal && selectedComboForJoin) { 
      setShowTermsModal(false);  
      setShowComboJoinModal(true); 
    } else if (selectedWishForJoin) { 
      setShowJoinModal(true);
    }
  };
  

  const handleRejoin = async (deal, e) => { 
    e.stopPropagation();  
    if(!userVerified){
      setErrorMessage("Verify your account to proceed");
      return;
    }
    setUserRejoining(true);     
    handleJoinDeal(deal, e)     
  };

  const handleCloseJoinModal = () => {
    setShowJoinModal(false);
    if (onRefresh) {
      onRefresh();
    }
  };

  const handleJoinDeal = (deal, e) => {
    e.stopPropagation();  
    if(!userVerified){
      setErrorMessage("Verify your account to proceed");
      return;
    }  
    setSelectedWishForJoin(null);
    setShowTermsModal(false); 
    setShowComboJoinModal(false);
    if (deal.dealType === 'groupWish') {  
      const hasNoPrefs = !deal.wish?.preferences || (Array.isArray(deal.wish.preferences) && deal.wish.preferences.length === 0);
      setHasNoPreferences(hasNoPrefs);
      setSelectedWishForJoin(deal);
      setShowTermsModal(true);
    } else {  
        setSelectedComboForJoin(deal.combo);
        setShowTermsModal(true);
        setShowComboJoinModal(true);
    }
  };
 
  const handleCalculateFromModal = (calculatedCostsData, selectedAddressData, comboQuantityValue, useRedeemCreditValue) => { 
    setCalculatedCosts(calculatedCostsData);
    setModalData(prevData => ({
      ...prevData,
      selectedAddress: selectedAddressData,
      calculatedCosts: calculatedCostsData
    }));
    setComboQuantity(comboQuantityValue);
    setUseRedeemCredit(useRedeemCreditValue);
  };
    
  const handleJoinFromModal = ( calculatedCostsData, selectedAddressData, comboQuantityValue, useRedeemCreditValue) => {
    setIsJoiningCombo(true);
    setJoinComboError(null);
    setCalculatedCosts(calculatedCostsData);
    setModalData(prevData => ({
      ...prevData,
      selectedAddress: selectedAddressData
    }));
    setComboQuantity(comboQuantityValue);
    setUseRedeemCredit(useRedeemCreditValue); 
    
    setShowComboJoinModal(false);
    setTimeout(() => {
      setShowPreferencesModal(true);
    }, 100);
  };

const handlePreferencesClose = (success) => {
  setIsJoiningCombo(false);
  setShowPreferencesModal(false);
  if (success) { 
    if (success.combo && success.combo.userPreferences) {
      setSelectedComboForJoin(prevData => ({
        ...prevData,
        userPreferences: success.combo.userPreferences, 
        progress_stats: {
          ...prevData.progress_stats,
          usersChoices: success.combo.userPreferences
        }
      }));
      setSuccessMessage('Successfully joined the combo!');
    } else if (success.preferences) { 
      setSelectedComboForJoin(prevData => ({
        ...prevData,
        progress_stats: {
          ...prevData.progress_stats,
          usersChoices: success.preferences
        }
      }));
      setSuccessMessage('Successfully updated your preferences!');
    } else if (Array.isArray(success)) { 
      setSelectedComboForJoin(prevData => ({
        ...prevData,
        progress_stats: {
          ...prevData.progress_stats,
          usersChoices: success
        }
      }));
      setSuccessMessage('Successfully joined the combo!');
    }else { 
      setSuccessMessage('Successfully joined!');
    } 
    setJoinComboError(null);
    setErrorMessage(null);
  }else { 
    setErrorMessage('Failed to join combo. Please try again.');
  }
  if (onRefresh) {
    onRefresh();
  }  
};

  const formatDate = (dateString) => {
    if (!dateString) return 'Date not available';
    try {
      const date = new Date(dateString);
      return date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      });
    } catch {
      return "Date not available";
    }
  };

  const formatTimeRemaining = (expiryDateString) => {
    if (!expiryDateString) return '';
    try {
      const expiryDate = parseISO(expiryDateString);
      const now = new Date();
      const diffTime = expiryDate.getTime() - now.getTime();
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      return diffDays < 0 ? '0' : diffDays.toString();
    } catch (error) {
      return '';
    }
  };

  const formatPrice = (price) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    }).format(price);
  };

  const calculateSavings = (deal) => {
    if (deal.dealType === 'groupWish') {
      const priceRanges = deal.displayData.priceRanges || [];
      if (priceRanges.length === 0) return { amount: 0, percentage: 0, text: "No savings data" }; 
      const maxSavingsRange = [...priceRanges].sort((a, b) => 
        parseFloat(b.savings_percentage) - parseFloat(a.savings_percentage)
      )[0];      
      if (!maxSavingsRange) return { amount: 0, percentage: 0, text: "No savings data" };
      
      return {
        amount: parseFloat(maxSavingsRange.savings_amount).toFixed(2),
        percentage: Math.round(parseFloat(maxSavingsRange.savings_percentage)),
        text: `Save $${parseFloat(maxSavingsRange.savings_amount).toFixed(2)} (${Math.round(parseFloat(maxSavingsRange.savings_percentage))}% off)`
      };
    } else { 
      const originalPrice = deal.displayData.originalPrice;
      const salePrice = deal.displayData.salePrice;
      if (!originalPrice || !salePrice) return { amount: 0, percentage: 0, text: "No savings data" };      
      const savingsAmount = originalPrice - salePrice;
      const savingsPercentage = Math.round((savingsAmount / originalPrice) * 100);
      
      return {
        amount: savingsAmount.toFixed(2),
        percentage: savingsPercentage,
        text: `Save ${formatPrice(savingsAmount)} (${savingsPercentage}% off)`
      };
    }
  };

  const getSavingsColor = (percentage) => {
    if (percentage >= 50) return style.Color.colorRed;
    if (percentage >= 30) return style.Color.colorOrange;
    if (percentage >= 10) return style.Color.colorYellow;
    return style.Color.colorGreen;
  };
 
  const DealCard = ({ deal }) => {
    const [isHovered, setIsHovered] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const displayData = deal.displayData;
    const savings = calculateSavings(deal);
    const isGroupWish = deal.dealType === 'groupWish';
    
    return (
      <div  className="deal-card" style={styles.dealCard}  onClick={() => handleViewDealDetails(deal)} >
        <div style={styles.dealHeader}>         
          <h3 style={styles.dealTitle}>{displayData.title}</h3>
          <button 
            style={{
              ...styles.favoriteButton,
              color: displayData.isFavorite ? style.Color.colorPeacockBlue : style.Color.colorPurple,
              transform: isHovered ? 'scale(1.15)' : (displayData.isFavorite ? 'scale(1.1)' : 'scale(1)'),
              backgroundColor: isProcessing 
                ? style.Color.colorCoolBeige 
                : isHovered 
                  ? style.Color.colorCoolBeige 
                  : style.Color.colorWhite,
              opacity: isProcessing ? 0.7 : 1,
              cursor: isProcessing ? 'not-allowed' : 'pointer',
            }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={async (e) => {
              if (isProcessing) return;
              e.preventDefault();
              e.stopPropagation();
              setIsProcessing(true);
              
              try {
                const newFavoriteState = await toggleFavorite(deal);
                deal.displayData.isFavorite = newFavoriteState;
              } finally {
                setIsProcessing(false);
                setIsHovered(false);
              }
            }}
            disabled={isProcessing}
          >
            <FontAwesomeIcon 
              icon={isProcessing ? faSpinner : (displayData.isFavorite ? faHeartCircleCheck : faHeart)} 
              style={{
                color: displayData.isFavorite ? style.Color.colorPeacockBlueDark : style.Color.colorPurple,
                animation: isProcessing ? 'spin 1s linear infinite' : 'none'
              }}
            />
          </button>
        </div>
        
        <div style={styles.dealContent}>
          <div style={styles.imageContainer}>
            <img 
              src={displayData.image || "/api/placeholder/400/320"} 
              alt={displayData.title}
              style={styles.dealImage}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = "/api/placeholder/400/320";
              }}
            />
          </div>
          
          <div style={styles.detailsContainer}>
            <div style={styles.timeInfo}>
              <div style={styles.expiryDate}>
                <FontAwesomeIcon icon={faClock} style={styles.infoIcon} />
                <span>Expires: {formatDate(displayData.expires)}</span>
              </div>
              <div style={styles.timeRemaining}>
                <span style={styles.daysNumber}>{formatTimeRemaining(displayData.expires)}</span>
                <span style={styles.daysText}>Days Left</span>
              </div>
            </div>
            
            <div style={styles.statsContainer}>
              <div style={styles.statItem}>
                <FontAwesomeIcon icon={faUsers} style={styles.infoIcon} />
                <span>Min Size: {displayData.minSize}</span>
              </div>
              <div style={styles.statItem}>
                <FontAwesomeIcon icon={faUsers} style={styles.infoIcon} />
                <span>{displayData.totalJoined} joined</span>
              </div>
            </div>
            
            <div style={styles.priceContainer}>
              {isGroupWish ? (
                <>
                  <div style={styles.savingsBadge}>
                    <FontAwesomeIcon icon={faFire} style={styles.fireIcon} />
                    <span style={{
                      ...styles.savingsText,
                      color: getSavingsColor(savings.percentage)
                    }}>
                      {savings.text}
                    </span>
                  </div>
                </>
              ) : (
                <>
                  <div style={styles.comboPrice}>
                    <span style={styles.originalPrice}>{formatPrice(displayData.originalPrice)}</span>
                    <span style={styles.salePrice}>{formatPrice(displayData.salePrice)}</span>
                  </div>
                  <div style={styles.savingsBadge}>
                    <FontAwesomeIcon icon={faFire} style={styles.fireIcon} />
                    <span style={{
                      ...styles.savingsText,
                      color: getSavingsColor(savings.percentage)
                    }}>
                      {savings.text}
                    </span>
                  </div>
                </>
              )}
            </div>
            
            {isGroupWish && (
              <div style={styles.rating}>
                <FontAwesomeIcon icon={faStar} style={styles.starIcon} />
                <span>{parseFloat(displayData.verificationScore || 0).toFixed(1)}</span>
              </div>
            )}
            
            {isGroupWish && displayData.status === 'WAITLISTED' && (
              <div style={styles.statusBadge}>
                WAITLISTED
              </div>
            )}
          </div>
        </div>
        
        <div style={styles.actionButtonsContainer}>
          {isGroupWish ? (
            // For group wishes
            <>
              {displayData.status === 'WITHDRAWN' && (
                <button style={{...style.RoseGoldButton, ...styles.actionButton}} onClick={(e) => handleRejoin(deal, e)}>
                  Rejoin
                </button>
              )}
              {(displayData.status === null) && !deal.user_already_joined && (
                <button style={{...style.RoseGoldButton, ...styles.actionButton}} onClick={(e) => handleJoinDeal(deal, e)}>
                  Join
                </button>
              )}
              {displayData.status === 'WAITLISTED' && !deal.user_already_joined && (
                <button style={{...style.RoseGoldButton, ...styles.actionButton}} onClick={(e) => handleRejoin(deal, e)}>
                  Rejoin
                </button>
              )}
            </>
          ) : (
            // For combos
            <>
              {deal.combo?.progress_stats?.group_wish_buddy_status === 'WITHDRAWN' && (
                <button style={{...style.RoseGoldButton, ...styles.actionButton}} onClick={(e) => handleRejoin(deal, e)}>
                  Rejoin
                </button>
              )}
              {deal.combo?.progress_stats?.group_wish_buddy_status === null && !deal.combo?.progress_stats?.user_already_joined && (
                <button style={{...style.RoseGoldButton, ...styles.actionButton}} onClick={(e) => handleJoinDeal(deal, e)}>
                  Join
                </button>
              )}
            </>
          )}
          <button style={styles.referButton} onClick={(e) => handleReferAFriend(deal, e)}>
            Refer
          </button>
        </div>  
      </div>
    );
  };
 
  const renderFilterAndSearch = () => {
    return (
      <div style={styles.searchAndFilterContainer}>
        <div style={styles.searchContainer}>
          <input
            style={styles.searchInput}
            type="text"
            placeholder="Search all deals..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            onKeyPress={handleSearchInputKeyPress}
          />
          <button
            onClick={handleSearch}
            style={{
              ...styles.searchButton,
              cursor: (!searchQuery.trim() || loadingState.isProcessingData) ? 'not-allowed' : 'pointer',
              opacity: (!searchQuery.trim() || loadingState.isProcessingData) ? 0.7 : 1
            }}
            disabled={!searchQuery.trim() || loadingState.isProcessingData}
          >
            <FontAwesomeIcon icon={faSearch} />
          </button>
        </div>
        
        <div style={styles.filterContainer}>
          <FontAwesomeIcon icon={faFilter} style={styles.filterIcon} />
          <select style={styles.filterSelect} value={selectedFilter}  onChange={handleFilterChange} >
            <option value="">Sort Deals By</option>
            {filterOptions.map(option => (
              <option key={option.value} value={option.value}>{option.label}</option>
            ))}
          </select>
        </div>
        
        {(selectedFilter || isSearching) && (
          <button onClick={clearFilter} style={styles.clearFilterButton}>
            <FontAwesomeIcon icon={faTimes} /> Clear
          </button>
        )}
      </div>
    );
  };

  // Render pagination
  const renderPagination = () => {
    const totalPages = Math.ceil(filteredDeals.length / itemsPerPage);
    
    if (totalPages <= 1) return null;
    
    return (
      <div style={styles.paginationContainer}>
        <button
          style={{
            ...styles.paginationButton,
            opacity: currentPage === 1 ? 0.5 : 1,
            cursor: currentPage === 1 ? 'not-allowed' : 'pointer'
          }}
          onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
          disabled={currentPage === 1}
        >
          <FontAwesomeIcon icon={faChevronLeft} /> Previous
        </button>
        
        <span style={styles.pageInfo}>
          Page {currentPage} of {totalPages}
        </span>
        
        <button
          style={{
            ...styles.paginationButton,
            opacity: currentPage >= totalPages ? 0.5 : 1,
            cursor: currentPage >= totalPages ? 'not-allowed' : 'pointer'
          }}
          onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
          disabled={currentPage >= totalPages}
        >
          Next <FontAwesomeIcon icon={faChevronRight} />
        </button>
      </div>
    );
  };

  // Main render function
  return (
    <div style={styles.container}>
      <div style={styles.header}>
        <h2 style={styles.sectionTitle}>Best Deals For You</h2>
        <p style={styles.description}>
          Browse our collection of group deals and combo packages!!
        </p>
      </div>
      <SuccessMessageComponent  message={successMessage}  onClose={() => setSuccessMessage(null)} />
      <ErrorMessageComponent  message={errorMessage}  onClose={() => setErrorMessage(null)} />      
      {renderFilterAndSearch()}
      
      {isDataLoading ? (
        <LoadingSpinnerSection title="Loading deals..." />
      ) : filteredDeals.length === 0 ? (
        <NoDataMessage  message="No deals matching your criteria. Try adjusting your filters or search terms."  icon={<FontAwesomeIcon icon={faStar} />} />
      ) : (
        <>  
          {isSearching && (
            <div style={styles.searchResultsHeader}>
              <h3>Search Results for "{searchQuery}"</h3>
              <span>{filteredDeals.length} results found</span>
            </div>
          )}          
          <div style={styles.dealsGrid}>
            {filteredDeals
              .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
              .map((deal, index) => (
                <DealCard key={`${deal.dealType}-${deal.displayData.id || index}`} deal={deal} />
              ))}
          </div>
          
          {renderPagination()} 
          </>
      )} 
      {showComboJoinModal  && (
        <ComboJoinModal isOpen={showComboJoinModal} onClose={() => {setShowComboJoinModal(false)}} comboData={selectedComboForJoin} callJoinApis= {true} onCalculate={handleCalculateFromModal} onJoin={handleJoinFromModal} queryLoginParam={queryLoginParam} userVerified={userVerified} isLoading={isJoiningCombo} userRejoining={userRejoining}/>  
      )}        
      <TermsAndConditionsModal isOpen={showTermsModal} onClose={() => {setShowTermsModal(false);}} onCancel={() => {setShowTermsModal(false);setShowJoinModal(false);setShowComboJoinModal(false)}} onAgree={handleAgreeTerms}/>
      <JoinGroupWishModal isOpen={showJoinModal} onClose={handleCloseJoinModal} wish={selectedWishForJoin} isRejoin={userRejoining} hasPrefernce={!hasNoPreferences} landBack={"Dashboard"} onJoinSuccess={() => {if (onRefresh) { onRefresh();setSuccessMessage('Successfully joined the group wish!');}}} onJoinError={(errorMsg) => { setErrorMessage(errorMsg || 'Failed to join the group wish'); if (onRefresh) {  onRefresh();}}}/>
      {showPreferencesModal && (
        <UserPreferencesScreen isOpen={showPreferencesModal} onClose={handlePreferencesClose} combo={true} grp_wish_id={selectedComboForJoin.id} groupWishName={selectedComboForJoin ? selectedComboForJoin.title : ''} existingPreferences={ "new"}  landBack={"Dashboard"} quantity={comboQuantity || 1}    isLoading={isJoiningCombo} onError={(errorMsg) => {setShowPreferencesModal(false);setErrorMessage(errorMsg || 'Failed to save preferences');if (onRefresh) {onRefresh();}}}/>
      )}        
    </div>
  );
};
 
const styles = {
    container: {
      width: '100%',
      margin: '0 auto',
      padding: '10px',
      backgroundColor: style.Color.colorWhite,
      borderRadius: '8px',
    },
    header: {
      marginBottom: '20px',
      textAlign: 'center',
    },
    sectionTitle: {
      fontSize: '28px',
      fontWeight: 'bold',
      color: style.Color.colorRoseGoldText,
      margin: '0 0 10px 0',
    },
    description: {
      fontSize: '16px',
      color: style.Color.colorDarkGrey,
      margin: 0,
    }, 
    successMessageContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      marginBottom: '20px',
    },
    successMessage: {
      backgroundColor: style.Color.colorLimeGreen,
      color: style.Color.colorWhite,
      padding: '10px 15px',
      borderRadius: '8px',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      maxWidth: '600px',
    },
    successMessageText: {
      flex: 1,
      color: style.Color.colorWhite,
      fontSize: '16px',
      fontWeight: 'bold',
    },
    closeSuccessButton: {
      background: 'none',
      border: 'none',
      color: style.Color.colorWhite,
      cursor: 'pointer',
      marginLeft: '15px',
      padding: '0',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    errorMessageContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      marginBottom: '20px',
    },
    errorMessage: {
      backgroundColor: '#fff', // White background
      border: `1px solid ${style.Color.colorRed}`,
      padding: '10px 15px',
      borderRadius: '8px',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      maxWidth: '600px',
    },
    errorMessageText: {
      flex: 1,
      color: style.Color.colorVibrantRed,
      fontSize: '16px',
      fontWeight: 'bold',
    },
    closeErrorButton: {
      background: 'none',
      border: 'none',
      color: style.Color.colorVibrantRed,
      cursor: 'pointer',
      marginLeft: '15px',
      padding: '0',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    closeIcon: {
      width: '16px',
      height: '16px',
      fontSize: '16px',
    },
    searchAndFilterContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      gap: '20px',
      marginBottom: '30px',
      padding: '15px',
      borderRadius: '8px', 
      backgroundColor: style.Color.colorWhite,
    },
    searchContainer: {
      position: 'relative',
      flex: 1,
    },
    searchInput: {
      width: '100%',
      height: '60px',
      borderRadius: '8px',
      padding: '0 40px 0 15px',
      fontSize: '16px',
      border: `1px solid ${style.Color.colorRoseGoldText}`,
      transition: 'all 0.3s ease',
    },
    searchButton: {
      position: 'absolute',
      right: '10px',
      top: '50%',
      transform: 'translateY(-50%)',
      background: 'none',
      border: 'none',
      cursor: 'pointer',
      fontSize: '20px',
      color: style.Color.colorRoseGoldText,
    },
    filterContainer: {
      position: 'relative',
      width: '250px',
    },
    filterIcon: {
      position: 'absolute',
      left: '15px',
      top: '50%',
      transform: 'translateY(-50%)',
      color: style.Color.colorRoseGoldText,
      zIndex: 1,
    },
    filterSelect: {
      width: '100%',
      height: '60px',
      borderRadius: '8px',
      padding: '0 15px 0 40px',
      fontSize: '16px',
      backgroundColor: style.Color.colorWhite, 
      border: `1px solid ${style.Color.colorRoseGoldText}`,
      color: style.Color.colorDarkGrey,
      appearance: 'none',
      cursor: 'pointer',
      transition: 'all 0.3s ease',
    },
    clearFilterButton: {
      backgroundColor: style.Color.colorWhite,
      color: style.Color.colorRoseGoldText,
      border: 'none',
      borderRadius: '5px',
      padding: '8px 15px',
      cursor: 'pointer',
      fontWeight: 'bold',
      display: 'flex',
      alignItems: 'center',
      gap: '5px',
      transition: 'background-color 0.3s ease',
    },
    dealsGrid: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
      gap: '25px',
      marginBottom: '30px',
    },
    dealCard: {
      backgroundColor: style.Color.colorWhite,
      borderRadius: '10px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      transition: 'transform 0.2s ease, box-shadow 0.2s ease',
      cursor: 'pointer',
      position: 'relative',
    },
    dealHeader: {
      padding: '15px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      borderBottom: `1px solid ${style.Color.colorLightGray}`,
      position: 'relative',
    },
    dealTypeTag: {
      position: 'absolute',
      top: '10px',
      left: '10px',
      backgroundColor: style.Color.colorPeacockBlue,
      color: style.Color.colorWhite,
      fontSize: '12px',
      fontWeight: 'bold',
      padding: '4px 8px',
      borderRadius: '4px',
      zIndex: 1,
    },
    dealTitle: {
      fontSize: '18px',
      fontWeight: 'bold',
      color: style.Color.colorRoyalBlue,
      margin: '0 40px 0 0',
      padding: '0 0 0 5px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      WebkitLineClamp: 2,
      WebkitBoxOrient: 'vertical',
      lineHeight: '1.3',
      minHeight: '47px',
    },
    favoriteButton: {
      position: 'absolute',
      top: '15px',
      right: '15px',
      background: 'none',
      border: 'none',
      cursor: 'pointer',
      fontSize: '24px',
      zIndex: 2,
      padding: '8px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '50%',
      transition: 'all 0.3s ease',
    },
    dealContent: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      padding: '15px',
    },
    imageContainer: {
      width: '100%',
      height: '180px',
      borderRadius: '8px',
      overflow: 'hidden',
      marginBottom: '15px',
      backgroundColor: style.Color.colorLightestBeige,
    },
    dealImage: {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
    },
    detailsContainer: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      gap: '15px',
      position: 'relative',
    },
    timeInfo: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    expiryDate: {
      display: 'flex',
      alignItems: 'center',
      gap: '5px',
      fontSize: '14px',
      color: style.Color.colorDarkGrey,
    },
    timeRemaining: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: '40px',
      height: '40px',
      borderRadius: '50%',
      backgroundColor: style.Color.colorLimeGreen,
      color: style.Color.colorWhite,
    },
    daysNumber: {
      fontSize: '14px',
      fontWeight: 'bold',
    },
    daysText: {
      fontSize: '5px',
    },
    statsContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      gap: '10px',
    },
    statItem: {
      display: 'flex',
      alignItems: 'center',
      gap: '5px',
      fontSize: '14px',
      color: style.Color.colorDarkGrey,
    },
    infoIcon: {
      color: style.Color.colorPeacockBlue,
      fontSize: '14px',
    },
    priceContainer: {
      backgroundColor: style.Color.colorLightBeige,
      borderRadius: '8px',
      padding: '12px',
      marginTop: '5px',
    },
    comboPrice: {
      display: 'flex',
      alignItems: 'center',
      gap: '15px',
      marginBottom: '8px',
    },
    originalPrice: {
      fontSize: '16px',
      textDecoration: 'line-through',
      color: style.Color.colorDarkGrey,
    },
    salePrice: {
      fontSize: '20px',
      fontWeight: 'bold',
      color: style.Color.colorRed,
    },
    savingsBadge: {
      display: 'flex',
      alignItems: 'center',
      gap: '5px',
    },
    fireIcon: {
      color: style.Color.colorRed,
      fontSize: '16px',
    },
    savingsText: {
      fontWeight: 'bold',
      fontSize: '14px',
    },
    rating: {
      display: 'flex',
      alignItems: 'center',
      gap: '5px',
      fontSize: '16px',
      fontWeight: 'bold',
    },
    starIcon: {
      color: '#FFD700',
    },
    actionButtonsContainer: {
      display: 'flex',
      gap: '10px',
      padding: '15px',
      borderTop: `1px solid ${style.Color.colorLightBeige}`,
    },
    actionButton: {
      flex: 1,
      padding: '10px',
      borderRadius: '5px',
      border: 'none',
      fontSize: '14px',
      fontWeight: 'bold',
      cursor: 'pointer',
      textAlign: 'center',
    },
    referButton: {
      flex: 1,
      padding: '10px',
      borderRadius: '5px',
      backgroundColor: style.Color.colorPeacockBlue,
      color: style.Color.colorWhite,
      border: 'none',
      fontSize: '14px',
      fontWeight: 'bold',
      cursor: 'pointer',
      textAlign: 'center',
    },
    paginationContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '20px',
      marginTop: '20px',
    },
    paginationButton: {
      backgroundColor: 'transparent',
      color: style.Color.colorDarkBlue,
      border: 'none',
      padding: '8px 15px',
      cursor: 'pointer',
      fontWeight: 'bold',
      display: 'flex',
      alignItems: 'center',
      gap: '5px',
    },
    pageInfo: {
      fontSize: '14px',
      color: style.Color.colorDarkGrey,
    },
    errorMessage: {
      textAlign: 'center',
      color: style.Color.colorRed,
      padding: '20px',
      fontSize: '16px',
    },
    searchResultsHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      margin: '0 0 20px 0',
      padding: '10px 0',
      borderBottom: `1px solid ${style.Color.colorLightGray}`,
    },
    statusBadge: {
      position: 'absolute',
      top: '10px',
      right: '10px',
      backgroundColor: style.Color.colorVibrantRed,
      color: style.Color.colorWhite,
      fontSize: '12px',
      fontWeight: 'bold',
      padding: '4px 8px',
      borderRadius: '4px',
      zIndex: 1,
    }
  };
export default DealsDashboard;