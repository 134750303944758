import React, { useState, useEffect } from 'react';
import { useNavigate , useLocation} from 'react-router-dom'; 
import { useAuth } from '../../../components/Common/InitialGears/AuthContext'; 
import {Color, RoseGoldButton, sameRowContainer} from "../../../GlobalStyles";  
import {  postApi, buildUrl} from "../../Common/BackendCalls/apiUtils";
import {InviteResults} from './InviteResults';

const WebInvites = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { queryLoginParam } = useAuth();
  const [contacts, setContacts] = useState([]);
  const [newContact, setNewContact] = useState({ name: '', email: '', phone: '' });
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [agreed, setAgreed] = useState(false);
  const [inviteResponse, setInviteResponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [groupWish, setGroupWish] = useState(null);
  const [groupWishTitle, setGroupWishTitle] = useState(null);
  const [groupWishId, setGroupWishId] = useState(null); 
  const [isCombo, setIsCombo] = useState(false);
  const [combo, setCombo] = useState(null);
  const [comboTitle, setComboTitle] = useState(null);
  const [comboId, setComboId] = useState(null); 
  

  useEffect(() => { 
    const styleElement = document.createElement('style');
    styleElement.type = 'text/css';
    styleElement.innerHTML = styles;
    document.head.appendChild(styleElement); 
    return () => {
      document.head.removeChild(styleElement);
    };
  }, []);

  useEffect(() => {   
    if (location.state && location.state.grp_wish) {
      setGroupWish(location.state.grp_wish);
      setGroupWishTitle(location.state.grp_wish.wish.product_name);
      setGroupWishId(location.state.grp_wish.wish.id);  
    } 
    else if (location.state && location.state.comboData) {
      setIsCombo(true); 
      setCombo(location.state.comboData);
      setComboTitle(location.state.comboData.title);
      setComboId(location.state.comboData.id);    
    } 
  }, [location]);

  const handleTermsClick = () => {
    setAgreed(!agreed);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewContact(prev => ({ ...prev, [name]: value }));
  };

  const addContact = () => {
    if (newContact.name && (newContact.email || newContact.phone)) {
      setContacts(prev => [...prev, { ...newContact, id: Date.now() }]);
      setNewContact({ name: '', email: '', phone: '' });
    }
  };

  const toggleContactSelection = (contactId) => {
    setSelectedContacts(prev => 
      prev.includes(contactId)
        ? prev.filter(id => id !== contactId)
        : [...prev, contactId]
    );
  };

  const calculateSavings = (listPrice, groupPrice) => {
    try {
      const parsedListPrice = parseFloat(listPrice);
      const parsedGroupPrice = parseFloat(groupPrice);
      
      if (isNaN(parsedListPrice) || isNaN(parsedGroupPrice)) { 
        return null;
      }
      
      const dollarSavings = parsedListPrice - parsedGroupPrice;
      const percentageSavings = (dollarSavings / parsedListPrice) * 100;
      
      return {
        dollarSavings: dollarSavings.toFixed(2),
        percentageSavings: percentageSavings.toFixed(1)
      };
    } catch (error) { 
      return null;
    }
  };

  const formatInviteTitle = (title, savings) => {
    if (!savings) return title;
    
    return `${title} - Save $${savings.dollarSavings} (${savings.percentageSavings}% off) with Group Buy!`;
  };

  const handleSendInvite = async () => {
    setErrorMessage('');
    setSuccessMessage('');
    setIsLoading(true);
    const selectedContactData = contacts.filter(contact => selectedContacts.includes(contact.id));
    
    try { 
      let genURL = buildUrl('sendInvite', queryLoginParam);      
      let requestBody = {}; 
      const contactsData = selectedContactData.map(contact => ({
        givenName: contact.name,
        familyName: '',
        phoneNumbers: contact.phone ? [{ number: contact.phone }] : [],
        emailAddresses: contact.email ? [{ email: contact.email }] : []
      }));

      if (isCombo) { 
        requestBody = {
          isCombo: true,
          dealId: comboId,
          comboId: comboId,
          comboTitle: comboTitle,
          Contacts: contactsData
        };
      } else {
        // Handle group wish invites
        let groupWishData = {};
        let listPrice = 0;
        let groupPrice = null;
        
        if (groupWish && groupWish.wish) {
          const priceRanges = groupWish.wish.price_ranges;
          listPrice = groupWish.wish.list_price;
          groupPrice = priceRanges && priceRanges.length > 0 ? priceRanges[0].range_price : null;
          
          if (listPrice && groupPrice) {
            const savings = calculateSavings(listPrice, groupPrice);
            const enhancedTitle = formatInviteTitle(groupWishTitle, savings);
            
            groupWishData = {
              groupWishId: groupWishId,
              groupWishEnhancedTitle: enhancedTitle,
              groupWishTitle: groupWishTitle,
              savings: savings
            };
          } else {
            groupWishData = {
              groupWishId: groupWishId,
              groupWishTitle: groupWishTitle
            };
          }
        }
        
        requestBody = {
          isCombo: false,
          ...groupWishData,
          Contacts: contactsData
        };
      }
    
      const response = await postApi(genURL, requestBody);  
      if (response.ok) {  
        setInviteResponse(response); 
      } else { 
        setErrorMessage('Failed to send invites. Please try again.');
      }
    } catch (error) {
      setErrorMessage(`Error while sending invites: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };
 
  return (
    <div style={styles.webInvitesContainer}>
      <h1>{isCombo ? `Invite to ${comboTitle}` : groupWish ? `Invite to ${groupWishTitle}` : 'Invite'}</h1>
      
      <div style={styles.addContactForm}>
        <input type="text" name="name" value={newContact.name} onChange={handleInputChange} placeholder="Name" style={styles.input}/>
        <input type="email" name="email" value={newContact.email} onChange={handleInputChange} placeholder="Email" style={styles.input}/>
        <input type="tel" name="phone" value={newContact.phone} onChange={handleInputChange} placeholder="Phone" style={styles.input}/>
        <button onClick={addContact} style={{...RoseGoldButton, ...styles.button}}>Add Contact</button>
      </div>

      <div style={styles.contactsList}>
        {contacts.map(contact => (
          <div  key={contact.id}  style={styles.contactItem}  onClick={() => toggleContactSelection(contact.id)}>          
            <input type="checkbox"  checked={selectedContacts.includes(contact.id)} onChange={() => {}} onClick={(e) => e.stopPropagation()}/>
            <span style={styles.contactInfo}>{contact.name} - {contact.email || contact.phone}</span>
          </div>
        ))}
      </div>

      <div style={styles.termsContainer}> 
        <div style={styles.termsRadioButtonContainer} onClick={handleTermsClick}>
          <span style={{...styles.checkbox, ...(agreed ? styles.checkedCheckbox : {})}}>
            {agreed ? '✓' : '×'}
          </span>
          <div style={styles.termsContent}>
            <p style={styles.inviteListHeading}>
              By inviting the listed contacts, I confirm I have their consent to send this SMS/Email invitation.
            </p>
            <div style={sameRowContainer}>
              <p style={styles.agreeStatement}>
                I also agree to BacksApp's{' '}
              </p>
              <a href="https://www.backsapp.com/privacy-policy" target="_blank" rel="noopener noreferrer" style={styles.termsLink}>
                Privacy Policy
              </a>
              <a href="https://www.backsapp.com/terms-of-service" target="_blank" rel="noopener noreferrer" style={styles.termsLink}>
                Terms of Service
              </a>
            </div>
          </div> 
        </div>
      </div>

      <button onClick={handleSendInvite} disabled={!agreed || selectedContacts.length === 0 || isLoading} style={{...RoseGoldButton,...styles.button, ...((!agreed || selectedContacts.length === 0 || isLoading) ? styles.disabledButton : {})}}>
        {isLoading ? 'Sending...' : 'Send Invite'}
      </button>

      {errorMessage && <div style={styles.errorMessage}>{errorMessage}</div>}
      {successMessage && <div style={styles.successMessage}>{successMessage}</div>}     
      {inviteResponse && <InviteResults inviteResponse={inviteResponse} isCombo={isCombo} />}   
    </div>
  );
};

const getCategoryTitle = (category) => {
  const titles = {
    invited_successfully: "Successfully Invited",
    already_using_backsapp: "Already Using BacksApp",
    already_in_group: "Already in Group",
    stop_messages: "Opted Out of Messages",
    invite_failed: "Invite Failed",
    phone_not_valid: "Invalid Phone Numbers"
  };
  return titles[category] || category;
};


const styles = {
  webInvitesContainer: {
    maxWidth: '100%',
    margin: '0 auto',
    padding: '20px',
    fontFamily: 'Arial, sans-serif',
    backgroundColor: Color.colorLightBeige,
    color: Color.colorPeacockBlueDark,
    borderRadius: '8px',
  },
  addContactForm: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    marginBottom: '20px',
    padding: '10px',
    borderRadius: '8px',
  },
  input: {
    padding: '15px',
    border: '1px solid #ccc',
    borderRadius: '8px',
  },
  button: {
    padding: '15px', 
    color: 'white',
    border: 'none',
    borderRadius: '8px',
    cursor: 'pointer',
    fontSize: '16px',
    fontWeight: 'bold',
    alignItems: 'center',
    marginLeft: '30%',
    width: '40%',
  },
  disabledButton: {
    backgroundColor: Color.colorRoseGold,
    cursor: 'not-allowed',
  },
  contactsList: {
    marginBottom: '20px',
    padding: '15px',
  },
  contactItem: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    marginBottom: '5px',
    padding: '15px',
  },
  termsContainer: {
    marginBottom: '20px',
    backgroundColor: Color.colorLightestBeige,
    padding: '15px',
    borderRadius: '8px',
  },
  termsRadioButtonContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    cursor: 'pointer',
    padding: '15px',
    paddingTop: '20px',
  },
  checkbox: {
    display: 'inline-block',
    width: '20px',
    height: '20px',
    border: `2px solid ${Color.colorRoseGoldText}`,
    borderRadius: '8px',
    textAlign: 'center',
    lineHeight: '18px',
    marginRight: '10px',
    color: Color.colorRoseGoldText,
    fontWeight: 'bold',
    padding: '15px',
  },
  checkedCheckbox: {
    backgroundColor: Color.colorRoseGoldText,
    color: 'white',
  },
  termsContent: {
    flex: 1,
  },
  inviteListHeading: {
    fontSize: '14px',
    color: Color.colorRoseGoldText,
    marginBottom: '10px',
  },
  agreeStatement: {
    fontSize: '14px',
    color: Color.colorPeacockBlueDark,
    marginBottom: '5px',
  },
  termsLink: {
    display: 'block',
    color: Color.colorPeacockBlueDark,
    fontSize: '14px',
    fontWeight: 500,
    textDecoration: 'underline',
    marginTop: '5px',
  },
  errorMessage: {
    color: '#dc3545',
    marginTop: '10px',
  },
  successMessage: {
    color: '#28a745',
    marginTop: '10px',
  },
  inviteResponse: {
    marginTop: '20px',
    borderTop: '1px solid #ccc',
    padding: '20px',
    backgroundColor: Color.colorLimeGreen,
    borderRadius: '8px',
  },
  responseCategory: {
    marginBottom: '15px',
  },
  responseCategoryHeader: {
    fontSize: '16px',
    color: Color.colorRoyalBlueText,
    marginBottom: '5px',
    backgroundColor: Color.colorLimeGreen,
    paddingLeft: '20px',
  },
  responseCategoryList: {
    listStyleType: 'none',
    paddingLeft: '20px',
  },
  responseCategoryItem: {
    fontSize: '14px',
    color: Color.colorPurple,
    marginBottom: '3px',
    paddingLeft: '10px',
  },
};

export default WebInvites;