import React, { createContext, useContext, useCallback, useState, useRef } from 'react';
import { getApi, buildUrl } from "../../Common/BackendCalls/apiUtils";

const GroupWishCacheContext = createContext(null);

export const GroupWishCacheProvider = ({ children }) => {
  const pendingRequests = useRef(new Map());
  
  const [cache, setCache] = useState({
    data: new Map(),
    lastUpdated: new Map(),
    expiryTime: 5 * 60 * 1000 // Fixed: 5 minutes cache expiry
  });

  const generateCacheKey = useCallback((endpoint, params) => {
    return `${endpoint}_${JSON.stringify(params)}`;
  }, []);

  const isDataValid = useCallback((key) => {
    if (!cache.data.has(key) || !cache.lastUpdated.has(key)) return false;
    
    const lastUpdated = cache.lastUpdated.get(key);
    return Date.now() - lastUpdated < cache.expiryTime;
  }, [cache]);

  const getCachedData = useCallback((key) => {
    if (!isDataValid(key)) return null;
    return cache.data.get(key);
  }, [cache, isDataValid]);

  const setCachedData = useCallback((key, data) => {
    setCache(prevCache => {
      const newData = new Map(prevCache.data);
      const newLastUpdated = new Map(prevCache.lastUpdated);
      
      newData.set(key, data);
      newLastUpdated.set(key, Date.now());
      
      return {
        ...prevCache,
        data: newData,
        lastUpdated: newLastUpdated
      };
    });
  }, []);

  const updateCachedFields = useCallback((key, updateFn) => {
    setCache(prevCache => {
      if (!prevCache.data.has(key)) return prevCache;

      const newData = new Map(prevCache.data);
      const currentData = newData.get(key);
      const updatedData = updateFn(currentData);
      newData.set(key, updatedData);

      const newLastUpdated = new Map(prevCache.lastUpdated);
      newLastUpdated.set(key, Date.now());

      return {
        ...prevCache,
        data: newData,
        lastUpdated: newLastUpdated
      };
    });
  }, []);

  const clearCache = useCallback((key) => {
    setCache(prevCache => {
      const newData = new Map(prevCache.data);
      const newLastUpdated = new Map(prevCache.lastUpdated);
      
      newData.delete(key);
      newLastUpdated.delete(key);
      
      return {
        ...prevCache,
        data: newData,
        lastUpdated: newLastUpdated
      };
    });
  }, []);

  const clearAllCache = useCallback(() => {
    setCache(prevCache => ({
      ...prevCache,
      data: new Map(),
      lastUpdated: new Map()
    }));
  }, []);

  const fetchWithCache = useCallback(async (endpoint, params = {}, forceRefresh = false) => {
    const cacheKey = generateCacheKey(endpoint, params);
    
    if (pendingRequests.current.has(cacheKey)) {
      return pendingRequests.current.get(cacheKey);
    }

    if (!forceRefresh) {
      const cachedData = getCachedData(cacheKey);
      if (cachedData) return cachedData;
    }

    try {
      const promise = (async () => {
        const url = buildUrl(endpoint, params);
        const response = await getApi(url);
        
        if (response && response.data) {
          setCachedData(cacheKey, response.data);
          return response.data;
        }
        throw new Error('Failed to fetch data');
      })();

      pendingRequests.current.set(cacheKey, promise);
      const result = await promise;
      pendingRequests.current.delete(cacheKey);
      return result;
    } catch (error) {
      pendingRequests.current.delete(cacheKey); 
      throw error;
    }
  }, [generateCacheKey, getCachedData, setCachedData]);

  const value = {
    fetchWithCache,
    getCachedData,
    setCachedData,
    updateCachedFields,
    clearCache,
    clearAllCache,
    isDataValid,
    generateCacheKey
  };

  return (
    <GroupWishCacheContext.Provider value={value}>
      {children}
    </GroupWishCacheContext.Provider>
  );
};

export const useApiCache = () => {
  const context = useContext(GroupWishCacheContext);
  if (!context) {
    throw new Error('useApiCache must be used within an GroupWishCacheProvider');
  }
  return context;
};

export const useWishListCache = (queryLoginParam) => {
  const { fetchWithCache, updateCachedFields, clearCache, generateCacheKey } = useApiCache();
  
  const loadWishList = useCallback(async (limit = 5) => {
    return await fetchWithCache('getMyWishes', `${queryLoginParam}&limit=${limit}`);
  }, [fetchWithCache, queryLoginParam]);

  const updateWishList = useCallback((updateFn) => {
    const cacheKey = generateCacheKey('getMyWishes', queryLoginParam);
    updateCachedFields(cacheKey, updateFn);
  }, [updateCachedFields, generateCacheKey, queryLoginParam]);

  const invalidateCache = useCallback(() => {
    const cacheKey = generateCacheKey('getMyWishes', queryLoginParam);
    clearCache(cacheKey);
  }, [clearCache, generateCacheKey, queryLoginParam]);

  return {
    loadWishList,
    updateWishList,
    invalidateCache
  };
};

export const useCategoriesCache = (queryLoginParam) => {
  const { fetchWithCache, clearCache, generateCacheKey } = useApiCache();
  
  const loadCategories = useCallback(async () => {
    return await fetchWithCache('getCategories', queryLoginParam);
  }, [fetchWithCache, queryLoginParam]);

  const invalidateCache = useCallback(() => {
    const cacheKey = generateCacheKey('getCategories', queryLoginParam);
    clearCache(cacheKey);
  }, [clearCache, generateCacheKey, queryLoginParam]);

  return {
    loadCategories,
    invalidateCache
  };
};

export const useGroupWishesCache = (queryLoginParam) => {
  const { fetchWithCache, clearCache, generateCacheKey } = useApiCache();
  
  const loadGroupWishes = useCallback(async () => {
    try {
      const data = await fetchWithCache('groupWishes', queryLoginParam);
      if (data) {
        const { user_group_wishes, connection_group_wishes, additional_group_wishes } = data;
        return {
          userGroupWishes: user_group_wishes || [],
          connectionGroupWishes: connection_group_wishes || [],
          latestGroupWishes: additional_group_wishes || []
        };
      }
      throw new Error('Failed to load group wishes');
    } catch (error) { 
      throw error;
    }
  }, [fetchWithCache, queryLoginParam]);

  const invalidateCache = useCallback(() => {
    const cacheKey = generateCacheKey('groupWishes', queryLoginParam);
    clearCache(cacheKey);
  }, [clearCache, generateCacheKey, queryLoginParam]);

  return {
    loadGroupWishes,
    invalidateCache
  };
};

export const useWishedCategoriesCache = (queryLoginParam) => {
  const { fetchWithCache, clearCache, generateCacheKey } = useApiCache();
  
  const loadWishedCategories = useCallback(async () => {
    return await fetchWithCache('getMyWishedCategories', queryLoginParam);
  }, [fetchWithCache, queryLoginParam]);

  const invalidateCache = useCallback(() => {
    const cacheKey = generateCacheKey('getMyWishedCategories', queryLoginParam);
    clearCache(cacheKey);
  }, [clearCache, generateCacheKey, queryLoginParam]);

  return {
    loadWishedCategories,
    invalidateCache
  };
};

export const useWishTrendsCache = (queryLoginParam) => {
  const { fetchWithCache, clearCache, generateCacheKey } = useApiCache();
  
  const loadWishTrends = useCallback(async (limit = 3) => {
    return await fetchWithCache('getWishTrends', `${queryLoginParam}&limit=${limit}`);
  }, [fetchWithCache, queryLoginParam]);

  const invalidateCache = useCallback(() => {
    const cacheKey = generateCacheKey('getWishTrends', queryLoginParam);
    clearCache(cacheKey);
  }, [clearCache, generateCacheKey, queryLoginParam]);

  return {
    loadWishTrends,
    invalidateCache
  };
};

export const useGroupWishCombosCache = (queryLoginParam) => {
  const { fetchWithCache, clearCache, generateCacheKey, updateCachedFields } = useApiCache();
  
  const loadGroupWishCombos = useCallback(async () => {
    try {
      const data = await fetchWithCache('Combos', queryLoginParam); 
      if (data) { 
        return data;  
      }
      throw new Error('Failed to load group wish combos');
    } catch (error) {  
      throw error;
    }
  }, [fetchWithCache, queryLoginParam]);

  const loadComboDetails = useCallback(async (comboId) => {
    try {
      const data = await fetchWithCache(
        `Combos/${comboId}`, 
        queryLoginParam
      );
      return data;
    } catch (error) {
      throw error;
    }
  }, [fetchWithCache, queryLoginParam]);

  const updateComboCache = useCallback((comboId, updateFn) => {
    const cacheKey = generateCacheKey('Combos', queryLoginParam);
    updateCachedFields(cacheKey, (currentData) => {
      if (!currentData) return currentData; 
      const updatedCombos = currentData.combos.map(combo => 
        combo.uuid === comboId ? updateFn(combo) : combo
      );

      return {
        ...currentData,
        combos: updatedCombos
      };
    });
  }, [updateCachedFields, generateCacheKey, queryLoginParam]);

  const invalidateCache = useCallback(() => {
    const combosKey = generateCacheKey('Combos', queryLoginParam);
    clearCache(combosKey);
  }, [clearCache, generateCacheKey, queryLoginParam]);

  const invalidateComboCache = useCallback((comboId) => {
    const detailKey = generateCacheKey(
      `Combos/${comboId}`, 
      queryLoginParam
    );
    clearCache(detailKey);
  }, [clearCache, generateCacheKey, queryLoginParam]);

  return {
    loadGroupWishCombos,
    loadComboDetails,
    updateComboCache,
    invalidateCache,
    invalidateComboCache
  };
};
 
export const useComboItemsCache = (queryLoginParam) => {
  const { fetchWithCache, clearCache, generateCacheKey } = useApiCache();
  
  const loadComboItems = useCallback(async (comboId) => {
    try {
      const data = await fetchWithCache(
        `Combos/${comboId}/items`, 
        queryLoginParam
      );
      return data || [];
    } catch (error) {
      throw error;
    }
  }, [fetchWithCache, queryLoginParam]);

  const invalidateItemsCache = useCallback((comboId) => {
    const cacheKey = generateCacheKey(
      `Combos/${comboId}/items`, 
      queryLoginParam
    );
    clearCache(cacheKey);
  }, [clearCache, generateCacheKey, queryLoginParam]);

  return {
    loadComboItems,
    invalidateItemsCache
  };
};