import React, { useState, useEffect, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faTimes } from '@fortawesome/free-solid-svg-icons';

const RatingSlider = ({ onRate, onClose }) => {
  const [starRating, setStarRating] = useState(0);
  const [hoveredRating, setHoveredRating] = useState(0);
  const [ratingSubmitting, setRatingSubmitting] = useState(false);
  const [animateThankYou, setAnimateThankYou] = useState(false);

  const handleStarRating = useCallback((value) => {
    setStarRating(value);
    setRatingSubmitting(true); 
    setAnimateThankYou(true);
  }, []);

  useEffect(() => {
    let timer;
    if (starRating > 0 && ratingSubmitting) {
      timer = setTimeout(() => {
        onRate(starRating);
        onClose();
      }, 2000);  
    }
    return () => clearTimeout(timer);
  }, [starRating, ratingSubmitting, onRate, onClose]);

  const ratingValues = [1, 2, 3, 4, 5];
  
  const ratingLabels = {
    1: "Poor",
    2: "Fair",
    3: "Good",
    4: "Great",
    5: "Excellent"
  };
 
  const renderRatingButtons = useCallback(() => {
    return ratingValues.map((value) => (
      <button 
        key={`star-${value}`} 
        className="rate-button" 
        onClick={() => handleStarRating(value)}
        onMouseEnter={() => setHoveredRating(value)}
        onMouseLeave={() => setHoveredRating(0)}
        aria-label={`Rate ${value} stars - ${ratingLabels[value]}`}
        disabled={ratingSubmitting}
      >
        <FontAwesomeIcon 
          icon={faStar} 
          className={`star-icon ${
            starRating >= value || 
            (hoveredRating >= value && !ratingSubmitting) ? 'active' : ''
          }`}
        />
      </button>
    ));
  }, [starRating, hoveredRating, ratingSubmitting, handleStarRating]);

  const displayRating = hoveredRating || starRating;
  
  return (
    <div className="modern-rating-container">
      <button className="close-button" onClick={onClose} aria-label="Close rating dialog"> 
        <FontAwesomeIcon icon={faTimes} />
      </button>
      
      <h2 className="rating-title">How was your experience?</h2>
      
      <div className="rating-display">
        {displayRating > 0 && !ratingSubmitting && (
          <div className="rating-emoji">{getRatingEmoji(displayRating)}</div>
        )}
        {displayRating > 0 && !ratingSubmitting && (
          <div className="rating-label">{ratingLabels[displayRating]}</div>
        )}
        {ratingSubmitting && (
          <div className={`thank-you-message ${animateThankYou ? 'animate' : ''}`}>
            <div className="emoji">✨</div>
            <div className="message">Thank you for your feedback!</div>
          </div>
        )}
      </div>
      
      <div className="stars-container">
        {renderRatingButtons()}
      </div>
      
      <div className="rating-progress">
        <div 
          className="progress-bar" 
          style={{ 
            width: `${(displayRating / 5) * 100}%`,
            backgroundColor: getColorForRating(displayRating)
          }} 
        />
      </div>
      
      <div className="rating-labels">
        {ratingValues.map(value => (
          <span 
            key={`label-${value}`} 
            className={`label ${displayRating >= value ? 'active' : ''}`}
          >
            {value}
          </span>
        ))}
      </div>
      
      {ratingSubmitting && (
        <div className="pulse-effect"></div>
      )}
    </div>
  );
};

// Helper function to get emoji for rating
function getRatingEmoji(rating) {
  if (rating <= 1) return '😔';
  if (rating <= 2) return '🙁';
  if (rating <= 3) return '😐';
  if (rating <= 4) return '🙂';
  return '😄';
}

// Helper function to get color for rating
function getColorForRating(rating) {
  if (rating === 0) return 'transparent';
  if (rating <= 1) return '#ff5252';  // Red for very poor
  if (rating <= 2) return '#ff9800';  // Orange for poor
  if (rating <= 3) return '#ffc107';  // Amber for average
  if (rating <= 4) return '#8bc34a';  // Light green for good
  return '#4caf50';                  // Green for excellent
}

// Add styles
const styles = `
  .modern-rating-container {
    display: flex;
    flex-direction: column;
    align-items: center; 
    padding: 40px 30px;
    border-radius: 24px;
    position: relative;
    width: 700px;
    max-width: 95%;
    background-color: white;
    box-shadow: 0 25px 50px -12px rgba(123, 63, 85, 0.25);
    transition: all 0.3s ease;
  }
  
  .rating-title {
    font-size: 26px;
    color: #7B3F55;
    margin-bottom: 35px;
    font-weight: 600;
    text-align: center;
  }

  .close-button {
    position: absolute;
    top: 20px;
    right: 20px;
    background: none;
    border: none;
    cursor: pointer;
    color: #888;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s;
    z-index: 5;
  }
  
  .close-button:hover {
    background-color: #f5f5f5;
    color: #333;
    transform: rotate(90deg);
  }

  .rating-display {
    height: 80px;
    margin-bottom: 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease;
  }
  
  .rating-emoji {
    font-size: 40px;
    margin-bottom: 10px;
    animation: bounce 0.5s ease;
  }
  
  .rating-label {
    font-size: 22px;
    font-weight: 500;
    color: #7B3F55;
    transition: all 0.3s ease;
    animation: fadeIn 0.3s ease;
  }
  
  .stars-container {
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-bottom: 30px;
  }

  .rate-button {
    background: none;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease;
    padding: 10px;
    position: relative;
  }
  
  .rate-button:hover {
    transform: translateY(-5px);
  }
  
  .star-icon {
    font-size: 38px;
    color: #e0e0e0;
    transition: all 0.3s ease;
    filter: drop-shadow(0 4px 6px rgba(0, 0, 0, 0.1));
  }
  
  .star-icon.active {
    color: #FFD700;
    filter: drop-shadow(0 0 8px rgba(255, 215, 0, 0.6));
  }
  
  .rating-progress {
    width: 100%;
    height: 10px;
    background-color: #f0f0f0;
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 15px;
  }
  
  .progress-bar {
    height: 100%;
    width: 0;
    border-radius: 10px;
    transition: width 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275), 
                background-color 0.5s ease;
  }
  
  .rating-labels {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 10px;
  }
  
  .rating-labels .label {
    font-size: 14px;
    color: #999;
    font-weight: 500;
    transition: all 0.3s ease;
  }
  
  .rating-labels .label.active {
    color: #7B3F55;
    font-weight: 600;
  }
  
  .thank-you-message {
    display: flex;
    flex-direction: column;
    align-items: center;
    opacity: 0;
    transform: translateY(20px);
  }
  
  .thank-you-message.animate {
    animation: slideUp 0.5s forwards;
  }
  
  .thank-you-message .emoji {
    font-size: 40px;
    margin-bottom: 10px;
  }
  
  .thank-you-message .message {
    font-size: 22px;
    font-weight: 600;
    color: #4caf50;
  }
  
  .pulse-effect {
    position: absolute;
    bottom: -20px;
    width: 80px;
    height: 5px;
    background-color: #7B3F55;
    border-radius: 10px;
    animation: pulse 1.5s infinite;
  }
  
  @keyframes fadeIn {
    from { opacity: 0; transform: translateY(10px); }
    to { opacity: 1; transform: translateY(0); }
  }
  
  @keyframes bounce {
    0% { transform: scale(0.8); }
    50% { transform: scale(1.2); }
    100% { transform: scale(1); }
  }
  
  @keyframes slideUp {
    to { opacity: 1; transform: translateY(0); }
  }
  
  @keyframes pulse {
    0% { opacity: 0.6; transform: scaleX(1); }
    50% { opacity: 0.4; transform: scaleX(0.8); }
    100% { opacity: 0.6; transform: scaleX(1); }
  }
  
  /* Responsive styles */
  @media (max-width: 600px) {
    .modern-rating-container {
      padding: 30px 20px;
    }
    
    .rating-title {
      font-size: 22px;
      margin-bottom: 25px;
    }
    
    .star-icon {
      font-size: 32px;
    }
    
    .stars-container {
      gap: 8px;
    }
    
    .rating-emoji {
      font-size: 36px;
    }
    
    .rating-label {
      font-size: 18px;
    }
  }
`;

// Add styles to the document
if (typeof document !== 'undefined') {
  const styleSheet = document.createElement("style");
  styleSheet.type = "text/css";
  styleSheet.innerText = styles;
  document.head.appendChild(styleSheet);
}

export default RatingSlider;