import React, { useState, useEffect } from 'react';
import { useNavigate,useParams } from 'react-router-dom'; 
import { useAuth } from '../../Common/InitialGears/AuthContext';
import { getApi, buildUrl } from "../../Common/BackendCalls/apiUtils";
import { TopMenuBar } from '../../Buyers/DashbordComponents/TopMenuBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faFilter, faCalendarAlt, faTags } from '@fortawesome/free-solid-svg-icons';
import * as styles1 from '../../../GlobalStyles';
import InvoiceCard from './InvoiceCard';

const InvoiceListScreen = () => {
  const navigate = useNavigate();
  const { queryLoginParam } = useAuth();
  const { invoiceId, groupWishId, comboId } = useParams();  
  const [invoices, setInvoices] = useState([]);
  const [filteredInvoices, setFilteredInvoices] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const [typeFilter, setTypeFilter] = useState('all');  
  const [searchTerm, setSearchTerm] = useState('');
  const [dateFilter, setDateFilter] = useState('');
  const [productFilter, setProductFilter] = useState('');
  const [showFilters, setShowFilters] = useState(false);
  const [dateRange, setDateRange] = useState({
    startDate: '',
    endDate: ''
  });

  useEffect(() => {
    document.title = 'My Invoices';
    fetchInvoices();
  }, []);

  useEffect(() => {
    let filtered = [...invoices]; 
    
    // Apply search term filter
    if (searchTerm) {
      filtered = filtered.filter(invoice => 
        invoice.invoice_number.toLowerCase().includes(searchTerm.toLowerCase()) ||
        invoice.invoice_item.description.toLowerCase().includes(searchTerm.toLowerCase())
      );
    } 
    
    // Apply date range filter
    if (dateRange.startDate && dateRange.endDate) {
      filtered = filtered.filter(invoice => {
        const invoiceDate = new Date(invoice.invoice_date);
        const start = new Date(dateRange.startDate);
        const end = new Date(dateRange.endDate);
        return invoiceDate >= start && invoiceDate <= end;
      });
    }
   
    // Apply product filter
    if (productFilter) {
      filtered = filtered.filter(invoice =>
        invoice.invoice_item.description.toLowerCase().includes(productFilter.toLowerCase())
      );
    }
    
    // Apply type filter (combo vs group wish)
    if (typeFilter !== 'all') {
      if (typeFilter === 'combo') {
        filtered = filtered.filter(invoice => invoice.is_combo === true);
      } else if (typeFilter === 'groupWish') {
        filtered = filtered.filter(invoice => !!invoice.group_wish_id);
      }
    }
  
    setFilteredInvoices(filtered);
  }, [searchTerm, dateRange, productFilter, typeFilter, invoices]);
  
  const fetchInvoices = async () => {
    try {
      const invoicesURL = buildUrl('invoices', queryLoginParam);
      const response = await getApi(invoicesURL);   

      if (response && response.ok && response.data) {
        setInvoices(response.data);
        setFilteredInvoices(response.data);
      } else {
        handleError(response);
      }
    } catch (error) {
      handleFetchError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleError = (response) => {
    if (response?.status === 401 || response?.data?.error === 'token_expired') {
      setErrorMessage("Your session has expired. Please login again.");
      setTimeout(() => {
        window.location.href = '/login';
      }, 3000);
    } else if (response?.status === 404) {
      setErrorMessage("No invoices found.");
    } else {
      setErrorMessage(response?.data?.error || "Failed to fetch invoices");
    }
  };

  const handleFetchError = (error) => {
    if (error.message?.includes('NetworkError') || !navigator.onLine) {
      setErrorMessage("Please check your internet connection and try again.");
    } else {
      setErrorMessage("An unexpected error occurred. Please try again later.");
    }
  };

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    }).format(amount);
  };
 
  useEffect(() => {
    let filtered = [...invoices]; 
    if (searchTerm) {
      filtered = filtered.filter(invoice => 
        invoice.invoice_number.toLowerCase().includes(searchTerm.toLowerCase()) ||
        invoice.invoice_item.description.toLowerCase().includes(searchTerm.toLowerCase())
      );
    } 
    if (dateRange.startDate && dateRange.endDate) {
      filtered = filtered.filter(invoice => {
        const invoiceDate = new Date(invoice.invoice_date);
        const start = new Date(dateRange.startDate);
        const end = new Date(dateRange.endDate);
        return invoiceDate >= start && invoiceDate <= end;
      });
    }
 
    if (productFilter) {
      filtered = filtered.filter(invoice =>
        invoice.invoice_item.description.toLowerCase().includes(productFilter.toLowerCase())
      );
    }

    setFilteredInvoices(filtered);
  }, [searchTerm, dateRange, productFilter, invoices]);

  const handleInvoiceClick = (invoiceId, groupWishId, comboId) => {
    let path = `/invoice/${invoiceId}`; 
    if (comboId) {
      path = `/invoice/${invoiceId}/combo_id/${comboId}`;
    } else if (groupWishId) {
      path = `/invoice/${invoiceId}/groupWishId/${groupWishId}`; 
    }
    navigate(path, { state: { from: 'notifications' } });   
  };

  const resetFilters = () => {
    setSearchTerm('');
    setDateRange({ startDate: '', endDate: '' });
    setProductFilter('');
    setShowFilters(false);
  };

  if (isLoading) {
    return (
      <div style={styles.loadingContainer}>
        <div className="spinner"></div>
      </div>
    );
  }

  if (errorMessage) {
    return (
      <div style={styles.mainContainer}>
        <div style={styles.contentContainer}>
          <div style={styles.errorCard}>
            <div style={styles.errorIconContainer}>
              <svg style={styles.errorIcon} viewBox="0 0 24 24" fill="none" stroke="currentColor">
                <circle cx="12" cy="12" r="10" />
                <line x1="12" y1="8" x2="12" y2="12" />
                <line x1="12" y1="16" x2="12.01" y2="16" />
              </svg>
            </div>
            <h2 style={styles.errorTitle}>Error</h2>
            <p style={styles.errorMessage}>{errorMessage}</p>
            <button 
              onClick={() => window.location.reload()} 
              style={styles.retryButton}
            >
              Try Again
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div style={styles.mainContainer}>
    <TopMenuBar showHomeButton={true} title= {"My Invoices"}  />
      <div style={styles.contentContainer}>
        <div style={styles.pageHeader}>
          <h1 style={styles.pageTitle}>My Invoices</h1>
          
          {/* Search and Filter Section */}
          <div style={styles.searchFilterContainer}>
            <div style={styles.searchContainer}>
              <FontAwesomeIcon icon={faSearch} style={styles.searchIcon} />
              <input
                type="text"
                placeholder="Search invoices..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                style={styles.searchInput}
              />
            </div>
            
            <button onClick={() => setShowFilters(!showFilters)} style={styles.filterButton}>
              <FontAwesomeIcon icon={faFilter} style={styles.buttonIcon} />
              Filters
            </button>
          </div>

          {/* Filter Panel */}
          {showFilters && (
            
            <div style={styles.filterPanel}>
              <div style={styles.filterGroup}>
                <div style={styles.filterHeader}>
                  <FontAwesomeIcon icon={faTags} style={styles.filterIcon} />
                  <span>Invoice Type</span>
                </div>
                <div style={styles.typeFilterButtons}>
                  <button 
                    onClick={() => setTypeFilter('all')} 
                    style={{
                      ...styles.typeFilterButton,
                      backgroundColor: typeFilter === 'all' ? styles1.Color.colorPeacockBlueDark : styles1.Color.colorLightBeigeStrong,
                      color: typeFilter === 'all' ? '#FFFFFF' : styles1.Color.colorPeacockBlueDark,
                    }}
                  >
                    All
                  </button>
                  <button 
                    onClick={() => setTypeFilter('combo')} 
                    style={{
                      ...styles.typeFilterButton,
                      backgroundColor: typeFilter === 'combo' ? styles1.Color.colorPeacockBlueDark : styles1.Color.colorLightBeigeStrong,
                      color: typeFilter === 'combo' ? '#FFFFFF' : styles1.Color.colorPeacockBlueDark,
                    }}
                  >
                    Combo
                  </button>
                  <button 
                    onClick={() => setTypeFilter('groupWish')} 
                    style={{
                      ...styles.typeFilterButton,
                      backgroundColor: typeFilter === 'groupWish' ? styles1.Color.colorPeacockBlueDark : styles1.Color.colorLightBeigeStrong,
                      color: typeFilter === 'groupWish' ? '#FFFFFF' : styles1.Color.colorPeacockBlueDark,
                    }}
                  >
                    Group Wishes
                  </button>
                </div>
              </div>

              <div style={styles.filterGroup}>
                <div style={styles.filterHeader}>
                  <FontAwesomeIcon icon={faCalendarAlt} style={styles.filterIcon} />
                  <span>Date Range</span>
                </div>
                <div style={styles.dateInputs}>
                  <input
                    type="date"
                    value={dateRange.startDate}
                    onChange={(e) => setDateRange({...dateRange, startDate: e.target.value})}
                    style={styles.dateInput}
                  />
                  <span style={styles.dateInputSeparator}>to</span>
                  <input
                    type="date"
                    value={dateRange.endDate}
                    onChange={(e) => setDateRange({...dateRange, endDate: e.target.value})}
                    style={styles.dateInput}
                  />
                </div>
              </div>

              <div style={styles.filterGroup}>
                <div style={styles.filterHeader}>
                  <FontAwesomeIcon icon={faTags} style={styles.filterIcon} />
                  <span>Product</span>
                </div>
                <input
                  type="text"
                  placeholder="Filter by product..."
                  value={productFilter}
                  onChange={(e) => setProductFilter(e.target.value)}
                  style={styles.filterInput}
                />
              </div>

              <button onClick={resetFilters} style={styles.resetButton}>
                Reset Filters
              </button>
            </div>
          )}
        </div>

        {/* Invoices List */}
        <div style={styles.invoicesList}>
        {filteredInvoices.map((invoice) => (
          <InvoiceCard
            key={invoice.invoice_id}
            invoice={invoice}
            onClick={handleInvoiceClick}
          />
        ))}
          
          {filteredInvoices.length === 0 && (
            <div style={styles.noInvoicesMessage}>
              No invoices found matching your criteria
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const styles = {
  mainContainer: {
    minHeight: '100vh',
    backgroundColor: '#F5F5F5',
    paddingBottom: '40px',
  },
  contentContainer: {
    maxWidth: '1200px',
    margin: '0 auto',
    padding: '20px',
    paddingTop: '100px',
  },
  pageHeader: {
    marginBottom: '30px',
  },
  pageTitle: {
    fontSize: '28px',
    fontWeight: 'bold',
    color: styles1.Color.colorPeacockBlueDark,
    marginBottom: '20px',
  },
  searchFilterContainer: {
    display: 'flex',
    gap: '12px',
  },
  detailGroup: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  detailLabel: {
    fontSize: '14px',
    color: '#666666',
  },
  detailValue: {
    fontSize: '14px',
    color: styles1.Color.colorRoyalBlueText,
    fontWeight: '500',
  },
  savingsTag: {
    alignSelf: 'flex-start',
    backgroundColor: '#E8F5E9',
    color: '#2E7D32',
    padding: '4px 8px',
    borderRadius: '4px',
    fontSize: '12px',
    fontWeight: '500',
  },
  noInvoicesMessage: {
    gridColumn: '1 / -1',
    textAlign: 'center',
    padding: '40px',
    backgroundColor: '#FFFFFF',
    borderRadius: '8px',
    color: '#666666',
    fontSize: '16px',
  },
  loadingContainer: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
    zIndex: 998,
  },
  errorCard: {
    backgroundColor: '#FFFFFF',
    borderRadius: '12px',
    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.15)',
    padding: '40px',
    textAlign: 'center',
    maxWidth: '500px',
    margin: '40px auto',
  },
  errorIconContainer: {
    marginBottom: '20px',
  },
  errorIcon: {
    width: '64px',
    height: '64px',
    color: '#F44336',
    margin: '0 auto',
    strokeWidth: '1.5',
  },
  errorTitle: {
    fontSize: '24px',
    fontWeight: 'bold',
    color: '#333333',
    marginBottom: '16px',
  },
  errorMessage: {
    fontSize: '16px',
    color: '#666666',
    marginBottom: '24px',
    lineHeight: '1.5',
  }, 
  searchContainer: {
    flex: 1,
    position: 'relative',
  },
  searchIcon: {
    position: 'absolute',
    left: '12px',
    top: '50%',
    transform: 'translateY(-50%)',
    color: '#666666',
  },
  searchInput: {
    width: '100%',
    padding: '12px 12px 12px 40px',
    borderRadius: '8px',
    border: '1px solid #E0E0E0',
    fontSize: '14px',
    backgroundColor: '#FFFFFF',
  },
  filterButton: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: styles1.Color.colorPeacockBlueDark,
    color: '#FFFFFF',
    padding: '12px 24px',
    borderRadius: '8px',
    border: 'none',
    cursor: 'pointer',
    fontSize: '14px',
    fontWeight: '500',
  },
  buttonIcon: {
    marginRight: '8px',
  },
  filterPanel: {
    backgroundColor: '#FFFFFF',
    padding: '20px',
    borderRadius: '8px',
    marginBottom: '20px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
  },
  filterGroup: {
    marginBottom: '16px',
  },
  filterHeader: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '8px',
    color: styles1.Color.colorTeal,
    fontWeight: '500',
  },
  filterIcon: {
    marginRight: '8px',
  },
  dateInputs: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },
  dateInput: {
    padding: '8px',
    borderRadius: '4px',
    border: '1px solid #E0E0E0',
    fontSize: '14px',
  },
  dateInputSeparator: {
    color: '#666666',
  },
  filterInput: {
    width: '100%',
    padding: '8px',
    borderRadius: '4px',
    border: '1px solid #E0E0E0',
    fontSize: '14px',
  },
  resetButton: {
    backgroundColor: '#F5F5F5',
    color: '#666666',
    padding: '8px 16px',
    borderRadius: '4px',
    border: 'none',
    cursor: 'pointer',
    fontSize: '14px',
  },
  invoicesList: {
    display: 'grid',
    gap: '20px',
    gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
  },
  invoiceCard: {
    backgroundColor: '#FFFFFF',
    borderRadius: '12px',
    padding: '20px',
    cursor: 'pointer',
    transition: 'transform 0.2s, box-shadow 0.2s',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
    '&:hover': {
      transform: 'translateY(-2px)',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    },
  },
  invoiceCardHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '16px',
    paddingBottom: '12px',
    borderBottom: '1px solid #E0E0E0',
  },
  invoiceNumber: {
    fontSize: '16px',
    fontWeight: '600',
    color: styles1.Color.colorPurple,
  },
  invoiceDate: {
    fontSize: '14px',
    color: '#666666',
  },
  typeFilterButtons: {
    display: 'flex',
    gap: '8px',
  },
  typeFilterButton: {
    flex: 1,
    padding: '8px',
    borderRadius: '4px',
    border: 'none',
    cursor: 'pointer',
    fontSize: '14px',
    fontWeight: '500',
    transition: 'background-color 0.2s',
}
}
export default InvoiceListScreen;