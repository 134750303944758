import React from 'react';
import * as style from "../../../GlobalStyles";

export const InviteResults = ({ inviteResponse, isCombo }) => {
  if (!inviteResponse) {
    return null;
  }

  const responseData = inviteResponse.data || inviteResponse;
  
  // Dynamic group name based on isCombo flag
  const groupName = isCombo ? "ComboGroup" : "Group";
  
  const categories = [
    {
      key: 'invited_successfully',
      title: 'Successfully Invited',
    },
    {
      key: 'already_using_backsapp',
      title: 'Already Using BacksApp',
    },
    {
      key: 'already_in_group',
      title: `Already in ${groupName}`,
    },
    {
      key: 'already_invited',
      title: `You have already invited the following users to the ${groupName}`,
    },
    {
      key: 'stop_messages',
      title: 'Opted Out of Messages',
    },
    {
      key: 'invite_failed',
      title: 'Invite Failed',
    },
    {
      key: 'phone_not_valid',
      title: 'Invalid Phone Numbers',
    }
  ];

  const hasResults = (key) => {
    return Array.isArray(responseData[key]) && responseData[key].length > 0;
  };

  return (
    <div style={styles.resultsContainer}>
      <div style={styles.contentWrapper}>
        
        {categories.map(({ key, title }) => {
          if (!hasResults(key)) {
            return null;
          }
          
          return (
            <div key={key} style={styles.categoryContainer}>
              <h3 style={styles.categoryTitle}>
                {title}:
              </h3>
              <ul style={styles.namesList}>
                {responseData[key].map((name, index) => (
                  <li key={index} style={styles.nameItem}>
                    {name}
                  </li>
                ))}
              </ul>
            </div>
          );
        })}
        
        {/* Debug output */}
        <div style={{display: 'none'}}>
          Raw response: {JSON.stringify(responseData, null, 2)}
        </div>
      </div>
    </div>
  );
};

const styles = {
  resultsContainer: {
    marginTop: '20px',
    borderRadius: '8px',
    padding: '20px',
  },
  contentWrapper: {
    backgroundColor: 'white',
    borderRadius: '4px',
    padding: '15px',
  },
  header: {
    fontSize: '18px',
    fontWeight: 'bold',
    marginBottom: '15px',
    color: style.Color.colorDarkBrown,
  },
  categoryContainer: {
    marginBottom: '10px',
  },
  categoryTitle: {
    fontSize: '16px',
    fontWeight: 'bold',
    color: style.Color.colorRoyalBlueText,
    marginBottom: '5px',
  },
  namesList: {
    listStyle: 'none',
    padding: 0,
    margin: 0,
  },
  nameItem: {
    fontSize: '14px',
    color: style.Color.colorPurple,
    padding: '3px 0',
  }
};

export default InviteResults;