import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPlus,  faCheck, faBoxOpen, } from '@fortawesome/free-solid-svg-icons';
import { TopMenuBar } from '../../Buyers/DashbordComponents/TopMenuBar';
import { useAuth } from '../../Common/InitialGears/AuthContext';
import * as style from '../../../GlobalStyles';
import { getApi, postApi, buildUrl } from "../../Common/BackendCalls/apiUtils";
import ComboCalculator from './ComboCalculator'; 
import PackagingOptimizer from '../../Common/PackagingShipping/PackagingOptimizer';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const CreateEditCombo = () => {
  const auth = useAuth();
  const { queryLoginParam } = auth;
  const navigate = useNavigate();
  const location = useLocation();
  const { comboId } = useParams(); 
  const isEditMode = !!comboId; 
  const selectedWishes = location.state?.selectedWishes || [];
  const selectedShippingOption = location.state?.shippingOption || null;
  const comboData = location.state?.comboData || null; 
  const [categories, setCategories] = useState([]);
  const [allSubCategories, setAllSubCategories] = useState([]);
  const [filteredSubCategories, setFilteredSubCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedSubCategories, setSelectedSubCategories] = useState([]);
  const [showPackagingOptimizer, setShowPackagingOptimizer] = useState(false);
  const [packagingItems, setPackagingItems] = useState([]);
  const [loadingPackagingItems, setLoadingPackagingItems] = useState(false);
  const [comboDetails, setComboDetails] = useState({
    title: '',
    description: '',
    min_combo_size: 2,
    min_combo_order_qty: 2,
    expires_at: '2050-12-31T23:59:59+00:00',
    items: []
  });
  const [comboItems, setComboItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [shippingOption, setShippingOption] = useState(null); 
  const [totalBundleMRP, setTotalBundleMRP] = useState(0);
  const [bundleRate, setBundleRate] = useState(0);
  const [showAdvancedCalculator, setShowAdvancedCalculator] = useState(true); 
  const [initialCalculationDone, setInitialCalculationDone] = useState(false);
  const lastComboItemsLength = useRef(0);
  const [calculationData, setCalculationData] = useState({
    bundle_mrp: 0,
    actual_bundle_cost: 0,
    actual_international_shipping_cost: 0,
    bundle_rate: 0,
    bundle_commission_type: 'percentage',
    bundle_commission_value: 20,
    bundle_shipping_cost: 0
  });

  const discountPercentage = totalBundleMRP > 0 
    ? Math.round(((totalBundleMRP - bundleRate) / totalBundleMRP) * 100) 
    : 0;
    const fetchCategories = async () => {
      try {
        const categoriesUrl = buildUrl('getCategories', queryLoginParam);
        const response = await getApi(categoriesUrl);
        if (response.ok) {
          setCategories(response.data);
        }
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };
  
    const fetchSubCategories = async () => {
      try {
        const subCategoriesUrl = buildUrl('getSubCategories', queryLoginParam);
        const response = await getApi(subCategoriesUrl);
        if (response.ok) {
          setAllSubCategories(response.data || []);
        } else {
          if (response.status === 426) {
            setErrorMessage('Your session has expired. Please log in again.');
            navigate("/");
          }
          setAllSubCategories([]);
        }
      } catch (error) {
        console.error("Error fetching subcategories:", error);
        setAllSubCategories([]);
      }
    };

    const fetchComboDetails = useCallback(async () => {
      if (!isEditMode) {
        setLoading(false);
        return;
      } 
      
      if (comboData) {
        const combo = comboData;
        setComboDetails({
          title: combo.title || '',
          description: combo.description || '',
          min_combo_size: combo.min_combo_size || 2,
          min_combo_order_qty: combo.min_combo_order_qty || 2,
          expires_at: combo.expires_at || '2050-12-31T23:59:59+00:00',
          items: combo.items || []
        });
        
        if (combo.category) {
          if (Array.isArray(combo.category)) {
            setSelectedCategories(combo.category.map(cat => 
              typeof cat === 'object' ? cat.id.toString() : cat.toString()
            ));
          } else {
            setSelectedCategories([
              typeof combo.category === 'object' ? combo.category.id.toString() : combo.category.toString()
            ]);
          }
        } 
        if (combo.sub_category && Array.isArray(combo.sub_category)) {
          setSelectedSubCategories(combo.sub_category.map(sc => 
            typeof sc === 'object' ? sc.id.toString() : sc.toString()
          ));
        }
    
        const itemsWithPriceRanges = (combo.items || []).map(item => ({
          ...item,
          price_ranges: item.price_ranges || []
        }));
        
        setComboItems(itemsWithPriceRanges);
        setBundleRate(combo.bundle_rate || 0);
        setTotalBundleMRP(combo.bundle_mrp || 0); 
        
        // Handle the global commission flag - check for both possible field names
        const isGlobalCommission = combo.bundle_commission_isglobal || combo.is_global_commission || false;
        
        if (combo.actual_bundle_cost !== undefined) {
          setCalculationData({
            bundle_mrp: combo.bundle_mrp || 0,
            actual_bundle_cost: Number(combo.actual_bundle_cost) || 0,
            actual_international_shipping_cost: Number(combo.actual_international_shipping_cost) || 0,
            bundle_rate: combo.bundle_rate || 0,
            bundle_commission_type: combo.bundle_commission_type || 'percentage',
            bundle_commission_value: combo.bundle_commission_value || 20,
            bundle_shipping_cost: combo.bundle_shipping_cost || 0,
            isGlobalCommission: isGlobalCommission
          });
        } 
        
        if (combo.shipping_option) {
          setShippingOption({
            method: combo.shipping_option.method,
            price: parseFloat(combo.shipping_option.rate) || 0,
            carrier: combo.shipping_option.carrier,
            serviceCode: combo.shipping_option.service_code
          });
        } 
        
        setLoading(false);
        return;
      }
    
      try {
        setLoading(true);
        const url = buildUrl(`getComboDetails/${comboId}`, queryLoginParam);
        const response = await getApi(url);
        
        if (response.ok && response.data) {
          const combo = response.data;           
          setComboDetails({
            title: combo.title || '',
            description: combo.description || '',
            min_combo_size: combo.min_combo_size || 2,
            min_combo_order_qty: combo.min_combo_order_qty || 2,
            expires_at: combo.expires_at || '2050-12-31T23:59:59+00:00',
            items: combo.items || []
          });
          if (combo.category) {
            if (Array.isArray(combo.category)) {
              setSelectedCategories(combo.category.map(cat => cat.toString()));
            } else {
              setSelectedCategories([combo.category.toString()]);
            }
          }
          
          if (combo.sub_category && Array.isArray(combo.sub_category)) {
            setSelectedSubCategories(combo.sub_category.map(sc => sc.toString()));
          }
          const itemsWithPriceRanges = await Promise.all((combo.items || []).map(async (item) => { 
            if (item.price_ranges && item.price_ranges.length > 0) {
              return item;
            }             
            try {
              const wishUrl = buildUrl(`getGroupWishDetails/${item.group_wish_id}`, queryLoginParam);
              const wishResponse = await getApi(wishUrl);
              if (wishResponse.ok && wishResponse.data) {
                return {
                  ...item,
                  price_ranges: wishResponse.data.price_ranges || [],
                  actual_price_ranges: wishResponse.data.actual_price_ranges || [],
                  expected_price_ranges: wishResponse.data.expected_price_ranges || []
                };
              }
            } catch (e) {
              console.error(`Error fetching price ranges for item ${item.group_wish_id}`, e);
            }
            
            return {
              ...item,
              price_ranges: []
            };
          }));          
          setComboItems(itemsWithPriceRanges || []);
          setBundleRate(combo.bundle_rate || 0);
          setTotalBundleMRP(combo.bundle_mrp || 0);   
          const isGlobalCommission = combo.bundle_commission_isglobal || combo.is_global_commission || false;           
          setCalculationData({
            bundle_mrp: combo.bundle_mrp || 0,
            actual_bundle_cost: Number(combo.actual_bundle_cost) || 0,
            actual_international_shipping_cost: Number(combo.actual_international_shipping_cost) || 0,
            bundle_rate: combo.bundle_rate || 0,
            bundle_commission_type: combo.bundle_commission_type || 'percentage',
            bundle_commission_value: combo.bundle_commission_value || 20,
            bundle_shipping_cost: combo.bundle_shipping_cost || 0,
            isGlobalCommission: isGlobalCommission
          });
          
          if (combo.shipping_option) {
            setShippingOption({
              method: combo.shipping_option.method,
              price: parseFloat(combo.shipping_option.rate) || 0,
              carrier: combo.shipping_option.carrier,
              serviceCode: combo.shipping_option.service_code
            });
          }
        } else {
          setErrorMessage('Failed to load combo details. Please try again.');
        }
      } catch (error) {
        console.error("Error fetching combo details:", error);
        setErrorMessage('An error occurred while loading combo details.');
      } finally {
        setLoading(false);
      }
    }, [comboId, isEditMode, queryLoginParam, comboData]);
 
  useEffect(() => {
    fetchCategories();
    fetchSubCategories();
    if (isEditMode) {
      fetchComboDetails();
    } else { 
      if (selectedWishes && selectedWishes.length > 0) {
        const initialItems = selectedWishes.map(wish => ({
          group_wish_id: wish.id,
          title: wish.product_name,
          image: wish.image,
          price: wish.list_price,
          required: true,
          max_quantity: 1,
          item_savings: 0, 
          actual_price_ranges: wish.actual_price_ranges || [],
          expected_price_ranges: wish.expected_price_ranges || [],
          price_ranges: wish.price_ranges || []
        }));
        
        setComboItems(initialItems); 
        const mrp = initialItems.reduce((sum, item) => sum + (parseFloat(item.price) || 0), 0);
        setTotalBundleMRP(mrp); 
        const defaultDiscount = 0.2;  
        setBundleRate(mrp * (1 - defaultDiscount));
      }
      
      if (selectedShippingOption) {
        setShippingOption(selectedShippingOption);
      }
      
      setLoading(false);
    }
  }, [fetchComboDetails, isEditMode]);
 
  useEffect(() => {
    if (selectedCategories.length > 0 && categories.length > 0 && allSubCategories.length > 0) { 
      const allFilteredSubs = [];
      
      for (const categoryId of selectedCategories) {
        const selectedCategoryData = categories.find(cat => 
          cat.id.toString() === categoryId || cat.name === categoryId
        );
        
        if (selectedCategoryData && selectedCategoryData.sub_category) {
          const categorySubs = allSubCategories.filter(sub => 
            selectedCategoryData.sub_category.includes(sub.id)
          );
          allFilteredSubs.push(...categorySubs);
        }
      } 
      const uniqueSubs = allFilteredSubs.filter((sub, index, self) =>
        index === self.findIndex((s) => s.id === sub.id)
      );
      
      setFilteredSubCategories(uniqueSubs);
    } else {
      setFilteredSubCategories([]);
    }
  }, [selectedCategories, categories, allSubCategories]);

  useEffect(() => {
    if (comboItems.length > 0 && (!initialCalculationDone || lastComboItemsLength.current !== comboItems.length)) {
      const mrp = comboItems.reduce((sum, item) => sum + (parseFloat(item.price) || 0), 0);
      setTotalBundleMRP(mrp); 
      
      if (discountPercentage > 0) {
        setBundleRate(mrp * (1 - (discountPercentage / 100)));
      } else { 
        const defaultDiscount = 0.2; // 20% discount
        setBundleRate(mrp * (1 - defaultDiscount));
      }
      
      setInitialCalculationDone(true);
      lastComboItemsLength.current = comboItems.length;
    }
  }, [comboItems, discountPercentage]);
 
  useEffect(() => {
    let timer;
    if (successMessage) {
      timer = setTimeout(() => {
        setSuccessMessage('');
      }, 5000);
    }
    return () => clearTimeout(timer);
  }, [successMessage]);
  
 
  const handleCategoryChange = (e) => {
    const selectedOptions = Array.from(e.target.selectedOptions, option => option.value);
    setSelectedCategories(selectedOptions); 
    setSelectedSubCategories([]);
  };
  
  const handleSubCategoryChange = (e) => {
    const selectedOptions = Array.from(e.target.selectedOptions, option => option.value);
    setSelectedSubCategories(selectedOptions);
  };
   
  const handleDateChange = (date) => {
    setComboDetails(prev => ({
      ...prev,
      expires_at: date
    }));
  };
  
  const handleInputChange = (e) => {
    const { name, value } = e.target; 
    setComboDetails(prev => {
      const newDetails = {
        ...prev,
        [name]: value
      }; 
      return newDetails;
    });
  }; 
 
const prepareItemsForPackagingOptimizer = async () => {
  if (comboItems.length === 0) return;
  setLoadingPackagingItems(true);    
  try { 
    const itemsWithSpecs = await Promise.all(comboItems.map(async (item) => { 
      if (item.product_specifications && 
          item.product_specifications.length && 
          item.product_specifications.width && 
          item.product_specifications.height && 
          item.product_specifications.weight) {
        return item;
      }
       
      try {
        const wishUrl = buildUrl(`getGroupWishDetails/${item.group_wish_id}`, queryLoginParam);
        const response = await getApi(wishUrl);
        
        if (response.ok && response.data && response.data.product_specifications) { 
          return {
            ...item,
            product_specifications: response.data.product_specifications
          };
        }
      } catch (error) {
        console.error(`Error fetching specs for item ${item.group_wish_id}:`, error);
      }
      return item;
    }));
     
    const formattedItems = itemsWithSpecs.map(item => {
      const specs = item.product_specifications || {}; 
      const length = parseFloat(specs.length) || 0;
      const width = parseFloat(specs.width) || 0;
      const height = parseFloat(specs.height) || 0;
      const weight = parseFloat(specs.weight) || 0; 
      
      return {
        id: item.group_wish_id,
        group_wish_id: item.group_wish_id,
        title: item.title || 'Unnamed Product',
        product_name: item.title || 'Unnamed Product',
        image: item.image,
        price: item.price,
        length: length,
        width: width,
        height: height,
        weight: weight,
        product_specifications: {
          length: length,
          width: width,
          height: height,
          weight: weight,
          dimension_unit: specs.dimension_unit || 'CM',
          weight_unit: specs.weight_unit || 'KG',
          is_fragile: !!specs.is_fragile,
          has_batteries: !!specs.has_batteries,
          is_hazardous: !!specs.is_hazardous,
          storage_requirements: specs.storage_requirements || ''
        }
      };
    }); 
    setPackagingItems(formattedItems);
  } catch (error) {
    console.error("Error preparing items for packaging optimizer:", error);
  } finally {
    setLoadingPackagingItems(false);
  }
};

  useEffect(() => { 
    if (isEditMode && comboData && comboData.items && comboData.items.length > 0) { 
      const apiItemsFormatted = comboData.items.map(item => {
        const specs = item.product_specifications || {};        
        return {
          id: item.id || item.group_wish_id,
          group_wish_id: item.group_wish_id || item.id,
          title: item.title || 'Unnamed Product',
          product_name: item.title || 'Unnamed Product',
          image: item.image,
          price: item.price,
          product_specifications: {
            length: specs.length || 0,
            width: specs.width || 0,
            height: specs.height || 0,
            weight: specs.weight || 0,
            dimension_unit: specs.dimension_unit || 'CM',
            weight_unit: specs.weight_unit || 'KG',
            is_fragile: specs.is_fragile || false,
            has_batteries: specs.has_batteries || false,
            is_hazardous: specs.is_hazardous || false,
            storage_requirements: specs.storage_requirements || ''
          }
        };
      });      
      setPackagingItems(apiItemsFormatted);
    }
  }, [isEditMode, comboData]);

  const togglePackagingOptimizer = () => {
    if (!showPackagingOptimizer) { 
      prepareItemsForPackagingOptimizer();
    }
    setShowPackagingOptimizer(!showPackagingOptimizer);
  };
 
  const handleRemoveItem = (index) => {
    if (window.confirm("Are you sure you want to remove this item from the combo?")) {
      setComboItems(prev => prev.filter((_, i) => i !== index));
    }
  }; 

  const handleAddWishes = () => { 
    if (isEditMode) { 
      navigate('/comboGroupWishes', { 
        state: { 
          sourceScreen: 'edit', 
          comboId: comboId,
          selectedWishes: selectedWishes 
        } 
      });
    } else {
      navigate('/comboGroupWishes', { 
        state: { 
          sourceScreen: 'create', 
          selectedWishes: selectedWishes 
        } 
      });
    }
  }; 

  const handleSaveCombo = async () => {
    if (comboItems.length < 2) {
      setErrorMessage('A combo must have at least 2 items.');
      return;
    }
    
    if (!comboDetails.title.trim()) {
      setErrorMessage('Please provide a title for the combo.');
      return;
    }
    
    setSaving(true);
    setErrorMessage('');
    setSuccessMessage('');
    
    try {
      const url = buildUrl(
        isEditMode ? `updateCombo/${comboId}` : 'createCombo', 
        queryLoginParam
      );  
      const isGlobalCommission = Boolean(calculationData.isGlobalCommission);       
      const itemsWithCommission = comboItems.map(item => ({
        id: item.group_wish_id,
        required_in_combo: item.required,
        max_quantity: item.max_quantity,
        item_cost: item.itemCost || 0,
        shipping_cost: item.shippingCost || 0,  
        commission_type: isGlobalCommission ? calculationData.bundle_commission_type : (item.commissionType || 'percentage'),
        commission_value: isGlobalCommission ? calculationData.bundle_commission_value : (item.commissionValue || 20),
        commission_amount: item.commissionAmount || 0,
        total_cost: item.totalCost || 0
      }));
  
      const requestData = {
        comboTitle: comboDetails.title,
        description: comboDetails.description,
        min_combo_size: comboDetails.min_combo_size,
        min_combo_order_qty: comboDetails.min_combo_order_qty,
        expires_at: comboDetails.expires_at,
        group_wishes: itemsWithCommission,
        bundle_rate: Number(bundleRate.toFixed(2)),
        bundle_mrp: Number(totalBundleMRP.toFixed(2)),
        category: selectedCategories.map(cat => parseInt(cat, 10)),
        sub_category: selectedSubCategories.length > 0 
          ? selectedSubCategories.map(sc => parseInt(sc, 10)) 
          : []
      };
       
      if (calculationData) { 
        requestData.actual_bundle_cost = Number(calculationData.actual_bundle_cost);
        requestData.actual_international_shipping_cost = Number(calculationData.actual_international_shipping_cost);
        requestData.bundle_commission_type = calculationData.bundle_commission_type || 'percentage';
        requestData.bundle_commission_value = Number(calculationData.bundle_commission_value || 20);
        requestData.bundle_shipping_cost = Number(calculationData.bundle_shipping_cost);   
        requestData.is_global_commission = isGlobalCommission;
        requestData.isGlobalCommission = isGlobalCommission;
        
        if (calculationData.bundle_mrp) {
          requestData.bundle_mrp = Number(calculationData.bundle_mrp);
        }
      }
      
      if (calculationData.item_commissions) {
        requestData.item_commissions = calculationData.item_commissions;
      } 
      
      if (calculationData.itemized_costs) {
        requestData.itemized_costs = calculationData.itemized_costs;
      } 
      
      if (shippingOption) {
        requestData.shipping_option = {
          carrier: shippingOption.carrier,
          method: shippingOption.method,
          service_code: shippingOption.serviceCode,
          rate: shippingOption.price.toFixed(2)
        };
      } 
      const response = await postApi(url, requestData); 
      if (response.ok) {
        const message = isEditMode ? 'Combo updated successfully!' : 'Combo created successfully!';
        setSuccessMessage(message);
        setShowPackagingOptimizer(true); 
        setTimeout(() => {
          navigate('/comboList');
        }, 2000);
      } else {
        setErrorMessage('Failed to save combo. Please try again.');
      }
    } catch (error) {
      console.error("Error saving combo:", error);
      setErrorMessage('An error occurred while saving the combo.');
    } finally {
      setSaving(false);
    }
  };

  const handleToggleRequired = (index) => {
    setComboItems(prev => prev.map((item, i) => {
      if (i === index) {
        return { ...item, required: !item.required };
      }
      return item;
    }));
  };
 
  const handleUpdateQuantity = (index, quantity) => {
    if (quantity < 1) return;
    
    setComboItems(prev => prev.map((item, i) => {
      if (i === index) {
        return { ...item, max_quantity: quantity };
      }
      return item;
    }));
  };
  
  const normalizeCommissionData = (data) => {
    const normalizedData = { ...data }; 
    if ('isGlobalCommission' in normalizedData) {
      const isGlobal = Boolean(normalizedData.isGlobalCommission);
      normalizedData.is_global_commission = isGlobal;
      normalizedData.isGlobalCommission = isGlobal;       
    } 
    if (normalizedData.item_commissions) { 
      const itemCommissions = {};
      
      Object.entries(normalizedData.item_commissions).forEach(([itemId, commission]) => {
        itemCommissions[itemId] = {
          type: commission.type || 'percentage',
          value: commission.value || '20'
        };
      });
      
      normalizedData.item_commissions = itemCommissions;
    }
    
    return normalizedData;
  };
 
  const handleCalculationsComplete = (data) => {
    const normalizedData = normalizeCommissionData(data);
    
    setCalculationData({
      bundle_mrp: Number(normalizedData.bundle_mrp.toFixed(2)),
      actual_bundle_cost: Number(normalizedData.actual_bundle_cost.toFixed(2)),
      actual_international_shipping_cost: Number(normalizedData.actual_international_shipping_cost.toFixed(2)),
      bundle_rate: Number(normalizedData.bundle_rate.toFixed(2)),
      isGlobalCommission: Boolean(normalizedData.isGlobalCommission),
      is_global_commission: Boolean(normalizedData.isGlobalCommission),  
      bundle_commission_type: normalizedData.bundle_commission_type,
      bundle_commission_value: normalizedData.bundle_commission_value,
      bundle_shipping_cost: Number(normalizedData.bundle_shipping_cost.toFixed(2)),
      item_commissions: normalizedData.item_commissions,
      itemized_costs: normalizedData.itemized_costs
    }); 
    if (normalizedData.itemized_costs) {
      setComboItems(prevItems => prevItems.map(item => {
        const itemCosts = normalizedData.itemized_costs[item.group_wish_id];
        if (itemCosts) {
          return {
            ...item,
            itemCost: itemCosts.productCost || 0,
            shippingCost: itemCosts.shippingCost || 0,
            commissionType: normalizedData.isGlobalCommission 
              ? normalizedData.bundle_commission_type 
              : (itemCosts.commissionType || 'percentage'),
            commissionValue: normalizedData.isGlobalCommission 
              ? normalizedData.bundle_commission_value 
              : (itemCosts.commissionValue || 20),
            commissionAmount: itemCosts.commissionAmount || 0,
            totalCost: itemCosts.totalCost || 0
          };
        }
        return item;
      }));
    }
    
    setBundleRate(Number(normalizedData.bundle_rate.toFixed(2)));
    setTotalBundleMRP(Number(normalizedData.bundle_mrp.toFixed(2)));
  };
 
  const formatItemsForCalculator = () => {
    return comboItems.map(item => ({
      id: item.group_wish_id,
      product_name: item.title,
      image: item.image,
      list_price: item.price,
      actual_price_ranges: item.actual_price_ranges || [],
      expected_price_ranges: item.expected_price_ranges || [],
      price_ranges: item.price_ranges || [],
      selectedPrices: item.selectedPrices || null
    }));
  };
 

  return (
    <>
      <style>{styles}</style>
      <TopMenuBar  showBackButton={true} showHomeButton={true} onBackClick={() => navigate(-1)}  title={isEditMode ? "Edit Combo" : "Create New Combo"}  showSearch={false}  showAddress={false} />
      <div style={containerStyle}>
        <div style={contentStyle}>
          {loading ? (
            <div style={loadingContainerStyle}>
              <div style={loaderStyle}></div>
              <p>Loading combo details...</p>
            </div>
          ) : (
            <>
              {(successMessage || errorMessage) && (
                <div style={{...messageContainerStyle, backgroundColor: successMessage ? '#d4edda' : '#f8d7da', color: successMessage ? '#155724' : '#721c24', }}>
                  <p>{successMessage || errorMessage}</p>
                </div>
              )}
              
              <div style={formContainerStyle}> 
                  <h2 style={sectionHeadingStyle}>
                    {isEditMode ? 'Edit Combo Details' : 'Create New Combo'}
                  </h2>
                  
                  <div style={inputGroupStyle}>
                    <label style={labelStyle} htmlFor="title">Combo Title</label>
                    <input
                      type="text"
                      id="title"
                      name="title"
                      style={inputStyle}
                      value={comboDetails.title}
                      onChange={handleInputChange}
                      placeholder="Enter combo title"
                    />
                  </div>
                  <div style={categoryContainerStyle}>
                  <div style={{ flex: 1 }}>
                    <label style={labelStyle} htmlFor="category">Categories (Multiple Select)</label>
                    <select
                      id="category"
                      multiple
                      style={multiSelectStyle}
                      value={selectedCategories}
                      onChange={handleCategoryChange}
                      className="multiselect"
                    >
                      {categories.map(category => (
                        <option key={category.id} value={category.id.toString()}>
                          {category.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  
                  <div style={{ flex: 1 }}>
                    <label style={labelStyle} htmlFor="subcategory">Subcategories (Multiple Select)</label>
                    <select
                      id="subcategory"
                      multiple
                      style={multiSelectStyle}
                      value={selectedSubCategories}
                      onChange={handleSubCategoryChange}
                      className="multiselect"
                    >
                      {filteredSubCategories.map(subCategory => (
                        <option key={subCategory.id} value={subCategory.id.toString()}>
                          {subCategory.name}
                        </option>
                      ))}
                    </select>
                    <small style={{ color: style.Color.colorDarkGrey, marginTop: '5px', display: 'block' }}>
                      First select categories to see available subcategories
                    </small>
                  </div>
                </div>
                <div style={inputGroupStyle}>
                  <label style={labelStyle} htmlFor="expires_at">Expiry Date</label>
                  <DatePicker
                    id="expires_at"
                    selected={comboDetails.expires_at}
                    onChange={handleDateChange}
                    dateFormat="yyyy-MM-dd"
                    minDate={new Date()}
                    customInput={
                      <input style={inputStyle} />
                    }
                  />
                </div>
                  <div style={inputGroupStyle}>
                    <label style={labelStyle} htmlFor="description">Description</label>
                    <textarea
                      id="description"
                      name="description"
                      style={textareaStyle}
                      value={comboDetails.description}
                      onChange={handleInputChange}
                      placeholder="Enter combo description"
                      rows={6}
                    />
                  </div> 
                   
                  {showAdvancedCalculator && comboItems.length > 0 && (
                    <div style={calculatorContainerStyle}> 
                      <ComboCalculator selectedWishes={formatItemsForCalculator()} onCalculationsComplete={handleCalculationsComplete} initialValues={{...calculationData, isGlobalCommission: Boolean(calculationData.isGlobalCommission)}}/>
                    </div>
                  )}
                  {!showPackagingOptimizer && isEditMode && (
                    <button  style={toggleButtonStyle} onClick={togglePackagingOptimizer} >
                      Show Packaging Optimizer
                    </button>
                  )}
                  {showPackagingOptimizer && isEditMode && (
                    <div>
                      
                      <div style={optimizerContainerStyle}>
                        <div style={optimizerHeaderStyle}> 
                          <button  style={toggleButtonStyle} onClick={togglePackagingOptimizer} >
                            {showPackagingOptimizer ? 'Hide Optimizer' : 'Show Optimizer'}
                          </button>
                        </div> 
                        <PackagingOptimizer 
                      selectedWishes={packagingItems.length > 0 ? packagingItems : comboItems} 
                      comboId={comboId} 
                      isFromGroupWishCombo={false} 
                    />
                      </div>
                    </div>
                  )}

                  <div style={wishesContainerStyle}>
                    <div style={wishesHeaderStyle}>
                      <h3 style={wishesHeadingStyle}>
                        Combo Items ({comboItems.length})
                      </h3>
                      <button style={addWishButtonStyle} onClick={handleAddWishes} >
                        <FontAwesomeIcon icon={faPlus} />
                        <span>Add Group Wishes</span>
                      </button>
                    </div>
                    
                    {comboItems.length === 0 ? (
                      <div style={emptyItemsStyle}>
                        <FontAwesomeIcon icon={faBoxOpen} size="3x" color={style.Color.colorLightGrey} />
                        <p style={emptyItemsTextStyle}>
                          No items added to this combo yet. Click "Add Group Wishes" to select items.
                        </p>
                      </div>
                    ) : (
                      <div style={itemsListStyle}>
                        {comboItems.map((item, index) => (
                          <div key={`${item.group_wish_id}-${index}`} style={itemCardStyle}>
                            <div style={itemImageContainerStyle}>
                              {item.image ? (
                                <img src={item.image} alt={item.title} style={itemImageStyle} />
                              ) : (
                                <div style={itemPlaceholderStyle}>
                                  <FontAwesomeIcon icon="image" size="2x" color="#ccc" />
                                </div>
                              )}
                            </div>
                            <div style={itemContentStyle}>
                              <h4 style={itemTitleStyle}>{item.title}</h4>
                              <p style={itemPriceStyle}>${parseFloat(item.price).toFixed(2)}</p>
                              <div style={itemControlsStyle}>
                                <div style={requiredToggleStyle}>
                                  <label style={toggleLabelStyle}>
                                    <input
                                      type="checkbox"
                                      checked={item.required}
                                      onChange={() => handleToggleRequired(index)}
                                      style={toggleCheckboxStyle}
                                    />
                                    <span>Required</span>
                                  </label>
                                </div>
                                <div style={quantityControlStyle}>
                                  <span>Max Qty:</span>
                                  <div style={quantityInputContainerStyle}>
                                    <button
                                      style={quantityButtonStyle}
                                      onClick={() => handleUpdateQuantity(index, Math.max(1, item.max_quantity - 1))}
                                    >
                                      -
                                    </button>
                                    <span style={quantityValueStyle}>{item.max_quantity}</span>
                                    <button
                                      style={quantityButtonStyle}
                                      onClick={() => handleUpdateQuantity(index, item.max_quantity + 1)}
                                    >
                                      +
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <button
                              style={removeItemButtonStyle}
                              onClick={() => handleRemoveItem(index)}
                              aria-label="Remove item"
                            >
                              <FontAwesomeIcon icon={faTrash} />
                            </button>
                          </div>
                        ))}
                      </div>
                    )}
                  </div> 
                <div style={actionsContainerStyle}>
                  <button
                    style={cancelButtonStyle}
                    onClick={() => navigate('/comboList')}
                  >
                    Cancel
                  </button>
                  <button
                    style={saveButtonStyle}
                    onClick={handleSaveCombo}
                    disabled={saving || comboItems.length < 2 || !comboDetails.title.trim()}
                  >
                    {saving ? 'Saving...' : (isEditMode ? 'Update Combo' : 'Save Combo')}
                    {!saving && <FontAwesomeIcon icon={faCheck} style={{ marginLeft: '8px' }} />}
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

// Styles
const styles = `
  .multiselect {
    background-color: white !important;
    -webkit-appearance: listbox !important;
    -moz-appearance: listbox !important;
  }
  .multiselect option {
    padding: 8px;
  }
  .multiselect option:checked,
  .multiselect option:selected {
    background: #e3f2fd !important;
    background-color: #e3f2fd !important;
    color: black !important;
    font-weight: bold;
  }
  .multiselect option:hover {
    background-color: #f5f5f5;
  }
  @-moz-document url-prefix() {
    .multiselect option:checked {
      background: #e3f2fd !important;
      box-shadow: 0 0 10px 100px #e3f2fd inset !important;
    }
  }
`;
const containerStyle = {
  fontFamily: 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif',
  backgroundColor: style.Color.colorWhite,
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  paddingTop: '5%',
  paddingBottom: '50px',
};

const contentStyle = {
  width: '100%',
  maxWidth: '1200px',
  padding: '0 20px',
};

const formContainerStyle = {
  backgroundColor: style.Color.colorLightBeige,
  borderRadius: '8px',
  padding: '30px',
  marginBottom: '30px',
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
};

const sectionHeadingStyle = {
  fontSize: '24px',
  fontWeight: 'bold',
  marginBottom: '25px',
  color: style.Color.colorRoyalBlueText,
  borderBottom: `2px solid ${style.Color.colorLightGrey}`,
  paddingBottom: '10px',
};

const inputGroupStyle = {
  marginBottom: '20px',
};

const labelStyle = {
  display: 'block',
  marginBottom: '8px',
  fontWeight: 'bold',
  color: style.Color.colorDarkGrey,
};

const inputStyle = {
  width: '100%',
  padding: '12px',
  border: `1px solid ${style.Color.colorLightGrey}`,
  borderRadius: '6px',
  fontSize: '16px',
};

const textareaStyle = {
  ...inputStyle,
  resize: 'vertical',
  minHeight: '120px',
};

const priceContainerStyle = {
  display: 'flex',
  flexWrap: 'wrap',
  gap: '30px',
  marginBottom: '30px',
  backgroundColor: style.Color.colorWhite,
  borderRadius: '8px',
  padding: '20px',
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
};

const priceSectionStyle = {
  flex: '1 1 300px',
};

const priceHeaderStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '15px',
};

const priceHeadingStyle = {
  fontSize: '18px',
  fontWeight: 'bold',
  marginBottom: '0',
  color: style.Color.colorRoyalBlueText,
}; 

const priceRowStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '8px 0',
  borderBottom: `1px dashed ${style.Color.colorLightGrey}`,
};
 

const wishesContainerStyle = {
  backgroundColor: style.Color.colorWhite,
  borderRadius: '8px',
  padding: '20px',
  marginBottom: '30px',
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
};

const wishesHeaderStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '20px',
  borderBottom: `1px solid ${style.Color.colorLightGrey}`,
  paddingBottom: '10px',
};

const wishesHeadingStyle = {
  fontSize: '18px',
  fontWeight: 'bold',
  color: style.Color.colorRoyalBlueText,
  margin: 0,
};

const addWishButtonStyle = {
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  backgroundColor: style.Color.colorMintGreen,
  color: 'white',
  border: 'none',
  borderRadius: '4px',
  padding: '8px 15px',
  fontWeight: 'bold',
  cursor: 'pointer',
  transition: 'background-color 0.2s',
};

const emptyItemsStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '40px 20px',
  backgroundColor: style.Color.colorLightBeige,
  borderRadius: '6px',
  marginTop: '20px',
};

const emptyItemsTextStyle = {
  marginTop: '15px',
  fontSize: '16px',
  color: style.Color.colorDarkGrey,
  textAlign: 'center',
};

const itemsListStyle = {
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
  gap: '20px',
  marginTop: '20px',
};

const itemCardStyle = {
  display: 'flex',
  backgroundColor: style.Color.colorLightBeige,
  borderRadius: '8px',
  padding: '15px',
  position: 'relative',
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
};

const itemImageContainerStyle = {
  width: '80px',
  height: '80px',
  flexShrink: 0,
  marginRight: '15px',
};

const itemImageStyle = {
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  borderRadius: '4px',
};

const itemPlaceholderStyle = {
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#f5f5f5',
  borderRadius: '4px',
};

const itemContentStyle = {
  flex: 1,
};

const itemTitleStyle = {
  fontSize: '16px',
  fontWeight: 'bold',
  marginBottom: '5px',
  color: style.Color.colorRoyalBlueText,
};

const itemPriceStyle = {
  fontSize: '18px',
  fontWeight: 'bold',
  marginBottom: '10px',
  color: style.Color.colorMintGreen,
};

const itemControlsStyle = {
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
};

const requiredToggleStyle = {
  display: 'flex',
  alignItems: 'center',
};

const toggleLabelStyle = {
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  fontSize: '14px',
  color: style.Color.colorDarkGrey,
};

const toggleCheckboxStyle = {
  marginRight: '8px',
  accentColor: style.Color.colorMintGreen,
};

const quantityControlStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  fontSize: '14px',
  color: style.Color.colorDarkGrey,
};

const quantityInputContainerStyle = {
  display: 'flex',
  alignItems: 'center',
  marginLeft: '10px',
};

const quantityButtonStyle = {
  width: '28px',
  height: '28px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: style.Color.colorLightGrey,
  border: 'none',
  borderRadius: '4px',
  cursor: 'pointer',
  fontWeight: 'bold',
};

const quantityValueStyle = {
  margin: '0 10px',
  minWidth: '20px',
  textAlign: 'center',
};

const removeItemButtonStyle = {
  position: 'absolute',
  top: '10px',
  right: '10px',
  backgroundColor: 'transparent',
  border: 'none',
  color: style.Color.colorDarkGrey,
  cursor: 'pointer',
  fontSize: '14px',
  transition: 'color 0.2s',
  '&:hover': {
    color: '#dc3545',
  },
};

const actionsContainerStyle = {
  display: 'flex',
  justifyContent: 'flex-end',
  gap: '20px',
  marginTop: '30px',
};

const cancelButtonStyle = {
  padding: '12px 25px',
  backgroundColor: style.Color.colorLightGrey,
  color: style.Color.colorDarkGrey,
  border: 'none',
  borderRadius: '6px',
  fontWeight: 'bold',
  fontSize: '16px',
  cursor: 'pointer',
  transition: 'background-color 0.2s',
};

const saveButtonStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '12px 25px',
  backgroundColor: style.Color.colorMintGreen,
  color: 'white',
  border: 'none',
  borderRadius: '6px',
  fontWeight: 'bold',
  fontSize: '16px',
  cursor: 'pointer',
  transition: 'background-color 0.2s',
  opacity: props => props.disabled ? '0.6' : '1',
  pointerEvents: props => props.disabled ? 'none' : 'auto',
};

const loadingContainerStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '300px',
};

const loaderStyle = {
  border: '4px solid #f3f3f3',
  borderTop: `4px solid ${style.Color.colorMintGreen}`,
  borderRadius: '50%',
  width: '40px',
  height: '40px',
  animation: 'spin 1s linear infinite',
  marginBottom: '15px',
};

const messageContainerStyle = {
  padding: '15px',
  borderRadius: '4px',
  marginBottom: '20px',
  fontWeight: 'bold',
  textAlign: 'center',
  position: 'fixed',
  top: '20px',
  right: '20px',
  zIndex: '1000',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  animation: 'fadeIn 0.3s ease-in-out',
};

const calculatorContainerStyle = {
  backgroundColor: style.Color.colorWhite,
  borderRadius: '8px',
  padding: '20px',
  marginBottom: '30px',
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
}; 

const optimizerHeaderStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '20px',
  padding: '15px',
  backgroundColor: style.Color.colorLightBlue,
  borderRadius: '8px',
};

const optimizerContainerStyle = {
  backgroundColor: style.Color.colorWhite,
  borderRadius: '8px',
  padding: '20px',
  marginBottom: '30px',
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
};

const toggleButtonStyle = {
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  backgroundColor: style.Color.colorRoyalBlueText,
  color: 'white',
  border: 'none',
  borderRadius: '4px',
  padding: '8px 16px',
  fontSize: '14px',
  fontWeight: 'bold',
  cursor: 'pointer',
  transition: 'background-color 0.2s',
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
};
 
const categoryContainerStyle = {
  display: 'flex',
  flexDirection: 'row',
  gap: '20px',
  marginBottom: '20px',
};

const multiSelectStyle = {
  width: '100%',
  padding: '8px',
  border: `1px solid ${style.Color.colorLightGrey}`,
  borderRadius: '6px',
  fontSize: '14px',
  height: '100px',
  backgroundColor: 'white',
};


export default CreateEditCombo;