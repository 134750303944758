const CostBreakdown = ({ basePrice, shippingCost, totalCost, retailPrice }) => {
    return (
      <div className="cost-breakdown p-4 bg-white rounded-lg shadow">
        <div className="grid gap-2">
          <div className="flex justify-between text-sm">
            <span>Base Price:</span>
            <span>${basePrice}</span>
          </div>
          <div className="flex justify-between text-sm">
            <span className="flex items-center">
              Shipping:
              <InfoTooltip text="Shipping costs are included to ensure safe delivery of bulk orders" />
            </span>
            <span>${shippingCost}</span>
          </div>
          <div className="border-t pt-2 flex justify-between font-medium">
            <span>Your Total Cost:</span>
            <span>${totalCost}</span>
          </div>
          <div className="bg-green-50 p-2 rounded mt-2">
            <div className="flex justify-between text-green-700">
              <span>Retail Price:</span>
              <span>${retailPrice}</span>
            </div>
            <div className="flex justify-between text-green-700 font-bold">
              <span>Total Savings:</span>
              <span>${(retailPrice - totalCost).toFixed(2)} ({((retailPrice - totalCost) / retailPrice * 100).toFixed(1)}%)</span>
            </div>
          </div>
        </div>
      </div>
    );
  };
  
  const InfoTooltip = ({ text }) => (
    <div className="group relative ml-1">
      <span className="cursor-help text-gray-400 hover:text-gray-600">ⓘ</span>
      <div className="hidden group-hover:block absolute z-10 w-48 p-2 text-xs bg-gray-900 text-white rounded shadow-lg -top-2 left-6">
        {text}
      </div>
    </div>
  );
  
  export default CostBreakdown;