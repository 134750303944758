import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
;import { faInfoCircle, faChevronUp, faChevronDown, faMoneyBillTransfer, faClock, faReceipt } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from '../../Common/InitialGears/AuthContext';
import { getApi, buildUrl } from "../../Common/BackendCalls/apiUtils";
import * as style from "../../../GlobalStyles";
import { TopMenuBar } from '../DashbordComponents/TopMenuBar';
import TotalCreditsDisplay from './TotalCreditsDisplay'; 

const CreditDetailsPage = () => {
  const { queryLoginParam } = useAuth();
  const navigate = useNavigate();
  const [creditData, setCreditData] = useState({
    summary: {
      totalEarned: 0,
      redeemed: 0,
      balance: 0,
      upcoming: 0,
    },
    earnedHistory: [],
    redeemedHistory: [],
    upcomingHistory: [],
  });
  const [selectedCategory, setSelectedCategory] = useState('totalEarned');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isDetailExpanded, setIsDetailExpanded] = useState(true);

  useEffect(() => {
    const fetchCreditDetails = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const creditUrl = buildUrl('creditDetails', queryLoginParam);
        const response = await getApi(creditUrl);
        
        if (response.ok) {
          setCreditData(response.data);
        } else {
          setError('Failed to fetch credit details. Please try again.');
        }
      } catch (error) {
        setError('An error occurred while fetching data.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchCreditDetails();
  }, [queryLoginParam]);

  const handleCategoryClick = (category) => {
    if (category !== 'balance') {
      setSelectedCategory(category);
      setIsDetailExpanded(true);
    }
  };

  const formatType = (type) => {
    switch (type) {
      case 'DEAL':
        return 'Deal';
      case 'NEW_USER':
        return 'New User';
      default:
        return type;
    }
  };

  const toggleDetailExpansion = () => {
    setIsDetailExpanded(!isDetailExpanded);
  };

  const renderDetailContent = () => {
    if (!isDetailExpanded) return null;

    if (selectedCategory === 'totalEarned') {
      return <TotalCreditsDisplay creditData={creditData.earnedHistory} />;
    }

    const historyData = selectedCategory === 'redeemed' 
      ? creditData.redeemedHistory 
      : creditData.upcomingHistory;

    return (
      <>
        {pendingNote}
        <table style={tableStyle}>
          <thead>
            <tr>
              <th style={tableHeaderStyle}>Date</th>
              {selectedCategory === 'redeemed' ? (
                <>
                  <th style={tableHeaderStyle}>Purchase Item</th>
                  <th style={tableHeaderStyle}>Points Redeemed</th>
                </>
              ) : (
                <>
                  <th style={tableHeaderStyle}>Referred User</th>
                  <th style={tableHeaderStyle}>Credit Allocated</th>
                  <th style={tableHeaderStyle}>Type</th>
                  <th style={tableHeaderStyle}>Points</th>
                  <th style={tableHeaderStyle}>Note</th>
                </>
              )}
            </tr>
          </thead>
          <tbody>
            {historyData.map((item, index) => (
              <tr key={index} style={index % 2 === 0 ? tableRowEvenStyle : tableRowOddStyle}>
                <td style={tableCellStyle}>{new Date(item.date).toLocaleDateString()}</td>
                {selectedCategory === 'redeemed' ? (
                  <>
                    <td style={tableCellStyle}>{item.purchaseItem}</td>
                    <td style={tableCellStyle}>{item.pointsRedeemed} points</td>
                  </>
                ) : (
                  <>
                    <td style={tableCellStyle}>{item.referredUser ? item.referredUser.replace(/ None$/, '') : ''}</td>
                    <td style={tableCellStyle}>{item.creditAllocated} points</td>
                    <td style={tableCellStyle}>{formatType(item.type)}</td>
                    <td style={tableCellStyle}>{item.creditAllocated}</td>
                    <td style={tableCellStyle}>{item.note}</td>
                  </>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </>
    );
  };

  if (isLoading) {
    return <div style={loadingStyle}>Loading credit details...</div>;
  }

  if (error) {
    return <div style={errorStyle}>{error}</div>;
  }

  const pendingNote = selectedCategory === 'upcoming' && (
    <div style={pendingNoteStyle}>
      <div style={noteHeaderStyle}>Note:</div>
      <ul style={noteListStyle}>
        <li style={noteListItemStyle}>Pending Deal points will be available once the deal life cycle completes</li>
        <li style={noteListItemStyle}>For new user referral, points will be credited once the user registers and verifies</li>
      </ul>
    </div>
  );

  return (
    <div>
      <TopMenuBar showBackButton={false} showHomeButton={true} onBackClick={() => navigate(-1)}  title={'Details'}  showMenuButton={true}  showSearch={false}  showAddress={false} />
      <div style={pageStyle}>
        <h1 style={titleStyle}>Credit Details</h1>        
        <div style={infoBoxStyle}>
          <h2 style={subtitleStyle}>How to Earn Credits</h2>
          <ul style={listStyle}>
            <li>Invite a new user to BacksApp: Earn points when they join BacksApp</li>
            <li>Additionally Refer an exisiting user to join a deal: Earn 0.5% of the deal value (max 50 points) when the deal lifecycle ends</li>
          </ul>
        </div>
        
        <div style={balanceStyle}>
          <span style={balanceLabelStyle}>Current Balance:</span>
          <span style={balanceValueStyle}>{creditData.summary.balance} points</span>
        </div>

        <div style={creditSummaryStyle}>
          {Object.entries(creditData.summary).map(([key, value]) => {
            if (key !== 'balance') {
              return (
                <div key={key} style={creditItemStyle} onClick={() => handleCategoryClick(key)}>
                  <div style={creditIconWrapperStyle}>
                    <FontAwesomeIcon  icon={
                        key === 'totalEarned' ? faMoneyBillTransfer :
                        key === 'upcoming' ? faClock :
                        key === 'redeemed' ? faReceipt :
                        faInfoCircle
                      } 
                      style={creditIconStyle}
                    />
                  </div>
                  <h3 style={creditLabelStyle}>
                    {key === 'upcoming' ? (
                      <>
                        Upcoming Credits
                        <FontAwesomeIcon 
                          icon={faInfoCircle} 
                          style={infoIconStyle} 
                          title="Credits from ongoing deals that haven't completed their lifecycle yet" 
                        />
                      </>
                    ) : (
                      key.charAt(0).toUpperCase() + key.slice(1)
                    )}
                  </h3>
                  <p style={creditValueStyle}>{value} points</p>
                </div>
              );
            }
            return null;
          })}
        </div>

        <div style={noteStyle}>
          <p>Note: You can use up to 10% of your credit balance when joining a group wish.</p>
        </div>
        
        <div style={detailSummaryStyle}>
          <h2 style={subtitleStyle}>
            {selectedCategory.charAt(0).toUpperCase() + selectedCategory.slice(1)} Details
            <FontAwesomeIcon 
              icon={isDetailExpanded ? faChevronUp : faChevronDown} 
              style={expandIconStyle} 
              onClick={toggleDetailExpansion}
            />
          </h2>
          {renderDetailContent()}
        </div>
      </div>
    </div>
  );
}; 

const pageStyle = {
  width:'100%',
  margin: '0 auto',
  marginTop: '20%',
  padding: '20px', 
  backgroundColor: style.Color.colorLightBeige,
  borderRadius: '8px',
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
};
 
const pendingNoteStyle = { 
  border: `2px solid ${style.Color.colorYellow}`,
  padding: '12px 15px',
  marginBottom: '15px',
  borderRadius: '8px',
  color: style.Color.colorDarkGrey,
  fontSize: '14px',
};

const noteHeaderStyle = {
  fontWeight: 'bold',
  marginBottom: '8px',
  color: style.Color.colorDarkBlue,
};

const noteListStyle = {
  margin: '0',
  padding: '0 0 0 20px',
  listStyle: 'disc',
};

const noteListItemStyle = {
  marginBottom: '6px',
  lineHeight: '1.4',
  paddingLeft: '4px',
};
const titleStyle = {
  fontSize: '28px',
  fontWeight: 'bold',
  color: style.Color.colorRoyalBlueText,
  marginBottom: '20px',
  textAlign: 'center',
  borderRadius: '8px',
};

const infoBoxStyle = {
  backgroundColor: style.Color.colorWhite,
  border: `2px solid ${style.Color.colorYellow}`,
  borderRadius: '8px',
  padding: '15px',
  marginBottom: '20px',
};

const subtitleStyle = {
  fontSize: '20px',
  fontWeight: 'bold',
  color: style.Color.colorPeacockBlue,
  marginBottom: '10px',
  borderRadius: '8px',
};

const listStyle = {
  paddingLeft: '20px',
  color: style.Color.colorDarkGrey,
};

const balanceStyle = {
    backgroundColor: style.Color.colorWhite,
    border: `1px solid ${style.Color.colorBlueGrey}`,
    borderRadius: '8px',
    padding: '15px',
    marginBottom: '20px',
    textAlign: 'center',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  };
  
  const balanceLabelStyle = {
    fontSize: '18px',
    fontWeight: 'bold',
    color: style.Color.colorPeacockBlue,
    marginRight: '10px',
  };
  
  const balanceValueStyle = {
    fontSize: '28px',
    fontWeight: 'bold',
    color: style.Color.colorPurple,
  };

const creditSummaryStyle = {
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
  gap: '20px',
  marginBottom: '20px',
  borderRadius: '8px',
};
const creditIconWrapperStyle = {
  backgroundColor: style.Color.colorLightGrey,
  borderRadius: '50%',
  width: '40px',
  height: '40px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  margin: '0 auto 10px auto',
};

const creditIconStyle = {
  fontSize: '20px',
  color: style.Color.colorPeacockBlueDark,
};

// Update existing creditItemStyle to add more padding at the top
const creditItemStyle = {
  backgroundColor: style.Color.colorWhite,
  borderRadius: '8px',
  padding: '20px 15px', // Added more padding
  textAlign: 'center',
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  cursor: 'pointer',
  transition: 'transform 0.2s ease',
  ':hover': {
    transform: 'translateY(-2px)',
  },
};
const creditLabelStyle = {
  fontSize: '16px',
  fontWeight: 'bold',
  color: style.Color.colorDarkGrey,
  marginBottom: '5px',
};

const creditValueStyle = {
  fontSize: '24px',
  fontWeight: 'bold',
  color: style.Color.colorPurple,
};

const infoIconStyle = {
  marginLeft: '5px',
  fontSize: '16px',
  color: style.Color.colorBlueGrey,
  cursor: 'help',
};

const noteStyle = {
  fontSize: '14px',
  color: style.Color.colorDarkGrey,
  fontStyle: 'italic',
};

const loadingStyle = {
  textAlign: 'center',
  fontSize: '18px',
  color: style.Color.colorDarkGrey,
  marginTop: '50px',
};

const errorStyle = {
  textAlign: 'center',
  fontSize: '18px',
  color: style.Color.colorVibrantRed,
  marginTop: '50px',
};

const detailSummaryStyle = {
    marginTop: '20px',
    backgroundColor: style.Color.colorWhite,
    borderRadius: '15px',
    padding: '15px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  };
  
  const tableStyle = {
    width: '100%',
    borderCollapse: 'collapse',
    marginTop: '10px',
  };
  
  const tableHeaderStyle = {
    backgroundColor: style.Color.colorLightBeige,
    color: style.Color.colorDarkGrey,
    padding: '20px',
    textAlign: 'left',
  };
  
  const tableRowEvenStyle = {
    backgroundColor: style.Color.colorWhite,
  };
  
  const tableRowOddStyle = {
    backgroundColor: style.Color.colorLightBeige,
  };
  
  const tableCellStyle = {    
  padding: '12px',
  borderBottom: `1px solid ${style.Color.colorLightestGrey}`,
  color: style.Color.colorDarkBlue,
  fontSize: '12px',
  };
  
  const expandIconStyle = {
    marginLeft: '10px',
    cursor: 'pointer',
  };
  

export default CreditDetailsPage;