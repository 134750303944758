import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faMapMarker, faTimes, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import Config from "../../../config"; 
import {useAuth} from '../../../components/Common/InitialGears/AuthContext'; 
import { Color } from "../../../GlobalStyles";
import trendingBackGround from '../../../assets/images/_bg1.png';
import closeImage from '../../../assets/images/cancel.png';  
import { getApi, postApi, buildUrl } from "../../Common/BackendCalls/apiUtils";
import { TopMenuBar } from '../DashbordComponents/TopMenuBar';


const TrendsDetailsPage = () => {
  const navigate = useNavigate();
  const { queryLoginParam } = useAuth();
  const [searchText, setSearchText] = useState('');
  const [sortOrder, setSortOrder] = useState('wishes-desc');
  const [allTrends, setAllTrends] = useState([]);
  const [filteredTrends, setFilteredTrends] = useState([]);
  const [expandedTrendId, setExpandedTrendId] = useState(null);
  const [expandedLocationId, setExpandedLocationId] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLocationModalVisible, setIsLocationModalVisible] = useState(false);
  const [selectedTrend, setSelectedTrend] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchTrends(); 
  }, []);

  useEffect(() => {
    if (Array.isArray(allTrends)) {
      filterAndSortTrends(searchText);
    }
  }, [searchText, allTrends, sortOrder]);

  const fetchTrends = async () => {
    try {
    const trendDetailsURL = buildUrl(`getTrendDetails`, queryLoginParam);
    const response = await getApi(trendDetailsURL, queryLoginParam);     

    if (response.ok && Array.isArray(response.data)) {
      setAllTrends(response.data);
    } else {
      setError('Received invalid data format for trends'); 
    }
  } catch (error) { 
    setError('An error occurred while fetching trends');
  }
};

  const filterAndSortTrends = (text) => {
    if (!Array.isArray(allTrends)) return;
    const filtered = allTrends.filter(trend =>
      (trend.product && trend.product.toLowerCase().includes(text.toLowerCase())) ||
      (trend.brand && trend.brand.toLowerCase().includes(text.toLowerCase())) ||
      (!trend.product && !trend.brand)
    );

    filtered.sort((a, b) => {
      const netA = a.locations.reduce((total, loc) => total + loc.wishes.reduce((sum, wish) => sum + wish.net, 0), 0);
      const netB = b.locations.reduce((total, loc) => total + loc.wishes.reduce((sum, wish) => sum + wish.net, 0), 0);
      return sortOrder === 'desc' ? netB - netA : netA - netB;
    });

    setFilteredTrends(filtered);
  };

  const toggleSortOrder = () => {
    setSortOrder(prevOrder => prevOrder === 'desc' ? 'asc' : 'desc');
  };

  const handleLocationGraphPress = (location) => {
    setSelectedLocation(location);
    setIsLocationModalVisible(true);
  };

  const renderTrendLocationItem = (item) => (
    <div key={`${item.trend_id}-${item.city}`} style={styles.trendLocationItem}>
      <div style={styles.trendLocationHeader}>
        <span style={styles.trendLocationCity}>{item.city}, {item.zipcode}</span>
        <span style={styles.trendLocationWishes}>Wishes: {item.wishes.reduce((total, wish) => total + wish.net, 0)}</span>
      </div>
      {expandedLocationId === `${item.trend_id}-${item.city}` && (
        <div>
          <button onClick={() => handleLocationGraphPress(item)} style={styles.viewGraphButton}>
            View Full Graph
          </button>
          <ResponsiveContainer width="100%" height={200}>
            <LineChart data={item.wishes}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis />
              <Tooltip />
              <Line type="monotone" dataKey="net" stroke={Color.colorVibrantBlue} />
            </LineChart>
          </ResponsiveContainer>
        </div>
      )}
    </div>
  );

  const renderTrendItem = (item) => {
    const isItemExpanded = expandedTrendId === item.id;
    const handleGraphPress = () => { setSelectedTrend(item); setIsModalVisible(true); };

    return (
      <div key={item.id} style={styles.trendItem}>
        <button onClick={() => setExpandedTrendId(isItemExpanded ? null : item.id)} style={styles.trendItemButton}>
          <h3 style={styles.trendItemTitle}>
            {item.product || item.category || item.seller || item.manufacturer || item.brand}
          </h3>
        </button>
        {isItemExpanded && (
          <div style={styles.trendItemDetails}>
            {item.brand && (
              <div style={styles.trendItemDetail}>
                <span style={styles.trendItemDetailLabel}>Brand:</span>
                <span>{item.brand}</span>
              </div>
            )}
            {item.seller && (
              <div style={styles.trendItemDetail}>
                <span style={styles.trendItemDetailLabel}>Seller:</span>
                <span>{item.seller}</span>
              </div>
            )}
            {item.manufacturer && (
              <div style={styles.trendItemDetail}>
                <span style={styles.trendItemDetailLabel}>Manufacturer:</span>
                <span>{item.manufacturer}</span>
              </div>
            )}
            {item.category && (
              <div style={styles.trendItemDetail}>
                <span style={styles.trendItemDetailLabel}>Category:</span>
                <span>{item.category}</span>
              </div>
            )}
            <button onClick={handleGraphPress} style={styles.trendItemActionButton}>
              <FontAwesomeIcon icon={faClock} style={styles.buttonIcon} />
              <span>{expandedTrendId === item.id ? 'Trend Over the Period':'Hide Data Details' }</span>
            </button>
            <div style={styles.locationSection}>
              <button onClick={() => setExpandedLocationId(expandedLocationId ? null : item.id)} style={styles.trendItemActionButton}>
                <FontAwesomeIcon icon={faMapMarker} style={styles.buttonIcon} />
                <span>Location based Wishes</span>
              </button>
              {expandedLocationId === item.id && item.locations.map(renderTrendLocationItem)}
            </div>
          </div>
        )}
      </div>
    );
  };

  const topTrends = Array.isArray(allTrends) 
    ? allTrends
        .map((trend) => ({
          id: trend.id,
          product: trend.product,
          category: trend.category,
          net_wishes: trend.locations.reduce((total, location) => total + location.wishes.reduce((wishTotal, wish) => wishTotal + wish.net, 0), 0),
        }))
        .sort((a, b) => b.net_wishes - a.net_wishes)
        .slice(0, 10)
    : [];

  if (error) {
    return <div className="p-4 text-red-600">{error}</div>;
  }

  return (
    <div style={styles.container}> 
      <TopMenuBar showHomeButton={true} title={"Wish Trends"} showNotifications={true}/>
      <div  >
      <div style={styles.topTrendsContainer}>
      <div style={styles.headerTop}>  
          <div style={styles.placeholder}></div>
        </div>
          <div style={styles.topTrendsScroll}>
            {topTrends.map((item) => (
              <div key={item.id} style={styles.topTrendItem}>
                <h3 style={styles.topTrendItemTitle}>{item.product || item.category}</h3>
                <p style={styles.topTrendItemValue}>{item.net_wishes}</p>
              </div>
            ))}
          </div>
        </div>
        
        
       
      </div>

      <div style={styles.trendsContainer}>
        <h2 style={styles.trendsTitle}>Trends</h2>
        <div style={styles.searchContainer}>
          <input type="text" placeholder="Search ..." value={searchText} onChange={(e) => setSearchText(e.target.value)} style={styles.searchInput}/>
          <button onClick={toggleSortOrder} style={{...styles.sortButton,backgroundColor: sortOrder === 'asc' ? Color.colorLightBeige : Color.colorCoolBeige,}}>
            Wishes {sortOrder === 'desc' ? '↑' : '↓'}
          </button>
        </div>
        {filteredTrends.map(renderTrendItem)}
      </div>

      {isModalVisible && (
        <div style={styles.modalOverlay}>
          <div style={styles.modalContent}>
            <div style={styles.modalHeader}>
              <h2 style={styles.modalTitle}>
                Demand trends of {selectedTrend?.product || selectedTrend?.category}
              </h2>
              <button onClick={() => setIsModalVisible(false)}> 
                <img src={closeImage} style={styles.closeImage} />
              </button>
            </div>
            <ResponsiveContainer width="100%" height={300}>
              <LineChart data={selectedTrend?.locations.flatMap(loc => loc.wishes) || []}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
                <Line type="monotone" dataKey="net" stroke={Color.vibrantBlue} />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>
      )}

      {isLocationModalVisible && (
        <div style={styles.modalOverlay}>
          <div style={styles.modalContent}>
            <div style={styles.modalHeader}>
              <h2 style={styles.modalTitle}>
                Wish Trends in {selectedLocation?.city}, {selectedLocation?.zipcode}
              </h2>
              <button onClick={() => setIsLocationModalVisible(false)}>
                <FontAwesomeIcon icon={faTimes} />
                <img src={closeImage} style={styles.closeImage} />
              </button>
            </div>
            <ResponsiveContainer width="100%" height={300}>
              <LineChart data={selectedLocation?.wishes || []}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
                <Line type="monotone" dataKey="net" stroke={Color.vibrantBlue} />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>
      )}
    </div>
  );
};


const styles = {
  container: { 
    minHeight: '100vh',
    marginTop: '4%',
    padding: '20px',
    flex: 1,
    backgroundColor: '#fff',
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    padding: '20px',
    backgroundColor: Color.colorLightBeige,    
    marginBottom: '20px',
    width: '100%',
  },
  headerTop: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '20px',
  },  
  placeholder: {
    width: '50px',  
  },
  topTrendsContainer: {
    backgroundImage: `url(${trendingBackGround})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center', 
    padding: '30px',
    borderRadius: '15px',
    marginBottom: '20px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    overflowX: 'auto',
  },
  topTrendsTitle: {
    fontSize: '24px',
    fontWeight: 'bold',
    color: Color.darkBrown,
    marginBottom: '16px',
    textAlign: 'center',
},
topTrendsScroll: {
  display: 'flex',
  overflowX: 'auto',
  paddingBottom: '16px',
  minWidth: 'max-content', 
},
topTrendItem: {
  flexShrink: 0,
  backgroundColor: Color.colorDarkBlue,
  padding: '16px',
  borderRadius: '8px',
  marginRight: '16px',
  width: '128px',
  textAlign: 'center',
},
topTrendItemTitle: {
  fontSize: '14px',
  color: Color.colorLightestBeige,
  marginBottom: '8px',
},
topTrendItemValue: {
  fontSize: '18px',
  fontWeight: 'bold',
  color: 'white',
},
trendsContainer: {
  padding: '20px',
  backgroundColor: Color.colorLightBeige,
  borderRadius: '10px',
},
trendsTitle: {
  fontSize: '24px',
  fontWeight: 'bold',
  color: Color.colorDarkGrey,
  marginBottom: '16px',
},
searchContainer: {
  display: 'flex',
  marginBottom: '16px',
  padding: '10px',
  marginRight: '10px',
},
searchInput: {
  flexGrow: 1,
  padding: '10px',
  borderTopLeftRadius: '8px',
  borderBottomLeftRadius: '8px',
  border: `1px solid ${Color.colorMidBeige}`,
  fontSize: '16px',
},
sortButton: {
  padding: '10px 20px',
  borderTopRightRadius: '8px',
  borderBottomRightRadius: '8px',
  border: 'none', 
  color: Color.colorDarkGrey,
  fontSize: '16px',
  cursor: 'pointer',
  marginLeft: '10px',
},
trendItem: {  
  backgroundColor:Color.colorWhite,
  padding: '20px',
  borderRadius: '8px',
  marginBottom: '16px',
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
},
trendItemButton: {
  width: '100%',
  textAlign: 'left',
  background: 'none',
  border: 'none',
  padding: 0,
  cursor: 'pointer',
  backgroundColor:Color.colorWhite,
},
trendItemTitle: {
  fontSize: '18px',
  fontWeight: 'bold',
  color: Color.colorDarkBlue,
  backgroundColor:Color.colorWhite,
  marginBottom: '8px',
},
trendItemDetails: {
  marginTop: '16px',
  backgroundColor:Color.colorWhite,
},
trendItemDetail: {
  marginBottom: '8px',
  backgroundColor:Color.colorWhite,
},
trendItemDetailLabel: {
  fontWeight: 'bold',
  marginRight: '8px',
  color: Color.colorDarkGrey,
  backgroundColor:Color.colorWhite,
},
trendItemActionButton: {
  display: 'flex',
  alignItems: 'center',
  color: Color.colorRoyalBlueText,
  background: 'none',
  border: 'none',
  padding: '8px 0',
  cursor: 'pointer',
  fontSize: '16px',
},
buttonIcon: {
  marginRight: '8px',
},
locationSection: {
  marginTop: '16px',
},
trendLocationItem: {
  backgroundColor: Color.colorCoolBeige,
  padding: '16px',
  borderRadius: '8px',
  marginTop: '8px',
},
trendLocationHeader: {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '8px',
},
trendLocationCity: {
  fontWeight: 'bold',
  color: Color.colorDarkGrey,
},
trendLocationWishes: {
  color: Color.colorVibrantBlue,
},
viewGraphButton: {
  color: Color.colorVibrantBlue,
  background: 'none',
  border: 'none',
  padding: '8px 0',
  cursor: 'pointer',
  fontSize: '14px',
},  
  modalOverlay: {
    position: 'fixed',
    inset: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 16,
  },
  modalContent: {
    backgroundColor: 'white',
    borderRadius: 8,
    padding: 24,
    width: '100%',
    maxWidth: '768px',
  },
  modalHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 16,
  },
  modalTitle: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  closeImage: {
    width: 25,
    height: 25,
    marginLeft: 12,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '20px',
    backgroundColor: Color.colorLightBeige,
    position: 'relative',
  }, 
  pageTitle: {
    fontSize: '24px',
    color: Color.colorGray,
    margin: 0,
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
    textAlign: 'center',
    width: '100%',
  },
  placeholder: {
    width: '50px',  
  },
};

export default TrendsDetailsPage;