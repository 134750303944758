// Facebook Page Post Implementation for BacksApp
import React, { useState, useEffect } from 'react';

// Helper function to request Facebook permissions and get page access token
const getFacebookPageAccessToken = async () => {
  return new Promise((resolve, reject) => {
    try {
      // Load the Facebook SDK asynchronously
      window.fbAsyncInit = function() {
        window.FB.init({
          appId: 'YOUR_FACEBOOK_APP_ID', // Replace with your app ID
          cookie: true,
          xfbml: true,
          version: 'v17.0' // Use the latest version
        });
        
        // Check login status
        window.FB.getLoginStatus(function(response) {
          if (response.status === 'connected') {
            // User is logged in and has authorized your app
            fetchPageAccessToken(response.authResponse.accessToken, resolve, reject);
          } else {
            // User needs to login and authorize
            promptFacebookLogin(resolve, reject);
          }
        });
      };
      
      // Load the Facebook SDK
      (function(d, s, id) {
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        js = d.createElement(s); js.id = id;
        js.src = "https://connect.facebook.net/en_US/sdk.js";
        fjs.parentNode.insertBefore(js, fjs);
      }(document, 'script', 'facebook-jssdk'));
      
    } catch (error) {
      reject(`Error initializing Facebook SDK: ${error.message}`);
    }
  });
};

// Prompt the user to login to Facebook and grant permissions
const promptFacebookLogin = (resolve, reject) => {
  window.FB.login(function(response) {
    if (response.authResponse) {
      // User authorized the app
      fetchPageAccessToken(response.authResponse.accessToken, resolve, reject);
    } else {
      // User cancelled login or did not authorize
      reject('Facebook login was cancelled or authorization was denied');
    }
  }, { 
    scope: 'pages_manage_posts,pages_read_engagement,pages_manage_engagement',
    return_scopes: true
  });
};

// Fetch Pages that the user manages and get access tokens
const fetchPageAccessToken = (userAccessToken, resolve, reject) => {
  window.FB.api('/me/accounts', function(response) {
    if (response && !response.error) {
      if (response.data && response.data.length > 0) {
        // Store all pages the user manages
        const pages = response.data.map(page => ({
          id: page.id,
          name: page.name,
          accessToken: page.access_token,
          category: page.category
        }));
        
        resolve({ userAccessToken, pages });
      } else {
        reject('No Facebook Pages found for this user');
      }
    } else {
      reject(`Error fetching pages: ${response.error.message}`);
    }
  });
};

// Function to post content to a Facebook Page
const postToFacebookPage = async (pageId, pageAccessToken, dealContent) => {
  return new Promise((resolve, reject) => {
    // Prepare the post data
    const postData = {
      message: dealContent.message,
      link: dealContent.link || null,
      published: true // Set to false for scheduled posts
    };
    
    // If there's an image, add it
    if (dealContent.imageUrl) {
      postData.picture = dealContent.imageUrl;
    }
    
    // Make the API call to post to the page
    window.FB.api(
      `/${pageId}/feed`,
      'POST',
      {
        ...postData,
        access_token: pageAccessToken
      },
      function(response) {
        if (response && !response.error) {
          resolve({
            success: true,
            postId: response.id,
            message: 'Deal successfully posted to Facebook Page'
          });
        } else {
          reject({
            success: false,
            error: response.error || 'Unknown error occurred while posting to Facebook'
          });
        }
      }
    );
  });
};

// Component to manage Facebook Page posting
const FacebookPagePoster = ({ dealData }) => {
  const [pages, setPages] = useState([]);
  const [selectedPageId, setSelectedPageId] = useState('');
  const [postMessage, setPostMessage] = useState(dealData?.title || '');
  const [postLink, setPostLink] = useState(dealData?.url || '');
  const [postImage, setPostImage] = useState(dealData?.imageUrl || '');
  const [isPosting, setIsPosting] = useState(false);
  const [result, setResult] = useState(null);
  const [error, setError] = useState(null);

  // Load Facebook SDK and get pages when component mounts
  useEffect(() => {
    const initFacebook = async () => {
      try {
        const fbAuth = await getFacebookPageAccessToken();
        setPages(fbAuth.pages || []);
        if (fbAuth.pages && fbAuth.pages.length > 0) {
          setSelectedPageId(fbAuth.pages[0].id);
        }
      } catch (error) {
        setError(error.toString());
      }
    };

    initFacebook();
  }, []);

  // Handler for posting the deal
  const handlePostDeal = async () => {
    if (!selectedPageId) {
      setError('Please select a Facebook Page to post to');
      return;
    }

    if (!postMessage.trim()) {
      setError('Please enter a message for the post');
      return;
    }

    setIsPosting(true);
    setError(null);
    setResult(null);

    try {
      // Find the selected page to get its access token
      const selectedPage = pages.find(page => page.id === selectedPageId);
      
      if (!selectedPage) {
        throw new Error('Selected page not found');
      }

      // Prepare deal content
      const dealContent = {
        message: postMessage,
        link: postLink,
        imageUrl: postImage
      };

      // Post to the page
      const response = await postToFacebookPage(
        selectedPageId,
        selectedPage.accessToken,
        dealContent
      );

      setResult(response);
    } catch (error) {
      setError(error.message || 'Failed to post to Facebook');
    } finally {
      setIsPosting(false);
    }
  };

  return (
    <div className="facebook-poster-container p-4 border rounded-lg">
      <h2 className="text-xl font-bold mb-4">Share Deal on Facebook</h2>
      
      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4">
          {error}
        </div>
      )}
      
      {result && result.success && (
        <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded mb-4">
          {result.message}
        </div>
      )}

      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2">
          Select Facebook Page
        </label>
        <select
          value={selectedPageId}
          onChange={(e) => setSelectedPageId(e.target.value)}
          className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          disabled={pages.length === 0 || isPosting}
        >
          {pages.length === 0 ? (
            <option value="">No pages available</option>
          ) : (
            pages.map(page => (
              <option key={page.id} value={page.id}>
                {page.name} ({page.category})
              </option>
            ))
          )}
        </select>
      </div>

      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2">
          Message
        </label>
        <textarea
          value={postMessage}
          onChange={(e) => setPostMessage(e.target.value)}
          className="shadow border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
          rows={4}
          placeholder="Enter your deal description here..."
          disabled={isPosting}
        />
      </div>

      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2">
          Deal Link
        </label>
        <input
          type="text"
          value={postLink}
          onChange={(e) => setPostLink(e.target.value)}
          className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          placeholder="https://example.com/deal"
          disabled={isPosting}
        />
      </div>

      <div className="mb-6">
        <label className="block text-gray-700 text-sm font-bold mb-2">
          Image URL (optional)
        </label>
        <input
          type="text"
          value={postImage}
          onChange={(e) => setPostImage(e.target.value)}
          className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          placeholder="https://example.com/image.jpg"
          disabled={isPosting}
        />
      </div>

      <div className="flex items-center justify-between">
        <button
          onClick={handlePostDeal}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          disabled={isPosting || pages.length === 0}
        >
          {isPosting ? 'Posting...' : 'Post to Facebook'}
        </button>
      </div>
    </div>
  );
};

export default FacebookPagePoster;