import React, { useState, useRef } from 'react';
import { Loader2, ArrowLeft, Mail, Facebook, Twitter, Github, Google, Chrome, Linkedin } from 'lucide-react';
import { auth } from '../../../firebaseConfig';
import { 
createUserWithEmailAndPassword,  
GoogleAuthProvider,
FacebookAuthProvider,
TwitterAuthProvider,
GithubAuthProvider,
signInWithPopup
} from "firebase/auth";
import { useAuth } from '../../../components/Common/InitialGears/AuthContext';
import { useNavigate } from 'react-router-dom';
import Config from '../../../config';
import { refreshAuthToken } from './authLoginUtils';
import ReferralRequestPanel from '../../WebPage/ReferralRequested';  

const Register = ({ onClose, setLoginType }) => {
const [step, setStep] = useState(1);
const [showReferralRequest, setShowReferralRequest] = useState(false);
const [showSocialOptions, setShowSocialOptions] = useState(false);
const [referralCode, setReferralCode] = useState('');
const [firstName, setFirstName] = useState('');
const [lastName, setLastName] = useState('');
const [phone, setPhone] = useState('');
const [email, setEmail] = useState('');
const [password, setPassword] = useState('');
const [errorMessage, setErrorMessage] = useState('');
const [loading, setLoading] = useState(false); 
const [userType, setUserType] = useState('');
const navigate = useNavigate();
const { login } = useAuth();
const otpInputRefs = useRef([]);

const formatFirebaseError = (error) => {
  switch (error.code) {
    case 'auth/email-already-in-use':
      return 'This email is already registered. Please try signing in instead.';
    case 'auth/weak-password':
      return 'Password must be at least 6 characters long.';
    case 'auth/invalid-email':
      return 'Please enter a valid email address.';
    case 'auth/account-exists-with-different-credential':
      return 'An account already exists with the same email address but different sign-in credentials. Sign in using a provider associated with this email address.';
    case 'auth/popup-closed-by-user':
      return 'The sign-in popup was closed before completing the sign in.';
    case 'auth/cancelled-popup-request':
      return 'The sign-in popup was cancelled by another popup.';
    case 'auth/popup-blocked':
      return 'The sign-in popup was blocked by the browser.';
    default:
      return error.message || 'An unexpected error occurred. Please try again.';
  }
};

const validateName = (name) => {
  const nameRegex = /^[A-Za-z\s-']+$/;
  return nameRegex.test(name);
};

const handleFirstNameChange = (e) => {
  const value = e.target.value;
  if (value === '' || validateName(value)) {
    setFirstName(value);
    setErrorMessage('');
  }
};

const handleLastNameChange = (e) => {
  const value = e.target.value;
  if (value === '' || validateName(value)) {
    setLastName(value);
    setErrorMessage('');
  }
};

const validatePassword = (password) => {
  return password.length >= 6;
};
 

// Handle social media login and additional data extraction
const extractUserInfo = (user, providerData) => {
  let userInfo = {
    firstName: '',
    lastName: '',
    email: user.email || '',
    phone: user.phoneNumber || '',
    photoURL: user.photoURL || '',
    providerId: user.providerId
  };

  // Extract name from displayName
  if (user.displayName) {
    const nameParts = user.displayName.split(' ');
    userInfo.firstName = nameParts[0] || '';
    userInfo.lastName = nameParts.slice(1).join(' ') || '';
  }

  // Provider-specific data extraction
  if (providerData && providerData.length > 0) {
    const data = providerData[0];
    
    // If we didn't get email from user object, try from provider data
    if (!userInfo.email && data.email) {
      userInfo.email = data.email;
    }
    
    // Some providers store names differently
    if ((!userInfo.firstName || !userInfo.lastName) && data.displayName) {
      const nameParts = data.displayName.split(' ');
      userInfo.firstName = userInfo.firstName || nameParts[0] || '';
      userInfo.lastName = userInfo.lastName || nameParts.slice(1).join(' ') || '';
    }
  }

  return userInfo;
};
 
const registerSocialMediaUser = async (email, phone, idToken, firstName, lastName, provider, referralCode, accessToken = null) => {
  // Build request body with all available user information
  const body = JSON.stringify({
    phone_number: phone || '',
    country_code: "+1",
    first_name: firstName || '',
    last_name: lastName || '',
    email: email || '',
    referral_secret: referralCode,
    user_type: userType || 'personal',
    social_provider: provider,
    provider_access_token: accessToken,
    provider_id: provider,
    profile_photo_url: '', // Can be added if available from provider
    additional_info: JSON.stringify({
      registration_source: 'web_app',
      registration_method: 'social_media',
      registration_timestamp: new Date().toISOString()
    })
  });

  // Set headers with authentication token
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${idToken}`
  };

  try {
    // Make API request to backend
    const response = await fetch(`${Config.apiUrl}registerSocialMediaUser?loginEmail=true`, {
      method: 'POST',
      headers: headers,
      body: body,
      mode: 'cors',
      credentials: 'omit'
    });

    // Return the response without handling errors (will be handled by caller)
    return response;
  } catch (error) {
    console.error('Error in registerSocialMediaUser API call:', error);
    throw error;
  }
};
 
  const completeSocialRegistration = async (e) => {
    e.preventDefault();
    setErrorMessage('');
    setLoading(true);
    
    try {
      if (!userType) {
        setErrorMessage("Please select an account type");
        setLoading(false);
        return;
      } 
      const socialAuthData = JSON.parse(sessionStorage.getItem('socialAuthCredential') || '{}');
      
      if (!socialAuthData.provider || !socialAuthData.idToken) {
        setErrorMessage("Social authentication data is missing. Please try again.");
        setLoading(false);
        return;
      } 
      const response = await registerSocialMediaUser(
        email,
        phone,
        socialAuthData.idToken,
        firstName,
        lastName,
        socialAuthData.provider,
        referralCode,
        socialAuthData.accessToken
      );
      
      if (!response.ok) {
        // Handle non-200 responses
        if (response.status === 400) {
          setErrorMessage("Referral Code not valid. \nRedirecting to request a code");
          await new Promise(resolve => setTimeout(resolve, 5000)); 
          setLoading(false);
          setShowReferralRequest(true);
          return;
        }
        
        // Handle other error cases
        const errorData = await response.json().catch(() => null);
        throw new Error(errorData?.message || `HTTP error! status: ${response.status}`);
      }
      
      const responseData = await response.json();
      
      // Set login parameters and navigate to dashboard
      await setLoginParams(
        socialAuthData.idToken,
        socialAuthData.uid,
        email,
        responseData.isBacksAppStaff || false,
        responseData.isBacksAppManager || false,
        firstName,
        responseData.userVerified || false,
        userType.toUpperCase()
      );
      
      // Clear temp storage
      sessionStorage.removeItem('socialAuthCredential');
      
      navigate('/dashboard');
    } catch (error) {
      console.error("Error completing social registration:", error);
      setErrorMessage(error.message || "Failed to complete registration. Please try again.");
    } finally {
      setLoading(false);
    }
  }; 

  const toggleSocialOptions = () => {
    setShowSocialOptions(!showSocialOptions);
    setErrorMessage('');
  };
 
  const renderSocialMediaButtons = () => {
    return (
      <div style={socialMediaButtonStyles.container}>
        <button 
          onClick={toggleSocialOptions}
          style={{
            padding: '10px',
            backgroundColor: 'transparent',
            color: '#B45309',
            border: 'none',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontWeight: 500,
            textDecoration: 'underline'
          }}
        >
          Or register with social media
        </button>
        
        {showSocialOptions && (
          <div style={socialMediaButtonStyles.buttonsGrid}>
            
            {/* Replace your Facebook button with the component */}
                
            <button
              type="button"
              onClick={handleFacebookAuth}
              style={socialMediaButtonStyles.button}
              onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#F9FAFB'}
              onMouseOut={(e) => e.currentTarget.style.backgroundColor = 'white'}
            >
              <Facebook style={{...socialMediaButtonStyles.icon, ...socialMediaButtonStyles.facebookIcon}} />
              <span>Facebook</span>
            </button>
             
          </div>
        )}
      </div>
    );
  };

 
// Replace your existing social media authentication handlers with these simplified versions 

const handleGoogleAuth = () => {
  setLoading(true);
  setErrorMessage('');
  
  const provider = new GoogleAuthProvider();
  provider.addScope('email');
  
  signInWithPopup(auth, provider)
    .then((result) => { 
      const user = result.user;
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const accessToken = credential ? credential.accessToken : null;
      
      // Process user info
      const userInfo = extractUserInfo(user, user.providerData);
      console.log("Google user info:", userInfo);
      
      // Update state with user info
      setFirstName(userInfo.firstName || '');
      setLastName(userInfo.lastName || '');
      setEmail(userInfo.email || '');
      setPhone(userInfo.phone || '');
      
      // Store auth data for API calls
      user.getIdToken().then(idToken => {
        sessionStorage.setItem('socialAuthCredential', JSON.stringify({
          provider: 'google',
          idToken,
          accessToken,
          uid: user.uid
        }));
        
        // Move to step 2
        setStep(2);
        setLoading(false);
      });
    })
    .catch((error) => {
      console.error("Google auth error:", error);
      setErrorMessage(formatFirebaseError(error));
      setLoading(false);
    });
};
 
const handleFacebookAuth = () => {
  setLoading(true);
  setErrorMessage('');
  
  try {
    console.log("Starting Facebook authentication...");
    
    const facebookProvider1 = new FacebookAuthProvider();
    facebookProvider1.addScope('email');
    facebookProvider1.addScope('public_profile'); 
     
    //const facebookProvider = authProviders.facebook();
    console.log("FB facebookAppId:", facebookProvider1)
    signInWithPopup(auth, facebookProvider1)
      .then((result) => {
        console.log("Facebook sign-in successful");
        
        // Get user and credential information message
        const user = result.user;
        const credential = FacebookAuthProvider.credentialFromResult(result);
        const accessToken = credential ? credential.accessToken : null;
        
        // Process user info
        const userInfo = extractUserInfo(user, user.providerData);
        console.log("Facebook user info:", userInfo);
        
        // Update state with user info
        setFirstName(userInfo.firstName || '');
        setLastName(userInfo.lastName || '');
        setEmail(userInfo.email || '');
        setPhone(userInfo.phone || '');
        
        // Store auth data for API calls
        user.getIdToken()
          .then(idToken => {
            sessionStorage.setItem('socialAuthCredential', JSON.stringify({
              provider: 'facebook',
              idToken,
              accessToken,
              uid: user.uid
            }));
            
            // Move to step 2 
            setStep(2);
            setLoading(false);
          })
          .catch(error => {
            console.error("Error getting ID token:", error);
            setErrorMessage("Failed to get authentication token. Please try again.");
            setLoading(false);
          });
      })
      .catch((error) => {
        console.error("Facebook auth error:", {
          code: error.code,
          message: error.message, 
        });
        
        setErrorMessage(formatFirebaseError(error));
        setLoading(false);
      });
  } catch (error) {
    console.error("Exception in Facebook auth setup:", error);
    setErrorMessage("Error setting up Facebook authentication");
    setLoading(false);
  }
};

const handleTwitterAuth = () => {
  setLoading(true);
  setErrorMessage('');
  
  const provider = new TwitterAuthProvider();
  
  signInWithPopup(auth, provider)
    .then((result) => {
      const user = result.user;
      const credential = TwitterAuthProvider.credentialFromResult(result);
      const accessToken = credential ? credential.accessToken : null;
      
      // Process user info
      const userInfo = extractUserInfo(user, user.providerData);
      console.log("Twitter user info:", userInfo);
      
      // Update state with user info
      setFirstName(userInfo.firstName || '');
      setLastName(userInfo.lastName || '');
      setEmail(userInfo.email || '');
      setPhone(userInfo.phone || '');
      
      // Store auth data for API calls
      user.getIdToken().then(idToken => {
        sessionStorage.setItem('socialAuthCredential', JSON.stringify({
          provider: 'twitter',
          idToken,
          accessToken,
          uid: user.uid
        }));
        
        // Move to step 2
        setStep(2);
        setLoading(false);
      });
    })
    .catch((error) => {
      console.error("Twitter auth error:", error);
      setErrorMessage(formatFirebaseError(error));
      setLoading(false);
    });
};

const handleGithubAuth = () => {
  setLoading(true);
  setErrorMessage('');
  
  const provider = new GithubAuthProvider();
  provider.addScope('user');
  provider.addScope('email');
  
  signInWithPopup(auth, provider)
    .then((result) => {
      const user = result.user;
      const credential = GithubAuthProvider.credentialFromResult(result);
      const accessToken = credential ? credential.accessToken : null;
      
      // Process user info
      const userInfo = extractUserInfo(user, user.providerData);
      console.log("GitHub user info:", userInfo);
      
      // Update state with user info
      setFirstName(userInfo.firstName || '');
      setLastName(userInfo.lastName || '');
      setEmail(userInfo.email || '');
      setPhone(userInfo.phone || '');
      
      // Store auth data for API calls
      user.getIdToken().then(idToken => {
        sessionStorage.setItem('socialAuthCredential', JSON.stringify({
          provider: 'github',
          idToken,
          accessToken,
          uid: user.uid
        }));
        
        // Move to step 2
        setStep(2);
        setLoading(false);
      });
    })
    .catch((error) => {
      console.error("GitHub auth error:", error);
      setErrorMessage(formatFirebaseError(error));
      setLoading(false);
    });
};

const handleRegister = async (e) => {
  e.preventDefault();
  setErrorMessage('');

  if (!userType) {
    setErrorMessage("Please select an account type");
    return;
  }

  if (!validatePassword(password)) {
    setErrorMessage("Password must be at least 6 characters long");
    return;
  }

  try {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    let idToken = await userCredential.user.getIdToken();
    const uid = userCredential.user.uid;
    const response = await registerNewUser(email, phone, idToken);
    const responseData = await response.json();

    idToken = await refreshAuthToken(userCredential.user);
    const accountType = userType.toUpperCase(); 
    await setLoginParams(
      idToken,
      uid,
      email,
      responseData.isBacksAppStaff || false,
      responseData.isBacksAppManager || false,
      firstName,
      responseData.userVerified || false,
      accountType
    );

    navigate('/dashboard');
  } catch (error) {
    if (error.response && error.response.status === 500) {
      setErrorMessage("Server error. Please try again later.");
    } else if (error.code && error.code.startsWith('auth/')) {
      console.log("=>", error)
      setErrorMessage(formatFirebaseError(error));
    } else {
      setErrorMessage(error.message || "Failed to register. Please try again.");
    }
  }
};

const registerNewUser = async (email, phone, idToken) => {
  const body = JSON.stringify({
    phone_number: phone,
    country_code: "+1",
    first_name: firstName,
    last_name: lastName,
    email: email,
    referral_secret: referralCode,
    user_type: userType,  
  });

  const response = await fetch(`${Config.apiUrl}registerNewUser?loginEmail=true`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': idToken
    },
    body: body,
    mode: 'cors',
    credentials: 'omit'
  });

  if (!response.ok) {
    if (response.status === 400) {
      setLoading(true);
      setErrorMessage("Referral Code not valid. \nRedirecting to request a code");
      await new Promise(resolve => setTimeout(resolve, 5000)); 
      setLoading(false);
      setShowReferralRequest(true);
      return;
    }
    throw new Error(response.status === 404 ? 'Referral Code Not acceptable' : `HTTP error! status: ${response.status}`);
  }

  return response;
};

const setLoginParams = async ( accessToken,   uid,  email,  isBacksAppStaff,  isBacksAppManager,  firstName,  userVerified, accountType ) => {    
  const success = await login( accessToken, uid, email, isBacksAppStaff, isBacksAppManager, firstName,userVerified,false,false,accountType, ""   );
  if (!success) {
    throw new Error("Failed to set login parameters");
  }
};

const validateStep3 = () => {
  if (!email.trim()) {
    setErrorMessage("Please enter your email address");
    return false;
  } 
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!emailRegex.test(email)) {
    setErrorMessage("Please enter a valid email address");
    return false;
  }
  if (!validatePassword(password)) {
    setErrorMessage("Password must be at least 6 characters long");
    return false;
  }
  return true;
};

const socialMediaButtonStyles = {
  container: {
    marginTop: '24px',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px'
  },
  dividerContainer: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  dividerLine: {
    position: 'absolute',
    width: '100%',
    borderTop: '1px solid #E5E7EB'
  },
  dividerText: {
    position: 'relative',
    padding: '0 16px',
    backgroundColor: 'white',
    fontSize: '14px',
    color: '#6B7280'
  },
  buttonsGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: '12px'
  },
  button: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10px 16px',
    border: '1px solid #E5E7EB',
    borderRadius: '8px',
    boxShadow: '0 1px 2px rgba(0, 0, 0, 0.05)',
    backgroundColor: 'white',
    color: '#4B5563',
    cursor: 'pointer',
    transition: 'background-color 0.2s'
  },
  buttonHover: {
    backgroundColor: '#F9FAFB'
  },
  icon: {
    width: '20px',
    height: '20px',
    marginRight: '8px'
  },
  googleIcon: {
    color: '#EA4335'
  },
  facebookIcon: {
    color: '#1877F2'
  },
  twitterIcon: {
    color: '#1DA1F2'
  }
};
 

const renderStep = () => {
  const commonInputClasses = "w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-900 placeholder-gray-500 focus:outline-none focus:border-amber-500 focus:ring-1 focus:ring-amber-500 transition-colors";
  const radioGroupClasses = "flex flex-col gap-3 mt-2";
  const radioLabelClasses = "flex items-center p-4 border rounded-lg cursor-pointer transition-all hover:border-amber-500";
  const radioInputClasses = "w-4 h-4 text-amber-600 border-gray-300 focus:ring-amber-500";
  
  switch (step) {
    case 1:
      return (
        <div className="space-y-6">
          <div className="text-center">
            <p className="text-sm text-gray-600 mb-4">Enter Referral Code</p>
          </div>
          <button onClick={() => setShowReferralRequest(true)} className="text-sm text-amber-600 hover:text-amber-700 underline mb-4" >
              Don't have a referral code? Request one
          </button>
          <div className="flex justify-between gap-2">
            {[0, 1, 2, 3, 4, 5].map((index) => (
              <input
                key={index}
                type="text"
                maxLength="1"
                value={referralCode[index] || ''}
                onChange={(e) => {
                  const newCode = referralCode.split('');
                  newCode[index] = e.target.value.toUpperCase();
                  setReferralCode(newCode.join(''));
                  if (e.target.value && index < 5) {
                    otpInputRefs.current[index + 1]?.focus();
                  }
                }}
                ref={(el) => (otpInputRefs.current[index] = el)}
                className="w-12 h-12 text-center bg-white border border-gray-300 rounded-lg text-gray-900 focus:outline-none focus:border-amber-500 focus:ring-1 focus:ring-amber-500 transition-colors"
              />
            ))}
          </div>
          
          <button  onClick={() => setStep(2)}  disabled={referralCode.length !== 6} className={`w-full py-3 rounded-lg font-medium transition-colors ${referralCode.length === 6 ? 'bg-gradient-to-r from-[#80666a] via-rose-900 to-[#80666a] text-white hover:opacity-90' : 'bg-gradient-to-r from-[#80666a] via-rose-900 to-[#80666a] text-white hover:opacity-90 cursor-not-allowed'}`}>            
            Next
          </button>
          
          {renderSocialMediaButtons()}
        </div>
      );
    case 2:
      return renderStep2();
    case 3:
      return (
        <div className="space-y-6">
          <div className="space-y-1">
            <label className="block text-sm font-medium text-gray-700">Email<span className="text-red-500">*</span></label>
            <input
              type="email"
              value={email}
              onChange={(e) => {setEmail(e.target.value);setErrorMessage('');}} required
              placeholder="Enter your email"
              className={commonInputClasses}
            />
          </div>
          <div className="space-y-1">
            <label className="block text-sm font-medium text-gray-700">Password <span className="text-red-500">*</span></label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Create a password"
              className={commonInputClasses}
            />
          </div>
          <div className="space-y-1">
            <label className="block text-sm font-medium text-gray-700">Phone Number</label>
            <input 
              type="tel" 
              value={phone} 
              onChange={(e) => setPhone(e.target.value)} 
              placeholder="Enter your phone number" 
              className={commonInputClasses} 
            />
          </div>
          {step > 1 && (
            <button 
            onClick={(e) => {
              if (validateStep3()) {
                handleRegister(e);
              }
            }} 
              className="flex items-center gap-2 text-gray-500 hover:text-gray-700 p-2 hover:bg-gray-100 rounded-lg transition-colors" >
              <ArrowLeft className="w-5 h-5" />
              <span>Go to Previous</span>
            </button>
          )}
          <button onClick={(e) => {e.preventDefault(); if (!email.trim()) {setErrorMessage("Please enter your email address"); return; } const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; if (!emailRegex.test(email)) { setErrorMessage("Please enter a valid email address"); return; } if (validateStep3()) { handleRegister(e);}}} className={`w-full py-3 rounded-lg font-medium transition-colors ${referralCode.length === 6 ? 'bg-gradient-to-r from-[#80666a] via-rose-900 to-[#80666a] text-white hover:opacity-90' : 'bg-gradient-to-r from-[#80666a] via-rose-900 to-[#80666a] text-white hover:opacity-90 cursor-not-allowed'}`}>            
            Create Account
          </button>
        </div>
      );
      default:
      return null;
  }
};

const renderStep2 = () => {
  const commonInputClasses = "w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-900 placeholder-gray-500 focus:outline-none focus:border-amber-500 focus:ring-1 focus:ring-amber-500 transition-colors";
  const radioGroupClasses = "flex flex-col gap-3 mt-2";
  const radioLabelClasses = "flex items-center p-4 border rounded-lg cursor-pointer transition-all hover:border-amber-500";
  const radioInputClasses = "w-4 h-4 text-amber-600 border-gray-300 focus:ring-amber-500";
  
  const validateStep2 = () => {
    if (!firstName.trim()) {
      setErrorMessage("Please enter your first name");
      return false;
    }
    if (!validateName(firstName)) {
      setErrorMessage("First name should only contain letters");
      return false;
    }
    if (!lastName.trim()) {
      setErrorMessage("Please enter your last name");
      return false;
    }
    if (!validateName(lastName)) {
      setErrorMessage("Last name should only contain letters");
      return false;
    }
    if (!userType) {
      setErrorMessage("Please select an account type");
      return false;
    }
    return true;
  };

  // Check if we're coming from social registration
  const isSocialFlow = !!sessionStorage.getItem('socialAuthCredential');
  
  return (
    <div className="space-y-6">
      <div className="space-y-1">
        <label className="block text-sm font-medium text-gray-700">
          First Name <span className="text-red-500">*</span>
        </label>
        <input
          type="text"
          value={firstName}
          onChange={handleFirstNameChange}              
          placeholder="Enter your first name"
          className={`${commonInputClasses} ${!firstName.trim() && errorMessage ? 'border-red-500 focus:border-red-500 focus:ring-red-500' : ''}`}
          disabled={isSocialFlow && !!firstName} // Disable if pre-filled by social
        />
      </div>
      <div className="space-y-1">
        <label className="block text-sm font-medium text-gray-700">
          Last Name <span className="text-red-500">*</span>
        </label>
        <input
          type="text"
          value={lastName}
          onChange={handleLastNameChange}
          placeholder="Enter your last name"
          className={`${commonInputClasses} ${!lastName.trim() && errorMessage ? 'border-red-500 focus:border-red-500 focus:ring-red-500' : ''}`}
          disabled={isSocialFlow && !!lastName} // Disable if pre-filled by social
        />
      </div>
      
      {isSocialFlow && (
        <div className="space-y-1">
          <label className="block text-sm font-medium text-gray-700">Email</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your email"
            className={commonInputClasses}
            disabled={!!email} // Disable if pre-filled by social
          />
        </div>
      )}
      
      {isSocialFlow && (
        <div className="space-y-1">
          <label className="block text-sm font-medium text-gray-700">Phone Number</label>
          <input 
            type="tel" 
            value={phone} 
            onChange={(e) => setPhone(e.target.value)} 
            placeholder="Enter your phone number" 
            className={commonInputClasses} 
          />
        </div>
      )}
      
      <div className="space-y-1">
        <label className="block text-sm font-medium text-gray-700">
          Account Type <span className="text-red-500">*</span>
        </label>
        <div className={radioGroupClasses}>
          <label className={`${radioLabelClasses} ${userType === 'personal' ? 'border-amber-500 bg-amber-50' : 'border-gray-300'}`}>
            <input type="radio" name="userType" value="personal" checked={userType === 'personal'} onChange={(e) => { setUserType(e.target.value); setErrorMessage(''); }}  className={radioInputClasses} />
            <span className="ml-3">Personal Use</span>
          </label>
          <label className={`${radioLabelClasses} ${userType === 'business' ? 'border-amber-500 bg-amber-50' : 'border-gray-300'}`}>
            <input type="radio" name="userType" value="business" checked={userType === 'business'} onChange={(e) => setUserType(e.target.value)} className={radioInputClasses} />
            <span className="ml-3">Business Use</span>
          </label> 
        </div>
      </div>
      
      {step > 1 && (
        <button 
          onClick={() => {
            // If we're in social flow, clear the social auth data and go back to step 1
            if (isSocialFlow) {
              sessionStorage.removeItem('socialAuthCredential');
            }
            setStep(step - 1);
            setErrorMessage('');
          }}
          className="flex items-center gap-2 text-gray-500 hover:text-gray-700 p-2 hover:bg-gray-100 rounded-lg transition-colors"
        >
          <ArrowLeft className="w-5 h-5" />
          <span>Go to Previous</span>
        </button>
      )}            
      
      {isSocialFlow ? (
        <button 
          onClick={completeSocialRegistration}
          className="w-full py-3 rounded-lg font-medium transition-colors bg-gradient-to-r from-[#80666a] via-rose-900 to-[#80666a] text-white hover:opacity-90"
        >            
          Complete Registration
        </button>
      ) : (
        <button 
          onClick={() => { 
            if (validateStep2()) { 
              setStep(3); 
              setErrorMessage(''); 
            } 
          }}  
          disabled={referralCode.length !== 6} 
          className={`w-full py-3 rounded-lg font-medium transition-colors ${referralCode.length === 6 ? 'bg-gradient-to-r from-[#80666a] via-rose-900 to-[#80666a] text-white hover:opacity-90' : 'bg-gradient-to-r from-[#80666a] via-rose-900 to-[#80666a] text-white hover:opacity-90 cursor-not-allowed'}`}
        >            
          Next
        </button>
      )}
    </div>
  );
};

return (
  <>    
    {showReferralRequest ? (
      <ReferralRequestPanel onClose={() => setShowReferralRequest(false)} />
    ) : (
      <div className="w-full h-full flex items-center justify-center px-4 py-8">
        <div className="bg-white rounded-xl shadow-lg p-8 w-full max-w-md relative">
          <div className="absolute top-4 right-4">
            <button onClick={() => setLoginType('login')} className="flex items-center gap-2 px-4 py-2 rounded-lg bg-white text-amber-700 hover:bg-amber-50 border border-amber-200 transition-all duration-300" >
              <Mail className="w-5 h-5" />
              <span>Sign In</span>
            </button>
          </div>

          <div className="mt-12">
            <div className="flex justify-between items-center mb-8">
              <div className="flex items-center gap-4">             
                <h2 className="text-2xl font-bold text-gray-900">
                  Create Account
                  {loading && (
                    <div className="flex justify-center items-center">
                      <Loader2 className="h-8 w-8 animate-spin text-rose-900" />
                    </div>
                  )}
                </h2>
              </div>
              <button onClick={onClose} className="p-2 text-gray-500 hover:text-gray-700 hover:bg-gray-100 rounded-full transition-colors">
                <ArrowLeft className="w-5 h-5" />
              </button>
            </div>

            {errorMessage && (
              <div className="bg-red-50 border border-red-200 text-red-600 text-sm rounded-lg p-4 mb-6 flex items-start">
                <div className="shrink-0 w-5 h-5 text-red-600 mt-0.5">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-5a.75.75 0 01.75.75v4.5a.75.75 0 01-1.5 0v-4.5A.75.75 0 0110 5zm0 10a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd" />
                  </svg>
                </div>
                <div className="ml-3">
                  <h3 className="text-sm font-medium text-red-800">Registration Error</h3>
                  <div className="mt-1 text-sm text-red-700">
                    {errorMessage}
                  </div>
                </div>
              </div>
            )}

            {renderStep()}
          </div>
        </div>
      </div>
    )}
  </>
);
};

export default Register;