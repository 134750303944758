import {Color, RoseGoldButton}   from "../../../GlobalStyles";
import React, { useState, useEffect, useMemo } from 'react';

const styles = {
  modalOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: Color.colorLightestBeige,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
    padding: '20px',
  },
  modalContent: {
    backgroundColor: Color.colorWhite,
    borderRadius: '8px',
    padding: '20px',
    maxWidth: '600px',
    width: '90%',
    maxHeight: '80vh',
    overflowY: 'auto',
  },
  modalTitle: {
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: '20px',
    color: Color.colorPeacockBlueDark,
  },
  modalBody: {
    fontSize: '14px',
    lineHeight: 1.5,
    marginBottom: '20px',
  },
  termsSection: {
    marginBottom: '15px',
    color: Color.colorRoyalBlueText,
  },
  termsTitle: {
    fontSize: '18px',
    color: Color.colorRoyalBlueText,
    marginBottom: '10px',
    fontWeight: 'bold',
  },
  termsItem: {
    marginBottom: '5px',
  },
  termsFooter: {
    fontWeight: 'bold',
    marginTop: '15px',
  },
  modalFooter: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    color: Color.colorRoyalBlueText,
  },
  checkboxLabel: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '15px',
    fontSize: '14px',
  },
  checkbox: {
    marginRight: '10px',
    color: Color.colorRoyalBlueText,
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
  },
  cancelButton: {
    padding: '10px 20px',
    borderRadius: '8px',
    fontSize: '16px',
    cursor: 'pointer',
    backgroundColor: Color.colorMintGreen,
    color: 'white',
    border: '1px solid #ccc',
    marginRight: '10px',
  },
  agreeButton: {  
    padding: '10px 20px',
    fontSize: '16px',
    borderRadius: '8px',
    cursor: 'pointer',
    border: 'none',
  },
  agreeButtonDisabled: {
    opacity: 0.6,
    cursor: 'not-allowed',
  }
};

const TermsAndConditionsModal = ({ isOpen, onClose,onCancel, onAgree }) => {
  const [agreed, setAgreed] = useState(false);

  useEffect(() => {
    setAgreed(false);
  }, []);
  
  const termsContent = useMemo(() => [
    {
      title: "1. Group Wish Participation",
      content: [
        "1.a No Guarantee: Joining a Group Wish does not guarantee that the deal will be confirmed. We will notify you if we are unable to reach the required pool size.",
        "1.b Verified Users: Only verified users count towards the required pool size. Not all participants may be verified users."
      ]
    },
    {
      title: "2. Deal Closure and Order Placement",
      content: [
        "2.a Closure Date: On the group wish closure date (as mentioned in the description), deal order placement may or may not occur. Users will be notified of any discussions and before any payment is initiated by BacksApp.",
        "2.b Withdrawal: Users can withdraw from the wish at any time until the group is closed for ordering."
      ]
    },
    {
      title: "3. Shipment and Tracking",
      content: [
        "3.a Shipment Details: All shipment details will be shared within the Group Wish. Users can track their packages using this information."
      ]
    },
    {
      title: "4. Payment and Refunds",
      content: [
        "4.a Confirmed Payments: The deal will proceed only when the count of verified users making confirmed payments reaches the required pool size.",
        "4.b Refunds: If for any reason the deal cannot be completed, the full amount will be repaid on the same day."
      ]
    },
    {
      title: "5. Order Fulfillment",
      content: [
        "5.a Preferences: Orders will be made trying to accommodate most preferences. However, it may not be possible to fulfill all individual preferences."
      ]
    },
    {
      title: "6. User Responsibility",
      content: [
        "6.a Compliance: Users are responsible for ensuring that their participation complies with all applicable laws and regulations in their jurisdiction.",
        "6.b Disputes: BacksApp encourages users to resolve disputes amicably. In cases of unresolved disputes, BacksApp reserves the right to make final decisions."
      ]
    },
    {
      title: "7. Modifications to Terms",
      content: [
        "7.a Updates: BacksApp may periodically update these terms. Users are encouraged to review the latest version regularly."
      ]
    }
  ], []);

  if (!isOpen) return null;

  return (
    <div style={styles.modalOverlay}>
      <div style={styles.modalContent}>
        <h2 style={styles.modalTitle}>Terms & Conditions</h2>
        <div style={styles.modalBody}>
          {termsContent.map((section, index) => (
            <div key={index} style={styles.termsSection}>
              <h3 style={styles.termsTitle}>{section.title}</h3>
              {section.content.map((item, itemIndex) => (
                <p key={itemIndex} style={styles.termsItem}>{item}</p>
              ))}
            </div>
          ))}
        </div>          
        <div style={styles.modalFooter}>
          <label style={styles.checkboxLabel}>
            <input 
              type="checkbox" 
              style={styles.checkbox}
              checked={agreed} 
              onChange={(e) => setAgreed(e.target.checked)}
            />
            I agree to the Terms and Conditions
          </label>
          <div style={styles.buttonGroup}>
            <button style={styles.cancelButton} onClick={onCancel}>
              Cancel
            </button>
            <button 
              style={{
                ...RoseGoldButton,
                ...styles.agreeButton,
                ...(agreed ? {} : styles.agreeButtonDisabled)
              }} 
              onClick={() => { if (agreed) { onAgree(); onClose(); } }}
              disabled={!agreed}
            >
              Agree & Join
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditionsModal;