import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers, faShippingFast, faExclamationTriangle, faDollarSign, faShippingFast as faShipping, faPercent, faCheck } from '@fortawesome/free-solid-svg-icons';
import { getApi, postApi, buildUrl } from "../Common/BackendCalls/apiUtils";
import { useAuth } from '../Common/InitialGears/AuthContext';
import { TopMenuBar } from '../Buyers/DashbordComponents/TopMenuBar';
import PriceRangesSelection from './PriceRangesSelection'; 
import * as style from "../../GlobalStyles";
import UserStatsDisplay from './UserStatsDisplay';
import WaitlistSection from './WaitListedUsers';

const statusOptions = [
  { value: "POOL_OPEN", label: "Open for pooling"},
  { value: "MIN_MET", label: "Minimum Met" },   
  { value: "PAYMENT_INITATED", label: "Payment Initated" },
  { value: "ALL_PAYMENTS_COMPLETED", label: "All Payments Completed" },
  { value: "ORDER_SHIPPED_FROM_MANUFACTURER", label: "Order Shipped from Manufacturer" },
  { value: "GOODS_AT_PORT", label: "Goods at port" },
  { value: "GOODS_CUSTOM_CLEARED", label: "Goods Custom Cleared" },
  { value: "INLAND_FRIEGHT", label: "Inland Frieght" },
  { value: "REPACKING", label: "Repacking" },
  { value: "SHIPPED_TO_DELIVERY", label: "Shipped to Delivery" },
  { value: "DELIVERING", label: "Delivering" },
  { value: "DEAL_COMPLETED", label: "Deal Completed" },
];

const STATUS_TRANSITIONS = {
  'POOL_OPEN': ['MIN_MET'],
  'MIN_MET': ['PAYMENT_INITATED'],
  'PAYMENT_INITATED': ['ALL_PAYMENTS_COMPLETED'],
  'ALL_PAYMENTS_COMPLETED': ['ORDER_SHIPPED_FROM_MANUFACTURER'],
  'ORDER_SHIPPED_FROM_MANUFACTURER': ['GOODS_AT_PORT'],
  'GOODS_AT_PORT': ['GOODS_CUSTOM_CLEARED'],
  'GOODS_CUSTOM_CLEARED': ['INLAND_FRIEGHT'],
  'INLAND_FRIEGHT': ['REPACKING'],
  'REPACKING': ['SHIPPED_TO_DELIVERY'],
  'SHIPPED_TO_DELIVERY': ['DELIVERING'],
  'DELIVERING': ['DEAL_COMPLETED'],
  'DEAL_COMPLETED': []
};
 

const GroupWishProcessing = () => {
  const { groupWishId } = useParams();
  const { queryLoginParam } = useAuth();
  const navigate = useNavigate();
  const [groupWish, setGroupWish] = useState(null);
  const [priceRanges, setPriceRanges] = useState(null);
  const [groupWishUsers, setGroupWishUsers] = useState(null);
  const [currentStatus, setCurrentStatus] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState(''); 
  const [closingProductCost, setClosingProductCost] = useState('');
  const [closingShippingCost, setClosingShippingCost] = useState('');
  const [closingBacksAppCharges, setClosingBacksAppCharges] = useState(''); 
  const [closingBacksAppChargeType, setClosingBacksAppChargeType] = useState('');   
  const [isEditingExpiry, setIsEditingExpiry] = useState(false);
  const [expiryDate, setExpiryDate] = useState('');
  const [selectedActualRange, setSelectedActualRange] = useState(null);
  const [selectedNonActualRange, setSelectedNonActualRange] = useState(null);
  const [closingOrderSize, setClosingOrderSize] = useState(''); 
  const [waitlistUsers, setWaitlistUsers] = useState([]);
  const [groupWishRating, setGroupWishRating] = useState(0);
  const [groupWishTotalRating, setGroupWishTotalRating] = useState(0);
  const [groupWishRatingCount, setGroupWishRatingCount] = useState(1);

  useEffect(() => {
    fetchGroupWishDetails();
  }, [groupWishId]);
   
  const fetchGroupWishDetails = async () => {
    setIsLoading(true);
    try {
      const url = buildUrl(`groupWishesOrderProcessing/${groupWishId}/`, queryLoginParam);
      const response = await getApi(url);
      if (response) { 
        setGroupWish(response.data.group_wish);
        setPriceRanges(response.data.price_ranges);
        setGroupWishUsers(response.data);
        setCurrentStatus(response.data.group_wish.group_wish_status); 
        setClosingOrderSize(response.data.group_wish.closing_order_size || '');
        setClosingProductCost(response.data.group_wish.closing_product_cost || '');
        setClosingShippingCost(response.data.closing_shipping_cost || '');
        setClosingBacksAppChargeType(response.data.group_wish.commission_type || '');
        setClosingBacksAppCharges(response.data.group_wish.commission_value || '');
        setWaitlistUsers(response.data.waitlist_users || []);

        const formattedDate = expiryDateTime.toISOString().split('T')[0];
        const expiryDateTime = new Date(response.data.group_wish.expires_at);        
        setExpiryDate(formattedDate); 

        const totalRating = response.data.group_wish.total_rating || 0;
        const ratingCount = response.data.group_wish.rating_count || 1;         
        setGroupWishTotalRating(totalRating);
        setGroupWishRatingCount(ratingCount);
        setGroupWishRating(ratingCount > 0 ? totalRating / ratingCount : 0);

        if (response.data.selected_price_range !== undefined) {
          const selectedRange = response.data.group_wish.price_ranges.find(
            range => range.id === response.data.selected_price_range
          );
          if (selectedRange) {
            if (selectedRange.actual_price_range) {
              setSelectedActualRange(selectedRange.id);
            } else {
              setSelectedNonActualRange(selectedRange.id);
            }
          }
        }
      } else {
        setErrorMessage('Failed to fetch group wish details');
      }
    } catch (error) {
      setErrorMessage('An error occurred while fetching group wish details');
    } finally {
      setIsLoading(false);
    }
  };

  const handleExpiryDateChange = async () => {
    if (expiryDate === null) {
        setErrorMessage('Please select an expiry date before updating');
        return;
    }
    setIsLoading(true);
    setErrorMessage('');
    setSuccessMessage('');
    try {
      const url = buildUrl(`updateGroupWishExpiryDate/${groupWishId}/`, queryLoginParam);
      const response = await postApi(url, {  
        expiry_date: new Date(new Date(expiryDate).setHours(23, 59, 59)).toISOString(), 
      });
      if (response) { 
        setSuccessMessage('Status, details, and date updated successfully');
        fetchGroupWishDetails();
      } else {
        setErrorMessage('Failed to update status and details');
      }
    } catch (error) {
      setErrorMessage('An error occurred while updating status and details');
    } finally {
      setIsLoading(false);
    }
  }; 

  const handleBumpedUsersUpdate = async (bumpedVerifiedCount, bumpedVerifiedQty, bumpedUnverifiedCount, bumpedUnverifiedQty) => {
    setIsLoading(true);
    setErrorMessage('');
    setSuccessMessage('');
  
    try {
      const url = buildUrl(`updateGroupWishBumpedUsers/${groupWishId}/`, queryLoginParam); 
      const response = await postApi(url, {
        bumped_verified_count: bumpedVerifiedCount,
        bumped_verified_qty: bumpedVerifiedQty,
        bumped_unverified_count: bumpedUnverifiedCount,
        bumped_unverified_qty: bumpedUnverifiedQty
      });
  
      if (response) {
        setSuccessMessage('Bumped users updated successfully');
        fetchGroupWishDetails(); // Refresh the data
      } else {
        setErrorMessage('Failed to update bumped users');
      }
    } catch (error) {
      setErrorMessage('An error occurred while updating bumped users');
    } finally {
      setIsLoading(false);
    }
  };

  const canEditClosingOrderSize = (status) => {
    return ['MIN_MET' ].includes(status) || 
           (groupWish?.group_wish_status === status && ['MIN_MET'].includes(status));
  };
 
  const handleClosingOrderCountChange = (e) => {
    const value = e.target.value; 
    if (value === '' || (/^\d+$/.test(value) && parseInt(value) > 0)) {
      setClosingOrderSize(value);
    }
  };

  const getAvailableStatusOptions = (currentStatus) => {
    const availableStatuses = STATUS_TRANSITIONS[currentStatus] || [];
    return statusOptions.filter(option => 
      option.value === currentStatus || availableStatuses.includes(option.value)
    );
  };

  const handleStatusChange = async (newStatus) => {
    const availableStatuses = STATUS_TRANSITIONS[currentStatus] || [];
    if (!availableStatuses.includes(newStatus) && newStatus !== currentStatus) {
      setErrorMessage(`Invalid status transition from ${currentStatus} to ${newStatus}`);
      return;
    }

    // Validate price range selection for MIN_MET status
    if (newStatus === 'MIN_MET') {
      if (!selectedActualRange || !selectedNonActualRange) {
        setErrorMessage('Please select both actual and customer facing price ranges before updating to MIN_MET status');
        return;
      }
    }

    // Validate closing order size for MIN_MET  
    if ((newStatus === 'MIN_MET' ) && !closingOrderSize) {
      setErrorMessage('Please enter a closing order size before updating to this status');
      return;
    }

    setIsLoading(true);
    setErrorMessage('');
    setSuccessMessage('');
    
    try {
      const url = buildUrl(`updateGroupWishOrderStatus/${groupWishId}/`, queryLoginParam);
      const response = await postApi(url, { 
        status: newStatus,
        closing_order_count: closingOrderSize,
        closing_product_cost: closingProductCost,
        closing_shipping_cost: closingShippingCost,
        closing_backsapp_charges: closingBacksAppCharges,
        selected_actual_range: selectedActualRange,
        selected_non_actual_range: selectedNonActualRange
      });
      
      if (response) {
        setCurrentStatus(newStatus);
        setSuccessMessage('Status and details updated successfully');
        fetchGroupWishDetails();
      } else {
        setErrorMessage('Failed to update status and details');
      }
    } catch (error) {
      setErrorMessage('An error occurred while updating status and details');
    } finally {
      setIsLoading(false);
    }
  }; 

  const calculateTotalQuantity = (users) => {
    return users.reduce((total, user) => total + user.quantity, 0);
  };

  const renderUserList = (users, title, icon, showDetails = true) => {
    const totalQuantity = calculateTotalQuantity(users);
    
    return (
      <div className="user-list-container">
        <h3 className="user-list-title">
          <FontAwesomeIcon icon={icon} /> {title}
        </h3>
        <p className="user-quantity">Total Users: {users.length}</p>
        <p className="user-quantity">Total Quantity: {totalQuantity}</p>
        {showDetails && users.length > 0 ? (
          <ul className="user-list">
            {users.map((user, index) => (
              <li key={index} className="user-item">
                <div className="user-details">
                  <span className="user-name">{`${user.user.first_name} ${user.user.last_name}`}</span>
                  <span className="user-email">{user.user.email}</span>
                </div>
                <span className="user-quantity">Qty: {user.quantity}</span>
              </li>
            ))}
          </ul>
        ) : (
          !showDetails && <p className="no-details"></p>
        )}
      </div>
    );
  };

  const canEditPriceRanges = (status) => {
    const editableStatuses = ['MIN_MET'];
    return editableStatuses.includes(status);
  };

  const handlePriceRangeSelection = (rangeId, isActual) => {
    if (!canEditPriceRanges(currentStatus)) {
      return;
    }

    const selectedRange = priceRanges.find(range => range.id === rangeId);
    if (selectedRange) {
      if (isActual) {
        setSelectedActualRange(rangeId);
      } else {
        setSelectedNonActualRange(rangeId);
      }
    }
  };

  const categorizeUsers = (groupWishData) => {
    if (!groupWishData || !groupWishData.group_wish) return {
      verifiedUsers: [],
      unverifiedUsers: [],
      bumpedVerifiedUsers: [],
      bumpedUnverifiedUsers: [],
      verifiedQtyCount: 0,
      unverifiedQtyCount: 0,
      bumpedVerifiedQtyCount: 0,  
      bumpedUnverifiedQtyCount: 0  
    };

    const gw = groupWishData.group_wish;     
    const createUserArray = (count) => Array(count).fill().map(() => ({ quantity: 1 }));

    return {
      verifiedUsers: createUserArray(gw.verified_user_count || 0),
      unverifiedUsers: createUserArray(gw.unverified_user_count || 0),
      bumpedVerifiedUsers: createUserArray(gw.bumped_verified_user_count || 0),
      bumpedUnverifiedUsers: createUserArray(gw.bumped_unverified_user_count || 0),
      verifiedQtyCount: gw.verified_qty_count || 0,
      unverifiedQtyCount: gw.unverified_qty_count || 0,
      bumpedVerifiedQtyCount: gw.bumped_verified_qty_count || 0,
      bumpedUnverifiedQtyCount: gw.bumped_unverified_qty_count || 0,
    };
  };

  const renderPriceRanges = () => {
    if (!groupWish || !priceRanges || priceRanges.length === 0) {
      return null;
    }
    return (
      <PriceRangesSelection
        priceRanges={priceRanges}
        onPriceRangeSelect={handlePriceRangeSelection}
        selectedActualRange={selectedActualRange}
        selectedNonActualRange={selectedNonActualRange}
        disabled={!canEditPriceRanges(currentStatus)}
      />
    );
  }; 

  if (isLoading) {
    return <div className="loading-container"><div className="spinner"></div></div>;
  }

  if (!groupWish) {
    return <div className="error-message">Group wish not found</div>;
  }

  const handleConfirmWaitlistUsers = async (selectedUserIds) => {
    setIsLoading(true);
    setErrorMessage('');
    setSuccessMessage('');
    
    try {
      const url = buildUrl(`confirmWaitlistUsers/${groupWishId}/`, queryLoginParam);
      const response = await postApi(url, {
        user_ids: selectedUserIds
      });
      
      if (response) {
        setSuccessMessage('Selected users confirmed successfully');
        fetchGroupWishDetails(); // Refresh data including waitlist
      } else {
        setErrorMessage('Failed to confirm selected users');
      }
    } catch (error) {
      setErrorMessage('An error occurred while confirming users');
    } finally {
      setIsLoading(false);
    }
  };

  const handleRatingUpdate = async (newRating, newTotalRating, newRatingCount) => {
    setIsLoading(true);
    setErrorMessage('');
    setSuccessMessage('');
  
    try {
      const url = buildUrl(`updateGroupWishRating/${groupWishId}/`, queryLoginParam);
      const response = await postApi(url, {
        rating: newRating,
        total_rating: newTotalRating,
        rating_count: newRatingCount
      });
  
      if (response) {
        setSuccessMessage('Rating updated successfully');
        setGroupWishRating(newRating);
        setGroupWishTotalRating(newTotalRating);
        setGroupWishRatingCount(newRatingCount);
      } else {
        setErrorMessage('Failed to update rating');
      }
    } catch (error) {
      setErrorMessage('An error occurred while updating rating');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
    <TopMenuBar showBackButton={true} showHomeButton={true} onBackClick={() => navigate('/orderManagement')} title="Order Processing" showSearch={false} showAddress={false} showMenuButton={true}/>
    <div className="order-processing-container">            
      <div className="content-wrapper">        
            <div className="left-column">
              <div className="card product-info-card">
                <div className="card-content">
                  <h2 className="card-name-title">{groupWish.product_name}</h2>
                  {successMessage && <div className="success-message">{successMessage}</div>}
                  <div className="product-details"> 
                    <p><strong>Minimum Group Size:</strong> {groupWish.group_size_min}</p>
                    <p><strong>BacksAp Commision Type:</strong> {groupWish.commission_type}</p>
                    <p><strong>BacksAp Commision Value:</strong> {groupWish.commission_value}</p>
                    <p>
                      <strong>Expiry Date:</strong>{' '}
                      {isEditingExpiry ? (
                        <div className="expiry-edit-container">
                          <input
                            type="date"
                            value={expiryDate}
                            onChange={(e) => setExpiryDate(e.target.value)}
                            className="date-input"/>
                          <button className="save-date-button"
                            onClick={() => {
                              handleExpiryDateChange();
                              setIsEditingExpiry(false);
                            }}
                          >
                            Save
                          </button>
                          <button 
                            className="cancel-date-button"
                            onClick={() => {
                              const cancelDateTime = new Date(groupWish.expires_at);
                              const localCancelDate = new Date(cancelDateTime.getTime() + cancelDateTime.getTimezoneOffset() * 60000);
                              setExpiryDate(localCancelDate.toISOString().split('T')[0]);
                              setIsEditingExpiry(false);
                            }}
                          >
                            Cancel  
                          </button>
                        </div>
                      ) : (
                        <span>
                          {new Date(groupWish.expires_at).toLocaleDateString()}{' '}
                          <button 
                            className="edit-date-button"
                            onClick={() => {
                              const formattedDate = new Date(groupWish.expires_at).toISOString().split('T')[0];
                              setExpiryDate(formattedDate);
                              setIsEditingExpiry(true);
                            }}
                            >
                            Edit
                          </button>
                        </span>
                      )}
                    </p>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-content">
                  <h3 className="card-title">Status</h3>
                  <select value={currentStatus} onChange={(e) => setCurrentStatus(e.target.value)} className="select-status">
                    {getAvailableStatusOptions(currentStatus).map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div> 
                <div className="card-content">
                  <h3 className="card-title">Closing Order Size</h3>
                  <input
                    type="text"
                    value={closingOrderSize}
                    onChange={handleClosingOrderCountChange}
                    disabled={!canEditClosingOrderSize(currentStatus)}
                    placeholder="Enter closing order size"
                    className="input-field"
                  />
                </div>
              </div>   
              <div>
              {renderPriceRanges()}               
            </div>                               
            </div>                            
            <div className="right-column">
            {groupWishUsers && (
              <>
              <UserStatsDisplay  {...categorizeUsers(groupWishUsers)} onBumpedUsersUpdate={handleBumpedUsersUpdate} rating={groupWishRating} totalRating={groupWishTotalRating} ratingCount={groupWishRatingCount} onRatingUpdate={handleRatingUpdate} isGroupWish={true} />
              <WaitlistSection waitlistUsers={waitlistUsers} onConfirmBuyers={handleConfirmWaitlistUsers} />
                </>
            )}
            </div> 
      </div>
      <div className="update-button-wrapper">
        <button  onClick={() => handleStatusChange(currentStatus)}  className="update-button" >
          Update Status and Details
        </button>
      </div>
    </div> 
    </>   
  );  
}

const styles = `
  .order-processing-container {
    font-family: Arial, sans-serif; 
    min-height: 100vh;
    background-color: ${style.Color.colorWhite};
    margin: '4% auto',
  }

  .content-wrapper {
    display: flex;
    gap: 20px;
    padding: 20px;
  }

  .left-column {
    flex: 2;
    background-color: ${style.Color.colorWhite};
  }

  .right-column {
    flex: 1;
  }

  .card {
    margin-top:70px;
    background-color: ${style.Color.colorLightBeige};
    border-radius: 8px;
    margin-bottom: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .card-content {
    padding: 20px;
  }

  .card-name-title {
    font-size: 24px;
    font-weight: bold;
    color: ${style.Color.colorPurple};
    margin-bottom: 15px;
  }

  .message-container {
    position: fixed;
    top: 100;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.7);
    padding: 10px;
    padding-top: 40px;
    z-index: 999;
  }

  .success-message {
    color: #4CAF50;
    font-size: 16px;
    text-align: center;
    margin: 0;
  }

  .error-message {
    color: #F44336;
    font-size: 16px;
    text-align: center;
    margin: 0;
  }

  .card-title {
    font-size: 20px;
    font-weight: bold;
    color: ${style.Color.colorDarkBlue};
    margin-bottom: 15px;
  }

  .product-details p {
    margin-bottom: 10px;
    color: ${style.Color.colorDarkBlue};
  }

  .select-status {
    width: 100%;
    padding: 20px;
    margin-bottom: 10px;
    border: 1px solid ${style.Color.colorBlueGrey};
    border-radius: 8px;
    font-size: 16px;
  }

  .input-field-container {
    margin-bottom: 15px;
  }

  .input-label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: ${style.Color.colorDarkBlue};
  }

  .input-field {
    width: 100%;
    padding: 10px;
    border: 1px solid ${style.Color.colorAppleGreen};
    border-radius: 4px;
    font-size: 16px;
  }

  .input-field.read-only {
    background-color: ${style.Color.colorLightestBeige};
    color: ${style.Color.colorDarkGrey};
  }

  .update-button {
    background-color: ${style.Color.colorMintGreen};
    color: white;
    font-weight: bold;
    border: none;
    padding: 10px 20px;
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    transition: background-color 0.3s;
    width: 30%;
    align-items: center
    margin: 20px 0; 
    margin-bottom: 20px 0; 
  }

  .update-button:hover {
    background-color: ${style.Color.colorAppleGreen};
  }

  .loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }

  .spinner {
    border: 4px solid #f3f3f3;
    border-top: 4px solid ${style.Color.colorPurple};
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  .user-list-container {
    margin-top:70px;
    background-color: ${style.Color.colorWhite};
    border-radius: 8px;
    margin-bottom: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
  }

  .user-list-title {
    font-size: 18px;
    font-weight: bold;
    color: ${style.Color.colorDarkBlue};
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .user-list {
    list-style-type: none;
    padding: 0;
  }

  .user-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid ${style.Color.colorLightGrey};
  }

  .user-details {
    display: flex;
    flex-direction: column;
  }

  .user-name {
    font-weight: bold;
    color: ${style.Color.colorDarkBlue};
  }

  .user-email {
    font-size: 14px;
    color: ${style.Color.colorDarkGrey};
  }

  .user-quantity {
    font-weight: bold;
    color: ${style.Color.colorPurple};
    margin: 5px 0;
  }

  .no-users {
    color: ${style.Color.colorDarkGrey};
    font-style: italic;
  }

  .price-ranges-container {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    margin: 15px 0;
  }

  .price-range-item {
    background-color: ${style.Color.colorLightestBeige};
    border-radius: 8px;
    padding: 15px;
    width: calc(50% - 7.5px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    position: relative;
    cursor: pointer;
    transition: all 0.3s ease;
  }

  .price-range-item:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
  }

  .price-range-item.selected {
    background-color: ${style.Color.colorLightMintGreen};
    border: 2px solid ${style.Color.colorMintGreen};
  }

  .price-range-header {
    font-weight: bold;
    margin-bottom: 10px;
    color: ${style.Color.colorDarkBlue};
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .price-range-details {
    margin-bottom: 10px;
  }

  .price-detail {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    gap: 8px;
  }

  .price-detail svg {
    color: ${style.Color.colorMintGreen};
  }

  .price-range-footer {
    font-style: italic;
    color: ${style.Color.colorDarkGrey};
    font-size: 14px;
    margin-top: 8px;
  }

  .selected-indicator {
    position: absolute;
    top: 10px;
    right: 10px;
    color: ${style.Color.colorMintGreen};
  }

  .expiry-edit-container {
    display: inline-flex;
    gap: 10px;
    align-items: center;
    margin-top: 5px;
  }

  .date-input {
    padding: 5px 10px;
    border: 1px solid ${style.Color.colorLightGrey};
    border-radius: 4px;
    font-size: 14px;
  }

  .edit-date-button {
    background-color: ${style.Color.colorPurple};
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 12px;
  }

  .edit-date-button:hover {
    background-color: ${style.Color.colorDarkPurple};
  }

  .save-date-button {
    background-color: ${style.Color.colorMintGreen};
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 12px;
  }

  .save-date-button:hover {
    background-color: ${style.Color.colorDarkMintGreen};
  }

  .cancel-date-button {
    background-color: ${style.Color.colorDarkGrey};
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 12px;
  }

  .cancel-date-button:hover {
    background-color: ${style.Color.colorDarkerGrey};
  }


  .price-range-item {
    width: 100%;
  }

  .expiry-edit-container {
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
  }
  
  .update-button-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 20px;
    margin-top: 20px;
  }

  .update-button {
    background-color: ${style.Color.colorMintGreen};
    color: white;
    font-weight: bold;
    border: none;
    padding: 16px 32px;
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.3s ease;
    min-width: 250px;
    text-align: center;
  }

  .update-button:hover {
    background-color: ${style.Color.colorAppleGreen};
    transform: translateY(-1px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .update-button:active {
    transform: translateY(0);
    box-shadow: none;
  }

  .update-button:disabled {
    background-color: ${style.Color.colorLightGrey};
    cursor: not-allowed;
    transform: none;
    box-shadow: none;
  }

  @media (max-width: 768px) {
    .content-wrapper {
      flex-direction: column;
    }

  }
`;

// Add styles to the document
const styleSheet = document.createElement("style");
styleSheet.type = "text/css";
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);

export default GroupWishProcessing;