import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faCheck, faScissors, faObjectGroup,faObjectUngroup } from '@fortawesome/free-solid-svg-icons';
import * as style from "../../../GlobalStyles";

const UserPreferenceGroup = ({ 
  group, 
  groupName,
  groupId,
  preferences, 
  onPreferenceValueSelect, 
  onSplitGroup, 
  onMergeGroup,
  totalGroups,
  groupIndex 
}) => {
  const [selectedPreferences, setSelectedPreferences] = useState([]);

  const handleDeleteValue = (preferenceUuid, valueUuid) => {
    const currentSelection = group.preferences[preferenceUuid];
    if (!currentSelection) return;
    
    if (currentSelection.selectedValue?.uuid === valueUuid) {
      const newValues = currentSelection.additionalValues.map(v => v.uuid);
      if (newValues.length > 0) {
        onPreferenceValueSelect(groupId, preferenceUuid, [newValues[0]]);
      } else {
        onPreferenceValueSelect(groupId, preferenceUuid, null);
      }
    } else {
      const newValues = [
        currentSelection.selectedValue.uuid,
        ...currentSelection.additionalValues
          .filter(v => v.uuid !== valueUuid)
          .map(v => v.uuid)
      ];
      onPreferenceValueSelect(groupId, preferenceUuid, newValues);
    }
  };

  const handleValueSelect = (preference, value) => {
    if (!group) {
      console.warn('Group is undefined, initializing empty group');
      return;
    }

    if (!group.preferences) {
      group.preferences = {};
    }

    if (!group.preferences[preference.uuid]) {
      group.preferences[preference.uuid] = {
        selectedValue: null,
        additionalValues: []
      };
    }

    const currentSelection = group.preferences[preference.uuid];
    let newValues = [];

    if (preference.isMultiple) {
      const isSelected = currentSelection.selectedValue?.uuid === value.uuid ||
        currentSelection.additionalValues?.some(v => v.uuid === value.uuid);

      if (isSelected) {
        if (currentSelection.selectedValue?.uuid === value.uuid) {
          newValues = (currentSelection.additionalValues || []).map(v => v.uuid);
          if (newValues.length > 0) {
            newValues = [newValues[0], ...newValues.slice(1)];
          }
        } else {
          newValues = [
            currentSelection.selectedValue?.uuid,
            ...(currentSelection.additionalValues || [])
              .filter(v => v.uuid !== value.uuid)
              .map(v => v.uuid)
          ].filter(Boolean);
        }
      } else {
        newValues = currentSelection.selectedValue
          ? [
              currentSelection.selectedValue.uuid,
              ...(currentSelection.additionalValues || []).map(v => v.uuid),
              value.uuid
            ]
          : [value.uuid];
      }
    } else {
      newValues = [value.uuid];
    }

    onPreferenceValueSelect(groupId, preference.uuid, newValues.length > 0 ? newValues : null);

    try {
      const updatedSelections = preferences.reduce((acc, pref) => {
        if (pref.uuid === preference.uuid) {
          const selections = [];
          if (newValues && newValues.length > 0) {
            const mainValue = pref.values.find(v => v.uuid === newValues[0]);
            if (mainValue) {
              selections.push({
                name: pref.name,
                value: mainValue.value,
                additionalCharges: mainValue.hasAdditionalCharges 
                  ? mainValue.additionalChargesAmount 
                  : null
              });
            }
            newValues.slice(1).forEach(valueUuid => {
              const additionalValue = pref.values.find(v => v.uuid === valueUuid);
              if (additionalValue) {
                selections.push({
                  name: pref.name,
                  value: additionalValue.value,
                  additionalCharges: additionalValue.hasAdditionalCharges 
                    ? additionalValue.additionalChargesAmount 
                    : null
                });
              }
            });
          }
          return [...acc, ...selections];
        } else {
          const currentPrefSelection = group.preferences[pref.uuid];
          if (!currentPrefSelection) return acc;
          
          const selections = [];
          if (currentPrefSelection.selectedValue) {
            selections.push({
              name: pref.name,
              value: currentPrefSelection.selectedValue.value,
              additionalCharges: currentPrefSelection.selectedValue.hasAdditionalCharges 
                ? currentPrefSelection.selectedValue.additionalChargesAmount 
                : null
            });
          }
          if (currentPrefSelection.additionalValues) {
            currentPrefSelection.additionalValues.forEach(val => {
              selections.push({
                name: pref.name,
                value: val.value,
                additionalCharges: val.hasAdditionalCharges 
                  ? val.additionalChargesAmount 
                  : null
              });
            });
          }
          return [...acc, ...selections];
        }
      }, []);

      setSelectedPreferences(updatedSelections);
    } catch (error) {
      console.error('Error updating selected preferences:', error);
      setSelectedPreferences([]);
    }
  };

  return (
    <div style={styles.preferenceGroup}>
      <div style={styles.preferenceGroupHeader}>
        <div style={styles.headerLeft}>
          <div style={styles.groupInfo}>
            <h3 style={styles.groupTitle}>Choice-{groupIndex + 1} </h3>
            <span style={styles.quantityBadge}>Quantity: {group.quantity}</span>
          </div>
        </div>
        <div style={styles.groupActions}>
          {group.quantity > 1 && (
            <button style={styles.actionButton}  onClick={() => onSplitGroup(groupId)} >
              <FontAwesomeIcon icon={faScissors} style={styles.buttonIcon} />
              Split
            </button>
          )}
          {groupIndex < totalGroups - 1 && (
            <button  style={styles.actionButton} onClick={() => onMergeGroup(groupId)} >
              <FontAwesomeIcon icon={faObjectGroup} style={styles.buttonIcon} />
              Merge
            </button>
          )}
        </div>
      </div>
      
      {selectedPreferences.length > 0 && (
        <div style={styles.selectedSummary}>
          <div style={styles.selectedSummaryHeader}>
            <FontAwesomeIcon icon={faCheck} style={styles.checkIcon} />
            <span style={styles.selectedSummaryTitle}>Selected Values</span>
          </div>
          <div style={styles.selectedSummaryContent}>
            {selectedPreferences.map((selection, index) => (
              <div key={index} style={styles.selectedSummaryItem}>
                <span style={styles.selectedItemName}>{selection.name}:</span>
                <span style={styles.selectedItemValue}>
                  {selection.value}
                  {selection.additionalCharges && (
                    <span style={styles.selectedItemCharge}>
                      (+${selection.additionalCharges})
                    </span>
                  )}
                </span>
              </div>
            ))}
          </div>
        </div>
      )}

      {preferences.map(preference => {
        const currentSelection = group.preferences[preference.uuid] || { selectedValue: null, additionalValues: [] };
        const hasSelection = currentSelection.selectedValue || currentSelection.additionalValues.length > 0;
        
        return (
          <div key={preference.uuid} style={styles.preferenceItem}>
            <div style={styles.preferenceHeader}>
              <span style={styles.preferenceTitle}>{preference.name}</span>
              {preference.isMultiple && (
                <p style={styles.preferenceNote}>
                  You can select multiple options for this preference
                </p>
              )}
            </div>
            
            {hasSelection && (
              <div style={styles.selectedValuesContainer}>
                {currentSelection.selectedValue && (
                  <div style={styles.selectedValueChip}>
                    <span>{currentSelection.selectedValue.value}</span>
                    <button  style={styles.deleteValueButton} onClick={() => handleDeleteValue(preference.uuid, currentSelection.selectedValue.uuid)} >
                      <FontAwesomeIcon icon={faTrashAlt} />
                    </button>
                  </div>
                )}
                {currentSelection.additionalValues.map((value) => (
                  <div key={value.uuid} style={styles.selectedValueChip}>
                    <span>{value.value}</span>
                    <button 
                      style={styles.deleteValueButton}
                      onClick={() => handleDeleteValue(preference.uuid, value.uuid)}
                    >
                      <FontAwesomeIcon icon={faTrashAlt} />
                    </button>
                  </div>
                ))}
              </div>
            )}

            <div style={styles.preferenceValues}>
              {preference.values.map(value => {
                const isSelected = currentSelection.selectedValue?.uuid === value.uuid ||
                  currentSelection.additionalValues.some(v => v.uuid === value.uuid);

                return (
                  <div
                    key={value.uuid}
                    onClick={() => handleValueSelect(preference, value)}
                    style={{
                      ...styles.preferenceValue,
                      ...(isSelected ? styles.preferenceValueSelected : {}),
                      cursor: 'pointer'
                    }}
                  >
                    <span>{value.value}</span>
                    {value.hasAdditionalCharges && (
                      <span style={styles.additionalCharges}>
                        (+{value.additionalChargesAmount})
                      </span>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};

const styles = {
  preferenceGroup: {
    border: '1px solid #e5e7eb',
    borderRadius: '0.25rem',
    padding: '1rem',
    marginBottom: '1rem',
    backgroundColor: '#ffffff',
    borderRadius: '8px',
  },
  preferenceGroupHeader: {
    borderBottom: '1px solid #e5e7eb',
    paddingBottom: '0.75rem',
    marginBottom: '1rem',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  headerLeft: {
    flex: '1'
  },
  groupInfo: {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem'
  },
  groupTitle: {  
    color: style.Color.colorPeacockBlue,
    fontSize: '0.875rem',
    fontWeight: '600',
    margin: 0
  },
  quantityBadge: { 
    color: style.Color.colorPeacockBlue,
    padding: '0.25rem 0.75rem',
    borderRadius: '9999px',
    fontSize: '0.875rem',
    fontWeight: '500'
  },
  groupActions: {
    display: 'flex',
    gap: '0.75rem',
    alignItems: 'center'
  },
  actionButton: { 
    color: style.Color.colorPeacockBlue,
    padding: '0.5rem 1rem',
    borderRadius: '0.375rem',
    fontSize: '0.875rem',
    fontWeight: '500',
    border: `1px solid ${style.Color.colorPeacockBlue}`,  
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
    background: 'transparent'
  },
  buttonIcon: {
    fontSize: '0.875rem'
  },
  preferenceItem: {
    marginBottom: '1rem'
  },
  preferenceHeader: {
    marginBottom: '0.5rem'
  },
  preferenceTitle: {
    fontWeight: '500',
    color: style.Color.colorPeacockBlue,
    display: 'block',
    marginBottom: '0.25rem'
  },
  preferenceNote: {
    fontWeight: '200',
    color: style.Color.colorDarkGrey,
    margin: '0.25rem 0'
  },
  selectedValuesContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '0.5rem',
    marginBottom: '0.75rem'
  },
  selectedValueChip: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: style.Color.colorGolden,
    color: style.Color.colorDarkBlueBlack,
    padding: '0.5rem 0.75rem',
    fontSize: '0.875rem',
    border: '1px solid #e5e7eb',
    borderRadius: '0.25rem',
    minWidth: '120px',
    maxWidth: '200px'
  },
  deleteValueButton: {
    background: 'none',
    border: 'none',
    color: style.Color.colorVibrantRed,
    padding: '0.25rem',
    cursor: 'pointer',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: '0.5rem'
  },
  preferenceValues: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '0.5rem'
  },
  preferenceValue: { 
    color: style.Color.colorLimeGreen,
    padding: '0.5rem 0.75rem',
    border: `1px solid ${style.Color.colorLimeGreen}`,  
    borderRadius: '8px',
    fontSize: '0.875rem', 
    display: 'flex',
    alignItems: 'center',
    gap: '0.25rem',
    transition: 'all 0.2s ease',
    userSelect: 'none'
  },
  preferenceValueSelected: {
    backgroundColor: style.Color.colorMintGreen,
    borderColor: style.Color.colorMintGreen,
    color: style.Color.colorDarkBlueBlack,
    fontWeight: '500'
  },
  additionalCharges: {
    marginLeft: '0.25rem',
    fontSize: '0.75rem',
    color: style.Color.colorDarkBlue
  },
  selectedSummary: {
    backgroundColor: style.Color.colorLightBeige,
    border: `1px solid ${style.Color.colorMintGreen}`,
    borderRadius: '0.375rem',
    padding: '0.75rem',
    marginTop: '0.75rem'
  },
  selectedSummaryHeader: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
    marginBottom: '0.5rem'
  },
  checkIcon: {
    color: style.Color.colorMintGreen,
    fontSize: '1rem'
  },
  selectedSummaryTitle: {
    color: style.Color.colorDarkBlueBlack,
    fontWeight: '600',
    fontSize: '0.875rem'
  },
  selectedSummaryContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.375rem'
  },
  selectedSummaryItem: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
    fontSize: '0.875rem'
  },
  selectedItemName: {
    color: style.Color.colorDarkGrey,
    fontWeight: '500',
    minWidth: '100px'
  },
  selectedItemValue: {
    color: style.Color.colorDarkBlueBlack,
    display: 'flex',
    alignItems: 'center',
    gap: '0.25rem'
  },
  selectedItemCharge: {
    color: style.Color.colorDarkGrey,
    fontSize: '0.75rem',
    marginLeft: '0.25rem'
  }
};

export default UserPreferenceGroup;