import React from 'react';
import * as styles1 from '../../../GlobalStyles';

const InvoiceProductDetails = ({ invoiceData, formatCurrency }) => {
  if (!invoiceData) return null; 
  const isCombo = invoiceData.combo_title && invoiceData.combo_image; 
  const imageSrc = isCombo 
    ? invoiceData.combo_image 
    : invoiceData.group_wish_image; 
  function cleanDescription(description) { 
    let cleaned = description.replace(/[()'"]/g, '').replace(/,\s*$/, ''); 
    cleaned = cleaned.replace(/^Group Wish:\s*/, ''); 
    return cleaned;
  } 
  const description = isCombo ? invoiceData.combo_title  : cleanDescription(invoiceData.invoice_item.description);      

  return (
    <div> 
      <div style={styles.flexRow}> 
        <div style={styles.imageContainer}>
          <img
            src={imageSrc}
            alt={description}
            style={styles.image}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = "https://via.placeholder.com/300x300?text=Image+Not+Available";
            }}
          />
        </div>
        
        {/* Product Info */}
        <div style={styles.infoContainer}>
          <div style={styles.infoCard}>
            <p style={styles.labelText}>Description</p>
            <p style={styles.valueText}>{description}</p>
          </div>
          
          <div style={styles.detailsGrid}>
            <div style={styles.infoCard}>
              <p style={styles.labelText}>Unit Price</p>
              <p style={styles.valueText}>
                {formatCurrency(invoiceData.closing_price_per_item)}
              </p>
            </div>
            
            <div style={styles.infoCard}>
              <p style={styles.labelText}>Quantity</p>
              <p style={styles.valueText}>
                {invoiceData.invoice_item.quantity}
              </p>
            </div>
            
            <div style={styles.infoCard}>
              <p style={styles.labelText}>Total Amount</p>
              <p style={styles.valueText}>
                {formatCurrency(invoiceData.total_amount)}
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Display individual group wish products if this is a combo */}
      {isCombo && invoiceData.group_wish_products && invoiceData.group_wish_products.length > 0 && (
        <div style={styles.bundleItemsContainer}>
          <h4 style={styles.bundleItemsTitle}>Bundle Products</h4>
          <div style={styles.bundleItemsGrid}>
            {invoiceData.group_wish_products.map((product, index) => (
              <div key={index} style={styles.bundleItem}>
                {product.image_url && (
                  <div style={styles.bundleItemImageContainer}>
                    <img 
                      src={product.image_url} 
                      alt={product.product_name}
                      style={styles.bundleItemImage}
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = "https://via.placeholder.com/100x100?text=Image+Not+Available";
                      }}
                    />
                  </div>
                )}
                <div style={styles.bundleItemDetails}>
                  <p style={styles.bundleItemName}>{product.product_name}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      {/* Display combo preferences if this is a combo */}
      {isCombo && invoiceData.invoice_item.preference_items && invoiceData.invoice_item.preference_items.length > 0 && (
        <div style={styles.comboItemsContainer}>
          <h4 style={styles.comboItemsTitle}>Your Preferences</h4>
          <div style={styles.comboItemsList}>
            {Array.from(new Set(invoiceData.invoice_item.preference_items.map(item => item.item_index))).map(index => (
              <div key={index} style={styles.comboItem}>
                <div style={styles.comboItemHeader}>Item {index}</div>
                <div style={styles.comboItemPrefs}>
                  {invoiceData.invoice_item.preference_items
                    .filter(pref => pref.item_index === index)
                    .map((pref, i) => (
                      <div key={i} style={styles.comboPrefRow}>
                        <span style={styles.comboPrefLabel}>{pref.preference_name}:</span>
                        <span style={styles.comboPrefValue}>{pref.preference_value_name}</span>
                        {pref.is_billable && pref.total_cost > 0 && (
                          <span style={styles.comboPrefCost}>
                            ({formatCurrency(pref.total_cost)})
                          </span>
                        )}
                      </div>
                    ))
                  }
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

const styles = {
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
    gap: '24px',
    marginBottom: '24px',
    width: '100%',
    '@media (max-width: 768px)': {
      flexDirection: 'column',
    }
  },
  imageContainer: {
    flex: '0 0 33.333333%',
    '@media (max-width: 768px)': {
      width: '100%'
    }
  },
  image: {
    width: '80%',
    height: 'auto',
    objectFit: 'cover',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)'
  },
  infoContainer: {
    flex: '0 0 66.666667%',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    '@media (max-width: 768px)': {
      width: '100%'
    }
  },
  infoCard: {
    backgroundColor: '#FFFFFF',
    padding: '10px',
    borderRadius: '8px',
    width: "95%",
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)'
  },
  detailsGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    width: "96%",
    gap: '16px',
    '@media (max-width: 768px)': {
      gridTemplateColumns: 'repeat(2, 1fr)'
    }
  },
  labelText: {
    fontSize: '14px',
    color: '#666666',
    marginBottom: '4px'
  },
  valueText: {
    fontSize: '16px',
    color: styles1.Color.colorRoyalBlueText,
    fontWeight: '500'
  },
  // Bundle items styles
  bundleItemsContainer: {
    marginTop: '20px',
    padding: '16px',
    backgroundColor: styles1.Color.colorLightBeige,
    borderRadius: '8px',
    marginBottom: '20px'
  },
  bundleItemsTitle: {
    fontSize: '18px',
    fontWeight: '600',
    color: styles1.Color.colorTeal,
    marginBottom: '16px'
  },
  bundleItemsGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
    gap: '16px'
  },
  bundleItem: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#FFFFFF',
    borderRadius: '8px',
    padding: '12px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
    transition: 'transform 0.2s ease-in-out',
    ':hover': {
      transform: 'translateY(-3px)'
    }
  },
  bundleItemImageContainer: {
    width: '100%',
    height: '120px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '12px'
  },
  bundleItemImage: {
    maxWidth: '100%',
    maxHeight: '100%',
    objectFit: 'contain',
    borderRadius: '4px'
  },
  bundleItemDetails: {
    textAlign: 'center'
  },
  bundleItemName: {
    fontSize: '14px',
    fontWeight: '500',
    color: styles1.Color.colorPurple,
    margin: 0
  },
  // Combo items styles
  comboItemsContainer: {
    backgroundColor: '#F5F5F5',
    padding: '16px',
    borderRadius: '8px',
    marginTop: '16px'
  },
  comboItemsTitle: {
    fontSize: '16px',
    fontWeight: '600',
    color: styles1.Color.colorTeal,
    marginBottom: '12px'
  },
  comboItemsList: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
    gap: '16px'
  },
  comboItem: {
    backgroundColor: '#FFFFFF',
    padding: '12px',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)'
  },
  comboItemHeader: {
    fontSize: '14px',
    fontWeight: '600',
    color: styles1.Color.colorPeacockBlueDark,
    marginBottom: '8px',
    padding: '0 0 4px 0',
    borderBottom: `1px solid ${styles1.Color.colorLightBeige}`
  },
  comboItemPrefs: {
    display: 'flex',
    flexDirection: 'column',
    gap: '6px'
  },
  comboPrefRow: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '13px',
    flexWrap: 'wrap'
  },
  comboPrefLabel: {
    color: '#666666',
    flex: '1 1 30%'
  },
  comboPrefValue: {
    color: styles1.Color.colorRoyalBlueText,
    fontWeight: '500',
    flex: '1 1 50%',
    textAlign: 'right'
  },
  comboPrefCost: {
    color: styles1.Color.colorPurple,
    fontSize: '12px',
    fontWeight: '500',
    marginLeft: '8px'
  }
};

export default InvoiceProductDetails;