import React, { useState, useRef, useEffect } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';

const CategoriesGrid = ({ categories }) => {
  const [viewMode, setViewMode] = useState('CONSUMER');
  const [failedImages, setFailedImages] = useState(new Set());
  const scrollContainerRef = useRef(null);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(true);

  useEffect(() => {
    const checkScroll = () => {
      if (scrollContainerRef.current) {
        const { scrollLeft, scrollWidth, clientWidth } = scrollContainerRef.current;
        setShowLeftArrow(scrollLeft > 0);
        setShowRightArrow(scrollLeft < scrollWidth - clientWidth - 10);
      }
    };

    const container = scrollContainerRef.current;
    if (container) {
      container.addEventListener('scroll', checkScroll);
      checkScroll(); // Initial check
      
      return () => container.removeEventListener('scroll', checkScroll);
    }
  }, []);

  if (!categories || categories.length === 0) {
    return (
      <div className="text-center text-gray-600">
        No categories available
      </div>
    );
  }

  const handleImageError = (categoryId) => {
    setFailedImages(prev => new Set(prev).add(categoryId));
  };

  const filteredCategories = categories.filter(category => 
    category.target_user_type === viewMode || category.target_user_type === 'BOTH'    
  );

  const fallbackImageSrc = `data:image/svg+xml;base64,${btoa(`
    <svg xmlns="http://www.w3.org/2000/svg" width="400" height="400" viewBox="0 0 400 400">
      <rect width="400" height="400" fill="#f3f4f6"/>
      <path d="M160 120h80v80h-80z" fill="#d1d5db"/>
      <text x="200" y="220" text-anchor="middle" font-family="Arial" font-size="16" fill="#6b7280">
        Image Not Available
      </text>
    </svg>
  `)}`;

  const scroll = (direction) => {
    if (scrollContainerRef.current) {
      const scrollAmount = 400; // Adjust this value to control scroll distance
      scrollContainerRef.current.scrollBy({
        left: direction === 'left' ? -scrollAmount : scrollAmount,
        behavior: 'smooth'
      });
    }
  };

  return (
    <section className="py-16 bg-gradient-to-b from-amber-50 to-white">
      <div className="max-w-[90rem] mx-auto px-8">
        <div className="text-center mb-8">
          <h2 className="text-3xl font-bold text-rose-800 mb-4">Featured Categories</h2>
          <p className="text-xl text-amber-700/80 mb-8">
            Explore group buys in your favorite categories, or suggest a new one — we're always growing!
          </p>
          
          <div className="inline-flex items-center bg-gray-100 rounded-lg p-1 mb-8">
            <button
              onClick={() => setViewMode('CONSUMER')}
              className={`px-6 py-2 rounded-md transition-all duration-200 ${
                viewMode === 'CONSUMER'
                  ? 'bg-rose-800 text-white'
                  : 'text-gray-600 hover:text-rose-800'
              }`}
            >
              Consumer
            </button>
            <button
              onClick={() => setViewMode('BUSINESS')}
              className={`px-6 py-2 rounded-md transition-all duration-200 ${
                viewMode === 'BUSINESS'
                  ? 'bg-rose-800 text-white'
                  : 'text-gray-600 hover:text-rose-800'
              }`}
            >
              Business
            </button>
          </div>
        </div>

        {filteredCategories.length === 0 ? (
          <div className="text-center text-gray-600">
            No categories available for {viewMode.toLowerCase()} view
          </div>
        ) : (
          <div className="relative">
            {/* Left scroll button */}
            {showLeftArrow && (
              <button 
                onClick={() => scroll('left')}
                className="absolute left-0 top-1/2 -translate-y-1/2 -translate-x-4 z-10 bg-white/80 hover:bg-white p-2 rounded-full shadow-lg transition-all duration-200"
              >
                <ChevronLeft className="w-6 h-6 text-rose-800" />
              </button>
            )}
            
            {/* Right scroll button */}
            {showRightArrow && (
              <button 
                onClick={() => scroll('right')}
                className="absolute right-0 top-1/2 -translate-y-1/2 translate-x-4 z-10 bg-white/80 hover:bg-white p-2 rounded-full shadow-lg transition-all duration-200"
              >
                <ChevronRight className="w-6 h-6 text-rose-800" />
              </button>
            )}

            {/* Scrollable container */}
            <div 
              ref={scrollContainerRef}
              className="overflow-x-auto scrollbar-hide"
              style={{
                scrollSnapType: 'x mandatory',
                WebkitOverflowScrolling: 'touch',
                scrollbarWidth: 'none',
                msOverflowStyle: 'none'
              }}
            >
              <div className="flex space-x-8 px-4">
                {filteredCategories.map((category) => (
                  <div 
                    key={category.id} 
                    className="flex-none w-48 md:w-64 group cursor-pointer transform transition-all duration-300 hover:scale-105"
                    style={{ scrollSnapAlign: 'start' }}
                  >
                    <div className="aspect-square rounded-xl overflow-hidden bg-white shadow-lg hover:shadow-xl transition-all duration-300 mb-4 relative">
                      <img 
                        src={failedImages.has(category.id) ? fallbackImageSrc : category.image}
                        alt={category.name}
                        className="w-full h-full object-cover group-hover:scale-110 transition-transform duration-500"
                        onError={() => handleImageError(category.id)}
                      />
                      <div 
                        className="absolute inset-0" 
                        style={{
                          background: 'linear-gradient(to right, rgba(140, 80, 80, 0.2), rgba(138, 109, 81, 0.2), rgba(203, 138, 81, 0.2), rgba(140, 80, 80, 0.2), rgba(203, 109, 81, 0.2), rgba(140, 80, 80, 0.2), rgba(140, 80, 80, 0.2))'
                        }} 
                      />
                      <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                        <div className="absolute bottom-4 left-4 right-4 text-white">
                          <div className="text-sm font-medium">
                            {category.sub_category?.length || 6} subcategories
                          </div> 
                          {category.target_user_type === 'BOTH' && (
                            <div className="text-amber-200 text-sm mt-1">
                              Available for all users
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="text-center">
                      <h3 className="text-[#5e1a7d] group-hover:text-amber-600 transition-colors duration-300 font-medium">
                        {category.name}
                      </h3>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default CategoriesGrid;