import html2canvas from 'https://cdnjs.cloudflare.com/ajax/libs/html2canvas/1.4.1/html2canvas.esm.js';  
import React, { useState, useEffect, useRef } from 'react'; 
import { useParams, useLocation } from 'react-router-dom';
import { useAuth } from '../../Common/InitialGears/AuthContext';
import { getApi, buildUrl } from "../../Common/BackendCalls/apiUtils";
import { TopMenuBar } from '../../Buyers/DashbordComponents/TopMenuBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons'; 
import * as styles1 from '../../../GlobalStyles';  
import InvoiceProductDetails from './InvoiceProductDetails';

const InvoiceScreen = () => {
  const { jsPDF } = window.jspdf;
  const { invoiceId, groupWishId, comboId } = useParams();   
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const fromNotifications = searchParams.get('from') === 'notifications';
  const { queryLoginParam } = useAuth(); 
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const [invoiceData, setInvoiceData] = useState(null); 
  const invoiceRef = useRef(null);

  useEffect(() => { 
    document.title = 'Invoice Details';
    fetchInvoiceData(); 
  }, [invoiceId]);  

  const fetchInvoiceData = async () => { 
    try {
      let invoiceURL;
      if (comboId) { 
        invoiceURL = buildUrl(`invoice/${invoiceId}/combo_id/${comboId}`, queryLoginParam);
      } else if (groupWishId) { 
        invoiceURL = buildUrl(`invoice/${invoiceId}/groupWishId/${groupWishId}`, queryLoginParam); 
      } else { 
        invoiceURL = buildUrl(`invoice/${invoiceId}`, queryLoginParam);
      }
      const response = await getApi(invoiceURL);      
      
      if (response && response.ok && response.data) { 
        setInvoiceData(response.data);
      } else { 
        if (response?.status === 401 || response?.data?.error === 'token_expired') {
          setErrorMessage("Your session has expired. Please login again."); 
          setTimeout(() => {window.location.href = '/login';}, 3000);
        } else if (response?.status === 404) {
          setErrorMessage("Invoice not found. Please check the URL and try again.");
        } else if (response?.status === 500) {
          setErrorMessage("A server error occurred. Please try again later.");
        } else {
          setErrorMessage(response?.data?.error || "Failed to fetch invoice details");
        }
      }
    } catch (error) {
      console.error("Error fetching invoice:", error);
      if (error.message?.includes('NetworkError') || !navigator.onLine) {
        setErrorMessage("Please check your internet connection and try again.");
      } else {
        setErrorMessage("An unexpected error occurred. Please try again later.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    }).format(amount);
  };

  const generatePDF = async () => {
    try {
      const element = invoiceRef.current;
      const canvas = await html2canvas(element, {
        scale: 2,
        useCORS: true,
        logging: false,
        allowTaint: true,
        foreignObjectRendering: true
      });
      
      const pageWidth = 595;
      const pageHeight = 842; 
      const imgWidth = canvas.width;
      const imgHeight = canvas.height; 
      const margin = 40;
      const scaleFactor = (pageWidth - 2 * margin) / imgWidth;
      
      const pdf = new jsPDF('p', 'pt', 'a4');
      const xPosition = (pageWidth - imgWidth * scaleFactor) / 2;
      
      pdf.addImage(
        canvas.toDataURL('image/png'),
        'PNG',
        xPosition,
        margin,
        imgWidth * scaleFactor,
        imgHeight * scaleFactor
      ); 
      const invoiceNumber = invoiceData.invoice_number || 'invoice';
      pdf.save(`${invoiceNumber}.pdf`);
    } catch (error) {
      console.error('PDF generation error:', error);
    }
  };

  const handleOpenInvoice = () => { 
    generatePDF();
  }; 
  const isComboInvoice = invoiceData && (invoiceData.combo_title || invoiceData.combo_image);
  const getInvoiceTitle = () => {
    if (!invoiceData) return "Invoice";
    return isComboInvoice ? "Bundle Invoice" : "Invoice";
  };

  if (isLoading) {
    return (
      <div style={styles.loadingContainer}>
        <div className="spinner"></div>
      </div>
    );
  }
  
  if (errorMessage) {
    return (
      <div style={styles.mainContainer}> 
        <div style={styles.contentContainer}>
          <div style={styles.errorCard}>
            <div style={styles.errorIconContainer}>
              <svg style={styles.errorIcon} viewBox="0 0 24 24" fill="none" stroke="currentColor">
                <circle cx="12" cy="12" r="10" />
                <line x1="12" y1="8" x2="12" y2="12" />
                <line x1="12" y1="16" x2="12.01" y2="16" />
              </svg>
            </div>
            <h2 style={styles.errorTitle}>Error</h2>
            <p style={styles.errorMessage}>{errorMessage}</p>
            <button 
              onClick={() => window.location.reload()} 
              style={styles.retryButton}
            >
              Try Again
            </button>
          </div>
        </div>
      </div>
    );
  }

  // Calculate the subtotal + shipping + tax (before applying redemption credits)
  const calculatePreCreditsTotal = () => {
    if (!invoiceData) return 0;
    return invoiceData.subtotal + invoiceData.shipping_cost + invoiceData.tax_amount;
  };

  return (
    <div style={styles.mainContainer}>
      <TopMenuBar showHomeButton={true} invoiceBackToNotification={fromNotifications} title={getInvoiceTitle()} />
      {invoiceData && (
        <div ref={invoiceRef}>
          <div style={styles.contentContainer}>
            <div style={styles.invoiceCard}>
              <div style={styles.invoiceHeader}>
                <div>
                  <h1 style={styles.invoiceTitle}>
                    {isComboInvoice ? "Bundle " : ""}Invoice #{invoiceData.invoice_number}
                  </h1>
                  <div style={styles.dateText}>
                    Date: {new Date(invoiceData.invoice_date).toLocaleDateString()}
                  </div>                        
                </div>
                <button onClick={handleOpenInvoice} style={{...styles1.RoseGoldButton,...styles.downloadButton}}>
                  <FontAwesomeIcon icon={faDownload} style={styles.buttonIcon} />
                  Download Invoice
                </button>
              </div>
              {/* Product Details Section */}
              <div style={styles.sectionContainer}>
                <h3 style={styles.sectionTitle}>
                  {isComboInvoice ? "Bundle Details" : "Product Details"}
                </h3>
                <InvoiceProductDetails invoiceData={invoiceData} formatCurrency={formatCurrency}/>                    
              </div>
              {/* Delivery Address Section */}
              {invoiceData.delivery_address && (
                <div style={styles.sectionContainer}>
                  <h3 style={styles.sectionTitle}>Delivery Address</h3>
                  <div style={styles.addressCard}>
                    <div style={styles.addressLine}>
                      {invoiceData.delivery_address.address1}
                    </div>
                    {invoiceData.delivery_address.address2 && (
                      <div style={styles.addressLine}>
                        {invoiceData.delivery_address.address2}
                      </div>
                    )}
                    <div style={styles.addressLine}>
                      {invoiceData.delivery_address.city}, {invoiceData.delivery_address.state} {invoiceData.delivery_address.postal_code}
                    </div>
                    <div style={styles.addressLine}>
                      {invoiceData.delivery_address.country}
                    </div>
                  </div>
                </div>
              )}
              {/* Cost Breakdown Section */}
              <div style={styles.sectionContainer}>
                <h3 style={styles.sectionTitle}>Cost Breakdown</h3>
                <div style={styles.costBreakdown}>
                  <div style={styles.costRow}>
                    <span>Product Cost</span>
                    <span>{formatCurrency(invoiceData.subtotal)}</span>
                  </div>
                  <div style={styles.subcostRow}>
                    <span>(Including Additional Cost for Your Preferences</span>
                    <span>{formatCurrency(invoiceData.additional_charges)})</span>
                  </div>
                  <div style={styles.costRow}>
                    <span>Shipping Cost</span>
                    <span>{formatCurrency(invoiceData.shipping_cost)}</span>
                  </div>     
                  <div style={styles.costRow}>
                    <span>Tax Amount</span>
                    <span>{formatCurrency(invoiceData.tax_amount)}</span>
                  </div>
                  
                  {/* Add subtotal row before redemption credits */}
                  <div style={styles.subtotalRow}>
                    <span>Subtotal</span>
                    <span>{formatCurrency(calculatePreCreditsTotal())}</span>
                  </div>
                  
                  {/* Display redemption credits if available */}
                  {invoiceData.redemption_credits > 0 && (
                    <div style={styles.creditRow}>
                      <span>Redemption Credits Applied</span>
                      <span>-{formatCurrency(invoiceData.redemption_credits)}</span>
                    </div>
                  )}
                  
                  <div style={styles.totalRow}>
                    <span>Total Amount</span>
                    <span>{formatCurrency(invoiceData.total_amount)}</span>
                  </div>
                </div>
              </div>
              {/* Preference Items Section - Only show in non-combo or if not shown in product details */}
              {!isComboInvoice && invoiceData.invoice_item.preference_items && 
               invoiceData.invoice_item.preference_items.length > 0 && (
                <div style={styles.sectionContainer}>
                  <h3 style={styles.sectionTitle}>Your Preferences</h3>
                  <div style={styles.preferenceGrid}>
                    {[...Array(5)].map((_, index) => {
                      const itemPrefs = invoiceData.invoice_item.preference_items
                        .filter(pref => pref.item_index === index + 1);
                      
                      if (itemPrefs.length === 0) return null;
                      
                      return (
                        <div key={index} style={styles.preferenceItem}>
                          <h4 style={styles.itemTitle}>Item {index + 1}</h4>
                          {itemPrefs.map((pref, prefIndex) => (
                            <div key={prefIndex} style={styles.prefRow}>
                              <span style={styles.prefLabel}>{pref.preference_name}:</span>
                              <span style={styles.prefValue}>{pref.preference_value_name}</span>
                            </div>
                          ))}
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
              {/* Savings Section */}
              {invoiceData.savings > 0 && (
                <div style={styles.savingsContainer}>
                  <h3 style={styles.savingsTitle}>Savings</h3>
                  <div style={styles.savingsContent}>
                    <div style={styles.savingsRow}>
                      <span>Amount Saved</span>
                      <span>{formatCurrency(invoiceData.savings)}</span>
                    </div>
                    <div style={styles.savingsRow}>
                      <span>Savings Percentage</span>
                      <span>{invoiceData.savings_percentage.toFixed(2)}%</span>
                    </div>
                    {/* Add redemption credits information to savings section too */}
                    {invoiceData.redemption_credits > 0 && (
                      <div style={styles.savingsRow}>
                        <span>Additional Savings from Credits</span>
                        <span>{formatCurrency(invoiceData.redemption_credits)}</span>
                      </div>
                    )}
                  </div>
                </div>
              )}
              {/* Show redemption credits section if credits were applied but no other savings */}
              {invoiceData.redemption_credits > 0 && invoiceData.savings <= 0 && (
                <div style={styles.savingsContainer}>
                  <h3 style={styles.savingsTitle}>Credits Applied</h3>
                  <div style={styles.savingsContent}>
                    <div style={styles.savingsRow}>
                      <span>Redemption Credits Used</span>
                      <span>{formatCurrency(invoiceData.redemption_credits)}</span>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div> 
  );
};

const styles = {
  errorCard: {
    backgroundColor: '#FFFFFF',
    borderRadius: '12px',
    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.15)',
    padding: '40px',
    textAlign: 'center',
    maxWidth: '500px',
    margin: '40px auto',
  },
  errorIconContainer: {
    marginBottom: '20px',
  },
  errorIcon: {
    width: '64px',
    height: '64px',
    color: '#F44336',
    margin: '0 auto',
    strokeWidth: '1.5',
  },
  errorTitle: {
    fontSize: '24px',
    fontWeight: 'bold',
    color: '#333333',
    marginBottom: '16px',
  },
  errorMessage: {
    fontSize: '16px',
    color: '#666666',
    marginBottom: '24px',
    lineHeight: '1.5',
  }, 
  retryButton: {
    backgroundColor: '#4A90E2',
    color: '#FFFFFF',
    padding: '12px 24px',
    borderRadius: '8px',
    border: 'none',
    cursor: 'pointer',
    fontSize: '16px',
    fontWeight: '500',
    transition: 'background-color 0.3s',
  },
  mainContainer: {
    minHeight: '100vh',
    backgroundColor: '#F5F5F5',
    paddingBottom: '40px',
  },
  contentContainer: {
    maxWidth: '1000px',
    margin: '0 auto',
    padding: '20px',
    paddingTop: '150px',
  },
  loadingContainer: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
    zIndex: 998,
  },
  errorContainer: {
    padding: '20px',
    color: '#F44336',
    textAlign: 'center',
    fontSize: '16px',
  },
  invoiceCard: {
    backgroundColor: '#FFFFFF',
    borderRadius: '12px',
    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.15)',
    padding: '30px',
    marginBottom: '30px',
  },
  invoiceHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginBottom: '30px',
    paddingBottom: '20px',
    borderBottom: '1px solid #E0E0E0',
  },
  invoiceTitle: {
    fontSize: '24px',
    fontWeight: 'bold',
    color: styles1.Color.colorPeacockBlueDark,
    marginBottom: '8px',
  },
  dateText: {
    fontSize: '14px',
    color: styles1.Color.colorRoyalBlueText,
    marginBottom: '10px',
  },
  downloadButton: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: styles1.Color.colorPurple,
    color: styles1.Color.colorLightBeige,
    padding: '10px 20px',
    borderRadius: '8px',
    border: 'none',
    cursor: 'pointer',
    fontSize: '14px',
    fontWeight: '500',
    transition: 'background-color 0.3s',
  },
  buttonIcon: {
    marginRight: '8px',
    fontSize: '16px',
  },
  sectionContainer: {
    marginBottom: '30px',
    padding: '20px',
    backgroundColor: styles1.Color.colorLightBeige, 
    color: styles1.Color.colorDarkBlue,
    borderRadius: '8px',
  },
  sectionTitle: {
    fontSize: '18px',
    fontWeight: '600',
    backgroundColor: styles1.Color.colorLightBeige,
    color: styles1.Color.colorTeal,
    marginBottom: '20px',
  },
  productGrid: {
    display: 'grid',
    gridTemplateColumns: '3fr 1fr 1fr 1fr',
    gap: '24px',
    alignItems: 'start',
    width: '100%',
  },
  gridItem: {
    padding: '16px',
    backgroundColor: '#FFFFFF',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
  },
  labelText: {
    fontSize: '14px',
    color: '#666666',
    marginBottom: '4px', 
  },
  valueText: {
    fontSize: '16px',
    color: styles1.Color.colorRoyalBlueText,
    fontWeight: '500',
  },
  costBreakdown: {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
  },
  costRow: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '14px',
    color: styles1.Color.colorRoyalBlueText,
    padding: '8px 0',
  },
  subcostRow: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '13px',
    color: '#666666',
    fontStyle: 'italic',
    padding: '0 0 8px 20px',
  },
  // New styles for subtotal and credit rows
  subtotalRow: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '15px',
    fontWeight: '500',
    color: styles1.Color.colorRoyalBlueText,
    borderTop: '1px dashed #E0E0E0',
    paddingTop: '12px',
    paddingBottom: '8px',
  },
  creditRow: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '14px',
    color: styles1.Color.colorVibrantGreen, // Green color for credits
    fontWeight: '500',
    padding: '8px 0',
  },
  totalRow: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '16px',
    fontWeight: 'bold',
    color: styles1.Color.colorPurple,
    borderTop: '1px solid #E0E0E0',
    marginTop: '12px',
    paddingTop: '12px',
  },
  savingsContainer: {
    border: `1px solid ${styles1.Color.colorVibrantRed}`,
    borderRadius: '8px',
    padding: '20px',
    marginTop: '20px',
  },
  savingsTitle: {
    fontSize: '18px',
    fontWeight: '600',
    color: styles1.Color.colorPeacockBlueDark,
    marginBottom: '16px',
  },
  savingsContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
  },
  savingsRow: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '14px',
    color: styles1.Color.colorPurple,
  },
  linkContainer: {
    marginTop: '10px',
  },
  invoiceLinkButton: {
    display: 'inline-flex',
    alignItems: 'center',
    background: 'none',
    border: '1px solid #4A90E2',
    cursor: 'pointer',
    padding: '8px 16px',
    borderRadius: '8px',
    transition: 'all 0.3s ease',
    color: '#4A90E2',
    backgroundColor: styles1.Color.colorLightBeige,
    textDecoration: 'none',
    fontWeight: '500',
    fontSize: '14px',
  },
  invoiceIcon: {
    fontSize: '16px',
    marginRight: '8px',
    color: styles1.Color.colorDarkGrey,
  },
  preferenceGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
    gap: '20px',
  },
  preferenceItem: {
    backgroundColor: '#FFFFFF',
    padding: '16px',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
  },
  itemTitle: {
    fontSize: '16px',
    fontWeight: '600',
    color: styles1.Color.colorTeal,
    marginBottom: '12px',
  },
  prefRow: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '8px',
    fontSize: '14px',
  },
  prefLabel: {
    color: '#666666',
    fontWeight: '500',
  },
  prefValue: {
    color: styles1.Color.colorRoyalBlueText,
  },
  addressCard: {
    backgroundColor: '#FFFFFF',
    padding: '16px',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
  },
  addressLine: {
    fontSize: '14px',
    color: styles1.Color.colorRoyalBlueText,
    marginBottom: '6px',
  }
};

export default InvoiceScreen;