import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faNoteSticky, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from '../../Common/InitialGears/AuthContext';
import * as style from "../../../GlobalStyles";
import { getApi, postApi, buildUrl } from "../../Common/BackendCalls/apiUtils";
 

const DefinePreferences = ({ 
  isOpen, 
  onClose, 
  groupId, 
  existingPreferences = [], 
  onSubmit 
}) => {
  const { queryLoginParam } = useAuth();
  const [standardPreferences, setStandardPreferences] = useState([]);
  const [selectedPreferences, setSelectedPreferences] = useState([]);
  const [selectedStandardPreference, setSelectedStandardPreference] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [expandedNotes, setExpandedNotes] = useState({});

  useEffect(() => {
    if (isOpen) {
      fetchStandardPreferences();
    }
  }, [isOpen, groupId]);

  
  const fetchStandardPreferences = async () => {
    setIsLoading(true);
    try {
      const standardPreferencesUrl = buildUrl(`getGroupsExistingPreferences/${groupId}/`, queryLoginParam);
      const response = await getApi(standardPreferencesUrl);
      
      if (response?.data?.standard_preferences) { 
        const formattedPreferences = response.data.standard_preferences.map(pref => ({
          ...pref,
          isMultiple: pref.isMultiple ?? false,
          isStandard: true
        }));
        setStandardPreferences(formattedPreferences);
 
        if (response.data.preferences_defined?.length > 0) {
          const formattedExisting = response.data.preferences_defined.map(pref => ({
            ...pref,
            values: pref.values.map(v => ({
              ...v,
              valueIncluded: true,
              hasAdditionalCharges: v.hasAdditionalCharges || false,
              additionalChargesAmount: v.additionalChargesAmount || ''
            })),
            isMultiple: pref.isMultiple ?? false,
            isStandard: true
          }));
          const initialExpandedState = formattedExisting.reduce((acc, pref, index) => {
            acc[index] = pref.notes && pref.notes.trim().length > 0;
            return acc;
          }, {});
          setSelectedPreferences(formattedExisting);
          setExpandedNotes(initialExpandedState);
        }
      }
    } catch (err) {
      setError(err.message || 'Failed to fetch preferences');
    } finally {
      setIsLoading(false);
    }
  };

  const handleStandardPreferenceSelect = (e) => {
    const selectedValue = e.target.value;
    setSelectedStandardPreference(selectedValue);
    
    if (selectedValue) {
      const preference = JSON.parse(selectedValue);
      const formattedPreference = {
        ...preference,
        notes: '',
        values: preference.values.map(v => ({
          ...v,
          valueIncluded: false,
          hasAdditionalCharges: false,
          additionalChargesAmount: ''
        }))
      };
      setSelectedPreferences(prev => [...prev, formattedPreference]);
    }
  };

  const handleUpdatePreferenceValue = (prefIndex, valueIndex, field, value) => {
    setSelectedPreferences(prev => {
      const updated = [...prev];
      
      if (field === 'isMultiple') {
        // Only update the isMultiple flag without affecting selected values
        updated[prefIndex] = {
          ...updated[prefIndex],
          isMultiple: value
        };
        return updated;
      }
  
      // For all other updates, handle normally without any single/multiple restrictions
      const updatedValues = [...updated[prefIndex].values];
      
      if (field === 'valueIncluded') { 
        updatedValues[valueIndex] = {
          ...updatedValues[valueIndex],
          valueIncluded: value
        };
      } else if (field === 'hasAdditionalCharges' && !value) {
        updatedValues[valueIndex] = {
          ...updatedValues[valueIndex],
          [field]: value,
          additionalChargesAmount: ''
        };
      } else {
        updatedValues[valueIndex] = {
          ...updatedValues[valueIndex],
          [field]: value
        };
      }
      
      updated[prefIndex] = { ...updated[prefIndex], values: updatedValues };
      return updated;
    });
  };

  const toggleNotes = (prefIndex) => {
    setExpandedNotes(prev => ({
      ...prev,
      [prefIndex]: !prev[prefIndex]
    }));
  };

  const handleNotesChange = (prefIndex, event) => {
    const value = event.target.value;
    
    setSelectedPreferences(prev => {
      const updated = [...prev];
      if (!updated[prefIndex]) return prev;
      updated[prefIndex] = {
        ...updated[prefIndex],
        notes: value
      };
      return updated;
    });
  };

  const handleRemovePreference = (index) => {
    setSelectedPreferences(prev => prev.filter((_, i) => i !== index));
  };

  const handleSave = async () => {
    try {
      const formattedPreferences = selectedPreferences.map(pref => { 
        const selectedValues = pref.values.filter(v => v.valueIncluded);
        if (selectedValues.length === 0) return null;
 
        const formattedPref = {
          name: pref.name,
          notes: pref.notes || '',
          values: selectedValues.map(v => { 
            let valueObj = {
              value: v.value,
              hasAdditionalCharges: Boolean(v.hasAdditionalCharges)
            };
             
            if (valueObj.hasAdditionalCharges) {
              valueObj = {
                ...valueObj,
                additionalChargesAmount: String(v.additionalChargesAmount || '0')
              };
            }
            
            return valueObj;
          }),
          is_multiple: Boolean(pref.isMultiple),
          is_product_specific: Boolean(pref.is_product_specific)
        };

        return formattedPref;
      }).filter(pref => pref !== null && pref.values.length > 0);

      const setPreferencesUrl = buildUrl('setGroupPreferences', queryLoginParam);
      const response = await postApi(setPreferencesUrl, {
        groupId: groupId,
        preferences: formattedPreferences
      });

      if (response?.data) {
        onSubmit(response.data.message);
        onClose();
      }
    } catch (err) {
      setError(err.message || 'Failed to save preferences');
    }
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="modal-header">
          <h1 className="modal-title">Define Group Preferences</h1>
          <button onClick={onClose} className="close-button">
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>

        {isLoading ? (
          <div className="loading">Loading...</div>
        ) : error ? (
          <div className="error">{error}</div>
        ) : (
          <>
            <div className="preferences-container">
              <h3>Standard Preferences</h3>
              {standardPreferences.length > 0 ? (
                <select onChange={handleStandardPreferenceSelect} value={selectedStandardPreference}>
                  <option value="">Select a standard preference</option>
                  {standardPreferences.map((pref, index) => (
                    <option key={index} value={JSON.stringify(pref)}>
                      {pref.name}
                    </option>
                  ))}
                </select>
              ) : (
                <p>No standard preferences available.</p>
              )}
            </div>
            <div className="preferences-container">
              <h2>Existing Preferences</h2>
              {selectedPreferences.map((pref, prefIndex) => (
                <div key={prefIndex} className="selected-preference">
                  <div className="preference-header">
                    <div className="preference-name-container">
                      <span className="preference-name">{pref.name}</span>
                      <div className="select-type-container">
                        <label className="radio-label">
                          <input
                            type="radio"
                            name={`selectType-${prefIndex}`}
                            value="single"
                            checked={!pref.isMultiple}
                            onChange={() => handleUpdatePreferenceValue(prefIndex, null, 'isMultiple', false)}
                          />
                          <span>Single Select</span>
                        </label>
                        <label className="radio-label">
                          <input
                            type="radio"
                            name={`selectType-${prefIndex}`}
                            value="multiple"
                            checked={pref.isMultiple}
                            onChange={() => handleUpdatePreferenceValue(prefIndex, null, 'isMultiple', true)}
                          />
                          <span>Multiple Select</span>
                        </label>
                      </div>
                    </div>
                    <button onClick={() => toggleNotes(prefIndex)} className={`notes-button ${pref.notes ? 'has-notes' : ''}`} title={expandedNotes[prefIndex] ? "Hide notes" : "Show notes"}>
                      <FontAwesomeIcon icon={faNoteSticky} style={{ color: pref.notes ? style.Color.colorPurple : style.Color.colorGolden }} />
                    </button>
                    <button onClick={() => handleRemovePreference(prefIndex)} className="remove-button">
                      <FontAwesomeIcon icon={faTrash} />
                    </button>
                  </div>
                  {expandedNotes[prefIndex] && (
                    <div className="notes-container">
                      <textarea value={pref.notes || ''} onChange={(e) => handleNotesChange(prefIndex, e)}  placeholder="Add notes for this preference..."  className="preference-notes"  rows={3}  />
                    </div>
                  )}
                  <div className="preference-values">
                    {pref.values.map((valueObj, valueIndex) => (
                      <div key={valueObj.uuid || valueIndex} className="preference-value-row">
                        <div className="preference-value-left">
                          <label className="checkbox-label">
                            <input type="checkbox" checked={valueObj.valueIncluded} onChange={(e) => handleUpdatePreferenceValue(prefIndex, valueIndex, 'valueIncluded', e.target.checked)} />
                            <span className="value-label">{valueObj.value}</span>
                          </label>
                        </div>
                        <div className="value-charges">
                          <label className="checkbox-label">
                            <input type="checkbox" checked={valueObj.hasAdditionalCharges} onChange={(e) => handleUpdatePreferenceValue(prefIndex, valueIndex, 'hasAdditionalCharges', e.target.checked)} />
                            Additional Charges
                          </label>
                          {valueObj.hasAdditionalCharges && (
                            <input type="number" step="0.01" value={valueObj.additionalChargesAmount} onChange={(e) => handleUpdatePreferenceValue(prefIndex, valueIndex, 'additionalChargesAmount', e.target.value)} placeholder="Amount" className="charge-amount-input" />
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
              <button onClick={handleSave} className="submit-button" style={style.RoseGoldButton}>
                Save Group Preferences
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
 
const styles = `
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.modal-content {
  background-color: ${style.Color.colorLightBeige};
  border-radius: 8px;
  padding: 20px;
  width: 90%;
  max-width: 600px;
  max-height: 90vh;
  overflow-y: auto;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid ${style.Color.colorLightBeige};
}

.modal-title {
  font-size: 24px;
  color: ${style.Color.colorRoyalBlueText};
  margin: 0;
}

.close-button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: ${style.Color.colorDarkGrey};
}

.preferences-container {
  color: ${style.Color.colorDarkGrey};
  background-color: ${style.Color.colorLightestBeige};
}

.preferences-container h2 {
  margin-top: 20px;
  color: ${style.Color.colorPurple};
}

.preferences-container h3 {
  margin-top: 20px;
  color: ${style.Color.colorRoyalBlueText};
}

.preference-section {
  display: flex;
  align-items: center;
  gap: 10px;
  flex: 1;
}

.preference-header {
  display: flex;
  align-items: flex-start;
  gap: 15px;
  margin-bottom: 10px;
}

.preference-name-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.select-type-container {
  display: flex;
  gap: 20px;
  margin-top: 8px;
  padding: 8px 12px;
  background-color: ${style.Color.colorLightestBeige};
  border-radius: 6px;
  border: 1px solid ${style.Color.colorLightBeige};
}

.radio-label {
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${style.Color.colorDarkGrey};
  font-size: 14px;
  padding: 4px 8px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.radio-label:hover {
  background-color: ${style.Color.colorLightBeige};
}

.radio-label input[type="radio"] {
  width: 16px;
  height: 16px;
  margin: 0;
  cursor: pointer;
  accent-color: ${style.Color.colorPurple};
  border: 2px solid ${style.Color.colorPurple};
}

.radio-label input[type="radio"]:checked + span {
  color: ${style.Color.colorPurple};
  font-weight: 500;
}

.value-label {
  font-weight: 500;
}

.charge-badge {
  background-color: ${style.Color.colorLimeGreen};
  color: white;
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 0.9em;
  margin-left: 8px;
}

.charge-amount-input.disabled {
  background-color: ${style.Color.colorLightBeige};
  cursor: not-allowed;
  color: ${style.Color.colorDarkGrey};
}

.preference-value {
  display: flex;
  align-items: center;
  padding: 4px 8px;
  background-color: ${style.Color.colorWhite};
  border-radius: 3px;
  min-width: 150px;
}

.value-charges {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-left: auto;
}

.preferences-container select,
.preferences-container input[type="text"],
.preferences-container input[type="number"] {
  padding: 8px;
  border: 1px solid ${style.Color.colorLightBeige};
  border-radius: 8px;
  color: ${style.Color.colorDarkGrey};
}

.selected-preference {
  padding: 15px;
  background-color: ${style.Color.colorLightBeige};
  margin-bottom: 15px;
  border-radius: 4px;
}

.preference-value-container {
  margin-bottom: 10px;
}

.preference-value-input {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 5px;
  width: 100%;
}

.preference-value-input input[type="text"] {
  flex: 2;
}

.charge-amount-input {
  width: 120px !important;
  min-width: 80px;
}

.include-checkbox-label {
  display: flex;
  align-items: left;
  gap: 3px;
  white-space: nowrap;
  user-select: none; 
} 

.custom-checkbox {
  -webkit-appearance: none;
  appearance: none;
  width: 18px;
  height: 18px;
  border: 2px solid ${style.Color.colorPurple};
  border-radius: 4px;
  margin-right: 8px;
  cursor: pointer;
  position: relative;
  background: white;
}

.custom-checkbox:checked {
  background-color: ${style.Color.colorPurple};
}

.custom-checkbox:checked::before {
  content: '';
  position: absolute;
  left: 4px;
  top: 1px;
  width: 6px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.checkbox-label {
  display: flex;
  align-items: center;
  gap: 5px;
  white-space: nowrap;
  user-select: none;
}

.cust-preference-name-input {
  flex: 1;
  font-size: 12px;
  font-weight: bold;
  background-color: ${style.Color.colorWhite};
}

.notes-button {
  background: none;
  border: none;
  cursor: pointer;
  color: ${style.Color.colorGolden};
  padding: 4px 8px;
  margin-left: auto;
}

.notes-button:hover {
  color: ${style.Color.colorRoyalBlueText};
}

.notes-container {
  margin: 10px 0;
  padding: 10px;
  background-color: ${style.Color.colorLightestBeige};
  border-radius: 4px;
}

.preference-notes {
  width: 100%;
  padding: 8px;
  border: 1px solid ${style.Color.colorLightBeige};
  border-radius: 4px;
  resize: vertical;
  font-family: inherit;
  background-color: ${style.Color.colorWhite};
  min-height: 60px;
  outline: none;
}

.preference-notes:focus {
  border-color: ${style.Color.colorPurple};
  box-shadow: 0 0 0 2px rgba(128, 0, 128, 0.1);
}

.preference-values {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
}

.preference-value-row {
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 8px;
  background-color: ${style.Color.colorLightestBeige};
  border-radius: 4px;
}

.remove-button,
.add-value-button,
.add-preference-button {
  background-color: ${style.Color.colorLimeGreen};
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  display: inline-flex;
  align-items: center;
  gap: 5px;
}

.remove-button {
  background-color: ${style.Color.colorRed};
  padding: 4px 8px;
  margin-left: auto;
}

.add-value-button,
.add-preference-button {
  margin: 10px 5px;
}

.submit-button {
  background-color: ${style.Color.colorPurple};
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  width: 50%;
  margin: 20px auto;
  display: block;
}

.loading,
.error {
  text-align: center;
  padding: 20px;
  font-size: 18px;
}

.error {
  color: ${style.Color.colorRed};
}
`;

const styles1 = `
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.modal-content {
  background-color: ${style.Color.colorLightBeige};
  border-radius: 8px;
  padding: 20px;
  width: 90%;
  max-width: 600px;
  max-height: 90vh;
  overflow-y: auto;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid ${style.Color.colorLightBeige};
}

.modal-title {
  font-size: 24px;
  color: ${style.Color.colorRoyalBlueText};
  margin: 0;
}

.close-button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: ${style.Color.colorDarkGrey};
}

.preferences-container {
  color: ${style.Color.colorDarkGrey};
  background-color: ${style.Color.colorLightestBeige};
}

.preferences-container h2 {
  margin-top: 20px;
  color: ${style.Color.colorPurple};
}

.preferences-container h3 {
  margin-top: 20px;
  color: ${style.Color.colorRoyalBlueText};
}

.preference-section {
  display: flex;
  align-items: center;
  gap: 10px;
  flex: 1;
}

.value-label {
  font-weight: 500;
}

.charge-badge {
  background-color: ${style.Color.colorLimeGreen};
  color: white;
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 0.9em;
  margin-left: 8px;
}

.charge-amount-input.disabled {
  background-color: ${style.Color.colorLightBeige};
  cursor: not-allowed;
  color: ${style.Color.colorDarkGrey};
}

.preference-value {
  display: flex;
  align-items: center;
  padding: 4px 8px;
  background-color: ${style.Color.colorWhite};
  border-radius: 3px;
  min-width: 150px;
}

.value-charges {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-left: auto;
}
.preferences-container select,
.preferences-container input[type="text"],
.preferences-container input[type="number"] {
  padding: 8px;
  border: 1px solid ${style.Color.colorLightBeige};
  border-radius: 8px;
  color: ${style.Color.colorDarkGrey};
}

.selected-preference {
  padding: 15px;
  background-color: ${style.Color.colorLightBeige};
  margin-bottom: 15px;
  border-radius: 4px;
}

.preference-header {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 10px;
}

.preference-value-container {
  margin-bottom: 10px;
}

.preference-value-input {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 5px;
  width: 100%;
}

.preference-value-input input[type="text"] {
  flex: 2;
}

.charge-amount-input {
  width: 120px !important;
  min-width: 80px;
}

.include-checkbox-label {
  display: flex;
  align-items: left;
  gap: 3px;
  white-space: nowrap;
  user-select: none; 
} 

.custom-checkbox {
    -webkit-appearance: none;
    appearance: none;
    width: 18px;
    height: 18px;
    border: 2px solid ${style.Color.colorPurple};
    border-radius: 4px;
    margin-right: 8px;
    cursor: pointer;
    position: relative;
    background: white;
  }

  .custom-checkbox:checked {
    background-color: ${style.Color.colorPurple};
  }

  .custom-checkbox:checked::before {
    content: '';
    position: absolute;
    left: 4px;
    top: 1px;
    width: 6px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
.checkbox-label {
  display: flex;
  align-items: center;
  gap: 5px;
  white-space: nowrap;
  user-select: none;
}

.cust-preference-name-input {
  flex: 1;
  font-size: 12px;
  font-weight: bold;
  background-color: ${style.Color.colorWhite};
}


.notes-button {
  background: none;
  border: none;
  cursor: pointer;
  color: ${style.Color.colorGolden};
  padding: 4px 8px;
  margin-left: auto;
}

.notes-button:hover {
  color: ${style.Color.colorRoyalBlueText};
}

.notes-container {
  margin: 10px 0;
  padding: 10px;
  background-color: ${style.Color.colorLightestBeige};
  border-radius: 4px;
}

.preference-notes {
  width: 100%;
  padding: 8px;
  border: 1px solid ${style.Color.colorLightBeige};
  border-radius: 4px;
  resize: vertical;
  font-family: inherit;
  background-color: ${style.Color.colorWhite};
  min-height: 60px;
  outline: none;
}
.preference-notes:focus {
  border-color: ${style.Color.colorPurple};
  box-shadow: 0 0 0 2px rgba(128, 0, 128, 0.1);
}
.preference-values {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
}

.preference-value-row {
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 8px;
  background-color: ${style.Color.colorLightestBeige};
  border-radius: 4px;
}

.preference-value {
  flex: 1;
  padding: 4px 8px;
  background-color: ${style.Color.colorWhite};
  border-radius: 3px;
}

.value-charges {
  display: flex;
  align-items: center;
  gap: 10px;
}

.remove-button,
.add-value-button,
.add-preference-button {
  background-color: ${style.Color.colorLimeGreen};
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  display: inline-flex;
  align-items: center;
  gap: 5px;
}

.remove-button {
  background-color: ${style.Color.colorRed};
  padding: 4px 8px;
  margin-left: auto;
}

.add-value-button,
.add-preference-button {
  margin: 10px 5px;
}

.submit-button {
  background-color: ${style.Color.colorPurple};
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  width: 50%;
  margin: 20px auto;
  display: block;
}

.loading,
.error {
  text-align: center;
  padding: 20px;
  font-size: 18px;
}

.error {
  color: ${style.Color.colorRed};
}
`;

// Add styles to the document
const styleSheet = document.createElement("style");
styleSheet.type = "text/css";
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);

export default DefinePreferences;