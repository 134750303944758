import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from '../../Common/InitialGears/AuthContext';
import {Color, RoseGoldButton} from "../../../GlobalStyles";
import { getApi, postApi, buildUrl } from "../../Common/BackendCalls/apiUtils";
import UserPreferenceGroup from './UserPreferenceGroup'; 

const PreferencesScreen = ({ isOpen, onClose, grp_wish_id, groupWishName, existingPreferences, quantity, combo, landBack }) => {   
  const { queryLoginParam } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { formData, draftWish } = location.state || {};
  const [preferences, setPreferences] = useState([]);
  const [preferenceGroups, setPreferenceGroups] = useState([]);
  const [customPreferences, setCustomPreferences] = useState([]);
  const [customPreference, setCustomPreference] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showAddCustom, setShowAddCustom] = useState(true); 
  const [groupedPreferences, setGroupedPreferences] = useState({});

  useEffect(() => {
    fetchPreferences();
  }, []);
 
const fetchPreferences = useCallback(async () => {
  try {
    setIsLoading(true);
    let prefernceListUrl = combo
      ? buildUrl(`getCombosPreferencesToJoin/${grp_wish_id}`, queryLoginParam)
      : buildUrl(`getGroupsPreferencesToJoin/${grp_wish_id}/`, queryLoginParam);
    const response = await getApi(prefernceListUrl);
    if (response.ok) {
      if (response.data.groupWishesPreferences) { 
        const groupedData = {};
        response.data.groupWishesPreferences.forEach(groupWish => {
          if (groupWish.preferences && groupWish.preferences.length > 0) {
            groupedData[groupWish.groupWishName] = {
              groupWishId: groupWish.groupWishId,
              preferences: groupWish.preferences.map(pref => ({
                ...pref,
                values: pref.values || [],
                selectedValue: pref.selectedValue || null
              }))
            };
          }
        }); 
        if (Object.keys(groupedData).length === 0) { 
          handleAutoSubmit();
          return;
        }        
        setGroupedPreferences(groupedData);         
        const allPreferences = Object.values(groupedData).flatMap(group => 
          group.preferences.map(pref => ({
            ...pref,
            groupWishId: group.groupWishId,
            groupWishName: group.groupWishName
          }))
        );
        setPreferences(allPreferences);
      } else if (response.data.preferences && Array.isArray(response.data.preferences)) { 
        if (response.data.preferences.length === 0) {  
          handleAutoSubmit();
          return;
        }        
        const formattedPreferences = response.data.preferences.map(pref => ({
          ...pref,
          values: pref.values || [],
          selectedValue: pref.selectedValue || null
        }));
        setPreferences(formattedPreferences);
        setGroupedPreferences({
          [groupWishName]: {
            groupWishId: grp_wish_id,
            preferences: formattedPreferences
          }
        });
      } else { 
        handleAutoSubmit();
        return;
      }
    } else {        
      if (response.status === 423) {
        setError('Your session has expired. Please log in again.');
        navigate("/");
      }
    }
  } catch (error) {
    setError('Failed to fetch preferences. Please try again.');
  } finally {
    setIsLoading(false);
  }
}, [grp_wish_id, queryLoginParam, navigate, combo, groupWishName]);
 
const handleAutoSubmit = async () => {
  try { 
    const emptyPreferences = [];    
    if (combo) {
      const grpWishComboURL = existingPreferences === "new" 
        ? buildUrl('addUsersComboPreferences', queryLoginParam)
        : buildUrl('updateUsersComboPreferences', queryLoginParam);        
      const requestBody = {
        preferences: emptyPreferences,
        combo_id: grp_wish_id || formData,
        id: draftWish?.id
      };      
      const response = await postApi(grpWishComboURL, requestBody);      
      if (response.ok) {
        onClose(true);
        if (landBack==="Dashboard"){
        }else{
          navigate('/comboProgress', {
            state: {
              comboId: grp_wish_id,
              successMessage: existingPreferences === "new" ?
                'Successfully joined!' :
                'Successfully updated!'
            },
            replace: true
          });
        }
      } else {
        throw new Error(response.data?.message || 'Failed to join');
      }
    } else {
      const grpWishURL = existingPreferences === "new"
        ? buildUrl('addUsersPreferences', queryLoginParam)
        : buildUrl('updateUsersPreference', queryLoginParam);
  
      const requestBody = {
        preferences: emptyPreferences,
        grp_wish_id: grp_wish_id || formData,
        id: draftWish?.id
      };      
      const response = await postApi(grpWishURL, requestBody);      
      if (response.ok) {
        onClose(true);
        if (landBack==="Dashboard"){
        }else{
          navigate('/groupWishProgress', {
            state: {
              wish: {
                id: grp_wish_id,
                product_name: groupWishName,
                ...response.data,
                preferences: response.data.preferences
              },
              progressData: {
                id: grp_wish_id,
                product_name: groupWishName,
                group_wish_buddy_status: 'JOINED',
                totalJoined: response.data.totalJoined,
                verifiedUsers: response.data.verifiedUsers,
                unverifiedUsers: response.data.unverifiedUsers,
                upvotes: response.data.upvotes, 
                averageRating: response.data.averageRating,
                progress: response.data.progress,
                is_favorite: response.data.is_favorite,
                user_vote: response.data.user_vote,
                preferences: response.data.preferences
              },
              joined: true,
              buddyStatus: 'JOINED',
              showJoinButton: false,
              successMessage: existingPreferences === "new" ?
                'Successfully joined!' :
                'Successfully updated!'
            },
            replace: true
          });
        }}
    }
  } catch (error) { 
    setError(error.message || 'Failed to join automatically. Please try manually.');
    setIsLoading(false);
  }
};
  useEffect(() => {
    if (Object.keys(groupedPreferences).length > 0) {
      initializePreferenceGroups();
    }
  }, [groupedPreferences, quantity]);

  const initializePreferenceGroups = () => {
    const groupsData = {};
    Object.entries(groupedPreferences).forEach(([groupName, groupData]) => {
      groupsData[groupName] = {
        quantity: quantity,
        groupWishId: groupData.groupWishId,
        preferences: groupData.preferences.reduce((acc, pref) => {
          if (pref.selectedValue || pref.required) {
            acc[pref.uuid] = {
              selectedValue: pref.selectedValue || null,
              additionalValues: pref.additionalValues || []
            };
          }
          return acc;
        }, {})
      };
    });
    setPreferenceGroups(groupsData);
  };

  const formatPreferencesForSubmission = (groups) => {
    const itemSets = [];
    let itemIndex = 1;  
    
    Object.entries(groups).forEach(([groupName, group]) => {
      if (!group || !group.preferences) return;
  
      const originalGroupName = getOriginalGroupName(groupName);
      const groupData = groupedPreferences[originalGroupName];
      if (!groupData || !groupData.preferences) return;
  
      Object.entries(group.preferences).forEach(([preferenceUuid, selection]) => {
        const preference = groupData.preferences.find(p => p.uuid === preferenceUuid);
        if (!preference || !selection.selectedValue) return; 
        if (selection.selectedValue) {
          itemSets.push({
            preference_name: preference.name,
            preference_value: selection.selectedValue.value,
            quantity: group.quantity || 1,
            group_wish_id: group.groupWishId,
            item_index: itemIndex,
            rank: 1  
          });
        } 
        if (selection.additionalValues && selection.additionalValues.length > 0) {
          selection.additionalValues.forEach((value, idx) => {
            itemSets.push({
              preference_name: preference.name,
              preference_value: value.value,
              quantity: group.quantity || 1,
              group_wish_id: group.groupWishId,
              item_index: itemIndex,
              rank: idx + 2  
            });
          });
        }
        itemIndex++;  
      });
    });
  
    return itemSets;
  };
  
  const handleSubmit = async () => {
    if (showAddCustom && customPreference.trim()) {
      handleAddCustomPreference();
      return;
    }  
    setIsSubmitting(true);  
    const itemSets = formatPreferencesForSubmission(preferenceGroups); 
    const customPrefs = customPreferences.map((cp, index) => ({
        preference_name: 'Custom',
        preference_value: cp.value,
        quantity: quantity || 1,
        group_wish_id: grp_wish_id,
        item_index: itemSets.length + index + 1,  
        rank: 1  
      }));  
      const allPreferences = [...itemSets, ...customPrefs];
      if (allPreferences.length === 0 && combo) {
        const confirmed = window.confirm("You haven't selected any preferences. Do you want to proceed anyway?");
        if (!confirmed) {
          setIsSubmitting(false);
          return;
        }
      }
      if (combo){
        try {    
          const grpWishComboURL = existingPreferences === "new" ? buildUrl('addUsersComboPreferences', queryLoginParam): buildUrl('updateUsersComboPreferences', queryLoginParam);      
          const requestBody = {
            preferences: allPreferences,
            combo_id: grp_wish_id || formData,
            id: draftWish?.id
          }; 
          const response = await postApi(grpWishComboURL, requestBody);          
          if (response.ok) { 
            onClose(true);
            const comboDetailsData = {
              ...response.data.combo, 
              id: response.data.id || response.data.combo.id,
              title: response.data.title || response.data.combo.title,
              group_wish_buddy_status: response.data.group_wish_buddy_status || 'JOINED',
              userPreferences: response.data.combo.userPreferences || [], 
              combo_items: response.data.combo.items ? response.data.combo.items.map(item => ({
                id: item.id,
                group_wish: {
                  id: item.group_wish_id,
                  product_name: item.title,
                  list_price: item.price,
                  image_url: item.image
                }
              })) : [], 
              progress_stats: {
                verifiedUsers: 0,
                unverifiedUsers: 0,
                totalJoined: 0,
                verification_score: 99,
                upvotes: 16,
                progress: 0,
                averageRating: 0,
                rating_counts: [],
                user_already_joined: true
              }
            };
            if (landBack==="Dashboard"){
            }else{
              navigate('/comboProgress', {
                state: {
                  comboId: grp_wish_id, 
                  successMessage: existingPreferences === "new" ?
                    'Successfully added preferences!' :
                    'Successfully updated preferences!'
                },
                replace: true
              });
            }
          }  
      } catch (error) { 
        setError(error.message || 'An error occurred while updating preferences');
        setTimeout(() => setError(null), 5000);
      } finally {
        setIsSubmitting(false);
      }
      }
      else{  
        try {    
          const grpWishURL = existingPreferences === "new"
            ? buildUrl('addUsersPreferences', queryLoginParam)
            : buildUrl('updateUsersPreference', queryLoginParam);
      
          const requestBody = {
            preferences: allPreferences,
            grp_wish_id: grp_wish_id || formData,
            id: draftWish?.id
          }; 
          const response = await postApi(grpWishURL, requestBody);
          
          if (response.ok) {
            onClose(true);
            if (landBack==="Dashboard"){
            }else{
              navigate('/groupWishProgress', {
                state: {
                  wish: {
                    id: grp_wish_id,
                    product_name: groupWishName,
                    ...response.data,
                    preferences: response.data.preferences
                  },
                  progressData: {
                    id: grp_wish_id,
                    product_name: groupWishName,
                    group_wish_buddy_status: 'JOINED',
                    totalJoined: response.data.totalJoined,
                    verifiedUsers: response.data.verifiedUsers,
                    unverifiedUsers: response.data.unverifiedUsers,
                    upvotes: response.data.upvotes, 
                    averageRating: response.data.averageRating,
                    progress: response.data.progress,
                    is_favorite: response.data.is_favorite,
                    user_vote: response.data.user_vote,
                    preferences: response.data.preferences
                  },
                  joined: true,
                  buddyStatus: 'JOINED',
                  showJoinButton: false,
                  successMessage: existingPreferences === "new" ?
                    'Successfully added preferences!' :
                    'Successfully updated preferences!'
                },
                replace: true
              });
            }
          } else {
            //throw new Error(response.data?.message || 'Failed to update preferences');
          }
      } catch (error) { 
        setError(error.message || 'An error occurred while updating preferences');
        setTimeout(() => setError(null), 5000);
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  const handleCustomPreferenceChange = (event) => {
    setCustomPreference(event.target.value);
  };

  const handleAddCustomPreference = () => {
    if (customPreference.trim() && customPreferences.length < 1) {
      setCustomPreferences([...customPreferences, { value: customPreference.trim() }]);
      setCustomPreference('');
      setShowAddCustom(false); 
    }
  };

  const handleDeleteCustomPreference = async (index) => {
    const isConfirmed = window.confirm("Are you sure you want to delete this custom preference?");
    
    if (isConfirmed) {
      setIsLoading(true);
      try {
        const preferenceToDelete = customPreferences[index];
        const deleteUrl = buildUrl('deleteUsersPreference', queryLoginParam);
        const body = {
          grp_wish_id: grp_wish_id,
          preference_value: preferenceToDelete.value
        };
        const response = await postApi(deleteUrl, body);

        if (response.ok) {
          setCustomPreferences(customPreferences.filter((_, i) => i !== index));
          alert("Custom preference deleted successfully.");
        } else {
          throw new Error('Failed to delete custom preference');
        }
      } catch (error) { 
        alert("Failed to delete custom preference. Please try again.");
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleClearCustomPreference = (index) => {
    setCustomPreferences(customPreferences.filter((_, i) => i !== index));
    setShowAddCustom(true); 
  };
   
  if (!isOpen) return null;

  const handleClose = () => {
    onClose(true);
  }; 
  const handleSplitGroup = (originalGroupName, groupId) => { 
    setPreferenceGroups(prevGroups => {
      const newGroups = { ...prevGroups };
      const group = newGroups[groupId]; 
      if (!group || group.quantity <= 1) return newGroups; 
      const splitGroupId = `${originalGroupName}-split-${Date.now()}`; 
      newGroups[splitGroupId] = {
        groupWishId: group.groupWishId,
        quantity: 1,
        preferences: {},
        originalGroupName: getOriginalGroupName(groupId)
      }; 
      newGroups[originalGroupName] = {
        ...group,
        quantity: group.quantity - 1,
        originalGroupName: getOriginalGroupName(groupId)
      };
      return newGroups;
    });
  };
  
  const getOriginalGroupName = (groupId) => {
    return groupId.split('-split-')[0];
  };

  const handlePreferenceValueSelect = (groupId, preferenceUuid, values) => {
    setPreferenceGroups(prevGroups => {
    const group = prevGroups[groupId];
    if (!group) return prevGroups;
 
    const originalGroupName = getOriginalGroupName(groupId);
    const originalGroupData = groupedPreferences[originalGroupName];

    if (!originalGroupData || !originalGroupData.preferences) return prevGroups; 
    const preference = originalGroupData.preferences.find(p => p.uuid === preferenceUuid);
    if (!preference) return prevGroups;

    let selectedValue = null;
    let additionalValues = [];

    if (values && values.length > 0) { 
      selectedValue = preference.values.find(v => v.uuid === values[0]); 
      if (values.length > 1) {
        additionalValues = values.slice(1)
          .map(uuid => preference.values.find(v => v.uuid === uuid))
          .filter(Boolean);
      }
    }

    return {
      ...prevGroups,
      [groupId]: {
        ...prevGroups[groupId],
        preferences: {
          ...prevGroups[groupId].preferences,
          [preferenceUuid]: {
            selectedValue,
            additionalValues
          }
        }
      }
    };
  });
};
  
const handleMergeGroup = (groupId) => {
  setPreferenceGroups(prevGroups => {
    const newGroups = { ...prevGroups };
    const groupIds = Object.keys(newGroups);
    const currentIndex = groupIds.indexOf(groupId);
    const nextGroupId = groupIds[currentIndex + 1]; 
    if (!nextGroupId) return newGroups; 
    const currentGroup = newGroups[groupId];
    const nextGroup = newGroups[nextGroupId]; 
    newGroups[groupId] = {
      ...currentGroup,
      quantity: currentGroup.quantity + nextGroup.quantity,
      originalGroupName: getOriginalGroupName(groupId)
    }; 
    delete newGroups[nextGroupId]; 
    return newGroups;
  });
};

  const getDisplayName = (groupName) => {
    if (typeof groupName === 'string') {
      return groupName;
    }
    if (typeof groupName === 'object' && groupName !== null) { 
      const firstKey = Object.keys(groupName)[0];
      return firstKey || 'Unnamed Group';
    }
    return 'Unnamed Group';
  };

  return (
    <div style={styles.modalOverlay}>
      <div style={styles.modalContent}>
      {isLoading ? (
          <div style={styles.loadingContainer}>
            <div style={styles.spinner} />
          </div>
        ) : (
          <>
            <div style={styles.preferencesDisclaimer}>
              <p>
                Note: While we'll make our best effort to accommodate your preferences, they are not guaranteed.
              </p>
            </div>

            {error && (
              <div style={styles.errorMessage}>
                {error}
              </div>
            )}

            <div style={styles.preferencesModalHeader}>
              <h1 style={styles.preferencesModalTitle}>
                {combo ? 'Set Preferences for Combo' : `Set Preferences`}
              </h1>
              <button onClick={handleClose} style={styles.preferencesCloseButton}>
                <FontAwesomeIcon icon={faTimes} />
              </button>
            </div>

            <div style={styles.preferencesContainer}>  
            {Object.entries(groupedPreferences).map(([groupName, groupData]) => {
              const groupIds = Object.keys(preferenceGroups)
                .filter(id => id.startsWith(groupName));
              
              return (
                <div key={groupName} style={styles.groupWishContainer}>
                  <h2 style={styles.groupWishTitle}>{groupName}</h2>
                  
                  {groupIds.map((groupId, index) => {
                    const group = preferenceGroups[groupId];
                    if (!group) return null;
                    return (
                      <div key={groupId} style={styles.splitGroupContainer}>
                        <UserPreferenceGroup
                          group={group}
                          groupName={groupName}
                          groupId={groupId}
                          groupIndex={index}
                          preferences={groupData.preferences}
                          onSplitGroup={() => handleSplitGroup(groupName, groupId)}
                          onMergeGroup={handleMergeGroup}
                          onPreferenceValueSelect={handlePreferenceValueSelect}
                          totalGroups={groupIds.length}
                        />
                      </div>
                    );
                  })}
                </div>
              );
            })}
            </div>

            <div>
              {/* Custom Preferences Section */}
              <div style={styles.customPreferences}>
                <h2>Custom Preferences</h2>
                <input
                  style={styles.customPreferenceInput}
                  placeholder="Add a custom preference (max 50 chars)"
                  value={customPreference}
                  onChange={handleCustomPreferenceChange}
                  onBlur={handleAddCustomPreference} maxLength={50} />
                {customPreferences.map((cp, index) => (
                  <div key={index} style={styles.preferenceItem}>
                    <div style={styles.preferenceHeader}>
                      <span>{cp.value}</span>
                      <button onClick={() => handleClearCustomPreference(index)} style={styles.deleteButton} >
                        Delete
                      </button>
                    </div>
                  </div>
                ))}
              </div>

              <button className={`submit-button ${isSubmitting ? 'submit-button-disabled' : ''}`}  onClick={handleSubmit}  disabled={isSubmitting} style={{...RoseGoldButton, ...styles.submitButton}}>
                {isSubmitting ? 'Submitting...' : 'Submit'}
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const styles = {
  modalOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  },
  modalContent: {
    backgroundColor: Color.colorLightBeige,
    borderRadius: '8px',
    padding: '20px',
    width: '90%',
    maxWidth: '800px',
    maxHeight: '90vh',
    overflowY: 'auto',
    position: 'relative',
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '200px',
  },
  spinner: {
    border: '4px solid #f3f3f3',
    borderTop: `4px solid ${Color.colorMintGreen}`,
    borderRadius: '50%',
    width: '40px',
    height: '40px',
    animation: 'spin 1s linear infinite',
  },
  preferencesDisclaimer: {
    backgroundColor: Color.colorYellow,
    borderLeft: '10px solid #ffd700',
    padding: '12px',
    marginBottom: '16px',
    borderRadius: '8px',
    color: Color.colorPeacockBlue,
    fontSize: '14px',
    lineHeight: '1.5',
    margin: 0,
  },
  errorMessage: {
    backgroundColor: '#fee2e2',
    color: '#dc2626',
    padding: '12px',
    borderRadius: '6px',
    marginBottom: '16px',
    textAlign: 'center',
    fontWeight: 500,
  },
  preferencesModalHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '20px',
  },
  preferencesModalTitle: {
    fontSize: '24px',
    color: Color.colorPeacockBlueDark,
  },
  preferencesCloseButton: {
    background: 'none',
    border: 'none',
    fontSize: '24px',
    cursor: 'pointer',
    color: Color.colorPeacockBlueDark,
  },
  preferencesContainer: { 
    borderRadius: '8px',
    padding: '15px',
    color: Color.colorThemeGrey,
    fontSize: '14px',
  },
  customPreferences: {
    marginTop: '20px',
    color: Color.colorPeacockBlueDark,
  },
  customPreferenceInput: {
    width: '95%',
    padding: '10px',
    border: '1px solid #ccc',
    borderRadius: '8px',
    marginBottom: '10px',
    marginTop: '2%'
  },
  deleteButton :{
    backgroundColor: '#dc2626',
    color: 'white',
    border: 'none',
    padding: '5px 10px',
    borderRadius: '4px',
    cursor: 'pointer',
  },
  submitButton : {     
    width: '65%',
    marginLeft: '15%',
    marginTop: '20px',
  },
  submitButtonDisabled: {
    opacity: 0.5,
    cursor: 'not-allowed',
  },
  groupWishSection: {
    marginBottom: '20px',
    padding: '15px',
    backgroundColor: Color.colorLightBeige,
    borderRadius: '8px',
  },
  groupWishTitle: {
    color: Color.colorPeacockBlueDark,
    marginBottom: '15px',
  },
  noPreferencesMessage: {
    color: Color.colorThemeGrey,
    fontStyle: 'italic',
  }
};
 
export default PreferencesScreen;