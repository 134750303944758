import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faSearchPlus, faSearchMinus, faExpand, faCompress } from '@fortawesome/free-solid-svg-icons';
import { Color } from "../../../GlobalStyles";

const ComboMediaGallery = ({ mainImageUrl, additionalMedia = [] }) => {
  const [selectedMedia, setSelectedMedia] = useState({ url: mainImageUrl, type: 'IMAGE' });
  const [showZoom, setShowZoom] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(1);
  const [isDragging, setIsDragging] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [startPosition, setStartPosition] = useState({ x: 0, y: 0 });
  const [isFullscreen, setIsFullscreen] = useState(false);
  
  // Filter out any empty or invalid media
  const validAdditionalMedia = additionalMedia.filter(media => 
    media && media.media_url && (media.media_type === 'IMAGE' || media.media_type === 'VIDEO')
  );
  
  const allMedia = [
    { uuid: 'main', media_url: mainImageUrl, media_type: 'IMAGE' },
    ...validAdditionalMedia
  ];

  // Reset position and zoom when media changes
  useEffect(() => {
    setZoomLevel(1);
    setPosition({ x: 0, y: 0 });
  }, [selectedMedia]);

  const handleMediaSelect = (media) => {
    setSelectedMedia({
      url: media.media_url,
      type: media.media_type
    });
  };

  const handleZoomClick = (e) => {
    e.stopPropagation();
    if (selectedMedia.type === 'IMAGE') {
      setShowZoom(true);
      // Reset zoom and position when opening zoom modal
      setZoomLevel(1);
      setPosition({ x: 0, y: 0 });
    }
  };

  const handleCloseZoom = () => {
    setShowZoom(false);
    setIsFullscreen(false);
  };

  const handleZoomIn = (e) => {
    e.stopPropagation();
    if (zoomLevel < 3) {
      setZoomLevel(prev => Math.min(prev + 0.5, 3));
    }
  };

  const handleZoomOut = (e) => {
    e.stopPropagation();
    if (zoomLevel > 1) {
      setZoomLevel(prev => Math.max(prev - 0.5, 1));
      // Reset position if zooming out to level 1
      if (zoomLevel <= 1.5) {
        setPosition({ x: 0, y: 0 });
      }
    }
  };

  const handleMouseDown = (e) => {
    if (zoomLevel > 1) {
      setIsDragging(true);
      setStartPosition({
        x: e.clientX - position.x,
        y: e.clientY - position.y
      });
    }
  };

  const handleMouseMove = (e) => {
    if (isDragging && zoomLevel > 1) {
      setPosition({
        x: e.clientX - startPosition.x,
        y: e.clientY - startPosition.y
      });
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const toggleFullscreen = (e) => {
    e.stopPropagation();
    setIsFullscreen(!isFullscreen);
  };

  // Add event listeners for mouse up and mouse leave
  useEffect(() => {
    const handleGlobalMouseUp = () => {
      setIsDragging(false);
    };

    document.addEventListener('mouseup', handleGlobalMouseUp);
    
    return () => {
      document.removeEventListener('mouseup', handleGlobalMouseUp);
    };
  }, []);

  return (
    <>
      <div style={styles.container}>
        {/* Main display area */}
        <div style={styles.mainDisplay}>
          {selectedMedia.type === 'VIDEO' ? (
            <video 
              style={styles.video}
              controls
              src={selectedMedia.url}
            >
              Your browser does not support the video tag.
            </video>
          ) : (
            <div style={styles.imageContainer}>
              <img
                src={selectedMedia.url}
                alt="Product"
                style={styles.mainImage}
                onError={(e) => {e.target.src = "/api/placeholder/400/400";}}
              />
              <button 
                style={styles.zoomButton}
                onClick={handleZoomClick}
                title="Click to zoom"
              >
                <FontAwesomeIcon icon={faSearchPlus} style={styles.zoomIcon} />
              </button>
            </div>
          )}
        </div>

        {/* Thumbnail strip */}
        {allMedia.length > 1 && (
          <div style={styles.thumbnailStrip}>
            <div style={styles.thumbnailContainer}>
              {allMedia.map((media, index) => (
                <div
                  key={media.uuid || index}
                  onClick={() => handleMediaSelect(media)}
                  style={{
                    ...styles.thumbnail,
                    ...(selectedMedia.url === media.media_url ? styles.thumbnailActive : {})
                  }}
                >
                  {media.media_type === 'VIDEO' ? (
                    <div style={styles.videoThumbnailWrapper}>
                      <video 
                        src={media.media_url}
                        style={styles.thumbnailVideo}
                      />
                      <div style={styles.videoOverlay}>
                        <div style={styles.playButtonCircle}>
                          <div style={styles.playButtonTriangle}></div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <img
                      src={media.media_url}
                      alt={`Thumbnail ${index + 1}`}
                      style={styles.thumbnailImage}
                      onError={(e) => {e.target.src = "/api/placeholder/80/80";}}
                    />
                  )}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>

      {/* Zoom Modal */}
      {showZoom && (
        <div 
          style={{
            ...styles.modal,
            ...(isFullscreen ? styles.fullscreenModal : {})
          }} 
          onClick={handleCloseZoom}
        >
          <div 
            style={{
              ...styles.modalContent,
              ...(isFullscreen ? styles.fullscreenContent : {})
            }} 
            onClick={e => e.stopPropagation()}
          >
            <div style={styles.controlsBar}>
              <button 
                style={styles.controlButton}
                onClick={handleZoomOut}
                disabled={zoomLevel <= 1}
                title="Zoom Out"
              >
                <FontAwesomeIcon icon={faSearchMinus} />
              </button>
              <span style={styles.zoomLevel}>{Math.round(zoomLevel * 100)}%</span>
              <button 
                style={styles.controlButton}
                onClick={handleZoomIn}
                disabled={zoomLevel >= 3}
                title="Zoom In"
              >
                <FontAwesomeIcon icon={faSearchPlus} />
              </button>
              <button 
                style={styles.controlButton}
                onClick={toggleFullscreen}
                title={isFullscreen ? "Exit Fullscreen" : "Fullscreen"}
              >
                <FontAwesomeIcon icon={isFullscreen ? faCompress : faExpand} />
              </button>
              <button 
                style={styles.closeButton} 
                onClick={handleCloseZoom}
                title="Close"
              >
                <FontAwesomeIcon icon={faTimes} />
              </button>
            </div>
            <div 
              style={styles.zoomedImageContainer}
              onMouseDown={handleMouseDown}
              onMouseMove={handleMouseMove}
              onMouseUp={handleMouseUp}
              onMouseLeave={handleMouseUp}
            >
              <img
                src={selectedMedia.url}
                alt="Zoomed Product"
                style={{
                  ...styles.zoomedImage,
                  transform: `scale(${zoomLevel}) translate(${position.x / zoomLevel}px, ${position.y / zoomLevel}px)`,
                  cursor: zoomLevel > 1 ? (isDragging ? 'grabbing' : 'grab') : 'default'
                }}
                onError={(e) => {e.target.src = "/api/placeholder/800/800";}}
                draggable="false" // Prevent browser default drag
              />
            </div>
            {allMedia.length > 1 && (
              <div style={styles.modalThumbnails}>
                {allMedia.map((media, index) => (
                  <div
                    key={media.uuid || `modal-${index}`}
                    onClick={() => handleMediaSelect(media)}
                    style={{
                      ...styles.modalThumbnail,
                      ...(selectedMedia.url === media.media_url ? styles.modalThumbnailActive : {})
                    }}
                  >
                    {media.media_type === 'IMAGE' ? (
                      <img
                        src={media.media_url}
                        alt={`Thumbnail ${index + 1}`}
                        style={styles.modalThumbnailImage}
                        onError={(e) => {e.target.src = "/api/placeholder/60/60";}}
                      />
                    ) : (
                      <div style={styles.videoThumbnailWrapper}>
                        <video src={media.media_url} style={styles.thumbnailVideo} />
                        <div style={styles.videoOverlay}>
                          <div style={styles.playButtonCircle}>
                            <div style={styles.playButtonTriangle}></div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    borderRadius: '8px',
    maxWidth: '42rem',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  mainDisplay: {
    width: '100%',
    height: '384px',
    backgroundColor: '#FFFFFF',
    borderRadius: '8px',
    marginBottom: '16px',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',  
  },
  imageContainer: {
    position: 'relative',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  mainImage: {
    width: '100%',
    height: '100%',
    borderRadius: '8px',
    objectFit: 'contain',
    padding: '16px'
  },
  video: {
    width: '100%',
    height: '100%',
    objectFit: 'contain'
  },
  zoomButton: {
    position: 'absolute',
    bottom: '16px',
    right: '16px',
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
    border: 'none',
    borderRadius: '50%',
    width: '40px',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer', 
    transition: 'all 0.2s ease',
    zIndex: 5
  },
  zoomIcon: {
    color: Color.colorPeacockBlueDark,
    fontSize: '20px',
  },
  thumbnailStrip: {
    width: '100%',
    overflowX: 'auto',
    paddingBottom: '8px'
  },
  thumbnailContainer: {
    display: 'flex',
    gap: '16px',
    padding: '8px',
    justifyContent: 'center'
  },
  thumbnail: {
    flexShrink: 0,
    width: '80px',
    height: '80px',
    borderRadius: '8px',
    overflow: 'hidden',
    cursor: 'pointer',
    border: `2px solid ${Color.colorLightBeige}`,
    transition: 'all 0.2s ease'
  },
  thumbnailActive: {
    border: `2px solid ${Color.colorPeacockBlue}`,
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
  },
  thumbnailImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    padding: '4px'
  },
  thumbnailVideo: {
    width: '100%',
    height: '100%',
    objectFit: 'cover'
  },
  videoThumbnailWrapper: {
    position: 'relative',
    width: '100%',
    height: '100%'
  },
  videoOverlay: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.3)'
  },
  playButtonCircle: {
    width: '32px',
    height: '32px',
    borderRadius: '50%',
    border: '2px solid #FFFFFF',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  playButtonTriangle: {
    width: 0,
    height: 0,
    borderTop: '6px solid transparent',
    borderLeft: '12px solid #FFFFFF',
    borderBottom: '6px solid transparent',
    marginLeft: '4px'
  },
  modal: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.85)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1000,
    animation: 'fadeIn 0.3s ease-in-out',
  },
  fullscreenModal: {
    backgroundColor: 'black',
  },
  modalContent: {
    position: 'relative',
    width: '90%',
    height: '85vh',
    maxWidth: '1200px',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#1a1a1a',
    borderRadius: '12px',
    overflow: 'hidden',
    boxShadow: '0 8px 30px rgba(0, 0, 0, 0.3)',
  },
  fullscreenContent: {
    width: '100%',
    height: '100%',
    borderRadius: '0',
  },
  controlsBar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '12px 16px',
    backgroundColor: '#2a2a2a',
    borderBottom: '1px solid #3a3a3a',
  },
  controlButton: {
    backgroundColor: 'transparent',
    border: 'none',
    color: 'white',
    padding: '8px 12px',
    margin: '0 4px',
    borderRadius: '4px',
    cursor: 'pointer',
    transition: 'all 0.2s ease',
  },
  zoomLevel: {
    color: 'white',
    margin: '0 8px',
    fontSize: '14px',
  },
  closeButton: {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    border: 'none',
    color: 'white',
    padding: '8px 12px',
    marginLeft: '16px',
    borderRadius: '4px',
    cursor: 'pointer',
    transition: 'all 0.2s ease',
  },
  zoomedImageContainer: {
    flex: 1,
    position: 'relative',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#1a1a1a',
  },
  zoomedImage: {
    maxWidth: '100%',
    maxHeight: '100%',
    objectFit: 'contain',
    transition: 'transform 0.1s ease-out',
    transformOrigin: 'center center',
    userSelect: 'none',
  },
  modalThumbnails: {
    display: 'flex',
    justifyContent: 'center',
    padding: '12px',
    backgroundColor: '#2a2a2a',
    borderTop: '1px solid #3a3a3a',
    overflowX: 'auto',
    gap: '8px',
  },
  modalThumbnail: {
    width: '60px',
    height: '60px',
    borderRadius: '4px',
    overflow: 'hidden',
    cursor: 'pointer',
    border: '2px solid transparent',
    transition: 'all 0.2s ease',
  },
  modalThumbnailActive: {
    border: `2px solid ${Color.colorPeacockBlue}`,
  },
  modalThumbnailImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  }
};

// Add animation styles
if (typeof document !== 'undefined') {
  const styleSheet = document.createElement("style");
  styleSheet.type = "text/css";
  styleSheet.innerText = `
    @keyframes fadeIn {
      from { opacity: 0; }
      to { opacity: 1; }
    }
  `;
  document.head.appendChild(styleSheet);
}

export default ComboMediaGallery;