import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import * as style from "../../../GlobalStyles"; 
import {getAuthToken} from '../../Common/InitialGears/authLoginUtils';
import { getApi, postApi, buildUrl} from "../../Common/BackendCalls/apiUtils";
import AddWishModal from '../Wishes/AddWish';
import MyWishesModal from "../Wishes/MyWishesModal";
import TrendingDetailsModal from '../Wishes/TrendingDetailsModal'; 
import CompletedGroupWishModal from '../GroupWishes/CompletedGroupWishes';
import { Color, RoseGoldButton,MatchingButton } from "../../../GlobalStyles";

const styles = {
  container: {
    width: '100%'
  },
  buttonGrid: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    gap: '1rem',
    padding: '1.25rem'
  },
  button: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'white',
    borderRadius: '0.75rem',
    padding: '1rem',
    width: '12rem',
    height: '6rem',
    transform: 'translateY(0)',
    transition: 'all 0.2s ease-in-out',
    cursor: 'pointer',
    border: 'none'
  },
  buttonHover: {
    transform: 'translateY(-0.25rem)',
  },
  iconContainer: {
    width: '2.5rem',
    height: '2.5rem',
    marginBottom: '0.5rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  icon: {
    width: '2.5rem',
    height: '2.5rem',
    objectFit: 'contain'
  },
  buttonText: {
    fontSize: '0.875rem',
    fontWeight: 'bold',
    textAlign: 'center',
    color: Color.colorRoseGoldText 
  },
  overlay: {
    position: 'fixed',
    inset: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 9999, 
  },
  modal: {
    backgroundColor: 'white',
    borderRadius: '0.5rem',
    padding: '1.5rem',
    width: '100%',
    maxWidth: '28rem',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)',  
    position: 'relative', 
    zIndex: 10000,
  },
  modalHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '1rem'
  },
  modalTitle: {
    fontSize: '1.25rem',
    fontWeight: 'bold',
    color: Color.colorRoseGoldText
  },
  closeButton: {
    color: '#6b7280',
    cursor: 'pointer',
    border: 'none',
    background: 'none',
    fontSize: '1.5rem'
  },
  modalText: {
    marginBottom: '1.5rem',
    color: '#374151'
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem'
  },
  createButton: {
    width: '100%', 
    color: 'white', 
    border: 'none',
    cursor: 'pointer'
  },
  viewButton: {
    width: '100%',
    backgroundColor: Color.colorPeacockBlueDark,
    color: 'white', 
    border: 'none',
    cursor: 'pointer'
  },
  termsContainer: {
    marginTop: '1.5rem',
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem'
  },
  checkbox: {
    height: '1rem',
    width: '1rem'
  },
  termsText: {
    fontSize: '0.875rem',
    color: '#374151'
  },
  termsLink: {
    fontSize: '0.875rem',
    color: '#2563eb',
    textDecoration: 'underline',
    cursor: 'pointer',
    background: 'none',
    border: 'none'
  },
  buttonContainerStyle: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    gap: '15px',
    padding: '20px 0',
  },
  wishTypeCardStyle: (color) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: style.Color.colorWhite,
    borderRadius: '15px',
    padding: '5px',
    margin: '25px',
    width: '200px',
    height: '100px',
    boxShadow: `10px 10px 10px 2px ${color}40`,
    transition: 'all 0.3s ease',
    cursor: 'pointer',
    position: 'relative',
    overflow: 'hidden',
    border: `0px solid ${color}`,
    '::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      height: '4px',
      backgroundColor: color,
    },
    ':hover': {
      transform: 'translateY(-5px)',
      boxShadow: `20px 20px 15px 15px ${color}60`,
    },
  }),
  iconContainerStyle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '40px',
    height: '40px',
    marginBottom: '10px',
  },
  cardTextStyle: {
    fontSize: '14px',
    fontWeight: 'bold',
    color: style.Color.colorPeacockBlue,
    textAlign: 'center',
    maxWidth: '90%',
  },

  errorMessage: {
    marginTop: '1rem',
    padding: '1rem',
    backgroundColor: '#fee2e2',
    color: '#b91c1c',
    borderRadius: '0.375rem'
  }
};

const DashboardButtons = ({
  isBacksAppStaff,
  queryLoginParam, 
  onRefresh, 
  onNavigate,
  imageUrls 
}) => {
  const [comboGroupWishModalVisible, setIsComboGroupWishModalVisible] = useState(false);
  const navigate = useNavigate();
  const [showDraftTermsModal, setShowDraftTermsModal] = useState(false);
  const [termsAgreed, setTermsAgreed] = useState(false); 
  const [isLoading, setIsLoading] = useState(true);
  const [wishlistData, setWishlistData] = useState([]);
  const [trendsData, setTrendsData] = useState([]);
  const [existingWishes, setExistingWishes] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null); 
  const [wishesLoaded, setWishesLoaded] = useState(false); 
  const [showMyWishesModal, setShowMyWishesModal] = useState(false);
  const [showWishTrends, setShowWishTrends] = useState(false);
  const [showCompletedModal, setShowCompletedModal] = useState(false);
  const [showAddWishModal, setShowAddWishModal] = useState(false); 


  const fetchWishListData = async () => { 
    let wishURL = buildUrl('getMyWishes', `${queryLoginParam}&limit=5`);
    let token = await getAuthToken();
    if (!token) {
      throw new Error('No authentication token available');
    } 
    try {
      const response = await getApi(wishURL);
      let responseData = response.data;
      
      if (responseData) {
        setWishlistData(responseData.wish_lists);
        setExistingWishes(responseData.categories || []);
        setWishesLoaded(true);
      } else {
        setWishlistData({});
        setWishesLoaded(true);
        setExistingWishes([]);
      }
    } catch (error) {
      setErrorMessage('Oops, please re-try');
      setExistingWishes([]);
    } finally {
      setIsLoading(false);
      setWishesLoaded(true);
    }
  };
 
  const fetchWishTrends = async () => { 
    try {
      let wishTrendsURL = buildUrl('getWishTrends', `${queryLoginParam}&limit=3`);
      let token = await getAuthToken();
      if (!token) {
        throw new Error('No authentication token available');
      } 
      const response = await getApi(wishTrendsURL); 

      if (response.ok && response.data && response.data.status === "success" && Array.isArray(response.data.data)) {
        const trendsArray = response.data.data.map(item => ({
          name: item.name,
          count: item.count
        }));
        setTrendsData(trendsArray);
      } else { 
        setTrendsData([]);
      }
    } catch (error) { 
      setTrendsData([]);
    }
  };
  const handleMyWishesClick = async () => {
    try {
      await fetchWishListData();
      setShowMyWishesModal(true);
    } catch (error) {
      setErrorMessage('Failed to load wish list data');
    }
  };

  const handleWishTrendsClick = async () => {
    try {
      await fetchWishTrends();
      setShowWishTrends(true);
    } catch (error) {
      setErrorMessage('Failed to load wish trends');
    }
  };
  
  const handleCheckToolClick = () => {
    navigate("/productEvaluationTool");
  };

  const handleAddNewWishClick = () => {
    setShowAddWishModal(true);
  };
  
  const handleCloseCompletedModal = () => {
    setShowCompletedModal(false); 
  };

  const wishTypes = [
    ...(isBacksAppStaff === 'true' || isBacksAppStaff === true 
      ? [{ 
          title: 'Combo/Group Wish', 
          icon: <img src={imageUrls.newImage} alt="My Wishes" style={styles.icon} />,
          onPress: () => setIsComboGroupWishModalVisible(true),
          color: '#45B7D1'
        },{
          title: 'Order Management',
          icon: <img src={imageUrls.cargoImage} alt="Orders" style={styles.icon} />,
          onPress: () => onNavigate('/orderManagement'),
          color: '#DDA0DD'
        },{
          title: 'Referral Requested',
          icon: <img src={imageUrls.bonusImage} alt="Orders" style={styles.icon} />,
          onPress: () => onNavigate('/referralRequested'),
          color: '#26524a'
        },
        { 
          title: 'CheckTool', 
          icon: <img src={imageUrls.completedImage} alt="My Wishes" style={styles.icon} />,
          onPress: handleCheckToolClick,
          color: '#FF6B6B'
        }]  
      : []
    ),
    { 
      title: 'Joined Groups', 
      icon: <img src={imageUrls.meetingImage} alt="My Wishes" style={styles.icon} />,
      onPress: () => onNavigate('/joinedDeals'),
      color: '#f5ce42'
    },
    { 
      title: 'Completed Wishes', 
      icon: <img src={imageUrls.completedImage} alt="My Wishes" style={styles.icon} />,
      onPress: () => onNavigate('/completedDeals'),
      color: '#FF6B6B'
    },   
  ];
  
  const personalWishTypes = [
    {
      title: 'My Wishes',
      icon: <img src={imageUrls.shootingStarImage} alt="My Wishes" style={styles.icon} />,
      onPress: handleMyWishesClick,
      color: '#f5600a'
    },
    {
      title: 'Wish Trends',
      icon: <img src={imageUrls.trendingImage} alt="Wish Trends" style={styles.icon} />,
      onPress: handleWishTrendsClick,
      color: '#98FB98'
    },
    {
      title: 'I have a new Wish',
      icon: <img src={imageUrls.newWishAIRequestImage} alt="New Wish" style={styles.icon} />,
      onPress: handleAddNewWishClick,
      color: '#2e40e6'
    }
  ];
  

  const ComboGroupWishOverlay = ({ isVisible, onClose }) => {
    if (!isVisible) return null;
    
    const handleCreateNew = () => {
      if (termsAgreed) {
        setIsComboGroupWishModalVisible(false);
        onNavigate('/createGroupWishes');
      } else {
        alert("Please agree to the Terms of Use before proceeding.");
      }
    };
    const handleComboView = () => {
      if (termsAgreed) {
        setIsComboGroupWishModalVisible(false);
        onNavigate('/comboList');
      } else {
        alert("Please agree to the Terms of Use before proceeding.");
      }
    };
    const handleViewDrafts = () => {
      if (termsAgreed) {
        setIsComboGroupWishModalVisible(false);
        onNavigate('/draftGroupWishes');
      } else {
        alert("Please agree to the Terms of Use before proceeding.");
      }
    };

    return (
      <div style={styles.overlay}>
        <div style={styles.modal}>
          <div style={styles.modalHeader}>
            <h2 style={styles.modalTitle}>Combo or Group Wishes</h2>
            <button onClick={onClose} style={styles.closeButton}>
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>
          
          <p style={styles.modalText}>Take me to Group Wishes or Combo Listing</p>
          
          <div style={styles.buttonContainer}>
            <button onClick={handleComboView} style={{...RoseGoldButton,...styles.createButton}} >
              Combos
            </button>
            
            <button onClick={handleViewDrafts} style={{...MatchingButton,...styles.viewButton}}>
              Group Wishes
            </button>
          </div>
          
          <div style={styles.termsContainer}>
            <input type="checkbox" checked={termsAgreed} onChange={(e) => setTermsAgreed(e.target.checked)} style={styles.checkbox} />
            <label style={styles.termsText}>I agree to the Terms of Use</label>
            <button onClick={() => setShowDraftTermsModal(true)} style={styles.termsLink}>
              View Terms
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div style={styles.container}>
      <div style={styles.buttonGrid}>
        {wishTypes.map(({ title, icon, onPress, color }) => (
          <button key={title} style={styles.wishTypeCardStyle(color)} onClick={onPress}>
            <div style={styles.iconContainerStyle}>{icon}</div>
            <span style={styles.cardTextStyle}>{title}</span>
          </button>
        ))}    
        {personalWishTypes.map(({ title, icon, onPress, color }) => (
          <button key={title} style={styles.wishTypeCardStyle(color)} onClick={onPress}>
            <div style={styles.iconContainerStyle}>{icon}</div>
            <span style={styles.cardTextStyle}>{title}</span>
          </button>
        ))}
      </div>

      <ComboGroupWishOverlay 
        isVisible={comboGroupWishModalVisible} 
        onClose={() => setIsComboGroupWishModalVisible(false)}
      />

      {showMyWishesModal && (
        <MyWishesModal 
          wishlistData={wishlistData} 
          onClose={() => setShowMyWishesModal(false)}
        />
      )}
      
      {showWishTrends && (
        <TrendingDetailsModal 
          trendsData={trendsData} 
          onClose={() => setShowWishTrends(false)}
        />
      )}
      
      <CompletedGroupWishModal isOpen={showCompletedModal} onClose={handleCloseCompletedModal} />
      
      <AddWishModal 
        isOpen={showAddWishModal}
        onClose={() => setShowAddWishModal(false)}
      />
       {errorMessage && (
        <div style={styles.errorMessage}>
          {errorMessage}
        </div>
      )}
    </div>
  );
};

export default DashboardButtons;