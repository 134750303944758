import React from 'react';
import { Star } from 'lucide-react';
import { Color } from "../../../GlobalStyles";
 

const ComboRatingDisplay = ({ averageRating = 0, ratingCounts = [], upvotes = 0 }) => { 
  const parseRatingCounts = () => {
    const ratings = {
      5: 0, 4: 0, 3: 0, 2: 0, 1: 0
    };
    
    ratingCounts.forEach(countText => { 
      const match = countText.match(/(\d+)\s+stars?\s+(\d+)/i);
      if (match) {
        const stars = parseInt(match[1]);
        const count = parseInt(match[2]);
        
        if (stars >= 1 && stars <= 5) {
          ratings[stars] = count;
        }
      }
    });
    
    return ratings;
  };
  
  const ratings = parseRatingCounts();
  const totalRatings = Object.values(ratings).reduce((sum, count) => sum + count, 0);
  
  return (
    <div style={styles.container}>
      <h3 style={styles.title}>Customer Ratings</h3>
      
      <div style={styles.overviewContainer}>
        <div style={styles.averageRatingContainer}>
          <span style={styles.averageRating}>{averageRating.toFixed(1)}</span>
          <div style={styles.starsRow}>
            {[1, 2, 3, 4, 5].map(star => (
              <Star 
                key={star}
                size={16} 
                fill={star <= averageRating ? Color.colorPurple : 'transparent'} 
                color={Color.colorPurple}
              />
            ))}
          </div>
          <span style={styles.totalRatings}>{totalRatings} ratings</span>
        </div>
        
        <div style={styles.ratingBarsContainer}>
          {[5, 4, 3, 2, 1].map(star => {
            const count = ratings[star] || 0;
            const percentage = totalRatings > 0 ? (count / totalRatings) * 100 : 0;
            
            return (
              <div key={star} style={styles.ratingBar}>
                <span style={styles.starLabel}>{star} star</span>
                <div style={styles.barBackground}>
                  <div style={{
                    ...styles.barFill,
                    width: `${percentage}%`,
                    backgroundColor: Color.colorPurple, 
                  }} />
                </div>
                <span style={styles.countLabel}>{count}</span>
              </div>
            );
          })}
        </div>
      </div>
      
      <div style={styles.upvotesContainer}>
        <span style={styles.upvotesLabel}>Upvotes:</span>
        <span style={styles.upvotesCount}>{upvotes}</span>
      </div>
    </div>
  );
};

const styles = {
  container: {
    backgroundColor: '#ffffff',
    borderRadius: '8px',
    padding: '16px',
    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
    marginBottom: '0px',
  },
  title: {
    fontSize: '18px',
    fontWeight: '600',
    color: Color.colorPeacockBlueDark,
    marginBottom: '16px',
  },
  overviewContainer: {
    display: 'flex',
    alignItems: 'stretch',
    gap: '15%',
    marginBottom: '16px',
    marginLeft: '25%'
  },
  averageRatingContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderRight: `1px solid ${Color.colorLightBeige}`,
    paddingRight: '20px',
    minWidth: '100px',
  },
  averageRating: {
    fontSize: '32px',
    fontWeight: '700',
    color: Color.colorPurple,
    lineHeight: '1',
  },
  starsRow: {
    display: 'flex',
    gap: '2px',
    margin: '8px 0',
  },
  totalRatings: {
    fontSize: '12px',
    color: Color.colorPurple,
  },
  ratingBarsContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    maxWidth: '25%'
  },
  ratingBar: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    height: '20px',
  },
  starLabel: {
    fontSize: '12px',
    color: Color.colorPurple,
    width: '45px',
  },
  barBackground: {
    flex: 1,
    height: '8px',
    backgroundColor: Color.colorLightBeige,
    borderRadius: '4px',
    overflow: 'hidden',
  },
  barFill: {
    height: '100%',
    backgroundColor: Color.colorPurple,  
    borderRadius: '4px',
  },
  countLabel: {
    fontSize: '12px',
    color: Color.colorPurple,
    width: '20px',
    textAlign: 'right',
  },
  upvotesContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    marginTop: '8px',
    padding: '8px 0',
    borderTop: `1px solid ${Color.colorLightBeige}`,
  },
  upvotesLabel: {
    fontSize: '14px',
    color: Color.colorPeacockBlueDark,
  },
  upvotesCount: {
    fontSize: '16px',
    fontWeight: '600',
    color: Color.colorPurple,
  },
};

export default ComboRatingDisplay;