import React from 'react';
import { formatDistanceToNow } from 'date-fns';
import * as styles1 from '../../../GlobalStyles';

const InvoiceCard = ({ invoice, onClick }) => {
  // Format date to show time since invoice was created
  const formatDate = (dateString) => {
    try {
      const date = new Date(dateString);
      return formatDistanceToNow(date, { addSuffix: true });
    } catch (e) {
      return 'Invalid date';
    }
  };
  
  // Format currency with USD
  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    }).format(amount);
  };
  
  // Get status color based on invoice status
  const getStatusColor = (status) => {
    switch (status) {
      case 'PAID':
        return '#4CAF50';
      case 'DRAFT':
        return '#FFC107';
      case 'OVERDUE':
        return '#F44336';
      default:
        return '#2196F3';
    }
  };
  
  // Determine if invoice is a combo or group wish
  const isCombo = invoice.is_combo;
  const isGroupWish = !!invoice.group_wish_id;
  
  // Get appropriate title, image, and ID for navigation
  const title = isCombo ? invoice.combo_title : 
               isGroupWish ? invoice.invoice_item.description.replace(/\('Group Wish: |\',\)/g, '') : 
               invoice.invoice_item.description;
  
  const imageUrl = isCombo ? invoice.combo_image : 
                  isGroupWish ? invoice.group_wish_image : 
                  null;
  
  const itemId = isCombo ? invoice.combo_id : 
                isGroupWish ? invoice.group_wish_id : 
                null;
  
  // Calculate savings if applicable
  const originalPrice = invoice.invoice_item.unit_price * invoice.invoice_item.quantity;
  const finalPrice = invoice.subtotal;
  const hasSavings = finalPrice < originalPrice;
  const savingsAmount = originalPrice - finalPrice;
  const savingsPercentage = ((savingsAmount / originalPrice) * 100).toFixed(0);

  return (
    <div 
      style={styles.invoiceCard}
      onClick={() => onClick(invoice.invoice_id, isGroupWish ? invoice.group_wish_id : null, isCombo ? invoice.combo_id : null)}
    >
      <div style={styles.invoiceCardHeader}>
        <div style={styles.invoiceNumber}>{invoice.invoice_number}</div>
        <div style={{
          ...styles.statusBadge,
          backgroundColor: getStatusColor(invoice.status),
        }}>
          {invoice.status}
        </div>
      </div>
      
      <div style={styles.invoiceCardContent}>
        {imageUrl && (
          <div style={styles.imageContainer}>
            <img 
              src={imageUrl} 
              alt={title}
              style={styles.productImage}
            />
          </div>
        )}
        
        <div style={styles.productInfo}>
          <div style={styles.productType}>
            {isCombo ? 'Bundle' : isGroupWish ? 'Group Wish' : 'Product'}
          </div>
          <div style={styles.productTitle}>{title}</div>
          
          <div style={styles.priceContainer}>
            <div style={styles.currentPrice}>{formatCurrency(invoice.total_amount)}</div>
            {hasSavings && (
              <div style={styles.savingsTag}>Save {savingsPercentage}%</div>
            )}
          </div>
          
          <div style={styles.quantityBadge}>
            Qty: {invoice.invoice_item.quantity}
          </div>
        </div>
      </div>
      
      <div style={styles.invoiceCardFooter}>
        <div style={styles.detailGroup}>
          <span style={styles.detailLabel}>Due Date:</span>
          <span style={styles.detailValue}>
            {new Date(invoice.due_date).toLocaleDateString()}
          </span>
        </div>
        <div style={styles.detailGroup}>
          <span style={styles.detailLabel}>Created:</span>
          <span style={styles.detailValue}>{formatDate(invoice.invoice_date)}</span>
        </div>
      </div>
    </div>
  );
};

const styles = {
  invoiceCard: {
    backgroundColor: '#FFFFFF',
    borderRadius: '12px',
    padding: '20px',
    cursor: 'pointer',
    transition: 'transform 0.2s, box-shadow 0.2s',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  invoiceCardHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '16px',
    paddingBottom: '12px',
    borderBottom: '1px solid #E0E0E0',
  },
  invoiceNumber: {
    fontSize: '16px',
    fontWeight: '600',
    color: styles1.Color.colorPurple,
  },
  statusBadge: {
    padding: '4px 8px',
    borderRadius: '4px',
    fontSize: '12px',
    fontWeight: '500',
    color: '#FFFFFF',
  },
  invoiceCardContent: {
    display: 'flex',
    flexGrow: 1,
    marginBottom: '16px',
  },
  imageContainer: {
    marginRight: '16px',
    width: '80px',
    height: '80px',
    borderRadius: '8px',
    overflow: 'hidden',
    backgroundColor: '#F5F5F5',
  },
  productImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  productInfo: {
    flex: 1,
  },
  productType: {
    fontSize: '12px',
    color: styles1.Color.colorTeal,
    marginBottom: '4px',
    fontWeight: '500',
  },
  productTitle: {
    fontSize: '14px',
    fontWeight: '500',
    marginBottom: '8px',
    color: '#333333',
    lineHeight: '1.4',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  priceContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '8px',
  },
  currentPrice: {
    fontSize: '16px',
    fontWeight: '600',
    color: styles1.Color.colorRoyalBlueText,
    marginRight: '8px',
  },
  savingsTag: {
    backgroundColor: '#E8F5E9',
    color: '#2E7D32',
    padding: '2px 6px',
    borderRadius: '4px',
    fontSize: '12px',
    fontWeight: '500',
  },
  quantityBadge: {
    display: 'inline-block',
    backgroundColor: '#F5F5F5',
    padding: '2px 6px',
    borderRadius: '4px',
    fontSize: '12px',
    color: '#666666',
  },
  invoiceCardFooter: {
    marginTop: 'auto',
    paddingTop: '12px',
    borderTop: '1px solid #E0E0E0',
  },
  detailGroup: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '4px',
  },
  detailLabel: {
    fontSize: '12px',
    color: '#666666',
  },
  detailValue: {
    fontSize: '12px',
    color: styles1.Color.colorRoyalBlueText,
    fontWeight: '500',
  },
};

export default InvoiceCard;