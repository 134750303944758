import React, { useState, useEffect } from "react";
import * as style from "../../../GlobalStyles";
import { FaEnvelope, FaPhone, FaShoppingCart } from 'react-icons/fa';
import NewInvites from './NewInvites';
import SentInvites from './SentInvites';
import ReceivedInvites from './ReceivedInvites';
import { TopMenuBar } from '../DashbordComponents/TopMenuBar';

const InvitesPage = () => {
  const [activeTab, setActiveTab] = useState('new');

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const renderActiveContent = () => {
    switch (activeTab) {
      case 'new':
        return <NewInvites />;
      case 'sent':
        return <SentInvites />;
      case 'received':
        return <ReceivedInvites />;
      default:
        return <NewInvites />;
    }
  };

  useEffect(() => {
    const styleElement = document.createElement('style');
    styleElement.textContent = styles;
    document.head.appendChild(styleElement);

    return () => {
      document.head.removeChild(styleElement);
    };
  }, []);

  return (
    <div style={styles.mainContainer}>
    <TopMenuBar showBackButton={false} showHomeButton={true} showMenuButton={true}  showUserName={false}   showNotifications={true} showSearch={false} showAddress={false} />       
    <div className="invites-page-container">
      <div className="invites-dashboard">
        <div className="dashboard-header">
          <h2>Invites Dashboard</h2>
        </div>
        <div className="invites-menu">
          <button
            className={`menu-item ${activeTab === 'new' ? 'active' : ''}`}
            onClick={() => handleTabChange('new')}
          >
            <FaEnvelope /> New Invite
          </button>
          <button
            className={`menu-item ${activeTab === 'sent' ? 'active' : ''}`}
            onClick={() => handleTabChange('sent')}
          >
            <FaPhone /> Sent Invites
          </button>
          <button
            className={`menu-item ${activeTab === 'received' ? 'active' : ''}`}
            onClick={() => handleTabChange('received')}
          >
            <FaShoppingCart /> Received Invites
          </button>
        </div>
        <div className="invites-content">
          {renderActiveContent()}
        </div>
      </div>
    </div>
    </div>
  );
};

const styles = `
  .invites-page-container {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
    min-height: 100vh;
    background-color: ${style.Color.colorWhite};
  }

  .invites-dashboard {
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: white;
    border-radius: 8px; 
  }

  .dashboard-header {
    padding: 20px;
    border-bottom: 1px solid ${style.Color.colorBackground};
  }

  .dashboard-header h2 {
    margin: 0;
    color: ${style.Color.colorDarkBrown};
    font-size: 24px;
  }

  .invites-menu {
    display: flex;
    gap: 260px;
    padding: 60px;
    border-bottom: 1px solid ${style.Color.colorBaseBeige};
    background-color: white;
  }

  .menu-item {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 10px 20px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 16px;
    background-color: ${style.Color.colorPeacockBlueDark};
    color: ${style.Color.colorLightBeige};
    transition: all 0.3s ease;
  }

  .menu-item:hover {
    background-color: ${style.Color.colorDarkBlue}90;
    color: ${style.Color.colorPeacockBlueDark};
  }

  .menu-item.active {
    background-color: ${style.Color.colorRoseGoldText};
    color: white;
  }

  .invites-content {
    flex-grow: 1;
    padding: 20px;
    background-color: white;
    min-height: 500px;
  }

  @media (max-width: 768px) {
    .invites-menu {
      flex-direction: column;
      gap: 10px;
    }

    .menu-item {
      width: 100%;
    }
  }
`;

export default InvitesPage;