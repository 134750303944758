import React from 'react';

const HowItWorksSection = ({ howItWorks, colorStyles }) => {
  return (
    <section className="py-16 bg-gradient-to-b from-amber-50 to-white">
      <div className="max-w-[90rem] mx-auto px-8">
        <div className="text-center mb-16">
          <h2 className={`text-3xl font-bold bg-gradient-to-r text-rose-800 bg-clip-text text-transparent mb-4`}>
            How It Works
          </h2>
          <p className="text-xl text-amber-700/80">Simple steps to start saving</p>
        </div>
        
        <div className="grid md:grid-cols-5 gap-8">
          {howItWorks.map((step, index) => (
            <div key={index}
              className="flex flex-col items-center text-center p-8 rounded-xl bg-white shadow-lg hover:shadow-xl transition-all duration-300 group hover:scale-105"
            >
              <div className={`w-16 h-16 rounded-full bg-gradient-to-r ${colorStyles.primary.gradient} flex items-center justify-center mb-6`}>
                <step.icon className="h-8 w-8 text-white" />
              </div>
              <h3 className={`text-xl font-semibold bg-gradient-to-r ${colorStyles.primary.gradient} bg-clip-text text-transparent mb-4`}>
                {step.title}
              </h3>
              <p className="text-amber-900/70">{step.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default HowItWorksSection;