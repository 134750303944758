import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getApi, buildUrl } from "../Common/BackendCalls/apiUtils";
import { useAuth } from '../Common/InitialGears/AuthContext';
import { TopMenuBar } from '../Buyers/DashbordComponents/TopMenuBar';
import * as style from "../../GlobalStyles";

const OrderManagement = () => {
  const { queryLoginParam } = useAuth();
  const navigate = useNavigate();
  const [allOrders, setAllOrders] = useState([]);  
  const [displayedOrders, setDisplayedOrders] = useState([]);  
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [activeTab, setActiveTab] = useState('all');  
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [pageSize, setPageSize] = useState(10);
 
  useEffect(() => {
    fetchOrders();
  }, [currentPage, pageSize]);
 
  useEffect(() => {
    filterOrders();
  }, [activeTab, allOrders, searchTerm]);

  const fetchOrders = async () => {
    setIsLoading(true);
    setErrorMessage("");
    
    try {  
      const queryParams = { ...queryLoginParam }; 
      queryParams.page = currentPage;
      queryParams.page_size = pageSize;      
      if (searchTerm) {
        queryParams.search = searchTerm;
      } 
      const url = buildUrl('orderList', queryLoginParam); 
      const response = await getApi(url);      
      if (response && response.data) {
        const { orders: ordersData, total_count, total_pages } = response.data;        
        if (Array.isArray(ordersData)) { 
          setAllOrders(ordersData);
          setTotalCount(total_count || ordersData.length);
          setTotalPages(total_pages || Math.ceil(ordersData.length / pageSize));          
          if (ordersData.length === 0) {
            setErrorMessage('No orders available for processing.');
          }
        } else {
          setErrorMessage('Invalid response format.');
        }
      } else {
        setErrorMessage('Failed to fetch orders.');
      }
    } catch (error) {
      console.error('Error fetching orders:', error);
      setErrorMessage('An error occurred while fetching orders');
    } finally {
      setIsLoading(false);
    }
  };
   
  const filterOrders = () => {
    if (!allOrders || allOrders.length === 0) {
      setDisplayedOrders([]);
      return;
    }    
    let filtered = [...allOrders];     
    if (activeTab === 'group') {
      filtered = filtered.filter(order => order.order_type === 'groupwish');
    } else if (activeTab === 'combo') {
      filtered = filtered.filter(order => order.order_type === 'combo');
    } 
    if (searchTerm && !searchTerm.trim().includes(' ')) {
      const term = searchTerm.toLowerCase();
      filtered = filtered.filter(order => {
        if (order.order_type === 'groupwish') {
          return order.wish.product_name.toLowerCase().includes(term);
        } else {
          return order.combo.title.toLowerCase().includes(term);
        }
      });
    }    
    setDisplayedOrders(filtered);
  };
   
  const handleTabChange = (tab) => {
    setActiveTab(tab);
    setCurrentPage(1); 
  };
   
  const handleSearch = (e) => {
    e.preventDefault();
    setCurrentPage(1);   
    fetchOrders();
  };
   
  const clearSearch = () => {
    setSearchTerm('');
    setCurrentPage(1);
    fetchOrders();
  };
 
  const handleOrderClick = (orderId, orderType) => {
    if (orderType === 'groupwish') {
      navigate(`/orderGWProcessing/${orderId}`);
    } else {
      navigate(`/orderComboProcessing/${orderId}`);
    }
  };

  const renderTabButtons = () => {
    return (
      <div style={tabsContainerStyle}>
        <button 
          style={{...tabButtonStyle, ...(activeTab === 'all' ? activeTabStyle : {})}} 
          onClick={() => handleTabChange('all')}
        >
          All Orders
        </button>
        <button 
          style={{...tabButtonStyle, ...(activeTab === 'group' ? activeTabStyle : {})}} 
          onClick={() => handleTabChange('group')}
        >
          Group Wishes
        </button>
        <button 
          style={{...tabButtonStyle, ...(activeTab === 'combo' ? activeTabStyle : {})}} 
          onClick={() => handleTabChange('combo')}
        >
          Combos
        </button>
      </div>
    );
  };

  // Get status color based on status text
  const getStatusColor = (status) => {
    if (!status) return style.Color.colorDarkGrey;
    
    const lowerStatus = status.toLowerCase();
    
    if (lowerStatus.includes('completed') || lowerStatus.includes('delivering')) {
      return '#4CAF50'; // Green
    } else if (lowerStatus.includes('payment') || lowerStatus.includes('shipped')) {
      return '#2196F3'; // Blue
    } else if (lowerStatus.includes('min_met') || lowerStatus.includes('activated')) {
      return '#FF9800'; // Orange
    } else if (lowerStatus.includes('draft') || lowerStatus.includes('pool_open')) {
      return '#9C27B0'; // Purple
    } else {
      return style.Color.colorDarkGrey;
    }
  };
  
  // Format progress bar based on percentage
  const renderProgressBar = (progress) => {
    const percentage = Math.min(100, Math.max(0, progress * 100)); // Ensure between 0-100
    
    return (
      <div style={progressBarContainerStyle}>
        <div style={{
          ...progressBarStyle,
          width: `${percentage}%`,
          backgroundColor: percentage > 66 ? '#4CAF50' : percentage > 33 ? '#FF9800' : '#F44336'
        }}></div>
      </div>
    );
  };

  const renderOrderItem = (orderData) => {
    if (orderData.order_type === 'groupwish') {
      const wish = orderData.wish;
      const status = wish.group_wish_status || 'N/A';
      const statusColor = getStatusColor(status);
      
      return (
        <div key={`group-${wish.id}`} style={wishItemStyle} onClick={() => handleOrderClick(wish.id, 'groupwish')}>
          <div style={orderTypeChipStyle('groupwish')}>Group Wish</div>
          <h3 style={wishTitleStyle}>{wish.product_name}</h3>
          <p style={wishCategoryStyle}>
            Category: {Array.isArray(wish.category) ? wish.category.join(', ') : (wish.category || 'N/A')}
          </p>
          <p style={{...wishStatusStyle, color: statusColor}}>
            Status: <span style={{fontWeight: 'bold'}}>{status}</span>
          </p>
          <p style={wishInfoStyle}>Group Size: {wish.group_size_min}</p>
          <p style={wishInfoStyle}>Expiry: {wish.expires_at ? new Date(wish.expires_at).toLocaleDateString() : 'N/A'}</p>
          
          <div style={wishInfoStyle}>
            <p style={{margin: '5px 0'}}>Progress: {orderData.progress ? (orderData.progress * 100).toFixed(2) : 0}%</p>
            {renderProgressBar(orderData.progress || 0)}
          </div>
          
          <p style={wishInfoStyle}>Total Joined: {orderData.totalJoined || 0}</p>
          
          {wish.image_url && (
            <div style={imageContainerStyle}>
              <img src={wish.image_url} alt={wish.product_name} style={imageStyle} />
            </div>
          )}
        </div>
      );
    } else {
      const combo = orderData.combo;
      const progressStats = combo.progress_stats || {};
      const status = combo.combo_status || 'N/A';
      const statusColor = getStatusColor(status);
      
      // Get the first combo item's image if available
      let primaryImage = null;
      if (combo.combo_items && combo.combo_items.length > 0) {
        const firstItem = combo.combo_items[0];
        if (firstItem.group_wish && firstItem.group_wish.image_url) {
          primaryImage = firstItem.group_wish.image_url;
        }
      }
      
      const progress = progressStats.progress ? progressStats.progress / 100 : 0;
      
      return (
        <div key={`combo-${combo.id}`} style={wishItemStyle} onClick={() => handleOrderClick(combo.id, 'combo')}>
          <div style={orderTypeChipStyle('combo')}>Combo</div>
          <h3 style={wishTitleStyle}>{combo.title}</h3>
          <p style={{...wishStatusStyle, color: statusColor}}>
            Status: <span style={{fontWeight: 'bold'}}>{status}</span>
          </p>
          <p style={wishInfoStyle}>Min Combo Size: {combo.min_combo_size}</p>
          <p style={wishInfoStyle}>Min Order Qty: {combo.min_combo_order_qty}</p>
          <p style={wishInfoStyle}>Expiry: {combo.expires_at ? new Date(combo.expires_at).toLocaleDateString() : 'N/A'}</p>
          <p style={wishInfoStyle}>Bundle Rate: ${parseFloat(combo.bundle_rate).toFixed(2)}</p>
          
          <div style={wishInfoStyle}>
            <p style={{margin: '5px 0'}}>Progress: {progressStats.progress ? progressStats.progress.toFixed(2) : 0}%</p>
            {renderProgressBar(progress)}
          </div>
          
          <p style={wishInfoStyle}>Total Joined: {progressStats.totalJoined || 0}</p>
          
          {primaryImage && (
            <div style={imageContainerStyle}>
              <img src={primaryImage} alt={combo.title} style={imageStyle} />
            </div>
          )}
        </div>
      );
    }
  };

  // Render pagination controls
  const renderPagination = () => {
    const pages = [];
    const maxVisiblePages = 5;
    
    let startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
    let endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);
    
    // Adjust if we're at the edges
    if (endPage - startPage + 1 < maxVisiblePages) {
      startPage = Math.max(1, endPage - maxVisiblePages + 1);
    }
    
    // Add first page button
    if (startPage > 1) {
      pages.push(
        <button 
          key="first" 
          onClick={() => setCurrentPage(1)}
          style={paginationButtonStyle}
          disabled={currentPage === 1}
        >
          &laquo;
        </button>
      );
    }
    
    // Add page buttons
    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <button 
          key={i} 
          onClick={() => setCurrentPage(i)}
          style={{
            ...paginationButtonStyle,
            ...(currentPage === i ? activePaginationButtonStyle : {})
          }}
        >
          {i}
        </button>
      );
    }
    
    // Add last page button
    if (endPage < totalPages) {
      pages.push(
        <button 
          key="last" 
          onClick={() => setCurrentPage(totalPages)}
          style={paginationButtonStyle}
          disabled={currentPage === totalPages}
        >
          &raquo;
        </button>
      );
    }
    
    return (
      <div style={paginationContainerStyle}>
        {pages}
      </div>
    );
  };
  
  // Render search form
  const renderSearchForm = () => {
    return (
      <form onSubmit={handleSearch} style={searchFormStyle}>
        <input
          type="text"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Search orders..."
          style={searchInputStyle}
        />
        <button type="submit" style={searchButtonStyle}>Search</button>
        {searchTerm && (
          <button type="button" onClick={clearSearch} style={clearSearchButtonStyle}>
            Clear
          </button>
        )}
      </form>
    );
  };

  return (
    <>
      <TopMenuBar 
        showHomeButton={true}  
        title="Order Management" 
        showSearch={false} 
        showAddress={false} 
        showMenuButton={true} 
      />      
      <div style={containerStyle}>
        <div style={contentStyle}>
          {renderTabButtons()}
          {renderSearchForm()}
          
          {isLoading ? (
            <div style={loadingStyle}>Loading orders...</div>
          ) : errorMessage ? (
            <div style={errorStyle}>{errorMessage}</div>
          ) : (
            <>
              <div style={listContainerStyle}>
                {displayedOrders.length > 0 ? (
                  displayedOrders.map(orderData => renderOrderItem(orderData))
                ) : (
                  <div style={noOrdersStyle}>
                    No {activeTab === 'all' ? '' : activeTab === 'group' ? 'group wish' : 'combo'} orders found.
                  </div>
                )}
              </div>
              
              {displayedOrders.length > 0 && (
                <div style={paginationInfoStyle}>
                  Showing {displayedOrders.length} of {
                    activeTab === 'all' 
                      ? totalCount 
                      : allOrders.filter(order => 
                          activeTab === 'group' 
                            ? order.order_type === 'groupwish' 
                            : order.order_type === 'combo'
                        ).length
                  } orders
                </div>
              )}
              
              {totalPages > 1 && renderPagination()}
            </>
          )}
        </div>
      </div>
    </>
  );
};
 
const containerStyle = {
  fontFamily: 'Arial, sans-serif',
  backgroundColor: style.Color.colorLightBeige,
  minHeight: '100vh',
  padding: '20px',
  paddingTop: '80px',
};

const contentStyle = {
  padding: '20px',
};

const loadingStyle = {
  textAlign: 'center',
  fontSize: '18px',
  marginTop: '20px',
};

const errorStyle = {
  color: 'red',
  textAlign: 'center',
  fontSize: '18px',
  marginTop: '20px',
};

const noOrdersStyle = {
  textAlign: 'center',
  fontSize: '16px',
  color: style.Color.colorDarkGrey,
  padding: '30px',
  gridColumn: '1 / -1',  
};

const listContainerStyle = {
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fill, minmax(350px, 1fr))',
  gap: '20px',
  marginTop: '20px',
};

const wishItemStyle = {
  backgroundColor: style.Color.colorWhite,
  borderRadius: '8px',
  padding: '15px',
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  cursor: 'pointer',
  position: 'relative',
  minHeight: '200px',
  display: 'flex',
  flexDirection: 'column',
  transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
  border: `1px solid ${style.Color.colorLightGrey}`,
};

const tabButtonStyle = {
  padding: '10px 20px',
  backgroundColor: style.Color.colorWhite,
  border: `1px solid ${style.Color.colorPeacockBlue}`,
  borderRadius: '8px',
  cursor: 'pointer',
  color: style.Color.colorPeacockBlue,
  fontWeight: '500',
  transition: 'all 0.2s ease-in-out',
};

const activeTabStyle = {
  backgroundColor: style.Color.colorPeacockBlue,
  color: style.Color.colorWhite,
  fontWeight: 'bold',
};

const orderTypeChipStyle = (type) => ({
  position: 'absolute',
  top: '10px',
  right: '10px',
  padding: '4px 10px',
  borderRadius: '8',
  fontSize: '12px',
  fontWeight: 'bold',
  backgroundColor: type === 'groupwish' ? style.Color.colorTeal : style.Color.colorPeacockBlue,
  color: style.Color.colorWhite,
}); 

const wishTitleStyle = {
  fontSize: '18px',
  fontWeight: 'bold',
  marginBottom: '10px',
  color: style.Color.colorPeacockBlue,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

const wishCategoryStyle = {
  fontSize: '14px',
  color: style.Color.colorDarkGrey,
  marginLeft: '15px',
  marginBottom: '5px',
};

const wishStatusStyle = {
  fontSize: '14px',
  color: style.Color.colorDarkGrey,
  marginBottom: '5px',
  marginLeft: '15px',
};

const wishInfoStyle = {
  fontSize: '14px',
  color: style.Color.colorDarkGrey,
  marginBottom: '5px',
  marginLeft: '15px',
};

const tabsContainerStyle = {
  display: 'flex',
  justifyContent: 'center',
  gap: '10px',
  marginBottom: '20px',
};

const imageContainerStyle = {
  marginTop: 'auto',
  textAlign: 'center',
  padding: '10px 0',
};

const imageStyle = {
  maxWidth: '100%',
  maxHeight: '120px',
  objectFit: 'contain',
  borderRadius: '8px',
};

const progressBarContainerStyle = {
  width: '100%',
  height: '8px',
  backgroundColor: '#e0e0e0',
  borderRadius: '8px',
  overflow: 'hidden',
  marginTop: '5px',
};

const progressBarStyle = {
  height: '100%',
  transition: 'width 0.3s ease-in-out',
};

const paginationInfoStyle = {
  textAlign: 'center',
  marginTop: '20px',
  color: style.Color.colorDarkGrey,
  fontSize: '14px',
};

const paginationContainerStyle = {
  display: 'flex',
  justifyContent: 'center',
  gap: '5px',
  marginTop: '20px',
};

const paginationButtonStyle = {
  padding: '8px 12px',
  border: `1px solid ${style.Color.colorPeacockBlue}`,
  backgroundColor: style.Color.colorWhite,
  color: style.Color.colorPeacockBlue,
  borderRadius: '8px',
  cursor: 'pointer',
  fontWeight: '500',
};

const activePaginationButtonStyle = {
  backgroundColor: style.Color.colorPeacockBlue,
  color: style.Color.colorWhite,
  fontWeight: 'bold',
};

const searchFormStyle = {
  display: 'flex',
  justifyContent: 'center',
  gap: '10px',
  marginBottom: '20px',
};

const searchInputStyle = {
  padding: '10px 15px',
  border: `1px solid ${style.Color.colorLightGrey}`,
  borderRadius: '8px',
  width: '300px',
  fontSize: '14px',
};

const searchButtonStyle = {
  padding: '10px 15px',
  backgroundColor: style.Color.colorPeacockBlue,
  color: style.Color.colorWhite,
  border: 'none',
  borderRadius: '8px',
  cursor: 'pointer',
  fontWeight: '500',
};

const clearSearchButtonStyle = {
  padding: '10px 15px',
  backgroundColor: style.Color.colorLightGrey,
  color: style.Color.colorDarkGrey,
  border: 'none',
  borderRadius: '8px',
  cursor: 'pointer',
};

export default OrderManagement;