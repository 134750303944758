 import * as style from "../../GlobalStyles";

export const orderTrackingStyles = {
    orderTrackingContainer: {
      width: '100%',
      margin: '4% 0',
      padding: '1rem',
      backgroundColor: style.Color.colorLightBeige,
      radius: '8px',
    },
    
    ordersContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: '1rem',
      marginTop: '1.5rem',
      radius: '8px',
      backgroundColor: style.Color.colorWhite,
      color: style.Color.colorPurple,
    },
  
    errorMessage: {
      padding: '1rem',
      backgroundColor: '#FEE2E2',
      color: '#DC2626',
      borderRadius: '0.5rem',
    },
  
    loadingContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '2rem',
      color: '#4B5563',
    },
  
    emptyOrdersContainer: {
      textAlign: 'center',
      padding: '2rem',
      backgroundColor: '#FFFFFF',
      borderRadius: '0.5rem',
      boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1)',
    },
  
    emptyOrdersIcon: {
      margin: '0 auto 1rem auto',
      color: '#9CA3AF',
    },
  
    emptyOrdersText: {
      color: '#4B5563',
      margin: 0,
    },
  
    orderCard: {
      backgroundColor: '#FFFFFF',
      borderRadius: '0.5rem',
      boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1)',
      overflow: 'hidden',
    },
  
    orderHeader: {
      padding: '1rem',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      cursor: 'pointer',
      transition: 'background-color 0.2s',
      '&:hover': {
        backgroundColor: style.Color.colorPurple,
      },
    },
  
    orderHeaderExpanded: {
      backgroundColor: '#F3F4F6',
    },
  
    orderHeaderContent: {
      display: 'flex',
      flexDirection: 'column',
      gap: '0.25rem',
    },
  
    orderTitle: {
      margin: 0,
      fontWeight: 500,
    },
  
    orderHeaderIcon: {
      color: '#6B7280',
    },
  
    shipmentContainer: {
      padding: '1rem',
      borderTop: '1px solid #E5E7EB',
    },
  
    shipmentHeader: {
      marginBottom: '1rem',
      paddingBottom: '0.5rem',
      borderBottom: '1px solid #E5E7EB',
    },
  
    shipmentHeaderContent: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  
    shipmentHeaderInfo: {
      display: 'flex',
      alignItems: 'center',
      gap: '0.5rem',
    },
  
    shipmentIcon: {
      color: '#3B82F6',
    },
  
    shipmentTitle: {
      fontWeight: 500,
    },
  
    shipmentCarrier: {
      fontSize: '0.875rem',
      color: '#6B7280',
    },
  
    trackingContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: '1rem',
    },
  
    statusContainer: {
      marginBottom: '1rem',
    },
  
    statusHeader: {
      display: 'flex',
      alignItems: 'center',
      gap: '0.5rem',
      marginBottom: '0.5rem',
    },
  
    statusIcon: {
      color: '#9CA3AF',
    },
    
    productName: {
        fontSize: '14px',
        color: '#666',
        marginTop: '4px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
      },
  
    statusTitle: {
      fontSize: '0.875rem',
      fontWeight: 500,
    },
  
    statusContent: {
      marginLeft: '1.5rem',
      padding: '0.75rem',
      backgroundColor: '#EFF6FF',
      borderRadius: '0.5rem',
    },
  
    statusDescription: {
      margin: 0,
      fontSize: '0.875rem',
      color: '#1E40AF',
    },
  
    statusTimestamp: {
      margin: '0.25rem 0 0 0',
      fontSize: '0.75rem',
      color: '#2563EB',
    },
  
    historyContainer: {
      marginTop: '1rem',
    },
  
    historyHeader: {
      display: 'flex',
      alignItems: 'center',
      gap: '0.5rem',
      marginBottom: '0.5rem',
    },
  
    historyIcon: {
      color: '#9CA3AF',
    },
  
    historyTitle: {
      fontSize: '0.875rem',
      fontWeight: 500,
    },
  
    historyContent: {
      marginLeft: '1.5rem',
      display: 'flex',
      flexDirection: 'column',
      gap: '0.75rem',
    },
  
    historyEvent: {
      padding: '0.75rem',
      backgroundColor: '#F3F4F6',
      borderRadius: '0.5rem',
    },
  
    eventDescription: {
      margin: 0,
      fontSize: '0.875rem',
      color: '#1F2937',
    },
  
    eventDetails: {
      margin: '0.25rem 0 0 0',
      fontSize: '0.75rem',
      color: '#4B5563',
    },
  
    invoiceContainer: {
      marginTop: '1rem',
    },
  
    invoiceHeader: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      cursor: 'pointer',
    },
  
    invoiceHeaderContent: {
      display: 'flex',
      alignItems: 'center',
      gap: '0.5rem',
    },
  
    invoiceIcon: {
      color: '#9CA3AF',
    },
  
    invoiceTitle: {
      fontSize: '0.875rem',
      fontWeight: 500,
    },
  
    invoiceContent: {
      marginTop: '0.5rem',
      marginLeft: '1.5rem',
      padding: '1rem',
      backgroundColor: '#F3F4F6',
      borderRadius: '0.5rem',
      display: 'flex',
      flexDirection: 'column',
      gap: '0.75rem',
    },
  
    invoiceRow: {
      display: 'flex',
      justifyContent: 'space-between',
      fontSize: '0.875rem',
    },
  
    invoiceLabel: {
      color: '#4B5563',
    },
  
    invoiceValue: {
      fontWeight: 500,
    },
  
    invoiceItems: {
      borderTop: '1px solid #D1D5DB',
      paddingTop: '0.5rem',
    },
  
    invoiceItem: {
      marginBottom: '0.5rem',
    },
  
    itemRow: {
      display: 'flex',
      justifyContent: 'space-between',
      fontSize: '0.875rem',
    },
  
    itemDescription: {
      color: '#1F2937',
    },
  
    itemTotal: {
      fontWeight: 500,
    },
  
    itemDetails: {
      marginLeft: '0.5rem',
      fontSize: '0.75rem',
      color: '#6B7280',
    },
  
    invoiceSummary: {
      borderTop: '1px solid #D1D5DB',
      paddingTop: '0.5rem',
      display: 'flex',
      flexDirection: 'column',
      gap: '0.25rem',
    },
  
    summaryRow: {
      display: 'flex',
      justifyContent: 'space-between',
      fontSize: '0.875rem',
    },
  
    summaryLabel: {
      color: '#4B5563',
    },
  
    summaryValue: {
      color: '#1F2937',
    },
  
    summaryTotal: {
      marginTop: '0.25rem',
      fontWeight: 500,
    },
  
    summaryTotalLabel: {
      color: '#1F2937',
    },
  
    summaryTotalValue: {
      color: '#1F2937',
    },
  };
  
  // Helper function to combine styles
  export const combineStyles = (...styles) => {
    return styles.reduce((acc, style) => ({
      ...acc,
      ...style,
    }), {});
  };