import React, { useState } from 'react';
import { ArrowLeft } from 'lucide-react';
import { getApi, postApi, buildUrl } from "../Common/BackendCalls/apiUtils";
import { useAuth } from '../Common/InitialGears/AuthContext';
import Config from "../../config";

const ReferralRequestPanel = ({ onClose }) => {
  const { queryLoginParam } = useAuth();
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
  });
  const [otp, setOtp] = useState(['', '', '', '', '', '']);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [verificationError, setVerificationError] = useState('');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const requestVerificationCode = async (phone) => {
    const requestRefURL = buildUrl(`verificationCode`, queryLoginParam);
    const requestBody = {
      phone: phone,
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email
    };
    
    try {
      const response = await postApi(requestRefURL, requestBody); 
      if (!response.ok) { 
        let errorMessage=response.data.error ;
        
        if (response.status === 403 || response.status === 429) {
          throw new Error('Too many attempts. Please re-try after 30 mins');
        } else if (errorMessage === "Pending referral request exists. Please contact your inviter.") {
          throw new Error('Your previous request is still under review, once approved you will be notified');
        } else { 
          throw new Error(response.data?.error || 'Failed to send verification code');
        }
      }
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const handleOTPChange = (index, value) => {
    if (value.length > 1) return;
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    if (value && index < 5) {
      const nextInput = document.getElementById(`otp-${index + 1}`);
      if (nextInput) nextInput.focus();
    }
  };

  const handleSubmitDetails = async (e) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);
    
    try {
      if (!formData.firstName || !formData.lastName || !formData.phone || !formData.email) {
        setError('Please fill in all fields');
        return;
      }
  
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(formData.email)) {
        setError('Please enter a valid email address');
        return;
      }
  
      const phoneRegex = /^\d{10}$/;
      if (!phoneRegex.test(formData.phone.replace(/\D/g, ''))) {
        setError('Please enter a valid 10-digit phone number');
        return;
      }
  
      let responseReceived=await requestVerificationCode(formData.phone);      
      setStep(2);
    } catch (error) { 
      setError(error.message || 'Failed to send verification code');
    } finally {
      setIsLoading(false);
    }
  };

  const verifyOTP = async (phone, otpValue) => {
    const queryParams = new URLSearchParams({
      verifyVerificationCode: 'true',
      phone: phone,
      otp: otpValue,
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email
    }).toString();
    
    const verifyEndpoint = `${Config.apiUrl}verifyVerificationCode?${queryParams}`;
    
    try {
      const response = await fetch(verifyEndpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: null,
        mode: 'cors',
        credentials: 'omit'
      });
  
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.error || 'Verification failed');
      }
      return data;
    } catch (error) {
      throw error;
    }
  };

  const handleVerifyOTP = async (e) => {
    e.preventDefault();
    setError('');
    setVerificationError('');
    setIsLoading(true);

    const otpValue = otp.join('');
    if (otpValue.length !== 6) {
      setError('Please enter a valid 6-digit OTP');
      setIsLoading(false);
      return;
    }

    try {
      const response = await verifyOTP(formData.phone, otpValue);
      
      if (response.status === 'SUCCESS') {
        setStep(3);
      } else if (response.status === 'INVALID_OTP') {
        setVerificationError('Invalid verification code. Please try again.');
      } else if (response.status === 'EXPIRED_OTP') {
        setVerificationError('Verification code has expired. Please request a new one.');
      } else {
        setVerificationError('Verification failed. Please try again later.');
      }      
    } catch (error) {      
        setVerificationError('Failed to verify code. Please try again.');      
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="w-full h-full flex items-center justify-center px-4 py-28">
      <div className="bg-white rounded-xl shadow-lg p-8 w-full max-w-md relative">
        {/* Header */}
        <div className="flex justify-between items-center mb-8">
          <h2 className="text-2xl font-bold text-gray-500">
            {step === 1 && "Request Referral"}
            {step === 2 && "Verify Code"}
            {step === 3 && "Thank You!"}
          </h2>
          <button onClick={onClose} className="p-2 text-gray-500 hover:text-gray-700 hover:bg-gray-100 rounded-full transition-colors" >
            <ArrowLeft className="w-5 h-5" />
          </button>
        </div>

        {/* Error Messages */}
        {(error || verificationError) && (
          <div className="bg-red-50 border border-red-200 text-red-600 text-sm rounded-lg p-3 mb-4">
            {error || verificationError}
          </div>
        )}

        {step === 1 && (
          <form onSubmit={handleSubmitDetails} className="space-y-6">
            <div className="space-y-1">
              <label className="block text-sm font-medium text-gray-700">First Name</label>
              <input
                type="text"
                name="firstName"
                value={formData.firstName}
                onChange={handleInputChange}
                className="w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-900 focus:outline-none focus:border-amber-500 focus:ring-1 focus:ring-amber-500 transition-colors"
                placeholder="Enter your first name"
                disabled={isLoading}
              />
            </div>

            <div className="space-y-1">
              <label className="block text-sm font-medium text-gray-700">Last Name</label>
              <input
                type="text"
                name="lastName"
                value={formData.lastName}
                onChange={handleInputChange}
                className="w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-900 focus:outline-none focus:border-amber-500 focus:ring-1 focus:ring-amber-500 transition-colors"
                placeholder="Enter your last name"
                disabled={isLoading}
              />
            </div>

            <div className="space-y-1">
              <label className="block text-sm font-medium text-gray-700">Phone Number</label>
              <input
                type="tel"
                name="phone"
                value={formData.phone}
                onChange={handleInputChange}
                className="w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-900 focus:outline-none focus:border-amber-500 focus:ring-1 focus:ring-amber-500 transition-colors"
                placeholder="Enter your phone number"
                disabled={isLoading}
              />
            </div>

            <div className="space-y-1">
              <label className="block text-sm font-medium text-gray-700">Email</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                className="w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-900 focus:outline-none focus:border-amber-500 focus:ring-1 focus:ring-amber-500 transition-colors"
                placeholder="Enter your email"
                disabled={isLoading}
              />
            </div>

            <button
              type="submit"
              disabled={isLoading} 
              className="w-full bg-gradient-to-r bg-gradient-to-r from-[#80666a] via-rose-900 to-[#80666a] text-white py-3 rounded-lg font-medium hover:opacity-90 transition-colors disabled:opacity-70 disabled:cursor-not-allowed"
            >
              {isLoading ? 'Sending...' : 'Request Verification Code'}
            </button>
          </form>
        )}

        {step === 2 && (
          <form onSubmit={handleVerifyOTP} className="space-y-6">
            <p className="text-gray-600 text-sm text-center mb-4">
              Enter the verification code sent to your phone
            </p>
            <div className="flex justify-between gap-2 mb-6">
              {otp.map((digit, index) => (
                <input
                  key={index}
                  id={`otp-${index}`}
                  type="text"
                  maxLength="1"
                  value={digit}
                  onChange={(e) => handleOTPChange(index, e.target.value)}
                  className="w-12 h-12 text-center bg-white border border-gray-300 rounded-lg text-gray-900 focus:outline-none focus:border-amber-500 focus:ring-1 focus:ring-amber-500 transition-colors disabled:opacity-70"
                  disabled={isLoading}
                />
              ))}
            </div>

            <button type="submit" disabled={isLoading} className="w-full bg-gradient-to-r bg-gradient-to-r from-[#80666a] via-rose-900 to-[#80666a] text-white py-3 rounded-lg font-medium hover:opacity-90 transition-colors disabled:opacity-70 disabled:cursor-not-allowed">
              {isLoading ? 'Verifying...' : 'Verify Code'}
            </button>
          </form>
        )}

        {step === 3 && (
          <div className="text-center">
            <div className="text-gray-800 text-lg mb-4">
              Thank you for verifying your contact information!
            </div>
            <p className="text-gray-600">
              Your referral request has been received and is being processed. 
              We'll notify you shortly with your referral code via email and SMS.
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default ReferralRequestPanel;