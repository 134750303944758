import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getApi, postApi, buildUrl } from "../../Common/BackendCalls/apiUtils";
import { useAuth } from '../../Common/InitialGears/AuthContext';
import { TopMenuBar } from '../../Buyers/DashbordComponents/TopMenuBar';
import { Search } from 'lucide-react';
import * as style from '../../../GlobalStyles';

const ReferralRequested = () => {
  const navigate = useNavigate();
  const { queryLoginParam } = useAuth();
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [filters, setFilters] = useState({
    status: '',
    dateFrom: '',
    dateTo: '',
    search: ''
  });

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      fetchUsers();
    }, 300);
    return () => clearTimeout(timeoutId);
  }, [filters]); 

  const fetchUsers = async () => {
    setIsLoading(true);
    setErrorMessage('');
    try {
      const queryParams = new URLSearchParams({
        status: filters.status || undefined,
        date_from: filters.dateFrom || undefined,
        date_to: filters.dateTo || undefined,
        search: filters.search || undefined
      }).toString();
      const url = buildUrl('referralsRequested', queryLoginParam);
      const response = await postApi(url, {
        status: filters.status || "All",
        date_from: filters.dateFrom || undefined,
        date_to: filters.dateTo || undefined
      });

      if (response && response.data) {
        setUsers(response.data.results);
        if (response.data.results.length === 0) {
          setErrorMessage('No verification requests found.');
        }
      } else {
        setErrorMessage('Failed to fetch verification requests');
      }
    } catch (error) {
      setErrorMessage('An error occurred while fetching verification requests');
    } finally {
      setIsLoading(false);
    }
  };

  const handleApprove = async (userId) => {
    try {
      const url = buildUrl('approveReferralsRequested', queryLoginParam);
      const response = await postApi(url, { id: userId });
      if (response && response.data) {
        fetchUsers();
      }
    } catch (error) {
      setErrorMessage('Failed to approve invite');
    }
  };

  const renderFilters = () => (
    <div style={filterContainerStyle}>
      <div style={filterItemStyle}>
        <label style={labelStyle}>Status:</label>
        <select 
          style={selectStyle} 
          value={filters.status} 
          onChange={(e) => setFilters({...filters, status: e.target.value})}
        >
          <option value="">All</option>
          <option value="SELF_REFERRAL_REQUESTED">Self Referral Requested</option>
          <option value="VERIFIED">Verified</option>
          <option value="APPROVED">Approved</option>
        </select>
      </div>
      
      <div style={filterItemStyle}>
        <label style={labelStyle}>From:</label>
        <input 
          type="date" 
          style={inputStyle} 
          value={filters.dateFrom} 
          onChange={(e) => setFilters({...filters, dateFrom: e.target.value})}
        />
      </div>
      
      <div style={filterItemStyle}>
        <label style={labelStyle}>To:</label>
        <input 
          type="date" 
          style={inputStyle} 
          value={filters.dateTo} 
          onChange={(e) => setFilters({...filters, dateTo: e.target.value})}
        />
      </div>
      
      <div style={searchContainerStyle}>
        <div style={searchInputContainerStyle}>
          <Search style={searchIconStyle} />
          <input 
            type="text" 
            placeholder="Search by name, email, or phone..." 
            style={searchInputStyle}
            value={filters.search} 
            onChange={(e) => setFilters({...filters, search: e.target.value})}
          />
        </div>
      </div>
    </div>
  );

  const renderUserList = () => {
    return users.map((user) => (
      <div key={user.id} style={userCardStyle}>
        <div style={userHeaderStyle}>
          <div>
            <h3 style={userNameStyle}>
              {user.first_name} {user.last_name}
            </h3>
            <p style={userInfoStyle}>{user.email}</p>
            <p style={userInfoStyle}>{user.phone}</p>
          </div>
          {['SELF_REFERRAL_REQUESTED'].includes(user.invite.status) && (
            <button 
              onClick={() => handleApprove(user.id)} 
              style={approveButtonStyle}
            >
              Approve
            </button>
          )}
        </div>
        
        <div style={userDetailsContainerStyle}>
          <div style={detailsColumnStyle}>
            <p style={detailItemStyle}>
              <span style={detailLabelStyle}>Verification Status:</span>{' '}
              {user.verification_status.is_verified ? 'Verified' : 'Not Verified'}
            </p>
            <p style={detailItemStyle}>
              <span style={detailLabelStyle}>Verification Date:</span>{' '}
              {user.verification_status.verification_date 
                ? new Date(user.verification_status.verification_date).toLocaleDateString() 
                : 'N/A'}
            </p>
            <p style={detailItemStyle}>
              <span style={detailLabelStyle}>Attempts:</span>{' '}
              {user.verification_status.verification_attempts}
            </p>
          </div>
          <div style={detailsColumnStyle}>
            <p style={detailItemStyle}>
              <span style={detailLabelStyle}>Invite Status:</span>{' '}
              {user.invite.status}
            </p>
            <p style={detailItemStyle}>
              <span style={detailLabelStyle}>Requested Date:</span>{' '}
              {new Date(user.invite.created_at).toLocaleDateString()}
            </p>
            <p style={detailItemStyle}>
              <span style={detailLabelStyle}>Invite Code:</span>{' '}
              {user.invite.invite_code || 'Not Generated'}
            </p>
          </div>
        </div>
      </div>
    ));
  };

  return (
    <>
    <TopMenuBar 
    showHomeButton={true} 
    title="User Verification Management" 
    showSearch={false} 
    showAddress={false} 
    showMenuButton={true} 
  />
    <div style={containerStyle}>
     
      
      <div style={contentStyle}>
        {renderFilters()}
        
        {isLoading ? (
          <div style={loadingContainerStyle}>
            <div style={loaderStyle}></div>
          </div>
        ) : errorMessage ? (
          <div style={errorMessageStyle}>{errorMessage}</div>
        ) : (
          <div style={userListContainerStyle}>
            {renderUserList()}
          </div>
        )}
      </div>
    </div>
    </>
  );
};

// Styles
const containerStyle = {
  minHeight: '100vh',
  backgroundColor: style.Color.colorLightBeige,
  display: 'flex',
  flexDirection: 'column',
  marginTop: '6%',
};

const contentStyle = {
  padding: '20px',
  marginTop: '10%',
  width: '100%',
  maxWidth: '1600px',
  margin: '0 auto',
};

const filterContainerStyle = {
  backgroundColor: style.Color.colorWhite,
  padding: '20px',
  borderRadius: '8px',
  marginBottom: '20px',
  display: 'flex',
  flexWrap: 'wrap',
  gap: '20px',
  alignItems: 'center',
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
};

const filterItemStyle = {
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
};

const labelStyle = {
  fontSize: '14px',
  color: style.Color.colorDarkGrey,
  fontWeight: '500',
};

const inputStyle = {
  padding: '8px 12px',
  border: '1px solid #bdc3c7',
  borderRadius: '4px',
  fontSize: '14px',
};

const selectStyle = {
  ...inputStyle,
  minWidth: '120px',
};

const searchContainerStyle = {
  flex: 1,
  minWidth: '300px',
};

const searchInputContainerStyle = {
  position: 'relative',
  width: '100%',
};

const searchIconStyle = {
  position: 'absolute',
  left: '12px',
  top: '50%',
  transform: 'translateY(-50%)',
  color: '#94a3b8',
  width: '20px',
  height: '20px',
};

const searchInputStyle = {
  ...inputStyle,
  width: '100%',
  paddingLeft: '40px',
};

const userCardStyle = {
  backgroundColor: style.Color.colorWhite,
  borderRadius: '8px',
  padding: '20px',
  marginBottom: '16px',
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  transition: 'box-shadow 0.3s ease',
};

const userHeaderStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  marginBottom: '16px',
};

const userNameStyle = {
  fontSize: '18px',
  fontWeight: 'bold',
  color: style.Color.colorRoyalBlueText,
  marginBottom: '8px',
};

const userInfoStyle = {
  fontSize: '14px',
  color: style.Color.colorDarkGrey,
  marginLeft: '24px',
  marginBottom: '4px',
};

const userDetailsContainerStyle = {
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  gap: '20px',
  marginLeft: '24px',
};

const detailsColumnStyle = {
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
};

const detailItemStyle = {
  fontSize: '14px',
  color: style.Color.colorDarkGrey,
};

const detailLabelStyle = {
  fontWeight: '600',
};

const approveButtonStyle = {
  backgroundColor: style.Color.colorMintGreen,
  color: style.Color.colorWhite,
  padding: '8px 16px',
  borderRadius: '4px',
  border: 'none',
  fontSize: '14px',
  fontWeight: '500',
  cursor: 'pointer',
  transition: 'background-color 0.3s ease',
};

const loadingContainerStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '200px',
};

const loaderStyle = {
  border: '4px solid #f3f3f3',
  borderTop: `4px solid ${style.Color.colorMintGreen}`,
  borderRadius: '50%',
  width: '40px',
  height: '40px',
  animation: 'spin 1s linear infinite',
};

const errorMessageStyle = {
  textAlign: 'center',
  color: '#e74c3c',
  fontSize: '16px',
  marginTop: '20px',
};

const userListContainerStyle = {
  marginTop: '20px',
};

export default ReferralRequested;