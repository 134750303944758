import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  Calculator,   
  Trash2, 
  Plus, 
  DollarSign, 
  PercentIcon,  
  Box,
  Save,
  BarChart3
} from 'lucide-react';
import PackagingOptimizer from '../../Common/PackagingShipping/PackagingOptimizer';
import { TopMenuBar } from '../../Buyers/DashbordComponents/TopMenuBar';

const ProductEvaluationTool = () => {   
  const navigate = useNavigate();
  const [products, setProducts] = useState([{
    id: Date.now(),
    name: '',
    moq: '',
    totalPrice: '',
    shippingCost: '',
    mrp: '',
    commissionType: 'percentage',
    commissionValue: '',
    dimensions: {
      length: '',
      width: '',
      height: '',
      weight: ''
    },
    isFragile: false,
    hasBatteries: false,
    isHazardous: false,
    storageRequirements: ''
  }]);

  // Units state
  const [units, setUnits] = useState({
    dimension: 'inch',
    weight: 'lb'
  });
  const [showPackagingOptimizer, setShowPackagingOptimizer] = useState(false);
  const [savedResults, setSavedResults] = useState([]);
  const [activeTab, setActiveTab] = useState('calculator');
  const [saveSuccess, setSaveSuccess] = useState(false);

  // Add a new product input row
  const handleAddProduct = () => {
    setProducts([...products, {
      id: Date.now(),
      name: '',
      moq: '',
      totalPrice: '',
      shippingCost: '',
      mrp: '',
      commissionType: 'percentage',
      commissionValue: '',
      dimensions: {
        length: '',
        width: '',
        height: '',
        weight: ''
      },
      isFragile: false,
      hasBatteries: false,
      isHazardous: false,
      storageRequirements: ''
    }]);
  };

  // Remove a product input row
  const handleRemoveProduct = (id) => {
    if (products.length > 1) {
      setProducts(products.filter(product => product.id !== id));
    }
  };

  // Handle product input changes
  const handleProductChange = (id, field, value) => {
    setProducts(products.map(product => {
      if (product.id === id) {
        if (field.includes('.')) {
          const [parent, child] = field.split('.');
          return {
            ...product,
            [parent]: {
              ...product[parent],
              [child]: value
            }
          };
        }
        return { ...product, [field]: value };
      }
      return product;
    }));
  };

  // Calculate results for each product and overall
  const calculateResults = () => {
    return products.map(product => {
      // Parse numeric inputs
      const moq = parseInt(product.moq) || 0;
      const totalPrice = parseFloat(product.totalPrice) || 0;
      const shippingCost = parseFloat(product.shippingCost) || 0;
      const commissionValue = parseFloat(product.commissionValue) || 0;
      const mrp = parseFloat(product.mrp) || 0;

      // Calculate costs per item
      const productCostPerItem = moq > 0 ? totalPrice / moq : 0;
      const shippingPerItem = moq > 0 ? shippingCost / moq : 0;

      // Calculate commission
      let commission = 0;
      if (product.commissionType === 'percentage') {
        commission = (productCostPerItem + shippingPerItem) * (commissionValue / 100);
      } else {
        commission = commissionValue;
      }

      // Calculate final costs and savings
      const totalCostPerItem = productCostPerItem + shippingPerItem + commission;
      
      // Use MRP if provided, otherwise estimate retail price (30% markup)
      const retailPrice = mrp > 0 ? mrp : totalCostPerItem * 1.3;
      
      // Calculate savings
      const savingsPerItem = retailPrice - totalCostPerItem;
      const savingsPercentage = retailPrice > 0 ? (savingsPerItem / retailPrice) * 100 : 0;

      return {
        ...product,
        results: {
          productCostPerItem,
          shippingPerItem,
          commission,
          totalCostPerItem,
          retailPrice,
          savingsPerItem,
          savingsPercentage,
          isMrpProvided: mrp > 0
        }
      };
    });
  };

  // Convert products to format needed for PackagingOptimizer
  const convertToOptimizerFormat = () => {
    return products.map(product => ({
      group_wish_id: product.id.toString(),
      title: product.name,
      product_specifications: {
        length: parseFloat(product.dimensions.length) || 0,
        width: parseFloat(product.dimensions.width) || 0,
        height: parseFloat(product.dimensions.height) || 0,
        weight: parseFloat(product.dimensions.weight) || 0,
        dimension_unit: units.dimension.toUpperCase(),
        weight_unit: units.weight.toUpperCase(),
        is_fragile: product.isFragile,
        has_batteries: product.hasBatteries,
        is_hazardous: product.isHazardous,
        storage_requirements: product.storageRequirements
      }
    }));
  };

  // Handle saving results
  const handleSaveResults = () => {
    const results = calculateResults();
    const timestamp = new Date().toLocaleString();
    
    const newSavedResult = {
      id: Date.now(),
      timestamp,
      products: results,
      totals: calculateTotals(results)
    };
    
    setSavedResults([...savedResults, newSavedResult]);
    setSaveSuccess(true);
    
    setTimeout(() => {
      setSaveSuccess(false);
    }, 3000);
  };

  // Calculate overall totals
  const calculateTotals = (calculatedProducts) => {
    return calculatedProducts.reduce((totals, product) => {
      const moq = parseInt(product.moq) || 0;
      const results = product.results;
      
      return {
        totalMOQ: totals.totalMOQ + moq,
        totalProductCost: totals.totalProductCost + (results.productCostPerItem * moq),
        totalShippingCost: totals.totalShippingCost + (results.shippingPerItem * moq),
        totalCommission: totals.totalCommission + (results.commission * moq),
        totalFinalCost: totals.totalFinalCost + (results.totalCostPerItem * moq),
        totalSavings: totals.totalSavings + (results.savingsPerItem * moq),
        averageSavingsPercentage: totals.totalProducts ? 
          ((totals.averageSavingsPercentage * totals.totalProducts) + results.savingsPercentage) / (totals.totalProducts + 1) :
          results.savingsPercentage,
        totalProducts: totals.totalProducts + 1
      };
    }, {
      totalMOQ: 0,
      totalProductCost: 0,
      totalShippingCost: 0,
      totalCommission: 0,
      totalFinalCost: 0,
      totalSavings: 0,
      averageSavingsPercentage: 0,
      totalProducts: 0
    });
  };

  // Format currency for display
  const formatCurrency = (value) => {
    return `$${value.toFixed(2)}`;
  };

  // Format percentage for display
  const formatPercentage = (value) => {
    return `${value.toFixed(2)}%`;
  };

  // Results to display
  const calculatedResults = calculateResults();
  const totals = calculateTotals(calculatedResults);
  
  return (
    <>
    <TopMenuBar  showBackButton={true}  onBackClick={() => navigate(-1)}  title={"Check Possibility"}  showSearch={false}  showAddress={false} />
    <div style={containerStyle}>
      <div style={headerStyle}>
        <div style={headerTitleContainerStyle}>
          <Calculator style={iconStyle} />
          <h2 style={titleStyle}>Product Evaluation & Cost Analysis Tool</h2>
        </div>
        
        <div style={tabsContainerStyle}>
          <button 
            style={{
              ...tabButtonStyle,
              backgroundColor: activeTab === 'calculator' ? '#4CAF50' : '#e0e0e0',
              color: activeTab === 'calculator' ? 'white' : '#333'
            }}
            onClick={() => setActiveTab('calculator')}
          >
            <Calculator size={18} />
            Calculator
          </button>
          <button 
            style={{
              ...tabButtonStyle,
              backgroundColor: activeTab === 'saved' ? '#4CAF50' : '#e0e0e0',
              color: activeTab === 'saved' ? 'white' : '#333'
            }}
            onClick={() => setActiveTab('saved')}
          >
            <BarChart3 size={18} />
            Saved Analyses
          </button>
        </div>
      </div>

      {activeTab === 'calculator' && (
        <div style={contentStyle}>
          <div style={unitSelectionStyle}>
            <div>
              <label style={labelStyle}>Dimension Unit: </label>
              <select 
                style={selectStyle}
                value={units.dimension}
                onChange={(e) => setUnits({...units, dimension: e.target.value})}
              >
                <option value="inch">Inches (in)</option>
                <option value="cm">Centimeters (cm)</option>
              </select>
            </div>
            <div>
              <label style={labelStyle}>Weight Unit: </label>
              <select 
                style={selectStyle}
                value={units.weight}
                onChange={(e) => setUnits({...units, weight: e.target.value})}
              >
                <option value="lb">Pounds (lb)</option>
                <option value="kg">Kilograms (kg)</option>
              </select>
            </div>
          </div>

          <div style={productsContainerStyle}>
            <h3 style={sectionTitleStyle}>Product Details</h3>
            
            {products.map((product, index) => (
              <div key={product.id} style={productRowStyle}>
                <div style={productIndexStyle}>{index + 1}</div>
                
                <div style={basicInfoContainerStyle}>
                  <div style={inputGroupStyle}>
                    <label style={labelStyle}>Product Name</label>
                    <input 
                      type="text" 
                      style={inputStyle} 
                      value={product.name}
                      onChange={(e) => handleProductChange(product.id, 'name', e.target.value)}
                      placeholder="Enter product name"
                    />
                  </div>
                  
                  <div style={inputGroupStyle}>
                    <label style={labelStyle}>MOQ</label>
                    <input 
                      type="number" 
                      style={inputStyle} 
                      value={product.moq}
                      onChange={(e) => handleProductChange(product.id, 'moq', e.target.value)}
                      placeholder="Minimum Order Quantity"
                    />
                  </div>
                  
                  <div style={inputGroupStyle}>
                    <label style={labelStyle}>Total Price ($)</label>
                    <input 
                      type="number" 
                      style={inputStyle} 
                      value={product.totalPrice}
                      onChange={(e) => handleProductChange(product.id, 'totalPrice', e.target.value)}
                      placeholder="Total product price"
                    />
                  </div>
                  
                  <div style={inputGroupStyle}>
                    <label style={labelStyle}>Shipping Cost ($)</label>
                    <input 
                      type="number" 
                      style={inputStyle} 
                      value={product.shippingCost}
                      onChange={(e) => handleProductChange(product.id, 'shippingCost', e.target.value)}
                      placeholder="Total shipping cost"
                    />
                  </div>
                  
                  <div style={inputGroupStyle}>
                    <label style={labelStyle}>MRP ($)</label>
                    <input 
                      type="number" 
                      style={inputStyle} 
                      value={product.mrp}
                      onChange={(e) => handleProductChange(product.id, 'mrp', e.target.value)}
                      placeholder="Manufacturer's Recommended Price"
                    />
                  </div>
                </div>
                
                <div style={commissionContainerStyle}>
                  <div style={commissionTypeStyle}>
                    <label style={labelStyle}>Commission Type</label>
                    <div style={radioGroupStyle}>
                      <label style={radioLabelStyle}>
                        <input 
                          type="radio" 
                          checked={product.commissionType === 'percentage'} 
                          onChange={() => handleProductChange(product.id, 'commissionType', 'percentage')}
                        />
                        <PercentIcon size={16} />
                        Percentage
                      </label>
                      <label style={radioLabelStyle}>
                        <input 
                          type="radio" 
                          checked={product.commissionType === 'fixed'} 
                          onChange={() => handleProductChange(product.id, 'commissionType', 'fixed')}
                        />
                        <DollarSign size={16} />
                        Fixed
                      </label>
                    </div>
                  </div>
                  
                  <div style={inputGroupStyle}>
                    <label style={labelStyle}>
                      Commission {product.commissionType === 'percentage' ? '(%)' : '($)'}
                    </label>
                    <input 
                      type="number" 
                      style={inputStyle} 
                      value={product.commissionValue}
                      onChange={(e) => handleProductChange(product.id, 'commissionValue', e.target.value)}
                      placeholder={product.commissionType === 'percentage' ? "Commission percentage" : "Fixed commission amount"}
                    />
                  </div>
                </div>
                
                <div style={dimensionsContainerStyle}>
                  <h4 style={dimensionsTitleStyle}>Product Dimensions</h4>
                  <div style={dimensionsGridStyle}>
                    <div style={inputGroupStyle}>
                      <label style={labelStyle}>Length ({units.dimension})</label>
                      <input 
                        type="number" 
                        style={inputStyle} 
                        value={product.dimensions.length}
                        onChange={(e) => handleProductChange(product.id, 'dimensions.length', e.target.value)}
                        placeholder="Length"
                      />
                    </div>
                    
                    <div style={inputGroupStyle}>
                      <label style={labelStyle}>Width ({units.dimension})</label>
                      <input 
                        type="number" 
                        style={inputStyle} 
                        value={product.dimensions.width}
                        onChange={(e) => handleProductChange(product.id, 'dimensions.width', e.target.value)}
                        placeholder="Width"
                      />
                    </div>
                    
                    <div style={inputGroupStyle}>
                      <label style={labelStyle}>Height ({units.dimension})</label>
                      <input 
                        type="number" 
                        style={inputStyle} 
                        value={product.dimensions.height}
                        onChange={(e) => handleProductChange(product.id, 'dimensions.height', e.target.value)}
                        placeholder="Height"
                      />
                    </div>
                    
                    <div style={inputGroupStyle}>
                      <label style={labelStyle}>Weight ({units.weight})</label>
                      <input 
                        type="number" 
                        style={inputStyle} 
                        value={product.dimensions.weight}
                        onChange={(e) => handleProductChange(product.id, 'dimensions.weight', e.target.value)}
                        placeholder="Weight"
                      />
                    </div>
                  </div>
                  
                  <div style={handlingPropertiesStyle}>
                    <label style={{...checkboxLabelStyle, marginBottom: '5px'}}>Special Handling:</label>
                    <div style={checkboxGroupStyle}>
                      <label style={checkboxLabelStyle}>
                        <input 
                          type="checkbox" 
                          checked={product.isFragile}
                          onChange={(e) => handleProductChange(product.id, 'isFragile', e.target.checked)}
                        />
                        Fragile
                      </label>
                      <label style={checkboxLabelStyle}>
                        <input 
                          type="checkbox" 
                          checked={product.hasBatteries}
                          onChange={(e) => handleProductChange(product.id, 'hasBatteries', e.target.checked)}
                        />
                        Batteries
                      </label>
                      <label style={checkboxLabelStyle}>
                        <input 
                          type="checkbox" 
                          checked={product.isHazardous}
                          onChange={(e) => handleProductChange(product.id, 'isHazardous', e.target.checked)}
                        />
                        Hazardous
                      </label>
                    </div>
                    <div style={inputGroupStyle}>
                      <label style={labelStyle}>Storage Requirements</label>
                      <input 
                        type="text" 
                        style={inputStyle} 
                        value={product.storageRequirements}
                        onChange={(e) => handleProductChange(product.id, 'storageRequirements', e.target.value)}
                        placeholder="Any special storage requirements"
                      />
                    </div>
                  </div>
                </div>
                
                <div style={productResultsContainerStyle}>
                  <h4 style={resultsTitleStyle}>Calculated Results</h4>
                  <div style={resultsGridStyle}>
                    <div style={resultItemStyle}>
                      <span style={resultLabelStyle}>Product Cost/Item:</span>
                      <span style={resultValueStyle}>{formatCurrency(calculatedResults[index].results.productCostPerItem)}</span>
                    </div>
                    <div style={resultItemStyle}>
                      <span style={resultLabelStyle}>Shipping/Item:</span>
                      <span style={resultValueStyle}>{formatCurrency(calculatedResults[index].results.shippingPerItem)}</span>
                    </div>
                    <div style={resultItemStyle}>
                      <span style={resultLabelStyle}>Commission/Item:</span>
                      <span style={resultValueStyle}>{formatCurrency(calculatedResults[index].results.commission)}</span>
                    </div>
                    <div style={resultItemStyle}>
                      <span style={resultLabelStyle}>Total Cost/Item:</span>
                      <span style={resultValueStyle}>{formatCurrency(calculatedResults[index].results.totalCostPerItem)}</span>
                    </div>
                    <div style={resultItemStyle}>
                      <span style={resultLabelStyle}>{calculatedResults[index].results.isMrpProvided ? 'MRP:' : 'Est. Retail Price:'}</span>
                      <span style={resultValueStyle}>{formatCurrency(calculatedResults[index].results.retailPrice)}</span>
                    </div>
                    <div style={resultItemStyle}>
                      <span style={resultLabelStyle}>Customer Savings:</span>
                      <span style={resultValueStyle}>{formatCurrency(calculatedResults[index].results.savingsPerItem)} ({formatPercentage(calculatedResults[index].results.savingsPercentage)})</span>
                    </div>
                  </div>
                </div>
                
                {products.length > 1 && (
                  <button 
                    style={removeButtonStyle} 
                    onClick={() => handleRemoveProduct(product.id)}
                    title="Remove product"
                  >
                    <Trash2 size={16} />
                  </button>
                )}
              </div>
            ))}
            
            <button style={addProductButtonStyle} onClick={handleAddProduct}>
              <Plus size={16} style={{ marginRight: '8px' }} />
              Add Another Product
            </button>
          </div>
          
          <div style={totalSummaryContainerStyle}>
            <h3 style={totalSummaryTitleStyle}>Overall Cost Summary</h3>
            <div style={summaryContentStyle}>
              <div style={summaryGridStyle}>
                <div style={summaryItemStyle}>
                  <span style={summaryLabelStyle}>Total Products:</span>
                  <span style={summaryValueStyle}>{totals.totalProducts}</span>
                </div>
                <div style={summaryItemStyle}>
                  <span style={summaryLabelStyle}>Total MOQ:</span>
                  <span style={summaryValueStyle}>{totals.totalMOQ}</span>
                </div>
                <div style={summaryItemStyle}>
                  <span style={summaryLabelStyle}>Total Product Cost:</span>
                  <span style={summaryValueStyle}>{formatCurrency(totals.totalProductCost)}</span>
                </div>
                <div style={summaryItemStyle}>
                  <span style={summaryLabelStyle}>Total Shipping Cost:</span>
                  <span style={summaryValueStyle}>{formatCurrency(totals.totalShippingCost)}</span>
                </div>
                <div style={summaryItemStyle}>
                  <span style={summaryLabelStyle}>Total Commission:</span>
                  <span style={summaryValueStyle}>{formatCurrency(totals.totalCommission)}</span>
                </div>
                <div style={summaryItemStyle}>
                  <span style={summaryLabelStyle}>Total Final Cost:</span>
                  <span style={summaryValueStyle}>{formatCurrency(totals.totalFinalCost)}</span>
                </div>
                <div style={summaryItemStyle}>
                  <span style={summaryLabelStyle}>Total Customer Savings:</span>
                  <span style={summaryValueStyle}>{formatCurrency(totals.totalSavings)}</span>
                </div>
                <div style={summaryItemStyle}>
                  <span style={summaryLabelStyle}>Avg. Savings Percentage:</span>
                  <span style={summaryValueStyle}>{formatPercentage(totals.averageSavingsPercentage)}</span>
                </div>
              </div>
            </div>
          </div>
          
          <div style={actionButtonsContainerStyle}>
            <button 
              style={togglePackagingButtonStyle} 
              onClick={() => setShowPackagingOptimizer(!showPackagingOptimizer)}
            >
              <Box size={16} style={{ marginRight: '8px' }} />
              {showPackagingOptimizer ? 'Hide' : 'Show'} Packaging Optimizer
            </button>
            
            <button style={saveResultsButtonStyle} onClick={handleSaveResults}>
              <Save size={16} style={{ marginRight: '8px' }} />
              Save These Results
            </button>
          </div>

          {saveSuccess && (
            <div style={successMessageStyle}>
              Results saved successfully!
            </div>
          )}
          
          {showPackagingOptimizer && (
            <div style={packagingOptimizerContainerStyle}>
              <PackagingOptimizer 
                selectedWishes={convertToOptimizerFormat()} 
                comboId={`evaluation-${Date.now()}`}
              />
            </div>
          )}
        </div>
      )}

      {activeTab === 'saved' && (
        <div style={contentStyle}>
          <h3 style={sectionTitleStyle}>Saved Analyses</h3>
          
          {savedResults.length === 0 ? (
            <div style={noSavedResultsStyle}>
              <p>No saved analyses yet. Calculate and save some results to see them here.</p>
            </div>
          ) : (
            <div style={savedResultsContainerStyle}>
              {savedResults.map((savedResult) => (
                <div key={savedResult.id} style={savedResultCardStyle}>
                  <div style={savedResultHeaderStyle}>
                    <h4 style={savedResultTitleStyle}>
                      Analysis from {savedResult.timestamp}
                    </h4>
                    <div style={savedResultSummaryStyle}>
                      <span style={savedResultSummaryItemStyle}>Products: {savedResult.totals.totalProducts}</span>
                      <span style={savedResultSummaryItemStyle}>Total Items: {savedResult.totals.totalMOQ}</span>
                      <span style={savedResultSummaryItemStyle}>Total Cost: {formatCurrency(savedResult.totals.totalFinalCost)}</span>
                      <span style={savedResultSummaryItemStyle}>Savings: {formatCurrency(savedResult.totals.totalSavings)} ({formatPercentage(savedResult.totals.averageSavingsPercentage)})</span>
                    </div>
                  </div>
                  
                  <div style={savedResultProductsStyle}>
                    {savedResult.products.map((product, index) => (
                      <div key={index} style={savedResultProductItemStyle}>
                        <div style={savedResultProductHeaderStyle}>
                          <span style={savedResultProductNameStyle}>{product.name}</span>
                          <span style={savedResultProductQuantityStyle}>MOQ: {product.moq}</span>
                        </div>
                        <div style={savedResultProductDetailsStyle}>
                          <span>Cost/Item: {formatCurrency(product.results.totalCostPerItem)}</span>
                          <span>{product.results.isMrpProvided ? 'MRP:' : 'Est. Retail:'} {formatCurrency(product.results.retailPrice)}</span>
                          <span>Savings: {formatCurrency(product.results.savingsPerItem)} ({formatPercentage(product.results.savingsPercentage)})</span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
    </>
  );
};

// Styles
const containerStyle = {
  fontFamily: 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif',
  backgroundColor: '#f9f9f9',
  borderRadius: '8px',
  boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
  margin: '20px auto',
  padding: '20px',
  maxWidth: '1500px',
  overflow: 'hidden'
};

const headerStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '20px',
  paddingBottom: '15px',
  borderBottom: '1px solid #e0e0e0'
};

const headerTitleContainerStyle = {
  display: 'flex',
  alignItems: 'center'
};

const iconStyle = {
  width: '24px',
  height: '24px',
  marginRight: '10px',
  color: '#4CAF50'
};

const titleStyle = {
  fontSize: '24px',
  fontWeight: 'bold',
  color: '#333',
  margin: 0
};

const tabsContainerStyle = {
  display: 'flex',
  gap: '10px'
};

const tabButtonStyle = {
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  padding: '8px 16px',
  border: 'none',
  borderRadius: '4px',
  cursor: 'pointer',
  fontWeight: 'bold',
  transition: 'background-color 0.3s'
};

const contentStyle = {
  backgroundColor: '#fff',
  borderRadius: '8px',
  padding: '20px',
  marginBottom: '20px'
};

const unitSelectionStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  gap: '20px',
  marginBottom: '20px',
  padding: '15px',
  backgroundColor: '#f5f5f5',
  borderRadius: '8px'
};

const labelStyle = {
  fontSize: '14px',
  fontWeight: 'bold',
  marginBottom: '5px',
  display: 'block',
  color: '#555'
};

const selectStyle = {
  padding: '8px 12px',
  borderRadius: '4px',
  border: '1px solid #ddd',
  fontSize: '14px',
  minWidth: '150px'
};

const sectionTitleStyle = {
  fontSize: '18px',
  fontWeight: 'bold',
  marginBottom: '15px',
  color: '#333'
};

const productsContainerStyle = {
  marginBottom: '30px'
};

const productRowStyle = {
  display: 'flex',
  flexDirection: 'column',
  gap: '15px',
  padding: '20px',
  backgroundColor: '#f9f9f9',
  borderRadius: '8px',
  marginBottom: '20px',
  border: '1px solid #e0e0e0',
  position: 'relative'
};

const productIndexStyle = {
  position: 'absolute',
  top: '-12px',
  left: '15px',
  backgroundColor: '#4CAF50',
  color: 'white',
  width: '24px',
  height: '24px',
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontWeight: 'bold',
  fontSize: '14px'
};

const basicInfoContainerStyle = {
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
  gap: '15px'
};

const inputGroupStyle = {
  marginBottom: '10px'
};

const inputStyle = {
  width: '100%',
  padding: '8px 12px',
  borderRadius: '4px',
  border: '1px solid #ddd',
  fontSize: '14px',
  boxSizing: 'border-box'
};

const commissionContainerStyle = {
  display: 'flex',
  gap: '20px',
  alignItems: 'flex-start',
  padding: '15px',
  backgroundColor: '#f0f0f0',
  borderRadius: '6px'
};

const commissionTypeStyle = {
  flex: '1'
};

const radioGroupStyle = {
  display: 'flex',
  gap: '15px',
  marginTop: '8px'
};

const radioLabelStyle = {
  display: 'flex',
  alignItems: 'center',
  gap: '5px',
  fontSize: '14px',
  cursor: 'pointer'
};

const dimensionsContainerStyle = {
  padding: '15px',
  backgroundColor: '#f5f5f5',
  borderRadius: '6px'
};

const dimensionsTitleStyle = {
  fontSize: '16px',
  fontWeight: 'bold',
  marginBottom: '10px',
  color: '#444'
};

const dimensionsGridStyle = {
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fit, minmax(180px, 1fr))',
  gap: '15px',
  marginBottom: '15px'
};

const handlingPropertiesStyle = {
  marginTop: '10px',
  padding: '10px',
  backgroundColor: '#e9e9e9',
  borderRadius: '4px'
};

const checkboxGroupStyle = {
  display: 'flex',
  gap: '15px',
  marginBottom: '10px'
};

const checkboxLabelStyle = {
  display: 'flex',
  alignItems: 'center',
  gap: '5px',
  fontSize: '14px',
  cursor: 'pointer'
};

const productResultsContainerStyle = {
  padding: '15px',
  backgroundColor: '#f0f7f0',
  borderRadius: '6px',
  border: '1px solid #d0e0d0'
};

const resultsTitleStyle = {
  fontSize: '16px',
  fontWeight: 'bold',
  marginBottom: '10px',
  color: '#2e7d32'
};

const resultsGridStyle = {
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fit, minmax(220px, 1fr))',
  gap: '10px'
};

const resultItemStyle = {
  display: 'flex',
  flexDirection: 'column',
  padding: '8px 12px',
  backgroundColor: 'white',
  borderRadius: '4px',
  border: '1px solid #e0e0e0'
};

const resultLabelStyle = {
  fontSize: '12px',
  color: '#666',
  marginBottom: '4px'
};

const resultValueStyle = {
  fontSize: '16px',
  fontWeight: 'bold',
  color: '#333'
};

const removeButtonStyle = {
  position: 'absolute',
  top: '10px',
  right: '10px',
  width: '30px',
  height: '30px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#f44336',
  color: 'white',
  border: 'none',
  borderRadius: '50%',
  cursor: 'pointer'
};

const addProductButtonStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '10px 15px',
  backgroundColor: '#4CAF50',
  color: 'white',
  border: 'none',
  borderRadius: '4px',
  cursor: 'pointer',
  fontWeight: 'bold',
  marginTop: '10px'
};

const totalSummaryContainerStyle = {
  padding: '20px',
  backgroundColor: '#e8f5e9',
  borderRadius: '8px',
  marginBottom: '20px',
  border: '1px solid #c8e6c9'
};

const totalSummaryTitleStyle = {
  fontSize: '18px',
  fontWeight: 'bold',
  marginBottom: '15px',
  color: '#2e7d32'
};

const summaryContentStyle = {
  backgroundColor: 'white',
  borderRadius: '6px',
  padding: '15px'
};

const summaryGridStyle = {
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fit, minmax(220px, 1fr))',
  gap: '15px'
};

const summaryItemStyle = {
  display: 'flex',
  flexDirection: 'column',
  padding: '10px',
  backgroundColor: '#f9f9f9',
  borderRadius: '4px',
  border: '1px solid #e0e0e0'
};

const summaryLabelStyle = {
  fontSize: '14px',
  color: '#555',
  marginBottom: '5px'
};

const summaryValueStyle = {
  fontSize: '18px',
  fontWeight: 'bold',
  color: '#333'
};

const actionButtonsContainerStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '20px'
};

const togglePackagingButtonStyle = {
  display: 'flex',
  alignItems: 'center',
  padding: '12px 20px',
  backgroundColor: '#2196F3',
  color: 'white',
  border: 'none',
  borderRadius: '4px',
  cursor: 'pointer',
  fontWeight: 'bold'
};

const saveResultsButtonStyle = {
  display: 'flex',
  alignItems: 'center',
  padding: '12px 20px',
  backgroundColor: '#4CAF50',
  color: 'white',
  border: 'none',
  borderRadius: '4px',
  cursor: 'pointer',
  fontWeight: 'bold'
};

const successMessageStyle = {
  padding: '15px',
  backgroundColor: '#d4edda',
  color: '#155724',
  borderRadius: '4px',
  marginBottom: '20px',
  textAlign: 'center',
  fontWeight: 'bold'
};

const packagingOptimizerContainerStyle = {
  marginTop: '20px',
  padding: '20px',
  backgroundColor: '#f5f5f5',
  borderRadius: '8px',
  border: '1px solid #e0e0e0'
};

const noSavedResultsStyle = {
  padding: '30px',
  textAlign: 'center',
  backgroundColor: '#f5f5f5',
  borderRadius: '8px',
  color: '#666'
};

const savedResultsContainerStyle = {
  display: 'flex',
  flexDirection: 'column',
  gap: '20px'
};

const savedResultCardStyle = {
  backgroundColor: '#f5f5f5',
  borderRadius: '8px',
  padding: '15px',
  border: '1px solid #e0e0e0'
};

const savedResultHeaderStyle = {
  marginBottom: '15px',
  borderBottom: '1px solid #e0e0e0',
  paddingBottom: '10px'
};

const savedResultTitleStyle = {
  fontSize: '16px',
  fontWeight: 'bold',
  marginBottom: '10px',
  color: '#333'
};

const savedResultSummaryStyle = {
  display: 'flex',
  flexWrap: 'wrap',
  gap: '15px'
};

const savedResultSummaryItemStyle = {
  backgroundColor: '#e0e0e0',
  padding: '5px 10px',
  borderRadius: '15px',
  fontSize: '14px'
};

const savedResultProductsStyle = {
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
  gap: '15px'
};

const savedResultProductItemStyle = {
  backgroundColor: 'white',
  borderRadius: '6px',
  padding: '12px',
  border: '1px solid #ddd'
};

const savedResultProductHeaderStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '10px',
  borderBottom: '1px solid #eee',
  paddingBottom: '5px'
};

const savedResultProductNameStyle = {
  fontWeight: 'bold',
  fontSize: '14px'
};

const savedResultProductQuantityStyle = {
  backgroundColor: '#f0f0f0',
  padding: '3px 6px',
  borderRadius: '4px',
  fontSize: '12px'
};

const savedResultProductDetailsStyle = {
  display: 'flex',
  flexDirection: 'column',
  gap: '5px',
  fontSize: '13px'
};

export default ProductEvaluationTool;