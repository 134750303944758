import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEdit, faBox, faShip, faCalendar, faPlus, faImage, faCheck, faListAlt } from '@fortawesome/free-solid-svg-icons';
import { TopMenuBar } from '../../Buyers/DashbordComponents/TopMenuBar';
import { useAuth } from '../../Common/InitialGears/AuthContext';
import * as style from '../../../GlobalStyles';
import { getApi, postApi, buildUrl } from "../../Common/BackendCalls/apiUtils";
import ApproveComboModal from './ApproveComboModal';

const DraftComboList = () => { 
    const auth = useAuth();
    const { queryLoginParam } = auth;
    const navigate = useNavigate();
    const [combos, setCombos] = useState([]);
    const [filteredCombos, setFilteredCombos] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [searchQuery, setSearchQuery] = useState('');
    const [errorMessage, setErrorMessage] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const [approveModalOpen, setApproveModalOpen] = useState(false);
    const [selectedCombo, setSelectedCombo] = useState(null);
  
    const fetchDraftCombos = useCallback(async () => {
      setIsLoading(true);
      try {
        const combosUrl = buildUrl('getDraftCombos', queryLoginParam);
        const response = await getApi(combosUrl, {});
        
        if (response.ok) {
          const data = response.data;
          setCombos(data.combos || []);
          setFilteredCombos(data.combos || []);
        } else {
          if (response.status === 423) {
            setErrorMessage('Your session has expired. Please log in again.');
            navigate("/");
          } else {
            setErrorMessage('Failed to fetch draft combos. Please try again.');
          }
        }
      } catch (error) { 
        setErrorMessage('An error occurred while fetching combos.');
      } finally {
        setIsLoading(false);
      }
    }, [queryLoginParam, navigate]);
  
    useEffect(() => {
      fetchDraftCombos();
    }, [fetchDraftCombos]);
  
    useEffect(() => {
      const filtered = combos.filter(combo => 
        combo.title?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        combo.description?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        combo.items?.some(item => 
          item.title?.toLowerCase().includes(searchQuery.toLowerCase())
        )
      );
      setFilteredCombos(filtered);
    }, [searchQuery, combos]);

    useEffect(() => {
      let timer;
      if (successMessage) {
        timer = setTimeout(() => {
          setSuccessMessage('');
        }, 7000);
      }
      return () => clearTimeout(timer);
    }, [successMessage]);
  
    const handleDelete = async (comboId) => {
      if (window.confirm("Are you sure you want to delete this combo?")) {
        try {
          const deleteURL = buildUrl(`deleteCombo/${comboId}`, queryLoginParam);
          const response = await postApi(deleteURL, {});
          
          if (response.ok) {
            setSuccessMessage('Combo deleted successfully');
            fetchDraftCombos();
          } else {
            setErrorMessage('Failed to delete the combo.');
          }
        } catch (error) {
          setErrorMessage('An error occurred while deleting the combo.');
        }
      }
    };

    const handleApprove = (combo) => {
      setSelectedCombo(combo);
      setApproveModalOpen(true);
    };

    const handleApproveSuccess = (message) => {
      setSuccessMessage(message);
      fetchDraftCombos();
    };
   
    const handleEdit = (combo) => { 
      navigate(`/editCombo/${combo.id}`, { 
        state: { comboData: combo } 
      });
    };

    const handleAddGroupWishesToCombo = (comboId) => {
      navigate('/comboGroupWishes', { state: { editingComboId: comboId } });
    };

    const handleCreateCombo = () => {
      navigate('/createCombo');
    };
    
    const handleGroupWishesForCombo = () => {
      navigate('/comboGroupWishes');
    };
    
    const formatCurrency = (amount) => {
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
      }).format(amount);
    };
  
    const formatDate = (dateString) => {
      if (!dateString) return 'No expiry set';
      return new Date(dateString).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      });
    };

    const renderComboItem = (combo) => { 
      const showApproveButton = combo.shipping_solution_id > 0 && combo.shipping_solution !== null; 
      
      return(
        <div key={combo.uuid} style={comboItemStyle}>
          <div style={comboHeaderStyle}>
            <h3 style={comboTitleStyle}>{combo.title}</h3>
            <div style={actionButtonsStyle}>
              <button 
                onClick={() => handleEdit(combo)}
                style={{...buttonStyle, backgroundColor: style.Color.colorMintGreen}}
              >
                <FontAwesomeIcon icon={faEdit} /> Edit
              </button>
              {showApproveButton && (
                <button 
                  onClick={() => handleApprove(combo)}
                  style={{...buttonStyle, backgroundColor: style.Color.colorLimeGreen}}
                >
                  <FontAwesomeIcon icon={faCheck} /> Approve
                </button>
              )}
              <button 
                onClick={() => handleDelete(combo.id)}
                style={{...buttonStyle, backgroundColor: '#e74c3c'}}
              >
                <FontAwesomeIcon icon={faTrash} /> Delete
              </button>
            </div>
          </div>

          <p style={comboDescriptionStyle}>{combo.description}</p>

          <div style={comboDetailsGridStyle}>
            <div style={detailItemStyle}>
              <FontAwesomeIcon icon={faBox} style={iconStyle} />
              <span>Min Size: {combo.min_combo_size}</span>
            </div>
            <div style={detailItemStyle}>
              <FontAwesomeIcon icon={faShip} style={iconStyle} />
              <span>Min Orders: {combo.min_combo_order_qty}</span>
            </div>
            <div style={detailItemStyle}>
              <FontAwesomeIcon icon={faCalendar} style={iconStyle} />
              <span>Expires: {formatDate(combo.expires_at)}</span>
            </div>
          </div>

          <div style={pricingContainerStyle}>
            <div style={priceItemStyle}>
              <span>Bundle MRP:</span>
              <span style={priceValueStyle}>{formatCurrency(combo.bundle_mrp)}</span>
            </div>
            <div style={priceItemStyle}>
              <span>Bundle Rate:</span>
              <span style={priceValueStyle}>{formatCurrency(combo.bundle_rate)}</span>
            </div>
            <div style={priceItemStyle}>
              <span>Shipping:</span>
              <span style={priceValueStyle}>{formatCurrency(combo.bundle_shiping_cost)}</span>
            </div>
          </div>

          {combo.items && combo.items.length > 0 && (
            <div style={itemsContainerStyle}>
              <div style={itemsHeaderContainerStyle}>
                <h4 style={itemsHeaderStyle}>Included Items:</h4>               
              </div>
              {combo.items.map((item) => (
                <div key={item.id} style={wishItemStyle}>
                  {item.image ? (
                    <img 
                      src={item.image} 
                      alt={item.title} 
                      style={wishImageStyle}
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = '/api/placeholder/120/120';
                      }} 
                    />
                  ) : (
                    <div style={{...wishImageStyle, ...placeholderImageStyle}}>
                      <FontAwesomeIcon icon={faImage} size="3x" color="#ccc" />
                    </div>
                  )}
                  <div style={wishContentStyle}>
                    <h3 style={wishTitleStyle}>{item.title}</h3>
                    <p style={wishInfoStyle}>Price: {formatCurrency(item.price)}</p>
                    {item.item_savings > 0 && (
                      <p style={wishInfoStyle}>Savings: {formatCurrency(item.item_savings)}</p>
                    )}
                    <p style={wishInfoStyle}>
                      {item.required ? 'Required' : 'Optional'} | Max Qty: {item.max_quantity}
                    </p>
                    <div style={itemDescriptionStyle}>{item.description}</div>
                    <button 
                      onClick={() => handleRemoveItemFromCombo(combo.id, item.id)}
                      style={removeItemButtonStyle}
                    >
                      <FontAwesomeIcon icon={faTrash} /> Remove
                    </button>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      )
    };

    const handleRemoveItemFromCombo = async (comboId, itemId) => {
      if (window.confirm("Are you sure you want to remove this item from the combo?")) {
        try {
          const removeURL = buildUrl(`removeItemFromCombo/${comboId}/${itemId}/`, queryLoginParam);
          const response = await postApi(removeURL, {});
          
          if (response.ok) {
            setSuccessMessage('Item removed from combo successfully');
            fetchDraftCombos();
          } else {
            setErrorMessage('Failed to remove item from combo.');
          }
        } catch (error) {
          setErrorMessage('An error occurred while removing the item.');
        }
      }
    };
  
    return (
      <>
        <TopMenuBar
          showBackButton={true}
          showHomeButton={true}
          onBackClick={() => navigate(-1)}
          title="Draft Combos"
          viewDraftCombos={true}
          showSearch={false}
          showAddress={false}
        />
        <div style={containerStyle}>
          <div style={contentStyle}>
            {(successMessage || errorMessage) && (
              <div style={{
                ...messageStyle,
                backgroundColor: successMessage ? '#d4edda' : '#f8d7da',
                color: successMessage ? '#155724' : '#721c24',
              }}>
                {successMessage || errorMessage}
              </div>
            )}

            <div style={formContainerStyle}>
              <div style={inputGroup}>
                <input
                  type="text"
                  placeholder="Search drafts..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  style={searchInputStyle}
                />
                <button style={createComboButtonStyle} onClick={handleGroupWishesForCombo}>
                  <FontAwesomeIcon icon={faPlus} /> Create New Combo
                </button>
              </div>

              {isLoading ? (
                <div style={loadingStyle}>
                  <div style={spinnerStyle}></div>
                </div>
              ) : (
                <div style={comboListStyle}>
                  {filteredCombos.length > 0 ? (
                    filteredCombos.map(renderComboItem)
                  ) : (
                    <p style={emptyMessageStyle}>No draft combos found</p>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Approve Modal */}
        {selectedCombo && (
          <ApproveComboModal
            combo={selectedCombo}
            isOpen={approveModalOpen}
            onClose={() => setApproveModalOpen(false)}
            onSuccess={handleApproveSuccess}
            queryLoginParam={queryLoginParam}
          />
        )}
      </>
    );
};
 
const containerStyle = {
  padding: '20px',
  backgroundColor: style.Color.colorLightBeige,
  minHeight: '100vh',
  marginTop: '10px',
};

const contentStyle = {
  maxWidth: '1000px',
  margin: '0 auto',
};

const formContainerStyle = {
  backgroundColor: style.Color.colorLightBeige,
  borderRadius: '8px',
  padding: '30px',
  marginBottom: '30px',
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
};

const inputGroup = {
  display: 'flex',
  gap: '20px',
  flexDirection: 'row',
  marginBottom: '20px', 
  marginTop: '4%',
};

const searchInputStyle = {
  flex: 1,
  padding: '12px',
  borderRadius: '8px',
  border: '1px solid #ddd',
  fontSize: '16px',
};

const createComboButtonStyle = {
  padding: '12px 24px',
  backgroundColor: style.Color.colorMintGreen,
  color: 'white',
  border: 'none',
  borderRadius: '8px',
  cursor: 'pointer',
  fontWeight: 'bold',
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  fontSize: '14px', 
  transition: 'background-color 0.2s ease',
};

const comboListStyle = {
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
};

const comboItemStyle = {
  backgroundColor: style.Color.colorWhite,
  borderRadius: '8px',
  padding: '20px',
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
};

const comboHeaderStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  marginBottom: '15px',
};

const comboTitleStyle = {
  fontSize: '20px',
  fontWeight: 'bold',
  color: style.Color.colorRoyalBlueText,
  margin: 0,
};

const comboDescriptionStyle = {
  fontSize: '14px',
  color: style.Color.colorDarkGrey,
  marginBottom: '15px',
  whiteSpace: 'pre-line',
};

const comboDetailsGridStyle = {
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
  gap: '15px',
  marginBottom: '20px',
};

const detailItemStyle = {
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  fontSize: '14px',
};

const iconStyle = {
  color: style.Color.colorMintGreen,
};

const pricingContainerStyle = {
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fit, minmax(150px, 1fr))',
  gap: '15px',
  padding: '15px',
  backgroundColor: '#f8f9fa',
  borderRadius: '6px',
  marginBottom: '20px',
};

const priceItemStyle = {
  display: 'flex',
  flexDirection: 'column',
  gap: '5px',
};

const priceValueStyle = {
  fontWeight: 'bold',
  color: style.Color.colorRoyalBlueText,
};

const itemsContainerStyle = {
  marginTop: '20px',
  borderTop: '1px solid #eee',
  paddingTop: '15px',
};

const itemsHeaderContainerStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '15px',
};

const itemsHeaderStyle = {
  fontSize: '16px',
  fontWeight: 'bold',
  margin: 0,
  color: style.Color.colorDarkGrey,
};

const addGroupWishButtonStyle = {
  padding: '6px 12px',
  backgroundColor: style.Color.colorMintGreen,
  color: 'white',
  border: 'none',
  borderRadius: '4px',
  cursor: 'pointer',
  fontSize: '12px',
  display: 'flex',
  alignItems: 'center',
  gap: '5px',
};

const wishItemStyle = {
  display: 'flex',
  backgroundColor: style.Color.colorWhite,
  padding: '20px',
  borderRadius: '8px',
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  marginBottom: '15px',
  border: `1px solid ${style.Color.colorLightGrey}`,
};

const wishImageStyle = {
  width: '100px',
  height: '100px',
  borderRadius: '8px',
  marginRight: '20px',
  objectFit: 'cover',
};

const placeholderImageStyle = {
  backgroundColor: '#f0f0f0',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const wishContentStyle = {
  flex: 1,
  position: 'relative',
};

const wishTitleStyle = {
  fontSize: '18px',
  fontWeight: 'bold',
  marginBottom: '10px',
  color: style.Color.colorRoyalBlueText,
};

const wishInfoStyle = {
  fontSize: '14px',
  color: style.Color.colorDarkGrey,
  marginBottom: '5px',
};

const itemDescriptionStyle = {
  fontSize: '14px',
  color: style.Color.colorDarkGrey,
  marginTop: '10px',
  lineHeight: '1.4',
  whiteSpace: 'pre-line',
};

const removeItemButtonStyle = {
  position: 'absolute',
  top: '5px',
  right: '5px',
  padding: '5px 10px',
  backgroundColor: '#e74c3c',
  color: 'white',
  border: 'none',
  borderRadius: '4px',
  cursor: 'pointer',
  fontSize: '12px',
  display: 'flex',
  alignItems: 'center',
  gap: '5px',
};

const actionButtonsStyle = {
  display: 'flex',
  gap: '10px',
};

const buttonStyle = {
  padding: '8px 16px',
  border: 'none',
  borderRadius: '4px',
  color: 'white',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  gap: '5px',
  fontSize: '14px',
  fontWeight: 'bold',
};

const messageStyle = {
  padding: '12px',
  borderRadius: '4px',
  marginBottom: '20px',
  textAlign: 'center',
  position: 'fixed',
  top: '20px',
  right: '20px',
  zIndex: '1000',
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
};

const loadingStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '200px',
};

const spinnerStyle = {
  width: '40px',
  height: '40px',
  border: '4px solid #f3f3f3',
  borderTop: `4px solid ${style.Color.colorMintGreen}`,
  borderRadius: '50%',
  animation: 'spin 1s linear infinite',
};

const emptyMessageStyle = {
  textAlign: 'center',
  color: style.Color.colorDarkGrey,
  fontSize: '16px',
  padding: '40px',
  backgroundColor: style.Color.colorWhite,
  borderRadius: '8px',
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
};

export default DraftComboList;