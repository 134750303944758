// components/common/Footer.js
import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import FAQ_CATEGORIES from './faqConfig';
import FAQSection from './FAQ';

const Footer = () => {
  const location = useLocation();
  const [openCategory, setOpenCategory] = useState(null);

  const toggleCategory = (category) => {
    setOpenCategory(openCategory === category ? null : category);
  };

  const isActivePath = (path) => {
    return location.pathname === path ? "text-amber-400" : "hover:text-amber-400 transition-colors";
  };

  return (
    <footer className="bg-gray-900 text-gray-300 py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8 mb-8">
          {/* Company Info */}
          <div>
            <h3 className="text-xl font-bold text-white mb-4">BacksApp by Infoply</h3>
            <p className="text-gray-400">
            Creating next-generation marketplace technology that brings buyers and sellers closer together
            </p>
          </div>

          {/* Quick Links */}
          <div>
            <h4 className="text-lg font-semibold text-white mb-4">Quick Links</h4>
            <ul className="space-y-2">
              <li><Link to="/" className={isActivePath('/')}>Home</Link></li>
              <li><Link to="/about" className={isActivePath('/about')}>About Us</Link></li>
            </ul>
          </div>

          {/* Legal */}
          <div>
            <h4 className="text-lg font-semibold text-white mb-4">Legal</h4>
            <ul className="space-y-2">
              <li><Link to="/privacyPolicy" className={isActivePath('/privacy-policy')}>Privacy Policy</Link></li>
              <li><Link to="/terms" className={isActivePath('/terms')}>Terms and Conditions</Link></li>
              <li><Link to="/dataDeletion" className={isActivePath('/dataDeletion')}>Data Deletion Policy</Link></li>
            </ul>
          </div>


          {/* FAQ Section */}
          <div className="md:col-span-1">
            <h4 className="text-lg font-semibold text-white mb-4">FAQ</h4>
            {Object.entries(FAQ_CATEGORIES).map(([key, category]) => (
              <FAQSection
                key={key}
                category={category}
                questions={category.questions}
                isOpen={openCategory === key}
                toggleCategory={() => toggleCategory(key)}
              />
            ))}
          </div>
        </div>

        {/* Footer Bottom */}
        <div className="pt-8 border-t border-gray-800 text-center">
          <div className="mb-4 flex justify-center space-x-6">
            <a href="mailto:support@backsapp.com" className="text-gray-400 hover:text-amber-400 transition-colors">
              support@backsapp.com
            </a>
            <span className="text-gray-400">510-771-7866</span>
          </div>
          <p>Copyright © 2024 BacksApp by Infoply - All Rights Reserved.</p>
        </div>
      </div>

      <style jsx>{`
        @keyframes fadeIn {
          from { opacity: 0; }
          to { opacity: 1; }
        }

        .fade-enter {
          animation: fadeIn 0.3s ease-in-out;
        }
      `}</style>
    </footer>
  );
};

export default Footer;