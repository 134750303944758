import React, { useState } from 'react';
import * as style from "../../../GlobalStyles";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faSearchPlus } from '@fortawesome/free-solid-svg-icons';

const MediaGallery = ({ mainImageUrl, additionalMedia = [] }) => {
  const [selectedMedia, setSelectedMedia] = useState({ url: mainImageUrl, type: 'IMAGE' });
  const [showZoom, setShowZoom] = useState(false);
  
  const allMedia = [
    { uuid: 'main', media_url: mainImageUrl, media_type: 'IMAGE' },
    ...additionalMedia
  ];

  const handleMediaSelect = (media) => {
    setSelectedMedia({
      url: media.media_url,
      type: media.media_type
    });
  };

  const handleZoomClick = (e) => {
    e.stopPropagation();
    if (selectedMedia.type === 'IMAGE') {
      setShowZoom(true);
    }
  };

  const handleCloseZoom = () => {
    setShowZoom(false);
  };

  return (
    <>
      <div style={styles.container}>
        {/* Main display area */}
        <div style={styles.mainDisplay}>
          {selectedMedia.type === 'VIDEO' ? (
            <video 
              style={styles.video}
              controls
              src={selectedMedia.url}
            >
              Your browser does not support the video tag.
            </video>
          ) : (
            <div style={styles.imageContainer}>
              <img
                src={selectedMedia.url}
                alt="Product"
                style={styles.mainImage}
              />
              <button 
                style={styles.zoomButton}
                onClick={handleZoomClick}
                title="Click to zoom"
              >
                <FontAwesomeIcon icon={faSearchPlus} style={styles.zoomIcon} />
              </button>
            </div>
          )}
        </div>

        {/* Thumbnail strip */}
        {allMedia.length > 1 && (
          <div style={styles.thumbnailStrip}>
            <div style={styles.thumbnailContainer}>
              {allMedia.map((media, index) => (
                <div
                  key={media.uuid || index}
                  onClick={() => handleMediaSelect(media)}
                  style={{
                    ...styles.thumbnail,
                    ...(selectedMedia.url === media.media_url ? styles.thumbnailActive : {})
                  }}
                >
                  {media.media_type === 'VIDEO' ? (
                    <div style={styles.videoThumbnailWrapper}>
                      <video 
                        src={media.media_url}
                        style={styles.thumbnailVideo}
                      />
                      <div style={styles.videoOverlay}>
                        <div style={styles.playButtonCircle}>
                          <div style={styles.playButtonTriangle}></div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <img
                      src={media.media_url}
                      alt={`Thumbnail ${index + 1}`}
                      style={styles.thumbnailImage}
                    />
                  )}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>

      {/* Zoom Modal */}
      {showZoom && (
        <div style={styles.modal} onClick={handleCloseZoom}>
          <div style={styles.modalContent} onClick={e => e.stopPropagation()}>
            <button style={styles.closeButton} onClick={handleCloseZoom}>
              <FontAwesomeIcon icon={faTimes} />
            </button>
            <img
              src={selectedMedia.url}
              alt="Zoomed Product"
              style={styles.zoomedImage}
            />
          </div>
        </div>
      )}
    </>
  );
};

const styles = {
  // ... existing styles ...
  imageContainer: {
    position: 'relative',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  zoomButton: {
    position: 'absolute',
    bottom: '10px',
    right: '10px',
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
    border: 'none',
    borderRadius: '50%',
    width: '40px',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    transition: 'all 0.2s ease',
    '&:hover': {
      backgroundColor: 'white',
      transform: 'scale(1.1)',
    }
  },
  zoomIcon: {
    color: style.Color.colorPeacockBlueDark,
    fontSize: '20px',
  },
  modal: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1000,
    animation: 'fadeIn 0.3s ease-in-out',
  },
  modalContent: {
    position: 'relative',
    maxWidth: '90vw',
    maxHeight: '90vh',
    backgroundColor: 'white',
    borderRadius: '8px',
    padding: '20px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  },
  closeButton: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    backgroundColor: 'white',
    border: 'none',
    borderRadius: '50%',
    width: '30px',
    height: '30px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    zIndex: 1,
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    '&:hover': {
      backgroundColor: '#f8f9fa',
    }
  },
  zoomedImage: {
    maxWidth: '100%',
    maxHeight: 'calc(90vh - 40px)',
    objectFit: 'contain',
  },
  
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    borderRadius: '8px',
    maxWidth: '42rem',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  mainDisplay: {
    width: '100%',
    height: '384px',
    backgroundColor: '#FFFFFF',
    borderRadius: '8px',
    marginBottom: '16px',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: `1px solid ${style.Color.colorLightBeige}`,
    boxShadow: '0 1px 2px rgba(0, 0, 0, 0.05)'
  },
  video: {
    width: '100%',
    height: '100%',
    objectFit: 'contain'
  },
  mainImage: {
    width: '100%',
    height: '100%',
    borderRadius: '8px',
    objectFit: 'contain',
    padding: '16px'
  },
  thumbnailStrip: {
    width: '100%',
    overflowX: 'auto'
  },
  thumbnailContainer: {
    display: 'flex',
    gap: '16px',
    padding: '8px',
    justifyContent: 'center'
  },
  thumbnail: {
    flexShrink: 0,
    width: '80px',
    height: '80px',
    borderRadius: '6px',
    overflow: 'hidden',
    cursor: 'pointer',
    border: `2px solid ${style.Color.colorLightBeige}`,
    transition: 'all 0.2s ease',
    '&:hover': {
      border: `2px solid ${style.Color.colorBlueLight}`
    }
  },
  thumbnailActive: {
    border: `2px solid ${style.Color.colorBlue}`,
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
  },
  thumbnailImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    padding: '4px'
  },
  thumbnailVideo: {
    width: '100%',
    height: '100%',
    objectFit: 'cover'
  },
  videoThumbnailWrapper: {
    position: 'relative',
    width: '100%',
    height: '100%'
  },
  videoOverlay: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.3)'
  },
  playButtonCircle: {
    width: '32px',
    height: '32px',
    borderRadius: '50%',
    border: '2px solid #FFFFFF',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  playButtonTriangle: {
    width: 0,
    height: 0,
    borderTop: '6px solid transparent',
    borderLeft: '12px solid #FFFFFF',
    borderBottom: '6px solid transparent',
    marginLeft: '4px'
  }
};

// Add styles to the document
const styleSheet = document.createElement("style");
styleSheet.type = "text/css";
styleSheet.innerText = `
  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
`;
document.head.appendChild(styleSheet);

export default MediaGallery;