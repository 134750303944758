import React, { useState, useCallback }  from 'react';
import { useNavigate } from 'react-router-dom'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAuth } from '../../Common/InitialGears/AuthContext'; 
import { getAuth, signOut } from "firebase/auth";
import { Color } from "../../../GlobalStyles";
import BacksAppsLogo from "../../../assets/images/BacksAppLogo.jpg";
import VerificationAlert from './VerificationAlertBanner';
import { 
  faSearch, 
  faShoppingCart, 
  faHome, 
  faArrowLeft, 
  faList, 
  faPlus, 
  faBell,
  faBars,
  faTimes,
  faUser,
  faCog,
  faSignOutAlt,
  faUserPlus,
  faStar,
  faHeart,
  faClipboardList,
  faFileInvoiceDollar
} from '@fortawesome/free-solid-svg-icons';

export const TopMenuBar = ({ 
  showSearch, 
  title, 
  showBackButton, 
  onBackClick, 
  showHomeButton, 
  showUserName, 
  viewDrafts, 
  viewDraftCombos,
  showNotifications = true,
  invoiceBackToNotification = undefined,
  onBackButtonClick
}) => {
  const navigate = useNavigate();
  const { firstName } = useAuth();  
  const [cartCount, setCartCount] = useState(0); 
  const [showCartPopover, setShowCartPopover] = useState(false);

  const handleHomeClick = () => {
    navigate('/dashboard');
  };

  const handleViewDrafts = () => {
    navigate('/draftGroupWishes');
  };

  const handleNewGrpWish = () => {  
    navigate('/createGroupWishes', { state: { createNew: true } });
  };

  const handleNotificationClick = () => { 
    navigate('/notifications');
  };

  const handleCartClick = () => {
    setShowCartPopover(!showCartPopover);
  }; 

  const handleSavedItems = () => {
    setShowCartPopover(false);
    navigate('/savedGroupWishes');
  };

  const handleOrders = () => {
    setShowCartPopover(false);
    navigate('/myOrders');
  };

  const handleInvoices = () => {
    setShowCartPopover(false);
    navigate('/myInvoices');
  };
  
  const handleBackNavigation = () => {
    if (onBackButtonClick) {
      onBackButtonClick();
    } else {
      if (invoiceBackToNotification) {
        navigate('/notifications');
      } else  {
        navigate('/myInvoices');
      }
    }
  };

  const handleLogout = useCallback(async () => {
    try {
      localStorage.clear();
      sessionStorage.clear();
      document.cookie.split(";").forEach((c) => {
        document.cookie = c
          .replace(/^ +/, "")
          .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
      });
      const auth = getAuth();
      await signOut(auth);
      navigate('/');
    } catch (error) { 
    }
  }, [navigate]);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const Drawer = ({ isOpen, onClose }) => {
    const drawerItems = [
      { icon: faHome, text: 'Dashboard', action: () => navigate('/dashboard') },
      { icon: faUserPlus, text: 'Invites', action: () => navigate('/invites') },
      { icon: faUser, text: 'Profile', action: () => navigate('/profiles') },
      { icon: faStar, text: 'Rewards', action: () => navigate('/creditDetails') },
      { icon: faCog, text: 'Settings', action: () => navigate('/settings') },
      { icon: faSignOutAlt, text: 'Logout', action: handleLogout  },
    ];

    return (
      <div style={{...styles.drawer, right: isOpen ? '0' : '-250px'}}>
        <button style={styles.drawerCloseButton} onClick={onClose}>
          <FontAwesomeIcon icon={faTimes} />
        </button>
        {drawerItems.map((item, index) => (
          <div key={index} style={styles.drawerItem} onClick={item.action}>
            <FontAwesomeIcon icon={item.icon} style={styles.drawerIcon} />
            <span>{item.text}</span>
          </div>
        ))}
      </div>
    );
  };

  const CartPopover = ({ isVisible, onClose }) => {
    if (!isVisible) return null;

    return (
      <div style={styles.cartPopover}>
        <div style={styles.cartPopoverHeader}>
          <h3 style={styles.cartPopoverTitle}>Shopping Cart</h3>
          <button onClick={onClose} style={styles.cartPopoverClose}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>
        
        <div style={styles.cartPopoverContent}>                    
          <button onClick={handleSavedItems} style={styles.cartPopoverButton}>
            <FontAwesomeIcon icon={faHeart} style={styles.cartPopoverIcon} />
            <span style={styles.cartPopoverText}>Favorite Items</span>
          </button>
          
          <button onClick={handleOrders} style={styles.cartPopoverButton}>
            <FontAwesomeIcon icon={faClipboardList} style={styles.cartPopoverIcon} />
            <span style={styles.cartPopoverText}>Track My Orders</span>
          </button>

          <button onClick={handleInvoices} style={styles.cartPopoverButton}>
            <FontAwesomeIcon icon={faFileInvoiceDollar} style={styles.cartPopoverIcon} />
            <span style={styles.cartPopoverText}>My Invoices</span>
          </button>
        </div>
      </div>
    );
  };

  return (
    <>
    <div style={styles.topBarContainer}>
      <div style={styles.topBar}>
        {showBackButton ? (
          <button onClick={onBackClick} style={styles.backButton}>
            <FontAwesomeIcon icon={faArrowLeft} style={styles.backIcon} />
          </button>
        ) : (
          <>
            {showHomeButton ? (
              <button onClick={handleHomeClick} style={styles.backButton}>
                <FontAwesomeIcon icon={faHome} style={styles.backIcon} />
              </button>
            ) : (
              <div style={styles.logo}>
                <img src={BacksAppsLogo} alt="BacksApp Logo" style={styles.logoImage} />
              </div>
            )}
          </>
        )}
        {invoiceBackToNotification !== undefined && (
          <button onClick={handleBackNavigation} style={styles.invoicesBack}>
            <FontAwesomeIcon icon={faArrowLeft} style={styles.backIcon} />
            <span>{invoiceBackToNotification ? 'Back to Notifications' : 'Back to Invoices'}</span>
          </button>
        )}
        <div style={styles.titleContainer}>
          <div style={styles.title}>{title}</div>
        </div>
        
        <div style={styles.rightSection}>
          {showUserName && (
            <div style={styles.accountInfo}>
              <div style={styles.accountText}>Hello, {firstName}</div> 
            </div>
          )}
          {showNotifications && (
            <button onClick={handleNotificationClick} style={styles.notificationButton}>
              <FontAwesomeIcon icon={faBell} style={styles.notificationIcon} />
            </button>
          )}
          {viewDrafts && (
            <div style={styles.buttonContainer}>
              <button onClick={handleNewGrpWish} style={styles.newGrpWishButtonStyle}>
                <FontAwesomeIcon icon={faPlus} /> New GrpWish
              </button>
              <button onClick={handleViewDrafts} style={styles.viewDraftsButtonStyle}>
                <FontAwesomeIcon icon={faList} /> View Draft GrpWishes
              </button>
            </div>
          )}
          {viewDraftCombos && (
            <div style={styles.buttonContainer}>
              <button onClick={handleNewGrpWish} style={styles.newGrpWishButtonStyle}>
                <FontAwesomeIcon icon={faPlus} /> New GrpWish
              </button>
              <button onClick={handleViewDrafts} style={styles.viewDraftsButtonStyle}>
                <FontAwesomeIcon icon={faList} /> View Draft GrpWishes
              </button>
            </div>
          )}
          <div style={styles.cart} onClick={handleCartClick}>
            <div style={styles.cartIconWrapper}>
              <FontAwesomeIcon icon={faShoppingCart} style={styles.cartIcon} />
              {cartCount > 0 && (
                <span style={styles.cartBadge}>{cartCount}</span>
              )}
            </div>
          </div>
          
            <button onClick={toggleDrawer} style={styles.menuButton}>
              <FontAwesomeIcon icon={faBars} />
            </button>
                  
        </div>
      </div>
      {showSearch && (
        <div style={styles.secondaryBar}>
          <div style={styles.searchBar}>
            <select style={styles.searchCategory}>
              <option>All</option>
            </select>
            <input type="text" placeholder="Search .." style={styles.searchInput} />
            <button style={styles.searchButton}>
              <FontAwesomeIcon icon={faSearch} />
            </button>
          </div>
        </div>
      )}
      <CartPopover isVisible={showCartPopover} onClose={() => setShowCartPopover(false)}/>
      <Drawer isOpen={isDrawerOpen} onClose={toggleDrawer} />
    </div>
     <VerificationAlert />
     </>
  );
}; 

const styles = {
  topBarContainer: {
    backgroundColor: '#89293A',
    background: 'linear-gradient(180deg, #9A3445 0%, #782333 100%)',
    color: Color.colorWhite,
    width: '100%',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 1000,
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  },
  
  topBar: {
    background: 'none',
    border: 'none',
    color: Color.colorWhite,
    fontSize: '16px',
    cursor: 'pointer',
    marginRight: '10px',
    display: 'flex',
    padding: '20px 20px 20px 30px',
    position: 'relative',
    width: '100%',
    maxWidth: '100vw',
    boxSizing: 'border-box',
    justifyContent: 'space-between',
    alignItems: 'center',
    zIndex: 2, 
    minWidth: 'fit-content', 
  },
  logo: {
    width: '100px',
  },
  logoImage: {
    width: '100%',
    height: 'auto',
  },
  invoicesBack:{ 
    color: Color.colorWhite,
    fontSize: '10px',
    cursor: 'pointer',
    marginLeft: '10px',
    display: 'flex', 
    zIndex: 2,   
  },
  backButton: {
    background: 'none',
    border: 'none',
    color: Color.colorWhite,
    fontSize: '16px',
    cursor: 'pointer',
    marginRight: '10px',
    display: 'flex',
    alignItems: 'center',
    zIndex: 2,  
    minWidth: 'fit-content',
  },

  buttonContainer: {
    display: 'flex',
    gap: '10px',
    marginLeft: 'auto',
  },

  newGrpWishButtonStyle: {
    padding: '8px',
    borderRadius: '8px',
    backgroundColor: 'transparent',
    color: Color.colorWhite,
    border: '1px solid white',
    fontSize: '16px',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '8px',
  },

  viewDraftsButtonStyle: {
    padding: '8px',
    borderRadius: '8px',
    backgroundColor: 'transparent',
    color: 'white',
    border: '1px solid white',
    fontSize: '16px',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '8px',
  },

  backIcon: {
    marginRight: '8px',
    fontSize: '18px',
  }, 
  titleContainer: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
    width: 'auto',
    maxWidth: '40%',  
    zIndex: 1,
  },
  
  title: {
    fontWeight: 'bold',
    fontSize: '18px',  
    color: Color.colorWhite,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',

  },
 
  logo: {
    width: '100px',
  },
  logoImage: {
    width: '100%',
    height: 'auto',
  },

  backButton: {
    background: 'none',
    border: 'none',
    color: Color.colorWhite,
    fontSize: '20px',
    cursor: 'pointer',
    marginRight: '10px',
  },
  backIcon: {
    marginRight: '5px',
  }, 
  deliverTo: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '20px',
  },
  deliverToText: {
    fontSize: '12px',
    color: Color.colorOffWhite,
  },
  location: {
    fontWeight: 'bold',
  },
  searchBar: {
    display: 'flex',
    flex: 1,
    maxWidth: '800px',
    margin: '0 20px',
  },
  searchCategory: {
    padding: '10px',
    backgroundColor: Color.colorMintGreen,
    border: 'none',
    borderRadius: '4px 4px 4px 4px',
  },
  searchInput: {
    flex: 1,
    padding: '10px',
    fontSize: '16px',
    border: 'none',
  },
  searchButton: {
    padding: '10px 15px',
    backgroundColor: Color.colorMintGreen,
    color: Color.colorDarkBlueBlack,
    border: 'none',
    borderRadius: '0 4px 4px 0',
    cursor: 'pointer',
  },
  rightIcons: {
    display: 'flex',
    alignItems: 'center',
    gap: '20px',
  },
  accountInfo: {
    textAlign: 'right',
  },
  hello: {
    fontSize: '12px',
  },
  accountText: {
    fontWeight: 'bold',
  },
  returnsOrders: {
    textAlign: 'center',
  },
  cart: {
    display: 'flex',
    alignItems: 'center',
  },
  cartIcon: {
    fontSize: '24px',
    marginRight: '25px',
  },
  cartText: {
    fontWeight: 'bold',
  },
  secondaryBar: {
    display: 'flex',
    alignItems: 'center',
    padding: '5px 5px',
    backgroundColor: Color.colorDarkBlue,
  },
  allButton: {
    display: 'flex',
    alignItems: 'center',
    background: 'none',
    border: 'none',
    color: Color.colorWhite,
    fontWeight: 'bold',
    cursor: 'pointer',
    marginRight: '20px',
  },
  menuIcon: {
    marginRight: '5px',
  },
  secondaryNav: {
    display: 'flex',
    gap: '15px',
  },
  navLink: {
    color: Color.colorWhite,
    textDecoration: 'none',
    fontSize: '14px',
  },
  icon: {
    marginRight: '5px',
  },
  rightSection: {
    display: 'flex',
    alignItems: 'center',
    gap: '20px',
    marginLeft: 'auto',  
    zIndex: 2, 
  },
  leftSection: {
    display: 'flex',
    alignItems: 'center',
    minWidth: 'fit-content',
    zIndex: 2,
  },
  notificationButton: {
    background: 'none',
    border: 'none',
    color: Color.colorWhite,
    fontSize: '20px',
    cursor: 'pointer',
    padding: '5px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  notificationIcon: {
    fontSize: '24px',
  },
  menuButton: {
    background: 'none',
    border: 'none',
    color: Color.colorWhite,
    fontSize: '24px',
    cursor: 'pointer',
  },
  drawer: {
    position: 'fixed',
    top: 0,
    width: '250px',
    height: '100%',
    backgroundColor: Color.colorDarkBlueBlack,
    transition: 'right 0.3s ease',
    zIndex: 1001,
    boxShadow: '-2px 0 5px rgba(0,0,0,0.5)',
    display: 'flex',
    flexDirection: 'column',
    padding: '60px 0 20px',
  },
  drawerCloseButton: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    background: 'none',
    border: 'none',
    color: Color.colorOffWhite,
    fontSize: '24px',
    cursor: 'pointer',
  },
  drawerItem: {
    padding: '15px 20px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    color: Color.colorOffWhite,
    transition: 'background-color 0.3s',
  },
  drawerIcon: {
    marginRight: '15px',
    width: '20px',
  }, 
    cartIconWrapper: {
      position: 'relative',
      cursor: 'pointer',
    },
    cartBadge: {
      position: 'absolute',
      top: '-8px',
      right: '-8px',
      background: Color.colorRed,
      color: Color.colorWhite,
      borderRadius: '50%',
      padding: '2px 6px',
      fontSize: '12px',
      fontWeight: 'bold',
    },
    cartPopover: {
      position: 'absolute',
      top: '60px',
      right: '20px',
      width: '250px',
      backgroundColor: Color.colorWhite,
      borderRadius: '8px',
      boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
      zIndex: 1000,
    },
    cartPopoverHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '15px',
      borderBottom: `1px solid ${Color.colorLightGray}`,
    },
    cartPopoverTitle: {
      margin: 0,
      color: Color.colorPeacockBlue,
      fontSize: '16px',
      fontWeight: 'bold',
    },
    cartPopoverClose: {
      background: 'none',
      border: 'none',
      color: Color.colorPeacockBlue,
      cursor: 'pointer',
      fontSize: '16px',
    },
    cartPopoverContent: {
      padding: '10px',
    },
    cartPopoverButton: {
      width: '100%',
      padding: '12px',
      margin: '5px 0',
      border: 'none',
      borderRadius: '4px',
      backgroundColor: Color.colorWhite,
      color: Color.colorDarkBlueBlack,
      fontSize: '14px',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      transition: 'background-color 0.3s',
      ':hover': {
        backgroundColor: Color.colorLightGray,
      },
    },
    cartPopoverIcon: {
      marginRight: '10px',
      width: '16px',
      color: Color.colorPeacockBlue,
    },
    cartPopoverText:{
      color: Color.colorPeacockBlueDark,

    }
};

export default TopMenuBar;