import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faUsers, 
  faUserCheck, 
  faUserXmark,
  faChartLine,
  faPencilAlt,
  faCheck,
  faTimes,
  faStar
} from '@fortawesome/free-solid-svg-icons';
import * as style from "../../GlobalStyles";

const UserStatsDisplay = ({ 
  verifiedUsers = [], verifiedQtyCount=0,
  unverifiedUsers = [], unverifiedQtyCount=0,
  bumpedVerifiedUsers = [],bumpedVerifiedQtyCount=0,
  bumpedUnverifiedUsers = [],bumpedUnverifiedQtyCount=0,
  onBumpedUsersUpdate,verificationScore=100,
  rating = 0,
  totalRating = 0,
  ratingCount = 1,
  onRatingUpdate,
  isGroupWish = false   
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const calculateTotalQty = (users) => {
    return users.reduce((total, user) => total + (user.quantity || 0), 0);
  }; 
  
  // Initialize state with the correct values from props
  const [editBumpedVerifiedCount, setEditBumpedVerifiedCount] = useState(bumpedVerifiedUsers.length);
  const [editBumpedVerifiedQty, setEditBumpedVerifiedQty] = useState(bumpedVerifiedQtyCount);
  const [editBumpedUnverifiedCount, setEditBumpedUnverifiedCount] = useState(bumpedUnverifiedUsers.length);
  const [editBumpedUnverifiedQty, setEditBumpedUnverifiedQty] = useState(bumpedUnverifiedQtyCount);
  
  const [editRating, setEditRating] = useState(rating);
  const [editTotalRating, setEditTotalRating] = useState(totalRating);
  const [editRatingCount, setEditRatingCount] = useState(ratingCount || 1);
  const [editingRating, setEditingRating] = useState(false);

  // Update state when props change
  useEffect(() => {      
    setEditBumpedVerifiedCount(bumpedVerifiedUsers.length);
    setEditBumpedVerifiedQty(bumpedVerifiedQtyCount);
    setEditBumpedUnverifiedCount(bumpedUnverifiedUsers.length);
    setEditBumpedUnverifiedQty(bumpedUnverifiedQtyCount);
    setEditRating(rating);
    setEditTotalRating(totalRating);
    setEditRatingCount(ratingCount || 1); 
  }, [bumpedVerifiedUsers, bumpedVerifiedQtyCount, bumpedUnverifiedUsers, bumpedUnverifiedQtyCount, rating, totalRating, ratingCount]);

  

  const handleSaveEdit = () => { 
    onBumpedUsersUpdate(
      editBumpedVerifiedCount, 
      editBumpedVerifiedQty, 
      editBumpedUnverifiedCount, 
      editBumpedUnverifiedQty
    );
    setIsEditing(false);
  };

  const handleCancelEdit = () => {
    setEditBumpedVerifiedCount(bumpedVerifiedUsers.length);
    setEditBumpedVerifiedQty(bumpedVerifiedQtyCount);
    setEditBumpedUnverifiedCount(bumpedUnverifiedUsers.length);
    setEditBumpedUnverifiedQty(bumpedUnverifiedQtyCount);
    setIsEditing(false);
  };

  const handleSaveRating = () => {
    if (onRatingUpdate) {
      onRatingUpdate(editRating, editTotalRating, editRatingCount);
    }
    setEditingRating(false);
  };

  const handleCancelRating = () => {
    setEditRating(rating);
    setEditTotalRating(totalRating);
    setEditRatingCount(ratingCount);
    setEditingRating(false);
  };

  // Fix: Use the length of arrays for counts and the actual values for quantities
  const stats = {
    verified: {
      regular: {
        count: verifiedUsers.length,
        qty: verifiedQtyCount,
      },
      bumped: {
        count: bumpedVerifiedUsers.length,
        qty: bumpedVerifiedQtyCount
      }
    },
    unverified: {
      regular: {
        count: unverifiedUsers.length,
        qty: unverifiedQtyCount
      },
      bumped: {
        count: bumpedUnverifiedUsers.length,
        qty: bumpedUnverifiedQtyCount
      }
    },
    totals: {
      verifiedCount: verifiedUsers.length + bumpedVerifiedUsers.length,
      verifiedQty: verifiedQtyCount + bumpedVerifiedQtyCount,
      unverifiedCount: unverifiedUsers.length + bumpedUnverifiedUsers.length,
      unverifiedQty: unverifiedQtyCount + bumpedUnverifiedQtyCount,
    },
    actual:{
      verified:{
        count: verifiedUsers.length,
        qty: verifiedQtyCount,
      },
      unverified:{
        count: unverifiedUsers.length,
        qty: unverifiedQtyCount,
      }
    }
  }; 
  const EditableStatSection = ({ 
    title, 
    icon, 
    stats, 
    bumpedCount, 
    bumpedQty,
    onBumpedCountChange,
    onBumpedQtyChange 
  }) => { 
    
    return (
      <div className="stat-section">
        <p className="stat-label">{title}</p>
        <p className="stat-value">Users: {stats.regular.count}</p>
        <p className="stat-value edit-container">
          Bumped Users: {
            isEditing ? (
              <input 
                type="number" 
                min="0" 
                value={bumpedCount} 
                onChange={(e) => onBumpedCountChange(parseInt(e.target.value) || 0)} 
                className="bumped-input"
                style={{width: '60px', marginLeft: '5px'}}
              /> 
            ) : stats.bumped.count
          }
        </p>
        <p className="stat-value">Quantity: {stats.regular.qty}</p>
        <p className="stat-value edit-container">
          Bumped Qty: {
            isEditing ? (
              <input 
                type="number" 
                min="0" 
                value={bumpedQty} 
                onChange={(e) => onBumpedQtyChange(parseInt(e.target.value) || 0)} 
                className="bumped-input"
                style={{width: '60px', marginLeft: '5px'}}
              /> 
            ) : stats.bumped.qty
          }
        </p>
      </div>
    );
  };

  const RatingSection = () => (
    <div className="rating-section">
      <p className="stat-label">User Rating</p>
      <div className="rating-display">
        <p className="stat-value rating-value">
          {!editingRating ? (
            <>
              <FontAwesomeIcon icon={faStar} className="rating-star" /> {rating.toFixed(1)}
            </>
          ) : (
            <div className="rating-edit-container">
              <div className="rating-edit-field">
                <label>Average Rating:</label>
                <input
                  type="number"
                  min="0"
                  max="5"
                  step="0.1"
                  value={editRating}
                  onChange={(e) => {
                    const val = parseFloat(e.target.value) || 0;
                    setEditRating(val); 
                    setEditTotalRating(val * editRatingCount);
                  }}
                  className="rating-input"
                />
              </div>
              
              <div className="rating-edit-field">
                <label>Total Rating:</label>
                <input
                  type="number"
                  min="0"
                  step="0.1"
                  value={editTotalRating}
                  onChange={(e) => {
                    const val = parseFloat(e.target.value) || 0;
                    setEditTotalRating(val); 
                    if (editRatingCount > 0) {
                      setEditRating(val / editRatingCount);
                    }
                  }}
                  className="rating-input"
                />
              </div>
              
              <div className="rating-edit-field">
                <label>Rating Count:</label>
                <input
                  type="number"
                  min="1"
                  step="1"
                  value={editRatingCount}
                  onChange={(e) => {
                    const val = parseInt(e.target.value) || 1;
                    setEditRatingCount(val); 
                    if (val > 0) {
                      setEditRating(editTotalRating / val);
                    }
                  }}
                  className="rating-input"
                />
              </div>
            </div>
          )}
        </p>
        
        {editingRating ? (
          <div className="edit-actions rating-edit-actions">
            <button className="save-button" onClick={handleSaveRating}>
              <FontAwesomeIcon icon={faCheck} />
            </button>
            <button className="cancel-button" onClick={handleCancelRating}>
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>
        ) : (
          onRatingUpdate && (
            <button className="edit-button rating-edit-button" onClick={() => setEditingRating(true)}>
              <FontAwesomeIcon icon={faPencilAlt} />
            </button>
          )
        )}
      </div>
      
      {!editingRating && (
        <div className="rating-details">
          <p className="stat-value">Total Rating: {totalRating.toFixed(1)}</p>
          <p className="stat-value">Rating Count: {ratingCount}</p>
        </div>
      )}
    </div>
  );

  const EditControls = () => (
    <div className="edit-controls">
      {!isEditing ? (
        <button className="edit-button" onClick={() => setIsEditing(true)}>
          <FontAwesomeIcon icon={faPencilAlt} />
        </button>
      ) : (
        <div className="edit-actions">
          <button className="save-button" onClick={handleSaveEdit}>
            <FontAwesomeIcon icon={faCheck} />
          </button>
          <button className="cancel-button" onClick={handleCancelEdit}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>
      )}
    </div>
  );

  const getTitle = () => {
    return isGroupWish ? "Order Statistics" : "Combo Statistics";
  };

  return (
    <div className="stats-card" style={styles.statsCard}>
      <div className="stats-header" style={styles.statsHeader}>
        <FontAwesomeIcon icon={faUsers} className="header-icon" style={styles.headerIcon} />
        <h2 className="header-title" style={styles.headerTitle}>{getTitle()}</h2>
        {onBumpedUsersUpdate && <EditControls />}
      </div>
      <div className="stats-content" style={styles.statsContent}>
        <div className="stat-block verified" style={styles.statBlockVerified}>
          <div className="stat-header" style={styles.statHeader}>
            <FontAwesomeIcon icon={faUserCheck} className="stat-icon" style={styles.statIcon} />
            <h3 className="stat-title" style={styles.statTitle}>Verified Users</h3>
          </div>
          <div className="stat-content" style={styles.statContentInner}>
            <EditableStatSection title="Verified" icon={faUserCheck} stats={stats.verified} bumpedCount={editBumpedVerifiedCount} bumpedQty={editBumpedVerifiedQty} onBumpedCountChange={setEditBumpedVerifiedCount} onBumpedQtyChange={setEditBumpedVerifiedQty}/>
          </div>          
        </div>

        <div className="stat-block unverified" style={styles.statBlockUnverified}>
          <div className="stat-header" style={styles.statHeader}>
            <FontAwesomeIcon icon={faUserXmark} className="stat-icon" style={styles.statIcon} />
            <h3 className="stat-title" style={styles.statTitle}>Unverified Users</h3>
          </div>
          <div className="stat-content" style={styles.statContentInner}>
            <EditableStatSection title="Unverified" icon={faUserXmark} stats={stats.unverified} bumpedCount={editBumpedUnverifiedCount} bumpedQty={editBumpedUnverifiedQty} onBumpedCountChange={setEditBumpedUnverifiedCount} onBumpedQtyChange={setEditBumpedUnverifiedQty}/>
          </div>
        </div>

        <div className="stat-block totals" style={styles.statBlockTotals}>
          <div className="stat-header" style={styles.statHeader}>
            <FontAwesomeIcon icon={faChartLine} className="stat-icon" style={styles.statIcon} />
            <h3 className="stat-title" style={styles.statTitle}>Total Statistics(Customer facing)</h3>
          </div>
          <div className="stat-grid" style={styles.statGrid}>
            <div className="stat-section" style={styles.statSection}>
              <p className="stat-label" style={styles.statLabel}>Verified Total</p>
              <p className="stat-value" style={styles.statValue}>Users: {stats.totals.verifiedCount}</p>
              <p className="stat-value" style={styles.statValue}>Quantity: {stats.totals.verifiedQty}</p>
            </div>
            <div className="stat-section" style={styles.statSection}>
              <p className="stat-label" style={styles.statLabel}>Unverified Total</p>
              <p className="stat-value" style={styles.statValue}>Users: {stats.totals.unverifiedCount}</p>
              <p className="stat-value" style={styles.statValue}>Quantity: {stats.totals.unverifiedQty}</p>
            </div>
          </div>
        </div>
        <div className="stat-block totals" style={styles.statBlockTotals}>
          <div className="stat-header" style={styles.statHeader}>
            <FontAwesomeIcon icon={faChartLine} className="stat-icon" style={styles.statIcon} />
            <h3 className="stat-title" style={styles.statTitle}>Total Statistics(Actual)</h3>
          </div>
          <div className="stat-grid" style={styles.statGrid}>
            <div className="stat-section" style={styles.statSection}>
              <p className="stat-label" style={styles.statLabel}>Verified Total</p>
              <p className="stat-value" style={styles.statValue}>Users: {stats.actual.verified.count}</p>
              <p className="stat-value" style={styles.statValue}>Quantity: {stats.actual.verified.qty}</p>
            </div>
            <div className="stat-section" style={styles.statSection}>
              <p className="stat-label" style={styles.statLabel}>Unverified Total</p>
              <p className="stat-value" style={styles.statValue}>Users: {stats.actual.unverified.count}</p>
              <p className="stat-value" style={styles.statValue}>Quantity: {stats.actual.unverified.qty}</p>
            </div>
          </div>
        </div>

        {onRatingUpdate && (
          <div className="stat-block rating" style={styles.statBlockRating}>
            <div className="stat-header" style={styles.statHeader}>
              <FontAwesomeIcon icon={faStar} className="stat-icon" style={styles.statIcon} />
              <h3 className="stat-title" style={styles.statTitle}>Rating</h3>
            </div>
            <div className="stat-content" style={styles.statContentInner}>
              <RatingSection />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

// Define styles as a JavaScript object to be used with inline styling
const styles = {
  statsCard: {
    backgroundColor: style.Color.colorLightBeige,
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    marginTop: '20px',
    overflow: 'hidden',
    margin: '6% auto'
  },
  statsHeader: {
    backgroundColor: style.Color.colorLightBeige,
    padding: '16px',
    borderBottom: '1px solid #e9ecef',
    display: 'flex',
    alignItems: 'center',
    gap: '12px'
  },
  headerIcon: {
    fontSize: '24px',
    color: style.Color.colorPurple
  },
  headerTitle: {
    fontSize: '20px',
    fontWeight: '600',
    margin: '0',
    color: style.Color.colorPurple
  },
  statsContent: {
    padding: '16px',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px'
  },
  statBlockVerified: {
    padding: '16px',
    borderRadius: '8px',
    backgroundColor: '#d4edda'
  },
  statBlockUnverified: {
    padding: '16px',
    borderRadius: '8px',
    backgroundColor: '#fff3cd'
  },
  statBlockTotals: {
    padding: '16px',
    borderRadius: '8px',
    backgroundColor: '#cce5ff'
  },
  statBlockRating: {
    padding: '16px',
    borderRadius: '8px',
    backgroundColor: '#e2d8f3'
  },
  statHeader: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    marginBottom: '12px'
  },
  statIcon: {
    fontSize: '18px',
    color: '#495057'
  },
  statTitle: {
    fontSize: '16px',
    fontWeight: '600',
    margin: '0',
    color: '#212529'
  },
  statContentInner: {
    marginTop: '8px'
  },
  statGrid: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '16px'
  },
  statSection: {
    padding: '8px',
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    borderRadius: '4px'
  },
  statLabel: {
    fontSize: '14px',
    color: '#6c757d',
    margin: '0 0 4px 0'
  },
  statValue: {
    fontSize: '14px',
    fontWeight: '500',
    color: '#212529',
    margin: '4px 0'
  },
  editContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px'
  },
  bumpedInput: {
    width: '60px',
    padding: '4px',
    border: `1px solid ${style.Color.colorMintGreen}`,
    borderRadius: '4px',
    fontSize: '14px'
  },
  ratingInput: {
    width: '60px',
    padding: '4px',
    border: `1px solid ${style.Color.colorMintGreen}`,
    borderRadius: '4px',
    fontSize: '14px'
  },
  editControls: {
    marginLeft: 'auto'
  },
  editButton: {
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    padding: '4px 8px',
    fontSize: '16px',
    color: style.Color.colorDarkGrey,
    transition: 'color 0.3s'
  },
  saveButton: {
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    padding: '4px 8px',
    fontSize: '16px',
    color: style.Color.colorMintGreen,
    transition: 'color 0.3s'
  },
  cancelButton: {
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    padding: '4px 8px',
    fontSize: '16px',
    color: style.Color.colorDarkGrey,
    transition: 'color 0.3s'
  },
  editActions: {
    display: 'flex',
    gap: '8px'
  },
  ratingSection: {
    padding: '8px',
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    borderRadius: '4px'
  },
  ratingDisplay: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: '8px'
  },
  ratingValue: {
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
    flexGrow: '1'
  },
  ratingStar: {
    color: 'gold'
  },
  ratingEditButton: { 
    marginLeft: '8px'
  },
  ratingDetails: {
    marginTop: '8px',
    fontSize: '13px',
    color: style.Color.colorDarkGrey
  },
  ratingEditContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    width: '100%'
  },
  ratingEditField: {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px'
  },
  ratingEditFieldLabel: {
    fontSize: '13px',
    color: style.Color.colorDarkGrey
  },
  ratingEditActions: {
    alignSelf: 'flex-start',
    marginTop: '24px'
  }
};

// Export styles as an object
export { styles as userStatsStyles };

export default UserStatsDisplay;