import { SHIPPING_ZONES } from './Constants';
import { convertDimension, convertWeight } from './Conversions';
 
export const convertToItems = (wishes) => {
  if (!Array.isArray(wishes) || wishes.length === 0) {
    console.warn("No wishes provided to convertToItems");
    return [];
  }

  return wishes.map(wish => { 
    const specs = wish.product_specifications || {}; 
    const isMetric = specs.dimension_unit === 'CM' || specs.dimension_unit === 'cm';
    const isKg = specs.weight_unit === 'KG' || specs.weight_unit === 'kg'; 
    return {
      id: wish.id,
      group_wish_id: wish.id,  
      name: wish.product_name || 'Unnamed Item',
      length: parseFloat(specs.package_length || 10),
      width: parseFloat(specs.package_width || 10),
      height: parseFloat(specs.package_height || 10),
      weight: parseFloat(specs.package_weight || 1),
      isFragile: specs.is_fragile || false,
      hasBatteries: specs.has_batteries || false,
      isHazardous: specs.is_hazardous || false,
      storageRequirements: specs.storage_requirements || null, 
      dimensionUnit: isMetric ? 'cm' : 'inch',
      weightUnit: isKg ? 'kg' : 'lb'
    };
  });
};

export const getZoneFromZip = (zip) => { 
  const firstDigit = zip.toString().charAt(0); 
  const zoneMap = {
    '0': 'zone1',
    '1': 'zone1',
    '2': 'zone1',
    '3': 'zone2',
    '4': 'zone2',
    '5': 'zone2',
    '6': 'zone3',
    '7': 'zone3',
    '8': 'zone3',
    '9': 'zone3'
  };
  
  const zone = zoneMap[firstDigit] || 'zone1';
  
  return {
    zone: zone,
    name: SHIPPING_ZONES[zone]?.name || 'Unknown Zone'
  };
};

/**
 * Calculate shipping options for a box
 * @param {Object} box - Box with items
 * @returns {Object} Shipping options
 */
export const calculateShippingOptions = (box) => {
  if (!box || !box.billableWeight) {
    return {
      top3Options: [],
      optionsByZone: {}
    };
  }
  
  const billableWeight = box.billableWeight;
  const options = {};
  const top3Options = [];
  
  // Calculate shipping costs for each zone and method
  Object.entries(SHIPPING_ZONES).forEach(([zoneKey, zoneInfo]) => {
    const zoneOptions = [];
    
    // Ground shipping
    const groundRate = zoneInfo.rates.ground || 0;
    const groundCost = 5.99 + (billableWeight * groundRate);
    zoneOptions.push({
      methodName: 'Ground',
      zoneName: zoneInfo.name,
      transitDays: zoneInfo.transit.ground,
      billableWeight: billableWeight,
      cost: groundCost
    });
    
    // Express shipping
    const expressRate = zoneInfo.rates.express || 0;
    const expressCost = 12.99 + (billableWeight * expressRate);
    zoneOptions.push({
      methodName: 'Express',
      zoneName: zoneInfo.name,
      transitDays: zoneInfo.transit.express,
      billableWeight: billableWeight,
      cost: expressCost
    });
    
    // Priority shipping
    const priorityRate = zoneInfo.rates.priority || 0;
    const priorityCost = 22.99 + (billableWeight * priorityRate);
    zoneOptions.push({
      methodName: 'Priority',
      zoneName: zoneInfo.name,
      transitDays: zoneInfo.transit.priority,
      billableWeight: billableWeight,
      cost: priorityCost
    });
    
    options[zoneKey] = zoneOptions;
    
    // Add to top3 options if this is zone1 (default zone)
    if (zoneKey === 'zone1') {
      top3Options.push(...zoneOptions);
    }
  });
  
  // Sort and limit top options
  top3Options.sort((a, b) => a.cost - b.cost);
  
  return {
    top3Options: top3Options.slice(0, 3),
    optionsByZone: options
  };
};

/**
 * Enhanced box selection algorithm based on the shipping calculator approach
 * This should replace the packItemsOptimally function in CalculationHelpers.js
 */
export const packItemsOptimally = (items, availableBoxes) => {
  if (!items?.length) return [];

  try {
    // Convert all dimensions to inches and weights to pounds for consistency
    const convertedItems = items.map(item => {
      const isMetric = item.dimensionUnit === 'cm';
      const isKg = item.weightUnit === 'kg';
      
      return {
        ...item,
        // Convert to inches if necessary
        length: isMetric ? item.length / 2.54 : item.length,
        width: isMetric ? item.width / 2.54 : item.width,
        height: isMetric ? item.height / 2.54 : item.height,
        // Convert to pounds if necessary
        weight: isKg ? item.weight * 2.20462 : item.weight
      };
    });
    
    // Calculate total volume and weight
    const totalWeight = convertedItems.reduce((sum, item) => sum + item.weight, 0);
    
    // Calculate volume for each item
    const itemVolumes = convertedItems.map(item => ({
      item,
      volume: item.length * item.width * item.height
    }));
    
    const totalVolume = itemVolumes.reduce((sum, { volume }) => sum + volume, 0);
    
    // Sort boxes by volume (smallest to largest)
    const sortedBoxes = [...availableBoxes].sort((a, b) => {
      const volumeA = a.length * a.width * a.height;
      const volumeB = b.length * b.width * b.height;
      return volumeA - volumeB;
    });
    
    // Find the smallest box that can fit all items
    const suitableBox = sortedBoxes.find(box => {
      const boxVolume = box.length * box.width * box.height;
      // Box must have enough volume and support the weight
      return boxVolume >= totalVolume * 1.2 && box.maxWeight >= totalWeight;
    });
    
    // If no suitable box was found, use the largest available box
    // This is a fallback option for very large items
    const boxToUse = suitableBox || sortedBoxes[sortedBoxes.length - 1];
    
    // Calculate dimensional utilization
    const boxVolume = boxToUse.length * boxToUse.width * boxToUse.height;
    const volumeUtilization = parseFloat(((totalVolume / boxVolume) * 100).toFixed(1));
    
    // If utilization is too low (<20%) and there are better options, try multiple boxes
    if (volumeUtilization < 20 && items.length > 1) {
      // Try to pack items in multiple smaller boxes
      return packInMultipleBoxes(convertedItems, sortedBoxes);
    }
    
    // Simple 3D placement algorithm for visualization
    const placedItems = placeItems3D(convertedItems, boxToUse);
    
    // Calculate the billable weight
    const dimensionalFactor = 139; // Standard dimensional factor for US domestic
    const dimensionalWeight = boxVolume / dimensionalFactor;
    const billableWeight = Math.max(totalWeight, dimensionalWeight);
    
    // For single box solution
    return [{
      ...boxToUse,
      items: placedItems,
      totalWeight,
      volumeUtilization,
      billableWeight,
      remainingWeight: billableWeight > totalWeight ? billableWeight - totalWeight : 0
    }];
  } catch (error) {
    console.error('Error in packItemsOptimally:', error);
    return [];
  }
};

/**
 * Try packing items in multiple boxes
 * @param {Array} items - Items to pack
 * @param {Array} sortedBoxes - Available boxes (sorted by volume)
 * @returns {Array} - Solution with multiple boxes
 */
const packInMultipleBoxes = (items, sortedBoxes) => {
  // Group similar items together for more efficient packing
  const itemGroups = items.reduce((groups, item) => {
    const key = `${item.length}-${item.width}-${item.height}`;
    if (!groups[key]) groups[key] = [];
    groups[key].push(item);
    return groups;
  }, {});
  
  const solutions = [];
  const remainingItems = [...items];
  
  // Try to pack each group of similar items together
  Object.values(itemGroups).forEach(group => {
    // Sort boxes by volume (smallest first)
    const boxCandidates = [...sortedBoxes].sort((a, b) => {
      const volumeA = a.length * a.width * a.height;
      const volumeB = b.length * b.width * b.height;
      return volumeA - volumeB;
    });
    
    // If group is large enough, try to find a dedicated box
    if (group.length > 1) {
      // Calculate total volume and weight for this group
      const groupVolume = group.reduce((sum, item) => 
        sum + (item.length * item.width * item.height), 0);
      const groupWeight = group.reduce((sum, item) => sum + item.weight, 0);
      
      // Find smallest suitable box
      const suitableBox = boxCandidates.find(box => {
        const boxVolume = box.length * box.width * box.height;
        return boxVolume >= groupVolume * 1.2 && box.maxWeight >= groupWeight;
      });
      
      if (suitableBox) {
        // Calculate utilization
        const boxVolume = suitableBox.length * suitableBox.width * suitableBox.height;
        const volumeUtilization = parseFloat(((groupVolume / boxVolume) * 100).toFixed(1));
        
        // If utilization is decent, use this box
        if (volumeUtilization > 30) {
          // Place items in the box
          const placedItems = placeItems3D(group, suitableBox);
          
          // Calculate billable weight
          const dimensionalFactor = 139;
          const dimensionalWeight = boxVolume / dimensionalFactor;
          const billableWeight = Math.max(groupWeight, dimensionalWeight);
          
          // Add this solution
          solutions.push({
            ...suitableBox,
            items: placedItems,
            totalWeight: groupWeight,
            volumeUtilization,
            billableWeight,
            remainingWeight: billableWeight > groupWeight ? billableWeight - groupWeight : 0
          });
          
          // Remove these items from remaining
          group.forEach(item => {
            const index = remainingItems.findIndex(i => i === item);
            if (index !== -1) remainingItems.splice(index, 1);
          });
        }
      }
    }
  });
  
  // Pack any remaining items
  if (remainingItems.length > 0) {
    const smallerBoxes = sortedBoxes.filter(box => {
      const boxVolume = box.length * box.width * box.height;
      // Only consider boxes smaller than the largest box
      return boxVolume < sortedBoxes[sortedBoxes.length - 1].length * 
                         sortedBoxes[sortedBoxes.length - 1].width * 
                         sortedBoxes[sortedBoxes.length - 1].height;
    });
    
    // Try packing remaining items in a single box first
    const result = tryPackingSingleBox(remainingItems, smallerBoxes);
    if (result) {
      solutions.push(result);
    } else {
      // If can't fit in a single box, pack each item in the smallest possible box
      remainingItems.forEach(item => {
        const suitableBox = sortedBoxes.find(box => 
          box.length >= item.length && 
          box.width >= item.width && 
          box.height >= item.height && 
          box.maxWeight >= item.weight
        ) || sortedBoxes[0]; // Default to smallest box if none fits
        
        const boxVolume = suitableBox.length * suitableBox.width * suitableBox.height;
        const itemVolume = item.length * item.width * item.height;
        const volumeUtilization = parseFloat(((itemVolume / boxVolume) * 100).toFixed(1));
        
        // Calculate billable weight
        const dimensionalFactor = 139;
        const dimensionalWeight = boxVolume / dimensionalFactor;
        const billableWeight = Math.max(item.weight, dimensionalWeight);
        
        solutions.push({
          ...suitableBox,
          items: [{ 
            ...item, 
            position: { x: 0, y: 0, z: 0 },
            rotation: 0
          }],
          totalWeight: item.weight,
          volumeUtilization,
          billableWeight,
          remainingWeight: billableWeight > item.weight ? billableWeight - item.weight : 0
        });
      });
    }
  }
  
  return solutions;
};

/**
 * Try packing all items in a single box
 * @param {Array} items - Items to pack
 * @param {Array} boxes - Available boxes
 * @returns {Object|null} - Box solution or null if not possible
 */
const tryPackingSingleBox = (items, boxes) => {
  // Calculate total volume and weight
  const totalVolume = items.reduce((sum, item) => 
    sum + (item.length * item.width * item.height), 0);
  const totalWeight = items.reduce((sum, item) => sum + item.weight, 0);
  
  // Find suitable box
  const suitableBox = boxes.find(box => {
    const boxVolume = box.length * box.width * box.height;
    return boxVolume >= totalVolume * 1.1 && box.maxWeight >= totalWeight;
  });
  
  if (!suitableBox) return null;
  
  const boxVolume = suitableBox.length * suitableBox.width * suitableBox.height;
  const volumeUtilization = parseFloat(((totalVolume / boxVolume) * 100).toFixed(1));
  
  // Calculate billable weight
  const dimensionalFactor = 139;
  const dimensionalWeight = boxVolume / dimensionalFactor;
  const billableWeight = Math.max(totalWeight, dimensionalWeight);
  
  return {
    ...suitableBox,
    items: placeItems3D(items, suitableBox),
    totalWeight,
    volumeUtilization,
    billableWeight,
    remainingWeight: billableWeight > totalWeight ? billableWeight - totalWeight : 0
  };
};

/**
 * Simplified 3D placement algorithm
 * @param {Array} items - Items to place
 * @param {Object} box - Box to place items in
 * @returns {Array} - Items with position information
 */
const placeItems3D = (items, box) => {
  // Create a simple grid system for placement
  const boxWidth = box.width;
  const boxLength = box.length;
  
  // Simple layer-by-layer placement
  let currentX = 0;
  let currentY = 0;
  let currentZ = 0;
  let layerHeight = 0;
  
  return items.map(item => {
    // Check if item needs to be rotated to fit better
    let rotatedItem = { ...item };
    let rotation = 0;
    
    // Try to rotate item to fit better if needed
    if (currentX + item.length > boxLength && item.width <= boxLength) {
      rotatedItem = {
        ...item,
        length: item.width,
        width: item.length
      };
      rotation = 90;
    }
    
    // If we've reached the end of the row, move to next row
    if (currentX + rotatedItem.length > boxLength) {
      currentX = 0;
      currentY += layerHeight;
      layerHeight = 0;
    }
    
    // If we've reached the end of the layer, move to next layer
    if (currentY + rotatedItem.width > boxWidth) {
      currentX = 0;
      currentY = 0;
      currentZ += layerHeight;
      layerHeight = 0;
    }
    
    // Update the layer height if this item is taller
    layerHeight = Math.max(layerHeight, rotatedItem.height);
    
    // Create positioned item
    const positionedItem = {
      ...item,
      position: { x: currentX, y: currentY, z: currentZ },
      rotation
    };
    
    // Move to next position
    currentX += rotatedItem.length;
    
    return positionedItem;
  });
};




////////
 
const tryPackInSingleBox = (items, boxTemplate) => {
  // Create a deep copy of the box template
  const box = {
    ...boxTemplate,
    items: [],
    totalWeight: 0,
    volumeUtilization: 0
  };
  
  // Check if all items can fit in this box
  const boxVolume = box.length * box.width * box.height;
  let usedVolume = 0;
  let totalWeight = 0;
  
  // Calculate total volume and weight of all items
  for (const item of items) {
    const itemVolume = item.length * item.width * item.height;
    usedVolume += itemVolume;
    totalWeight += item.weight;
  }
  
  // Check if total volume and weight are within box limits
  if (usedVolume > boxVolume || totalWeight > box.maxWeight) {
    return null; // Items don't fit in this box
  }
  
  // Try to place each item using 3D bin packing algorithm
  const placementResult = placeItemsIn3D(items, box);
  
  if (!placementResult.success) {
    return null; // Items don't fit in this box
  }
  
  // Calculate volume utilization and update box information
  box.items = placementResult.items;
  box.totalWeight = totalWeight;
  box.volumeUtilization = parseFloat(((usedVolume / boxVolume) * 100).toFixed(1));
  
  // Calculate billable weight
  const dimensionalFactor = 139; // Standard dimensional factor for US domestic shipments
  const volumeInCubicInches = box.length * box.width * box.height;
  const dimensionalWeight = volumeInCubicInches / dimensionalFactor;
  
  box.billableWeight = Math.max(box.totalWeight, dimensionalWeight);
  box.remainingWeight = box.billableWeight > box.totalWeight 
    ? box.billableWeight - box.totalWeight 
    : 0;
  
  return box;
};
  
const placeItemsIn3D = (items, box) => {
  // Simple implementation for demonstration
  // In a real-world scenario, use a more sophisticated 3D bin packing algorithm
  
  // Check box constraints first
  let totalVolume = 0;
  let totalWeight = 0;
  
  for (const item of items) {
    // Check if item can fit in any orientation
    const canFit = (
      // Try all 6 possible orientations
      (item.length <= box.length && item.width <= box.width && item.height <= box.height) ||
      (item.width <= box.length && item.length <= box.width && item.height <= box.height) ||
      (item.length <= box.length && item.height <= box.width && item.width <= box.height) ||
      (item.height <= box.length && item.length <= box.width && item.width <= box.height) ||
      (item.width <= box.length && item.height <= box.width && item.length <= box.height) ||
      (item.height <= box.length && item.width <= box.width && item.length <= box.height)
    );
    
    if (!canFit) {
      return { success: false, items: [] }; // Item doesn't fit in any orientation
    }
    
    const itemVolume = item.length * item.width * item.height;
    totalVolume += itemVolume;
    totalWeight += item.weight;
  }
  
  // Check if total volume and weight are acceptable
  if (totalVolume > (box.length * box.width * box.height) || totalWeight > box.maxWeight) {
    return { success: false, items: [] };
  }
  
  // In this simplified version, if all items fit in volume and weight,
  // we'll assign positions using a simple layer-by-layer approach
  
  // Track occupied spaces
  const spaceTracker = new SpaceTracker(box.length, box.width, box.height);
  const placedItems = [];
  
  // Try to place each item
  for (let i = 0; i < items.length; i++) {
    const item = { ...items[i] };
    const placement = spaceTracker.findBestPlacement(item);
    
    if (!placement) {
      return { success: false, items: placedItems };
    }
    
    // Apply the placement
    item.position = { 
      x: placement.x,
      y: placement.y,
      z: placement.z
    };
    item.rotation = placement.rotation;
    
    // Update dimensions based on rotation
    if (placement.rotation === 1 || placement.rotation === 3) {
      [item.length, item.width] = [item.width, item.length];
    }
    
    // Mark space as occupied
    spaceTracker.occupySpace(
      placement.x, placement.y, placement.z, 
      item.length, item.width, item.height
    );
    
    placedItems.push(item);
  }
  
  return { success: true, items: placedItems };
};

/**
 * Helper class to track available space in the box
 */
class SpaceTracker {
  constructor(boxLength, boxWidth, boxHeight) {
    this.length = boxLength;
    this.width = boxWidth;
    this.height = boxHeight;
    this.spaces = [{ x: 0, y: 0, z: 0, w: boxWidth, l: boxLength, h: boxHeight }];
  }
  
  findBestPlacement(item) {
    // Try all possible orientations
    const orientations = [
      { l: item.length, w: item.width, h: item.height, r: 0 },
      { l: item.width, w: item.length, h: item.height, r: 1 },
      // Add more orientations if needed
    ];
    
    let bestFit = null;
    let minWastedSpace = Number.MAX_VALUE;
    
    for (const space of this.spaces) {
      for (const orientation of orientations) {
        // Check if item fits in this space with this orientation
        if (
          orientation.l <= space.l &&
          orientation.w <= space.w &&
          orientation.h <= space.h
        ) {
          // Calculate wasted space (higher priority to bottom-left-back corner)
          const wastedSpace = (space.x + space.y + space.z) + 
            (space.l - orientation.l) * (space.w - orientation.w) * (space.h - orientation.h);
          
          if (wastedSpace < minWastedSpace) {
            minWastedSpace = wastedSpace;
            bestFit = {
              x: space.x,
              y: space.y,
              z: space.z,
              space: space,
              orientation: orientation,
              rotation: orientation.r
            };
          }
        }
      }
    }
    
    if (!bestFit) return null;
    
    return {
      x: bestFit.x,
      y: bestFit.y,
      z: bestFit.z,
      rotation: bestFit.rotation
    };
  }
  
  occupySpace(x, y, z, l, w, h) {
    // First, find the space being occupied
    const spaceIndex = this.spaces.findIndex(space => 
      space.x === x && space.y === y && space.z === z &&
      space.l >= l && space.w >= w && space.h >= h
    );
    
    if (spaceIndex === -1) return false;
    
    // Get the space
    const space = this.spaces[spaceIndex];
    
    // Remove this space
    this.spaces.splice(spaceIndex, 1);
    
    // Create up to 6 new spaces (if there's remaining space)
    // Right space
    if (space.l > l) {
      this.spaces.push({
        x: x + l,
        y: y,
        z: z,
        l: space.l - l,
        w: w,
        h: h
      });
    }
    
    // Front space
    if (space.w > w) {
      this.spaces.push({
        x: x,
        y: y + w,
        z: z,
        l: l,
        w: space.w - w,
        h: h
      });
    }
    
    // Top space
    if (space.h > h) {
      this.spaces.push({
        x: x,
        y: y,
        z: z + h,
        l: l,
        w: w,
        h: space.h - h
      });
    }
    
    // ... Add remaining spaces as needed
    
    return true;
  }
}

/**
 * Helper function to pack maximum items into a box
 * @param {Array} items - Items to pack
 * @param {Object} box - Box to pack into
 * @returns {Object} Packing result
 */
const packMaxItemsInBox = (items, box) => {
  // Try different subsets of items to find best packing
  // For demonstration purposes, this is a greedy approach
  
  // Sort items by volume (largest first)
  const sortedItems = [...items].sort((a, b) => 
    (b.length * b.width * b.height) - (a.length * a.width * a.height)
  );
  
  const boxVolume = box.length * box.width * box.height;
  let remainingVolume = boxVolume;
  let remainingWeight = box.maxWeight;
  const packedItems = [];
  
  // In a real implementation, use a more sophisticated algorithm
  // This is a simple first-fit greedy algorithm
  for (const item of sortedItems) {
    // Check if item fits in any orientation
    const itemVolume = item.length * item.width * item.height;
    const canFitVolume = itemVolume <= remainingVolume;
    const canFitWeight = item.weight <= remainingWeight;
    
    // Check if any dimension of the item exceeds the box dimensions
    const canFitDimensions = (
      // Try all 6 possible orientations
      (item.length <= box.length && item.width <= box.width && item.height <= box.height) ||
      (item.width <= box.length && item.length <= box.width && item.height <= box.height) ||
      (item.length <= box.length && item.height <= box.width && item.width <= box.height) ||
      (item.height <= box.length && item.length <= box.width && item.width <= box.height) ||
      (item.width <= box.length && item.height <= box.width && item.length <= box.height) ||
      (item.height <= box.length && item.width <= box.width && item.length <= box.height)
    );
    
    if (canFitVolume && canFitWeight && canFitDimensions) {
      // In a real implementation, check if there's space at a specific position
      // For simplicity, just assume it fits spatially if it fits volumetrically
      
      // Add item to packed items with position
      const packedItem = { 
        ...item,
        position: { x: 0, y: 0, z: 0 }, // Placeholder position
        rotation: 0 // Default rotation
      };
      
      packedItems.push(packedItem);
      remainingVolume -= itemVolume;
      remainingWeight -= item.weight;
    }
  }
  
  // For a more accurate solution, use 3D bin packing to assign real positions
  if (packedItems.length > 0) {
    const spaceTracker = new SpaceTracker(box.length, box.width, box.height);
    
    // Assign positions to each item
    for (let i = 0; i < packedItems.length; i++) {
      const item = packedItems[i];
      const placement = spaceTracker.findBestPlacement(item);
      
      if (placement) {
        item.position = { 
          x: placement.x,
          y: placement.y,
          z: placement.z
        };
        item.rotation = placement.rotation;
        
        // Update dimensions based on rotation
        if (placement.rotation === 1 || placement.rotation === 3) {
          [item.length, item.width] = [item.width, item.length];
        }
        
        // Mark space as occupied
        spaceTracker.occupySpace(
          placement.x, placement.y, placement.z, 
          item.length, item.width, item.height
        );
      } else {
        // If we can't find a placement, remove this item
        packedItems.splice(i, 1);
        i--;
      }
    }
  }
  
  return { packedItems };
};
 
export const updateWeightAlert = (packagingSolution) => {
  if (!packagingSolution || packagingSolution.length === 0) {
    return {
      showAlert: false,
      actualWeight: 0,
      billableWeight: 0,
      remainingWeight: 0,
      boxCount: 0,
      volumeUtilization: 0,
      length: 0,
      width: 0,
      height: 0
    };
  }
 
  const totalActual = packagingSolution.reduce((sum, box) => sum + (box.totalWeight || 0), 0);
  const totalBillable = packagingSolution.reduce((sum, box) => sum + (box.billableWeight || box.totalWeight || 0), 0);
  const totalRemaining = packagingSolution.reduce((sum, box) => sum + (box.remainingWeight || 0), 0); 
  const showAlert = 
    totalBillable > totalActual + 0.1 &&  
    totalRemaining > 0.1;   
  if (packagingSolution.length === 1) {
    const box = packagingSolution[0];
    return {
      showAlert,
      actualWeight: totalActual,
      billableWeight: totalBillable,
      remainingWeight: totalRemaining,
      boxCount: packagingSolution.length,
      volumeUtilization: box.volumeUtilization || 0,
      length: box.length || 0,
      width: box.width || 0,
      height: box.height || 0
    };
  }
   
  return {
    showAlert,
    actualWeight: totalActual,
    billableWeight: totalBillable,
    remainingWeight: totalRemaining,
    boxCount: packagingSolution.length,
    volumeUtilization: 0,
    length: 0,
    width: 0, 
    height: 0
  };
}

 
export const convertManualToItems = (manualItems, units) => {
  return manualItems.map((item, index) => {
    // Extract values, ensuring they're numbers and have reasonable defaults
    const length = parseFloat(item.length) || 1;
    const width = parseFloat(item.width) || 1;
    const height = parseFloat(item.height) || 1;
    const weight = parseFloat(item.weight) || 0.1;
    const name = item.name || `Manual Item ${index + 1}`;
    
    // Convert to inches and pounds for internal calculations
    const lengthInches = units.dimension === 'inch' ? length : convertDimension(length, units.dimension, 'inch');
    const widthInches = units.dimension === 'inch' ? width : convertDimension(width, units.dimension, 'inch');
    const heightInches = units.dimension === 'inch' ? height : convertDimension(height, units.dimension, 'inch');
    const weightLbs = units.weight === 'lb' ? weight : convertWeight(weight, units.weight, 'lb');
    
    return {
      id: `manual-${index}`,
      name: name,
      length: lengthInches,
      width: widthInches,
      height: heightInches,
      weight: weightLbs,
      isFragile: false,
      hasBatteries: false,
      isHazardous: false,
      storageRequirements: '',
      dimensionUnit: 'inch',
      weightUnit: 'lb'
    };
  }).filter(item => 
    // Filter out items with empty name or invalid dimensions
    item.name.trim() !== '' &&
    item.length > 0 && 
    item.width > 0 && 
    item.height > 0 && 
    item.weight > 0
  );
}