import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import AddressSection from "./AddressSection";
import Config from "../../config";
import { useAuth } from '../../components/Common/InitialGears/AuthContext';  
import { getApi, postApi, buildUrl } from "./BackendCalls/apiUtils";

const MultipleAddressesSection = ({ 
  addresses: initialAddresses, 
  primaryAddressId, 
  onAddNewAddress,
  onPrimaryAddressChange,
  onAddressListChange
}) => { 
  const { queryLoginParam, idTokenLoggedIn } = useAuth();
  const [addresses, setAddresses] = useState(initialAddresses); 
  const [isAddingNewAddress, setIsAddingNewAddress] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleAddNewAddress = () => {
    setIsAddingNewAddress(true);
  };
 
  const handleCancel = () => {
    setIsAddingNewAddress(false);
  };

  const deleteAddress = async (addressId) => { 
    setErrorMessage(null);
    setSuccessMessage(null);
    setIsLoading(true);
    let addressUrl = `${Config.apiUrl}deleteAddress?${queryLoginParam}`;  
    const headers = {'Authorization': idTokenLoggedIn, 'Content-Type': 'application/json'};    
    const requestBody = JSON.stringify({address_id: addressId});
    try {
        const response = await fetch(addressUrl, { method: 'POST', headers: headers, body: requestBody });   
        if (response.ok) { 
            setSuccessMessage("Address deleted");
            setAddresses(prevAddresses => prevAddresses.filter(address => address.id !== addressId));
        } else {
          setErrorMessage("Something went wrong. Please try later"); 
        }
    } catch (error) { 
        setErrorMessage('Failed to delete address. Please try again.');
    } finally {
        setIsLoading(false);
    }
  }; 

  const fetchCurrentPrimaryAddress = async () => {
    try {
      const addressUrl = `${Config.apiUrl}address?${queryLoginParam}`;
      const headers = { 'Authorization': idTokenLoggedIn };
      const response = await fetch(addressUrl, { headers });
      const data = await response.json();
      return data.primary_address;
    } catch (error) {
      //console.error('Error fetching current primary address:', error);
      return null;
    }
  };

  const handleSetPrimaryAddress = async (addressId) => {
    setErrorMessage(null);
    setSuccessMessage(null);
    setIsLoading(true);
    try {
      const setPrimaryUrl = buildUrl('setPrimaryAddress', queryLoginParam);
      const requestBody = { address_id: addressId };
      const response = await postApi(setPrimaryUrl, requestBody);
      
      if (response) { 
        // Find the address that will become the new primary
        const newPrimaryAddress = addresses.find(addr => addr.id === addressId);
        
        // Fetch current primary to ensure we have the latest data
        const currentPrimaryAddress = await fetchCurrentPrimaryAddress();
        
        if (newPrimaryAddress) {
          // Update the new primary address's status
          const updatedNewPrimary = {
            ...newPrimaryAddress,
            primary_residence: true
          };

          // Filter out the new primary address from the current addresses
          let updatedAddresses = addresses.filter(addr => addr.id !== addressId);
          
          // If there was a previous primary address, add it to other addresses
          if (currentPrimaryAddress) {
            const oldPrimaryAsNonPrimary = {
              ...currentPrimaryAddress,
              primary_residence: false
            };
            // Only add old primary if it's not already in the list
            const oldPrimaryExists = updatedAddresses.some(addr => addr.id === oldPrimaryAsNonPrimary.id);
            if (!oldPrimaryExists) {
              updatedAddresses = [...updatedAddresses, oldPrimaryAsNonPrimary];
            }
          }
          
          // Update the addresses state
          setAddresses(updatedAddresses);
          
          // Notify parent component about the change
          if (onPrimaryAddressChange) {
            onPrimaryAddressChange(updatedNewPrimary, currentPrimaryAddress);
            // Call parent's fetchAddresses to ensure everything is in sync
            if (onAddressListChange) {
              onAddressListChange();
            }
          }
        }
        
        setSuccessMessage("Primary address updated successfully");
      } else {
        setErrorMessage("Failed to update primary address. Please try again.");
      }
    } catch (error) {
      setErrorMessage("An error occurred while updating the primary address");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="space-y-4">      
        <div className="w-full p-4">
          {isLoading && <div className="text-gray-600">Loading...</div>}
          {successMessage && <div className="bg-green-100 text-green-700 p-3 rounded">{successMessage}</div>}
          {errorMessage && <div className="bg-red-100 text-red-700 p-3 rounded">{errorMessage}</div>}
          
          {addresses.length > 0 ? (
            addresses.map((address) => (
              <div key={address.id} className="bg-white rounded-lg shadow p-4 mb-4">
                <p className="text-gray-700">
                  {address.address1}
                  {address.address2 && `, ${address.address2}`}
                  {`, ${address.city}, ${address.state} ${address.postal_code}`}
                </p>
                <div className="flex justify-between items-center mt-2">
                  {address.id === primaryAddressId ? (
                    <span className="text-green-600 font-semibold">Primary</span>
                  ) : (
                    <button 
                      onClick={() => handleSetPrimaryAddress(address.id)}
                      className="text-blue-600 hover:text-blue-800"
                    >
                      Set as Primary
                    </button>
                  )}
                  <button 
                    onClick={() => deleteAddress(address.id)}
                    className="text-red-600 hover:text-red-800"
                  >
                    <FontAwesomeIcon icon={faTrashAlt} />
                  </button>
                </div>
              </div>
            ))
          ) : (
            <p className="text-gray-600 italic">No additional addresses found.</p>
          )}
          
          {isAddingNewAddress ? (
            <AddressSection 
              address={{}}  
              isPrimaryAddress={false} 
              handleAddressSave={onAddNewAddress}  
              onCancel={handleCancel}  
              isNewAddress={true}
            />
          ) : (
            <button 
              onClick={handleAddNewAddress} className="w-full bg-[#155f8a] hover:bg-[#0e4c70] text-white font-semibold py-2 px-8 rounded" >
            
              + Add New Address
            </button>
          )}
        </div>
    </div>
  );
};

export default MultipleAddressesSection;