import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Timer, Package, DollarSign, ShoppingCart, Calculator, PercentIcon } from 'lucide-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {   faUsers,   faTimes, faFire,faHeart, faHeartCircleCheck } from '@fortawesome/free-solid-svg-icons'; 
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { getApi, postApi, buildUrl } from "../../Common/BackendCalls/apiUtils";
import { TopMenuBar } from '../DashbordComponents/TopMenuBar';
import { Color, RoseGoldButton } from "../../../GlobalStyles";
import AddressSection from '../../Common/AddressSection';
import { useAuth } from '../../../components/Common/InitialGears/AuthContext';  
import ComboProgressChart from './ComboProgressChart'; 
import TermsAndConditionsModal from '../GroupWishes/JoiningTermsAndConditions';
import UserPreferencesScreen from '../GroupWishes/UserPreferenceScreen';
import RatingSlider from '../GroupWishes/RatingSlider';
import ComboRatingDisplay from './ComboRatingDisplay';
import ComboJoinModal from './ComboJoinModal';
import ComboMediaGallery from './ComboMediaGallery';
import { ComboPreferences, getPreferencesStyles } from './ComboPreferences';

const ComboDetails = () => { 
    const location = useLocation();
    const initialLoadRef = useRef(false);
    const progressUpdateNeededRef = useRef(false);
    const initialComboDataRef = useRef(location.state?.comboData); 
    const [shouldUpdateProgress, setShouldUpdateProgress] = useState(false);
    const navigate = useNavigate();    
    const [isLoading, setIsLoading] = useState(false);
    const { queryLoginParam, userVerified } = useAuth();
    const initialComboData = location.state?.comboData; 
    const [successMessage, setSuccessMessage] = useState(null); 
    const [errorMessage, setErrorMessage] = useState(null);
    const [showJoinModal, setShowJoinModal] = useState(false);
    const [calculatedCosts, setCalculatedCosts] = useState(null);
    const [comboQuantity, setComboQuantity] = useState(1); 
    const [addresses, setAddresses] = useState([]);
    const [quantities, setQuantities] = useState({});
    const [showAddressSection, setShowAddressSection] = useState(false);
    const [showAddressModal, setShowAddressModal] = useState(false);
    const [comboData, setComboData] = useState(initialComboData || {});
    const [showTermsModal, setShowTermsModal] = useState(false);
    const [showPreferencesModal, setShowPreferencesModal] = useState(false);
    const [selectedComboForJoin, setSelectedComboForJoin] = useState(null);
    const [useRedeemCredit, setUseRedeemCredit] = useState(false);
    const [availableCredit, setAvailableCredit] = useState(0);
    const [showRating, setShowRating] = useState(false); 
    const [userRejoining, setUserRejoining] = useState(false); 
    const [joined, setIsJoined] = useState(location.state?.joined || false);  
    const [buddyStatus, setBuddyStatus] = useState(initialComboDataRef.current?.progress_stats?.group_wish_buddy_status || null);   
    const [userJoined, setUserJoined] = useState(initialComboDataRef.current?.progress_stats?.user_already_joined || null);  
    const [buttonsKey, setButtonsKey] = useState(0); 
    const [DiffDays, setDiffDays] = useState("0");  
    const [isFavoriteProcessing, setIsFavoriteProcessing] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);   
    const [isHovered, setIsHovered] = useState(false); 
    const preferencesStyles = getPreferencesStyles();
    const [showJoinButton, setShowJoinButton] = useState(() => {if (location.state?.showJoinButton !== undefined) { return location.state.showJoinButton; } return !joined || buddyStatus === 'WITHDRAWN';});    
    const [modalData, setModalData] = useState({selectedRange: null,selectedQuantity: '',selectedAddress: null,calculatedCosts: null,showAddressSection: false,externalUnitPrice: comboData?.list_price || 0});       
    
    
    useEffect(() => {
      if (!initialComboDataRef.current) { 
        navigate('/dashboard');
        return;
      } 
      setIsLoading(false); 
      const fetchInitialData = async () => {
        try {
          if (!initialLoadRef.current) {
            await fetchAddresses();
            await fetchAvailableCredit();
            initialLoadRef.current = true;
          }
        } catch (error) {
          //console.error("Error fetching initial data:", error);
        }
      };
      
      fetchInitialData();
  }, []);
  

     useEffect(() => {
      if (comboData?.combo_items && !Object.keys(quantities).length) {
          const initialQuantities = {};
          comboData.combo_items.forEach(item => {
              initialQuantities[item.id] = 1;
          });
          setQuantities(initialQuantities);
      }
  }, [comboData?.combo_items, quantities]); 
 
  useEffect(() => {  
    if (comboData?.group_wish_buddy_status && 
        comboData.group_wish_buddy_status !== buddyStatus) {
        setBuddyStatus(comboData.group_wish_buddy_status);
    }
}, [comboData?.group_wish_buddy_status, buddyStatus]);
 
  useEffect(() => { 
        if (comboData?.group_wish_buddy_status) {
            setBuddyStatus(comboData.group_wish_buddy_status);
        }
  }, [comboData]);
 
  useEffect(() => {
      if (joined && comboData?.progress_stats) {
        setShouldUpdateProgress(true);
      }
  }, [joined, comboData?.progress_stats]);
          
  useEffect(() => { 
      if (buddyStatus === 'JOINED' || buddyStatus === 'WITHDRAWN' || buddyStatus === 'RE_JOINED') {
          setButtonsKey(prevKey => prevKey + 1);
      }
  }, [buddyStatus]);

  useEffect(() => {
      if (progressUpdateNeededRef.current) {
        setComboData(prevData => {
          if (!prevData.progress_stats) return prevData; 
          const updatedData = {
            ...prevData,
            progress_stats: {
              ...prevData.progress_stats,
              totalJoined: Math.max(1, prevData.progress_stats.totalJoined),
              verifiedUsers: userVerified ? Math.max(1, prevData.progress_stats.verifiedUsers) : prevData.progress_stats.verifiedUsers,
              unverifiedUsers: !userVerified ? Math.max(1, prevData.progress_stats.unverifiedUsers) : prevData.progress_stats.unverifiedUsers,
              progress: Math.max(prevData.progress_stats.progress, (1 / prevData.min_combo_order_qty) * 100)
            }
          }; 
          progressUpdateNeededRef.current = false;
          return updatedData;
        });
      }
  }, [progressUpdateNeededRef.current, userVerified]);  

    useEffect(() => { 
      const comboId = location.state?.comboId; 
      if (comboId && (!comboData || Object.keys(comboData).length === 0)) {
        const fetchComboDetails = async () => {
          setIsLoading(true);
          try {
            const comboUrl = buildUrl(`getComboDetails/${comboId}`, queryLoginParam);
            const response = await getApi(comboUrl);
            
            if (response.ok) {
              setComboData(response.data);
              setIsJoined(true);  
              setBuddyStatus('JOINED');
            } else {
              setErrorMessage('Failed to load combo details.');
            }
          } catch (error) {
            setErrorMessage('Failed to load combo details.');
          } finally {
            setIsLoading(false);
          }
        };
        
        fetchComboDetails();
      }
    }, [location.state?.comboId]);

    useEffect(() => {
      if (!initialComboDataRef.current) { 
        navigate('/dashboard');
        return;
      } 
      setIsLoading(true); 
      const fetchInitialData = async () => {
        try {
          if (!initialLoadRef.current) {
            await fetchAddresses();
            await fetchAvailableCredit();
            initialLoadRef.current = true; 
            if (location.state?.showJoinModal) {
              setShowJoinModal(true);
              setIsLoading(false);
            }
          }
        } catch (error) {
          //console.error("Error fetching initial data:", error);
        }
      };
      
      fetchInitialData();
    }, []);

    useEffect(() => { 
      if (joined && comboData?.progress_stats && !progressUpdateNeededRef.current) {
        progressUpdateNeededRef.current = true;
      }
    }, [joined, comboData?.progress_stats]);

    const fetchAddresses = useCallback(async () => {
      setErrorMessage(null);
      setSuccessMessage(null);
        try {
          const addressesUrl = buildUrl('address', `${queryLoginParam}&type=completed`);
          const response = await getApi(addressesUrl);
          
          if (response.ok) {
            const data = response.data;
            const primaryAddress = data.primary_address ? [data.primary_address] : [];
            const otherAddresses = Array.isArray(data.other_addresses) ? data.other_addresses : [];

            setAddresses([...primaryAddress, ...otherAddresses]);
          } else { 
            setAddresses([]);
          }
        } catch (error) { 
          setAddresses([]);
        } finally {
          setIsLoading(false);
        }
    }, [queryLoginParam]);
    const formatCurrency = (amount) => {
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD'
        }).format(amount);
    };

    const formatDate = (dateString) => {
        return new Date(dateString).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        });
    };   
 
    const handleJoinCombo = () => { 
        setErrorMessage(null); 
        if(!userVerified){
          setErrorMessage("Please verify your account to proceed");
          return;
        }
        if (!modalData.selectedAddress || !calculatedCosts) { 
          setShowJoinModal(true);
        } else { 
          setSelectedComboForJoin(comboData);
          setShowTermsModal(true);
        }
    };

const handleEditPreferences = () => {
      const userPreferences = comboData.progress_stats?.usersChoices || [];
      if (userPreferences.length > 0 && userPreferences[0].quantity) {
        setComboQuantity(userPreferences[0].quantity);
      }
      setShowPreferencesModal(true);
};

const handlePreferencesClose = (success) => {
  setShowPreferencesModal(false);
  if (success) { 
    if (success.combo && success.combo.userPreferences) {
      setComboData(prevData => ({
        ...prevData,
        userPreferences: success.combo.userPreferences, 
        progress_stats: {
          ...prevData.progress_stats,
          usersChoices: success.combo.userPreferences
        }
      }));
      setSuccessMessage('Successfully updated your preferences!');
    } else if (success.preferences) { 
      setComboData(prevData => ({
        ...prevData,
        progress_stats: {
          ...prevData.progress_stats,
          usersChoices: success.preferences
        }
      }));
      setSuccessMessage('Successfully updated your preferences!');
    } else if (Array.isArray(success)) { 
      setComboData(prevData => ({
        ...prevData,
        progress_stats: {
          ...prevData.progress_stats,
          usersChoices: success
        }
      }));
      setSuccessMessage('Successfully updated your preferences!');
  }
  }
};

const handleCalculateFromModal = (calculatedCostsData, selectedAddressData, comboQuantityValue, useRedeemCreditValue) => {
      setCalculatedCosts(calculatedCostsData);
      setModalData(prevData => ({
        ...prevData,
        selectedAddress: selectedAddressData
      }));
      setComboQuantity(comboQuantityValue);
      setUseRedeemCredit(useRedeemCreditValue);
      if (comboData) {
        const updatedSavings = calculateTotalSavingsForQty(comboQuantityValue);
        calculatedCostsData.savings = updatedSavings;
      }
    };

const handleJoinFromModal = (calculatedCostsData, selectedAddressData, comboQuantityValue, useRedeemCreditValue) => {
      setCalculatedCosts(calculatedCostsData);
      setModalData(prevData => ({
        ...prevData,
        selectedAddress: selectedAddressData
      }));
      setComboQuantity(comboQuantityValue);
      setUseRedeemCredit(useRedeemCreditValue); 
      setSelectedComboForJoin(comboData);
      setShowTermsModal(true);
    };
    
const handleRate = async (rating) => {
        if (!userVerified) {
          setErrorMessage('Please verify your account to rate this combo');
          return;
        }
        await rateCombo(comboData.id, rating);
        setShowRating(false);
    };

    const handleAgreeTerms = async () => {
        setIsLoading(true);
        setErrorMessage(null);      
        if (!modalData.selectedAddress) {
          setErrorMessage('Please select an address before joining');
          setIsLoading(false);
          return;
        }
        try {  
          if(userRejoining){
            const rejoinComboUrl = buildUrl('reJoinCombo', queryLoginParam);
            const comboRequestBody = {
              combo_id: comboData.id,
              quantity: comboQuantity,
              total_cost: calculatedCosts?.total || 0,
              address_id: modalData.selectedAddress.id,
              use_redeem_credit: useRedeemCredit 
            };
            const comboResponse = await postApi(rejoinComboUrl, comboRequestBody);          
            if (!comboResponse.ok) {
              throw new Error('Failed to re-join combo');
            }        
            setShowTermsModal(false);
            setShowPreferencesModal(true);
            setIsJoined(true);
            setBuddyStatus('RE_JOINED'); 
            setUserRejoining(false);           
        }else{
          const joinComboUrl = buildUrl('joinCombo', queryLoginParam);
          const comboRequestBody = {
            combo_id: comboData.id,
            quantity: comboQuantity,
            total_cost: calculatedCosts?.total || 0,
            address_id: modalData.selectedAddress.id,
            use_redeem_credit: useRedeemCredit 
          };
      
          const comboResponse = await postApi(joinComboUrl, comboRequestBody);          
          if (!comboResponse.ok) {
            throw new Error('Failed to join combo');
          }
          else{
            setShowTermsModal(false);
            setShowPreferencesModal(true);
            setIsJoined(true);
            setBuddyStatus('JOINED');
          }
        }
        } catch (error) { 
          setErrorMessage('Failed to join combo. Please try again.');
        } finally {
          setIsLoading(false);
        }
    };

  const handleAddressSave = useCallback(async (newAddress) => { 
    setIsLoading(true);
    setErrorMessage(null);
    setSuccessMessage(null);
    const addressUrl = buildUrl('address', queryLoginParam);
    const requestBody = {
      address1: newAddress.address1,
      address2: newAddress.address2,
      city: newAddress.city,
      state: newAddress.state,
      country: newAddress.country,
      postal_code: newAddress.postal_code,
      primary_residence: false,
    };
     
    try {
      const response = await postApi(addressUrl, requestBody);

      if (response.ok) {
        setAddresses(prevAddresses => [...prevAddresses, response.data]);  
        setModalData(prevData => ({
          ...prevData,
          selectedAddress: response.data
        })); 
        setShowAddressSection(false);
        await fetchAddresses();
      } else {
        setErrorMessage('Failed to update address. Please try again.');
      }
    } catch (error) { 
      setErrorMessage('Failed to update address. Please try again.');
    } finally {
      setIsLoading(false);
    }
  }, [queryLoginParam]);

  const rateCombo = useCallback(async (comboId, rating) => {
        setIsLoading(true);
        setErrorMessage(null);
        setSuccessMessage(null);
        const rateComboURL = buildUrl('rateCombo', queryLoginParam);
        const requestBody = {
          combo_id: comboId,
          is_upvote: rating >= 0,
          rating: rating
        };
    
        try {
          const response = await postApi(rateComboURL, requestBody);
    
          if (response.ok) { 
            setComboData(prevData => ({
              ...prevData,
              upvotes: response.data.upvotes || prevData.upvotes,
              downvotes: response.data.downvotes || prevData.downvotes,
              averageRating: response.data.averageRating || prevData.averageRating,
            }));
            setSuccessMessage('Rating submitted successfully!');
          } else {
            setErrorMessage('Failed to submit rating. Please try again.');
          }
        } catch (error) { 
          setErrorMessage('Failed to submit rating. Please try again.');
        } finally {
          setIsLoading(false);
          setShowRating(false);
        }
  }, [queryLoginParam]);
        
  const handleWithdrawFromGroup = useCallback(async () => {
    setErrorMessage(null);
    setSuccessMessage(null);
        const confirmWithdraw = window.confirm(
          "Once you withdraw, you can only rejoin the waitlist and may or may not be added to the active order. Are you sure you want to withdraw?"
        );
      
        if (!confirmWithdraw) {
          return;  
        }
      
        setIsLoading(true);
        try {
          const withdrawUrl = buildUrl('withdrawCombo', queryLoginParam);
          const requestBody = {
            combo_id: comboData.id
          };
      
          const response = await postApi(withdrawUrl, requestBody);
      
          if (response.ok) {
            setIsJoined(false);
            setBuddyStatus('WITHDRAWN');
            setShowJoinButton(true);
            setSuccessMessage('You have successfully withdrawn from the combo. If you wish to rejoin, you will be added to the waitlist.');         
          } else {
            setErrorMessage('Failed to withdraw from the combo. Please try again.');
          }
        } catch (error) { 
          setErrorMessage('Failed to withdraw from the combo. Please try again.');
        } finally {
          setIsLoading(false);
        }
  }, [comboData.id, queryLoginParam]);

  const fetchAvailableCredit = useCallback(async () => {
        try {
          const creditUrl = buildUrl('creditDetails', queryLoginParam);
          const response = await getApi(creditUrl);          
          if (response.ok) {
            setAvailableCredit(response.data.availableCredit);
          } else {
            setAvailableCredit(0);
          }
        } catch (error) {
          setAvailableCredit(0);
        }
  }, [queryLoginParam]);
       
useEffect(() => {
    if (comboData && !initialLoadRef.current) {
      fetchAddresses();
      fetchAvailableCredit();
      initialLoadRef.current = true;
    }
}, [comboData, fetchAddresses, fetchAvailableCredit]);
      
const calculateTotalSavings = () => {
        if (!comboData) return 0;
        let totalSavings = 0;
        totalSavings += (comboData.bundle_mrp || 0) - (comboData.bundle_rate || 0);       
        return (totalSavings )
};
    
const calculateTotalSavingsPercentage = () => {
  if (!comboData || !comboData.bundle_mrp || comboData.bundle_mrp === 0) return "0.00";
  let totalSavings = (comboData.bundle_mrp || 0) - (comboData.bundle_rate || 0); 
  return ((totalSavings / comboData.bundle_mrp) * 100).toFixed(2); 
};

const calculateTotalSavingsForQty = () => {
  if (!comboData) return 0;
  let totalSavings = 0;
  totalSavings += (comboData.bundle_mrp || 0) - (comboData.bundle_rate || 0);       
  return (totalSavings * comboQuantity)  
};

const handleRejoinGroup = useCallback(async () => {
  if (!comboData || !comboData.id) { 
      setErrorMessage('Unable to re-join the combo.');
      return;
  }  
  setUserRejoining(true); 
  setShowJoinModal(true); 
}, [comboData, modalData.selectedAddress, calculatedCosts]);

const handleReferAFriend = (item) => {  
  navigate("/invites", { state: { comboData: item } });
};

  const handleComboQuantityChange = (e) => {
        const value = Math.max(1, parseInt(e.target.value) || 1);
        setComboQuantity(value);
  };

 
  const getFavoriteButtonStyle = (item, isHovered) => {
    const baseTransform = item.is_favorite ? 'scale(1.1)' : 'scale(1)';
    const hoverTransform = 'scale(1.15)';
    
    return {
      ...baseFavoriteButtonStyle,
      color: item.is_favorite ? Color.colorPeacockBlue : Color.colorPurple,
      transform: isHovered ? hoverTransform : baseTransform,
       
      backgroundColor: isProcessing 
        ? Color.colorCoolBeige 
        : isHovered 
          ? Color.colorCoolBeige 
          : Color.colorWhite,
      opacity: isProcessing ? 0.7 : 1,
      cursor: isProcessing ? 'not-allowed' : 'pointer',
    };
  };

const toggleFavorite = async (comboId, isFavorite) => { 
  setErrorMessage('');
  setSuccessMessage('');
  if (isFavoriteProcessing || !comboId) return;
  setIsFavoriteProcessing(true);  
  try {
    const favoriteUrl = buildUrl(`${isFavorite ? 'removeFromfavoriteCombos' : 'addTofavoriteCombos'}`, queryLoginParam );        
    const response = await postApi(favoriteUrl, { 
      combo_id: comboId
    });  
    if (response.ok) {
      const responseData = response.data; 
      if (responseData.error === "Combo already in favorites") {
        setSuccessMessage('Combo already a favorite');
        return true;
      } 
      setSuccessMessage('Combo added to favorites list');
      return !isFavorite;
    } 

   return !isFavorite;  
   
 } catch (error) { 
   setErrorMessage('Error updating favorites. Please try again.');
   return isFavorite;  
 } finally {
   setTimeout(() => {
     setIsFavoriteProcessing(false);
   }, 500);
 }
};

  const renderButtons = useCallback(() => {
        if (!showJoinButton) return null;  
        if (buddyStatus === null || buddyStatus === 'WITHDRAWN') {
          return (
            <button className="button join-button"  style={{...RoseGoldButton, display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '8px', flex: '2', }} onClick={() => buddyStatus === 'WITHDRAWN' ? handleRejoinGroup() : handleJoinCombo()} >
              <ShoppingCart size={18} />
              <span>{buddyStatus === 'WITHDRAWN' ? 'Rejoin Group' : 'Proceed to Join'}</span>
            </button>
          );          
        }

        if (buddyStatus === 'WAITLISTED') {
          return (
            <button className="button join-button"  style={{...RoseGoldButton, display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '8px', flex: '2', }} onClick={() =>  handleRejoinGroup() } >
              <ShoppingCart size={18} />
              <span>Rejoin Group</span>
            </button>
          );          
        }
        if (buddyStatus === 'RE_JOINED' || buddyStatus === 'JOINED') {
          return( 
            <button style={{...RoseGoldButton, display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '8px', flex: '0.5', }} onClick={() => handleWithdrawFromGroup()} >             
            <FontAwesomeIcon icon={faTimes} size="sm" />
              <span>Withdraw</span>
            </button>  
          );
        }
        return null;
  }, [buddyStatus, showJoinButton, handleRejoinGroup, handleJoinCombo]);

  const ComboHeader = () => {   
    const isFavorite = comboData.progress_stats.is_favorite;  
        return (
          <div style={styles.fullWidthHeaderRow}>
            <div style={styles.headerContent}>
              <div style={styles.headerTitleRow}>
                <h1 style={styles.headerTitle}>{comboData?.title || 'Combo'}</h1>
                 
                <div style={styles.headerRightSection}>
                  <div style={styles.statusBadgeContainer}>
                    <span style={styles.statusBadge}>
                      {(comboData?.progress_stats?.verification_score || 0) + '%  ' || 'Active  '}
                      <FontAwesomeIcon icon={faFire} style={styles.fireIconStyle} />
                    </span>
                  </div>
                  <button 
                    style={getFavoriteButtonStyle({...comboData, is_favorite: isFavorite}, isHovered, isProcessing)}  
                    onMouseEnter={() => setIsHovered(true)}  
                    onMouseLeave={() => setIsHovered(false)} 
                    onClick={async (e) => { 
                      if (isProcessing) return;
                      e.preventDefault();  
                      e.stopPropagation(); 
                      setIsProcessing(true);                
                      try {
                        const newFavoriteState = await toggleFavorite(comboData.id, isFavorite); 
                        comboData.is_favorite = newFavoriteState;
                      } finally {
                        setIsProcessing(false);
                        setIsHovered(false);
                      } 
                    }} 
                    disabled={isProcessing}
                  >
                    <FontAwesomeIcon 
                      icon={isProcessing ? faSpinner : (isFavorite ? faHeartCircleCheck : faHeart)} 
                      style={{
                        color: isFavorite ? Color.colorPeacockBlueDark : Color.colorPurple, 
                        animation: isProcessing ? 'spin 1s linear infinite' : 'none' 
                      }}
                    />
                  </button>
                </div>
              </div>
              {/* Messages section */}
              <div style={styles.messagesContainer}>
                {successMessage && (
                  <div style={styles.successMessage}>{successMessage}</div>
                )}
                {errorMessage && (
                  <div style={styles.errorMessage}>{errorMessage}</div>
                )} 
              </div>
            
            <div style={styles.infoGrid}>
              <div style={styles.infoItem}>
                <Timer size={20} color="#6b7280" />
                <div>
                  <p style={styles.infoLabel}>Expires</p>
                  <p style={styles.infoValue}>{comboData?.expires_at ? formatDate(comboData.expires_at) : 'N/A'}</p>
                </div>
              </div>
              
              <div style={styles.infoItem}>
                <Package size={20} color="#6b7280" />
                <div>
                  <p style={styles.infoLabel}>Order fulfillment needs </p>
                  <p style={styles.infoValue}>{comboData?.min_combo_order_qty || 0} combos</p>
                </div>
              </div>
              
              <div style={styles.infoItem}>
                <DollarSign size={20} color="#6b7280" />
                <div>
                  <p style={styles.infoLabel}>MRP </p>
                  <p style={styles.infoValue}>{comboData?.bundle_mrp}</p>
                </div>
              </div>
            </div>
            <div style={styles.infoGrid}> 
              <div style={styles.infoItem}>
                <DollarSign size={20} color="#6b7280" />
                <div>
                  <p style={styles.infoLabel}>Bundle Rate</p>
                  <p style={styles.infoValue}>
                    {formatCurrency(comboData?.bundle_rate || 0)}
                  </p>
                </div>
              </div>  
              <div style={styles.infoItem}>
                <DollarSign size={20} color="#6b7280" />
                <div>
                  <p style={styles.infoLabel}>Total Savings</p>
                  <p style={styles.savingsValue}>
                    {formatCurrency(calculateTotalSavings())}
                  </p>
                </div>
              </div>
              <div style={styles.infoItem}> 
                <div>
                  <p style={styles.infoLabel}>Savings</p>
                  <p style={styles.savingsValue}>
                    {calculateTotalSavingsPercentage()}%
                  </p>
                </div>
              </div>
            </div>   
            </div>         
            {comboData?.description && (
                <div style={styles.description}>
                    <div style={{...styles.descriptionText, minHeight: '100%'}}>
                    {comboData.description}
                    </div>                     
                </div>
            )}            
          </div>
          
        );
  };
 
const ItemCard = ({ item }) => {
  const [showDescription, setShowDescription] = useState(false); 
  const [showMediaGallery, setShowMediaGallery] = useState(false);
  
  if (!item || !item.group_wish) {
    return null;
  }    

  const details = item.group_wish;
  const itemDiscount = parseFloat(item.item_discount || 0);
  const comboPrice = details.list_price - itemDiscount; 
  
  const toggleDescription = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowDescription(!showDescription);
  };   
  
  const toggleMediaGallery = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowMediaGallery(!showMediaGallery);
  };   
  const additionalMedia = Array.isArray(details.additional_media) ? 
    details.additional_media.map((media, index) => ({
      uuid: media.uuid || `media-${index}`,
      media_url: media.image_url,
      media_type: media.media_type || 'IMAGE'
    })) : [];
  const hasAdditionalMedia = additionalMedia.length > 0;
  
  return (
    <div style={styles.itemCard}> 
      <div style={styles.imageWrapper} onClick={toggleMediaGallery}>
        <img 
          src={details.image_url} 
          alt={details.product_name} 
          style={styles.itemImage} 
          onError={(e) => {e.target.src = "/api/placeholder/96/96";}} 
        />
        <div style={styles.imageOverlay}>
          <span style={styles.viewText}>
            {hasAdditionalMedia ? "View Gallery" : "View Image"}
          </span>
        </div>
      </div>           
      <div style={styles.itemDetails}>
        <h3 style={styles.itemName}>{details.product_name}</h3>                
        <div style={styles.priceGrid}>
          <div>
            <p style={styles.priceLabel}>Market Price</p>
            <p style={styles.priceValue}>{formatCurrency(details.list_price)}</p>
          </div> 
          <div style={styles.quantityContainer} onClick={toggleDescription}>
            {showDescription ? (
              <>
                <i className="fa fa-eye-slash" style={styles.infoIcon}></i>
                <span style={styles.quantityDisplay}>
                  Hide Details
                </span>
              </>
            ) : (
              <>
                <Package size={16} color={Color.colorDarkBlue} />
                <span style={styles.quantityDisplay}>
                  {quantities[item.id] || 1} | View Details
                </span>
              </>
            )}
          </div>
        </div>
          
        {showDescription && (
          <div style={styles.descriptionContainer}>
            <p style={styles.descriptionText}>
              {details.description}
            </p>
          </div>
        )}
      </div>

      {/* Media Gallery Modal */}
      {showMediaGallery && (
        <div style={styles.galleryModalOverlay} onClick={toggleMediaGallery}>
          <div style={styles.galleryModalContent} onClick={e => e.stopPropagation()}>
            <button style={styles.closeGalleryButton} onClick={toggleMediaGallery}>
              <FontAwesomeIcon icon={faTimes} />
            </button>
            <ComboMediaGallery 
              mainImageUrl={details.image_url} 
              additionalMedia={additionalMedia} 
            />
          </div>
        </div>
      )}
    </div>
  );
};

    const MemoizedComboHeader = React.memo(ComboHeader); 
    if (!comboData) {
        return <div style={styles.loading}>Loading combo details...</div>;
    }
     
    return (
      <>
      <TopMenuBar title={"Combo Details"} showBackButton={false}  showHomeButton={true} showMenuButton={true}  onBackClick={() => navigate(-1)}  showSearch={false} showAddress={false} />                         
        <div style={styles.container}>
           {/* Row 1: Header and Calculator */}
          <div style={styles.gridRow}>
            <div style={styles.leftPanel}>
              <MemoizedComboHeader />
            </div>
            <div style={styles.rightPanelTop}> 
              <ComboProgressChart 
                progressStats={{
                  verifiedUsers: comboData?.progress_stats?.verifiedUsers || 0,
                  unverifiedUsers: comboData?.progress_stats?.unverifiedUsers || 0,
                  totalJoined: comboData?.progress_stats?.totalJoined || 0,
                  totalWaitListed: comboData?.progress_stats?.totalWaitListed || 0,  
                  progress: comboData?.progress_stats?.progress || 0,
                  averageRating: comboData?.progress_stats?.averageRating || 0
                }}
                comboData={{
                  joined_users: comboData?.joined_users || 0,
                  min_combo_size: comboData?.min_combo_size || 0,
                  combo_expiry: comboData?.expires_at || null
                }} 
              />
              <div style={styles.ratingDisplayWrapper}>
              <ComboRatingDisplay 
                  averageRating={comboData?.progress_stats?.averageRating || 4.8}  
                  ratingCounts={comboData?.progress_stats?.rating_counts?.length 
                    ? comboData?.progress_stats?.rating_counts 
                    : ["5 stars 12 users", "4 stars 14 users", "3 stars 1 users", "2 stars 0 users", "1 stars 0 user"]} 
                  upvotes={comboData?.progress_stats?.upvotes || (Math.floor(Math.random() * 14) + 3)} 
              />
              </div>
            </div>             
          </div>
       
          <div style={styles.gridRow}>
            <div style={styles.leftPanel}>
              <div style={styles.itemsCard}>
                <h2 style={styles.itemsTitle}>Included Items ({comboData?.min_combo_size || 0})</h2>
                <div style={styles.itemGrid}>
                  {comboData?.combo_items?.map(item => (
                    <ItemCard key={item?.id} item={item} />
                  ))}
                </div>
              </div>
            </div>
            <div style={styles.rightPanel}>              
              {buddyStatus === 'JOINED' || buddyStatus === 'RE_JOINED' || (userJoined === true && buddyStatus !== 'WITHDRAWN') ? (
                  <ComboPreferences comboData={comboData} userJoined={true} userPreferences={comboData.userPreferences ||comboData.progress_stats?.usersChoices || []} onEditPreferences={handleEditPreferences} styles={preferencesStyles} />
                ) : (
                  <>
                    <ComboPreferences comboData={comboData} userJoined={false} styles={preferencesStyles} />                    
                </>
              )}              
            </div>
          </div>
       
          <div className="action-buttons-container" style={styles.buttonsContainer}>       
            <div className="button-row" style={styles.buttonRow}>    
              <button style={{ ...RoseGoldButton, ...styles.inviteButton, display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '8px'}} onClick={() => handleReferAFriend(comboData)} >
                <FontAwesomeIcon icon={faUsers} size="sm" />
                <span>Refer</span>
              </button>                
                {renderButtons()}                
                <button className="button rateButton"  style={{...RoseGoldButton,...styles.rateButton,opacity: userVerified ? 1 : 0.6,cursor: userVerified ? 'pointer' : 'not-allowed'}} onClick={() => setShowRating(true)} disabled={!userVerified}>
                  Rate
                </button>
            </div>
          </div>
          <ComboJoinModal isOpen={showJoinModal} onClose={() => setShowJoinModal(false)} comboData={comboData}  onCalculate={handleCalculateFromModal} onJoin={handleJoinFromModal} queryLoginParam={queryLoginParam} userVerified={userVerified} />
          <TermsAndConditionsModal  isOpen={showTermsModal} onClose={() => setShowTermsModal(false)}onCancel={() => {setShowTermsModal(false);setShowJoinModal(false)}} onAgree={handleAgreeTerms} />
        
          {showPreferencesModal && (
            <UserPreferencesScreen isOpen={showPreferencesModal} onClose={handlePreferencesClose} grp_wish_id={comboData.id} groupWishName={comboData.title} existingPreferences={comboData.progress_stats?.usersChoices || "new"} quantity={comboQuantity || 1}  combo={true}
              onSave={(updatedPreferences) => {
                setShowPreferencesModal(false); 
                if (updatedPreferences) {
                  setComboData(prevData => ({
                    ...prevData,
                    progress_stats: {
                      ...prevData.progress_stats,
                      usersChoices: updatedPreferences
                    }
                  }));
                }
              }} />
          )}
        {showRating && (
          <div className="modal is-active">
            <div   onClick={() => setShowRating(false)}></div> 
              <RatingSlider onRate={handleRate} onClose={() => setShowRating(false)} /> 
          </div>
        )}
        </div>
         
         </>
    );
};

const baseFavoriteButtonStyle = {
  position: 'relative', 
  width: "20%",
  background: 'none',
  border: 'none',
  cursor: 'pointer',
  fontSize: 24,
  zIndex: 2,
  padding: 8, 
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: Color.colorWhite, 
  boxShadow: '0 0px 0px rgba(0, 0, 0, 0.0)',
  transition: 'all 0.3s ease',
  outline: 'none'  
};

const styles = {
  container: {
    maxWidth: '2800px',
    margin: '0 auto',
    padding: '32px 24px',
    marginTop: '4%', 
    backgroundColor: Color.colorLightBeigeStrong,
  },
  header: {
    backgroundColor: '#ffffff',
    borderRadius: '8px',
    padding: '24px',
    marginBottom: '0',
    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
    height: '100%', 
    display: 'flex',
    flexDirection: 'column',
  },
  headerTop: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginBottom: '16px', 
  },  
  statusBadgeContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  messagesContainer: {
    marginBottom: '16px',
    alignItems: 'center',
    width: '40px'
  },
  successMessage: {
    padding: '10px',
    backgroundColor: Color.colorVibrantGreen,
    color: Color.colorWhite,
    borderRadius: '6px',
    marginBottom: '12px',
    fontSize: '18px',
    fontWeight: '600',
  },
  errorMessage: {
    padding: '10px',
    backgroundColor: Color.colorVibrantRed,
    color: Color.colorWhite,
    borderRadius: '6px',
    marginBottom: '12px',
    fontSize: '18px',
    fontWeight: '600',
  }, 
  fullWidthHeaderRow: {
    width: '100%',
    marginBottom: '0%',
    backgroundColor: '#ffffff',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    padding: '24px',
    height: '100%',
    transition: 'all 0.3s ease-in-out',
    border: `1px solid ${Color.colorLightBeige}`
  },
  headerContent: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  headerTitleRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    marginBottom: '16px',
  }, 
  statusBadgeContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  messagesContainer: {
    marginBottom: '16px',
  },
  successMessage: {
    padding: '10px',
    backgroundColor: '#dcfce7',
    color: '#166534',
    borderRadius: '6px',
    marginBottom: '12px',
  }, 
  headerTitle: {
  fontSize: '28px',
  fontWeight: '700',
  color: Color.colorPeacockBlueDark,
  margin: 0,
  flex: '1',  
  paddingRight: '16px',  
  textOverflow: 'ellipsis', 
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  letterSpacing: '-0.02em',
  textShadow: '0 1px 2px rgba(0, 0, 0, 0.05)',
}, 
headerRightSection: {
  display: 'flex',
  alignItems: 'center',
  gap: '16px',
  flexShrink: 0, 
}, 
  loading: {
    textAlign: 'center',
    padding: '40px',
    fontSize: '18px',
    color: Color.colorGray
  },
  error: {
    textAlign: 'center',
    padding: '40px',
    fontSize: '18px',
    color: Color.colorRed
  },
  statusBadge: {
    padding: '8px 16px', 
    color: Color.colorWhite,
    backgroundColor: Color.colorRed,
    border: `1px solid ${Color.colorRed}`, 
    borderRadius: '8px',
    fontSize: '14px',
    fontWeight: '600',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
  },
  infoGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gap: '24px',
    marginTop: '16px',
    marginBottom: '16px',
    padding: '16px',
    backgroundColor: Color.colorLightestBeige,
    borderRadius: '8px',
    border: `1px solid ${Color.colorLightBeige}`,
  },
  infoItem: {
    display: 'flex', 
    alignItems: 'center',
    gap: '12px',
    padding: '12px',
    backgroundColor: Color.colorWhite,
    borderRadius: '6px',
    boxShadow: '0 1px 2px rgba(0, 0, 0, 0.05)',
    transition: 'all 0.2s ease',
    '&:hover': {
        transform: 'translateY(-2px)',
        boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1)',
    }
  },
  infoLabel: {
            fontSize: '14px',
            color: Color.colorGray, 
        },
  infoValue: {
          fontSize: '20px',
          fontWeight: '700',
          color: Color.colorDarkBlue,
          textShadow: '0 1px 2px rgba(0, 0, 0, 0.1)',
          padding: '4px 8px', 
          borderRadius: '4px',
          display: 'inline-block',
          marginTop: '4px',
          letterSpacing: '-0.01em'
      },
  savingsValue: {
        fontSize: '20px',
        fontWeight: '700',
        color: Color.colorLimeGreen, 
        padding: '4px 8px',
        borderRadius: '4px',
        display: 'inline-block',
        marginTop: '4px',
        textShadow: '0 1px 2px rgba(0, 0, 0, 0.1)',
        letterSpacing: '-0.01em'
    },
  joinButton: {
    backgroundColor: '${Color.colorLimeGreen}',
    color: '${Color.colorWhite}',
    border: 'none',
    padding: '10px 20px',
    borderRadius: '8px',
    fontSize: '16px',
    fontWeight: 'bold',
    cursor: 'pointer',
    flex: '1',
    margin: '0 10px'
  },
  rejoinButton: {
    backgroundColor: '${Color.colorLimeGreen}',
    color: '${Color.colorWhite}',
    border: 'none',
    padding: '10px 20px',
    borderRadius: '8px',
    fontSize: '16px',
    fontWeight: 'bold',
    cursor: 'pointer',
    flex: 1,
    margin: '0 10px'
  }, 
  comboQuantityInput: {
    width: '80px',
            padding: '8px 12px',
            border: `1px solid ${Color.colorDarkBlue}`,   
            borderRadius: '6px',
            fontSize: '16px',
            color: 'Color.colorDarkBlue',
            backgroundColor: '#ffffff',
            textAlign: 'center',
            fontWeight: '500',
            cursor: 'text',  
            '&:hover': {
                borderColor: Color.colorDarkBlue,
            },
            '&:focus': {
                outline: 'none',
                border: `2px solid ${Color.colorDarkBlue}`,
                boxShadow: `0 0 0 1px ${Color.colorDarkBlue}33`,   
            },
            '&::-webkit-inner-spinButton': {
                opacity: 1,   
                height: '24px',   
            }
        },
        quantityRow: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: '16px',
        }, 
        comboQuantityContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginTop: '8px',
        },
        quantityInputWrapper: {
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
        }, 
        quantityDisplay: {
            fontSize: '14px',
            fontWeight: 'bold',
            color: Color.colorDarkBlue, 
            padding: '4px 12px',
            borderRadius: '4px', 
            textAlign: 'center',
        },
        sectionTitleWrapper: {
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            marginBottom: '12px',
        },
        itemLabelWrapper: {
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            flex: 1,
        },
        quantitySection: {
            marginBottom: '24px',
            padding: '16px',
            backgroundColor: Color.colorLightBeige,
            borderRadius: '8px',
            border: `1px solid ${Color.colorDarkBlue}33`,
        },
        sectionTitle: {
            fontSize: '16px',
            fontWeight: '600',
            color: Color.colorDarkBlue,
            margin: 0,
        },
        quantityLabel: {
            fontSize: '14px',
            color: Color.colorDarkBlue,
            flex: 1,
        },
        quantityInputContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '8px 12px',
            backgroundColor: '#ffffff',
            borderRadius: '6px',
            marginBottom: '8px', 
        },        
        savingsHighlight: {
            color: '#0369a1',             
            fontWeight: '600'               
        },
        mainGrid: {
            display: 'grid',
            gridTemplateColumns: '3fr 2fr',
            gap: '24px',
            marginBottom: '24px'
        },
        leftColumn: {
            display: 'flex',
            flexDirection: 'column',
            gap: '12px'  
          },
          rightColumn: {
            alignSelf: 'start'  
          },
        sameRowContainer : {
            flexDirection: 'row'
        },
        addressSectionContainer: {
            marginBottom: '20px',
            padding: '16px',
            backgroundColor: '#f9fafb',
            borderRadius: '8px',
            border: '1px solid #e5e7eb'
        },
        itemsCard: {
            backgroundColor: '#ffffff',
            borderRadius: '8px',
            boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
            padding: '24px',
            marginBottom: '0' 
        },
        itemQuantitiesSection: {
            marginTop: '16px',
            padding: '16px',
            backgroundColor: '#f9fafb',
            color: Color.colorDarkBlue,
            borderRadius: '8px',
        }, 
        modalOverlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1000,
        },
        modalContent: {
            backgroundColor: '#ffffff',
            padding: '20px',
            borderRadius: '8px',
            maxWidth: '500px',
            width: '100%',
            position: 'relative',
        },
        itemsTitle: {
            fontSize: '20px',
            fontWeight: '600',
            color: Color.colorPeacockBlueDark, 
            marginBottom: '16px',
        },
        itemGrid: {
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
        },
        itemCard: {
          display: 'flex',
          gap: '16px',
          padding: '16px',
          backgroundColor: Color.colorLightestBeige, 
          borderRadius: '8px',
          border: '1px solid #e5e7eb',
          transition: 'all 0.2s ease',
          '&:hover': {
              boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1)',
          }
      },
      itemImage: {
          width: '96px',
          height: '96px',
          objectFit: 'cover',
          borderRadius: '8px',
          border: '1px solid ${Color.colorRed}',
      },
      itemDetails: {
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
      },
      itemName: {
          fontSize: '16px',
          fontWeight: '500',
          color: Color.colorPeacockBlueDark,
          marginBottom: '8px',
          lineHeight: '1.4',
      },
      priceGrid: {
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          gap: '16px',
          marginBottom: '4px',
          alignItems: 'center',
      },
      priceLabel: {
          fontSize: '14px',
          color: Color.colorDarkGrey,
          marginBottom: '4px',
      },
      priceValue: {
          fontSize: '16px',
          fontWeight: '500',
          color: Color.colorDarkBlue,
      },
      quantityContainer: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '6px', 
          padding: '6px 12px',
          borderRadius: '6px',
          border: '1px solid rgba(30, 58, 138, 0.1)',
      },
      quantityDisplay: {
          fontSize: '14px',
          fontWeight: 'bold',
          color: Color.colorDarkBlue,
          textAlign: 'center',
      },
        requiredBadge: {
        display: 'inline-block',
        padding: '4px 8px',
        backgroundColor: '#fee2e2',
        color: '#991b1b',
        borderRadius: '4px',
        fontSize: '12px',
        fontWeight: '500',
        marginRight: '8px',
        },
        maxQuantity: {
        fontSize: '14px',
        color: '#6b7280',
        }, 
        calculatorTitle: {
        fontSize: '20px',
        fontWeight: '600',
        color: Color.colorPeacockBlueDark,
        marginBottom: '16px',
        }, 
        quantityInput: {
        width: '80px',
        padding: '4px 8px',
        border: '1px solid #d1d5db',
        borderRadius: '4px',
        },
        addressSelect: {
        width: '100%',
        padding: '8px',
        border: '1px solid #d1d5db',
        borderRadius: '8px',
        marginBottom: '16px',
        },
        calculateButton: {
        width: '100%',
        padding: '12px',
        backgroundColor:  Color.colorPeacockBlue,//'#2563eb',
        color: '#ffffff',
        border: 'none',
        borderRadius: '8px',
        fontSize: '16px',
        fontWeight: '500',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '8px',
        },
        calculateButtonDisabled: {
        backgroundColor: '#9ca3af',
        cursor: 'not-allowed',
        },
        costBreakdown: {
        marginTop: '24px',
        borderTop: '1px solid #e5e7eb',
        paddingTop: '16px',
        },
        costRow: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '8px',
        },
        costLabel: {
        fontSize: '14px',
        color: '#6b7280',
        },
        costValue: {
        fontSize: '14px',
        color: '#111827',
        },
        totalRow: {
        display: 'flex',
        justifyContent: 'space-between',
        borderTop: '1px solid #e5e7eb',
        paddingTop: '8px',
        marginTop: '8px',
        fontWeight: '600',
        },
        savingsRow: {
        display: 'flex',
        justifyContent: 'space-between',
        color: '#059669',
        fontWeight: '500',
        marginTop: '8px',
        },
        deliveryEstimate: {
        fontSize: '14px',
        color: '#6b7280',
        textAlign: 'center',
        marginTop: '16px',
        },
        checkoutButton: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '8px',
            padding: '12px 24px',
            borderRadius: '8px',
            fontSize: '16px',
            fontWeight: '500',
            cursor: 'pointer',
            border: 'none',
            flex: '2',   
          },
        description: {
            marginTop: '07%',
            padding: '12px',
            backgroundColor: '#fff',
            borderRadius: '8px',
            border: `1px solid ${Color.colorLightBeige}`, 
            maxHeight: '480px',  
            overflowY: 'auto',  
            scrollbarWidth: 'thin',
            '&::-webkit-scrollbar': {
              width: '6px',
            },
            '&::-webkit-scrollbar-track': {
              background: '#f1f1f1',
              borderRadius: '8px',
            },
            '&::-webkit-scrollbar-thumb': {
              background: '#888',
              borderRadius: '8px',
            },
          },
    descriptionText: {
      fontSize: '15px',
            color: Color.colorPeacockBlueDark,
            lineHeight: '1.6',
            marginBottom: '12px',
            fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif',
    }, 
    imageWrapper: {
      position: 'relative',
      width: '96px',
      height: '96px',
      borderRadius: '8px',
      overflow: 'hidden',
      cursor: 'pointer',
      transition: 'all 0.2s ease',
    },
    imageOverlay: {
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.6)',
      padding: '4px',
      opacity: 0,
      transition: 'opacity 0.2s ease',
      textAlign: 'center',
    },
    viewText: {
      color: '#FFFFFF',
      fontSize: '12px',
      fontWeight: '500',
    },
    galleryModalOverlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.75)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 1000,
      animation: 'fadeIn 0.3s ease',
    },
    galleryModalContent: {
      position: 'relative',
      backgroundColor: '#FFFFFF',
      borderRadius: '12px',
      padding: '24px',
      width: '90%',
      maxWidth: '800px',
      maxHeight: '90vh',
      overflow: 'auto',
      boxShadow: '0 4px 20px rgba(0, 0, 0, 0.15)',
    },
    closeGalleryButton: {
      position: 'absolute',
      top: '12px',
      right: '12px',
      backgroundColor: 'white',
      border: 'none',
      borderRadius: '50%',
      width: '32px',
      height: '32px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      zIndex: 1001,
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
      transition: 'all 0.2s ease',
    }, 
    gridRow: {
      display: 'grid',
      gridTemplateColumns: '3fr 2fr',
      gap: '24px',
      marginBottom: '24px',
      minHeight: 'fit-content'        
},
leftPanel: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%'
},
rightPanel: {
  display: 'flex',
  flexDirection: 'column',
  height: '100%', 
},
rightPanelTop:{
  display: 'flex',
  flexDirection: 'column',
  height: 'auto', 
  gap: '20px', 
},
ratingDisplayWrapper: {
  marginTop: '0',  
}, 
itemsCard: {
      backgroundColor: '#ffffff',
      borderRadius: '8px',
      boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
      padding: '24px',
      height: '100%',
      display: 'flex',
      flexDirection: 'column'
    },
    quantityContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '6px', 
      padding: '6px 12px',
      borderRadius: '6px',
      border: '1px solid rgba(30, 58, 138, 0.1)',
      cursor: 'pointer',
      transition: 'all 0.2s ease',
      '&:hover': {
          backgroundColor: Color.colorLightBeige,
      }
  },
  descriptionContainer: {
      marginTop: '12px',
      padding: '12px',
      backgroundColor: Color.colorWhite,
      borderRadius: '8px',
      maxHeight: '200px',
      overflowY: 'auto',
      border: `1px solid ${Color.colorLightBeige}`,
      transition: 'all 0.3s ease',
      animation: 'fadeIn 0.3s',
      scrollbarWidth: 'thin',
      '&::-webkit-scrollbar': {
          width: '4px',
      },
      '&::-webkit-scrollbar-track': {
          background: '#f1f1f1',
          borderRadius: '4px',
      },
      '&::-webkit-scrollbar-thumb': {
          background: '#888',
          borderRadius: '4px',
      }
  },
  descriptionText: {
      fontSize: '14px',
      lineHeight: '1.5',
      color: Color.colorPeacockBlueDark,
      margin: 0,
      whiteSpace: 'pre-line'
  },
  infoIcon: {
      fontSize: '14px',
      color: Color.colorDarkBlue
  },
calculatorCard: {
      backgroundColor: '#ffffff',
      borderRadius: '8px',
      boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
      padding: '10px',
      height: '100%',
      display: 'flex',
      flexDirection: 'column'
    },
    redeemCreditSection: {
      marginTop: '16px',
      marginBottom: '16px',
      padding: '12px',
      backgroundColor: Color.colorWhite,
      borderRadius: '8px',
      border: `1px solid ${Color.colorDarkBlue}33`,
    },
    checkboxLabel: {
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
      fontSize: '14px',
      color: Color.colorPeacockBlueDark,
      marginBottom: '8px',
    },
    checkbox: {
      width: '16px',
      height: '16px',
      cursor: 'pointer',
    },    
          
  };

export default ComboDetails;