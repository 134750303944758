/**
 * Default box sizes with improved dimensions and weights
 * This should replace the DEFAULT_BOXES constant in Constants.js
 */
export const DEFAULT_BOXES = [
  {
    name: 'Small Box',
    length: 12,
    width: 9,
    height: 6,
    maxWeight: 20,
    dimensionUnit: 'inch',
    weightUnit: 'lb'
  },
  {
    name: 'Medium Box',
    length: 16,
    width: 12,
    height: 8,
    maxWeight: 40,
    dimensionUnit: 'inch',
    weightUnit: 'lb'
  },
  {
    name: 'Large Box',
    length: 18,
    width: 12,
    height: 9,
    maxWeight: 70,
    dimensionUnit: 'inch',
    weightUnit: 'lb'
  },
  {
    name: 'Extra Large Box',
    length: 24,
    width: 18,
    height: 12,
    maxWeight: 100,
    dimensionUnit: 'inch',
    weightUnit: 'lb'
  }
];

/**
 * Shipping zones with rates and transit times
 */
export const SHIPPING_ZONES = {
  zone1: {
    name: "Zone 1 (0-150 miles)",
    rates: {
      ground: 0.65,
      express: 1.15,
      priority: 1.85
    },
    transit: {
      ground: "1-2 days",
      express: "1 day",
      priority: "Same day"
    }
  },
  zone2: {
    name: "Zone 2 (151-500 miles)",
    rates: {
      ground: 0.75,
      express: 1.25, 
      priority: 2.00
    },
    transit: {
      ground: "2-3 days",
      express: "1-2 days",
      priority: "1 day"
    }
  },
  zone3: {
    name: "Zone 3 (500+ miles)",
    rates: {
      ground: 0.85,
      express: 1.35,
      priority: 2.15
    },
    transit: {
      ground: "3-5 days",
      express: "2-3 days",
      priority: "1-2 days"
    }
  }
};