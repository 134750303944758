import React, { useState, useEffect, useRef } from 'react';
import * as style from '../../../GlobalStyles';

const ComboCalculator = ({ selectedWishes, onCalculationsComplete, initialValues = null }) => {
  const [mrpTotal, setMrpTotal] = useState(0);
  const [customMrp, setCustomMrp] = useState('');
  const [selectedRanges, setSelectedRanges] = useState({});
  const [commissionType, setCommissionType] = useState('percentage');
  const [commissionValue, setCommissionValue] = useState('20');
  const [itemCommissions, setItemCommissions] = useState({});
  const [useGlobalCommission, setUseGlobalCommission] = useState(true);
  const [calculatedValues, setCalculatedValues] = useState({
    actualBundleCost: 0,
    internationalShippingCost: 0,
    bundleRate: 0,
    commissionAmount: 0,
    itemCommissionAmounts: {},
    savingsAmount: 0,
    savingsPercentage: 0,
    itemTotalCosts: {}
  }); 

  // Refs to track initialization state
  const isInitialized = useRef(false);
  const previousCalculationData = useRef(null);
  const customMrpChanged = useRef(false);
  const rangesInitialized = useRef(false);
  const updatingSelection = useRef(false); 
  useEffect(() => {
    if (initialValues && !isInitialized.current) {  
      if (initialValues.bundle_mrp) {
        setCustomMrp(initialValues.bundle_mrp.toString());
      }      
      
      if (initialValues.bundle_commission_type) {
        setCommissionType(initialValues.bundle_commission_type);
      }      
      
      if (initialValues.bundle_commission_value) {
        setCommissionValue(initialValues.bundle_commission_value.toString());
      }       
      if ('isGlobalCommission' in initialValues) {
        const isGlobal = Boolean(initialValues.isGlobalCommission); 
        setUseGlobalCommission(isGlobal);
      } 
      if (initialValues.item_commissions) { 
        setItemCommissions(initialValues.item_commissions);
      }
      
      isInitialized.current = true;
    }
  }, [initialValues]);
  
  // Set up initial ranges and pricing based on selected wishes
  useEffect(() => {
    if (!selectedWishes || selectedWishes.length === 0 || updatingSelection.current) {
      return;
    }

    // Calculate total MRP
    const total = selectedWishes.reduce((sum, wish) => {
      return sum + parseFloat(wish.list_price || wish.price || 0);
    }, 0);
    setMrpTotal(total);
     
    // Only set custom MRP if it hasn't been manually changed and no initial value
    if (!customMrp && !initialValues?.bundle_mrp && !customMrpChanged.current) {
      setCustomMrp(total.toFixed(2));
    }
    
    // Initialize ranges and commissions only once
    if (!rangesInitialized.current) {
      let newRanges = { ...selectedRanges };
      let newCommissions = { ...itemCommissions };
      let hasChanges = false;
      
      selectedWishes.forEach(wish => {
        // Check if we have selectedPrices data from the API
        if (wish.selectedPrices) {
          hasChanges = true;
          
          // Use the selected price information from the API
          newRanges[wish.id] = {
            priceRangeIndex: wish.selectedPrices.selectedRangeIndex || 0,
            productCost: wish.selectedPrices.productCost || 0,
            shippingCost: wish.selectedPrices.shippingCost || 0
          };
          
          // Use the commission information from the API
          newCommissions[wish.id] = {
            type: wish.selectedPrices.commissionType || commissionType,
            value: wish.selectedPrices.commissionValue?.toString() || commissionValue
          };
        } 
        // Only create initial selections for wishes that don't already have them
        else if (!newRanges[wish.id] && wish.price_ranges && wish.price_ranges.length > 0) {
          hasChanges = true;
          
          // Prefer actual price ranges over expected
          const actualRanges = wish.price_ranges.filter(r => r.actual_price_range === true);
          const firstRange = actualRanges.length > 0 ? actualRanges[0] : wish.price_ranges[0];
          
          // Use the price range index as the selection identifier
          newRanges[wish.id] = {
            priceRangeIndex: actualRanges.length > 0 ? 0 : 0, // Default to first actual or first price range
            productCost: parseFloat(firstRange.rangePrice || 0),
            shippingCost: parseFloat(firstRange.shippingCost_for_range || 0)
          };
          
          // Initialize item commission only for new wishes
          newCommissions[wish.id] = {
            type: commissionType,
            value: commissionValue
          };
        }
      });
      
      // Only update state if we actually have new ranges or commissions
      if (hasChanges) {
        setSelectedRanges(newRanges);
        setItemCommissions(newCommissions);
      }
      
      rangesInitialized.current = true;
    }
  }, [selectedWishes, customMrp, initialValues]);
  
  // Helper function to detect calculation data changes
  const hasCalculationDataChanged = (newData) => {
    if (!previousCalculationData.current) return true;    
    return JSON.stringify(newData) !== JSON.stringify(previousCalculationData.current);
  };

  // Main calculation function
  const calculateAll = () => { 
    const actualProductCost = Object.values(selectedRanges).reduce((sum, range) => {
      return sum + (range.productCost || 0);
    }, 0);
     
    const totalShippingCost = Object.values(selectedRanges).reduce((sum, range) => {
      return sum + (range.shippingCost || 0);
    }, 0); 

    // Calculate commissions
    let totalCommissionAmount = 0;
    const itemCommissionAmounts = {};
    const itemTotalCosts = {};
    const itemizedCosts = {};

    if (useGlobalCommission) { 
      const totalCost = actualProductCost + totalShippingCost;
      const parsedCommissionValue = parseFloat(commissionValue || 0);
      
      if (commissionType === 'percentage') {
        totalCommissionAmount = (totalCost * parsedCommissionValue) / 100;
      } else {
        totalCommissionAmount = parsedCommissionValue;
      } 

      // Calculate per-item commission amounts for global commission
      Object.entries(selectedRanges).forEach(([wishId, range]) => {
        const itemBaseCost = (range.productCost || 0) + (range.shippingCost || 0);
        let itemCommission = 0;
        
        if (commissionType === 'percentage') {
          itemCommission = (itemBaseCost * parsedCommissionValue) / 100;
        } else { 
          // For fixed commission, distribute proportionally based on item cost
          const proportion = totalCost > 0 ? itemBaseCost / totalCost : 0;
          itemCommission = proportion * parsedCommissionValue;
        }
        
        itemCommissionAmounts[wishId] = itemCommission;
        itemTotalCosts[wishId] = itemBaseCost + itemCommission;
        itemizedCosts[wishId] = {
          productCost: range.productCost || 0,
          shippingCost: range.shippingCost || 0,
          commissionType: commissionType,
          commissionValue: parsedCommissionValue,
          commissionAmount: itemCommission,
          totalCost: itemBaseCost + itemCommission
        };
      });
    } else { 
      // Calculate individual item commissions
      Object.entries(selectedRanges).forEach(([wishId, range]) => {
        const itemBaseCost = (range.productCost || 0) + (range.shippingCost || 0);
        // Use item-specific commission or fallback to default
        const commission = itemCommissions[wishId] || { type: 'percentage', value: '20' };
        const parsedItemCommissionValue = parseFloat(commission.value || 0);
        let itemCommission = 0;
        
        if (commission.type === 'percentage') {
          itemCommission = (itemBaseCost * parsedItemCommissionValue) / 100;
        } else {
          itemCommission = parsedItemCommissionValue;
        }        
        itemCommissionAmounts[wishId] = itemCommission;
        itemTotalCosts[wishId] = itemBaseCost + itemCommission;
        totalCommissionAmount += itemCommission;
        itemizedCosts[wishId] = {
          productCost: range.productCost || 0,
          shippingCost: range.shippingCost || 0,
          commissionType: commission.type,
          commissionValue: parsedItemCommissionValue,
          commissionAmount: itemCommission,
          totalCost: itemBaseCost + itemCommission
        };
      });
    }
    
    // Calculate final values
    const bundleRate = actualProductCost + totalShippingCost + totalCommissionAmount; 
    const mrpValue = parseFloat(customMrp || mrpTotal);
    const savingsAmount = mrpValue - bundleRate;
    const savingsPercentage = mrpValue > 0 ? (savingsAmount / mrpValue) * 100 : 0; 
    
    // Update calculated values in state
    setCalculatedValues({
      actualBundleCost: actualProductCost,
      internationalShippingCost: totalShippingCost,
      bundleRate: bundleRate,
      commissionAmount: totalCommissionAmount,
      itemCommissionAmounts,
      savingsAmount: savingsAmount,
      savingsPercentage: savingsPercentage,
      itemTotalCosts: itemTotalCosts  
    });
     
    // Prepare data to send back to parent component - with enhanced commission data
    const newCalculationData = {
      bundle_mrp: parseFloat(customMrp || mrpTotal),
      actual_bundle_cost: actualProductCost,
      actual_international_shipping_cost: totalShippingCost,
      bundle_rate: bundleRate,
      bundle_commission_type: commissionType,
      bundle_commission_value: parseFloat(commissionValue || 0),
      bundle_shipping_cost: totalShippingCost,
      isGlobalCommission: useGlobalCommission,
      
      // Ensure item_commissions is properly formatted for each item
      item_commissions: Object.entries(itemCommissions).reduce((acc, [itemId, commission]) => {
        acc[itemId] = {
          type: commission.type || 'percentage',
          value: commission.value || '20'
        };
        return acc;
      }, {}),
      
      // Make sure itemized_costs has consistent structure
      itemized_costs: itemizedCosts
    };
     
    // Only call the callback if data has changed
    if (hasCalculationDataChanged(newCalculationData) && onCalculationsComplete) {
      previousCalculationData.current = newCalculationData;
      onCalculationsComplete(newCalculationData);
    }
  }; 
  useEffect(() => { 
    if (Object.keys(selectedRanges).length > 0) { 
      calculateAll(); 
      if (useGlobalCommission) { 
        const updatedCommissions = { ...itemCommissions };
        Object.keys(selectedRanges).forEach(wishId => {
          updatedCommissions[wishId] = {
            type: commissionType,
            value: commissionValue
          };
        });
        setItemCommissions(updatedCommissions);
      }
    }
  }, [selectedRanges, commissionType, commissionValue, customMrp, useGlobalCommission, itemCommissions]);

  // Event handlers with improved state management
  const handleCustomMrpChange = (e) => {
    const value = e.target.value;
    setCustomMrp(value);
    customMrpChanged.current = true;
  };
  
  const handleItemCommissionTypeChange = (wishId, type) => {
    setItemCommissions(prev => ({
      ...prev,
      [wishId]: {
        ...prev[wishId],
        type
      }
    }));
  };
  
  const handleItemCommissionValueChange = (wishId, value) => {
    setItemCommissions(prev => ({
      ...prev,
      [wishId]: {
        ...prev[wishId],
        value
      }
    }));
  };

  // Fixed price range selection handler
  const handlePriceRangeChange = (wishId, selectedIndex, selectedPriceRange) => {
    if (!selectedPriceRange) return;
    
    updatingSelection.current = true;
    
    setSelectedRanges(prev => {
      const newRanges = {
        ...prev,
        [wishId]: {
          priceRangeIndex: selectedIndex,
          productCost: parseFloat(selectedPriceRange.rangePrice || 0),
          shippingCost: parseFloat(selectedPriceRange.shippingCost_for_range || 0)
        }
      };
      
      // Use setTimeout to ensure state updates properly
      setTimeout(() => {
        updatingSelection.current = false;
      }, 0);
      
      return newRanges;
    });
  };

  const formatCurrency = (value) => {
    return parseFloat(value).toFixed(2);
  };

  // Helper function to get commission amount from selectedPrices if available
  const getCommissionAmount = (wish) => {
    // Always use our calculated commission amount when parameters have changed
    if (calculatedValues.itemCommissionAmounts[wish.id] !== undefined) {
      return calculatedValues.itemCommissionAmounts[wish.id];
    }
    
    // Fall back to pre-calculated commission if available
    if (wish.selectedPrices && 'commissionAmount' in wish.selectedPrices) {
      return wish.selectedPrices.commissionAmount;
    }
    
    // Return 0 if no commission can be determined
    return 0;
  };
  
  // Helper function to get total item cost (product + shipping + commission)
  const getItemTotalCost = (wish, selectedPriceRange) => {
    // Use calculated total if available
    if (calculatedValues.itemTotalCosts[wish.id] !== undefined) {
      return calculatedValues.itemTotalCosts[wish.id];
    }
    
    // Otherwise calculate from components
    const productCost = selectedPriceRange?.rangePrice || 0;
    const shippingCost = selectedPriceRange?.shippingCost_for_range || 0;
    const commissionAmount = wish.selectedPrices?.commissionAmount || 0;
    
    return productCost + shippingCost + commissionAmount;
  };

  return (
    <div style={containerStyle}>
      <div style={cardStyle}>
        <div style={cardContentStyle}>
          <h3 style={cardTitleStyle}>Bundle MRP Calculation</h3>          
          <div style={flexRowStyle}>
            <div style={flexItemStyle}>
              <label style={labelStyle}>Automatic MRP Total</label>
              <div style={readonlyValueStyle}>
                ${formatCurrency(mrpTotal)}
              </div>
            </div>
            <div style={flexItemStyle}>
              <label style={labelStyle}>Custom MRP (if needed)</label>
              <input
                type="number"
                value={customMrp}
                onChange={handleCustomMrpChange}
                style={inputStyle}
                step="0.01"
                min="0"
              />
            </div>
          </div>
        </div>
      </div>

      <div style={cardStyle}>
        <div style={cardContentStyle}>
          <h3 style={cardTitleStyle}>Commission Settings</h3>
          <div style={toggleContainerStyle}>
            <label style={toggleLabelStyle}>
              <input
                type="checkbox"
                checked={useGlobalCommission}
                onChange={() => setUseGlobalCommission(!useGlobalCommission)}
                style={checkboxStyle}
              />
              Use global commission for all items (instead of per-item commission)
            </label>
          </div>
          
          <div style={gridTwoColumnsStyle}>
            <div>
              <label style={labelStyle}>
                {useGlobalCommission ? 'Global Commission Type' : 'Default Commission Type'}
              </label>
              <select 
                value={commissionType} 
                onChange={(e) => setCommissionType(e.target.value)} 
                style={selectStyle}
              >
                <option value="percentage">Percentage</option>
                <option value="fixed">Fixed Amount</option>
              </select>
            </div>
            <div>
              <label style={labelStyle}>
                {useGlobalCommission ? 'Global' : 'Default'} Commission Value 
                {commissionType === 'percentage' ? ' (%)' : ' ($)'}
              </label>
              <input
                type="number"
                value={commissionValue}
                onChange={(e) => setCommissionValue(e.target.value)} 
                style={inputStyle} 
                step={commissionType === 'percentage' ? "1" : "0.01"}  
                min="0" 
              />
            </div>
          </div>
        </div>
      </div>

      <div style={cardStyle}>
        <div style={cardContentStyle}>
          <h3 style={cardTitleStyle}>Price Range Selection</h3>          
          <div style={tableContainerStyle}>
            <table style={tableStyle}>
              <thead>
                <tr style={tableHeaderRowStyle}>
                  <th style={tableHeaderCellStyle}>Product</th>
                  <th style={tableHeaderCellStyle}>List Price</th>
                  <th style={tableHeaderCellStyle}>Available Price Ranges</th>
                  <th style={tableHeaderCellStyle}>Product Cost</th>
                  <th style={tableHeaderCellStyle}>Shipping Cost</th>
                  {!useGlobalCommission && (
                    <>
                      <th style={tableHeaderCellStyle}>Commission Type</th>
                      <th style={tableHeaderCellStyle}>Commission Value</th>
                    </>
                  )}
                  <th style={tableHeaderCellStyle}>Commission Amount</th>
                  <th style={tableHeaderCellStyle}>Total Cost</th>
                </tr>
              </thead>
              <tbody>
                {selectedWishes.map(wish => {
                  const selectedPriceRangeIndex = selectedRanges[wish.id]?.priceRangeIndex || 0;
                  const selectedPriceRange = wish.price_ranges ? wish.price_ranges[selectedPriceRangeIndex] : null;
                  
                  return (
                    <tr key={wish.id}>
                      <td style={tableCellStyle}>{wish.product_name || wish.title}</td>
                      <td style={tableCellStyle}>${wish.list_price || wish.price}</td>
                      <td style={tableCellStyle}>
                        <select 
                          style={selectStyle}
                          value={selectedPriceRangeIndex}
                          onChange={(e) => {
                            const selectedIndex = parseInt(e.target.value, 10);
                            const selectedPriceRange = wish.price_ranges[selectedIndex];
                            handlePriceRangeChange(wish.id, selectedIndex, selectedPriceRange);
                          }}
                        >
                          {wish.price_ranges && wish.price_ranges.map((range, index) => (
                            <option key={index} value={index}>
                              {range.actual_price_range ? 'Actual' : 'Expected'}: ${range.rangePrice} 
                              ({range.minQuantity}-{range.maxQuantity} units)
                              {parseFloat(range.shippingCost_for_range || 0) > 0 ? ` + $${range.shippingCost_for_range} shipping` : ''}
                            </option>
                          ))}
                        </select>
                      </td>
                      <td style={tableCellStyle}>${formatCurrency(selectedPriceRange?.rangePrice || 0)}</td>
                      <td style={tableCellStyle}>${formatCurrency(selectedPriceRange?.shippingCost_for_range || 0)}</td>
                      
                      {!useGlobalCommission && (
                        <>
                          <td style={tableCellStyle}>
                            <select 
                              style={cellSelectStyle}
                              value={itemCommissions[wish.id]?.type || 'percentage'}
                              onChange={(e) => handleItemCommissionTypeChange(wish.id, e.target.value)}
                            >
                              <option value="percentage">Percentage</option>
                              <option value="fixed">Fixed</option>
                            </select>
                          </td>
                          <td style={tableCellStyle}>
                            <input
                              type="number"
                              style={cellInputStyle}
                              value={itemCommissions[wish.id]?.value || '20'}
                              onChange={(e) => handleItemCommissionValueChange(wish.id, e.target.value)}
                              step={itemCommissions[wish.id]?.type === 'percentage' ? "1" : "0.01"}
                              min="0"
                            />
                            {itemCommissions[wish.id]?.type === 'percentage' ? '%' : '$'}
                          </td>
                        </>
                      )}
                      
                      <td style={{...tableCellStyle, fontWeight: 'bold', color: useGlobalCommission ? style.Color.colorPurple : 'inherit'}}>
                        ${formatCurrency(getCommissionAmount(wish))}
                      </td>
                      <td style={tableCellStyle}>
                        ${formatCurrency(getItemTotalCost(wish, selectedPriceRange))}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
              <tfoot>
                <tr style={tableFooterRowStyle}>
                  <td colSpan="1" style={{...tableFooterCellStyle, textAlign: 'right'}}>Totals:</td>
                  <td style={tableFooterCellStyle}>${formatCurrency(mrpTotal)}</td>
                  <td style={tableFooterCellStyle}></td>
                  <td style={tableFooterCellStyle}>${formatCurrency(calculatedValues.actualBundleCost)}</td>
                  <td style={tableFooterCellStyle}>${formatCurrency(calculatedValues.internationalShippingCost)}</td>
                  
                  {!useGlobalCommission && <td colSpan="2" style={tableFooterCellStyle}></td>}
                  
                  <td style={tableFooterCellStyle}>${formatCurrency(calculatedValues.commissionAmount)}</td>
                  <td style={tableFooterCellStyle}>${formatCurrency(calculatedValues.actualBundleCost + calculatedValues.internationalShippingCost + calculatedValues.commissionAmount)}</td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>

      <div style={cardStyle}>
        <div style={cardContentStyle}>
          <h3 style={cardTitleStyle}>User Savings</h3>          
          <div style={savingsContainerStyle}>
            <div style={savingsItemStyle}>
              <div style={savingsLabelStyle}>MRP (Original Price)</div>
              <div style={savingsValueStyle}>${formatCurrency(parseFloat(customMrp || mrpTotal))}</div>
            </div>            
            <div style={savingsItemStyle}>
              <div style={savingsLabelStyle}>Bundle Rate (Sale Price)</div>
              <div style={savingsValueStyle}>${formatCurrency(calculatedValues.bundleRate)}</div>
            </div>            
            <div style={savingsDividerStyle}></div>            
            <div style={savingsItemStyle}>
              <div style={savingsLabelStyle}>Savings Amount</div>
              <div style={{...savingsValueStyle, color: style.Color.colorMintGreen, fontWeight: 'bold'}}>
                ${formatCurrency(calculatedValues.savingsAmount)}
              </div>
            </div>            
            <div style={savingsItemStyle}>
              <div style={savingsLabelStyle}>Savings Percentage</div>
              <div style={{...savingsValueStyle, color: style.Color.colorMintGreen, fontWeight: 'bold'}}>
                {formatCurrency(calculatedValues.savingsPercentage)}%
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={cardStyle}>
        <div style={cardContentStyle}>
          <h3 style={cardTitleStyle}>Final Calculations</h3>
          
          <div style={gridTwoColumnsStyle}>
            <div>
              <label style={labelStyle}>Total Product Cost</label>
              <div style={readonlyValueStyle}>
                ${formatCurrency(calculatedValues.actualBundleCost)}
              </div>
            </div>
            <div>
              <label style={labelStyle}>Total Shipping Cost</label>
              <div style={readonlyValueStyle}>
                ${formatCurrency(calculatedValues.internationalShippingCost)}
              </div>
            </div>
            <div>
              <label style={labelStyle}>Total Commission Amount</label>
              <div style={readonlyValueStyle}>
                ${formatCurrency(calculatedValues.commissionAmount)}
              </div>
            </div>
            <div>
              <label style={labelStyle}>Bundle Rate (Final Price)</label>
              <div style={{...readonlyValueStyle, fontWeight: 'bold'}}>
                ${formatCurrency(calculatedValues.bundleRate)}
              </div>
            </div>
          </div>
        </div>
      </div>            
    </div>
  );
};
 
const containerStyle = {
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
};

const cardStyle = {
  backgroundColor: 'white',
  borderRadius: '8px',
  boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
  overflow: 'hidden',
  marginBottom: '20px',
};

const cardContentStyle = {
  padding: '20px',
};

const cardTitleStyle = {
  fontSize: '18px',
  fontWeight: 'bold',
  marginBottom: '15px',
  color: style.Color.colorPurple,
};

const flexRowStyle = {
  display: 'flex',
  gap: '20px',
  alignItems: 'flex-end',
  marginBottom: '15px',
};

const flexItemStyle = {
  flex: 1,
};

const labelStyle = {
  display: 'block',
  fontSize: '14px',
  fontWeight: '600',
  marginBottom: '8px',
  color: style.Color.colorRoyalBlueText,
};

const inputStyle = {
  width: '100%',
  padding: '10px',
  border: '1px solid #ddd',
  borderRadius: '4px',
  fontSize: '14px',
};

const readonlyValueStyle = {
  padding: '10px',
  backgroundColor: '#f8f9fa',
  border: '1px solid #ddd',
  borderRadius: '4px',
  fontSize: '14px',
};

const selectStyle = {
  width: '100%',
  padding: '10px',
  border: '1px solid #ddd',
  borderRadius: '4px',
  fontSize: '14px',
  backgroundColor: 'white',
};

const cellSelectStyle = {
  width: '100%',
  padding: '6px',
  border: '1px solid #ddd',
  borderRadius: '4px',
  fontSize: '14px',
  backgroundColor: 'white',
};

const cellInputStyle = {
  width: '70%',
  padding: '6px',
  border: '1px solid #ddd',
  borderRadius: '4px',
  fontSize: '14px',
  backgroundColor: 'white',
  marginRight: '4px',
};

const tableContainerStyle = {
  overflowX: 'auto',
};

const tableStyle = {
  width: '100%',
  borderCollapse: 'collapse',
  border: '1px solid #ddd',
};

const tableHeaderRowStyle = {
  backgroundColor: '#f8f9fa',
};

const tableHeaderCellStyle = {
  padding: '12px',
  textAlign: 'left',
  border: '1px solid #ddd',
  fontWeight: 'bold',
};

const tableCellStyle = {
  padding: '12px',
  border: '1px solid #ddd',
};

const tableFooterRowStyle = {
  backgroundColor: '#f8f9fa',
  fontWeight: 'bold',
};

const tableFooterCellStyle = {
  padding: '12px',
  border: '1px solid #ddd',
  fontWeight: 'bold',
};

const gridTwoColumnsStyle = {
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gap: '20px',
};

const savingsContainerStyle = {
  backgroundColor: '#f8f9fa',
  borderRadius: '8px',
  padding: '20px',
  border: `1px solid ${style.Color.colorLightGrey}`,
};

const savingsItemStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  padding: '10px 0',
};

const savingsLabelStyle = {
  fontWeight: 'bold',
  color: style.Color.colorRoyalBlueText,
};

const savingsValueStyle = {
  fontSize: '16px',
};
 
const savingsDividerStyle = {
  height: '1px',
  backgroundColor: style.Color.colorLightGrey,
  margin: '10px 0',
};

const toggleContainerStyle = {
  marginBottom: '20px',
  padding: '10px',
  backgroundColor: '#f8f9fa',
  borderRadius: '4px',
  border: `1px solid ${style.Color.colorLightGrey}`,
};

const toggleLabelStyle = {
  display: 'flex',
  alignItems: 'center',
  fontSize: '14px',
  fontWeight: '600',
  color: style.Color.colorRoyalBlueText,
};

const checkboxStyle = {
  marginRight: '10px',
  width: '16px',
  height: '16px',
};

export default ComboCalculator;