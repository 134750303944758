import React from 'react';
import * as style from "../../../GlobalStyles";

const NoDataMessage = ({ 
  message = 'No data available', 
  icon, 
  showSubMessage = false, // Add this prop with default value false
  subMessage = 'Check back later for updates' // Add default submessage text
}) => {
  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '40px',
    height: '50%',
    minHeight: '160px',
    backgroundColor: style.Color.colorFillBeige,
    borderRadius: '8px',
    margin: '1px 0'
  };

  const iconStyle = {
    fontSize: '38px',
    color: '#6c757d',
    marginBottom: '5%',
    marginTop: '5%',
  };

  const messageStyle = {
    fontSize: '16px',
    color: '#6c757d',
    textAlign: 'center',
    fontWeight: '500',
    marginBottom: showSubMessage ? '5%' : '0', // Adjust margin based on submessage presence
    marginTop: '5%',
  };

  const subMessageStyle = {
    fontSize: '14px',
    color: '#adb5bd',
    textAlign: 'center'
  };

  return (
    <div style={containerStyle}>
      {icon && <div style={iconStyle}>{icon}</div>}
      <div style={messageStyle}>
        {message}
        {showSubMessage && <div style={subMessageStyle}>{subMessage}</div>}
      </div>
    </div>
  );
};

export default NoDataMessage;