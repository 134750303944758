import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthProvider} from './components/Common/InitialGears/AuthContext'; 
import AboutPage from './components/WebPage/AboutUs';
import TermsAndConditionsScreen from './components/WebPage/Terms&Conditions';
import DataDeletionPage from './components/WebPage/DataDeletionRequest';
import PrivacyPolicyScreen from './components/WebPage/PrivacyPolicy';
import FAQSection from './components/WebPage/FAQ';
import LandingPage from './components/WebPage/LandingPage';
import LoginModal from './components/Common/InitialGears/Login';
import RegisterModal from './components/Common/InitialGears/Register'; 
import InvitesPage from './components/Buyers/Invites/Invites';
import Profiles from './components/Common/Profiles';
import ProtectedRoute from './ProtectedRoute';
import WishesPage from './components/Buyers/Wishes/Wishes';
import MyWishDetails from './components/Buyers/Wishes/MyWishes';
import TrendsDetailsPage from './components/Buyers/Wishes/TrendingDetails';
import AddWish from './components/Buyers/Wishes/AddWish'; 
import GroupWishProgressScreen from './components/Buyers/GroupWishes/GroupWishProgressScreen';
import UserPreferencesScreen from './components/Buyers/GroupWishes/UserPreferenceScreen'; 
import JoinedCompletedDealsRoute from './components/Buyers/Deals/JoinedCompletedDealsRoute';
import CreateGroupWishes from './components/BacksAppTeam/GroupWish/CreateGroupWish';
import DefinePreferencesScreen from './components/BacksAppTeam/GroupWish/DefinePreferences';
import WishesDashboardPage from './components/Buyers/DashbordComponents/WishesDashboard';
import DraftGroupWishes from './components/BacksAppTeam/GroupWish/DraftGroupWishes';
import NotificationScreen from './components/Common/Notifications/NotificationScreen';
import CreditDetailsPage from './components/Buyers/Credits/CreditDetails';
import UserSettingsPage from './components/Buyers/Settings/UserSettings';
import OrderManagement from './components/Orders/OrderManagement';
import GroupWishProcessing from './components/Orders/GroupWishProcessing';
import ComboProcessing from './components/Orders/ComboProcessing';
import ReferralRequested from './components/BacksAppManager/Referrals/ReferralRequested';
import FavouriteGroupWishes from './components/Buyers/Wishes/FavouriteWishes';
import OrderTracking from './components/Orders/UserOrderTracking';
import LoginPage from './components/Common/InitialGears/Login';
import PhoneLoginPage from './components/Common/InitialGears/PhoneLogin';
import RegisterPage from './components/Common/InitialGears/Register';
import InvoiceScreen from './components/Buyers/Invoice/InvoiceScreen';
import InvoiceListScreen from './components/Buyers/Invoice/InvoiceLists';
import WithWishCache from './WishCacheRoutes';
import ComboDetails from './components/Buyers/Combo/ComboDetails';
import GroupWishesCombo from './components/BacksAppTeam/Combos/ComboGroupWishes';
import ComboList from './components/BacksAppTeam/Combos/ComboList';
import CreateEditCombo from './components/BacksAppTeam/Combos/CreateEditCombo';
import ProductEvaluationTool from './components/Common/PackagingShipping/ProductEvaluationTool';

function App() {
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showRegisterModal, setShowRegisterModal] = useState(false);

  const openLoginModal = () => {
    setShowLoginModal(true);
    setShowRegisterModal(false);
  };

  const openRegisterModal = () => {
    setShowRegisterModal(true);
    setShowLoginModal(false);
  };

  const closeModals = () => {
    setShowLoginModal(false);
    setShowRegisterModal(false);
  };

  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/" element={<LandingPage openLoginModal={openLoginModal} openRegisterModal={openRegisterModal} />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/privacyPolicy" element={<PrivacyPolicyScreen />} />
          <Route path="/terms" element={<TermsAndConditionsScreen/>} />
          <Route path="/dataDeletion" element={<DataDeletionPage/>} />          
          <Route path="/FAQs" element={<FAQSection/>} />
          <Route path="/Login" element={<LoginPage/>} />
          <Route path="/phoneLogin" element={<PhoneLoginPage/>} />
          <Route path="/register" element={<RegisterPage/>} />
           
          <Route path="/dashboard" element={<ProtectedRoute> <WithWishCache> <WishesDashboardPage/> </WithWishCache> </ProtectedRoute>} />   
          <Route path="/comboProgress" element={<ProtectedRoute> <WithWishCache> <ComboDetails/> </WithWishCache> </ProtectedRoute>} />
          <Route path="/groupWishProgress" element={<ProtectedRoute> <WithWishCache> <GroupWishProgressScreen/> </WithWishCache> </ProtectedRoute>} />
          <Route path="/preferenceList" element={<ProtectedRoute> <UserPreferencesScreen /> </ProtectedRoute>} />
          <Route path="/completedDeals" element={<ProtectedRoute> <WithWishCache> <JoinedCompletedDealsRoute/> </WithWishCache> </ProtectedRoute>} />
          <Route path="/joinedDeals" element={<ProtectedRoute> <WithWishCache> <JoinedCompletedDealsRoute/> </WithWishCache> </ProtectedRoute>} />
          <Route path="/savedGroupWishes" element={<ProtectedRoute> <WithWishCache>  <FavouriteGroupWishes/> </WithWishCache> </ProtectedRoute>} />

          <Route path="/invites/*" element={<ProtectedRoute> <InvitesPage /> </ProtectedRoute>} />
          <Route path="/profiles" element={<ProtectedRoute> <Profiles /> </ProtectedRoute>} />
          <Route path="/wishes" element={<ProtectedRoute> <WishesPage /> </ProtectedRoute>} /> 
          <Route path="/myWishes" element={<ProtectedRoute> <MyWishDetails /> </ProtectedRoute>} />
          <Route path="/trendingWishes" element={<ProtectedRoute> <TrendsDetailsPage /> </ProtectedRoute>} />
          <Route path="/myOrders" element={<ProtectedRoute> <OrderTracking /> </ProtectedRoute>} />
          
          <Route path="/addWish" element={<ProtectedRoute> <AddWish /> </ProtectedRoute>} />
          <Route path="/orderManagement" element={<ProtectedRoute> <OrderManagement /> </ProtectedRoute>} />
          <Route path="/referralRequested" element={<ProtectedRoute> <ReferralRequested /> </ProtectedRoute>} />
          <Route path="/orderGWProcessing/:groupWishId" element={<ProtectedRoute> <GroupWishProcessing /> </ProtectedRoute>} />
          <Route path="/orderComboProcessing/:comboId" element={<ProtectedRoute> <ComboProcessing /> </ProtectedRoute>} />
          <Route path="/productEvaluationTool" element={<ProtectedRoute> <ProductEvaluationTool /> </ProtectedRoute>} />
          
          <Route path="/createGroupWishes" element={<ProtectedRoute> <CreateGroupWishes /> </ProtectedRoute>} />
          <Route path="/definePreferences" element={<ProtectedRoute> <DefinePreferencesScreen /> </ProtectedRoute>} />
          <Route path="/draftGroupWishes" element={<ProtectedRoute> <DraftGroupWishes /> </ProtectedRoute>} />

          <Route path="/comboGroupWishes" element={<ProtectedRoute> <GroupWishesCombo/> </ProtectedRoute>} />
          <Route path="/comboList" element={<ProtectedRoute> <ComboList/> </ProtectedRoute>} />
          <Route path="/createCombo" element={<ProtectedRoute> <CreateEditCombo/> </ProtectedRoute>} /> 
          <Route path="/editCombo/:comboId" element={<ProtectedRoute> <CreateEditCombo/> </ProtectedRoute>} />
          
          <Route path="/notifications" element={<ProtectedRoute> <NotificationScreen /> </ProtectedRoute>} />
          <Route path="/myInvoices" element={<ProtectedRoute> <InvoiceListScreen /> </ProtectedRoute>} /> 
          <Route path="/invoice/:invoiceId/groupWishId/:groupWishId" element={<ProtectedRoute> <InvoiceScreen /> </ProtectedRoute>} />
          <Route path="/invoice/:invoiceId/combo_id/:comboId" element={<ProtectedRoute> <InvoiceScreen /> </ProtectedRoute>} />
          <Route path="/creditDetails" element={<ProtectedRoute> <CreditDetailsPage /> </ProtectedRoute>} />
          <Route path="/settings" element={<ProtectedRoute> <UserSettingsPage /> </ProtectedRoute>} />
        </Routes>

        {showLoginModal && (
          <LoginModal onClose={closeModals} openRegisterModal={openRegisterModal}/>
        )}
        
        {showRegisterModal && (
          <RegisterModal onClose={closeModals} openLoginModal={openLoginModal}/>
        )}
      </Router>
    </AuthProvider>
  );
}

export default App;