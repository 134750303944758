import React from 'react'; 
import DashboardButtons from './DashboardButtons';
import DealsDashboard from './DealsDashboard';

const styles = {
  container: {
    width: '100%', 
    marginLeft: 'auto',
    marginRight: 'auto', 
    borderRadius: '0.5rem',
    padding: '1.5rem', 
  },
  comboContainer: {
    width: '100%',
    maxHeight:'600px',
    marginLeft: 'auto',
    marginRight: 'auto',
    backgroundColor: '#fff',
    borderRadius: '0.5rem',
    padding: '1.5rem',
    marginTop: '12px', 
    maxWidth: '100%'
  } 
};

const RightDashboardSection = ({ 
  groupWishes, 
  combos,
  onRefresh, 
  isBacksAppStaff, 
  queryLoginParam,
  navigate, 
  isGroupWishesLoading,
  isCombosLoading,
  imageUrls 
}) => { 
 
   
  return (
    <>
      <div style={styles.container}>
        <DashboardButtons 
          isBacksAppStaff={isBacksAppStaff} 
          onNavigate={navigate} 
          queryLoginParam={queryLoginParam} 
          onRefresh={onRefresh} 
          imageUrls={imageUrls} 
        />            
      </div> 
      <div style={styles.container}>  
        <DealsDashboard 
          groupWishes={groupWishes} 
          combos={combos} 
          onRefresh={onRefresh} 
          isGroupWishesLoading={isGroupWishesLoading}
          isCombosLoading={isCombosLoading}
        />            
      </div>
    </>
  );
};

export default RightDashboardSection;