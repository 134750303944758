import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import JoinedCompletedDeals from './JoinedCompletedDeals';  

const JoinedCompletedDealsRoute = () => {
  const location = useLocation();
  const [dealType, setDealType] = useState("joined");
  
  useEffect(() => {  
    if (location.pathname.includes('completed')) { 
      setDealType("completed");
    } else {
      setDealType("joined");
    }
  }, [location.pathname]); 
  return (
    <JoinedCompletedDeals dealType={dealType} />
  );
};

export default JoinedCompletedDealsRoute;