import React, { useState, useEffect, useCallback ,useRef} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart, faHeartCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { faChevronDown, faChevronUp, faUsers, faTimes, faCalculator } from '@fortawesome/free-solid-svg-icons';
import { PieChart, Pie, Cell,ResponsiveContainer, Sector } from 'recharts'; 
import {useAuth} from '../../../components/Common/InitialGears/AuthContext';    
import {mainContainer, sameRowContainer, Color, RoseGoldButton} from "../../../GlobalStyles";
import RatingSlider from './RatingSlider';
import AddressSection from '../../Common/AddressSection'; 
import { format, parseISO } from 'date-fns';
import RatingsDisplay from './RatingsDisplay';  
import {TopMenuBar} from '../DashbordComponents/TopMenuBar';
import TermsAndConditionsModal from './JoiningTermsAndConditions';
import JoinGroupWishModal from './JoinGroupWishModal';
import { getApi, postApi, buildUrl } from "../../Common/BackendCalls/apiUtils";
import UserPreferencesDisplay from './UserPreferencesDisplay';
import UserPreferencesScreen from './UserPreferenceScreen'; 
import CostBreakdown from './CostBreakDown';
import {ProductDetailsCard} from './ProductDetails';

const GroupWishProgressScreen = () => { 
  const { queryLoginParam} = useAuth();
  const { userVerified } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { wish, progressData: initialProgressData, joined = true } = location.state || {};   
  const [isLoading, setIsLoading] = useState(true);
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [showJoinModal, setShowJoinModal] = useState(false); 
  const [isJoined, setIsJoined] = useState(joined); 
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [showFullDescription, setShowFullDescription] = useState(false);  
  const [addresses, setAddresses] = useState([]);
  const [showAddressSection, setShowAddressSection] = useState(false);
  const [showAddressModal, setShowAddressModal] = useState(false); 
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedRange, setSelectedRange] = useState(null);
  const [showRating, setShowRating] = useState(false);
  const [progressData, setProgressData] = useState(initialProgressData);
  const [selectedWishForJoin, setSelectedWishForJoin] = useState(null); 
  const [showSuccessMessage, setShowSuccessMessage] = useState(false); 
  const [userRejoining, setUserRejoining] = useState(false); 
  const [isCalculating, setIsCalculating] = useState(false);
  const [buddyStatus, setBuddyStatus] = useState(progressData.group_wish_buddy_status);   
  const [buttonsKey, setButtonsKey] = useState(0); 
  const [DiffDays,setDiffDays] = useState("0"); 
  const [showJoinButton, setShowJoinButton] = useState(() => { 
    if (location.state?.showJoinButton !== undefined) {
      return location.state.showJoinButton;
    } 
    return !joined || buddyStatus === 'WITHDRAWN';
  });
  const successMessageRef = useRef(null);
  const [isFavorite, setIsFavorite] = useState(() => {
    return progressData?.is_favorite || false;
  });
  const [isFavoriteProcessing, setIsFavoriteProcessing] = useState(false);
  const isApiCallInProgress = useRef(false);
  const [showPreferencesModal, setShowPreferencesModal] = useState(false); 
  const [modalData, setModalData] = useState({
    selectedRange: null,
    selectedQuantity: '',
    selectedAddress: null,
    calculatedCosts: null,
    showAddressSection: false,
    externalUnitPrice: wish?.list_price || 0
  });

  let data = progressData;
  if (typeof progressData === 'string') {
    try {
      data = JSON.parse(progressData); 
    } catch (error) { 
    }
  }

  useEffect(() => {
    fetchAddresses();
  }, []);
 
  useEffect(() => {
    setButtonsKey(prevKey => prevKey + 1);
  }, [buddyStatus]);

  useEffect(() => { 
    if (progressData?.group_wish_buddy_status) {
      setBuddyStatus(progressData.group_wish_buddy_status);
    }
  }, [progressData]);

  useEffect(() => {
    if (location.state?.successMessage) {
      setSuccessMessage(location.state.successMessage);
      setTimeout(() => {
        setSuccessMessage(null);
      }, 5000);
    }
  }, [location.state]);

  useEffect(() => { 
    if (location.state?.successMessage) {
      setSuccessMessage(location.state.successMessage);
      successMessageRef.current?.scrollIntoView({ behavior: 'smooth' });
       
      if (location.state.successMessage.includes('join')) {
        setShowJoinButton(false);
      }
    }
  }, [location.state]);

  useEffect(() => {
    if (location.state) {
      if (location.state?.showJoinButton !== undefined) {
        setShowJoinButton(location.state.showJoinButton);
      }
      if (location.state.successMessage) {
        setSuccessMessage(location.state.successMessage);
        successMessageRef.current?.scrollIntoView({ behavior: 'smooth' }); 
        if (location.state.successMessage.includes('preferences')) {
          setShowJoinButton(false);
        }
      } 
      if (location.state?.progressData?.preferences) {
        setProgressData(prevData => ({
          ...prevData,
          preferences: location.state.progressData.preferences
        }));
      }
    }
  }, [location.state]);
  const fetchAddresses = useCallback(async () => {
    try {
      const addressesUrl = buildUrl('address', `${queryLoginParam}&type=completed`);
      const response = await getApi(addressesUrl);
      
      if (response.ok) {
        const data = response.data;
        const primaryAddress = data.primary_address ? [data.primary_address] : [];
        const otherAddresses = Array.isArray(data.other_addresses) ? data.other_addresses : [];

        setAddresses([...primaryAddress, ...otherAddresses]);
      } else { 
        setAddresses([]);
      }
    } catch (error) { 
      setAddresses([]);
    } finally {
      setIsLoading(false);
    }
  }, [queryLoginParam]);

  const getCalculatedSalesTax = useCallback(async (wishId, taxCode, zipCode, to_state, quantity, priceRangeId, taxable_amount, shippingRate) => {
    try {
      const salesTaxUrl = buildUrl('getCalculatedSalesTax', queryLoginParam);
      const requestBody = {
        group_wish_id: parseInt(wishId),
        taxCode: parseInt(taxCode),
        price_range_id: parseInt(priceRangeId),
        zipCode: zipCode.toString(),
        to_state: to_state.toString(),
        quantity: parseInt(quantity),
        taxable_amount: parseInt(taxable_amount),
        shipping_rate: shippingRate,
      };
      const response = await postApi(salesTaxUrl, requestBody);      
      return response.data.tax_amount
    } catch (error) { 
    }
  }, [queryLoginParam]);

  const handleRejoinGroup = useCallback(async () => {
    if (!wish || !wish.id) { 
      setErrorMessage('Unable to re-join group.');
      return;
    } 
    setUserRejoining(true);
    setSelectedWishForJoin(wish);
    setShowTermsModal(true); 
  }, [wish.id, queryLoginParam]);

  const handleWithdrawFromGroup = useCallback(async () => {
    const confirmWithdraw = window.confirm(
      "Once you withdraw, you can only rejoin the waitlist and may or may not be added to the active order. Are you sure you want to withdraw?"
    );
  
    if (!confirmWithdraw) {
      return;  
    }
  
    setIsLoading(true);
    try {
      const withdrawUrl = buildUrl('withdrawGroupWish', queryLoginParam);
      const requestBody = {
        wish_id: wish.id
      };
  
      const response = await postApi(withdrawUrl, requestBody);
  
      if (response.ok) {
        setIsJoined(false);
        setBuddyStatus('WITHDRAWN');
        setSuccessMessage('You have successfully withdrawn from the group. If you wish to rejoin, you will be added to the waitlist.');         
      } else {
        setErrorMessage('Failed to withdraw from the group. Please try again.');
      }
    } catch (error) { 
      setErrorMessage('Failed to withdraw from the group. Please try again.');
    } finally {
      setIsLoading(false);
    }
  }, [wish.id, queryLoginParam]);

  const handleAddressSave = useCallback(async (newAddress) => { 
    setIsLoading(true);
    const addressUrl = buildUrl('address', queryLoginParam);
    const requestBody = {
      address1: newAddress.address1,
      address2: newAddress.address2,
      city: newAddress.city,
      state: newAddress.state,
      country: newAddress.country,
      postal_code: newAddress.postal_code,
      primary_residence: false,
    };
     
    try {
      const response = await postApi(addressUrl, requestBody);

      if (response.ok) {
        setAddresses(prevAddresses => [...prevAddresses, response.data]);  
        setModalData(prevData => ({
          ...prevData,
          selectedAddress: response.data
        })); 
        setShowAddressSection(false);
        await fetchAddresses();
      } else {
        setErrorMessage('Failed to update address. Please try again.');
      }
    } catch (error) { 
      setErrorMessage('Failed to update address. Please try again.');
    } finally {
      setIsLoading(false);
    }
  }, [queryLoginParam]);

  const rateGroupWish = useCallback(async (wishId, rating) => {
    setIsLoading(true);
    const grpWishURL = buildUrl('rateGroupwish', queryLoginParam);
    const requestBody = {
      grp_wish_id: wishId,
      is_upvote: rating >= 0,
      rating: rating
    };
  
    try {
      const response = await postApi(grpWishURL, requestBody);
  
      if (response.ok) {
        setProgressData(prevData => ({
          ...prevData,
          upvotes: response.data.upvotes || prevData.upvotes, 
          averageRating: response.data.averageRating || prevData.averageRating,
        }));
        setSuccessMessage('Rating submitted successfully!');
      } else {
        setErrorMessage('Failed to submit rating. Please try again.');
      }
    } catch (error) { 
      setErrorMessage('Failed to submit rating. Please try again.');
    } finally {
      setIsLoading(false);
      // Removed setShowRating(false) from here to avoid redundant state changes
    }
  }, [queryLoginParam]);
    
  const toggleFavorite = async () => {  
    if (isFavoriteProcessing || !wish?.id || isApiCallInProgress.current) { 
      return;
    }
    
    setIsFavoriteProcessing(true);
    isApiCallInProgress.current = true;
    
    try {
      const currentState = isFavorite;
      const endpoint = currentState ? 'removeFromfavoriteGroupWish' : 'addTofavoriteGroupWish'; 
      const favoriteUrl = buildUrl(endpoint, queryLoginParam);    
      
      const response = await postApi(favoriteUrl, { 
        group_wish_id: wish.id
      }); 
      
      if (response.ok) {
        const newState = !currentState;  
        setIsFavorite(newState);
        setProgressData(prevData => ({
          ...prevData,
          is_favorite: newState
        }));
      } else { 
        setErrorMessage(`Failed to ${currentState ? 'remove from' : 'add to'} favorites.`);
      }
    } catch (error) { 
      setErrorMessage('Error updating favorites. Please try again.');
    } finally { 
      setIsFavorite(!isFavorite);
      setIsFavoriteProcessing(false);
      isApiCallInProgress.current = false;
    }
  };
 
  const FavoriteButton = useCallback(() => (
    <div className="detail-item" onClick={(e) => e.stopPropagation()}>        
      <div  >
        <div className="detail-label">
          <button className="favorite-button"  onClick={toggleFavorite}  disabled={isFavoriteProcessing || isApiCallInProgress.current}>
            <FontAwesomeIcon icon={isFavorite ? faHeartCircleCheck : faHeart}
              className={`favorite-icon ${isFavoriteProcessing ? 'processing' : ''}`}
              style={{color: isFavorite ? Color.colorPurple : Color.colorRed,}}/>
          </button>
        </div> 
      </div>
    </div>
  ), [isFavorite, isFavoriteProcessing, toggleFavorite]);
  
  const calculateMaxPreferenceCost = (preferences) => {
    if (!preferences || !Array.isArray(preferences) || preferences.length === 0) {
      return 0;
    }

    // Group preferences by item_index
    const preferencesByItem = {};
    
    preferences.forEach(pref => {
      if (pref.selectedValue) {
        const itemIndex = pref.item_index || 0;
        if (!preferencesByItem[itemIndex]) {
          preferencesByItem[itemIndex] = [];
        }
        
        // Make sure additionalCharges is a valid number
        const additionalCharges = parseFloat(pref.selectedValue.additionalChargesAmount || 0);
        preferencesByItem[itemIndex].push({
          name: pref.name,
          additionalCharges: isNaN(additionalCharges) ? 0 : additionalCharges
        });
      }
    });
  
    // Calculate total cost for each item_index
    const itemTotalCosts = Object.entries(preferencesByItem).map(([itemIndex, prefs]) => {
      const total = prefs.reduce((sum, pref) => sum + pref.additionalCharges, 0);
      return { itemIndex, total };
    });
  
    // Find the maximum total cost among all item_indexes
    if (itemTotalCosts.length === 0) return 0;
    
    const maxCost = Math.max(...itemTotalCosts.map(item => item.total));
    
    // Ensure we return a valid number
    return isFinite(maxCost) ? maxCost : 0;
  };

  const CostItem = ({ label, value }) => {
    if (value === null || value === undefined || !isFinite(parseFloat(value)) || parseFloat(value) === 0) {
      return null;
    }
    return(
      <div className="cost-item-container">
        <div className="cost-item-label">{label}:</div>
        <div className="cost-item-value">${value}</div>
      </div>
    )
  };

 
  const calculateCosts = async () => {
    setIsCalculating(true);
    setErrorMessage('');
    
    try {
      const quantity = parseInt(modalData.selectedQuantity);
      const range = selectedRange;
      
      if (!range || quantity < 0) {
        setErrorMessage('Invalid quantity or price range');
        return;
      }
   
      const baseItemPrice = parseFloat(range.product_cost);      
      const baseProductCost = baseItemPrice * quantity;                      
      const shippingResponse = await calculateShippingCost(quantity, modalData.selectedAddress);       
      let preferenceCosts = 0;
      if (wish.preferences && Array.isArray(wish.preferences)) {
        const maxCost = calculateMaxPreferenceCost(wish.preferences);
        preferenceCosts = isFinite(maxCost) ? maxCost * quantity : 0;
      }
      const pre_total = baseProductCost +  shipping + preferenceCosts; 
      const taxResponse = await getCalculatedSalesTax(
        wish.id, wish.taxjar_tax_code,
        modalData.selectedAddress.postal_code, 
        modalData.selectedAddress.state, 
        quantity, 
        range.id,
        range.taxable_amount,shippingResponse.rate
      ); 
      const tax = parseFloat(taxResponse || 0);
      const shipping = parseFloat(shippingResponse.rate);
      const total = baseProductCost + tax + shipping + preferenceCosts; 
      const externalCost = parseFloat(wish.list_price) * quantity;
      const savings = range.savings_amount * quantity; 
      const formatNumber = (num) => { 
        const parsedNum = parseFloat(num); 
        if (!isFinite(parsedNum)) {
          return "0.00";
        }        
        return parsedNum.toFixed(2);
      };      
      const baseProcessingDays = 4;  
      const shippingDays = parseInt(shippingResponse.delivery_days) || 0;
      const internationalDays = wish.international_shipment_days || 0;
      const totalEstimatedDays = baseProcessingDays + shippingDays + internationalDays; 
      const estimatedDeliveryDate = new Date();
      estimatedDeliveryDate.setDate(estimatedDeliveryDate.getDate() + totalEstimatedDays);
      const formattedDeliveryDate = estimatedDeliveryDate.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      });

      setModalData(prevData => ({
        ...prevData,
        calculatedCosts: {
          productCost: formatNumber(baseProductCost),
          salesTax: formatNumber(tax),
          shippingCost: formatNumber(shipping),
          shippingDays: shippingResponse.delivery_days,
          shippingService: shippingResponse.service_type,
          preferenceCosts: formatNumber(preferenceCosts),
          total: formatNumber(total),
          externalCost: formatNumber(externalCost),
          savings: formatNumber(savings),
          savingsPercentage: formatNumber(range.savings_percentage),
          estimatedDeliveryDays: totalEstimatedDays,
          estimatedDeliveryDate: formattedDeliveryDate
        }
      }));
    } catch (error) {
      setErrorMessage('Error calculating costs. Please try again.');
    } finally {
      setIsCalculating(false);
    }
  };

  const handleAgreeTerms = async () => {
    if (selectedWishForJoin) {
      setShowJoinModal(true); 
    }
  };

  const handleJoinGroup = async (item) => { 
    if(!userVerified){
      setErrorMessage("Please verify your account to proceed");
      return;
    }
    if (!item || !item.id) { 
      setErrorMessage('Unable to join group.');
      return;
    }  
    setSelectedWishForJoin(item);
    setShowTermsModal(true);
  };

  const handleJoinSuccess = useCallback(() => {
    setIsJoined(true);
    setShowSuccessMessage(true);
    setShowJoinModal(false); 
    setTimeout(() => setShowSuccessMessage(false), 5000);
    setSuccessMessage('Successfully joined the group wish!');
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setTimeout(() => {
      setShowSuccessMessage(false);
    }, 5000);
  }, []);
 
  const calculateShippingCost = async(quantity, address) => {  
    try {
      const shippingCalculateUrl = buildUrl('getShippingRate', queryLoginParam);
      const requestBody = {
        grp_wish_id: wish.id, 
        destination_zip: address.postal_code,
        quantity: quantity
      };
  

      const response = await postApi(shippingCalculateUrl, requestBody);

      if (response && response.data) {
        return {
          rate: parseFloat(response.data.rate),
          delivery_days: response.data.delivery_days,
          service_type: response.data.service_type
        };
      }
      return {
        rate: 20.00,
        delivery_days: "5 days",
        service_type: "Standard Ground"
      };
    } catch (error) {  
      return {
        rate: 20.00,
        delivery_days: "5 days",
        service_type: "Standard Ground"
      };
    } finally {
      setIsLoading(false);
    }
  };

  const safeProgressData = {
    totalJoined: data?.totalJoined != null ? Number(data.totalJoined) : 0,
    verifiedUsers: data?.verifiedUsers != null ? Number(data.verifiedUsers) : 0,
    unverifiedUsers: data?.unverifiedUsers != null ? Number(data.unverifiedUsers) : 0,
    upvotes: data?.upvotes != null ? Number(data.upvotes) : 0, 
    progress: data?.progress != null ? Number(data.progress) : 0,
    averageRating: data?.averageRating != null ? Number(data.averageRating) : 0,
  };

  
  const pieChartData = [
    { name: 'Verified', value: safeProgressData.verifiedUsers },
    { name: 'Unverified', value: safeProgressData.unverifiedUsers },
  ].filter(item => item.value > 0);

  const renderDetailItem = (label, value, icon) => {
    if (!value) return null;
    return (
      <div className="detail-item">
        <div className="detail-icon-container">
          <FontAwesomeIcon icon={icon} size="lg" color={{...RoseGoldButton,...Color.colorPurple}} />
        </div>
        <div className="detail-text-container">
          <div className="detail-label">{label}</div>
          <div className="detail-value">{value}</div>
        </div>
      </div>
    );
  };

  const renderDescription = (description) => {
    if (!description) return null;

    const formatDescription = (text) => {
      // Split text by double newlines to separate sections
      const sections = text.split(/\r\n\r\n/);
      
      return sections.map((section, index) => {
        if (section.includes(':')) {
          // Handle sections with bullet points
          const [title, ...points] = section.split(/\r\n/);
          return (
            <div key={index} className="description-section">
              <h4 className="section-title">{title}</h4>
              <ul className="section-points">
                {points.map((point, i) => {
                  const trimmedPoint = point.replace(/^[-•]?\s*/, '');
                  if (trimmedPoint) {
                    return <li key={i}>{trimmedPoint}</li>;
                  }
                  return null;
                })}
              </ul>
            </div>
          );
        } else {
          // Handle regular paragraphs
          return <p key={index} className="description-paragraph">{section}</p>;
        }
      });
    };
  
    const maxLength = 150;
    const shouldShowMore = description.length > maxLength;

    return ( 
      <div className="description-container">
        <div className="description-content">
          {shouldShowMore && !showFullDescription
            ? (
              <>
                <div>{formatDescription(description.substring(0, maxLength) + '...')}</div>
                <button className="show-more-button" onClick={() => setShowFullDescription(true)}>
                  <span>Show More</span>
                  <FontAwesomeIcon icon={faChevronDown} />
                </button>
              </>
            ) : (
              <>
                <div>{formatDescription(description)}</div>
                {shouldShowMore && (
                  <button className="show-less-button" onClick={() => setShowFullDescription(false)}>
                    <span>Show Less</span>
                    <FontAwesomeIcon icon={faChevronUp} />
                  </button>
                )}
              </>
            )
          }
        </div>
      </div>
    );
  };

  const formatAddress = (address) => {
    if (!address) return 'No address selected';

    return `${address.address1}, ${address.city}, ${address.state} ${address.postal_code}`;
  };


  const resetModalData = () => {
    setModalData({
      selectedRange: null,
      selectedQuantity: '',
      selectedAddress: null,
      calculatedCosts: null
    });
  };
  
  const openCalculatorModal = (range) => {
    resetModalData();
    setSelectedRange(range);
    setModalVisible(true);
  };
  
  const handleModalClose = () => {
    setModalVisible(false);
    resetModalData();
  };

  const handleQuantityChange = (e) => {
    const value = e.target.value;
    if (value < 0) return;  
    
    setModalData(prevData => ({
      ...prevData,
      selectedQuantity: value,
      calculatedCosts: null  
    }));
  };

  const handleAddressChange = (e) => { 
    const value = e.target.value;
    if (value === 'add_new') { 
      setShowAddressSection(true);
    } else if (value !== '') {
      const selectedAddress = JSON.parse(value); 
      setModalData(prevData => ({
        ...prevData,
        selectedAddress: selectedAddress
      }));
      setShowAddressSection(false); 
    } else { 
      setModalData(prevData => ({
        ...prevData,
        selectedAddress: null
      }));
    }
  };

  const handleAddressAdded = (newAddress) => {
    setAddresses(prevAddresses => [...prevAddresses, newAddress]);
    setModalData(prevData => ({
      ...prevData,
      selectedAddress: newAddress
    }));
    setShowAddressModal(false);
    setShowAddressSection(false);
  };

  const calculateBasePrice= useCallback((productCost) => {  
    return parseFloat(productCost).toFixed(2);
  }, [wish.list_price]);

  const calculateSavings = useCallback((rangePrice) => {   
    let listPrice = parseFloat(wish.list_price);     
    const productCost = parseFloat(rangePrice.product_cost);
    const savingsAmount = listPrice - productCost;
    const savingsPercentage = (savingsAmount / listPrice) * 100;  
     
    return { 
      amount: Math.abs(savingsAmount).toFixed(2), 
      percentage: Math.abs(savingsPercentage).toFixed(2),  
      isPositive: savingsAmount > 0  
    };
  }, [wish.list_price]);

  const ShippingComparisonNote = () => {
    return (
      <div className="shipping-info-container p-4 bg-gray-50 rounded-lg">
        <h3 className="text-lg font-semibold mb-2 text-gray-800">Understanding Your Total Savings</h3>
        <div className="space-y-3">
          <p className="text-sm text-gray-600">
            While Amazon Prime offers free shipping, BacksApp's group buying power provides significant overall savings:
          </p>
          <ul className="list-disc pl-5 space-y-2 text-sm text-gray-600">
            <li><span className="font-medium">Bulk Pricing Benefits:</span> Our group orders access wholesale/bulk pricing not available to individual buyers</li>
            <li><span className="font-medium">Net Savings:</span> Even with shipping costs, your total price is typically 30-70% lower than retail prices</li>
            <li><span className="font-medium">Quality Assurance:</span> We use reliable shipping services to ensure your bulk orders arrive safely</li>
          </ul>
          <p className="text-sm text-gray-600 mt-3">
            The shipping fee is included in your product cost calculation, so the savings shown already account for all costs. You're still saving significantly compared to retail prices.
          </p>
        </div>
      </div>
    );
  };

  const renderPriceRanges = () => {
    const priceRanges = wish.price_ranges; 
    if (!priceRanges || priceRanges.length === 0) return null;

    return (
      <div className="card">
        <div className="price-ranges-container">
          <h3 className="sub-card-title">Price Ranges</h3>
          <div>
            <p className="price-range-note-text">
              Product costs vary based on our collective order quantity. Typically, higher quantities lead to better pricing. For accurate individual costs, please use the calculator for each price range.
            </p>
          </div>
          {ShippingComparisonNote}
          {SalesTaxDisclaimerNote()}
          {CostBreakdown}
          <div className="price-range-table">
            <div className="price-range-header">
              <div className="column-heading">Calculate</div>
              <div className="column-heading">Orders</div>
              <div className="column-heading">Savings</div>
              <div className="column-heading">Price/Item</div>
            </div>
            {wish.price_ranges.map((range, index) => {
              const savings = calculateSavings(range.product_cost);
              const basePrice = calculateBasePrice(range.product_cost)
              return (
                <div key={index} className="price-range-row">
                  <div className="column">
                    <button className="calculate-button" onClick={() => openCalculatorModal(range)}>
                      <FontAwesomeIcon icon={faCalculator} size="lg" color="#89a96a" />
                    </button>
                  </div>
                  <div className="column">{`${range.price_range_start} - ${range.price_range_end}`}</div>
                  <div className="savings-column">
                    <div className="savings-percentage">
                      {`${range.savings_percentage}%`}
                    </div>
                    <div className="savings-amount">
                      {`$${range.savings_amount}`}
                    </div>
                  </div>
                  <div className="column">{`$${basePrice}`}</div>                  
                </div>
              );
            })}
          </div>
          </div>
      </div>
    );
  };
  const COLORS = ['#4CAF50', '#FF9800', '#E91E63'];

const renderActiveShape = (props) => {
  const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;

  return (
    <g>
      <Sector cx={cx} cy={cy} innerRadius={innerRadius}  outerRadius={outerRadius} startAngle={startAngle} endAngle={endAngle} fill={fill}/>
      <Sector cx={cx} cy={cy} startAngle={startAngle} endAngle={endAngle} innerRadius={outerRadius + 6} outerRadius={outerRadius + 10} fill={fill}/>
      <text x={cx} y={cy} dy={-4} textAnchor="middle" fill={fill} style={{ fontSize: '16px', fontWeight: 'bold' }}>
        {payload.name}
      </text>
      <text x={cx} y={cy} dy={20} textAnchor="middle" fill="#333" style={{ fontSize: '14px' }}>
        {`${value} (${(percent * 100).toFixed(0)}%)`}
      </text>
    </g>
  );
};

const CustomPieChart = ({ data, totalRequired }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const totalValue = data.reduce((sum, entry) => sum + entry.value, 0);
  const remainingValue = Math.max(0, totalRequired - totalValue);

  const chartData = [
    ...data,
    { name: 'Remaining', value: remainingValue }
  ];

  return (
    <ResponsiveContainer width="100%" height={300}>
      <PieChart>
        <Pie activeIndex={activeIndex} activeShape={renderActiveShape} data={chartData} cx="50%" cy="50%" innerRadius={60} outerRadius={80} fill="#8884d8" dataKey="value" onMouseEnter={(_, index) => setActiveIndex(index)}>
          {chartData.map((entry, index) => (
            <Cell key={`cell-${index}`} 
              fill={index === chartData.length - 1 ? 'url(#remainingPattern)' : COLORS[index % COLORS.length]}
              stroke={index === chartData.length - 1 ? '#ccc' : 'none'} strokeWidth={index === chartData.length - 1 ? 2 : 0} />
          ))}
        </Pie>
        <defs>
          <pattern id="remainingPattern" patternUnits="userSpaceOnUse" width="4" height="4">
            <path d="M-1,1 l2,-2 M0,4 l4,-4 M3,5 l2,-2" style={{ stroke: '#ccc', strokeWidth: 1 }} />
          </pattern>
        </defs>
      </PieChart>
    </ResponsiveContainer>
  );
};

  const renderPieChart = () => (    
    <div className="rightCard">
        <h3 className="sub-card-title">User Composition</h3>        
        <CustomPieChart data={pieChartData} totalRequired={wish.group_size_min} />
        <div className="legend">
          {pieChartData.map((entry, index) => (
            <div key={entry.name} className="legend-item">
              <span className="legend-color" style={{ backgroundColor: COLORS[index] }}></span>
              <span>{entry.name}: {entry.value}</span>
            </div>
          ))}
          <div className="legend-item">
            <span className="legend-color remaining"></span>
            <span>Pending: {Math.max(0, wish.group_size_min - safeProgressData.verifiedUsers)}</span>
          </div>
        </div>
        <div  >
          <p className="note-text">
          The pie chart illustrates the composition of users, indicating how many are verified and unverified. Only verified users are considered when calculating the overall minimum quantity needed to place orders. We strongly encourage you to verify your account by clicking the verification link. Additionally, please recommend that any friends joining the group complete their verification as well.
          </p>
        </div>
    </div>
  )

  const renderParticipation = () => (
      <div className="rightCard">
            <div className="card-content">
              <h3 className="card-title">Participation</h3>
              <div className="stats-row">
                <div className="stat-item">
                  <FontAwesomeIcon icon={faUsers} size="2x" color="#89a96a" />
                  <p className="stat-value">{safeProgressData.totalJoined}</p>
                  <p className="stat-label">Total Joined</p>
                </div>
                <div className="stat-item">
                  <FontAwesomeIcon icon={faUsers} size="2x" color="#89a96a" />
                  <p className="stat-value">{safeProgressData.verifiedUsers}</p>
                  <p className="stat-label">Verified {safeProgressData.verifiedUsers === 1 ? 'User' : 'Users'}</p>
                </div>
                <div className="stat-item">
                  <FontAwesomeIcon icon={faUsers} size="2x" color="#d9ae67" />
                  <p className="stat-value">{safeProgressData.unverifiedUsers}</p>
                  <p className="stat-label"> Unverified {safeProgressData.unverifiedUsers === 1 ? 'User' : 'Users'}</p>
                </div>
              </div>
            </div>
          </div>
  );
 
  const SalesTaxDisclaimerNote = () => {
    return (
      <div className="tax-disclaimer-container p-4 bg-gray-50 rounded-lg my-3">
        <h3 className="text-lg font-semibold mb-2 text-gray-800">Important Tax Information</h3>
        <p className="text-sm text-gray-600">
          Sales tax is calculated based on your delivery address and current tax rates. Please note that tax amounts may vary at the time of purchase due to:
        </p>
        <ul className="list-disc pl-5 mt-2 space-y-1 text-sm text-gray-600">
          <li>Changes in local tax regulations</li>
          <li>Seasonal tax adjustments</li>
          <li>Tax jurisdiction updates</li>
          <li>Special tax events or holidays</li>
        </ul>
        <p className="text-sm text-gray-600 mt-2 italic">
          The final tax amount will be calculated at checkout based on the most current rates.
        </p>
      </div>
    );
  };
  const renderCalculatorModal = () => {
    return(
    <div className={`modal ${modalVisible ? 'is-active' : ''}`}>
      <div className="modal-background" onClick={handleModalClose}></div>
      <div className="modal-content">
        <div className="calculator-modal-container">
          <button className="close-button" onClick={handleModalClose}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
          <h2 className="modal-title">Calculate Cost for Range</h2>
          <p className="range-info">
            This estimate calculates your individual cost if the total group order falls within:
          </p>
          <p className="range-info-details">
            Range: {selectedRange?.price_range_start} - {selectedRange?.price_range_end}
          </p>
          <div className="field">
            <label className="label">Quantity:</label>
            <div className="control">
              <input className="input" type="number" value={modalData.selectedQuantity} onChange={handleQuantityChange} placeholder={`Enter quantity`}/>
            </div>
          </div>
          <div className="field">
            <label className="label">Delivery Address:</label>
            <div className="control">
              <div className="select">
                <select value={modalData.selectedAddress ? JSON.stringify(modalData.selectedAddress) : ''} onChange={handleAddressChange}>
                  <option value="">Select an address</option>
                  {addresses.map((address, index) => (
                    <option key={index} value={JSON.stringify(address)}>
                      {formatAddress(address)}
                    </option>
                  ))}
                  <option value="add_new">+ Add New Address</option>
                </select>
              </div>
            </div>
          </div>
          {showAddressSection && (
            <AddressSection onAddressAdded={handleAddressAdded} address={""} callFromProfiles={false} handleAddressSave={handleAddressSave}/>
          )}
          <button className="button is-primary" onClick={calculateCosts}  disabled={isCalculating}>Calculate</button>
          {isCalculating && (
            <div className="spinner-overlay">
              <div className="spinner"></div>
            </div>
          )}
          {modalData.calculatedCosts && (
            <>
            <div className="estimate-container">
              <div className="estimate-header">
                <h3 className="estimate-header-text">
                  Estimate for {modalData.selectedQuantity} items
                </h3>
              </div>
              
              <div className="estimate-content">
                <div className="estimate-address-container">
                  <p className="estimate-address-label">Delivery Address:</p>
                  <p className="estimate-address">{formatAddress(modalData.selectedAddress)}</p>
                </div>
                <div className="cost-breakdown">
                  <CostItem label="Product Cost" value={modalData.calculatedCosts.productCost} />
                  <CostItem label="Shipping" value={modalData.calculatedCosts.shippingCost} />
                  <CostItem label="Estimated Sales Tax" value={modalData.calculatedCosts.salesTax} />
                  <CostItem label="Additional Costs" value={modalData.calculatedCosts.preferenceCosts} />
                </div>
                <div className="total-cost-container">
                  <div className="total-cost-label">Total:</div>
                  <div className="total-cost-value">
                    ${modalData.calculatedCosts.total}
                  </div>
                </div>
                <div className="savings-container">
                  <div className="savings-row">
                    <div className="outside-cost-label">External cost:</div>
                    <div className="outside-cost-value">
                      ${modalData.calculatedCosts.externalCost}
                    </div>
                  </div>                  
                  <div className="cost-comparison-row">
                    <div className="savings-label">With BacksApp You Save:</div>
                    <div className="savings-value">
                      ${modalData.calculatedCosts.savings} ({modalData.calculatedCosts.savingsPercentage}%)
                    </div>
                  </div>
                </div>
                <div className="cost-disclaimer">
                  <p>Note: These costs are current estimates and may vary based on carrier rates, tax regulations, and other third-party fees at the time of purchase.</p>
                </div>
              </div>
              </div>
              <div className="delivery-estimate-container">
              <div className="delivery-estimate-header">
                <h3 className="delivery-estimate-header-text">
                Approximate Delivery Timeline
                </h3>
              </div>
                                                    
                <div className="delivery-info">
                  <div className="delivery-date">
                    <span className="delivery-label">Expected Delivery:</span>
                    <span className="delivery-value">{modalData.calculatedCosts.estimatedDeliveryDate}</span>
                  </div>
                  
                  <div className="delivery-breakdown">
                    <div className="breakdown-item">
                      <span className="breakdown-label">Processing Time:</span>
                      <span className="breakdown-value">3-4 business days</span>
                    </div>
                    <div className="breakdown-item">
                      <span className="breakdown-label">Shipping Time:</span>
                      <span className="breakdown-value">{modalData.calculatedCosts.shippingDays} days ({modalData.calculatedCosts.shippingService})</span>
                    </div>
                    <div className="breakdown-item">
                      <span className="breakdown-label">International Shipping:</span>
                      <span className="breakdown-value">{wish.international_shipment_days} days</span>
                    </div>
                  </div>
                  <div className="delivery-disclaimer">
                    * Estimated delivery dates are tentative and may vary based on customs clearance, local delivery conditions, and other factors.
                  </div>
                </div>  
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );}

  const formatExpiryDate = (expiryDateString) => {
    if (!expiryDateString) return 'Date not available';
    try { 
      const expiryDate = parseISO(expiryDateString); 
      const formattedDate = format(expiryDate, "MMMdo,`yy h:mm aa");        
      return `${formattedDate}`;
    } catch (error) { 
      return 'Invalid date';
    }
  };
  
  useEffect(() => {
    if (wish?.expires_at) {
      const calculateDiffDays = () => {
        try {
          const expiryDate = parseISO(wish.expires_at);
          const now = new Date();
          const diffTime = expiryDate.getTime() - now.getTime();
          let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
          diffDays = diffDays < 0 ? '0' : diffDays.toString();
          setDiffDays(diffDays);
        } catch (error) {
          setDiffDays('0');
        }
      };  
      calculateDiffDays();
    }
  }, [wish?.expires_at]);

  const formatTimeRemaining = (expiryDateString) => {
    if (!expiryDateString) return '';
    try {
      const expiryDate = parseISO(expiryDateString);
      const now = new Date(); 
      const diffTime = expiryDate.getTime() - now.getTime(); 
      let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      diffDays=  diffDays < 0 ? '0' : diffDays.toString();
      setDiffDays(diffDays);
      return diffDays;
    } catch (error) { 
      return '';
    }
  }
  
  const handleRate = async (rating) => {
    if (!userVerified) {
      setErrorMessage('Please verify your account to rate this group wish');
      return;
    }
    await rateGroupWish(wish.id, rating); 
  };

  const parseRatingCounts = (ratingCountsString) => {
    const counts = {};
    if (Array.isArray(ratingCountsString)) {
      ratingCountsString.forEach(item => {
        const match = item.match(/(-?\d+) stars? (\d+) users?/);
        if (match) {
          const [, rating, count] = match;
          counts[parseInt(rating)] = parseInt(count);
        }
      });
    }
    return counts;
  };

  const ratingCounts = parseRatingCounts(progressData.rating_counts || []);
  const totalRatings = Object.values(ratingCounts).reduce((a, b) => a + b, 0);

  const handleReferAFriend = (item) => { 
    navigate("/invites", { state: { grp_wish: item } });
  };

  const renderButtons = useCallback(() => {
    if (!showJoinButton) return null;
    
    if (buddyStatus === null || buddyStatus === 'WITHDRAWN') {
      return (
        <button className="button join-button" style={{...RoseGoldButton}} onClick={() => 
          buddyStatus === 'WITHDRAWN' ? handleRejoinGroup() : handleJoinGroup(wish)
        }>
          {buddyStatus === 'WITHDRAWN' ? 'Rejoin Group' : 'Join Group'}
        </button>
      );
    }
    return null;
  }, [buddyStatus, showJoinButton, handleJoinGroup, handleRejoinGroup, wish]);

  return (
    <div style={mainContainer}>
    <TopMenuBar title={"Group Wish Details"} showBackButton={false} showHomeButton={true} showMenuButton={true} onBackClick={() => navigate(-1)} showSearch={false} showAddress={false} />           
    {successMessage && (
      <div className="success-message">
        {successMessage}
      </div>
    )}
    {errorMessage && (
        <div className="error-message">
          {errorMessage}
        </div>
    )} 
    <div className="header" style={sameRowContainer}>                 
      {isLoading && (
        <div className="loading-container" style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 9999 }}>
          <div className="spinner"></div>
        </div>
      )} 

      <div className="content-wrapper">
        <div className="left-column"> 
          <ProductDetailsCard productName={wish.product_name} imageUrl={wish.image_url} category={wish.category} marketPrice={wish.list_price}  groupSize={wish.group_size_min} expiryDate={formatExpiryDate(wish.expires_at)} daysLeft={DiffDays} additionalMedia={wish.additional_media} /> 
            <div className="card">
              <div className="card-content" >
                <div style= {styles.sameRowContainer}>
                  <p className="card-title" > 
                    Description 
                  </p>
                </div>
                {renderDescription(wish.description)}
            </div>
            </div>  
            {renderPriceRanges()}  
            {isJoined && (
              <div className="card">
                <UserPreferencesDisplay preferences={wish?.preferences || []} onEditPreferences={() => setShowPreferencesModal(true)} isJoined={isJoined} wish={wish}  />
              </div>
            )}             
        </div>
        <div className="right-column">
          <div className="right-column-sticky">
            <div className="card price-ranges-card">
              <div className="card-content">                
                {renderPieChart()}
                {renderParticipation()}
                {<RatingsDisplay ratings={ratingCounts} totalRatings={totalRatings}  averageRating={safeProgressData.averageRating} upvotes={safeProgressData.upvotes} totalJoined={safeProgressData.totalJoined}/>}
                {(buddyStatus === 'RE_JOINED' || buddyStatus === 'JOINED') && (
                  <div className="rightCardPlain">                 
                    <button className="button withdraw-button" onClick={handleWithdrawFromGroup} style={{...RoseGoldButton}}>
                      Withdraw
                    </button>                 
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {showRating && (
          <div className="modal is-active">
            <div   onClick={() => setShowRating(false)}></div> 
              <RatingSlider onRate={(rating) => {handleRate(rating);setTimeout(() => setShowRating(false), 100);}} onClose={() => setShowRating(false)} /> 
          </div>
      )}
      <div className="button-row">          
          <button className="button invite-button" onClick={() => handleReferAFriend({wish})}>
            Refer
          </button>
          {renderButtons()}
          <button className="button rate-button" onClick={() => setShowRating(true)} style={{opacity: userVerified ? 1 : 0.6, cursor: userVerified ? 'pointer' : 'not-allowed'}}>
          {progressData?.user_vote ? 'Update Rating' : 'Rate'}
          </button>
      </div>
      {renderCalculatorModal()}        
      <TermsAndConditionsModal isOpen={showTermsModal} onClose={() => setShowTermsModal(false)} onCancel={() => {setShowTermsModal(false);setShowJoinModal(false)}} onAgree={handleAgreeTerms}/>
      <JoinGroupWishModal isOpen={showJoinModal} onClose={() => setShowJoinModal(false)} wish={initialProgressData} onJoinSuccess={handleJoinSuccess} isRejoin={userRejoining}/>
      {showPreferencesModal && (
          <UserPreferencesScreen isOpen={showPreferencesModal} onClose={() => {setShowPreferencesModal(false);setShowJoinModal(false)}} grp_wish_id={wish.id} groupWishName={wish.product_name} existingPreferences="update" quantity={1} combo={false} />
        )}
    </div>
  </div> 
  );
};

const styles = `
  .container { 
    position: relative;
    min-height: 100vh;
    padding: 20px;
  }  
  .success-message {
    background-color: #e6f3e6;
    color: #2e7d32;
    padding: 15px;
    margin: 20px auto;
    max-width: 800px;
    border-radius: 8px;
    text-align: center;
    font-weight: 500;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .success-message {
    padding: 10px,
    background-color: Color.colorVibrantGreen,
    color: Color.colorWhite,
    border-radius: 6px,
    margin-bottom: 12px,
    font-size: 18px,
    font-weight: 600,
  }
  .error-message {
    padding: 10px,
    background-color: Color.colorVibrantRed,
    color: Color.colorWhite,
    border-radius: 6px,
    margin-bottom: 12px,
    font-size: 18px,
    font-weight: 600,
  }
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    backgroundColor: Color.colorMintGreen,
    padding: '20px',
    borderRadius: '8px',
    marginBottom: '20px',
  },
  .title {
    font-size: 24px;
    font-weight: bold;
    color: colo;
    margin-bottom: 5px;
  }
  .subtitle {
    font-size: 18px;
    color: ${Color.colorGray};
    text-align: center;
  }
  .content-wrapper { 
    display: flex;
    gap: 20px;
    position: relative;
    max-width: 2000px;
    margin: 0 auto;
    padding: 20px;
  }
  .left-column {
    flex: 2;
    background-color: ${Color.colorLightestBeige};
    border-radius: 8px;
    padding:10px;
  } 
  .right-column-sticky {
    position: sticky;
    top: 20px;
    background-color: transparent;
    z-index: 10;
    transition: all 0.3s ease;
  }
  @media (max-width: 1024px) {
    .content-wrapper {
      flex-direction: column;
    }
 
  }
  .card {
    background-color: ${Color.colorWhite};
    border-radius: 8px;
    margin-bottom: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin: 10px;
  }
  .rightCard {
    background-color: ${Color.colorLightestBeige};
    border-radius: 8px;
    margin-bottom: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
  }
  .rightCardPlain { 
    border-radius: 8px;
    margin-bottom: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
  } 
  .card-content {
    padding: 10px;
  }
  .card-name-title{
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 15px; 
    color: ${Color.colorRoseGoldText};  
  }
  .card-title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 15px; 
    color: #003366;  
  }
  .product-info-card {
    background-color: ${Color.colorWhite};
    border-radius: 8px;
    margin-bottom: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .product-info-grid {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 20px;
    align-items: start; 
  }

  .product-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${Color.colorLightestBeige};
    border-radius: 8px;
    padding: 10px;
  }

  .product-image {
    max-width: 100%;
    max-height: 200px;
    object-fit: contain;
  }
  .shipping-details {
    background-color: #f8f9fa;
    border-radius: 8px;
    padding: 12px;
    margin: 10px 0;
    border: 1px solid #e9ecef;
  }
  
  .shipping-service {
    color: ${Color.colorRoseGoldText};
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 4px;
  }
  
  .delivery-estimate {
    color: ${Color.colorDarkGrey};
    font-size: 13px;
  }
  
  .product-details {
    display: flex;
    flex-direction: column;
    gap: 10px; 
  }

  .detail-item {
    display: flex;
    align-items: center;
    gap: 15px;
    padding: 3px;
    background-color: ${Color.colorWhite};
    border-radius: 8px;
  }

  .modal-title {
    color: ${Color.colorPurple};
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    margin: 0;
    margin-bottom: 15px;
  }
  .range-info {
    font-size: 10px;
    color: ${Color.colorDarkGrey}; 
    background-color: ${Color.colorLightBeige};
    padding: 12px; 
    margin: 10px 0;
    line-height: 1.4;
    text-align: left;
  }

  .range-info-details {
    font-size: 14px;
    color: ${Color.colorDarkGrey}; 
    padding: 12px; 
    margin: 10px 0;
    line-height: 1.4;
    text-align: center;
  }

  .detail-icon-container {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${Color.colorWhite};
    border-radius: 50%;
  }

  .detail-text-container {
    flex: 1;
  }

  .detail-label {
    font-size: 14px;
    color: ${Color.colorDarkGrey};
    margin-bottom: 2px;
  }

  .detail-value {
    font-size: 16px;
    color: ${Color.colorRoyalBlueText};
    font-weight: bold;
  }

  .description-header {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
  }

  .description-container {
    padding: 15px;
    background-color: ${Color.colorWhite};
    border-radius: 8px;
  }
  .progress-bar {
    height: 10px;
    background-color: #e0e0e0;
    border-radius: 5px;
    overflow: hidden;
  }
  .progress-bar-fill {
    height: 100%;
    background-color: #d9ae67;
  }
  .progress-text {
    text-align: center;
    margin-top: 10px;
    font-weight: bold;
    color: ${Color.colorDarkGrey};
  }
  .stats-row {
    display: flex;
    justify-content: space-around;
  }
  .stat-item {
    text-align: center;
  }
  .stat-value {
    font-size: 18px;
    font-weight: bold;
    margin: 5px 0;
  }
  .stat-label {
    font-size: 14px;
    color: ${Color.colorDarkGrey};
  }
  .placeholder-chart-container {
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f0f0f0;
    border-radius: 8px;
  }
  .no-data-text {
    font-size: 16px;
    color: ${Color.colorDarkGrey};
  }
  .button-row {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 20px;
    max-width: 800px; 
  }
  .button-row {
    display: flex;
    justify-content: center;  
    gap: 20px;  
    margin-top: 20px;
    margin-bottom: 20px;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;  
  }
   
  .invite-button, .rate-button, .join-button .rejoin-button .withdraw-button  {
    min-width: 120px;  
    flex: 0 1 auto; 
    padding: 10px 25px; 
    border-radius: 8px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    text-align: center;
    transition: background-color 0.3s ease;
    margin: 0;  
  }
   
  .invite-button {
    margin-left: 0;
    background-color: ${Color.colorDarkBlue};
    color: white;
    border: none;
  }
  
  .rate-button {
    margin-left: 0;
    background-color: ${Color.colorPurpleBlue};
    color: white;
    border: none;
  }
  
  .join-button {
    background-color: ${Color.colorLimeGreen};
    color: white;
    border: none;
  } 
  .invite-button:hover, .rate-button:hover, .join-button:hover {
    opacity: 0.9;
  }
  .rejoin-button {
    background-color: ${Color.colorLimeGreen};
    color: white;
    border: none; 
  }
  .withdraw-button { 
    background-color: ${Color.colorWhite};
    color: ${Color.colorDarkBeige};;
    border: none;
    padding: 10px 20px;
    border-radius: 8px; 
    opacity: 0.7;  
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .price-ranges-container {      
    padding: 20px;
  }
  .sub-card-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
    color: ${Color.colorPeacockBlueDark};
    margin-left:5px;
  }
  .price-ranges-card {
    height: 100%;
    width: 100%;
  }
  .note-card {
    background-color: ${Color.colorCoolBeige}; 
    border-radius: 5px;
    margin-bottom: 1px; 
    margin-left:-12px;
    margin-right:2px;
    width: 107%;
  }
  .price-range-table {
    display: table;
    width: 100%;
    border-collapse: collapse;
  }
  .price-range-note-text {
    font-size: 14px;
    background-color: ${Color.colorLightBeige};
    color: ${Color.colorDarkBlue}; 
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 15px;
    margin-left:10px;
    margin-right:10px;
  }

  .note-text {
    font-size: 14px;
    color: ${Color.colorDarkGrey};
  }
 
.price-range-row {
  display: table-row;
}

.price-range-header .column {
  font-weight: bold;
  background-color: #f5f5f5;
}

  .price-range-header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #ccc;
    padding-bottom: 8px;
    margin-bottom: 8px;
  }
  .column-heading {
    flex: 1;
    font-weight: bold;
    font-size: 14px;
    text-align: center;
    color: #555;
  }
  .price-range-row {
    display: flex;
    justify-content: space-between;
    padding: 8px 0;
    border-bottom: 1px solid #eee;
  }
  .column {
    flex: 1;
    font-size: 14px;
    text-align: center;
    color: #333;
  }
  .savings-column {
    flex: 1;
    text-align: center;
  }
  .savings-percentage {
    font-size: 14px;
    font-weight: bold;
    color: ${Color.colorPurple};
  }
  .savings-amount {
    font-size: 12px;
    color: ${Color.colorPurple};
  }
  .calculate-button {
    background: none;
    border: none;
    cursor: pointer;
  }
  .cost-disclaimer {
    margin-top: 15px;
    padding: 10px;
    background-color: #f8f9fa;
    border-radius: 8px;
    border: 1px solid #e9ecef;
  }
  
  .cost-disclaimer p {
    font-size: 12px;
    color: ${Color.colorDarkGrey};
    text-align: center;
    margin: 0;
    font-style: italic;
  }
  .delivery-estimate-container {
    margin-top: 20px;
    border: 1px solid ${Color.colorPeacockBlue};
    background-color: white;
    border-radius: 8px;
    overflow: hidden;
  } 
  .delivery-estimate-header {
    background-color: white;
    padding: 10px;
  }
  .delivery-estimate-header-text {
    color: ${Color.colorPeacockBlue};
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    margin: 0;
  }
.delivery-info {
    padding: 10px;
    background-color: white;
    border-radius: 6px;
}

/* Expected delivery date section */
.delivery-date {
    padding: 10px;
    margin-bottom: 10px;
    background-color: ${Color.colorWhite};
    border-radius: 6px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.delivery-label {
    font-size: 14px;
    color: ${Color.colorPurple};
}

.delivery-value {
    font-size: 14px;
    color: ${Color.colorPurple};
    font-weight: bold;
}

/* Timeline breakdown section */
.delivery-breakdown {
    padding: 10px;
    background-color: #ffffff;
    border-radius: 6px;
}

.breakdown-item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    padding: 5px 0;
    border-bottom: 1px dashed #e0e0e0;
}

.breakdown-item:last-child {
    border-bottom: none;
    margin-bottom: 0;
}

.breakdown-label {
    font-size: 13px;
    color: #4a4a4a;  /* colorDarkGrey */
}

.breakdown-value {
    font-size: 13px;
    color: #003366;  /* colorDarkBlue */
    font-weight: 500;
}

/* Disclaimer text */
.delivery-disclaimer {
    margin-top: 12px;
    font-size: 12px;
    color: #808080;  /* colorGrey */
    font-style: italic;
    text-align: center;
}
  .modal {
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.3s;
  }
  
  .modal.is-active {
    visibility: visible;
    opacity: 1;
  }
  
  .modal-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  .modal-content {
    position: relative;
    z-index: 1001;
  } 
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 20px;
    color:  ${Color.colorPurple};
    cursor: pointer;
  }  
  .field {
    margin-bottom: 20px;
  }
  .label {
    display: block;
    font-size: 16px;
    color: ${Color.colorDarkGrey};
    margin-bottom: 5px;
  }
  .select {
    position: relative;
    width: 100%;
  }

  .select select {
    width: 100%;
    padding: 12px 16px;
    border: 1px solid #e2e8f0;
    border-radius: 6px;
    font-size: 14px;
    line-height: 1.5;
    background-color: white;
    color: #2d3748;
    cursor: pointer;
    appearance: none;
    transition: all 0.2s ease;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  }

  .select::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #718096;
    pointer-events: none;
  }

  .select select:hover {
    border-color: #cbd5e0;
  }

  .select select:focus {
    outline: none;
    border-color: #4299e1;
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.15);
  }

  .select select option {
    padding: 12px;
    font-size: 14px;
    background-color: white;
    color: #2d3748;
  }

  .select select option:hover {
    background-color: #f7fafc;
  }

  .select select option[value="add_new"] {
    color: #4299e1;
    font-weight: 500;
  }

  .select select:invalid {
    color: #a0aec0;
  }
  .input {
    width: 95%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 8px;
    font-size: 16px;
  }
  .button.is-primary {
    background-color: ${Color.colorMintGreen};
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 8px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    width: 100%;
    margin-top: 10px;
  }
  .estimate-container {
    margin-top: 20px; 
    border: 1px solid ${Color.colorPeacockBlue};
    background-color: white;
    border-radius: 8px;
    overflow: hidden;
  } 
  .estimate-header {
    background-color: white;
    padding: 10px;
  }
  .estimate-header-text {
    color: ${Color.colorPeacockBlue};
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    margin: 0;
  }
  .estimate-content {
    padding: 15px;
  }
  .estimate-address-container {
    margin-bottom: 10px;
  }
  .estimate-address-label {
    font-size: 14px;
    color: ${Color.colorDarkGrey};
    margin-bottom: 2px;
  }
  .estimate-address {
    font-size: 14px;
    color: ${Color.colorPurple};
    font-weight: bold;
  }
  .cost-breakdown { 
    background-color: white;
    border-radius: 8px;
    padding: 10px;
    margin-bottom: 10px;
  }
  .cost-item-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;  
  }
  .cost-item-label {
    font-size: 14px;
    color: ${Color.colorDarkGrey};
  }
  .cost-item-value {
    font-size: 14px;
    font-weight: bold;
    color: ${Color.colorPeacockBlueDark};
  }
  .total-cost-container {
    display: flex;
    justify-content: space-between;
    padding-top: 5px;
    border-top: 1px solid ${Color.colorMintGreen};
  }
  .total-cost-label {
    font-size: 16px; 
    color: ${Color.colorDarkGrey};
  }
  .total-cost-value {
    font-size: 18px; 
    color: ${Color.colorPeacockBlueDark};
  }
  .same-row-container {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .savings-container {
    margin-top: 10px;
    padding: 10px;
    background-color: ${Color.colorWhite};
    border-radius: 8px;
    align-items: center;
  }

  .savings-row, .cost-comparison-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
  }
  
  .savings-label, .outside-cost-label {
    font-size: 14px;
    color: ${Color.colorVibrantRed};
  }
  
  .savings-value, .outside-cost-value {
    font-size: 16px;
    font-weight: bold;
    color: ${Color.colorVibrantRed};
  }
  
  .cost-comparison-row {
    padding-top: 5px;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    margin-top: 5px;
  }

  .time-info-container {
    position: relative;
    margin-bottom: 20px;
    display: flex;
    align-items: flex-start;  /* Align items to the top */
    justify-content: space-between; 
  }

  .expiry-date-container {
    border-radius: 8px;
    padding: 10px 15px;
    flex-grow: 1; 
  }

  .wish-time-info {
    font-size: 14px;
    color: #FF4500;
    background-color: #FFFFFF;
    padding: 8px 12px;
    border-radius: 8px;
    margin-bottom: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    display: inline-block;
    letter-spacing: 0.5px;
    border: 2px solid #fc8f7c;
    animation: pulse 2s infinite;
  }

  @keyframes pulse {
    0% { transform: scale(1); }
    50% { transform: scale(1.05); }
    100% { transform: scale(1); }
  }

  .time-remaining-circle {
    position: absolute;
    top: -30%;
    right: 45%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #FFFFFF;
    color: #FF4500;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.8);
    animation: pulse 2s infinite;
    z-index: 1;
  }

  .time-remaining-number {
    font-size: 12px;
  }

  .time-remaining-text {
    font-size: 8px;
    padding-left: 8px;
  }
  .show-more-button {
    background-color: #FFFFFF;
  }
  .description-card {
    background-color: ${Color.colorWhite};
    border-radius: 8px;
    margin-bottom: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .description-header {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 15px;
  }

  .description-container {
    margin-top: 10px;
  }

  .description-content p {
    font-size: 12px;
    line-height: 1.6;
    color: ${Color.colorDarkBlue}; 
  }
  .description-section {
    margin-bottom: 20px;
  }
  .section-title {
    font-size: 12px;
    font-weight: bold;
    color: ${Color.colorDarkslategray};
    margin-bottom: 10px;
  }

  .section-points {
    font-size: 12px;
    list-style-type: none;
    padding-left: 20px;
    color: ${Color.colorRoyalBlueText};
    margin: 0;
  }

  .section-points li {
    position: relative;
    padding-left: 15px;
    margin-bottom: 8px;
    font-size: 12px;
  }

  .section-points li:before {
    content: "•";
    position: absolute;
    left: 0;
    color: ${Color.colorMintGreen};
  }

  .description-paragraph {
    margin-bottom: 15px;
    text-align: justify;
    font-size: 12px;
  }

  .show-more-button,
  .show-less-button {
    background: none;
    border: none;
    color: ${Color.colorMintGreen};
    font-size: 12px;
    font-weight: bold;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 8px 12px;
    margin: 10px auto;
    border-radius: 20px;
    transition: background-color 0.3s ease;
  }

  .show-more-button:hover,
  .show-less-button:hover {
    background-color: ${Color.colorLightestBeige};
  }
  .users-needed-text {
    margin-top: 10px;
    font-size: 12px;
    color: #d9ae67;
    font-weight: bold; 
  }

  .minimum-reached-text {
    margin-top: 10px;
    font-size: 12px;
    color: ${Color.colorDarkBlue};
    font-weight: bold;
  }
  .legend {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: -2px;
  }
  
  .legend-item {
    display: flex;
    align-items: center;
    margin-right: 20px;
    margin-bottom: 10px;
    font-size: 10px;
    color: ${Color.colorDarkBlue};
  }
  
  .legend-color {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-right: 5px;
  }
  
  .legend-color.remaining {
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="4" height="4">
                <path d="M-1,1 l2,-2 M0,4 l4,-4 M3,5 l2,-2" style="stroke:%23ccc;stroke-width:1"/></svg>');
  }

  .spinner-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }

  .spinner {
    border: 4px solid #f3f3f3;
    border-top: 4px solid ${Color.colorPurple};
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  .button.is-primary.is-loading {
    color: transparent !important;
    pointer-events: none;
  }

  .button.is-primary.is-loading::after {
    border-color: transparent transparent #fff #fff !important;
  }
  .favorite-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.2s ease;
    margin-left: 150px;
  }
  
  .favorite-button:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }
  
  .favorite-icon {
    font-size: 24px;
    transition: all 0.3s ease;
  }
  
  .favorite-icon.processing {
    animation: pulse 1s infinite;
  }
  .card.price-ranges-card {
    background-color: ${Color.colorWhite};
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;
  }

  /* Add subtle animation when scrolling */
  .right-column-sticky:not(.at-top) .card.price-ranges-card {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  }

  /* Ensure content doesn't overflow */
  .right-column-sticky {
    max-height: calc(100vh - 40px);
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: ${Color.colorLightBeigeStrong} transparent;
  }

  .right-column-sticky::-webkit-scrollbar {
    width: 6px;
  }

  .right-column-sticky::-webkit-scrollbar-track {
    background: transparent;
  }

  .right-column-sticky::-webkit-scrollbar-thumb {
    background-color: ${Color.colorPurple};
    border-radius: 3px;
  }
`;


// Add styles to the document
const styleSheet = document.createElement("style");
styleSheet.type = "text/css";
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);

export default GroupWishProgressScreen; 