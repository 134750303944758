import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faImage, 
  faPencilAlt, 
  faFilter, 
  faChevronDown, 
  faChevronUp, 
  faSignOutAlt, 
  faUsers, 
  faSpinner,
  faShare,
  faInfoCircle,
  faTag
} from '@fortawesome/free-solid-svg-icons';
import Config from "../../../config";
import { useAuth } from '../../Common/InitialGears/AuthContext'; 
import { Color } from "../../../GlobalStyles"; 
import TopMenuBar from '../DashbordComponents/TopMenuBar'; 
import { getApi, postApi, buildUrl } from "../../Common/BackendCalls/apiUtils";

const JoinedCompletedDeals = ({ dealType  }) => { 
    const navigate = useNavigate();
    const location = useLocation();
    const { queryLoginParam } = useAuth();
    const [allDeals, setAllDeals] = useState([]);
    const [filteredDeals, setFilteredDeals] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [searchQuery, setSearchQuery] = useState('');
    const [showFilters, setShowFilters] = useState(false);
    const [filterCategory, setFilterCategory] = useState('');
    const [filterPrice, setFilterPrice] = useState('');
    const [showPreferences, setShowPreferences] = useState({});
    const [selectedDealToWithdraw, setSelectedDealToWithdraw] = useState(null);
    const [isWithdrawDialogOpen, setIsWithdrawDialogOpen] = useState(false);
    const [activeTab, setActiveTab] = useState(0); 
    const isCompleted = dealType === "completed";
    
    // Get the page title based on deal type
    const getPageTitle = () => {
      return isCompleted ? "Completed Deals" : "My Deals";
    };

    const fetchAllDeals = useCallback(async () => {
        try {
          setIsLoading(true); 
          const groupWishesUrl = buildUrl('myGroupWishes', `${queryLoginParam}&type=${dealType}`);
          const groupWishesResponse = await getApi(groupWishesUrl); 
          const combosUrl = buildUrl('myCombos', `${queryLoginParam}&type=${dealType}`);
          const combosResponse = await getApi(combosUrl); 
          let combinedDeals = [];
          
          if (groupWishesResponse.ok && Array.isArray(groupWishesResponse.data)) {
            const formattedGroupWishes = groupWishesResponse.data.map(item => ({
              ...item,
              dealType: 'groupWish',
              displayTitle: item.wish?.product_name || 'Unnamed Group Wish',
              imageUrl: item.wish?.image_url || null,
              price: item.wish?.list_price || 0,
              progress: item.progress || 0,
              totalJoined: item.totalJoined || 0,
              minRequired: item.wish?.group_size_min || 0,
              preferences: item.wish?.user_preferences || [],
              id: item.wish?.id,
              uuid: item.uuid || item.wish?.id,
              status: item.group_wish_buddy_status,
              priceRanges: item.wish?.price_ranges || [],
              is_favorite: item.is_favorite || false
            }));
            combinedDeals = [...combinedDeals, ...formattedGroupWishes];
          }
          
          if (combosResponse.ok && Array.isArray(combosResponse.data)) {
            const formattedCombos = combosResponse.data.map(item => { 
              const originalCombo = item.combo || {};
              
              return {
                ...item,
                dealType: 'combo',
                displayTitle: originalCombo.title || 'Unnamed Bundle',
                imageUrl: originalCombo.combo_items?.[0]?.group_wish?.image_url || null,
                price: originalCombo.bundle_rate || 0,
                originalPrice: originalCombo.bundle_mrp || 0,
                progress: (originalCombo.progress_stats?.progress || 0) / 100,
                totalJoined: originalCombo.progress_stats?.totalJoined || 0,
                minRequired: originalCombo.min_combo_size || 0,
                preferences: originalCombo.progress_stats?.usersChoices || [],
                id: originalCombo.id,
                uuid: originalCombo.uuid || `combo-${originalCombo.id}`,
                status: originalCombo.progress_stats?.group_wish_buddy_status,
                comboItems: originalCombo.combo_items || [], 
                combo: originalCombo,
                is_favorite: originalCombo.progress_stats?.is_favorite || false
              };
            });
            combinedDeals = [...combinedDeals, ...formattedCombos];
          } 
          combinedDeals.sort((a, b) => b.progress - a.progress);          
          setAllDeals(combinedDeals);
          setFilteredDeals(combinedDeals);
        } catch (error) {
          console.error(`Error fetching ${dealType} deals:`, error);
        } finally {
          setIsLoading(false);
        }
      }, [queryLoginParam, dealType]);
    
      useEffect(() => {
        fetchAllDeals();
      }, [fetchAllDeals]);
     
      const filterDeals = useCallback(() => {
        if (!Array.isArray(allDeals) || allDeals.length === 0) {
          setFilteredDeals([]);
          return;
        }
    
        const normalize = (str) => {
          if (!str) return '';
          return str.toString().toLowerCase().replace(/[^a-z0-9]/g, '');
        };
        
        const partialMatch = (full, partial) => {
          if (!full || !partial) return false;
          return normalize(full).includes(normalize(partial));
        };
    
        const filtered = allDeals.filter(deal => { 
          const matchesQuery = !searchQuery ||  partialMatch(deal.displayTitle, searchQuery); 
          const matchesCategory = !filterCategory || (deal.dealType === 'groupWish' && deal.wish?.category &&  deal.wish.category.some(cat => partialMatch(cat, filterCategory))) || (deal.dealType === 'combo' && partialMatch(deal.displayTitle, filterCategory));           
          const matchesPrice = !filterPrice ||  (deal.price && deal.price <= parseFloat(filterPrice));
           
          const matchesTab = activeTab === 0 || 
            (activeTab === 1 && deal.dealType === 'groupWish') || 
            (activeTab === 2 && deal.dealType === 'combo');
          
          return matchesQuery && matchesCategory && matchesPrice && matchesTab;
        });
    
        setFilteredDeals(filtered);
      }, [allDeals, searchQuery, filterCategory, filterPrice, activeTab]);
    
      useEffect(() => {
        filterDeals();
      }, [filterDeals, searchQuery, filterCategory, filterPrice, activeTab]);
           
      const togglePreferences = (uuid) => {
        setShowPreferences(prev => ({ ...prev, [uuid]: !prev[uuid] }));
      };
    
      const handleWithdraw = (deal) => {
        setSelectedDealToWithdraw(deal);
        setIsWithdrawDialogOpen(true);
      };
    
      const confirmWithdraw = async () => {
        if (!selectedDealToWithdraw) return;
        setIsLoading(true);
        setIsWithdrawDialogOpen(false);
        try {
          if (selectedDealToWithdraw.dealType === 'groupWish') {
            const withdrawUrl = `${Config.apiUrl}withdrawGroupWish?${queryLoginParam}`;
            await postApi(withdrawUrl, { wish_id: selectedDealToWithdraw.id });
          } else {
            const withdrawUrl = `${Config.apiUrl}withdrawCombo?${queryLoginParam}`;
            await postApi(withdrawUrl, { combo_id: selectedDealToWithdraw.id });
          }
          
          // Refresh data after withdrawal
          await fetchAllDeals();
        } catch (error) {
          console.error("Error withdrawing from deal:", error);
        } finally {
          setIsWithdrawDialogOpen(false);
          setSelectedDealToWithdraw(null);
          setIsLoading(false);
        }
      };
    
      const handleEditPreferences = (deal) => {
        if (deal.dealType === 'groupWish') {
          navigate('/preferenceList', {
            state: {
              formData: deal.wish,
              existingPreferences: "edit",
              groupWishName: deal.displayTitle,
              grp_wish_id: deal.id,
              dealType: dealType // Pass the deal type
            }
          });
        } else {
          navigate('/comboPreferences', {
            state: {
              combo: deal.combo,
              existingPreferences: "edit",
              dealType: dealType // Pass the deal type
            }
          });
        }
      };
    
      const handleViewProgress = (deal) => {
        if (deal.dealType === 'groupWish') {
          navigate('/groupWishProgress', { 
            state: { 
              wish: deal.wish, 
              progressData: deal,
              dealType: dealType // Pass the deal type
            } 
          });
        } else {
          // For combo deals, navigate to comboDetails instead of comboProgress
          // and format the state properly as expected by ComboDetails
          navigate('/comboProgress', { 
            state: { 
              comboData: deal.combo || {
                ...deal,
                progress_stats: {
                  ...(deal.combo?.progress_stats || {}),
                  is_favorite: deal.is_favorite || false,
                  verifiedUsers: deal.verifiedUsers || 0,
                  unverifiedUsers: deal.unverifiedUsers || 0,
                  totalJoined: deal.totalJoined || 0,
                  progress: typeof deal.progress === 'number' ? deal.progress * 100 : 0,
                  usersChoices: deal.preferences || [],
                  user_already_joined: deal.user_already_joined || true,
                  group_wish_buddy_status: deal.status || "JOINED"
                }
              },
              dealType: dealType // Pass the deal type
            } 
          });
        }
      };
    
      const handleReferAFriend = (deal) => {
        if (deal.dealType === 'groupWish') {
          navigate("/invites", { 
            state: { 
              grp_wish: deal,
              dealType: dealType // Pass the deal type
            } 
          });
        } else {
          // For combo deals, format the state as expected by the invites page
          navigate("/invites", { 
            state: { 
              comboData: deal.combo || {
                ...deal,
                progress_stats: {
                  ...(deal.combo?.progress_stats || {}),
                  is_favorite: deal.is_favorite || false,
                  verifiedUsers: deal.verifiedUsers || 0,
                  unverifiedUsers: deal.unverifiedUsers || 0,
                  totalJoined: deal.totalJoined || 0,
                  progress: typeof deal.progress === 'number' ? deal.progress * 100 : 0,
                  usersChoices: deal.preferences || [],
                  user_already_joined: deal.user_already_joined || true,
                  group_wish_buddy_status: deal.status || "JOINED"
                }
              },
              dealType: dealType // Pass the deal type
            } 
          });
        }
      };
    
      const getPriceDisplay = (deal) => {
        // Ensure price is a number
        const ensureNumber = (value) => {
          if (typeof value === 'string') {
            return parseFloat(value);
          }
          return typeof value === 'number' ? value : 0;
        };
        
        if (deal.dealType === 'combo') {
          const priceNum = ensureNumber(deal.price);
          const originalPriceNum = ensureNumber(deal.originalPrice);
          
          return {
            price: `${priceNum.toFixed(2)}`,
            originalPrice: deal.originalPrice ? `${originalPriceNum.toFixed(2)}` : null,
            discount: deal.originalPrice ? Math.round((1 - priceNum / originalPriceNum) * 100) : null
          };
        } else {
          // Logic for group wish price ranges
          const fallbackPrice = `${ensureNumber(deal.price).toFixed(2)}`;
          
          if (!Array.isArray(deal.priceRanges) || deal.priceRanges.length === 0) {
            return { price: fallbackPrice };
          }
      
          try { 
            const costs = deal.priceRanges
              .map(range => typeof range.product_cost === 'string' ? 
                   parseFloat(range.product_cost) : range.product_cost)
              .filter(cost => !isNaN(cost));
        
            if (costs.length === 0) {
              return { price: fallbackPrice };
            }
        
            const minPrice = Math.min(...costs);
            const maxPrice = Math.max(...costs); 
            return { 
              price: minPrice === maxPrice 
                ? `${minPrice.toFixed(2)}` 
                : `${minPrice.toFixed(2)} - ${maxPrice.toFixed(2)}`,
              originalPrice: fallbackPrice
            };
          } catch (error) { 
            return { price: fallbackPrice };
          }
        }
      };
    
      // UI Components
      const LoadingSpinner = () => (
        <div style={styles.loadingContainer}>
          <FontAwesomeIcon 
            icon={faSpinner} 
            spin 
            style={{ fontSize: '2rem', color: Color.colorDarkGrey }} 
          />
        </div>
      );
    
      const NoDealsMessage = () => (
        <div style={styles.noDealsContainer}>
          <FontAwesomeIcon 
            icon={faUsers} 
            style={{ fontSize: '3rem', color: Color.colorDarkGrey, marginBottom: '16px' }} 
          />
          <div style={styles.noDealsTitle}>
            No {isCompleted ? 'completed' : ''} deals found
          </div>
          <div style={styles.noDealsSubtitle}>
            {isCompleted 
              ? "Your completed deals will appear here" 
              : "Join some deals to see them here"}
          </div>
        </div>
      );
    
      const DealCard = ({ deal }) => {
        const priceInfo = getPriceDisplay(deal);
        const hideWithdrawAndReferStatuses = [
          "INVOICE_READY",
          "PAYMENT_INITIATED",
          "PAYMENT_RECEIVED",
          "PAYMENT_FAILED",
          "WAITING_FOR_SHIPMENT",
          "COMPLAINED"
        ];
        const completedOrMinMetStatuses = [
          "PAYMENT_INITATED", 
          "PAYMENT_PROCESSED",
          "PARTIAL_PAYMENTS_COMPLETED",
          "ALL_PAYMENTS_COMPLETED",
          "ORDER_SHIPPED_FROM_MANUFACTURER",
          "GOODS_AT_PORT",
          "GOODS_CUSTOM_CLEARED",
          "INLAND_FRIEGHT",
          "REPACKING",
          "SHIPPED_TO_DELIVERY",
          "DELIVERING"
        ];
        const shouldHideButtons = () => {
          const status = deal.status;
          return hideWithdrawAndReferStatuses.includes(status) || 
                 completedOrMinMetStatuses.includes(status);
        };
        return (
          <div style={styles.dealCard}>
            {!isCompleted && deal.status === "WAITLISTED" && (
              <div style={styles.statusTag}>WAITLISTED</div>
            )}
            
            {deal.dealType === 'combo' && (
              <div style={styles.comboTag}>
                <FontAwesomeIcon icon={faTag} /> BUNDLE
              </div>
            )}
            
            <div style={styles.dealImageContainer}>
              {deal.imageUrl ? (
                <img src={deal.imageUrl} alt={deal.displayTitle} style={styles.dealImage} />
              ) : (
                <div style={styles.placeholderImage}>
                  <FontAwesomeIcon icon={faImage} size="2x" color="#ccc" />
                </div>
              )}
            </div>
            
            <div style={styles.dealDetails}>
              <div style={styles.dealTitle}>{deal.displayTitle}</div>
              
              <div style={styles.priceContainer}>
                <span style={styles.currentPrice}>{priceInfo.price}</span>
                {priceInfo.originalPrice && (
                  <span style={styles.originalPrice}>{priceInfo.originalPrice}</span>
                )}
                {priceInfo.discount && (
                  <span style={styles.discountTag}>{priceInfo.discount}% OFF</span>
                )}
              </div>
              
              {!isCompleted && (
                <div style={styles.progressInfo}>
                  <div style={styles.progressBar}>
                    <div 
                      style={{
                        ...styles.progressFill,
                        width: `${Math.min(100, deal.progress * 100)}%`
                      }}
                    />
                  </div>
                  <div style={styles.progressText}>
                    {deal.totalJoined}/{deal.minRequired} joined • {(deal.progress * 100).toFixed(0)}% complete
                  </div>
                </div>
              )}
              
              {deal.dealType === 'combo' && deal.comboItems && deal.comboItems.length > 0 && (
                <div style={styles.bundleInfo}>
                  <div style={styles.bundleHeader}>
                    <FontAwesomeIcon icon={faInfoCircle} style={{ marginRight: '5px' }} />
                    Bundle includes {deal.comboItems.length} items
                  </div>
                  <div style={styles.bundleItems}>
                    {deal.comboItems.slice(0, 2).map((item, index) => (
                      <div key={index} style={styles.bundleItem}>
                        • {item.group_wish?.product_name || 'Item ' + (index + 1)}
                      </div>
                    ))}
                    {deal.comboItems.length > 2 && (
                      <div style={styles.bundleItem}>• And {deal.comboItems.length - 2} more...</div>
                    )}
                  </div>
                </div>
              )}
              
              {deal.preferences && deal.preferences.length > 0 && (
                <div style={styles.preferencesContainer}>
                  <div 
                    style={styles.preferencesHeader} 
                    onClick={() => togglePreferences(deal.uuid)}
                  >
                    <span style={styles.preferencesTitle}>My Preferences</span>
                    <FontAwesomeIcon 
                      icon={showPreferences[deal.uuid] ? faChevronUp : faChevronDown} 
                    />
                  </div>
                  
                  {showPreferences[deal.uuid] && (
                    <div style={styles.preferencesList}>
                      {deal.preferences.map((pref, index) => (
                        <div key={index} style={styles.preferenceItem}>
                          • {deal.dealType === 'combo' 
                              ? `${pref.preference_name?.name || 'Option'}: ${pref.preference_value_text}` 
                              : `${pref.preference_name}: ${pref.preferenceValue}`}
                          {pref.comments && ` (${pref.comments})`}
                        </div>
                      ))}
                      {!isCompleted && (
                        <button 
                          onClick={() => handleEditPreferences(deal)} 
                          style={{...styles.button, marginTop: '8px'}}
                        >
                          <FontAwesomeIcon icon={faPencilAlt} style={{ marginRight: '4px' }} /> 
                          Edit Preferences
                        </button>
                      )}
                    </div>
                  )}
                </div>
              )}
              
              {/* Only show action buttons for non-completed deals */}
              {!isCompleted && (
                <>
                  <div style={styles.buttonContainer}>
                    <button  onClick={() => handleViewProgress(deal)}  style={styles.button} >
                      View Progress
                    </button>
                    {!shouldHideButtons() && (
                      <button onClick={() => handleReferAFriend(deal)} style={styles.button}> 
                        <FontAwesomeIcon icon={faShare} style={{ marginRight: '4px' }} />
                        Refer Friend
                      </button>
                    )}
                  </div>
                  {!shouldHideButtons() && (
                    <button  onClick={() => handleWithdraw(deal)}  style={styles.withdrawButton} >
                      <FontAwesomeIcon icon={faSignOutAlt} style={{ marginRight: '4px' }} /> 
                      Withdraw
                    </button>
                  )}
                </>
              )}
            </div>
          </div>
        );
      };
     
      const tabOptions = [
        { id: 0, label: `All ${isCompleted ? 'Completed' : ''} Deals` },
        { id: 1, label: `${isCompleted ? 'Completed' : ''} Group Wishes` },
        { id: 2, label: `${isCompleted ? 'Completed' : ''} Bundles` }
      ];
    
      const renderTabContent = () => {
        if (isLoading) {
          return <LoadingSpinner />;
        }
    
        if (filteredDeals.length === 0) {
          return <NoDealsMessage />;
        }
    
        return (
          <div style={styles.dealsList}>
            {filteredDeals.map((deal) => (
              <DealCard key={deal.uuid} deal={deal} />
            ))}
          </div>
        );
      };
    
      return (
        <>
          <TopMenuBar 
            showBackButton={false} 
            showHomeButton={true} 
            showMenuButton={true} 
            onBackClick={() => navigate(-1)} 
            title={getPageTitle()} 
            showSearch={false} 
            showAddress={false} 
          /> 
          
          <div style={styles.pageContainer}>       
            <div style={styles.mainContent}>
              {/* Custom Tab Navigation */}
              <div style={styles.tabContainer}>
                {tabOptions.map(tab => (
                  <button
                    key={tab.id}
                    style={activeTab === tab.id ? styles.activeTab : styles.tab}
                    onClick={() => setActiveTab(tab.id)}
                  >
                    {tab.label}
                  </button>
                ))}
              </div>
              
              <div style={styles.searchAndFilterContainer}>
                <input
                  style={styles.input}
                  placeholder={`Search ${isCompleted ? 'completed' : ''} deals...`}
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
                
                <button 
                  style={styles.filterToggle} 
                  onClick={() => setShowFilters(!showFilters)}
                >
                  <FontAwesomeIcon icon={faFilter} style={{ marginRight: '8px' }} />
                  {showFilters ? 'Hide Filters' : 'Show Filters'}
                </button>
                
                {showFilters && (
                  <div style={styles.filterContainer}>
                    <input 
                      style={styles.filterInput} 
                      placeholder="Filter by category" 
                      value={filterCategory} 
                      onChange={(e) => setFilterCategory(e.target.value)} 
                    />
                    <input
                      style={styles.filterInput}
                      placeholder="Max price"
                      type="number"
                      value={filterPrice}
                      onChange={(e) => setFilterPrice(e.target.value)}
                    />
                  </div>
                )}
              </div>
    
              {/* Tab Content */}
              <div style={styles.tabContent}>
                {renderTabContent()}
              </div>
            </div>
            
            {isWithdrawDialogOpen && (
              <div style={styles.confirmationDialogOverlay}>
                <div style={styles.confirmationDialog}>
                  <div style={styles.confirmationTitle}>Confirm Withdrawal</div>
                  <div style={styles.confirmationDescription}>
                    Are you sure you want to withdraw? If you wish to join again, you may be waitlisted.
                  </div>
                  <div style={styles.confirmationButtons}>
                    <button 
                      onClick={() => setIsWithdrawDialogOpen(false)} 
                      style={styles.cancelButton}
                    >
                      Cancel
                    </button>
                    <button 
                      onClick={confirmWithdraw} 
                      style={styles.confirmButton}
                    >
                      Withdraw
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      );
    };
    
const styles = {
  pageContainer: {
    backgroundColor: Color.colorLightestBeige,
    minHeight: '100vh',
    width: '100%',
    paddingTop: '20px',
  },
  mainContent: {
    maxWidth: '1600px',
    margin: '0 auto',
    padding: '0 16px 24px',
  },
  tabContainer: {
    display: 'flex',
    borderBottom: `1px solid ${Color.colorBlueGrey}`,
    marginBottom: '20px',
  },
  tab: {
    flex: 1,
    padding: '12px',
    textAlign: 'center',
    cursor: 'pointer',
    color: Color.colorDarkGrey,
    fontWeight: '500',
    background: 'none',
    border: 'none',
    borderBottom: '3px solid transparent',
  },
  activeTab: {
    flex: 1,
    padding: '12px',
    textAlign: 'center',
    cursor: 'pointer',
    color: Color.colorDarkBlue,
    fontWeight: 'bold',
    background: 'none',
    border: 'none',
    borderBottom: `3px solid ${Color.colorMintGreen}`,
  },
  tabContent: {
    padding: '10px 0',
  },
  searchAndFilterContainer: {
    marginBottom: '24px',
  },
  input: {
    width: '100%',
    padding: '12px',
    fontSize: '16px',
    backgroundColor: 'white',
    border: `1px solid ${Color.colorBlueGrey}`,
    borderRadius: '8px',
    marginBottom: '12px',
  },
  filterToggle: {
    background: 'none',
    border: 'none',
    color: Color.colorDarkBlue,
    fontSize: '16px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    marginBottom: '12px',
  },
  filterContainer: {
    display: 'flex',
    gap: '12px',
    marginBottom: '12px',
  },
  filterInput: {
    flex: 1,
    padding: '12px',
    fontSize: '14px',
    backgroundColor: 'white',
    border: `1px solid ${Color.colorBlueGrey}`,
    borderRadius: '8px',
  },
  dealsList: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(320px, 1fr))',
    gap: '20px',
    width: '100%',
  },
  dealCard: {
    backgroundColor: 'white',
    borderRadius: '12px',
    boxShadow: '0px 2px 8px rgba(0,0,0,0.08)',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    transition: 'transform 0.2s ease, box-shadow 0.2s ease',
  },
  statusTag: {
    position: 'absolute',
    top: '12px',
    right: '12px',
    backgroundColor: Color.colorVibrantRed,
    color: 'white',
    padding: '4px 10px',
    borderRadius: '4px',
    fontSize: '12px',
    fontWeight: 'bold',
    zIndex: 1,
  },
  comboTag: {
    position: 'absolute',
    top: '12px',
    left: '12px',
    backgroundColor: Color.colorMintGreen,
    color: 'white',
    padding: '4px 10px',
    borderRadius: '4px',
    fontSize: '12px',
    fontWeight: 'bold',
    zIndex: 1,
  },
  dealImageContainer: {
    height: '200px',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Color.colorBackground,
  },
  dealImage: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
  placeholderImage: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Color.colorLightestGrey,
  },
  dealDetails: {
    padding: '16px',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  dealTitle: {
    fontSize: '18px',
    fontWeight: 'bold',
    color: Color.colorDarkBlue,
    marginBottom: '8px',
    lineHeight: 1.3,
  },
  priceContainer: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: '8px',
    marginBottom: '12px',
  },
  currentPrice: {
    fontSize: '20px',
    fontWeight: 'bold',
    color: Color.colorVibrantRed,
  },
  originalPrice: {
    fontSize: '16px',
    color: Color.colorDarkGrey,
    textDecoration: 'line-through',
  },
  discountTag: {
    backgroundColor: Color.colorVibrantRed,
    color: 'white',
    padding: '2px 6px',
    borderRadius: '4px',
    fontSize: '12px',
    fontWeight: 'bold',
  },
  progressInfo: {
    marginBottom: '12px',
  },
  progressBar: {
    height: '8px',
    backgroundColor: Color.colorLightestGrey,
    borderRadius: '4px',
    overflow: 'hidden',
    marginBottom: '4px',
  },
  progressFill: {
    height: '100%',
    backgroundColor: Color.colorMintGreen,
    borderRadius: '4px',
  },
  progressText: {
    fontSize: '14px',
    color: Color.colorDarkGrey,
  },
  bundleInfo: {
    backgroundColor: Color.colorLightestBeige,
    padding: '10px',
    borderRadius: '8px',
    marginBottom: '12px',
  },
  bundleHeader: {
    fontWeight: 'bold',
    color: Color.colorDarkBlue,
    marginBottom: '6px',
    fontSize: '14px',
  },
  bundleItems: {
    fontSize: '13px',
    color: Color.colorDarkGrey,
  },
  bundleItem: {
    marginBottom: '4px',
  },
  preferencesContainer: {
    backgroundColor: Color.colorLightestGrey,
    padding: '12px',
    borderRadius: '8px',
    marginTop: '12px',
    marginBottom: '12px',
  },
  preferencesHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
  },
  preferencesTitle: {
    fontWeight: 'bold',
    color: Color.colorDarkBlue,
    fontSize: '14px',
  },
  preferencesList: {
    marginTop: '8px',
  },
  preferenceItem: {
    fontSize: '13px',
    color: Color.colorDarkGrey,
    marginBottom: '4px',
  },
  buttonContainer: {
    display: 'flex',
    gap: '8px',
    marginTop: 'auto',
    marginBottom: '8px',
  },
  button: {
    flex: 1,
    padding: '10px',
    backgroundColor: Color.colorMintGreen,
    color: 'white',
    border: 'none',
    borderRadius: '6px',
    cursor: 'pointer',
    fontSize: '14px',
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  withdrawButton: {
    backgroundColor: 'transparent',
    color: Color.colorVibrantRed,
    border: `1px solid ${Color.colorVibrantRed}`,
    borderRadius: '6px',
    padding: '10px',
    cursor: 'pointer',
    fontSize: '14px',
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '8px',
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '200px',
    width: '100%',
  },
  noDealsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '40px',
    backgroundColor: Color.colorFillBeige,
    borderRadius: '8px',
    margin: '20px 0',
  },
  noDealsTitle: {
    fontSize: '18px',
    color: Color.colorDarkGrey,
    textAlign: 'center',
    marginBottom: '8px',
  },
  noDealsSubtitle: {
    fontSize: '14px',
    color: Color.colorGray,
    textAlign: 'center',
  },
  confirmationDialogOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000
  },
  confirmationDialog: {
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    zIndex: 1001,
    maxWidth: '90%',
    width: '400px',
  },
  confirmationTitle: {
    fontSize: '18px',
    fontWeight: 'bold',
    marginBottom: '10px',
  },
  confirmationDescription: {
    marginBottom: '20px',
  },
  confirmationButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '10px',
  },
  cancelButton: {
    backgroundColor: Color.colorMintGreen,
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    padding: '8px 16px',
    cursor: 'pointer',
    fontWeight: 'bold',
  },
  confirmButton: {
    backgroundColor: Color.colorVibrantRed,
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    padding: '8px 16px',
    cursor: 'pointer',
    fontWeight: 'bold',
  }
}
export default JoinedCompletedDeals;