// firebaseConfig.js
import { initializeApp } from "firebase/app"; 
import { getAuth, browserLocalPersistence , setPersistence} from "firebase/auth";
import { GoogleAuthProvider, FacebookAuthProvider, TwitterAuthProvider, GithubAuthProvider } from "firebase/auth";

const firebaseConfigDev = {
    apiKey: "AIzaSyB3gc3akEfOHogBu4I95w1S-WLAx6ntXfo",
    authDomain: "dev-backsapp.firebaseapp.com",
    projectId: "dev-backsapp",
    storageBucket: "dev-backsapp.firebasestorage.app",
    messagingSenderId: "712607729226",
    appId: "1:712607729226:web:e0c0e11db31b6a578f48b3",
    measurementId: "G-YF52MZV3B1"
};


const firebaseConfigProd = {    
  apiKey: "AIzaSyCrIMT2F4rj54NrRj55JPdRkQ_6IILWLbo",
  authDomain: "backsapp.firebaseapp.com",
  projectId: "backsapp",
  storageBucket: "backsapp.firebasestorage.app",
  messagingSenderId: "714775103411",
  appId: "1:714775103411:web:f74b0c0d3a2c4b30b96dc6"
};

const firebaseConfigQa = {
    apiKey: "AIzaSyDVcHZok6DYzSEqvrTfHY15fbAzj8TlVpY",
    authDomain: "qa-backsapp.firebaseapp.com",
    projectId: "qa-backsapp",
    storageBucket: "qa-backsapp.appspot.com",
    messagingSenderId: "941302270590",
    appId: "1:941302270590:web:754bfda0c35c4c612ce62f",
    measurementId: "G-01EPNXZ32K",
    databaseURL: "https://backsapp-5ccd0-default-rtdb.firebaseio.com/",
};

const env = process.env.REACT_APP_ENV || 'qa';
const config = {
    dev: firebaseConfigDev,
    qa: firebaseConfigQa,
    production: firebaseConfigProd
};
const firebaseConfig = config[env] || firebaseConfigDev; 
  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  
  // Get Auth instance using the standard method
  const auth = getAuth(app);
  
  // Set persistence if needed
  setPersistence(auth, browserLocalPersistence)
    .catch((error) => {
      console.error("Error setting persistence:", error);
    });
    export const getFirebaseAuth = () => {
        if (!auth) {
            throw new Error('Firebase Auth not initialized. Call ensureFirebaseInit first.');
        }
        return auth;
    };
  // Define providers
  const authProviders = {
      google: () => {
        const provider = new GoogleAuthProvider();
        provider.addScope('email');
        return provider;
      },
      facebook: () => {
        const provider = new FacebookAuthProvider();
        provider.addScope('email');
        provider.addScope('public_profile');
        provider.setCustomParameters({
          'display': 'popup'
        });
        return provider;
      },
      twitter: () => {
        return new TwitterAuthProvider();
      },
      github: () => {
        const provider = new GithubAuthProvider();
        provider.addScope('user');
        provider.addScope('email');
        return provider;
      }
  };
  
  export { auth, authProviders };