import React, { useState, useEffect, useCallback , useRef} from 'react';
import { getApi, postApi, buildUrl } from "../../Common/BackendCalls/apiUtils";
import { useNavigate, useLocation } from 'react-router-dom'; 
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useAuth } from '../../Common/InitialGears/AuthContext'; 
import {Color, RoseGoldButton} from "../../../GlobalStyles";   
import { faUpload,faTrash ,faPlus} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; 
import {TopMenuBar} from '../../Buyers/DashbordComponents/TopMenuBar';
import DefinePreferences from './DefinePreferences';
import ApprovalModal from '../../BacksAppManager/ApprovalModalComponent';
import MediaUploadModal from './MediaUploadModal';
import AdditionalMediaDisplay from './AdditionalMedia'; 
import ProductSpecifications from './ProductSpecifications';
import FacebookPagePoster from '../../Influencers/FaceBookPosts';

const styles = `
  .multiselect {
    background-color: white !important;
    -webkit-appearance: listbox !important;
    -moz-appearance: listbox !important;
  }
  .multiselect option {
    padding: 8px;
  }
  .multiselect option:checked,
  .multiselect option:selected {
    background: #e3f2fd !important;
    background-color: #e3f2fd !important;
    color: black !important;
    font-weight: bold;
  }
  .multiselect option:hover {
    background-color: #f5f5f5;
  }
  @-moz-document url-prefix() {
    .multiselect option:checked {
      background: #e3f2fd !important;
      box-shadow: 0 0 10px 100px #e3f2fd inset !important;
    }
  }
`;

const LabeledInput = ({ label, value, onChange, placeholder, type, multiline, style: propStyle, error }) => (
  <div style={labeledInputContainerStyle}>
    <label style={inputLabelStyle}>{label}</label>
    {multiline ? (
      <textarea value={value} onChange={(e) => onChange(e.target.value)} placeholder={placeholder} style={{...baseInputStyle, ...textareaStyle, ...(error ? inputErrorStyle : {}), ...propStyle}}/>
    ) : (
      <input type={type} value={value} onChange={(e) => onChange(e.target.value)} placeholder={placeholder} style={{...baseInputStyle, ...(error ? inputErrorStyle : {}), ...propStyle}}/>
    )}
    {error && <span style={errorTextStyle}>{error}</span>}
  </div>
);  
const CreateGroupWish = () => { 
  const { queryLoginParam, idTokenLoggedIn ,isBacksAppManager } = useAuth();  
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate(); 
  let draftWish = location.state?.draftWish;   
  const createNew = location.state?.createNew || false;
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [showPreferencesModal, setShowPreferencesModal] = useState(false); 
  const [existingPreferences, setExistingPreferences] = useState([]);
  const [createdGroupId, setCreatedGroupId] = useState(null);
  const [categories, setCategories] = useState([]);
  const [allSubCategories, setAllSubCategories] = useState([]);
  const [filteredSubCategories, setFilteredSubCategories] = useState([]);
  const [showApprovalModal, setShowApprovalModal] = useState(false); 
  const [userVerified, setUserVerified] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedSubCategories, setSelectedSubCategories] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const fileInputRef = useRef(null);
  const [isFormValid, setIsFormValid] = useState(false);
  const [errors, setErrors] = useState({}); 
  const [priceRangesChanged, setPriceRangesChanged] = useState(false);
  const [imageChanged, setImageChanged] = useState(false);
  const [formRequestBody, setFormRequestBody]= useState('');
  const [formData, setFormData] = useState({
    productName: draftWish?.product_name || '',
    dealTitle: draftWish?.deal_title || '',
    listPrice: draftWish?.list_price?.toString() || '',
    description: draftWish?.description || '',
    singlePrice: draftWish?.list_price?.toString() || '',
    storeSeller: draftWish?.store_seller || '',
    groupSizeMin: draftWish?.group_size_min?.toString() || '',
    marketLink: draftWish?.market_link || '',
    expiresAt: draftWish?.expires_at ? new Date(draftWish.expires_at) : null,
    note: draftWish?.market_notes || '',
    isComboDeal: draftWish?.isComboDeal || false, 
    priceRanges: draftWish?.price_ranges?.filter(range => !range?.actual_price_range)?.map(range => ({
      minQuantity: range?.minQuantity?.toString() || '',
      maxQuantity: range?.maxQuantity?.toString() || '',
      price: range?.rangePrice?.toString() || '',
      shippingCost: range?.shippingCost_for_range?.toString() || '',
      additionalComments: range?.additional_comments || ''
    })) || [{ minQuantity: '', maxQuantity: '', price: '', shippingCost: '', negotiated: false, additionalComments: '' }],
    actualSellerPrices: draftWish?.price_ranges?.filter(range => range?.actual_price_range)?.map(range => ({
      minQuantity: range?.minQuantity?.toString() || '',
      maxQuantity: range?.maxQuantity?.toString() || '',
      price: range?.rangePrice?.toString() || '',
      shippingCost: range?.shippingCost_for_range?.toString() || '',
      negotiated: false,
      additionalComments: range?.additional_comments || ''
    })) || [{ minQuantity: '', maxQuantity: '', price: '', shippingCost: '', negotiated: false, additionalComments: '' }]
  });
  const [taxCode, setTaxCode] = useState(draftWish?.taxjar_tax_code || '00000');
  const [taxCodes, setTaxCodes] = useState([]);
  const [showMediaModal, setShowMediaModal] = useState(false);
  const [additionalImages, setAdditionalImages] = useState([]);
  const [video, setVideo] = useState(null);
  const [removedMediaUuids, setRemovedMediaUuids] = useState([]);
  const [actualSellerPricesChanged, setActualSellerPricesChanged] = useState(false);
  
  const initialFormState = {
    productName: '',
    dealTitle: '',
    description: '',
    singlePrice: '',
    marketLink: '',
    storeSeller: '',
    groupSizeMin: '',
    expiresAt: null,
    note: '',
    priceRanges: [{ minQuantity: '', maxQuantity: '', price: '', shippingCost: '', negotiated: false, additionalComments: '' }]
  };
  
  const resetForm = () => {
    setFormData({
      ...initialFormState,
      priceRanges: [{ minQuantity: '', maxQuantity: '', price: '', shippingCost: '', negotiated: false, additionalComments: '' }],
      actualSellerPrices: [{ minQuantity: '', maxQuantity: '', price: '', shippingCost: '', negotiated: false, additionalComments: '' }]
    });
    setSelectedCategory('');
    setSelectedSubCategories([]);
    setSelectedImage(null);
    setImagePreview(null);
    setErrors({});
    setIsFormValid(false);
    setCreatedGroupId(null);
    setExistingPreferences([]);
    setSuccessMessage(null);
    setErrorMessage(null);
    setAdditionalImages([]);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };
 
  useEffect(() => { 
    if (createNew){ 
      resetForm();
    }
    fetchCategories();
    fetchSubCategories();
    fetchTaxCodes(); 
  }, [location]);

  useEffect(() => {
    if (draftWish && categories.length > 0) {
      if (draftWish.image) {
        setImagePreview(draftWish.image);
      }
      if (draftWish.category && draftWish.category.length > 0) {
        const categoryName = draftWish.category[0];
        const category = categories.find(cat => cat.name === categoryName);
        if (category) {
          setSelectedCategory(category.id.toString());
        }
      }
      if (draftWish.sub_category && draftWish.sub_category.length > 0 && allSubCategories.length > 0) {
        const subcategoryMatches = allSubCategories.filter(sub => 
          draftWish.sub_category.includes(sub.name)
        );
        const subCategoryIds = subcategoryMatches.map(sub => sub.id.toString());
        setSelectedSubCategories(subCategoryIds); 
      }
      setCreatedGroupId(draftWish.id);  
      validateForm();
    }
  }, [draftWish, categories, allSubCategories]);

  useEffect(() => {  
    if (selectedCategory && categories.length > 0 && allSubCategories.length > 0) { 
      const selectedCategoryData = categories.find(cat => 
        cat.id.toString() === selectedCategory || cat.name === selectedCategory
      );
       
      if (selectedCategoryData && selectedCategoryData.sub_category) {
        const filteredSubs = allSubCategories.filter(sub => 
          selectedCategoryData.sub_category.includes(sub.id)
        );
        setFilteredSubCategories(filteredSubs);  
      } else {
        setFilteredSubCategories([]);
      }
    }
  }, [selectedCategory, categories, allSubCategories]);

  useEffect(() => {
    if (draftWish ){
      if (draftWish.preferences_defined) {
      setExistingPreferences(draftWish.preferences_defined);
      }else if (draftWish.preferences_defined === undefined || draftWish.preferences_defined === null) { 
        setExistingPreferences([]); 
      } else { 
        setExistingPreferences([]); 
      }
    } else { 
      setExistingPreferences([]);
    }
  }, [draftWish]);

  useEffect(() => {
    if (draftWish && draftWish.taxjar_tax_code) {
      setTaxCode(draftWish.taxjar_tax_code);
    }
  }, [draftWish]);
  
  const fetchCategories = async () => {
    setIsLoading(true);
    try {
      const categoriesUrl = buildUrl('getCategories', queryLoginParam);
      const response = await getApi(categoriesUrl);
      if (response.ok) {
        setCategories(response.data);
      }  
    } catch (error) { 
    } finally {
      setIsLoading(false);
    }
  };

  const fetchDraftWishes = useCallback(async () => {
    setIsLoading(true);
    try {
      setRefreshing(true);
      const draftWishesUrl = buildUrl('myGroupWishes', `${queryLoginParam}&type=draft`);
      const response = await getApi(draftWishesUrl);
      if (response.ok) {
        const data = response.data;
        setUserVerified(data.is_verified_user);
        // ... (handle draft wishes data)
      } else {
        if (response.status === 423) {
          setErrorMessage('Your session has expired. Please log in again.');
          navigate("/");
        }
      }
    } catch (error) { 
    } finally {
      setRefreshing(false);
      setIsLoading(false);
    }
  }, [queryLoginParam, navigate]);

  const fetchSubCategories = async () => {
    setIsLoading(true);
    try {
      const subCategoriesUrl = buildUrl('getSubCategories', queryLoginParam);
      const response = await getApi(subCategoriesUrl);
      if (response.ok) { 
        setAllSubCategories(response.data || []);
      } else {
        if (response.status === 426) {
          setErrorMessage('Your session has expired. Please log in again.');
          navigate("/");
        }
        setAllSubCategories([]);
      }
    } catch (error) { 
      setAllSubCategories([]);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchTaxCodes = async () => {
    try {
      const taxCodesUrl = buildUrl('getTaxCodes', queryLoginParam);
      const response = await getApi(taxCodesUrl);
      if (response.ok) {
        if (Array.isArray(response.data)) { 
          setTaxCodes(response.data);
        } else if (response.data && Array.isArray(response.data.data)) { 
          setTaxCodes(response.data.data);
        } else {
          console.error('Unexpected tax codes data structure:', response);
          setTaxCodes([]);
        }
      } else {
        console.error('Failed to fetch tax codes:', response);
        setTaxCodes([]);
      }
    } catch (error) {
      console.error('Error fetching tax codes:', error);
      setTaxCodes([]);
    }
  };

  const ToggleSwitch = ({ label, checked, onChange }) => (
    <div style={toggleContainerStyle}>
      <label style={toggleLabelStyle}>{label}</label>
      <div 
        style={toggleSwitchStyle(checked)}
        onClick={() => onChange(!checked)}
      >
        <div style={toggleKnobStyle(checked)}></div>
      </div>
    </div>
  );

  const handleActualSellerPriceChange = (index, field, value) => {
    setFormData(prevData => {
      const updatedPrices = [...prevData.actualSellerPrices];
      updatedPrices[index] = { ...updatedPrices[index], [field]: value };
      setActualSellerPricesChanged(true);
      return { ...prevData, actualSellerPrices: updatedPrices };
    });
  };

  const addActualSellerPrice = () => {
    setFormData(prevData => ({
      ...prevData,
      actualSellerPrices: [
        ...prevData.actualSellerPrices, 
        { minQuantity: '', maxQuantity: '', price: '', shippingCost: '', negotiated: false, additionalComments: '' }
      ]
    }));
    setActualSellerPricesChanged(true);
  };

  const removeActualSellerPrice = (index) => {
    if (formData.actualSellerPrices.length > 1) {
      setFormData(prevData => ({
        ...prevData,
        actualSellerPrices: prevData.actualSellerPrices.filter((_, i) => i !== index)
      }));
      setActualSellerPricesChanged(true);
    }
  };


  const handleMediaSave = ({ images, video }) => {
    const hasNewImages = images.some(img => img.file instanceof File);
    const hasNewVideo = video && video.file instanceof File;
    
    if (hasNewImages || hasNewVideo) {
      setImageChanged(true);
    }
    setAdditionalImages(images);
    setVideo(video);  
  };

  const handleMediaRemove = (uuid) => {
    setRemovedMediaUuids(prev => [...prev, uuid]); 
    if (draftWish?.additional_media) {
      const updatedMedia = draftWish.additional_media.filter(media => media.uuid !== uuid);
      setAdditionalImages(updatedMedia.filter(media => media.media_type === 'IMAGE'));
      setVideo(updatedMedia.find(media => media.media_type === 'VIDEO'));
    }
  };

  const handleCategoryChange = (event) => {
    const categoryId = event.target.value;
    setSelectedCategory(categoryId);
    setSelectedSubCategories([]);
    const selectedCategoryData = categories.find(cat => 
      cat.id.toString() === categoryId || cat.name === categoryId
    );
    if (selectedCategoryData && selectedCategoryData.sub_category) {
      const filteredSubs = allSubCategories.filter(sub => 
        selectedCategoryData.sub_category.includes(sub.id)
      );
      setFilteredSubCategories(filteredSubs);
    } else {
      setFilteredSubCategories([]);
    }
  }; 

  const handleSubCategoryChange = (event) => {
    const selectedOptions = Array.from(event.target.selectedOptions, option => option.value);
    setSelectedSubCategories(selectedOptions);
  };
  
  const handleDateChange = (date) => { 
    setFormData(prevData => {
      const updatedData = {
        ...prevData,
        expiresAt: date
      }; 
      return updatedData;
    });
  };

  const validateDraft = (formData) => {
    let newErrors = {};
    let isValid = true;
  
    // Only validate product name and deal link for drafts
    if (!formData.productName || formData.productName.trim() === '') {
      newErrors.productName = 'Product name is required';
      isValid = false;
    }
  
    if (!formData.dealTitle || formData.dealTitle.trim() === '') {
      newErrors.dealTitle = 'Deal link is required';
      isValid = false;
    }
  
    return { isValid, errors: newErrors };
  };

  const validateForm = useCallback(() => {
    let newErrors = {};
    let isValid = true;
  
    // Required fields validation
    if (!formData.productName || formData.productName.length < 3) {
      newErrors.productName = 'Product name must be at least 3 characters';
      isValid = false;
    }
  
    if (!formData.dealTitle || !isValidURL(formData.dealTitle)) {
      newErrors.dealTitle = 'Please enter a valid URL for the deal';
      isValid = false;
    }
  
    if (!formData.groupSizeMin || parseInt(formData.groupSizeMin) < 1) {
      newErrors.groupSizeMin = 'Please enter a valid group size';
      isValid = false;
    }
  
    // Validate price ranges
    if (formData.priceRanges && formData.priceRanges.length > 0) {
      formData.priceRanges.forEach((range, index) => {
        if (!range.minQuantity || !range.price) {
          if (!newErrors.priceRanges) newErrors.priceRanges = [];
          newErrors.priceRanges[index] = 'Price range fields are required';
          isValid = false;
        }
      });
    }
  
    // Important: Set both errors and isFormValid
    setErrors(newErrors);
    setIsFormValid(Object.keys(newErrors).length === 0);
  
    return Object.keys(newErrors).length === 0;  // Return validation state
  }, [formData]);

  const handleInputChange = (name, value) => { 
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
    if (errors[name]) {
      setErrors(prev => {
        const newErrors = { ...prev };
        delete newErrors[name];
        return newErrors;
      });
    }
  };

  const handlePriceRangeChange = (index, field, value) => { 
    setFormData(prevData => {
      const updatedRanges = [...prevData.priceRanges];
      updatedRanges[index] = { ...updatedRanges[index], [field]: value };
      setPriceRangesChanged(true);
      return { ...prevData, priceRanges: updatedRanges };
    });
  };

  const addPriceRange = () => {
    setFormData(prevData => ({
      ...prevData,
      priceRanges: [...prevData.priceRanges, { minQuantity: '', maxQuantity: '', price: '' , shippingCost: '', negotiated: false, additionalComments: '' }]
    }));
    setPriceRangesChanged(true);
  };

  const removePriceRange = (index) => {
    if (formData.priceRanges.length > 1) {
      setFormData(prevData => ({
        ...prevData,
        priceRanges: prevData.priceRanges.filter((_, i) => i !== index)
      }));
      setPriceRangesChanged(true);
    }
  };

  const isValidURL = (string) => {
    setSuccessMessage(null);
    setErrorMessage(null);
    try {
      new URL(string);
      return true;
    } catch (_) {
      return false;
    }
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImage(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
      setImageChanged(true);
    }
  };

  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  const handleRemoveImage = () => {
    setSelectedImage(null);
    setImagePreview(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
    setImageChanged(true);
    setFormData(prevData => ({
      ...prevData,
      removeImage: true,
      removeTitleImage: true,   
    }));
  };

  const saveDraft = async () => { 
    setSuccessMessage("");
    setErrorMessage("");
    setErrors("");
    const { isValid, errors } = validateDraft(formData);
    if (!isValid) {
      setErrors(errors);
      return;
    }       
    setIsLoading(true);
    try{   
      const grpWishURL = draftWish && draftWish.id
        ? buildUrl(`updateGroupWish/${draftWish.id}/`, queryLoginParam)
        : buildUrl('createGroupWish', queryLoginParam);
      
      let formDataToSend = new FormData();  
      
      if (selectedCategory) {
        formDataToSend.delete('category');
        formDataToSend.append('category', selectedCategory);
      }
      
      if (selectedSubCategories.length > 0) { 
        const subCategoryData = selectedSubCategories.map(subCat => 
          typeof subCat === 'object' ? (subCat.id || subCat.name) : subCat
        );
        formDataToSend.append('sub_category', JSON.stringify(subCategoryData));
      }

      const subCategoryData = selectedSubCategories.map(subCat => 
        typeof subCat === 'object' ? (subCat.id || subCat.name) : subCat
      );
      const defaultExpiryDate = '2050-12-31 23:59:59';
      const formattedExpiryDate = formData.expiresAt 
        ? formData.expiresAt.toISOString().split('.')[0].replace('T', ' ')
        : defaultExpiryDate;
      const requestBody = { 
        productName: formData.productName,
        dealTitle: formData.dealTitle,
        listPrice: parseFloat(formData.listPrice) || 0,
        description: formData.description,
        storeSeller: formData.storeSeller,
        marketLink: formData.marketLink,
        isComboDeal: formData.isComboDeal ? "True" : "False", 
        groupSizeMin: parseInt(formData.groupSizeMin) || 1,
        expiresAt: formattedExpiryDate,
        note: formData.note || "", 
        category: selectedCategory,
        sub_category: selectedSubCategories.length > 0 
          ? JSON.stringify(selectedSubCategories)
          : JSON.stringify([]), 
        imageChanged: imageChanged,
        priceRangesChanged: priceRangesChanged,
        priceRanges: priceRangesChanged ? JSON.stringify(formData.priceRanges) : undefined, 
        actualSellerPricesChanged: actualSellerPricesChanged,
        actualSellerPrices: actualSellerPricesChanged ? JSON.stringify(formData.actualSellerPrices) : undefined,
        salePrice: parseFloat(formData.salePrice) || 0,
        discountPercentage: parseFloat(formData.discountPercentage) || 0,
        taxjarTaxCode: taxCode,
      };
 
      Object.entries(requestBody).forEach(([key, value]) => {
        if (value !== undefined) {
          formDataToSend.append(key, value);
        }
      });
      if (selectedImage) {
        formDataToSend.append('image', selectedImage);
        formDataToSend.append('imageChanged', 'true');
      } else if (formData.removeImage) {
        formDataToSend.append('imageChanged', 'true');
        formDataToSend.append('removeImage', 'true');
        if(formData.removeTitleImage){
          formDataToSend.append('removeTitleImage', 'true');
        }
      }
      additionalImages.forEach((image, index) => {
        if (image.file instanceof File) {
          formDataToSend.append(`additional_image_${index}`, image.file);
        }
      });
      if (video?.file instanceof File) {
        formDataToSend.append('video', video.file);
      }
      
      if (removedMediaUuids.length > 0) {
        formDataToSend.append('removed_media_uuids', JSON.stringify(removedMediaUuids));
      } 
      const response = await postApi(grpWishURL, formDataToSend, {}, 2, true); 
      
      if (response.data) {
        setFormData(prevData => ({
          ...prevData,
          ...response.data
        }));
        setCreatedGroupId(response.data.id);
        setSuccessMessage(`Saved ${draftWish ? 'updates on ' : 'the draft for'} "${formData.productName}"`);
      } 
      setSuccessMessage(`Saved ${draftWish ? 'updates on ' : 'the draft for'} "${formData.productName}"`);        
      setPriceRangesChanged(false);
      setImageChanged(false);
      
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
      
      return response.data;             
    } catch (error) {   
      if (error.response?.data?.duplicate_details) {
        const details = error.response.data.duplicate_details;
        setErrorMessage(`Cannot save: duplicate image found in ${details.type} with ID: ${details.id}`);
      } else {
        setErrorMessage('Failed to save the draft. Please try again.');
      } 
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleDraft = () => {       
    saveDraft();   
  };

  const handleApprove = async() => { 
    setIsLoading(true);
    setErrorMessage("");
    setSuccessMessage("");
    let currentDraft = createdGroupId ? { id: createdGroupId } : null;
    if (!currentDraft) { 
      currentDraft = await saveDraft();
    }
    
    if (currentDraft && currentDraft.id) {
      setShowApprovalModal(true);
    } else {
      setErrorMessage('Failed to create or retrieve the draft. Please try again.');
    }
  };

  const handleApprovalSubmit = async (isVerified, verificationScore, commissionData) => {
    setIsLoading(true);
    try {
      const groupId = createdGroupId || (draftWish && draftWish.id);
      if (!groupId) {
        throw new Error('No group ID found');
      }

      const approveURL = buildUrl(`approveGroupWish/${draftWish.id}/`, queryLoginParam);
      const requestBody = {
        isVerified,
        verificationScore,
        commissionType: commissionData.type,
        commissionValue: commissionData.value
      };
      const response = await postApi(approveURL, requestBody); 
      if (response) {
        setSuccessMessage('Group wish approved successfully');
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
        fetchDraftWishes();
      } else {
        setErrorMessage('Failed to approve group wish');
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      }
    } catch (error) { 
      setErrorMessage('An error occurred while approving the group wish');
    } finally {
      setIsLoading(false);
      setShowApprovalModal(false);
    }
  };

  const getGroupsExistingPreferences = async (groupId) => {    
    setIsLoading(true);
    setErrorMessage("");
    setSuccessMessage("");
    try {
      const groupsExistingPreferencesURL = buildUrl(`getGroupsExistingPreferences/${groupId}`, queryLoginParam);
      const response = await getApi(groupsExistingPreferencesURL);
      if (response.ok) {
        const responseData = response.data;
        if (responseData.preferences_defined) {
          setExistingPreferences(responseData.preferences_defined);
        }
      }  
    } catch (error) { 
    } finally {
      setIsLoading(false);
    }
  };
  
  useEffect(() => {
    validateForm();
  }, [formData, validateForm]);

  const handleSubmit = async () => {
    if (validateForm()) {
       
      setShowPreferencesModal(true);
      
    } else {
      alert('Please fill all required fields correctly before proceeding.');
    }
  };

  const handlePreferenceSubmit = (response) => {  
    if (response) {
      setExistingPreferences(response.preferences || []);
      setSuccessMessage("Preferences have been successfully set!");
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    } else {
      setErrorMessage("Failed to set preferences. Please try again.");
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
    setShowPreferencesModal(false);
  };

  
 
  return (   
    <>
      <style>{styles}</style>
      <div >
        <TopMenuBar showBackButton={false} showHomeButton={true} viewDrafts={true} onBackClick={() => navigate('/')} title={"Create"} showSearch={false} showAddress={false} /> 
        <div style={contentStyle}>
          {isLoading ? (
            <div style={loadingContainerStyle}>
              <div style={loaderStyle}></div>
            </div>
          ) : (
            <>
              {(successMessage || errorMessage) && (
                <div style={{...messageContainerStyle,backgroundColor: successMessage ? '#d4edda' : '#f8d7da', color: successMessage ? '#155724' : '#721c24',}}>
                  <p>{successMessage || errorMessage}</p>
                </div>
              )}
              <div style={formContainerStyle}>
              {Object.keys(errors).length > 0 && (
                <div style={{
                  color: '#e74c3c',
                  padding: '10px',
                  marginBottom: '20px',
                  backgroundColor: '#fde8e8',
                  borderRadius: '4px'
                }}>
                  <h4>Please correct the following errors:</h4>
                  {Object.entries(errors).map(([field, error]) => (
                    <p key={field}>{error}</p>
                  ))}
                </div>
              )}
                <section style={sectionStyle}>
                  <div style={inputGroup}> 
                  <h3 style={sectionTitleStyle}>Basic Information</h3>                  
                  </div>
                  
                  <LabeledInput label="Product Name" value={formData.productName} onChange={(value) => handleInputChange('productName', value)} placeholder="Enter product name"  error={errors.productName}/>
                  <LabeledInput label="Deal Link" value={formData.dealTitle}  onChange={(value) => handleInputChange('dealTitle', value)}  placeholder="Enter deal link"  error={errors.dealTitle}/>
                  <ToggleSwitch label="Is Combo Deal" checked={formData.isComboDeal} onChange={(value) => handleInputChange('isComboDeal', value)} />
                  <FacebookPagePoster dealData={formData.deal} />
                </section>
                <div style={imageAndMarketDetailsContainerStyle}>
                  <section style={sectionStyle}>
                    <h3 style={sectionTitleStyle}>Market Details</h3>
                    <div style={inputGroup}>
                      <LabeledInput label="Market Price/Piece"  value={formData.listPrice} onChange={(value) => handleInputChange('listPrice', value)} placeholder="Enter single item price"  type="number"  error={errors.listPrice}/>
                      <LabeledInput label="Compare" value={formData.marketLink}  onChange={(value) => handleInputChange('marketLink', value)} placeholder="Enter Comparable Link"  type="text"  error={errors.marketLink}/>
                    </div>
                  </section>
                   
                  </div>
                <section style={sectionStyle}>
                  <div>
                  <section style={groupWishImageSectionStyle}>
                    <h3 style={sectionTitleStyle}>Group Wish Media</h3>
                    <div style={imageUploadContainerStyle}>
                      {imagePreview ? (
                        <div style={imagePreviewContainerStyle}>
                          <img src={imagePreview} alt="Preview" style={imagePreviewStyle} />
                          <button onClick={handleRemoveImage} style={removeImageButtonStyle}>Remove Image</button>
                        </div>
                      ) : (
                        <div style={uploadPromptStyle} onClick={handleImageClick}>
                          <FontAwesomeIcon icon={faUpload} size="2x" />
                          <p>Click to upload main image</p>
                        </div>
                      )}
                      <input type="file" ref={fileInputRef} style={{ display: 'none' }} onChange={handleImageChange} accept="image/*" />
                    </div>                    
                      {/* Only show additional media section if there's a main image or existing media */}
                      {(imagePreview || additionalImages.length > 0 || video) && (
                        <div style={additionalMediaContainerStyle}>
                          <button style={{...RoseGoldButton, ...additionalMediaButtonStyle}} onClick={() => setShowMediaModal(true)}>
                            Add More Media
                          </button>
                          {(additionalImages.length > 0 || video) && (
                            <div style={mediaPreviewStyle}>
                              <p>{additionalImages.length} additional images</p>
                              {video && <p>1 video added</p>}
                            </div>
                          )}
                        </div>
                      )}  
                        {draftWish?.additional_media && draftWish.additional_media.length > 0 && (
                        <div className="mt-4">
                          <h4 className="text-sm font-semibold mb-2">Additional Media</h4>
                          <AdditionalMediaDisplay additionalMedia={draftWish.additional_media.filter(media => !removedMediaUuids.includes(media.uuid))} onRemoveMedia={handleMediaRemove}/>
                        </div>
                      )}                    
                  </section>  
                  <MediaUploadModal isOpen={showMediaModal} onClose={() => setShowMediaModal(false)} onSave={handleMediaSave} currentImages={additionalImages} currentVideo={video} />                                                 
                  </div>
                </section>   
                <section style={sectionStyle}>
                  <h3 style={sectionTitleStyle}>Actual Seller Prices</h3>
                  <div style={priceRangeHeaderStyle}>
                    <span style={columnHeaderStyle1}>Min Qty</span>
                    <span style={columnHeaderStyle2}>Max Qty</span>
                    <span style={columnHeaderStyle3}>Price</span>
                    <span style={columnHeaderStyle4}>Shipping</span>
                    <span style={columnHeaderStyle5}>Actions</span>
                  </div>
                  {formData.actualSellerPrices.map((price, index) => (
                    <div key={index} style={priceRangeContainerStyle}>
                      <div style={priceRangeRowStyle}>
                        <input style={priceRangeInputStyle} type="number" value={price.minQuantity} onChange={(e) => handleActualSellerPriceChange(index, 'minQuantity', e.target.value)} placeholder="Min"/>
                        <input style={priceRangeInputStyle} type="number" value={price.maxQuantity} onChange={(e) => handleActualSellerPriceChange(index, 'maxQuantity', e.target.value)} placeholder="Max" />
                        <input style={priceRangeInputStyle} type="number" value={price.price} onChange={(e) => handleActualSellerPriceChange(index, 'price', e.target.value)} placeholder="Price" />
                        <input style={priceRangeInputStyle} type="number" value={price.shippingCost} onChange={(e) => handleActualSellerPriceChange(index, 'shippingCost', e.target.value)} placeholder="Shipping" />
                        <button style={removeButtonStyle} onClick={() => removeActualSellerPrice(index)}>
                          <FontAwesomeIcon icon={faTrash} style={removeIconStyle} />
                        </button>
                      </div>
                    </div>
                  ))}
                  <button style={{...RoseGoldButton, ...addRangeButtonStyle}} onClick={addActualSellerPrice}>
                    <FontAwesomeIcon icon={faPlus} /> Add Actual Seller Price
                  </button>
                </section>
                <section style={sectionStyle}>
                  <h3 style={sectionTitleStyle}>Price Ranges</h3>
                  <div style={priceRangeHeaderStyle}>
                    <span style={columnHeaderStyle1}>Min Qty</span>
                    <span style={columnHeaderStyle2}>Max Qty</span>
                    <span style={columnHeaderStyle3}>Price</span>
                    <span style={columnHeaderStyle4}>Shipping</span>
                    <span style={columnHeaderStyle5}>Actions</span>
                  </div>
                  {formData.priceRanges.map((range, index) => (
                    <div key={index} style={priceRangeContainerStyle}>
                      <div style={priceRangeRowStyle}>
                        <input style={priceRangeInputStyle} type="number" value={range.minQuantity} onChange={(e) => handlePriceRangeChange(index, 'minQuantity', e.target.value)} placeholder="Min"/>
                        <input style={priceRangeInputStyle} type="number" value={range.maxQuantity} onChange={(e) => handlePriceRangeChange(index, 'maxQuantity', e.target.value)} placeholder="Max"/>
                        <input style={priceRangeInputStyle} type="number" value={range.price} onChange={(e) => handlePriceRangeChange(index, 'price', e.target.value)} placeholder="Price"/>
                        <input style={priceRangeInputStyle} type="number" value={range.shippingCost} onChange={(e) => handlePriceRangeChange(index, 'shippingCost', e.target.value)} placeholder="Shipping"/>
                        <button style={removeButtonStyle} onClick={() => removePriceRange(index)}>
                          <FontAwesomeIcon icon={faTrash} style={removeIconStyle} />
                        </button>
                      </div>
                    </div>
                  ))}
                  <button style={{...RoseGoldButton, ...addRangeButtonStyle}} onClick={addPriceRange}>
                    <FontAwesomeIcon icon={faPlus} /> Add Price Range
                  </button>
                </section> 
                
  
                <section style={sectionStyle}>
                  <h3 style={sectionTitleStyle}>Additional Details</h3>
                  <div style={inputGroup}>
                    <div style={labeledInputContainerStyle}>
                      <label style={inputLabelStyle}>Expires At</label>
                      <DatePicker selected={formData.expiresAt} onChange={handleDateChange} minDate={new Date()} placeholderText="Select expiration date" style={inputStyle} />
                      {errors.expiresAt && <span style={errorTextStyle}>{errors.expiresAt}</span>}
                    </div>
                    <LabeledInput label="Note" value={formData.note} onChange={(value) => handleInputChange('note', value)} placeholder="Enter any additional notes" multiline style={{ height: '100px' }}/>
                  </div>
                  <LabeledInput label="Description (Optional)" value={formData.description}  onChange={(value) => handleInputChange('description', value)}  placeholder="Enter description"  multiline style={{ height: '100px' }}  error={errors.description}/>
                  <LabeledInput label="Store/Seller" value={formData.storeSeller}  onChange={(value) => handleInputChange('storeSeller', value)} placeholder="Enter store or seller name" />
                  <LabeledInput label="Minimum Group Size" value={formData.groupSizeMin} onChange={(value) => handleInputChange('groupSizeMin', value)} placeholder="Enter minimum group size" type="number" error={errors.groupSizeMin}/>
                  <div style={labeledInputContainerStyle}>
                    <label style={inputLabelStyle}>Tax Category</label>
                    <select  value={taxCode} onChange={(e) => setTaxCode(e.target.value)}  style={inputStyle}>
                      <option value="00000">General</option>
                      {Array.isArray(taxCodes) && taxCodes
                        .filter(code => code.taxjar_tax_code !== "00000")  
                        .map(code => (
                          <option key={code.taxjar_tax_code} value={code.taxjar_tax_code}>
                            {code.description} {code.category ? `(${code.category})` : ''}
                          </option>
                        ))
                      }
                    </select>
                    <span style={{fontSize: '12px', color: '#666', marginTop: '4px'}}>
                      The product category for tax calculation purposes
                    </span>
                  </div>
                </section>
                <section style={sectionStyle}>
                  <h3 style={sectionTitleStyle}>Categories</h3>
                  <div style={inputGroup}>
                    <div style={labeledInputContainerStyle}>
                      <label style={inputLabelStyle}>Category</label>
                      <select value={selectedCategory} onChange={handleCategoryChange} style={inputStyle}>                      
                        <option value="">Select a category</option>
                        {categories.map(category => (
                          <option key={category.id} value={category.id.toString()} >
                             {category?.name || 'Unknown Category'}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div style={labeledInputContainerStyle}>
                      <label style={inputLabelStyle}>Subcategories</label>
                      <select multiple value={selectedSubCategories} onChange={handleSubCategoryChange}                        
                       className="multiselect"
                       style={{
                        ...inputStyle,
                        height: '100px',
                        backgroundColor: 'white',
                        WebkitAppearance: 'listbox',
                        MozAppearance: 'listbox'
                      }}
                      >                       
                      {filteredSubCategories.map(subCategory => {
                        const isSelected = selectedSubCategories.includes(subCategory.id.toString());
                        return (
                          <option 
                            key={subCategory.id} 
                            value={subCategory.id.toString()}
                            style={{
                              backgroundColor: isSelected ? Color.colorRoseGold : 'transparent',
                              padding: '8px',
                              fontWeight: isSelected ? 'bold' : 'normal'
                            }}
                          >
                            {subCategory.name || 'Unknown Subcategory'}
                          </option>
                        );
                      })}
                      </select>
                    </div>
                  </div>
                </section>
                {draftWish && !createNew && (
                  <ProductSpecifications 
                    groupId={draftWish.id}
                    queryLoginParam={queryLoginParam}
                    isEditMode={true}
                    existingSpecifications={draftWish.product_specifications}
                    onSave={(updatedSpecs) => {
                      setSuccessMessage('Product specifications updated successfully');
                      window.scrollTo({ top: 0, behavior: 'smooth' });
                    }}
                  />
                )}

                <div style={buttonContainerStyle}>
                  <button style={saveDraftButtonStyle} onClick={handleDraft}>
                    Save as Draft
                  </button>
                  <button style={{ ...setPreferenceButtonStyle,backgroundColor: isFormValid ? Color.colorMintGreen : '#bdc3c7', opacity: isFormValid ? 1 : 0.7,cursor: isFormValid ? 'pointer' : 'not-allowed',}} onClick={handleSubmit} disabled={!isFormValid}>
                    Set Preference
                  </button>
                  {isBacksAppManager && (
                    <button style={approveButtonStyle} onClick={handleApprove}>Approve</button>
                  )}
                </div>
                <p style={requiredFieldsNoteStyle}>* Required fields</p>
              </div>
            </>
          )}
        </div>
        {showPreferencesModal && (
          <DefinePreferences isOpen={showPreferencesModal} onClose={() => setShowPreferencesModal(false)} groupId={createdGroupId} existingPreferences={existingPreferences} onSubmit={handlePreferenceSubmit}/>
        )}
        {showApprovalModal && (
          <ApprovalModal isOpen={showApprovalModal} onClose={() => {setShowApprovalModal(false)}}  onApprove={(isVerified, verificationScore, commissionData) => {            
            handleApprovalSubmit(isVerified, verificationScore, commissionData);
          }}/>
        )}
      </div>
    </>
    );
  };
  
  
const contentStyle = {
  width: '100%',
  padding: '20px', 
};

const formContainerStyle = {
  backgroundColor: Color.colorLightBeige,
  borderRadius: '8px',
  padding: '30px',
  marginBottom: '30px',
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
};

const sectionStyle = {
  backgroundColor: Color.colorWhite,
  width:"95%",
  margin: "1%",
  borderRadius: '8px',
  padding: '20px',
  marginBottom: '20px',
  border: '1px solid #e0e0e0',
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
};

const sectionTitleStyle = {
  fontSize: '20px',
  fontWeight: 'bold',
  marginBottom: '20px',
  color: Color.colorPurple, 
  paddingBottom: '10px',
};

const inputGroup = {
  display: 'flex',
  gap: '20px',
  flexDirection: 'row',
};

const labeledInputContainerStyle = {
  marginBottom: '20px',
  marginLeft: '5%',
  marginRight: '5%',
  flex: 1,
  width: '90%',
  alignItems: 'center',
};

const inputLabelStyle = {
  display: 'block',
  fontSize: '14px',
  fontWeight: '600',
  marginBottom: '8px',
  color: Color.colorRoyalBlueText,
};

const inputStyle = {
  width: '100%',
  padding: '12px',
  border: '1px solid #bdc3c7',
  borderRadius: '4px',
  fontSize: '14px',
  transition: 'border-color 0.3s ease, box-shadow 0.3s ease',
  outline: 'none',
  color: Color.colorRoyalBlueText,
};

const baseInputStyle = {
  width: '100%',
  padding: '12px',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: '#bdc3c7',
  borderRadius: '4px',
  fontSize: '14px',
  transition: 'border-color 0.3s ease, box-shadow 0.3s ease',
  outline: 'none',
  color: Color.colorRoyalBlueText,
};

const textareaStyle = {
  minHeight: '100px',
  resize: 'vertical',
};

const inputErrorStyle = {
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: '#e74c3c',
  boxShadow: '0 0 0 2px rgba(231, 76, 60, 0.25)',
};

const errorTextStyle = {
  color: '#e74c3c',
  fontSize: '12px',
  marginTop: '5px',
};

const priceRangeRowStyle = {
  display: 'flex',
  gap: '10px',
  marginBottom: '10px',
  alignItems: 'center',
  width: '100%',
};

const priceRangeInputStyle = {
  ...inputStyle, 
  flex: 2,
  padding: '8px',
};

const removeButtonStyle = {
  background: 'none',
  border: 'none',
  cursor: 'pointer',
  color: '#e74c3c',
  fontSize: '18px',
  transition: 'color 0.3s ease',
  flex: 1,
  display: 'flex',
  justifyContent: 'center',
};

const removeIconStyle = {
  fontStyle: 'normal',
};

const addRangeButtonStyle = { 
  color: 'white',
  border: 'none',
  padding: '12px',
  borderRadius: '8px',
  cursor: 'pointer',
  fontWeight: 'bold',
  width: '50%',
  marginLeft: '25%',
  transition: 'background-color 0.3s ease',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '8px',
};

const loadingContainerStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '200px',
};

const loaderStyle = {
  border: '4px solid #f3f3f3',
  borderTop: `4px solid ${Color.colorMintGreen}`,
  borderRadius: '50%',
  width: '40px',
  height: '40px',
  animation: 'spin 1s linear infinite',
};

const messageContainerStyle = {
  padding: '15px',
  borderRadius: '4px',
  marginBottom: '20px',
  fontWeight: 'bold',
  textAlign: 'center',
};

const priceRangeContainerStyle = {
  marginBottom: '15px',
};

const priceRangeHeaderStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  fontWeight: 'bold',
  marginBottom: '10px',
  padding: '0 10px',
  color: Color.colorRoyalBlueText,
  width: '100%',
};
const columnHeaderStyle = {
  flex: 1,
  textAlign: 'center',
  padding: '0 5px',
};

const columnHeaderStyle1 = {
  ...columnHeaderStyle,
  flex: 2,
};

const columnHeaderStyle2 = {
  ...columnHeaderStyle,
  flex: 2,
};

const columnHeaderStyle3 = {
  ...columnHeaderStyle,
  flex: 2,
};

const columnHeaderStyle4 = {
  ...columnHeaderStyle,
  flex: 2,
};

const columnHeaderStyle5 = {
  ...columnHeaderStyle,
  flex: 1,
};
const buttonContainerStyle = {
  display: 'flex',
  justifyContent: 'center',
  gap: '25px',
  marginTop: '30px',
  width:'100%',
  marginLeft: '0%',
};

const saveDraftButtonStyle = {
  flex: 1,
  padding: '15px',
  borderRadius: '8px',
  backgroundColor: Color.colorDarkBlue,
  color: 'white',
  border: 'none',
  fontWeight: 'bold',
  cursor: 'pointer',
  transition: 'background-color 0.3s ease',
  fontWeight: 'bold',
};

const setPreferenceButtonStyle = {
  flex: 1,
  padding: '15px',
  borderRadius: '8px',
  color: 'white',
  border: 'none',
  fontWeight: 'bold',
  backgroundColor: Color.colorDarkBlue,
  transition: 'background-color 0.3s ease',
  fontWeight: 'bold',
};

const approveButtonStyle = {
  flex: 1,
  padding: '15px',
  borderRadius: '8px',
  backgroundColor: Color.colorDarkBlue,
  color: 'white',
  border: 'none',
  fontWeight: 'bold',
  cursor: 'pointer',
  transition: 'background-color 0.3s ease', 
  backgroundColor: Color.colorPurpleBlue,  
  fontWeight: 'bold',
};

const requiredFieldsNoteStyle = {
  fontSize: '12px',
  color: Color.colorDarkGrey,
  textAlign: 'center',
  marginTop: '20px',
};

const imageAndMarketDetailsContainerStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  gap: '20px',
  marginBottom: '20px',
};

const additionalMediaContainerStyle = {
  marginTop: '15px',
  display: 'flex',
  flexDirection: 'column',
  gap: '10px'
};

const additionalMediaButtonStyle = { 
  color: 'white',
  border: 'none',
  borderRadius: '4px',
  padding: '10px 20px',
  marginTop: '10px',
  cursor: 'pointer',
  fontWeight: 'bold',
  width: '100%',
};

const mediaPreviewStyle = {
  marginTop: '10px',
  padding: '10px',
  backgroundColor: Color.colorLightBeige,
  borderRadius: '4px',
  textAlign: 'center',
};

const groupWishImageSectionStyle = {
  ...sectionStyle,
  flex: 1,
  marginBottom: 0,
};

const imageUploadContainerStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '300px',
  width: '100%',
  border: '2px dashed #bdc3c7',
  borderRadius: '4px',
  cursor: 'pointer',
};

const imagePreviewContainerStyle = {
  position: 'relative',
  width: '100%',
  height: '100%',
};

const imagePreviewStyle = {
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  borderRadius: '4px',
};

const removeImageButtonStyle = {
  position: 'absolute',
  top: '10px',
  right: '10px',
  background: 'rgba(0, 0, 0, 0.5)',
  color: 'white',
  border: 'none',
  borderRadius: '4px',
  padding: '5px 10px',
  cursor: 'pointer',
};
const multiSelectStyle = {
  ...inputStyle,
  height: '100px',
  backgroundColor: 'white',
  padding: '8px',
  outline: 'none',
  cursor: 'pointer'
};

const multiSelectOptionStyle = {
  padding: '8px',
  backgroundColor: 'transparent'
};
const uploadPromptStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  color: '#bdc3c7',
};
const toggleContainerStyle = {
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  marginBottom: '20px',
  marginLeft: '5%',
  marginRight: '5%',
};

const toggleLabelStyle = {
  fontSize: '14px',
  fontWeight: '600',
  color: Color.colorRoyalBlueText,
};
const toggleSwitchStyle = (checked) => ({
  width: '48px',
  height: '24px',
  backgroundColor: checked ? Color.colorMintGreen : '#ccc',
  borderRadius: '12px',
  padding: '2px',
  cursor: 'pointer',
  position: 'relative',
  transition: 'background-color 0.3s',
});

const toggleKnobStyle = (checked) => ({
  width: '20px',
  height: '20px',
  backgroundColor: 'white',
  borderRadius: '50%',
  position: 'absolute',
  left: checked ? '26px' : '2px',
  transition: 'left 0.3s',
});
const keyframesStyle = `
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;


  export default CreateGroupWish; 
      