import React, { useRef, useState, useEffect } from 'react';
 
const previewContainerStyle = {
  marginBottom: '20px',
  border: '1px solid #dee2e6',
  borderRadius: '4px',
  padding: '15px',
  backgroundColor: '#f8f9fa',
};

const previewHeaderStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '15px',
};

const previewTitleStyle = {
  fontSize: '15px',
  fontWeight: 'bold',
  color: '#343a40',
  margin: 0,
};

const previewControlsStyle = {
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
};

const previewInstructionsStyle = {
  fontSize: '12px',
  color: '#6c757d',
  fontStyle: 'italic',
};

const preview3DSceneStyle = {
  height: '300px',
  backgroundColor: '#eaeaea',
  borderRadius: '4px',
  perspective: '1000px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'hidden',
  marginBottom: '15px',
};

const previewBoxStyle = {
  position: 'relative',
  transformStyle: 'preserve-3d',
  transition: 'transform 0.3s ease',
  boxSizing: 'border-box',
};

const previewBoxFaceStyle = {
  position: 'absolute',
  border: '1px solid rgba(0, 0, 0, 0.2)',
  boxSizing: 'border-box',
  backgroundColor: 'rgba(255, 255, 255, 0.6)',
  backfaceVisibility: 'hidden',
};

const previewBoxFrontStyle = {
  width: '100%',
  height: '100%',
  transform: 'translateZ(100%)',
};

const previewBoxBackStyle = {
  width: '100%',
  height: '100%',
  transform: 'translateZ(0) rotateY(180deg)',
};

const previewBoxLeftStyle = {
  width: '100%',
  height: '100%',
  transform: 'translateX(-50%) rotateY(-90deg)',
  transformOrigin: 'left',
};

const previewBoxRightStyle = {
  width: '100%',
  height: '100%',
  transform: 'translateX(50%) rotateY(90deg)',
  transformOrigin: 'right',
};

const previewBoxTopStyle = {
  width: '100%',
  height: '100%',
  transform: 'translateY(-50%) rotateX(90deg)',
  transformOrigin: 'top',
};

const previewBoxBottomStyle = {
  width: '100%',
  height: '100%',
  transform: 'translateY(50%) rotateX(-90deg)',
  transformOrigin: 'bottom',
};

const itemLabelStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  color: 'white',
  fontWeight: 'bold',
  fontSize: '12px',
  textShadow: '1px 1px 2px rgba(0,0,0,0.8)',
};

const previewLegendStyle = {
  borderTop: '1px solid #dee2e6',
  paddingTop: '10px',
};

const legendTitleStyle = {
  fontSize: '14px',
  fontWeight: 'bold',
  marginBottom: '8px',
  color: '#343a40',
};

const legendItemsStyle = {
  display: 'flex',
  flexWrap: 'wrap',
  gap: '10px',
};

const legendItemStyle = {
  display: 'flex',
  alignItems: 'center',
  gap: '5px',
};

const legendColorBoxStyle = {
  width: '20px',
  height: '20px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '3px',
  fontSize: '10px',
  fontWeight: 'bold',
  color: 'white',
  textShadow: '1px 1px 1px rgba(0,0,0,0.5)',
};

const legendTextStyle = {
  fontSize: '12px',
  color: '#343a40',
  maxWidth: '150px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

/**
 * 3D preview component for a packed box
 */
const BoxPreview3D = ({ box, boxIndex }) => {
  const containerRef = useRef(null);
  const [rotation, setRotation] = useState({ x: 15, y: 45 });
  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  
  // Set up event listeners for 3D rotation
  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;
    
    const handleMouseDown = (e) => {
      setIsDragging(true);
      setDragStart({ x: e.clientX, y: e.clientY });
    };
    
    const handleMouseMove = (e) => {
      if (!isDragging) return;
      
      const dx = e.clientX - dragStart.x;
      const dy = e.clientY - dragStart.y;
      
      setRotation({
        x: rotation.x + dy * 0.5,
        y: rotation.y + dx * 0.5
      });
      
      setDragStart({ x: e.clientX, y: e.clientY });
    };
    
    const handleMouseUp = () => {
      setIsDragging(false);
    };
    
    container.addEventListener('mousedown', handleMouseDown);
    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);
    
    return () => {
      container.removeEventListener('mousedown', handleMouseDown);
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragging, dragStart, rotation]);
  
  // Calculate scale factor to fit box in container
  const maxDimension = Math.max(box.length, box.width, box.height);
  const scaleFactor = 150 / maxDimension; // Scale to 150px max size
  
  // Generate random colors for items (but keep consistent for each item)
  const getItemColor = (item, opacity = 0.7) => {
    // Hash the item name to get a consistent color
    const hash = item.name.split('').reduce((acc, char) => acc + char.charCodeAt(0), 0);
    const hue = hash % 360;
    return `hsla(${hue}, 70%, 50%, ${opacity})`;
  };
  
  return (
    <div style={previewContainerStyle}>
      <div style={previewHeaderStyle}>
        <h4 style={previewTitleStyle}>3D Preview: {box.name}</h4>
        <div style={previewControlsStyle}>
          <span style={previewInstructionsStyle}>Click and drag to rotate</span>
        </div>
      </div>
      
      <div 
        ref={containerRef}
        style={{
          ...preview3DSceneStyle,
          cursor: isDragging ? 'grabbing' : 'grab'
        }}
      >
        {/* Box container */}
        <div style={{
          ...previewBoxStyle,
          width: `${box.width * scaleFactor}px`,
          height: `${box.height * scaleFactor}px`,
          transform: `rotateX(${rotation.x}deg) rotateY(${rotation.y}deg) scale3d(1, 1, ${box.length / box.width})`,
        }}>
          {/* Front face */}
          <div style={{...previewBoxFaceStyle, ...previewBoxFrontStyle}}></div>
          
          {/* Back face */}
          <div style={{...previewBoxFaceStyle, ...previewBoxBackStyle}}></div>
          
          {/* Left face */}
          <div style={{...previewBoxFaceStyle, ...previewBoxLeftStyle}}></div>
          
          {/* Right face */}
          <div style={{...previewBoxFaceStyle, ...previewBoxRightStyle}}></div>
          
          {/* Top face */}
          <div style={{...previewBoxFaceStyle, ...previewBoxTopStyle}}></div>
          
          {/* Bottom face */}
          <div style={{...previewBoxFaceStyle, ...previewBoxBottomStyle}}></div>
          
          {/* Render items inside the box */}
          {box.items.map((item, index) => {
            if (!item.position) return null;
            
            // Get the actual dimensions based on rotation
            let width, height, length;
            switch(item.rotation) {
              case 0: width = item.width; height = item.height; length = item.length; break;
              case 1: width = item.width; height = item.length; length = item.height; break;
              case 2: width = item.height; height = item.width; length = item.length; break;
              case 3: width = item.height; height = item.length; length = item.width; break;
              case 4: width = item.length; height = item.width; length = item.height; break;
              case 5: width = item.length; height = item.height; length = item.width; break;
              default: width = item.width; height = item.height; length = item.length;
            }
            
            // Calculate position (translate coordinates to 3D space)
            const x = item.position.x / box.width * 100;
            const y = item.position.y / box.height * 100;
            const z = item.position.z / box.length * 100;
            
            // Calculate size as percentage of box dimensions
            const widthPercent = width / box.width * 100;
            const heightPercent = height / box.height * 100;
            const depthPercent = length / box.length * 100;
            
            const itemColor = getItemColor(item);
            const itemBorderColor = getItemColor(item, 1);
            
            return (
              <div 
                key={index}
                style={{
                  position: 'absolute',
                  left: `${x}%`,
                  top: `${y}%`,
                  width: `${widthPercent}%`,
                  height: `${heightPercent}%`,
                  transform: `translateZ(${z}%) scale3d(1, 1, ${depthPercent/widthPercent})`,
                  backgroundColor: itemColor,
                  border: `1px solid ${itemBorderColor}`,
                  borderRadius: '2px',
                  transformStyle: 'preserve-3d',
                  boxSizing: 'border-box',
                  overflow: 'hidden',
                  zIndex: item.isFragile ? 10 : 1
                }}
                title={`${item.name} (${width} × ${height} × ${length}, ${item.weight} ${item.weightUnit})`}
              >
                <div style={itemLabelStyle}>
                  {index + 1}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      
      <div style={previewLegendStyle}>
        <h5 style={legendTitleStyle}>Items</h5>
        <div style={legendItemsStyle}>
          {box.items.map((item, index) => (
            <div key={index} style={legendItemStyle}>
              <span style={{
                ...legendColorBoxStyle,
                backgroundColor: getItemColor(item)
              }}>{index + 1}</span>
              <span style={legendTextStyle}>{item.name}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BoxPreview3D;