import React, { createContext, useState, useContext, useCallback, useMemo, useEffect } from 'react';
import { debounce } from 'lodash';
import { getAuth, onAuthStateChanged, onIdTokenChanged, signOut, signInAnonymously } from 'firebase/auth';
import Config from '../../../config';

const AuthContext = createContext();

const initialAuthState = {
  idTokenLoggedIn: null,
  countryCodeLoggedIn: null,
  phoneLoggedIn: null,
  emailLoggedIn: null,
  loginEmail: false,
  isBacksAppStaff: false,
  isBacksAppManager: false,
  userVerified: false,
  accountType: null,
  firstName: null,
  queryLoginParam: null,
  lastLoginTime: null,
  logInRequested: false,
  logOutRequested: false,
  user: null,
  verificationCooldownUntil: null,
  isMerchant: null,
  isMerchantVerified: null,
  company: null,
  // Influencer specific properties
  isInfluencer: false,
  influencerProfileComplete: false,
  connectedPlatforms: [],
  audienceSize: null,
  primaryNiche: null,
  commissionRate: null,
  // Guest user properties
  isGuest: false,
  guestId: null,
  guestSessionStartTime: null,
  pendingCampaignParticipations: []
};

export const AuthProvider = ({ children }) => { 
  const [user, setUser] = useState(null);
  const [authState, setAuthState] = useState(initialAuthState);

  
  const updateAuthState = useCallback((newState) => {
    setAuthState(prevState => {
      const updatedState = { ...prevState, ...newState }; 
      return updatedState;
    });
  }, []);

  const clearLocalStorage = useCallback(async () => {
    if (authState.logOutRequested) {
      return;
    }
    
    try { 
      const auth = getAuth();
      await signOut(auth);  
      localStorage.clear();     
      
      setAuthState(initialAuthState); 
      setUser(null); 
    } catch (error) {
      //console.error('Logout failed:', error);
    }
  }, [authState.logOutRequested, updateAuthState]);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribeAuthState = onAuthStateChanged(auth, (firebaseUser) => {
      if (firebaseUser) {
        setUser(firebaseUser);
        // Check if this is an anonymous user (guest)
        const isGuest = firebaseUser.isAnonymous;
        
        updateAuthState({ 
          user: firebaseUser,
          emailLoggedIn: firebaseUser.email, 
          logOutRequested: false, 
          logInRequested: true,
          isGuest,
          guestId: isGuest ? firebaseUser.uid : null
        });
      } else {
        setUser(null);
        setAuthState(initialAuthState);
      }
    }); 

    const unsubscribeIdToken = onIdTokenChanged(auth, async (user) => {
      if (user) {
        const token = await user.getIdToken(true);   
        updateAuthState({ 
          idTokenLoggedIn: token, 
          logOutRequested: false,
          emailLoggedIn: user.email,
          loginEmail: !user.isAnonymous
        });
      } else {
        updateAuthState({ idTokenLoggedIn: null });
      }
    });

    return () => {
      unsubscribeAuthState();
      unsubscribeIdToken();
    };
  }, [updateAuthState]);

  const refreshToken = async () => {
    const auth = getAuth();
    const user = auth.currentUser;
    if (user) {
      try {
        const newToken = await user.getIdToken(true); // Force refresh
        updateAuthState({ idTokenLoggedIn: newToken });
        return newToken;
      } catch (error) {
        //console.error('Token refresh failed:', error);
        return null;
      }
    }
    return null;
  };

  const debouncedRefreshToken = useMemo(
    () => debounce(refreshToken, 1000, { leading: true, trailing: false }),
    [refreshToken]
  );

  // New function for guest login
  const loginAsGuest = async () => {
    try {
      const auth = getAuth();
      const credential = await signInAnonymously(auth);
      const guestUser = credential.user;
      const guestToken = await guestUser.getIdToken();
      
      const guestStartTime = new Date();
      localStorage.setItem('guest_id', guestUser.uid);
      localStorage.setItem('guest_token', guestToken);
      localStorage.setItem('guest_session_start', guestStartTime.toISOString());
      
      updateAuthState({
        isGuest: true,
        guestId: guestUser.uid,
        guestSessionStartTime: guestStartTime,
        idTokenLoggedIn: guestToken,
        user: guestUser,
        logInRequested: true,
        logOutRequested: false
      });
      
      return true;
    } catch (error) {
      //console.error('Guest login failed:', error);
      return false;
    }
  };
  
  // Function to fetch influencer profile data
  const fetchInfluencerProfile = async (userId) => {
    if (!authState.isInfluencer) return null;
    
    try {
      const token = await refreshToken();
      const response = await fetch(`${Config.apiUrl}getInfluencerProfile?userId=${userId}`, {
        headers: {
          'Authorization': token,
          'Content-Type': 'application/json'
        }
      });
      
      if (response.ok) {
        const profileData = await response.json();
        
        updateAuthState({
          influencerProfileComplete: true,
          connectedPlatforms: profileData.connectedPlatforms || [],
          audienceSize: profileData.audienceSize || null,
          primaryNiche: profileData.primaryNiche || null,
          commissionRate: profileData.commissionRate || 10
        });
        
        // Also update localStorage
        localStorage.setItem('influencerProfileComplete', 'true');
        localStorage.setItem('connectedPlatforms', JSON.stringify(profileData.connectedPlatforms || []));
        localStorage.setItem('audienceSize', profileData.audienceSize || '');
        localStorage.setItem('primaryNiche', profileData.primaryNiche || '');
        localStorage.setItem('commissionRate', profileData.commissionRate || '10');
        
        return profileData;
      }
      return null;
    } catch (error) {
      //console.error('Failed to fetch influencer profile:', error);
      return null;
    }
  };  

  const login = async (accessToken, uid, email, isBacksAppStaff, isBacksAppManager, firstName, userVerified, isMerchant, isMerchantVerified, accountType, companyName) => {    
    localStorage.clear();
    const is_merchant_user = `loginEmail=true`;
    const merchant_email = `&email=${encodeURIComponent(email)}`;
    const queryParams = `${is_merchant_user}${merchant_email}`; 
  
    if (!authState.logOutRequested) {
      try {
        localStorage.setItem('auth_token', accessToken);
        localStorage.setItem('firebase_uid', uid);
        localStorage.setItem('merchant_email', email);
        localStorage.setItem('loginEmail', 'true');
        localStorage.setItem('queryParam', queryParams);
        localStorage.setItem('isBacksAppStaff', String(isBacksAppStaff));
        localStorage.setItem('firstName', firstName);
        localStorage.setItem('isBacksAppManager', String(isBacksAppManager));
        localStorage.setItem('userVerified', String(userVerified));
        localStorage.setItem('accountType', accountType);       
        localStorage.setItem('company', String(companyName));
        localStorage.setItem('isMerchant', String(isMerchant));
        localStorage.setItem('isMerchantVerified', String(isMerchantVerified));
        
        // Handle influencer status
        const isInfluencer = accountType === 'INFLUENCER';
        localStorage.setItem('isInfluencer', String(isInfluencer));
  
        const auth = getAuth();
        const currentUser = auth.currentUser;
        setUser(currentUser);
  
        const newAuthState = {
          idTokenLoggedIn: accessToken,
          emailLoggedIn: email,
          loginEmail: true,
          isBacksAppStaff: Boolean(isBacksAppStaff),
          isBacksAppManager: Boolean(isBacksAppManager),
          userVerified: Boolean(userVerified),
          accountType: accountType,
          company: companyName,
          firstName: firstName,
          queryLoginParam: queryParams,
          logOutRequested: false,
          logInRequested: true,
          user: currentUser,
          isMerchant: Boolean(isMerchant),
          isMerchantVerified: Boolean(isMerchantVerified),
          // Set influencer specific properties
          isInfluencer: isInfluencer,
          // Clear guest properties on regular login
          isGuest: false,
          guestId: null,
          guestSessionStartTime: null
        };
  
        updateAuthState(newAuthState);
        
        // If user is an influencer, fetch their profile data
        if (isInfluencer) {
          fetchInfluencerProfile(uid);
        }
  
        return true;
      } catch (error) {
        //console.error('Login failed:', error);
        return false;
      } finally {
        checkVerificationCooldown();
      }
    }
  };

  // Function to save pending campaign participation for guest users
  const savePendingParticipation = (campaignData) => {
    if (!authState.isGuest) return false;
    
    try {
      const pendingParticipations = JSON.parse(localStorage.getItem('pendingCampaignParticipations') || '[]');
      pendingParticipations.push(campaignData);
      localStorage.setItem('pendingCampaignParticipations', JSON.stringify(pendingParticipations));
      
      updateAuthState({
        pendingCampaignParticipations: pendingParticipations
      });
      
      return true;
    } catch (error) {
      //console.error('Failed to save pending participation:', error);
      return false;
    }
  };

  // Function to convert guest to registered user
  const convertGuestToUser = async (userData, token) => {
    if (!authState.isGuest) return false;
    
    try {
      // Transfer any pending participations
      const pendingParticipations = authState.pendingCampaignParticipations;
      
      // Proceed with normal login
      const success = await login(
        token,
        userData.uid,
        userData.email,
        userData.isBacksAppStaff || false,
        userData.isBacksAppManager || false,
        userData.firstName || '',
        userData.userVerified || false,
        userData.isMerchant || false,
        userData.isMerchantVerified || false,
        userData.accountType || 'PERSONAL',
        userData.company || ''
      );
      
      if (success && pendingParticipations.length > 0) {
        // Here you would typically call an API to transfer the pending participations
        // to the new registered user account
        
        // For now, let's just clear the pending participations
        localStorage.removeItem('pendingCampaignParticipations');
        updateAuthState({
          pendingCampaignParticipations: []
        });
      }
      
      return success;
    } catch (error) {
      //console.error('Failed to convert guest to user:', error);
      return false;
    }
  };

  const returningUser = async () => {
    try {
      // Check for guest user first
      const guestId = localStorage.getItem('guest_id');
      const guestToken = localStorage.getItem('guest_token');
      const guestSessionStart = localStorage.getItem('guest_session_start');
      
      if (guestId && guestToken) {
        const pendingParticipations = JSON.parse(localStorage.getItem('pendingCampaignParticipations') || '[]');
        updateAuthState({
          isGuest: true,
          guestId,
          guestSessionStartTime: guestSessionStart ? new Date(guestSessionStart) : new Date(),
          pendingCampaignParticipations: pendingParticipations,
          idTokenLoggedIn: guestToken
        });
        return true;
      }
      
      // If not a guest, check for regular user
      const token = localStorage.getItem('auth_token');
      const countryCode = localStorage.getItem('countryCode');
      const phoneNumber = localStorage.getItem('phoneNumber');
      const email = localStorage.getItem('email');
      const loginEmail = localStorage.getItem('loginEmail') === 'true';
      const isBacksAppStaff = localStorage.getItem('isBacksAppStaff') === 'true';
      const isBacksAppManager = localStorage.getItem('isBacksAppManager') === 'true';
      const userVerified = localStorage.getItem('userVerified') === 'true';
      const isMerchant = localStorage.getItem('isMerchant') === 'true';
      const isMerchantVerified = localStorage.getItem('isMerchantVerified') === 'true';
      const accountType = localStorage.getItem('accountType');      
      const company = localStorage.getItem('company');
      const queryParam = localStorage.getItem('queryParam');
      const firstName = localStorage.getItem('firstName');
      
      // Influencer-specific properties
      const isInfluencer = localStorage.getItem('isInfluencer') === 'true';
      const influencerProfileComplete = localStorage.getItem('influencerProfileComplete') === 'true';
      const connectedPlatforms = JSON.parse(localStorage.getItem('connectedPlatforms') || '[]');
      const audienceSize = localStorage.getItem('audienceSize');
      const primaryNiche = localStorage.getItem('primaryNiche');
      const commissionRate = localStorage.getItem('commissionRate');

      if (token) {
        const newToken = await refreshToken();
        if (newToken) {
          const newAuthState = {
            idTokenLoggedIn: newToken,
            countryCodeLoggedIn: countryCode,
            phoneLoggedIn: phoneNumber,
            emailLoggedIn: email,            
            loginEmail: loginEmail,
            isBacksAppStaff: isBacksAppStaff,
            isBacksAppManager: isBacksAppManager,
            userVerified: userVerified,
            isMerchant: isMerchant,
            isMerchantVerified: isMerchantVerified,
            accountType: accountType,
            firstName: firstName,
            queryLoginParam: queryParam,
            company: company,
            // Influencer properties
            isInfluencer: isInfluencer,
            influencerProfileComplete: influencerProfileComplete,
            connectedPlatforms: connectedPlatforms,
            audienceSize: audienceSize,
            primaryNiche: primaryNiche,
            commissionRate: commissionRate
          };
           
          updateAuthState(newAuthState);
          return true;
        }
      }
      return false;
    } catch (error) {
      //console.error('Returning user check failed:', error);
      return false;
    } finally {
      checkVerificationCooldown();
    }
  };

  const setVerificationCooldown = useCallback(() => {
    const cooldownTime = new Date();
    cooldownTime.setHours(cooldownTime.getHours() + 4);
    localStorage.setItem('verificationCooldownUntil', cooldownTime.toISOString());
    updateAuthState({ verificationCooldownUntil: cooldownTime });
  }, [updateAuthState]);

  const checkVerificationCooldown = useCallback(() => {
    const cooldownUntil = localStorage.getItem('verificationCooldownUntil');
    if (cooldownUntil) {
      const cooldownTime = new Date(cooldownUntil);
      if (new Date() < cooldownTime) {
        updateAuthState({ verificationCooldownUntil: cooldownTime });
        return cooldownTime;
      } else {
        localStorage.removeItem('verificationCooldownUntil');
        updateAuthState({ verificationCooldownUntil: null });
      }
    }
    return null;
  }, [updateAuthState]);

  useEffect(() => {
    checkVerificationCooldown();
  }, [checkVerificationCooldown]);

  return (
    <AuthContext.Provider value={{ 
      ...authState,
      user, 
      login, 
      loginAsGuest,
      clearLocalStorage, 
      refreshToken: debouncedRefreshToken, 
      returningUser, 
      updateAuthState,
      checkVerificationCooldown,
      fetchInfluencerProfile,
      savePendingParticipation,
      convertGuestToUser
    }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};