import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; 
import { 
  faCheckCircle, 
  faBell, 
  faChevronDown, 
  faChevronUp, 
  faTrash,
  faFileInvoice,
  faSearch,
  faFilter,
  faTimes
} from '@fortawesome/free-solid-svg-icons';
import * as styles from '../../../GlobalStyles'; 
import { useAuth } from '../InitialGears/AuthContext';
import { getApi, postApi, buildUrl } from "../BackendCalls/apiUtils";
import ReminderSettingScreen from "./ReminderSettingScreen";
import { TopMenuBar } from '../../Buyers/DashbordComponents/TopMenuBar';

const NotificationsScreen = () => {
  const { queryLoginParam, idTokenLoggedIn } = useAuth();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const [allNotifications, setAllNotifications] = useState([]); // Store all notifications for filtering
  const [expandedNotificationId, setExpandedNotificationId] = useState(null);
  const [isReminderSettingVisible, setIsReminderSettingVisible] = useState(false);
  const [selectedNotificationId, setSelectedNotificationId] = useState(null);
  const [messageVisible, setMessageVisible] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [filterOptions, setFilterOptions] = useState({
    showFilterMenu: false,
    notificationType: 'all',
    readStatus: 'all'
  });

  useEffect(() => {
    document.title = 'Notifications';
    fetchData();
  }, []);

  useEffect(() => {
    if (successMessage || errorMessage) {
      setMessageVisible(true);
      const timer = setTimeout(() => {
        setMessageVisible(false);
        setSuccessMessage(null);
        setErrorMessage(null);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [successMessage, errorMessage]);

  // Apply filters and search whenever these values change
  useEffect(() => {
    applyFiltersAndSearch();
  }, [searchQuery, filterOptions.notificationType, filterOptions.readStatus, allNotifications]);

  const fetchData = async () => {
    try {
      setSuccessMessage("");
      setErrorMessage("");
      await getNotifications();
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const getNotifications = async () => {
    setIsLoading(true);
    try { 
        const notificationsURL = buildUrl('myNotifications', queryLoginParam);  
        const response = await getApi(notificationsURL); 
        
        if (response && response.ok && response.data && response.data.notifications) {
            if (response.data.notifications.length > 0) {
              // Sort notifications with the latest on top
              const sortedNotifications = response.data.notifications
                .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
                .map((notification) => ({
                  id: notification.id,
                  title: notification.title,
                  message: notification.message,
                  is_read: notification.is_read,
                  reminderTime: notification.remind_me_later_at,
                  type: notification.type,
                  referenceNote: notification.referenceNote,
                  created_at: notification.created_at
                }));
                
              setAllNotifications(sortedNotifications);
              setNotifications(sortedNotifications);
            } else {
              const emptyNotification = [{
                id: 0,
                title: 'No Notifications',
                message: 'No notifications for you at the moment.',
                is_read: true,
                reminderTime: null,
                type: 'EMPTY'
              }];
              setAllNotifications(emptyNotification);
              setNotifications(emptyNotification);
            }
        } else { 
            setErrorMessage("Failed to fetch notifications. Please try again later.");
            setNotifications([]);
            setAllNotifications([]);
        }
    } catch (error) {  
        setErrorMessage("An error occurred while fetching notifications.");
        setNotifications([]);
        setAllNotifications([]);
    } finally {
        setIsLoading(false);
    }
  };

  const applyFiltersAndSearch = () => {
    if (allNotifications.length === 0) return;

    // If we have the No Notifications placeholder
    if (allNotifications.length === 1 && allNotifications[0].id === 0) {
      setNotifications(allNotifications);
      return;
    }

    let filteredNotifications = [...allNotifications];

    // Apply notification type filter
    if (filterOptions.notificationType !== 'all') {
      filteredNotifications = filteredNotifications.filter(
        notification => notification.type === filterOptions.notificationType
      );
    }

    // Apply read status filter
    if (filterOptions.readStatus !== 'all') {
      const isRead = filterOptions.readStatus === 'read';
      filteredNotifications = filteredNotifications.filter(
        notification => notification.is_read === isRead
      );
    }

    // Apply search query
    if (searchQuery.trim() !== '') {
      const query = searchQuery.toLowerCase();
      filteredNotifications = filteredNotifications.filter(
        notification => 
          notification.title.toLowerCase().includes(query) || 
          notification.message.toLowerCase().includes(query)
      );
    }

    // If we end up with no notifications after filtering
    if (filteredNotifications.length === 0) {
      filteredNotifications = [{
        id: 0,
        title: 'No Matching Notifications',
        message: 'No notifications match your current filters or search query.',
        is_read: true,
        reminderTime: null,
        type: 'EMPTY'
      }];
    }

    setNotifications(filteredNotifications);
  };

  const resetFilters = () => {
    setFilterOptions({
      showFilterMenu: false,
      notificationType: 'all',
      readStatus: 'all'
    });
    setSearchQuery('');
  };

  const notificationDelete = async (id) => {
    setIsLoading(true);
    try {
      const notificationsURL = buildUrl('deleteNotification', queryLoginParam); 
      const requestBody = {
        notification_id: id,
      };
      const notificationsResponse = await postApi(notificationsURL, requestBody); 
      try { 
        if (notificationsResponse) {
          setErrorMessage("");
          setSuccessMessage(notificationsResponse.data.message);
        } else {
          setSuccessMessage("");
          setErrorMessage(notificationsResponse.data.error);
        }
      } catch (error) { 
        setSuccessMessage(notificationsResponse.data.message);
        setErrorMessage("");
      }
    } catch (error) {
      setErrorMessage("Something went wrong");
      setSuccessMessage("");
    }
    await getNotifications();
    setIsLoading(false);
  };

  const markRead = async (id) => {
    setIsLoading(true);
    try {
      const notificationsURL = buildUrl('markNotificationRead', queryLoginParam); 
      const requestBody = {
        notification_id: id,
      };
      const response = await postApi(notificationsURL, requestBody); 
      try { 
        if (response) {
          setErrorMessage("");
          setSuccessMessage(response.data.message);
        } else {
          setSuccessMessage("");
          setErrorMessage(response.data.error);
        }
      } catch (error) { 
        setSuccessMessage(response.data.message);
        setErrorMessage("");
      }
    } catch (error) {
      setErrorMessage("Something went wrong");
      setSuccessMessage("");
    }
    await getNotifications();
    setIsLoading(false);
  };

  const formatReminderTime = (dateString) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat(undefined, {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
    }).format(date);
  };

  const formatCreatedTime = (dateString) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat(undefined, {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    }).format(date);
  };

  const sendNotification = async (message) => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem('pushNotificationToken');
      const notificationsURL = buildUrl('setReminder', queryLoginParam); 
      const requestBody = {
        notification_id: message.notificationId,
        push_token: token,
        title: message.title,
        content: message.content,
        reminderTime: message.reminderTime,
        userTimezone: message.userTimezone,
      };
      const userLocalTime = formatReminderTime(requestBody.reminderTime.toLocaleString());
      const response = await postApi(notificationsURL, requestBody); 
      try { 
        if (response) {
          setSuccessMessage(`Reminder Set for "${requestBody.title}" At: ${userLocalTime}`);
        } else {
          setErrorMessage(response.error);
        }
      } catch (error) { 
        setErrorMessage("");
        setSuccessMessage(`Reminder Set for "${requestBody.title}" At: ${userLocalTime}`);
      }
    } catch (error) {
      setErrorMessage("Something went wrong");
    }
    setIsLoading(false);
  };

  const toggleNotificationExpansion = (id) => {
    setExpandedNotificationId(expandedNotificationId === id ? null : id);
  };

  const markAsRead = async (id) => {
    setIsLoading(true);
    await markRead(id);
    setIsLoading(false);
  };

  const setReminder = async (id) => {
    setSelectedNotificationId(id);
    setIsReminderSettingVisible(true);
  };

  const deleteNotification = async (id) => {
    await notificationDelete(id);
  };

  const handleViewInvoice = (referenceNote) => {
    try { 
      if (referenceNote) { 
        const noteData = referenceNote.split(','); 
        const invoiceId = noteData.find(item => item.includes('invoiceId'))?.split(':')[1]?.trim();
        const groupWishId = noteData.find(item => item.includes('groupWishId'))?.split(':')[1]?.trim(); 
        const comboId = noteData.find(item => item.includes('comboId'))?.split(':')[1]?.trim();
        let path = `/invoice/${invoiceId}`; 
        if (comboId) {
          path = `/invoice/${invoiceId}/combo_id/${comboId}`;
        } else if (groupWishId) {
          path = `/invoice/${invoiceId}/groupWishId/${groupWishId}`; 
        }
        navigate(path, { state: { from: 'notifications' } });
        
      } else {
        setErrorMessage("Invoice reference not found");
      }
    } catch (error) {  
      setErrorMessage("Failed to open invoice page");
    }
  }; 

  const renderNotificationContent = (item) => {
    if (item.type === 'INVOICE_CREATED' && item.referenceNote) {
      return (
        <>
          <p style={styles.notificationMessage}>{item.message}</p>
          <div style={localStyles.linkContainer}> 
            <button onClick={(e) => {e.preventDefault(); handleViewInvoice(item.referenceNote); }} style={localStyles.invoiceLinkButton}>
              <FontAwesomeIcon icon={faFileInvoice} style={localStyles.invoiceIcon} />
              <span>View Invoice</span>
            </button>
          </div>
          {item.created_at && (
            <p style={localStyles.timestampText}>
              {formatCreatedTime(item.created_at)}
            </p>
          )}
        </>
      );
    }
    return (
      <>
        <p style={styles.notificationMessage}>{item.message}</p>
        {item.created_at && (
          <p style={localStyles.timestampText}>
            {formatCreatedTime(item.created_at)}
          </p>
        )}
      </>
    );
  };

  const renderFilterMenu = () => {
    if (!filterOptions.showFilterMenu) return null;

    // Get all unique notification types for the filter dropdown
    const notificationTypes = [...new Set(allNotifications
      .filter(note => note.id !== 0)
      .map(note => note.type))];

    return (
      <div style={localStyles.filterMenuContainer}>
        <div style={localStyles.filterHeader}>
          <h3 style={localStyles.filterTitle}>Filter Notifications</h3>
          <button 
            onClick={() => setFilterOptions({...filterOptions, showFilterMenu: false})}
            style={localStyles.closeFilterButton}
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>
        
        <div style={localStyles.filterSection}>
          <label style={localStyles.filterLabel}>By Type:</label>
          <select 
            value={filterOptions.notificationType} 
            onChange={(e) => setFilterOptions({...filterOptions, notificationType: e.target.value})}
            style={localStyles.filterSelect}
          >
            <option value="all">All Types</option>
            {notificationTypes.map(type => (
              <option key={type} value={type}>
                {type.replace(/_/g, ' ')}
              </option>
            ))}
          </select>
        </div>
        
        <div style={localStyles.filterSection}>
          <label style={localStyles.filterLabel}>By Status:</label>
          <select 
            value={filterOptions.readStatus} 
            onChange={(e) => setFilterOptions({...filterOptions, readStatus: e.target.value})}
            style={localStyles.filterSelect}
          >
            <option value="all">All Statuses</option>
            <option value="read">Read</option>
            <option value="unread">Unread</option>
          </select>
        </div>
        
        <div style={localStyles.filterActions}>
          <button 
            onClick={resetFilters}
            style={localStyles.resetFilterButton}
          >
            Reset Filters
          </button>
          <button 
            onClick={() => setFilterOptions({...filterOptions, showFilterMenu: false})}
            style={localStyles.applyFilterButton}
          >
            Apply
          </button>
        </div>
      </div>
    );
  };

  return ( 
    <div style={styles.mainContainer}>
      <TopMenuBar showHomeButton={true} showNotifications={true} title={"Notifications"}/>
      
      {messageVisible && (
        <div style={localStyles.messageContainer}>
          {successMessage && (
            <p style={localStyles.successMessage}>{successMessage}</p>
          )}
          {errorMessage && (
            <p style={localStyles.errorMessage}>{errorMessage}</p>
          )}
        </div>
      )}
      
      {isLoading && (
        <div style={localStyles.loadingContainer}>
          <div className="spinner"></div>
        </div>
      )}
      
      <div style={localStyles.searchFilterContainer}>
        <div style={localStyles.searchContainer}>
          <FontAwesomeIcon icon={faSearch} style={localStyles.searchIcon} />
          <input
            type="text"
            placeholder="Search notifications..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            style={localStyles.searchInput}
          />
          {searchQuery && (
            <button 
              onClick={() => setSearchQuery('')}
              style={localStyles.clearSearchButton}
            >
              <FontAwesomeIcon icon={faTimes} />
            </button>
          )}
        </div>
        
        <button 
          onClick={() => setFilterOptions({...filterOptions, showFilterMenu: !filterOptions.showFilterMenu})}
          style={localStyles.filterButton}
          className={filterOptions.notificationType !== 'all' || filterOptions.readStatus !== 'all' ? 'active-filter' : ''}
        >
          <FontAwesomeIcon icon={faFilter} style={localStyles.filterIcon} />
          <span>Filter</span>
        </button>
      </div>
      
      {renderFilterMenu()}
      
      <div style={styles.notificationContainer}>
        {notifications.map((item) => (
          <div key={item.id.toString()} style={localStyles.notificationItem}>
            <div style={localStyles.notificationHeader}>
              <h3 
                style={{
                  ...styles.notificationTitle,
                  ...(item.is_read && styles.readNotificationTitle),
                }}
              >
                {item.title}
              </h3>
              <button 
                onClick={() => toggleNotificationExpansion(item.id)}  
                style={localStyles.expandButton}
              >
                <FontAwesomeIcon 
                  icon={expandedNotificationId === item.id ? faChevronUp : faChevronDown}  
                  style={localStyles.expandIcon} 
                />
              </button>
            </div>                        
            
            {expandedNotificationId === item.id && (
              <>
                {!item.is_read && item.id !== 0 && (
                  <div style={localStyles.actionButtonsContainer}>
                    <button 
                      onClick={() => markAsRead(item.id)} 
                      style={localStyles.actionButton}
                    >
                      <FontAwesomeIcon icon={faCheckCircle} style={localStyles.actionIcon} />
                      <span style={styles.notificationAction}>Mark as Read</span>
                    </button>
                    <button 
                      onClick={() => setReminder(item.id)} 
                      style={localStyles.reminderButton}
                    >
                      <FontAwesomeIcon icon={faBell} style={localStyles.reminderIcon} />
                      <span style={styles.notificationAction}>Set Reminder</span>
                    </button>
                  </div>
                )}
                    
                {item.is_read && item.id !== 0 && (
                  <div style={localStyles.actionButtonsContainer}>
                    <button 
                      onClick={() => deleteNotification(item.id)} 
                      style={localStyles.actionButton}
                    >
                      <FontAwesomeIcon icon={faTrash} style={localStyles.deleteIcon} />
                      <span style={styles.notificationAction}>Delete</span>
                    </button>
                  </div>
                )} 
                {renderNotificationContent(item)}
              </>
            )}
          </div>
        ))}
      </div>
      
      {isReminderSettingVisible && (
        <div style={localStyles.modalOverlay}>
          <div style={localStyles.modalContent}>
            <ReminderSettingScreen
              onSaveReminder={(message) => { 
                setIsReminderSettingVisible(false); 
                sendNotification(message); 
              }}
              onCancel={() => setIsReminderSettingVisible(false)}
              initialTitle={
                notifications && notifications.length > 0
                  ? notifications.find((item) => item.id === selectedNotificationId)?.title
                  : 'No notifications for you at the moment'
              }
              notificationId={selectedNotificationId}
            />
          </div>
        </div>
      )}
    </div>
  );
};

const localStyles = {
  messageContainer: {
    position: 'fixed',
    top: '290px',
    left: 0,
    right: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    padding: '20px', 
    zIndex: 999,
  },
  successMessage: {
    color: '#4CAF50',
    fontSize: '16px',
    textAlign: 'center',
  },
  errorMessage: {
    color: '#F44336',
    fontSize: '16px',
    textAlign: 'center',
  },
  actionIcon: {
    fontSize: '18px',
    marginRight: '5px',
    color: styles.Color.colorDarkGrey,
  },
  loadingContainer: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
    zIndex: 998,
  },
  modalOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
    backdropFilter: 'blur(5px)',
  },
  modalContent: {
    backgroundColor: styles.Color.colorLightBeige,
    padding: '30px',
    borderRadius: '12px',
    maxWidth: '500px',
    width: '90%',
    maxHeight: '80vh',
    overflow: 'auto',
    position: 'relative',
    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.15)',
    border: `1px solid ${styles.Color.colorMidBeige}`,
    marginBottom: '80px',
    paddingBottom: '80px',
  },  
  notificationItem: {
    ...styles.notificationItem,
    backgroundColor: styles.Color.colorLightBeige,
    marginBottom: '15px',
    padding: '15px',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  },
  notificationHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '10px',
  },
  expandButton: {
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    padding: '5px',
  },
  expandIcon: {
    fontSize: '18px',
    color: styles.Color.colorPurple,
  },
  actionButtonsContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    gap: '10px',
    marginTop: '10px',
  },
  actionButton: {
    display: 'flex',
    alignItems: 'center',
    background: 'none',
    border: '1px solid ' + styles.Color.colorPurple,
    cursor: 'pointer',
    padding: '5px 10px',
    borderRadius: '8px',
    transition: 'background-color 0.3s',
    color: styles.Color.colorPurple,
    backgroundColor: styles.Color.colorDarkBlue,
  },
  actionIcon: {
    fontSize: '16px',
    marginRight: '5px',
    color: styles.Color.colorWhite,
  },
  deleteIcon: {
    fontSize: '16px',
    marginRight: '5px',
    color: styles.Color.colorWhite,
  },
  reminderButton: {
    display: 'flex',
    alignItems: 'center',
    background: 'none',
    border: '1px solid ' + styles.Color.colorPurple,
    cursor: 'pointer',
    padding: '5px 10px',
    borderRadius: '8px',
    transition: 'background-color 0.3s',
    color: styles.Color.colorPurple,
    backgroundColor: styles.Color.colorLimeGreen,
  },
  reminderIcon: {
    fontSize: '16px',
    marginRight: '5px',
    color: styles.Color.colorWhite,
  },
  linkContainer: {
    marginTop: '10px',
  },
  invoiceLinkButton: {
    display: 'flex',
    alignItems: 'center',
    background: 'none',
    border: '1px solid ' + styles.Color.colorPurple,
    cursor: 'pointer',
    padding: '8px 16px',
    borderRadius: '8px',
    transition: 'all 0.3s ease',
    color: styles.Color.colorPurple,
    backgroundColor: styles.Color.colorWhite,
    textDecoration: 'none',
    fontWeight: '500',
    fontSize: '14px',
  },
  invoiceIcon: {
    fontSize: '16px',
    marginRight: '8px',
    color: styles.Color.colorPurple,
  },
  timestampText: {
    fontSize: '12px',
    color: styles.Color.colorDarkGrey,
    marginTop: '10px',
    textAlign: 'right',
    fontStyle: 'italic'
  },
  searchFilterContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '20px',
    padding: '0 15px',
  },
  searchContainer: {
    flex: '1',
    position: 'relative',
    marginRight: '10px',
  },
  searchIcon: {
    position: 'absolute',
    left: '10px',
    top: '50%',
    transform: 'translateY(-50%)',
    color: styles.Color.colorDarkGrey,
  },
  searchInput: {
    width: '100%',
    padding: '10px 10px 10px 35px',
    borderRadius: '8px',
    border: `1px solid ${styles.Color.colorMidBeige}`,
    fontSize: '14px',
    backgroundColor: styles.Color.colorWhite,
  },
  clearSearchButton: {
    position: 'absolute',
    right: '10px',
    top: '50%',
    transform: 'translateY(-50%)',
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    color: styles.Color.colorDarkGrey,
  },
  filterButton: {
    display: 'flex',
    alignItems: 'center',
    background: styles.Color.colorWhite,
    border: `1px solid ${styles.Color.colorPurple}`,
    borderRadius: '8px',
    padding: '10px 15px',
    cursor: 'pointer',
    transition: 'all 0.3s ease',
    color: styles.Color.colorPurple,
  },
  filterIcon: {
    marginRight: '5px',
  },
  filterMenuContainer: {
    backgroundColor: styles.Color.colorWhite,
    border: `1px solid ${styles.Color.colorMidBeige}`,
    borderRadius: '8px',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
    padding: '15px',
    marginBottom: '20px',
    marginLeft: '15px',
    marginRight: '15px',
  },
  filterHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '15px',
    borderBottom: `1px solid ${styles.Color.colorMidBeige}`,
    paddingBottom: '10px',
  },
  filterTitle: {
    margin: 0,
    color: styles.Color.colorPurple,
    fontSize: '16px',
  },
  closeFilterButton: {
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    color: styles.Color.colorDarkGrey,
    fontSize: '16px',
  },
  filterSection: {
    marginBottom: '15px',
  },
  filterLabel: {
    display: 'block',
    marginBottom: '5px',
    color: styles.Color.colorDarkGrey,
    fontSize: '14px',
  },
  filterSelect: {
    width: '100%',
    padding: '8px 10px',
    borderRadius: '6px',
    border: `1px solid ${styles.Color.colorMidBeige}`,
    backgroundColor: styles.Color.colorWhite,
  },
  filterActions: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '10px',
    marginTop: '15px',
  },
  resetFilterButton: {
    padding: '8px 15px',
    borderRadius: '6px',
    border: `1px solid ${styles.Color.colorDarkGrey}`,
    backgroundColor: styles.Color.colorWhite,
    color: styles.Color.colorDarkGrey,
    cursor: 'pointer',
  },
  applyFilterButton: {
    padding: '8px 15px',
    borderRadius: '6px',
    border: 'none',
    backgroundColor: styles.Color.colorPurple,
    color: styles.Color.colorWhite,
    cursor: 'pointer',
  },
  // Add styling for active filter button (when filters are applied)
  '.active-filter': {
    backgroundColor: styles.Color.colorLightPurple,
    color: styles.Color.colorWhite,
  }
};

export default NotificationsScreen;