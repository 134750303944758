import React from 'react'; 
import { Color } from "../../../GlobalStyles";

const LoadingSpinnerSection = ({ title }) => {
  return (
    <div style={styles.loadingContainer}>
      <div style={styles.spinner}></div>
      <p style={styles.spinnerText}>{title}...</p>
    </div> 
  );
};

const styles = {
loadingContainer: {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
},
spinner: {
  border: `4px solid ${Color.colorRoseGoldText}`,
  borderTop: `4px solid ${Color.colorBaseBeige}`,
  borderRadius: '50%',
  width: '40px',
  height: '40px',
  animation: 'spin 1s linear infinite',
},
spinnerText: { 
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  margin: '10px 10px 20px 20px',
  padding: '10px 10px',
  color: `${Color.colorRoseGoldText}`,
},
};
export default LoadingSpinnerSection;