import React from 'react'; 
import _ from 'lodash';
import * as style from "../../../GlobalStyles";

const TotalCreditsDisplay = ({ creditData }) => {
  const totalPoints = _.sumBy(creditData, 'creditAllocated');
  
  return (
    <div style={pageStyle}> 
        <div style={tableContainerStyle}>
          <table style={tableStyle}>
            <thead>
              <tr>
                <th style={tableHeaderStyle}>Date</th>
                <th style={tableHeaderStyle}>Type</th>
                <th style={tableHeaderStyle}>Referred User</th>
                <th style={{...tableHeaderStyle, textAlign: 'right'}}>Points</th>
                <th style={tableHeaderStyle}>Description</th>
              </tr>
            </thead>
            <tbody>
              {creditData.map((credit, index) => (
                <tr key={index} style={index % 2 === 0 ? tableRowEvenStyle : tableRowOddStyle}>
                  <td style={tableCellStyle}>
                    {new Date(credit.date).toLocaleDateString()}
                  </td>
                  <td style={tableCellStyle}>
                    <span style={credit.type === 'NEW_USER' ? typeTagNewStyle : typeTagDealStyle}>
                      {credit.type === 'NEW_USER' ? 'New User' : 'Deal'}
                    </span>
                  </td>
                  <td style={tableCellStyle}>{credit.referredUser}</td>
                  <td style={{...tableCellStyle, textAlign: 'right', fontWeight: 'bold'}}>
                    {credit.creditAllocated} points
                  </td>
                  <td style={tableCellStyle}>{credit.note}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div style={summaryStyle}>
          <span style={summaryLabelStyle}>Total Earned Credits:</span>
          <span style={summaryValueStyle}>{totalPoints} points</span>
        </div> 
    </div>
  );
};

const pageStyle = {
  width: '100%',
  maxWidth: '1200px',
  margin: '0 auto',
  padding: '20px',
};

const tableContainerStyle = {
  overflowX: 'auto',
  marginTop: '20px',
};

const tableStyle = {
  width: '100%',
  borderCollapse: 'collapse',
};

const tableHeaderStyle = {
  backgroundColor: style.Color.colorLightGrey,
  color: style.Color.colorDarkGrey,
  padding: '12px',
  textAlign: 'left',
};

const tableRowEvenStyle = {
  backgroundColor: style.Color.colorWhite,
};

const tableRowOddStyle = {
  backgroundColor: style.Color.colorLightBeige,
};

const tableCellStyle = {
  padding: '12px',
  borderBottom: `1px solid ${style.Color.colorLightestGrey}`,
  color: style.Color.colorDarkBlue,
  fontSize: '12px',
};

const typeTagNewStyle = {
  backgroundColor: style.Color.colorBlueB,
  color: style.Color.colorDarkBlue,
  padding: '4px 8px',
  borderRadius: '12px',
  fontSize: '12px',
};

const typeTagDealStyle = {
  backgroundColor: style.Color.colorLightPurple,
  color: style.Color.colorPurple,
  padding: '4px 8px',
  borderRadius: '12px',
  fontSize: '12px',
};

const summaryStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: style.Color.colorLightGrey,
  borderRadius: '15px',
  padding: '15px',
  marginTop: '20px',
};

const summaryLabelStyle = {
  fontSize: '16px',
  color: style.Color.colorDarkGrey,
};

const summaryValueStyle = {
  fontSize: '24px',
  fontWeight: 'bold',
  color: style.Color.colorPurple,
};

export default TotalCreditsDisplay;