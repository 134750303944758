import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers, faShippingFast, faExclamationTriangle, faDollarSign, faShippingFast as faShipping, faPercent, faCheck, faBoxes, faLayerGroup } from '@fortawesome/free-solid-svg-icons';
import { getApi, postApi, buildUrl } from "../Common/BackendCalls/apiUtils";
import { useAuth } from '../Common/InitialGears/AuthContext';
import { TopMenuBar } from '../Buyers/DashbordComponents/TopMenuBar';
import PriceRangesSelection from './PriceRangesSelection'; 
import * as style from "../../GlobalStyles";
import UserStatsDisplay from './UserStatsDisplay';
import WaitlistSection from './WaitListedUsers';

const statusOptions = [
  { value: "ACTIVE", label: "Active" },
  { value: "INACTIVE", label: "Inactive" },
  { value: "EXPIRED", label: "Expired" }, 
  { value: "COMBO_OPEN", label: "Combo Open" }, 
  { value: "MIN_MET", label: "Minimum Met" },   
  { value: "PAYMENT_INITATED", label: "Payment Initiated" },
  { value: "ALL_PAYMENTS_COMPLETED", label: "All Payments Completed" },
  { value: "ORDER_SHIPPED_FROM_MANUFACTURER", label: "Order Shipped from Manufacturer" },
  { value: "GOODS_AT_PORT", label: "Goods at Port" },
  { value: "GOODS_CUSTOM_CLEARED", label: "Goods Custom Cleared" },
  { value: "INLAND_FRIEGHT", label: "Inland Freight" },
  { value: "REPACKING", label: "Repacking" },
  { value: "SHIPPED_TO_DELIVERY", label: "Shipped to Delivery" },
  { value: "DELIVERING", label: "Delivering" },
  { value: "DEAL_COMPLETED", label: "Deal Completed" },
];  

const STATUS_TRANSITIONS = {
  'ACTIVE': ['INACTIVE','MIN_MET','EXPIRED'],
  'INACTIVE': ['ACTIVE','COMBO_OPEN','EXPIRED'],
  'EXPIRED': ['ACTIVE','COMBO_OPEN','INACTIVE'],
  'MIN_MET': ['PAYMENT_INITATED'],
  'PAYMENT_INITATED': ['ALL_PAYMENTS_COMPLETED'],
  'ALL_PAYMENTS_COMPLETED': ['ORDER_SHIPPED_FROM_MANUFACTURER'],
  'ORDER_SHIPPED_FROM_MANUFACTURER': ['GOODS_AT_PORT'],
  'GOODS_AT_PORT': ['GOODS_CUSTOM_CLEARED'],
  'GOODS_CUSTOM_CLEARED': ['INLAND_FRIEGHT'],
  'INLAND_FRIEGHT': ['REPACKING'],
  'REPACKING': ['SHIPPED_TO_DELIVERY'],
  'SHIPPED_TO_DELIVERY': ['DELIVERING'],
  'DELIVERING': ['DEAL_COMPLETED'],
  'DEAL_COMPLETED': []
};

const ComboProcessing = () => {
  const { comboId } = useParams();
  const { queryLoginParam } = useAuth();
  const navigate = useNavigate();
  const [combo, setCombo] = useState(null);
  const [priceRanges, setPriceRanges] = useState(null);
  const [comboUsers, setComboUsers] = useState(null);
  const [currentStatus, setCurrentStatus] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState(''); 
  const [closingProductCost, setClosingProductCost] = useState('');
  const [closingShippingCost, setClosingShippingCost] = useState('');
  const [closingBacksAppCharges, setClosingBacksAppCharges] = useState(''); 
  const [closingBacksAppChargeType, setClosingBacksAppChargeType] = useState('');   
  const [isEditingExpiry, setIsEditingExpiry] = useState(false);
  const [expiryDate, setExpiryDate] = useState('');
  const [selectedActualRange, setSelectedActualRange] = useState(null);
  const [selectedNonActualRange, setSelectedNonActualRange] = useState(null);
  const [closingOrderSize, setClosingOrderSize] = useState('');  
  const [waitlistUsers, setWaitlistUsers] = useState([]);
  const [comboProducts, setComboProducts] = useState([]);
  const [groupWishes, setGroupWishes] = useState([]);
  const [comboRating, setComboRating] = useState(0);

  useEffect(() => {
    fetchComboDetails();
  }, [comboId]);

  const fetchComboDetails = async () => {
    setIsLoading(true);
    try {
      const url = buildUrl(`comboOrderProcessing/${comboId}/`, queryLoginParam);
      const response = await getApi(url);
      if (response) { 
        setCombo(response.data.combo); 
        const priceRangesFromGroupWishes = response.data.group_wishes?.[0]?.price_ranges || [];
        setPriceRanges(priceRangesFromGroupWishes);
        setComboUsers(response.data);
        setCurrentStatus(response.data.combo.combo_status); 
        setClosingOrderSize(response.data.combo.min_combo_order_qty);
        setClosingOrderSize(response.data.combo.closing_order_size || '');
        setClosingProductCost(response.data.combo.closing_product_cost || '');
        setClosingShippingCost(response.data.shipping_cost_estimate || '');
        setClosingBacksAppChargeType(response.data.combo.commission_type || '');
        setClosingBacksAppCharges(response.data.combo.commission_value || '');
        setWaitlistUsers(response.data.waitlist_users || []);
         
        if (response.data.group_wishes && response.data.group_wishes.length > 0) {
          setGroupWishes(response.data.group_wishes);
        } 
        if (response.data.combo.combo_items && response.data.combo.combo_items.length > 0) {
          const products = response.data.combo.combo_items.map(item => ({
            product_name: item.group_wish_details?.product_name || "Unnamed Product",
            quantity: item.max_quantity || 1,
            variant: item.variant || '',
            image: item.group_wish_details?.image || '',  
            image_url: item.group_wish_details?.image_url || '',  
            price: item.group_wish_details?.list_price || 0
          }));
          setComboProducts(products);
        }
        if (response.data.combo) {
            const totalRating = response.data.combo.total_rating || 0;
            const ratingCount = response.data.combo.rating_count || 1;  
            setComboRating(ratingCount > 0 ? totalRating / ratingCount : 0);
        } 
        if (response.data.combo.expires_at) {
          const expiryDateTime = new Date(response.data.combo.expires_at);
          const formattedDate = expiryDateTime.toISOString().split('T')[0];
          setExpiryDate(formattedDate);
        } 
        if (response.data.group_wishes && response.data.group_wishes.length > 0) {
          const firstGroupWish = response.data.group_wishes[0];
          const actualRange = firstGroupWish.price_ranges?.find(range => range.actual_price_range === true);
          const nonActualRange = firstGroupWish.price_ranges?.find(range => range.actual_price_range === false);          
          if (actualRange) {
            setSelectedActualRange(actualRange.id);
          }          
          if (nonActualRange) {
            setSelectedNonActualRange(nonActualRange.id);
          }
        }
        if (response.data.combo.bumped_verified_user_count !== undefined) {
            setCombo(prevCombo => ({
              ...prevCombo,
              bumped_verified_user_count: response.data.combo.bumped_verified_user_count
            }));
          }          
        if (response.data.combo.bumped_unverified_user_count !== undefined) {
            setCombo(prevCombo => ({
              ...prevCombo,
              bumped_unverified_user_count: response.data.combo.bumped_unverified_user_count
            }));
        }          
        if (response.data.combo.bumped_verified_qty_count !== undefined) {
            setCombo(prevCombo => ({
              ...prevCombo,
              bumped_verified_qty_count: response.data.combo.bumped_verified_qty_count
            }));
          } 
        if (response.data.combo.bumped_unverified_qty_count !== undefined) {
            setCombo(prevCombo => ({
              ...prevCombo,
              bumped_unverified_qty_count: response.data.combo.bumped_unverified_qty_count
            }));
        }        
      } else {
        setErrorMessage('Failed to fetch combo details');
      }
    } catch (error) {
      setErrorMessage('An error occurred while fetching combo details');
      console.error('Error fetching combo details:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleExpiryDateChange = async () => {
    if (expiryDate === null) {
        setErrorMessage('Please select an expiry date before updating');
        return;
    }
    setIsLoading(true);
    setErrorMessage('');
    setSuccessMessage('');
    try {
      const url = buildUrl(`updateComboExpiryDate/${comboId}/`, queryLoginParam);
      const response = await postApi(url, {  
        expiry_date: new Date(new Date(expiryDate).setHours(23, 59, 59)).toISOString(), 
      });
      if (response) { 
        setSuccessMessage('Status, details, and date updated successfully');
        fetchComboDetails();
      } else {
        setErrorMessage('Failed to update status and details');
      }
    } catch (error) {
      setErrorMessage('An error occurred while updating status and details');
    } finally {
      setIsLoading(false);
    }
  };

  const handleRatingUpdate = async (newRating) => {
    setIsLoading(true);
    setErrorMessage('');
    setSuccessMessage('');
  
    try {
      const url = buildUrl(`updateComboRating/${comboId}/`, queryLoginParam);
      const response = await postApi(url, {
        rating: newRating
      });
  
      if (response) {
        setSuccessMessage('Rating updated successfully');
        setComboRating(newRating);
      } else {
        setErrorMessage('Failed to update rating');
      }
    } catch (error) {
      setErrorMessage('An error occurred while updating rating');
    } finally {
      setIsLoading(false);
    }
  };
 
  const handleBumpedUsersUpdate = async (bumpedVerifiedCount, bumpedVerifiedQty, bumpedUnverifiedCount, bumpedUnverifiedQty) => {
    setIsLoading(true);
    setErrorMessage('');
    setSuccessMessage('');
  
    try { 
      const payload = {}; 
      if (combo) { 
        if (bumpedVerifiedCount !== combo.bumped_verified_user_count) {
          payload.bumped_verified_count = bumpedVerifiedCount;
        }
        
        if (bumpedVerifiedQty !== combo.bumped_verified_qty_count) {
          payload.bumped_verified_qty = bumpedVerifiedQty;
        }
        
        if (bumpedUnverifiedCount !== combo.bumped_unverified_user_count) {
          payload.bumped_unverified_count = bumpedUnverifiedCount;
        }
        
        if (bumpedUnverifiedQty !== combo.bumped_unverified_qty_count) {
          payload.bumped_unverified_qty = bumpedUnverifiedQty;
        }
      } 
      if (Object.keys(payload).length > 0) { 
        const url = buildUrl(`updateComboBumpedUsers/${comboId}/`, queryLoginParam);
        const response = await postApi(url, payload);
        
        if (response) {
          setSuccessMessage('Bumped users updated successfully');
          fetchComboDetails(); 
        } else {
          setErrorMessage('Failed to update bumped users');
        }
      } else { 
        setSuccessMessage('No changes to update');
      }
    } catch (error) {
      console.error('Error updating bumped users:', error);
      setErrorMessage(`An error occurred while updating bumped users: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const canEditClosingOrderSize = (status) => {
    return ['MIN_MET'].includes(status) || 
           (combo?.combo_status === status && ['MIN_MET'].includes(status));
  };
 
  const handleClosingOrderCountChange = (e) => {
    const value = e.target.value;
    // Allow empty string or positive integers only
    if (value === '' || (/^\d+$/.test(value) && parseInt(value) > 0)) {
      setClosingOrderSize(value);
    }
  };

  const getAvailableStatusOptions = (currentStatus) => {
    // If the current status isn't found in our options, include all options
    if (!statusOptions.find(option => option.value === currentStatus)) {
      return statusOptions;
    }
    
    // Get transitions from current status
    const availableStatuses = STATUS_TRANSITIONS[currentStatus] || [];
    return statusOptions.filter(option => 
      option.value === currentStatus || availableStatuses.includes(option.value)
    );
  };

  const handleStatusChange = async (newStatus) => { 
    if (!statusOptions.find(option => option.value === newStatus)) {
      setErrorMessage(`Unknown status: ${newStatus}`);
      return;
    }    
    const availableStatuses = STATUS_TRANSITIONS[currentStatus] || [];
    if (!availableStatuses.includes(newStatus) && newStatus !== currentStatus) {
      setErrorMessage(`Invalid status transition from ${currentStatus} to ${newStatus}`);
      return;
    } 
    if (newStatus === 'MIN_MET') {
      if (!selectedActualRange || !selectedNonActualRange) {
        setErrorMessage('Please select both actual and customer facing price ranges before updating to MIN_MET status');
        return;
      }
    }  
    if ((newStatus === 'MIN_MET') && !closingOrderSize) {
      setErrorMessage('Please enter a closing order size before updating to this status');
      return;
    }

    setIsLoading(true);
    setErrorMessage('');
    setSuccessMessage('');
    
    try {
      const url = buildUrl(`updateComboOrderStatus/${comboId}/`, queryLoginParam);
      const requestBody = {}; 
    requestBody.status = newStatus; 
    if (closingOrderSize) {
      requestBody.closing_order_count = closingOrderSize;
    }    
    if (closingProductCost) {
      requestBody.closing_product_cost = closingProductCost;
    }         
    if (closingBacksAppCharges) {
      requestBody.closing_backsapp_charges = closingBacksAppCharges;
    } 
    const response = await postApi(url, requestBody);      
    if (response) {
        setCurrentStatus(newStatus);
        setSuccessMessage('Status and details updated successfully');
        fetchComboDetails();
      } else {
        setErrorMessage('Failed to update status and details');
      }
    } catch (error) {
      setErrorMessage('An error occurred while updating status and details');
    } finally {
      setIsLoading(false);
    }
  }; 

  const calculateTotalQuantity = (users) => { 
    if (!Array.isArray(users)) {
      console.warn('calculateTotalQuantity received non-array:', users);
      return 0;
    }
    return users.reduce((total, user) => total + (user.quantity || 0), 0);
  };
 
  const getTotalQuantity = (usersOrCount) => {
    if (typeof usersOrCount === 'number') {
      return usersOrCount;
    }    
    return calculateTotalQuantity(usersOrCount);
  };

  const canEditPriceRanges = (status) => {
    const editableStatuses = ['MIN_MET'];
    return editableStatuses.includes(status);
  };

  const handlePriceRangeSelection = (rangeId, isActual) => {
    if (!canEditPriceRanges(currentStatus)) {
      return;
    }

    const selectedRange = priceRanges.find(range => range.id === rangeId);
    if (selectedRange) {
      if (isActual) {
        setSelectedActualRange(rangeId);
      } else {
        setSelectedNonActualRange(rangeId);
      }
    }
  };
   
  const categorizeUsers = (comboData) => {
    if (!comboData) {
      return {
        verifiedUsers: [],
        unverifiedUsers: [],
        bumpedVerifiedUsers: [],
        bumpedUnverifiedUsers: [],
        verifiedQtyCount: 0,
        unverifiedQtyCount: 0,
        bumpedVerifiedQtyCount: 0,  
        bumpedUnverifiedQtyCount: 0  
      };
    } 
    
    // Create empty arrays for user types that we will display by their count
    const result = {
      // Use actual user arrays from the data or create empty ones
      verifiedUsers: comboData.verified_users || Array(comboData.verified_user_count || 0)
        .fill()
        .map(() => ({ quantity: 1 })),
      unverifiedUsers: comboData.unverified_users || Array(comboData.unverified_user_count || 0)
        .fill()
        .map(() => ({ quantity: 1 })),
      bumpedVerifiedUsers: Array(comboData.bumped_verified_user_count || 0)
        .fill()
        .map(() => ({ quantity: 1 })),
      bumpedUnverifiedUsers: Array(comboData.bumped_unverified_user_count || 0)
        .fill()
        .map(() => ({ quantity: 1 })),
      
      // Pass quantity counts directly as numbers
      verifiedQtyCount: comboData.verified_qty_count || 0,
      unverifiedQtyCount: comboData.unverified_qty_count || 0,
      bumpedVerifiedQtyCount: comboData.bumped_verified_qty_count || 0,  
      bumpedUnverifiedQtyCount: comboData.bumped_unverified_qty_count || 0   
    };
    
    return result;
  };

  const renderPriceRanges = () => {
    if (!combo || !priceRanges || priceRanges.length === 0) {
      return null;
    }

    return (
      <PriceRangesSelection
        priceRanges={priceRanges}
        onPriceRangeSelect={handlePriceRangeSelection}
        selectedActualRange={selectedActualRange}
        selectedNonActualRange={selectedNonActualRange}
        disabled={!canEditPriceRanges(currentStatus)}
      />
    );
  };

  // No separate renderGroupWishes function needed

  const renderProductsList = () => {
    if (!comboProducts || comboProducts.length === 0) {
      return null;
    }

const handleClosingOrderCountChange = (e) => {
    const value = e.target.value; 
    if (value === '' || (/^\d+$/.test(value) && parseInt(value) > 0)) {
        setClosingOrderSize(value);
    }
}; 

const canEditClosingOrderSize = (status) => {
    return ['MIN_MET'].includes(status) || (combo?.combo_status === status && ['MIN_MET'].includes(status));
};

    return (
      <div className="card">
        <div className="card-content">
          <h3 className="card-title">
            <FontAwesomeIcon icon={faBoxes} /> Products in Combo
          </h3>          
          <ul className="products-list">
            {comboProducts.map((product, index) => (
              <li key={index} className="product-item">
                <div className="product-row">
                  {(product.image || product.image_url) && (
                    <div className="product-image-container">
                      <img 
                        src={product.image || product.image_url} 
                        alt={product.product_name} 
                        className="product-image" 
                      />
                    </div>
                  )}
                  <div className="product-details">
                    <span className="product-name">{product.product_name}</span>
                    <div className="product-info-row">
                      <span className="product-quantity">Quantity: {product.quantity}</span>
                      {product.price && (
                        <span className="product-price">List Price: ${product.price}</span>
                      )}
                    </div>
                    {product.variant && (
                      <span className="product-variant">Variant: {product.variant}</span>
                    )}
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  };

  const handleConfirmWaitlistUsers = async (selectedUserIds) => {
    setIsLoading(true);
    setErrorMessage('');
    setSuccessMessage('');
    
    try {
      const url = buildUrl(`confirmComboWaitlistUsers/${comboId}/`, queryLoginParam);
      const response = await postApi(url, {
        user_ids: selectedUserIds
      });
      
      if (response) {
        setSuccessMessage('Selected users confirmed successfully');
        fetchComboDetails();  
      } else {
        setErrorMessage('Failed to confirm selected users');
      }
    } catch (error) {
      setErrorMessage('An error occurred while confirming users');
    } finally {
      setIsLoading(false);
    }
  };

  const getStatusLabel = (statusValue) => {
    const status = statusOptions.find(option => option.value === statusValue);
    return status ? status.label : statusValue;
  };

  if (isLoading) {
    return <div className="loading-container"><div className="spinner"></div></div>;
  }

  if (!combo) {
    return <div className="error-message">Combo order not found</div>;
  }

  return (
    <>
    <TopMenuBar showBackButton={true} showHomeButton={true} onBackClick={() => navigate('/orderManagement')} title="Combo Processing" showSearch={false} showAddress={false} showMenuButton={true}/>
    <div className="combo-processing-container">            
      <div className="content-wrapper">        
            <div className="left-column">
              <div className="card product-info-card">
                <div className="card-content">
                  <h2 className="card-name-title">{combo.title}</h2>
                  {errorMessage && <div className="error-message">{errorMessage}</div>}
                  {successMessage && <div className="success-message">{successMessage}</div>}
                  
                  <div className="product-details"> 
                    <p><strong>Current Status:</strong> {getStatusLabel(currentStatus)}</p>
                    <p><strong>Minimum Combo Size:</strong> {combo.min_combo_size}</p>
                    <p><strong>Bundle MRP:</strong> ${combo.bundle_mrp}</p>
                    <p><strong>Bundle Rate:</strong> ${combo.bundle_rate}</p>
                    <p><strong>BacksApp Commission Type:</strong> {combo.commission_type || 'N/A'}</p>
                    <p><strong>BacksApp Commission Value:</strong> {combo.commission_value || 'N/A'}</p>
                    <p><strong>Shipping Cost Estimate:</strong> ${combo.shipping_cost_estimate || 'N/A'}</p>
                    <p><strong>Verification Score:</strong> {combo.verification_score}%</p>
                    <p>
                      <strong>Expiry Date:</strong>{' '}
                      {isEditingExpiry ? (
                        <div className="expiry-edit-container">
                          <input
                            type="date"
                            value={expiryDate}
                            onChange={(e) => setExpiryDate(e.target.value)}
                            className="date-input"/>
                          <button className="save-date-button"
                            onClick={() => {
                              handleExpiryDateChange();
                              setIsEditingExpiry(false);
                            }}
                          >
                            Save
                          </button>
                          <button 
                            className="cancel-date-button"
                            onClick={() => {
                              const cancelDateTime = new Date(combo.expires_at);
                              const localCancelDate = new Date(cancelDateTime.getTime() + cancelDateTime.getTimezoneOffset() * 60000);
                              setExpiryDate(localCancelDate.toISOString().split('T')[0]);
                              setIsEditingExpiry(false);
                            }}
                          >
                            Cancel  
                          </button>
                        </div>
                      ) : (
                        <span>
                          {new Date(combo.expires_at).toLocaleDateString()}{' '}
                          <button 
                            className="edit-date-button"
                            onClick={() => {
                              const formattedDate = new Date(combo.expires_at).toISOString().split('T')[0];
                              setExpiryDate(formattedDate);
                              setIsEditingExpiry(true);
                            }}
                            >
                            Edit
                          </button>
                        </span>
                      )}
                    </p>
                  </div>
                </div>
              </div>

              {/* No separate group wishes section needed */}

              {renderProductsList()}

              <div className="card">
                <div className="card-content">
                  <h3 className="card-title">Status</h3>
                  <select value={currentStatus} onChange={(e) => setCurrentStatus(e.target.value)} className="select-status">
                    {getAvailableStatusOptions(currentStatus).map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>  
                <div className="card-content">
                  <h3 className="card-title">Closing Order Size</h3>
                  <input
                    type="text"
                    value={closingOrderSize}
                    onChange={handleClosingOrderCountChange}
                    disabled={!canEditClosingOrderSize(currentStatus)}
                    placeholder="Enter closing order size"
                    className="input-field"
                  />
                </div>
              </div>   
              <div>
                {renderPriceRanges()}               
              </div>                               
            </div>                            
            <div className="right-column">
              {comboUsers && (
                <>
                  <UserStatsDisplay {...categorizeUsers(combo)} onBumpedUsersUpdate={handleBumpedUsersUpdate} rating={comboRating} onRatingUpdate={handleRatingUpdate} isGroupWish={false}/>
                  <WaitlistSection waitlistUsers={waitlistUsers} onConfirmBuyers={handleConfirmWaitlistUsers} />
                </>
              )}
            </div> 
      </div>
      <div className="update-button-wrapper">
        <button onClick={() => handleStatusChange(currentStatus)} className="update-button">
          Update Status and Details
        </button>
      </div>
    </div> 
    </>   
  );  
}

const styles = `
  .combo-processing-container {
    font-family: Arial, sans-serif; 
    min-height: 100vh;
    background-color: ${style.Color.colorWhite};
    margin: '4% auto',
  }

  .content-wrapper {
    display: flex;
    gap: 20px;
    padding: 20px;
  }

  .left-column {
    flex: 2;
    background-color: ${style.Color.colorWhite};
  }

  .right-column {
    flex: 1;
  }

  .card {
    margin-top:70px;
    background-color: ${style.Color.colorLightBeige};
    border-radius: 8px;
    margin-bottom: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .card-content {
    padding: 20px;
  }

  .card-name-title {
    font-size: 24px;
    font-weight: bold;
    color: ${style.Color.colorPurple};
    margin-bottom: 15px;
  }

  .message-container {
    position: fixed;
    top: 100;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.7);
    padding: 10px;
    padding-top: 40px;
    z-index: 999;
  }

  .success-message {
    color: #4CAF50;
    font-size: 16px;
    text-align: center;
    margin: 0;
  }

  .error-message {
    color: #F44336;
    font-size: 16px;
    text-align: center;
    margin: 0;
  }

  .card-title {
    font-size: 20px;
    font-weight: bold;
    color: ${style.Color.colorDarkBlue};
    margin-bottom: 15px;
  }

  .product-details p {
    margin-bottom: 10px;
    color: ${style.Color.colorDarkBlue};
  }

  .select-status {
    width: 100%;
    padding: 20px;
    margin-bottom: 10px;
    border: 1px solid ${style.Color.colorBlueGrey};
    border-radius: 8px;
    font-size: 16px;
  }

  .input-field-container {
    margin-bottom: 15px;
  }

  .input-label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: ${style.Color.colorDarkBlue};
  }

  .input-field {
    width: 100%;
    padding: 10px;
    border: 1px solid ${style.Color.colorAppleGreen};
    border-radius: 4px;
    font-size: 16px;
  }

  .input-field.read-only {
    background-color: ${style.Color.colorLightestBeige};
    color: ${style.Color.colorDarkGrey};
  }

  .update-button {
    background-color: ${style.Color.colorMintGreen};
    color: white;
    font-weight: bold;
    border: none;
    padding: 10px 20px;
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    transition: background-color 0.3s;
    width: 30%;
    align-items: center
    margin: 20px 0; 
    margin-bottom: 20px 0; 
  }

  .update-button:hover {
    background-color: ${style.Color.colorAppleGreen};
  }

  .loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
 
  .spinner {
    border: 4px solid #f3f3f3;
    border-top: 4px solid ${style.Color.colorPurple};
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  .user-list-container {
    margin-top:70px;
    background-color: ${style.Color.colorWhite};
    border-radius: 8px;
    margin-bottom: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
  }

  .user-list-title {
    font-size: 18px;
    font-weight: bold;
    color: ${style.Color.colorDarkBlue};
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .user-list {
    list-style-type: none;
    padding: 0;
  }

  .user-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid ${style.Color.colorLightGrey};
  }

  .user-details {
    display: flex;
    flex-direction: column;
  }

  .user-name {
    font-weight: bold;
    color: ${style.Color.colorDarkBlue};
  }

  .user-email {
    font-size: 14px;
    color: ${style.Color.colorDarkGrey};
  }

  .user-quantity {
    font-weight: bold;
    color: ${style.Color.colorPurple};
    margin: 5px 0;
  }

  .no-users {
    color: ${style.Color.colorDarkGrey};
    font-style: italic;
  }

  .products-list {
    list-style-type: none;
    padding: 0;
  }

  .product-item {
    padding: 15px;
    border-bottom: 1px solid ${style.Color.colorLightGrey};
    background-color: ${style.Color.colorWhite};
    border-radius: 4px;
    margin-bottom: 10px;
  }

  .product-row {
    display: flex;
    gap: 15px;
  }

  .product-image-container {
    flex-shrink: 0;
    width: 80px;
    height: 80px;
    overflow: hidden;
    border-radius: 4px;
    border: 1px solid ${style.Color.colorLightGrey};
  }

  .product-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .combo-image-container {
    width: 100%;
    max-height: 200px;
    overflow: hidden;
    border-radius: 8px;
    margin-bottom: 15px;
    text-align: center;
  }

  .combo-image {
    max-width: 100%;
    max-height: 200px;
    object-fit: contain;
  }

  .product-details {
    display: flex;
    flex-direction: column;
    gap: 5px;
    flex: 1;
  }

  .product-info-row {
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
  }

  .product-name {
    font-weight: bold;
    color: ${style.Color.colorDarkBlue};
    font-size: 16px;
  }

  .product-quantity {
    color: ${style.Color.colorPurple};
  }

  .product-price {
    color: ${style.Color.colorDarkBlue};
    font-weight: bold;
  }

  .product-variant {
    color: ${style.Color.colorDarkGrey};
    font-style: italic;
  }

  .price-ranges-container {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    margin: 15px 0;
  }

  .price-range-item {
    background-color: ${style.Color.colorLightestBeige};
    border-radius: 8px;
    padding: 15px;
    width: calc(50% - 7.5px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    position: relative;
    cursor: pointer;
    transition: all 0.3s ease;
  }

  .price-range-item:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
  }

  .price-range-item.selected {
    background-color: ${style.Color.colorLightMintGreen};
    border: 2px solid ${style.Color.colorMintGreen};
  }

  .price-range-header {
    font-weight: bold;
    margin-bottom: 10px;
    color: ${style.Color.colorDarkBlue};
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .price-range-details {
    margin-bottom: 10px;
  }

  .price-detail {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    gap: 8px;
  }

  .price-detail svg {
    color: ${style.Color.colorMintGreen};
  }

  .price-range-footer {
    font-style: italic;
    color: ${style.Color.colorDarkGrey};
    font-size: 14px;
    margin-top: 8px;
  }

  .selected-indicator {
    position: absolute;
    top: 10px;
    right: 10px;
    color: ${style.Color.colorMintGreen};
  }

  .expiry-edit-container {
    display: inline-flex;
    gap: 10px;
    align-items: center;
    margin-top: 5px;
  }

  .date-input {
    padding: 5px 10px;
    border: 1px solid ${style.Color.colorLightGrey};
    border-radius: 4px;
    font-size: 14px;
  }

  .edit-date-button {
    background-color: ${style.Color.colorPurple};
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 12px;
  }

  .edit-date-button:hover {
    background-color: ${style.Color.colorDarkPurple};
  }

  .save-date-button {
    background-color: ${style.Color.colorMintGreen};
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 12px;
  }

  .save-date-button:hover {
    background-color: ${style.Color.colorDarkMintGreen};
  }

  .cancel-date-button {
    background-color: ${style.Color.colorDarkGrey};
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 12px;
  }

  .cancel-date-button:hover {
    background-color: ${style.Color.colorDarkerGrey};
  }

  .price-range-item {
    width: 100%;
  }

  .expiry-edit-container {
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
  }
  
  .update-button-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 20px;
    margin-top: 20px;
  }

  .update-button {
    background-color: ${style.Color.colorMintGreen};
    color: white;
    font-weight: bold;
    border: none;
    padding: 16px 32px;
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.3s ease;
    min-width: 250px;
    text-align: center;
  }

  .update-button:hover {
    background-color: ${style.Color.colorAppleGreen};
    transform: translateY(-1px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .update-button:active {
    transform: translateY(0);
    box-shadow: none;
  }

  .update-button:disabled {
    background-color: ${style.Color.colorLightGrey};
    cursor: not-allowed;
    transform: none;
    box-shadow: none;
  }

  @media (max-width: 768px) {
    .content-wrapper {
      flex-direction: column;
    }
  }
`;

// Add styles to the document
const styleSheet = document.createElement("style");
styleSheet.type = "text/css";
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);

export default ComboProcessing;