import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as style from '../../../GlobalStyles';
import { postApi, buildUrl } from "../../Common/BackendCalls/apiUtils";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';

const ApproveComboModal = ({ combo, isOpen, onClose, onSuccess, queryLoginParam }) => {
  const [verificationScore, setVerificationScore] = useState(5);
  const [isVerified, setIsVerified] = useState(false);
  const [isShippingOptimized, setIsShippingOptimized] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  if (!isOpen) return null;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setErrorMessage('');

    try {
      const approveURL = buildUrl(`approveCombo/${combo.id}`, queryLoginParam);
      const response = await postApi(approveURL, {
        verification_score: verificationScore,
        is_verified: isVerified,
        is_shipping_optimized: isShippingOptimized
      });
      
      if (response.ok) {
        onSuccess(`Combo "${combo.title}" approved successfully!`);
        onClose();
      } else {
        setErrorMessage(response.message || 'Failed to approve combo. Please try again.');
      }
    } catch (error) {
      //console.error('Error approving combo:', error);
      setErrorMessage('An unexpected error occurred. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div style={overlayStyle}>
      <div style={modalStyle}>
        <div style={headerStyle}>
          <h2 style={titleStyle}>Approve Combo</h2>
          <button style={closeButtonStyle} onClick={onClose}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>

        <div style={contentStyle}>
          <h3 style={comboTitleStyle}>{combo.title}</h3>
          
          <form onSubmit={handleSubmit}>
            <div style={formGroupStyle}>
              <label style={labelStyle}>
                Verification Score (0-100):
                <input
                  type="number"
                  min="0"
                  max="100"
                  value={verificationScore}
                  onChange={(e) => setVerificationScore(Number(e.target.value))}
                  style={inputStyle}
                  required
                />
              </label>
            </div>
            
            <div style={formGroupStyle}>
              <label style={checkboxLabelStyle}>
                <input
                  type="checkbox"
                  checked={isVerified}
                  onChange={(e) => setIsVerified(e.target.checked)}
                  style={checkboxStyle}
                />
                Mark as Verified
              </label>
            </div>
            
            <div style={formGroupStyle}>
              <label style={checkboxLabelStyle}>
                <input
                  type="checkbox"
                  checked={isShippingOptimized}
                  onChange={(e) => setIsShippingOptimized(e.target.checked)}
                  style={checkboxStyle}
                />
                Is shipping optimized?
              </label>
            </div>
            
            {errorMessage && <p style={errorStyle}>{errorMessage}</p>}
            
            <div style={buttonContainerStyle}>
              <button
                type="button"
                onClick={onClose}
                style={cancelButtonStyle}
                disabled={isLoading}
              >
                Cancel
              </button>
              <button
                type="submit"
                style={submitButtonStyle}
                disabled={isLoading}
              >
                {isLoading ? (
                  <span style={spinnerStyle}></span>
                ) : (
                  <>
                    <FontAwesomeIcon icon={faCheck} /> Approve
                  </>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

ApproveComboModal.propTypes = {
  combo: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  queryLoginParam: PropTypes.string.isRequired
};

// Styles
const overlayStyle = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 1000,
};

const modalStyle = {
  backgroundColor: style.Color.colorWhite,
  borderRadius: '8px',
  boxShadow: '0 5px 15px rgba(0, 0, 0, 0.3)',
  width: '90%',
  maxWidth: '500px',
  maxHeight: '90vh',
  overflow: 'auto',
  position: 'relative',
};

const headerStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '15px 20px',
  borderBottom: `1px solid ${style.Color.colorLightGrey}`,
};

const titleStyle = {
  margin: 0,
  color: style.Color.colorRoyalBlueText,
  fontSize: '20px',
  fontWeight: 'bold',
};

const closeButtonStyle = {
  background: 'none',
  border: 'none',
  fontSize: '20px',
  cursor: 'pointer',
  color: style.Color.colorDarkGrey,
};

const contentStyle = {
  padding: '20px',
};

const comboTitleStyle = {
  fontSize: '16px',
  marginBottom: '20px',
  color: style.Color.colorDarkGrey,
};

const formGroupStyle = {
  marginBottom: '20px',
};

const labelStyle = {
  display: 'block',
  marginBottom: '5px',
  fontWeight: 'bold',
  color: style.Color.colorRoyalBlueText,
};

const inputStyle = {
  width: '100%',
  padding: '10px',
  border: `1px solid ${style.Color.colorLightGrey}`,
  borderRadius: '4px',
  fontSize: '14px',
  marginTop: '5px',
};

const checkboxLabelStyle = {
  display: 'flex',
  alignItems: 'center',
  fontWeight: 'bold',
  color: style.Color.colorRoyalBlueText,
  cursor: 'pointer',
};

const checkboxStyle = {
  marginRight: '10px',
  width: '18px',
  height: '18px',
  accentColor: style.Color.colorMintGreen,
};

const errorStyle = {
  color: '#721c24',
  backgroundColor: '#f8d7da',
  padding: '10px',
  borderRadius: '4px',
  marginBottom: '20px',
};

const buttonContainerStyle = {
  display: 'flex',
  justifyContent: 'flex-end',
  gap: '10px',
  marginTop: '20px',
};

const cancelButtonStyle = {
  padding: '10px 15px',
  border: `1px solid ${style.Color.colorLightGrey}`,
  borderRadius: '4px',
  backgroundColor: style.Color.colorWhite,
  color: style.Color.colorDarkGrey,
  cursor: 'pointer',
  fontSize: '14px',
  fontWeight: 'bold',
};

const submitButtonStyle = {
  padding: '10px 15px',
  border: 'none',
  borderRadius: '4px',
  backgroundColor: style.Color.colorMintGreen,
  color: style.Color.colorWhite,
  cursor: 'pointer',
  fontSize: '14px',
  fontWeight: 'bold',
  display: 'flex',
  alignItems: 'center',
  gap: '5px',
};

const spinnerStyle = {
  display: 'inline-block',
  width: '16px',
  height: '16px',
  border: '2px solid rgba(255, 255, 255, 0.3)',
  borderRadius: '50%',
  borderTopColor: style.Color.colorWhite,
  animation: 'spin 0.8s linear infinite',
};

export default ApproveComboModal;