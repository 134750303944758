import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { 
  Shield, 
  Trash2, 
  Clock, 
  CheckCircle, 
  FileText, 
  Lock,
  Database,
  AlertCircle,
  Mail,
  UserX,
  ScrollText,
  Eye
} from 'lucide-react';
import Footer from './Footer';

const DataDeletionPage = () => {
  const [formData, setFormData] = useState({
    email: '',
    userId: '',
    reason: '',
    confirmDelete: false
  });
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setErrorMessage(null);
    setSuccessMessage(null);

    if (!formData.confirmDelete) {
      setErrorMessage('Please confirm that you understand the data deletion process.');
      setIsSubmitting(false);
      return;
    }

    try {
      // This would be your actual API endpoint
      const response = await fetch('/api/data-deletion-request', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error('Failed to submit deletion request');
      }

      setSuccessMessage('Your data deletion request has been submitted successfully. You will receive a confirmation email shortly.');
      setFormData({
        email: '',
        userId: '',
        reason: '',
        confirmDelete: false
      });
    } catch (error) {
      setErrorMessage('Failed to submit your request. Please try again or contact our support team directly.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const sections = [
    {
      icon: Shield,
      title: "Data Deletion Policy",
      content: "BacksApp is committed to protecting your privacy and providing you with control over your personal data. This page outlines the process for requesting deletion of your data in accordance with various privacy regulations including GDPR and CCPA.",
      subsections: [
        "This page serves as our official Data Deletion Request Callback URL for Facebook's Platform requirements",
        "We respect your right to control your personal information",
        "All deletion requests are processed in compliance with applicable privacy laws",
        "Our team is available to assist with any questions about the data deletion process"
      ]
    },
    {
      icon: Database,
      title: "What Data Will Be Deleted",
      content: "Upon verification of your request, we will delete the following data associated with your account:",
      subsections: [
        "Your profile information including name, email, and contact details",
        "Your activity history and interactions within the BacksApp platform",
        "Your payment information and transaction history",
        "Any GroupWishes you've created or participated in",
        "Facebook-connected data including login information and permissions granted",
        "Preferences, settings, and personalization data"
      ]
    },
    {
      icon: Clock,
      title: "Data Deletion Timeline",
      content: "We are committed to processing your request in a timely manner:",
      subsections: [
        "Initial confirmation of your request within 2 business days",
        "Data deletion completed within 30 days of verification",
        "Final confirmation email sent once deletion is complete",
        "Some information may be retained if required by law, which will be clearly communicated",
        "Backup data may take up to 90 days to be fully removed from all systems"
      ]
    },
    {
      icon: ScrollText,
      title: "Deletion Request Process",
      content: "The data deletion process follows these steps:",
      subsections: [
        "Submit the data deletion request form below with your account information",
        "Receive a verification email to confirm your identity and request",
        "Our team reviews and processes your request",
        "Your data is permanently deleted from our active systems",
        "You receive confirmation once the deletion is complete"
      ]
    },
    {
      icon: AlertCircle,
      title: "Important Considerations",
      content: "Before requesting data deletion, please note:",
      subsections: [
        "Data deletion is permanent and cannot be reversed",
        "Your account will be deactivated and you will lose access to all BacksApp services",
        "Any active GroupWishes you've created will be closed and participants notified",
        "Funds in pending transactions may need to be processed before deletion can be completed",
        "Some anonymized data may be retained for analytics purposes"
      ]
    },
    {
      icon: Lock,
      title: "Your Privacy Rights",
      content: "In addition to data deletion, you have the following rights:",
      subsections: [
        "Right to access your personal data",
        "Right to correct inaccurate information",
        "Right to restrict processing in certain circumstances",
        "Right to object to processing of your data",
        "Right to data portability where technically feasible"
      ]
    },
    {
      icon: Eye,
      title: "Alternative Options",
      content: "If you're considering data deletion, you might want to explore these alternatives:",
      subsections: [
        "Account deactivation - temporarily suspends your account without deleting data",
        "Limited data removal - delete specific information while maintaining your account",
        "Preference updates - adjust your privacy settings and marketing preferences",
        "Data access request - review what information we have before deciding to delete"
      ]
    }
  ];

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white">
      {/* Navigation Bar */}
      <nav className="fixed top-0 w-full bg-white/95 backdrop-blur-sm shadow-sm z-50">
        <div className="max-w-7xl mx-auto px-4">
          <div className="flex items-center justify-between h-16">
            <div className="flex items-center space-x-8">
              <Link to="/" className="text-xl font-bold bg-gradient-to-r from-amber-600 to-rose-800 bg-clip-text text-transparent">
                BacksApp
              </Link>
              <div className="hidden md:flex space-x-6">
                <Link to="/" className="text-gray-600 hover:text-amber-600 transition-colors">Home</Link>
                <Link to="/about" className="text-gray-600 hover:text-amber-600 transition-colors">About</Link>
                <Link to="/terms" className="text-gray-600 hover:text-amber-600 transition-colors">Terms</Link>
              </div>
            </div>
          </div>
        </div>
      </nav>

      {/* Main Content */}
      <div className="pt-32 pb-16">
        <div className="max-w-4xl mx-auto px-4">
          <div className="text-center mb-12">
            <h1 className="text-4xl font-bold mb-4 bg-gradient-to-r from-amber-600 to-rose-800 bg-clip-text text-transparent">
              Data Deletion Request
            </h1>
            <p className="text-gray-600">Last updated: March 24, 2025</p>
          </div>

          {errorMessage && (
            <div className="bg-rose-50 border border-rose-200 text-rose-700 px-4 py-3 rounded-lg mb-6 fade-in-up">
              {errorMessage}
            </div>
          )}
          
          {successMessage && (
            <div className="bg-green-50 border border-green-200 text-green-700 px-4 py-3 rounded-lg mb-6 fade-in-up">
              {successMessage}
            </div>
          )}

          <div className="space-y-12">
            {sections.map((section, index) => (
              <div key={index} className="bg-white rounded-lg shadow-md hover:shadow-lg transition-duration-300 p-8 fade-in-up">
                <div className="flex items-start space-x-4">
                  <section.icon className="w-6 h-6 text-amber-600 flex-shrink-0 mt-1" />
                  <div className="flex-1">
                    <h2 className="text-xl font-semibold mb-4 text-gray-800">
                      {index + 1}. {section.title}
                    </h2>
                    <p className="text-gray-700 mb-4 leading-relaxed">
                      {section.content}
                    </p>
                    <ul className="space-y-2 text-gray-700 pl-5 list-disc">
                      {section.subsections.map((item, i) => (
                        <li key={i} className="leading-relaxed">{item}</li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            ))}

            {/* Request Form */}
            <div className="bg-white rounded-lg shadow-md hover:shadow-lg transition-duration-300 p-8 fade-in-up">
              <div className="flex items-start space-x-4">
                <UserX className="w-6 h-6 text-amber-600 flex-shrink-0 mt-1" />
                <div className="flex-1">
                  <h2 className="text-xl font-semibold mb-4 text-gray-800">Submit Deletion Request</h2>
                  <p className="text-gray-700 mb-6">
                    Please complete this form to request deletion of your BacksApp account and associated data. 
                    All fields are required to process your request.
                  </p>
                  
                  <form onSubmit={handleSubmit} className="space-y-6">
                    <div>
                      <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
                        Email Address
                      </label>
                      <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                        className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-amber-500 focus:border-amber-500"
                        placeholder="Your registered email address"
                      />
                    </div>
                    
                    <div>
                      <label htmlFor="userId" className="block text-sm font-medium text-gray-700 mb-1">
                        User ID or Username (if known)
                      </label>
                      <input
                        type="text"
                        id="userId"
                        name="userId"
                        value={formData.userId}
                        onChange={handleChange}
                        className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-amber-500 focus:border-amber-500"
                        placeholder="Optional if you know your user ID"
                      />
                    </div>
                    
                    <div>
                      <label htmlFor="reason" className="block text-sm font-medium text-gray-700 mb-1">
                        Reason for Deletion (Optional)
                      </label>
                      <textarea
                        id="reason"
                        name="reason"
                        value={formData.reason}
                        onChange={handleChange}
                        rows="3"
                        className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-amber-500 focus:border-amber-500"
                        placeholder="This helps us improve our services"
                      ></textarea>
                    </div>
                    
                    <div className="flex items-start">
                      <div className="flex items-center h-5">
                        <input
                          id="confirmDelete"
                          name="confirmDelete"
                          type="checkbox"
                          checked={formData.confirmDelete}
                          onChange={handleChange}
                          required
                          className="h-4 w-4 text-amber-600 border-gray-300 rounded focus:ring-amber-500"
                        />
                      </div>
                      <div className="ml-3 text-sm">
                        <label htmlFor="confirmDelete" className="text-gray-700">
                          I understand that by requesting data deletion, my account will be permanently removed 
                          and all data associated with it will be deleted according to the policy described above.
                        </label>
                      </div>
                    </div>
                    
                    <div>
                      <button
                        type="submit"
                        disabled={isSubmitting}
                        className="w-full scale-on-hover px-4 py-3 bg-gradient-to-r from-amber-600 to-rose-800 text-white font-medium rounded-lg hover:opacity-90 transition-all disabled:opacity-50 disabled:cursor-not-allowed"
                      >
                        {isSubmitting ? 'Processing...' : 'Submit Deletion Request'}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            {/* Contact Section */}
            <div className="bg-white rounded-lg shadow-md hover:shadow-lg transition-duration-300 p-8 fade-in-up">
              <div className="flex items-start space-x-4">
                <Mail className="w-6 h-6 text-amber-600 flex-shrink-0 mt-1" />
                <div>
                  <h2 className="text-xl font-semibold mb-4 text-gray-800">Contact Us</h2>
                  <p className="text-gray-700 mb-4">
                    If you have any questions about data deletion or need assistance with your request, please contact us at:
                  </p>
                  <address className="text-gray-700 not-italic">
                    Infoply Inc<br />
                    California, CA<br />
                    privacy@backsapp.com<br />
                    510-771-7866
                  </address>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Footer */}
      <Footer />

      <style>{`
        @keyframes fadeInUp {
          from {
            opacity: 0;
            transform: translateY(20px);
          }
          to {
            opacity: 1;
            transform: translateY(0);
          }
        }

        .fade-in-up {
          animation: fadeInUp 0.5s ease-out forwards;
        }

        .scale-on-hover {
          transition: transform 0.3s ease;
        }

        .scale-on-hover:hover:not(:disabled) {
          transform: scale(1.02);
        }
      `}</style>
    </div>
  );
};

export default DataDeletionPage;