import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPlus, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import { TopMenuBar } from '../../Buyers/DashbordComponents/TopMenuBar';
import { useAuth } from '../../Common/InitialGears/AuthContext';
import * as style from '../../../GlobalStyles';
import { getApi, postApi, buildUrl } from "../../Common/BackendCalls/apiUtils"; 
import PackagingOptimizer from '../../Common/PackagingShipping/PackagingOptimizer'; 

const statusOptions = [
  { value: "NOT_APPLICABLE", label: "Not Applicable Status" },
  { value: "DRAFT", label: "Draft" },
  { value: "SEEKING_SELLER", label: "Seeking Seller" },
  { value: "DEAL_UNDER_DISCUSSION", label: "Deal Under Discussion" },
  { value: "PROPOSAL_REVIEW_READY", label: "Proposal Ready for Review" },
  { value: "PROPOSAL_AGREED", label: "Proposal Agreed" },
  { value: "PROPOSAL_ACCEPTED", label: "Proposal Accepted" },
  { value: "PROPOSAL_REOPENED", label: "Proposal Reopened" },
  { value: "PROPOSAL_TERMINATED", label: "Proposal Terminated" }
];

const filterWishes = (wishes, query, category, productType) => {
  if (!Array.isArray(wishes)) {
    return [];
  }

  const normalize = (str) => str?.toLowerCase().replace(/[^a-z0-9]/g, '') || '';
  const partialMatch = (full, partial) => {
    if (!full || !partial) return false;
    return normalize(full).includes(normalize(partial));
  };

  return wishes.filter(wish => {
    const matchesQuery = !query ||
      partialMatch(wish.deal_title, query) ||
      (wish.category && wish.category.some(cat => partialMatch(cat, query))) ||
      (wish.product_type && wish.product_type.some(type => partialMatch(type, query)));

    const matchesCategory = !category ||
      (wish.category && wish.category.some(cat => partialMatch(cat, category)));

    const matchesProductType = !productType ||
      (wish.product_type && wish.product_type.some(type => partialMatch(type, productType)));

    return matchesQuery && matchesCategory && matchesProductType;
  });
};
 
const GroupWishesCombo = () => {
  const auth = useAuth();
  const { queryLoginParam, idTokenLoggedIn } = auth;
  const navigate = useNavigate();
  const location = useLocation(); 
  const sourceScreen = location.state?.sourceScreen || 'direct';  
  const comboId = location.state?.comboId || null;
  const initialSelectedWishes = location.state?.selectedWishes || [];

  const [comboWishes, setComboWishes] = useState([]);
  const [filteredWishes, setFilteredWishes] = useState([]);
  const [refreshing, setRefreshing] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [filterCategory, setFilterCategory] = useState('');
  const [selectedWishes, setSelectedWishes] = useState(initialSelectedWishes);
  const [filterProductType, setFilterProductType] = useState('');
  const [userVerified, setUserVerified] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [statusUpdating, setStatusUpdating] = useState({});
  const [showPackagingOptimizer, setShowPackagingOptimizer] = useState(false);

  // Determine if we need to show resume button based on source
  const showResumeButton = sourceScreen === 'create' || sourceScreen === 'edit';

  const handleCreateCombo = () => {
    if (selectedWishes.length === 0) {
      setErrorMessage('Please select at least one group wish to create a combo');
      return;
    }
    navigate('/createCombo', { state: { selectedWishes } });
  };

  const handleResumeCombo = () => {
    if (sourceScreen === 'create') {
      navigate('/createCombo', { state: { selectedWishes } });
    } else if (sourceScreen === 'edit') {
      navigate(`/editCombo/${comboId}`, { state: { selectedWishes } });
    }
  };

  const fetchGroupWishesForCombo = useCallback(async () => {
    setIsLoading(true);
    try {
      setRefreshing(true);
      const comboWishesUrl = buildUrl('myGroupWishes', `${queryLoginParam}&type=comboListing`);
      const response = await getApi(comboWishesUrl);
      
      if (response.ok) {
        const data = response.data;
        setUserVerified(data.is_verified_user);
        if (data.combo_group_wishes) {
          setComboWishes(data.combo_group_wishes);
          setFilteredWishes(data.combo_group_wishes);
          setSearchQuery('');
          setFilterCategory('');
          setFilterProductType('');
        }
      } else {
        if (response.status === 423) {
          setErrorMessage('Your session has expired. Please log in again.');
          navigate("/");
        }
      }
    } catch (error) {
      setErrorMessage('Failed to fetch combo wishes. Please try again.');
    } finally {
      setRefreshing(false);
      setIsLoading(false);
    }
  }, [queryLoginParam, navigate]);

  useEffect(() => {
    fetchGroupWishesForCombo();
  }, [fetchGroupWishesForCombo]);
  
  // Re-fetch data when navigating back from create/edit to ensure latest data
  const locationKey = location.key;
  useEffect(() => {
    // This effect will run when the location key changes (navigation occurs)
    fetchGroupWishesForCombo();
  }, [locationKey, fetchGroupWishesForCombo]);

  useEffect(() => {
    let timer;
    if (successMessage) {
      timer = setTimeout(() => {
        setSuccessMessage('');
      }, 7000);
    }
    return () => clearTimeout(timer);
  }, [successMessage]);

  useEffect(() => {
    setFilteredWishes(filterWishes(comboWishes, searchQuery, filterCategory, filterProductType));
  }, [comboWishes, searchQuery, filterCategory, filterProductType]);

  const handleSearch = (event) => {
    const query = event.target.value.trim();
    setSearchQuery(query);
  };

  const handleCategoryFilter = (event) => {
    const category = event.target.value.trim();
    setFilterCategory(category);
  };

  const handleProductTypeFilter = (event) => {
    const productType = event.target.value.trim();
    setFilterProductType(productType);
  };

  const handleDelete = useCallback((wish) => {
    if (window.confirm("Are you sure you want to delete this combo wish?")) {
      const deleteWish = async () => {
        try {
          const deleteURL = buildUrl(`deleteComboWish/${wish.id}/`, queryLoginParam);
          const response = await postApi(deleteURL, {});
          
          if (response.ok) {
            fetchGroupWishesForCombo();
            setSuccessMessage(`${wish.product_name} deleted`);
            // Remove from selected wishes if it was selected
            setSelectedWishes(prev => prev.filter(w => w.id !== wish.id));
          } else {
            setErrorMessage('Failed to delete the combo wish. Please try again.');
          }
        } catch (error) {
          setErrorMessage('An error occurred while deleting the combo wish.');
        }
      };
      deleteWish();
    }
  }, [fetchGroupWishesForCombo, queryLoginParam]);

  const handleStatusChange = useCallback(async (wish, newStatus) => {
    if (statusUpdating[wish.id]) return;
    
    setStatusUpdating(prev => ({ ...prev, [wish.id]: true }));
    setErrorMessage('');
    setSuccessMessage('');
    
    try {
      const updateStatusURL = buildUrl(`updateComboWishStatus/${wish.id}/`, queryLoginParam);
      const response = await postApi(updateStatusURL, {
        status: newStatus
      });
      
      if (response.ok) {
        setSuccessMessage(`Status updated for ${wish.product_name}`);
        fetchGroupWishesForCombo();
      } else {
        setErrorMessage('Failed to update status. Please try again.');
      }
    } catch (error) {
      setErrorMessage('An error occurred while updating status.');
    } finally {
      setStatusUpdating(prev => ({ ...prev, [wish.id]: false }));
    }
  }, [queryLoginParam, fetchGroupWishesForCombo]);

  GroupWishesCombo.propTypes = {
    onWishSelect: PropTypes.func
  };
  
  GroupWishesCombo.defaultProps = {
    onWishSelect: () => {}
  };

  const handleWishSelect = useCallback((wish) => {
    const isWishSelected = selectedWishes.some(w => w.id === wish.id);
    setSelectedWishes(prev => {
      const newSelected = isWishSelected ? 
        prev.filter(w => w.id !== wish.id) : 
        [...prev, wish];
      return newSelected;
    });
  }, [selectedWishes]);
 
  const renderWishItem = (wish) => {
    const isUpdating = statusUpdating[wish.id];
    const isSelected = selectedWishes.some(w => w.id === wish.id);
    const specs = wish.product_specifications;      
    return (
        <div key={wish.uuid} style={wishItemStyle}>
          <input
            type="checkbox"
            checked={isSelected}
            onChange={() => handleWishSelect(wish)}
            disabled={isUpdating}
            style={checkboxStyle}
          />
          {wish.image ? (
            <img src={wish.image} alt={wish.product_name} style={wishImageStyle} />
          ) : (
            <div style={{...wishImageStyle, ...placeholderImageStyle}}>
              <FontAwesomeIcon icon="image" size="3x" color="#ccc" />
            </div>
          )}
          <div style={wishContentStyle}>
            <h3 style={wishTitleStyle}>{wish.product_name}</h3>
            <p style={wishInfoStyle}>Total Price: ${wish.total_price}</p>
            <p style={wishInfoStyle}>Number of Products: {wish.product_count}</p>
            <p style={wishInfoStyle}>Discount: {wish.total_discount}%</p>
            {specs && (
                <div style={packageSpecsStyle}>
                    <p style={specsHeaderStyle}>Package Specifications:</p>
                    <p style={specsDetailStyle}>
                    Dimensions: {specs.package_length} × {specs.package_width} × {specs.package_height} {specs.dimension_unit}
                    </p>
                    <p style={specsDetailStyle}>Weight: {specs.package_weight} {specs.weight_unit}</p>
                    {(specs.is_fragile || specs.has_batteries || specs.is_hazardous || specs.storage_requirements) && (
                    <div style={specialHandlingStyle}>
                        {specs.is_fragile && <span style={handlingTagStyle}>Fragile</span>}
                        {specs.has_batteries && <span style={handlingTagStyle}>Batteries</span>}
                        {specs.is_hazardous && <span style={handlingTagStyle}>Hazardous</span>}
                        {specs.storage_requirements && <span style={handlingTagStyle}>{specs.storage_requirements}</span>}
                    </div>
                    )}
                </div>
            )}
            <div style={statusContainerStyle}>
              <select
                value={wish.group_wish_status || 'NOT_APPLICABLE'}
                onChange={(e) => handleStatusChange(wish, e.target.value)}
                style={{
                  ...statusSelectStyle,
                  opacity: isUpdating ? 0.7 : 1,
                  cursor: isUpdating ? 'not-allowed' : 'pointer'
                }}
                disabled={isUpdating}
              >
                {statusOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
              {isUpdating && <span style={loadingSpinnerStyle}></span>}
            </div>
            <div style={buttonContainerStyle}>
              <button style={deleteButtonStyle} onClick={() => handleDelete(wish)}>
                <FontAwesomeIcon icon={faTrash} size="sm" />
              </button>
            </div>
          </div>
        </div>
      );
    };
  
    return (
      <>
        <TopMenuBar 
          showBackButton={true} 
          onBackClick={() => navigate(-1)} 
          title={showResumeButton 
            ? sourceScreen === 'create' 
              ? "Add Group Wishes to Combo" 
              : "Add More Group Wishes to Combo" 
            : "Group Wishes - Combo Deals"
          } 
          showSearch={false} 
          showAddress={false}
        />
        <div style={containerStyle}>
          <div style={contentStyle}>
            {isLoading ? (
              <div style={loadingContainerStyle}>
                <div style={loaderStyle}></div>
              </div>
            ) : (
              <>
                {(successMessage || errorMessage) && (
                  <div style={{
                    ...messageContainerStyle,
                    backgroundColor: successMessage ? '#d4edda' : '#f8d7da',
                    color: successMessage ? '#155724' : '#721c24',
                  }}>
                    <p>{successMessage || errorMessage}</p>
                  </div>
                )}
                <div style={formContainerStyle}>
                  <div style={inputGroup}>
                    <input
                      type="text"
                      style={searchInputStyle}
                      placeholder="Search combo deals..."
                      value={searchQuery}
                      onChange={handleSearch}
                    />
                    {showResumeButton ? (
                      <button 
                        style={resumeButtonStyle} 
                        onClick={handleResumeCombo}
                        disabled={selectedWishes.length === 0}
                      >
                        <FontAwesomeIcon icon={faArrowRight} /> Resume {sourceScreen === 'create' ? 'Creating' : 'Editing'} Combo
                      </button>
                    ) : (
                      <button style={createComboButtonStyle} onClick={handleCreateCombo}>
                        <FontAwesomeIcon icon={faPlus} /> Create New Combo
                      </button>
                    )}
                  </div>

                  {showResumeButton && (
                    <div style={selectionInfoStyle}>
                      <p>Selected Items: {selectedWishes.length}</p>
                      {selectedWishes.length > 0 && (
                        <p style={selectionInstructionStyle}>
                          You can unselect items you don't want or select additional items, then click "Resume" to continue.
                        </p>
                      )}
                    </div>
                  )}

                  <div style={filterContainerStyle}>
                    <input
                      type="text"
                      style={filterInputStyle}
                      placeholder="Filter by category"
                      value={filterCategory}
                      onChange={handleCategoryFilter}
                    />                    
                    <input
                      type="text"
                      style={filterInputStyle}
                      placeholder="Filter by product type"
                      value={filterProductType}
                      onChange={handleProductTypeFilter}
                    />
                  </div>
                  {/* Add packaging optimizer toggle button */}
                <div style={packagingOptimizerToggleStyle}>
                    <button style={packagingOptimizerButtonStyle} onClick={() => setShowPackagingOptimizer(!showPackagingOptimizer)} >
                        {showPackagingOptimizer ? 'Hide' : 'Show'} Packaging Optimizer
                    </button>
                </div> 
                {showPackagingOptimizer && selectedWishes.length > 0 && (           
                    <PackagingOptimizer 
                      selectedWishes={selectedWishes} 
                      comboId={comboId} 
                      isFromGroupWishCombo={true}  
                    />
                )}
                {showPackagingOptimizer && selectedWishes.length === 0 && (
                    <div style={warningMessageStyle}>
                        Please select wishes to see packaging optimization options
                    </div>
                )}
                <div style={wishListStyle}>
                    {filteredWishes.map(renderWishItem)}
                    {filteredWishes.length === 0 && <p style={emptyTextStyle}>No combo wishes found</p>}
                </div>
                </div>
              </>
            )}
          </div>
        </div>
      </>
    );
  };
   
const containerStyle = {
  fontFamily: 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif',
  backgroundColor: style.Color.colorWhite,
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  paddingTop: '5%',
};

const contentStyle = {
  width: '100%',
  maxWidth: '1600px',
};

const inputGroup = {
  display: 'flex',
  gap: '20px',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: '15px',
};

const createComboButtonStyle = {
  borderRadius: '8px',
  padding: '12px 20px',
  backgroundColor: style.Color.colorMintGreen,
  color: 'white',
  border: 'none',
  fontWeight: 'bold',
  cursor: 'pointer',
  transition: 'background-color 0.3s ease',
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
};

const resumeButtonStyle = {
  borderRadius: '8px',
  padding: '12px 20px',
  backgroundColor: style.Color.colorRoyalBlueText,
  color: 'white',
  border: 'none',
  fontWeight: 'bold',
  cursor: 'pointer',
  transition: 'background-color 0.3s ease',
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  ':disabled': {
    backgroundColor: '#cccccc',
    cursor: 'not-allowed',
  }
};

const selectionInfoStyle = {
  backgroundColor: style.Color.colorLightBeige,
  padding: '12px 16px',
  borderRadius: '6px',
  marginBottom: '20px',
  border: `1px solid ${style.Color.colorBlueGrey}`,
};

const selectionInstructionStyle = {
  fontSize: '14px',
  color: style.Color.colorRoyalBlueText,
  fontStyle: 'italic',
};

const wishItemStyle = {
    display: 'flex',
    backgroundColor: style.Color.colorWhite,
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  };
  
  const wishImageStyle = {
    width: '100px',
    height: '100px',
    borderRadius: '8px',
    marginRight: '20px',
    objectFit: 'cover',
  };
  
  const placeholderImageStyle = {
    backgroundColor: '#f0f0f0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };
  
  const wishContentStyle = {
    flex: 1,
  };
  
  const wishTitleStyle = {
    fontSize: '18px',
    fontWeight: 'bold',
    marginBottom: '10px',
    color: style.Color.colorRoyalBlueText,
  };
  
  const wishInfoStyle = {
    fontSize: '14px',
    color: style.Color.colorDarkGrey,
    marginBottom: '5px',
  };
  const statusContainerStyle = {
    marginTop: '10px',
    marginBottom: '10px',
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  };
  
const formContainerStyle = {
    backgroundColor: style.Color.colorLightBeige,
    borderRadius: '8px',
    padding: '30px',
    marginBottom: '30px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  };   
  
  const searchInputStyle = {
    flex: '1',
    padding: '12px',
    border: '1px solid #bdc3c7',
    borderRadius: '8px',
    fontSize: '14px',
  };
  
  const filterContainerStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '20px',
  };
  
  const filterInputStyle = {
    width: '48%',
    padding: '12px',
    border: '1px solid #bdc3c7',
    borderRadius: '8px',
    fontSize: '14px',
  };
  
  const wishListStyle = {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
  };
  const statusSelectStyle = {
    padding: '8px',
    borderRadius: '4px',
    border: `1px solid ${style.Color.colorBlueGrey}`,
    backgroundColor: style.Color.colorWhite,
    color: style.Color.colorDarkGrey,
    fontSize: '14px',
    width: '100%',
    maxWidth: '300px',
  };
  
  const loadingSpinnerStyle = {
    width: '20px',
    height: '20px',
    border: '2px solid #f3f3f3',
    borderTop: `2px solid ${style.Color.colorMintGreen}`,
    borderRadius: '50%',
    animation: 'spin 1s linear infinite',
  };
  const buttonContainerStyle = {
    display: 'flex',
    gap: '10px',
    marginTop: '15px',
  };
 
  const packagingOptimizerToggleStyle = {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '20px',
    marginBottom: '20px',
  };
  
  const packagingOptimizerButtonStyle = {
    padding: '10px 20px',
    backgroundColor: style.Color.colorMintGreen,
    color: style.Color.colorWhite,
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    fontWeight: 'bold',
    transition: 'background-color 0.3s ease',
  };
  
  const warningMessageStyle = {
    textAlign: 'center',
    padding: '15px',
    backgroundColor: '#fff3cd',
    color: '#856404',
    borderRadius: '4px',
    marginBottom: '20px',
  };
  
  const deleteButtonStyle = {
    padding: '8px 12px',
    backgroundColor: '#e74c3c',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  };
 
  const loadingContainerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '200px',
  };
  
  const loaderStyle = {
    border: '4px solid #f3f3f3',
    borderTop: `4px solid ${style.Color.colorMintGreen}`,
    borderRadius: '50%',
    width: '40px',
    height: '40px',
    animation: 'spin 1s linear infinite',
  };
  
  const messageContainerStyle = {
    padding: '15px',
    borderRadius: '4px',
    marginBottom: '20px',
    fontWeight: 'bold',
    textAlign: 'center', 
    backgroundColor: '#4CAF50',
    color: 'white',
    padding: '10px',
    borderRadius: '5px',
    position: 'fixed',
    top: '20px',
    right: '20px',
    zIndex: '1000',
  };
  
  const emptyTextStyle = {
    textAlign: 'center',
    color: style.Color.colorDarkGrey,
    fontSize: '16px',
    marginTop: '20px',
  };
  
  const checkboxStyle = {
    width: '20px',
    height: '20px',
    marginRight: '15px',
    cursor: 'pointer',
    accentColor: style.Color.colorMintGreen,
    border: `2px solid ${style.Color.colorGray}`,
    borderRadius: '3px',
  };

  const packageSpecsStyle = {
    marginTop: '10px',
    padding: '10px',
    backgroundColor: style.Color.colorLightBeige,
    borderRadius: '4px',
  };
  
  const specsHeaderStyle = {
    fontSize: '14px',
    fontWeight: 'bold',
    marginBottom: '5px',
    color: style.Color.colorDarkGrey,
  };
  
  const specsDetailStyle = {
    fontSize: '13px',
    color: style.Color.colorDarkGrey,
    marginBottom: '3px',
  };
  
  const specialHandlingStyle = {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '5px',
    marginTop: '5px',
  };
  
  const handlingTagStyle = {
    backgroundColor: '#fff3cd',
    color: '#856404',
    padding: '2px 6px',
    borderRadius: '4px',
    fontSize: '12px',
  };
export default GroupWishesCombo;