import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Config from "../../../config"; 
import { useAuth } from '../../../components/Common/InitialGears/AuthContext'; 
import * as style from "../../../GlobalStyles"; 
import TermsAndConditionsModal from '../GroupWishes/JoiningTermsAndConditions'; 
import JoinGroupWishModal from '../GroupWishes/JoinGroupWishModal';
import { getApi, postApi, buildUrl } from "../../Common/BackendCalls/apiUtils";
import {FaCheck, FaTimes, FaUsers, FaRegEnvelope, FaGift, FaChevronDown, FaChevronUp } from 'react-icons/fa';
import ComboJoinModal from '../Combo/ComboJoinModal';
import UserPreferencesScreen from '../GroupWishes/UserPreferenceScreen';

const ReceivedInvites = () => {
  const navigate = useNavigate();
  const { queryLoginParam, userVerified } = useAuth();
  const [regularInvites, setRegularInvites] = useState([]);
  const [groupWishInvites, setGroupWishInvites] = useState([]);
  const [comboInvites, setComboInvites] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedTab, setSelectedTab] = useState('regular');
  const [expandedInvites, setExpandedInvites] = useState({});
  const [invite, setInvite] = useState({}); 
  const [showComboJoinModal, setShowComboJoinModal] = useState(false);
  const [selectedComboForJoin, setSelectedComboForJoin] = useState(null);
  const [isFavoriteProcessing, setIsFavoriteProcessing] = useState(false); 
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [selectedWishForJoin, setSelectedWishForJoin] = useState(null); 
  const [showJoinModal, setShowJoinModal] = useState(false); 
  const [userRejoining, setUserRejoining] = useState(false); 
  const [inviteReferralCode, setInviteReferralCode] = useState('');
  const [showPreferencesModal, setShowPreferencesModal] = useState(false);
  const [calculatedCosts, setCalculatedCosts] = useState(null);
  const [comboQuantity, setComboQuantity] = useState(1);
  const [useRedeemCredit, setUseRedeemCredit] = useState(false);
  const [isJoiningCombo, setIsJoiningCombo] = useState(false); 

  useEffect(() => {
    fetchReceivedInvites();    
  }, []);


  const fetchReceivedInvites = async () => {
    setIsLoading(true);
    setErrorMessage('');
    try { 
      const apiUrl = buildUrl('invitesIReceived', queryLoginParam); 
      const response = await getApi(apiUrl);
      const data = await response.data;
      if (response.ok) {
        setRegularInvites(data.regular_invites || []);
        setGroupWishInvites(data.grp_wish_invites || []);
        setComboInvites(data.combo_invites || []);
        if (!data.regular_invites?.length && !data.grp_wish_invites?.length && !data.combo_invites?.length) {
          setErrorMessage('No invites found');
        }
      } else {
        setErrorMessage('Failed to fetch invites');
      }
    } catch (error) {
      setErrorMessage('Error loading invites');
    } finally {
      setIsLoading(false);
    }
  };

  const backeEndCall= async (inviteId, type, action)=>{ 
    try {    
      const apiUrl = `${Config.apiUrl}inviteResponse?${queryLoginParam}`; 
      const payload = {invite_id: inviteId, invite_type: type,action: action};      
      const response = await postApi(apiUrl,payload); 
      if (response.ok) {  
        if (type === 'regular') {
          setRegularInvites(prevInvites => 
            prevInvites.map(item => 
              item.invite_id === inviteId 
                ? { ...item, recipient_status: response.data.recipient_status } 
                : item
            )
          );
        } else if (type === 'groupwish') {
          setGroupWishInvites(prevInvites => 
            prevInvites.map(item => 
              item.invite_id === inviteId 
                ? { ...item, recipient_status: response.data.recipient_status } 
                : item
            )
          );
        } else if (type === 'combo') {
          setComboInvites(prevInvites => 
            prevInvites.map(item => 
              item.invite_id === inviteId 
                ? { ...item, recipient_status: response.data.recipient_status } 
                : item
            )
          );
        }
      }
    } catch (error) {
      //console.error('Error tracking invite view:', error);
    }
  }
 
const findInviteById = (inviteId, type) => {
  if (type === 'regular') {
    return regularInvites.find(item => item.invite_id === inviteId);
  } else if (type === 'groupwish') {
    return groupWishInvites.find(item => item.invite_id === inviteId);
  } else if (type === 'combo') {
    return comboInvites.find(item => item.invite_id === inviteId);
  }
  return null;
};
 
const toggleInviteDetails = async (inviteId, type) => {
  const key = `${type}-${inviteId}`;
  const isExpanding = !expandedInvites[key]; 
  setExpandedInvites(prev => ({
    ...prev,
    [key]: isExpanding
  }));   
  const invite = findInviteById(inviteId, type);  
  if (!isExpanding) {        
    if ((invite && invite.recipient_status === 'ON_THE_WAY')|| !userVerified) {
      await backeEndCall(inviteId, type, "read");
    }  await backeEndCall(inviteId, type, "reset");
  }
}; 


const handleCalculateFromModal = (calculatedCostsData, selectedAddressData, comboQuantityValue, useRedeemCreditValue) => { 
  /*console.log("Calculation completed", {
    calculatedCosts: calculatedCostsData,
    selectedAddress: selectedAddressData,
    comboQuantity: comboQuantityValue,
    useRedeemCredit: useRedeemCreditValue
  });*/
};

const handleJoinFromModal = (calculatedCostsData, selectedAddressData, comboQuantityValue, useRedeemCreditValue) => { 
    setIsJoiningCombo(true); 
    setCalculatedCosts(calculatedCostsData);     
    setComboQuantity(comboQuantityValue);
    setUseRedeemCredit(useRedeemCreditValue);     
    setShowComboJoinModal(false);
    setTimeout(() => {
      setShowPreferencesModal(true);
    }, 100);
};

const handleAgreeTerms = async () => { 
  setIsLoading(true);
  if (selectedWishForJoin) {
    setIsLoading(false); 
    setShowJoinModal(true); 
  }
};
 
const handleCloseJoinModal = () => {
  setShowJoinModal(false); 
};

const handleAcceptInvite = async (inviteId, type) => { 
  if (!userVerified){
    setErrorMessage("Please verify your account to proceed");
    return;
  }
  setIsLoading(true);
  const invite = findInviteById(inviteId, type);  
  if (invite && invite.recipient_status !== 'ACCEPTED') {
    await backeEndCall(inviteId, type, "accepted");  
    if (invite.referral_code) {
      setInviteReferralCode(invite.referral_code); 
    } else { 
      setInviteReferralCode('');
    }      
    if (type === 'groupwish') { 
      const invitedFor = invite.details[0];
      const userAlreadyJoined = invitedFor.user_already_joined;       
      if (userAlreadyJoined === true) {
        setErrorMessage("You have already Joined");
        }      
      setSelectedWishForJoin(invitedFor);
      setShowTermsModal(true);
    } else {  
        setSelectedComboForJoin(invite.details[0].combo);
        setShowComboJoinModal(true);
        setShowTermsModal(true);
      }
    }
    setIsLoading(false);
  };
 
const handleDeclineInvite = async (inviteId, type) => { 
  if (!userVerified){
    setErrorMessage("Please verify your account to proceed");
    return;  
  }
  setIsLoading(true);
  const invite = findInviteById(inviteId, type);
  if (invite && invite.recipient_status !== 'REJECTED') {
    await backeEndCall(inviteId, type, "rejected"); 
  }
  setIsLoading(false);
};

const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric'
    });
};

const renderStatus = (status) => {
    let statusText = '';
    let StatusIcon = null;
    switch (status) {
      case "ON_THE_WAY":
        statusText = "UnRead";
        StatusIcon = () => <span style={styles.statusIcon}>📤</span>;
        break;
      case "READ":
        statusText = "Read";
        StatusIcon = () => <span style={styles.statusIcon}>📖</span>;
        break;
      case "DELIVERED":
        statusText = "Delivered";
        StatusIcon = () => <span style={styles.statusIcon}>✅</span>;
        break;
      case "DELIVERY_FAILED":
        statusText = "Delivery Failed";
        StatusIcon = () => <span style={styles.statusIcon}>❌</span>;
        break;
      case "ACCEPTED":
        statusText = "Accepted";
        StatusIcon = () => <span style={styles.statusIcon}>👍</span>;
        break;
      case "JOINED_GRP":
          statusText = status;
          StatusIcon = () => <span style={styles.statusIcon}>🤝</span>;
      default:
        statusText = status;
        StatusIcon = () => <span style={styles.statusIcon}>❓</span>;
    }
    return (
      <div style={styles.statusContainer}>
        <StatusIcon />
        <span style={styles.statusText}>
          {statusText}
        </span>
      </div>
    );
  };


const handlePreferencesClose = (success) => {
  setIsJoiningCombo(false);
  setShowPreferencesModal(false);
  if (success) { 
    if (success.combo && success.combo.userPreferences) {
      setSelectedComboForJoin(prevData => ({
        ...prevData,
        userPreferences: success.combo.userPreferences, 
        progress_stats: {
          ...prevData.progress_stats,
          usersChoices: success.combo.userPreferences
        }
      })); 
    } else if (success.preferences) { 
      setSelectedComboForJoin(prevData => ({
        ...prevData,
        progress_stats: {
          ...prevData.progress_stats,
          usersChoices: success.preferences
        }
      })); 
    } else if (Array.isArray(success)) { 
      setSelectedComboForJoin(prevData => ({
        ...prevData,
        progress_stats: {
          ...prevData.progress_stats,
          usersChoices: success
        }
      })); 
    };
    setErrorMessage(null);
  }else { 
    setErrorMessage('Failed to join combo. Please try again.');
  }  
};

  const handleGroupWishTitleClick = (invite) => {
    const wishData = invite.details[0];
    navigate('/groupWishProgress', {
      state: {
        wish: wishData.wish,
        progressData: {
          wish: wishData.wish,
          is_favorite: wishData.is_favorite,
          totalJoined: wishData.totalJoined || 0,
          totalWaitListed: wishData.totalWaitListed || 0,
          verifiedUsers: wishData.verifiedUsers || 0,
          unverifiedUsers: wishData.unverifiedUsers || 0,
          upvotes: wishData.upvotes || 0,
          downvotes: 0, // You might need to add this to your data structure
          progress: wishData.progress || 0,
          averageRating: wishData.averageRating || 0,
          user_already_joined: wishData.user_already_joined || false,
          price_ranges: wishData.wish.price_ranges || [],
          rating_counts: wishData.rating_counts || [],
          group_wish_buddy_status: wishData.group_wish_buddy_status || null,
          delivery_address: wishData.delivery_address || null
        },
        joined: wishData.user_already_joined || false,
        is_favorite: wishData.is_favorite,
      }
    });
  };
  const handleTermsCancel = async ( ) => { 
    setIsLoading(true);
    setShowTermsModal(false);
    setShowJoinModal(false);
    setShowComboJoinModal(false);
    if (selectedWishForJoin) { 
      const wishInvite = groupWishInvites.find(
        invite => invite.details[0]?.wish?.id === selectedWishForJoin.wish.id
      );
      if (wishInvite) {
        await backeEndCall(wishInvite.invite_id, 'groupwish', "read");
      }
    } else if (selectedComboForJoin) { 
      const comboInvite = comboInvites.find(
        invite => invite.details[0]?.combo?.id === selectedComboForJoin.id
      );
      if (comboInvite) {
        await backeEndCall(comboInvite.invite_id, 'combo', "read");
      }
    } 
    setSelectedWishForJoin(null);
    setSelectedComboForJoin(null);
    setIsLoading(false);
  }
  const handleComboTitleClick = (invite) => {
    navigate('/comboProgress', {
      state: {
        comboData: invite.details[0].combo
      }
    });
  };
  const renderInviteItem = (invite, type) => {
    let icon;
    let title;let itemId;
    let buddyStatus;
    let alreadyJoined;
    switch (type) {
      case 'groupwish':
        icon = <FaUsers style={{...styles.colorRoseGoldText,...styles.inviteIcon}} />;
        title = <span onClick={() => handleGroupWishTitleClick(invite)}  style={{...styles.inviteItemHeading, cursor: 'pointer'}} > {invite.details[0].wish.product_name} </span>
        itemId = invite.group_wish_id;
        alreadyJoined = (invite.details && invite.details.length > 0 &&  invite.details[0].user_already_joined)? true: false;        
        buddyStatus = (invite.details && invite.details.length > 0) ? (invite.details[0].group_wish_buddy_status|| "Not Joined") :"Not Joined"; 
        break;
      case 'combo':
        icon = <FaGift style={{...styles.colorRoseGoldText,...styles.inviteIcon}} />;
        title = <span onClick={() => handleComboTitleClick(invite)} style={{...styles.inviteItemHeading, cursor: 'pointer'}}>{invite.details[0].combo.title} </span>
        itemId = invite.combo_id;
        buddyStatus = (invite.details && invite.details.length > 0) ? (invite.details[0].combo && invite.details[0].combo.combo_buddy_status|| "Not Joined") :"Not Joined";             
        break;
      default: 
        icon = <FaRegEnvelope style={{...styles.colorRoseGoldText,...styles.inviteIcon}} />;
        title = 'Invite to BacksApp';
        itemId = invite.invite_id;
        buddyStatus = null;
    }
    
    const expandKey = `${type}-${invite.invite_id}`;
    const isExpanded = expandedInvites[expandKey] || false;
    const isInviteProcessed = invite.recipient_status === 'ACCEPTED' || invite.recipient_status === 'REJECTED';   
    const canJoin = buddyStatus === "Not Joined" || buddyStatus === "WITHDRAWN";
    const showButtons = type !== 'regular' &&  canJoin;
    const showNotApplicable = type !== 'regular' && (!canJoin || isInviteProcessed); 
    return (
      <div key={invite.invite_id} style={styles.inviteItemCard}>
        <div style={styles.inviteItemHeader}>
          <div style={styles.inviteItemLeft}>
            <div style={styles.inviteItemTitle}>
              {icon}
              <h3 style={styles.inviteItemHeading}>
                {title}
              </h3>
            </div>
            <div style={styles.inviteItemDetails}>
              <div style={styles.dateContainer}>
                <span style={styles.dateIcon}>📅</span>
                <span style={styles.dateText}>
                  {formatDate(invite.invited_date)}
                </span>
              </div>
              {renderStatus(invite.recipient_status)}
            </div>
            <p style={styles.senderInfo}>
              <span style={styles.senderLabel}>From:</span> {invite.sender}
            </p>
          </div>
          
          <div style={styles.actionButtonContainer}>
            {type !== 'regular' && (invite.recipient_status === 'ON_THE_WAY' || invite.recipient_status === 'READ') &&  (
              <>
                {/* Case 1 & 3: If already joined/other status OR invite is processed, show message */}
                {showNotApplicable && (
                    <div style={styles.notApplicableMessage}>
                      This invite is no longer applicable for you
                    </div>
                )}
                {showButtons && (
                  <>
                    <button onClick={() => handleAcceptInvite(invite.invite_id, type)} style={{...styles.colorRoseGoldText,...styles.acceptButton}}> 
                      <FaCheck style={styles.buttonIcon} /> Accept
                    </button>
                    <button onClick={() => handleDeclineInvite(invite.invite_id, type)} style={{...styles.colorRoseGoldText,...styles.declineButton}}>
                      <FaTimes style={styles.buttonIcon} /> Reject
                    </button>
                  </>
                )}
              </>
            )}
            {invite.invite_message_body && (
              <button onClick={() => toggleInviteDetails(invite.invite_id, type)} style={{...styles.colorRoseGoldText,...styles.toggleButton}}>
                {isExpanded ? (
                  <>
                    <FaChevronUp style={styles.buttonIcon} /> Hide Invite
                  </>
                ) : (
                  <>
                    <FaChevronDown style={styles.buttonIcon} /> View Invite
                  </>
                )}
              </button>
            )}
          </div>
        </div>
        {invite.invite_message_body && isExpanded && (
          <div style={styles.messageContainer}>
            <div style={styles.messageContent}>
              <div dangerouslySetInnerHTML={{ __html: invite.invite_message_body }} />
            </div>
          </div>
        )}           
      </div>
    );
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.heading}>
        Invites I have received
      </h1>

      {isLoading ? (
        <div style={styles.loadingContainer}>
          <div style={styles.spinner}></div>
        </div>
      ) : (
        <>
          <div style={styles.tabContainer}>
            <button onClick={() => setSelectedTab('regular')} style={selectedTab === 'regular' ? styles.activeTabButton : styles.tabButton}>
              Regular Invites ({regularInvites.length})
            </button>
            <button onClick={() => setSelectedTab('groupwish')} style={selectedTab === 'groupwish' ? styles.activeTabButton : styles.tabButton}>
              Group Wish Invites ({groupWishInvites.length})
            </button>
            <button onClick={() => setSelectedTab('combo')} style={selectedTab === 'combo' ? styles.activeTabButton : styles.tabButton}>
              Combo Invites ({comboInvites.length})
            </button>
          </div>
          <div style={styles.inviteListContainer}>
            {errorMessage && (
              <div style={styles.errorMessage}>
                {errorMessage}
              </div>
            )}
          </div>
        

          <div style={styles.inviteListContainer}>
            {selectedTab === 'regular' && regularInvites.map(invite => renderInviteItem(invite, 'regular'))}
            {selectedTab === 'groupwish' && groupWishInvites.map(invite => renderInviteItem(invite, 'groupwish'))}
            {selectedTab === 'combo' && comboInvites.map(invite => renderInviteItem(invite, 'combo'))}
          </div>
          {showComboJoinModal && selectedComboForJoin && (
            <ComboJoinModal isOpen={showComboJoinModal} onClose={() => setShowComboJoinModal(false)} comboData={selectedComboForJoin} referralCode={inviteReferralCode} onCalculate={handleCalculateFromModal} callJoinApis={true} onJoin={handleJoinFromModal} queryLoginParam={queryLoginParam} userVerified={userVerified}/>  
          )}
          <TermsAndConditionsModal isOpen={showTermsModal} onClose={() => setShowTermsModal(false)} onCancel={() => handleTermsCancel()} onAgree={handleAgreeTerms}/>
          <JoinGroupWishModal isOpen={showJoinModal} onClose={handleCloseJoinModal} wish={selectedWishForJoin} isRejoin={userRejoining} referralCode={inviteReferralCode} hasPrefernce={selectedWishForJoin && selectedWishForJoin.wish && selectedWishForJoin.wish.preferences && selectedWishForJoin.wish.preferences.length > 0} onJoinSuccess={() => {fetchReceivedInvites();}} />         
          {showPreferencesModal && (
            <UserPreferencesScreen isOpen={showPreferencesModal} onClose={handlePreferencesClose} combo={true} grp_wish_id={selectedComboForJoin.id} groupWishName={selectedComboForJoin ? selectedComboForJoin.title : ''} existingPreferences={ "new"}  landBack={"Dashboard"} quantity={comboQuantity || 1}    isLoading={isJoiningCombo} onError={(errorMsg) => {setShowPreferencesModal(false);setErrorMessage(errorMsg || 'Failed to save preferences');}}/>
           )}
        </>
      )}
    </div>
  );
};

const styles = {
  container: {
    width: '100%',
    padding: '16px',
    borderRadius: '8px',
    backgroundColor: style.Color.colorLightBeige
  },
  heading: {
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: '24px',
    color: style.Color.colorPeacockBlueDark
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: '32px 0'
  },
  spinner: {
    width: '32px',
    height: '32px',
    borderWidth: '4px',
    borderRadius: '50%',
    borderStyle: 'solid',
    borderColor: style.Color.colorMidBeige,
    borderTopColor: style.Color.colorDarkBeige,
    animation: 'spin 1s linear infinite'
  },
  notApplicableMessage: {
    padding: '8px 12px',
    backgroundColor: style.Color.colorLightBeige,
    borderRadius: '8px',
    color: style.Color.colorDarkBeige,
    fontSize: '14px',
    fontStyle: 'italic'
  },
  errorMessage: {
    textAlign: 'center',
    padding: '16px 0',
    color: style.Color.colorRed
  },
  tabContainer: {
    display: 'flex',
    justifyContent: 'center',
    gap: '16px',
    marginBottom: '24px'
  },
  tabButton: {
    padding: '8px 16px',
    borderRadius: '8px',
    fontWeight: '500',
    transition: 'all 0.3s',
    backgroundColor: style.Color.colorLightBeige,
    color: style.Color.colorDarkBrown,
    border: 'none',
    cursor: 'pointer'
  },
  activeTabButton: {
    padding: '8px 16px',
    borderRadius: '8px',
    fontWeight: '500',
    transition: 'all 0.3s',
    backgroundColor: style.Color.colorRoseGoldText,
    color: 'white',
    border: 'none',
    cursor: 'pointer'
  },
  inviteListContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px'
  },
  inviteItemCard: {
    marginBottom: '16px',
    padding: '16px',
    borderRadius: '8px',
    backgroundColor: style.Color.colorWhite
  },
  inviteItemHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start'
  },
  inviteItemLeft: {
    flex: 1
  },
  inviteItemTitle: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    marginBottom: '8px'
  },
  inviteIcon: {
    fontSize: '20px', 
  },
  inviteItemHeading: {
    fontSize: '18px',
    fontWeight: '500',
    color: style.Color.colorDarkBrown
  },
  inviteItemDetails: {
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
    marginBottom: '8px'
  },
  dateContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px'
  },
  dateIcon: {
    fontSize: '18px'
  },
  dateText: {
    fontSize: '14px',
    color: style.Color.colorDarkBeige
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px'
  },
  statusIcon: {
    fontSize: '18px'
  },
  statusText: {
    fontSize: '14px',
    color: style.Color.colorDarkBeige
  },
  senderInfo: {
    marginBottom: '8px',
    color: style.Color.colorRoyalBlueText
  },
  senderLabel: {
    fontWeight: '500'
  },
  actionButtonContainer: {
    display: 'flex',
    gap: '8px',
    flexWrap: 'wrap'
  },
  acceptButton: {
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
    padding: '8px 12px',
    borderRadius: '8px',
    backgroundColor: 'white',
    border: '1px solid',
    borderColor: style.Color.colorRoseGoldText,
    color: style.Color.colorRoseGoldText,
    cursor: 'pointer'
  },
  declineButton: {
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
    padding: '8px 12px',
    borderRadius: '8px',
    border: '1px solid', 
    borderColor: style.Color.colorPeacockBlue,
    color: style.Color.colorPeacockBlue, 
    cursor: 'pointer'
  },
  buttonIcon: {
    fontSize: '14px'
  },
  toggleButton: {
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
    padding: '8px 12px',
    borderRadius: '8px',
    backgroundColor: style.Color.colorWhite,
    color: style.Color.colorPurple,
    border: '1px solid',
    borderColor: style.Color.colorDarkslategray,
    color: style.Color.colorDarkslategray,
    cursor: 'pointer'
  },
  messageContainer: {
    marginTop: '12px',
    paddingTop: '12px',
    borderTopWidth: '1px',
    borderTopStyle: 'solid',
    borderTopColor: style.Color.colorLightBeige
  },
  messageContent: {
    fontSize: '14px',
    color: style.Color.colorRoyalBlueText
  }
};

export default ReceivedInvites;