import React from 'react';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';

const ZipCodeSelector = ({ 
  googleApiKey,  // Your Google Maps API key
  onSelect,      // Callback function that receives the selected zip code and location data
  currentValue,  // Current zip code value
  countryCode = 'us'  // Default to US
}) => {
  // Custom styles for the dropdown
  const customStyles = {
    control: (provided) => ({
      ...provided,
      borderRadius: '8px',
      minHeight: '40px',
      boxShadow: 'none',
      border: '1px solid #ccc',
      '&:hover': {
        borderColor: '#aaa'
      }
    }),
    option: (provided) => ({
      ...provided,
      padding: '8px 12px',
      fontSize: '14px'
    }),
    input: (provided) => ({
      ...provided,
      margin: '0',
      padding: '0'
    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#666'
    })
  };

  // Handle selection of a place
  const handleSelect = async (selected) => {
    if (!selected || !selected.value || !selected.value.place_id) return;

    try {
      // Get detailed place information using Google Geocoding API
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?place_id=${selected.value.place_id}&key=${googleApiKey}`
      );
      const data = await response.json();

      if (data.results && data.results.length > 0) {
        const components = data.results[0].address_components;
        
        // Extract location details
        const locationData = {
          zipCode: components.find(c => c.types.includes('postal_code'))?.long_name || '',
          city: components.find(c => c.types.includes('locality'))?.long_name || '',
          state: components.find(c => c.types.includes('administrative_area_level_1'))?.short_name || '',
          isValid: true
        };

        // Call the onSelect callback with the location data
        onSelect(locationData);
      }
    } catch (error) { 
      onSelect({ isValid: false });
    }
  };

  return (
    <GooglePlacesAutocomplete
      apiKey={googleApiKey}
      selectProps={{
        value: currentValue ? { label: currentValue, value: { place_id: currentValue } } : null,
        onChange: handleSelect,
        placeholder: 'Enter ZIP code',
        styles: customStyles,
        // Format the dropdown to only show the ZIP code
        formatOptionLabel: option => {
          const zipMatch = option.label.match(/\b\d{5}\b/);
          return zipMatch ? zipMatch[0] : option.label;
        }
      }}
      autocompletionRequest={{
        types: ['postal_code'],
        componentRestrictions: { country: countryCode }
      }}
    />
  );
};

export default ZipCodeSelector;