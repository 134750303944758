import React, { useState } from 'react';
import * as style from '../../../GlobalStyles';

const BulkImportModal = ({ onImport, onClose }) => {
  const [bulkText, setBulkText] = useState('');
  
  const handleImport = () => {
    onImport(bulkText);
    onClose();
  };
  
  return (
    <div style={modalOverlayStyle}>
      <div style={modalContainerStyle}>
        <h3 style={modalTitleStyle}>Bulk Import Items</h3>
        
        <div style={instructionsContainerStyle}>
          <p style={instructionsTextStyle}>
            Enter one item per line in the format: <br/>
            <span style={codeStyle}>Name [Dimensions] [Weight]</span><br/>
            Example: <span style={codeStyle}>Screw Driver 25X20X4.5 cm 1.000 kg</span>
          </p>
          
          <textarea 
            style={textareaStyle}
            placeholder="Screw Driver 25X20X4.5 cm 1.000 kg
Translation Pen 20X12X6 cm 0.550 kg
Bottle 7X7X23 cm 0.450 kg"
            value={bulkText}
            onChange={(e) => setBulkText(e.target.value)}
          />
        </div>
        
        <div style={buttonsContainerStyle}>
          <button 
            style={cancelButtonStyle}
            onClick={onClose}
          >
            Cancel
          </button>
          <button 
            style={importButtonStyle}
            onClick={handleImport}
          >
            Import Items
          </button>
        </div>
      </div>
    </div>
  );
};

// Styles
const modalOverlayStyle = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 1000,
};

const modalContainerStyle = {
  backgroundColor: 'white',
  padding: '24px',
  borderRadius: '8px',
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  maxWidth: '600px',
  width: '100%',
};

const modalTitleStyle = {
  fontSize: '18px',
  fontWeight: 'bold',
  marginBottom: '16px',
  color: style.Color.colorRoyalBlueText,
};

const instructionsContainerStyle = {
  marginBottom: '16px',
};

const instructionsTextStyle = {
  fontSize: '14px',
  color: style.Color.colorDarkGrey,
  marginBottom: '8px',
};

const codeStyle = {
  backgroundColor: '#f6f8fa',
  padding: '2px 4px',
  borderRadius: '4px',
  fontFamily: 'monospace',
};

const textareaStyle = {
  width: '100%',
  height: '200px',
  padding: '8px',
  borderRadius: '4px',
  border: `1px solid ${style.Color.colorGray}`,
  resize: 'vertical',
  fontSize: '14px',
};

const buttonsContainerStyle = {
  display: 'flex',
  justifyContent: 'flex-end',
  gap: '12px',
};

const cancelButtonStyle = {
  padding: '8px 16px',
  backgroundColor: '#f6f8fa',
  border: `1px solid ${style.Color.colorGray}`,
  borderRadius: '4px',
  cursor: 'pointer',
  fontSize: '14px',
};

const importButtonStyle = {
  padding: '8px 16px',
  backgroundColor: style.Color.colorMintGreen,
  color: 'white',
  border: 'none',
  borderRadius: '4px',
  cursor: 'pointer',
  fontSize: '14px',
  fontWeight: 'bold',
};

export default BulkImportModal;