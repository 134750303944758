import { CM_TO_INCH, INCH_TO_CM, KG_TO_LB, LB_TO_KG } from './Constants';

export const convertDimension = (value, fromUnit, toUnit) => { 
  if (fromUnit === toUnit) return value; 
  const numValue = typeof value === 'number' ? value : parseFloat(value); 
  if (isNaN(numValue)) return 0; 
  if (fromUnit === 'inch' && toUnit === 'cm') {
    return numValue * 2.54; 
  } else if (fromUnit === 'cm' && toUnit === 'inch') {
    return numValue / 2.54; 
  } 
  return numValue;
};

export const convertWeight = (value, fromUnit, toUnit) => { 
  if (fromUnit === toUnit) return parseFloat(value.toFixed(2));; 
  const numValue = typeof value === 'number' ? value : parseFloat(value); 
  if (isNaN(numValue)) return 0; 
  
  let result;
  if (fromUnit === 'lb' && toUnit === 'kg') {
    result = numValue * 0.45359237;
  } else if (fromUnit === 'kg' && toUnit === 'lb') {
    result = numValue / 0.45359237;
  } else {
    result = numValue;
  }  
  return parseFloat(result.toFixed(2));
};
 
export const formatMeasurement = (value, unit) => { 
  const numValue = typeof value === 'number' ? value : parseFloat(value); 
  if (isNaN(numValue)) return '0.00 ' + unit; 
  return numValue.toFixed(2) + ' ' + unit;
};

export const detectInputUnit = (specs) => { 
  const packageDimensions = specs.package_dimensions || {}; 
  const weight = parseFloat(packageDimensions.weight || 0);
  const length = parseFloat(packageDimensions.length || 0); 
  if (specs.dimension_unit && specs.weight_unit) {
    return {
      dimension: specs.dimension_unit.toLowerCase() === 'cm' ? 'cm' : 'inch',
      weight: specs.weight_unit.toLowerCase() === 'kg' ? 'kg' : 'lb'
    };
  } 
  const isMetric = weight < 10 || length > 30; 
  return {
    dimension: isMetric ? 'cm' : 'inch',
    weight: isMetric ? 'kg' : 'lb'
  };
};