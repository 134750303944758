import React, { useState, useEffect, useRef, memo } from 'react';
import { useNavigate } from 'react-router-dom';
import Config from "../../../config"; 
import { useAuth } from '../../Common/InitialGears/AuthContext'; 
import { Color, RoseGoldButton } from "../../../GlobalStyles"; 
import { getApi, postApi, buildUrl } from "../../Common/BackendCalls/apiUtils"; 
import phoneCountryCodeToCountry from '../../Common/InitialGears/PhoneCountryCodeToCountry';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faHistory } from '@fortawesome/free-solid-svg-icons';
import ZipCodeSelector from './ZipCodeSelector';

const AddWishModal = ({ isOpen, onClose }) => {
  const navigate = useNavigate();
  const { queryLoginParam, countryCodeLoggedIn } = useAuth();
  
  // Form state
  const [productName, setProductName] = useState('');
  const [brandPreference, setBrandPreference] = useState('');
  const [quantity, setQuantity] = useState('1');
  const [zipCode, setZipCode] = useState('');
  const [deliveryCity, setDeliveryCity] = useState('');
  const [state, setStateAbbreviation] = useState('');
  const [additionalDetails, setAdditionalDetails] = useState('');
  
  // UI state
  const [activeView, setActiveView] = useState('input');  
  const [activeStep, setActiveStep] = useState(1);  
  const [isLoading, setIsLoading] = useState(false); 
  const [knownWish, setKnownWish] = useState(false);
  const [messages, setMessages] = useState([]);
  const [recentWishes, setRecentWishes] = useState([]);
  const [matchingDeals, setMatchingDeals] = useState([]);
  const [showDealsSection, setShowDealsSection] = useState(false);
  
  // Data state
  const [remainingWishes, setRemainingWishes] = useState(0);
  const [wishLimitReached, setWishLimitReached] = useState(false);
  const [category, setCategory] = useState('');
  const [subCategory, setSubCategory] = useState('');
  const [productType, setProductType] = useState('');
  
  // Validation state
  const [zipCodeError, setZipCodeError] = useState('');
  const [isValidZipCode, setIsValidZipCode] = useState(false);
  const [showZipCodeMessage, setShowZipCodeMessage] = useState(false);
  const [productNameError, setProductNameError] = useState('');
  
  // Refs
  const scrollViewRef = useRef(null);
  const inputRef = useRef(null);
  
  const countryRestriction = phoneCountryCodeToCountry[countryCodeLoggedIn] || 'us';

  // Initialize on open
  useEffect(() => {
    if (isOpen) {
      initializeModal();
    }
  }, [isOpen]);

  // Scroll to bottom of messages when they change
  useEffect(() => {
    scrollToLatestMessage();
  }, [messages]);

  // Focus on input when step changes
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [activeStep]);

  const initializeModal = async () => {
    // Reset all states to default
    resetForm();
    
    // Get wish count
    const wishCount = await getWishesCount(); 
    if (wishCount <= 0) {
      setWishLimitReached(true);
      addBotMessage("You've reached your daily wish limit. Come back tomorrow for more wishes!"); 
      fetchWishHistory();
    } else { 
      addBotMessage("Share with me a product you've been eyeing and want to purchase at a discount.");
      setWishLimitReached(false);
    }
  };

  const fetchWishHistory = async () => {
    setIsLoading(true);
    try {
      const wishHistoryURL = buildUrl('getRecentWishes', queryLoginParam);
      const response = await getApi(wishHistoryURL);
      
      if (response && response.ok) {
        setRecentWishes(response.data.wishes || []);
      }
    } catch (error) {
      console.error('Error fetching wish history:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const resetForm = () => {
    setProductName('');
    setBrandPreference('');
    setCategory('');
    setSubCategory('');
    setProductType('');
    setQuantity('1');
    setAdditionalDetails('');
    setZipCode('');
    setDeliveryCity('');
    setStateAbbreviation('');
    setZipCodeError('');
    setIsValidZipCode(false);
    setShowZipCodeMessage(false);
    setProductNameError('');
    setActiveStep(1);
    setActiveView('input');
    setMessages([]);
    setMatchingDeals([]);
    setShowDealsSection(false);
  };

  const getWishesCount = async () => {
    try { 
      setIsLoading(true);
      const wishesCountURL = buildUrl('getWishesAllowed', queryLoginParam);
      const response = await getApi(wishesCountURL);

      if (response && response.ok && response.data.remaining_wishes !== undefined) {
        const count = response.data.remaining_wishes;
        setRemainingWishes(count);
        return count;
      } else {
        addBotMessage("Something went wrong. Please try again later.");
        return 0;
      }
    } catch (error) {
      console.error('Error getting wishes count:', error);
      addBotMessage("Something went wrong. Please try again later.");
      return 0;
    } finally {
      setIsLoading(false);
    }
  };

  const handleProductSubmit = async () => {
    if (!productName.trim()) {
      setProductNameError('Please enter a product name');
      return;
    }
    
    setProductNameError('');
    setIsLoading(true);
    addUserMessage(productName);
    
    try { 
      const wishesLeft = await getWishesCount();
      if (wishesLeft <= 0) {
        setWishLimitReached(true);
        addBotMessage("You've reached your daily wish limit. Come back tomorrow for more wishes!");
        return;
      }
       
      const isInappropriate = await checkForInappropriateContent(productName);
      
      if (isInappropriate) {
        if (knownWish){
          setProductName('');
          return;
        } else {
          addBotMessage('Sorry, your input contains inappropriate content. Please try again with a different product name.');
          setProductName('');
          return;
        }
      }
       
      const productData = await classifyProduct(productName);
      if (!productData) {
        addBotMessage("Sorry, we couldn't classify your product. Please try again with a more specific description.");
        setProductName('');
        return;
      } 
      setCategory(productData.category || '');
      setSubCategory(productData.subCategory || '');
      setProductType(productData.productType || ''); 
      addBotMessage("Would you like to specify a preferred brand for this product?");
      setActiveStep(2);
    } catch (error) {
      console.error('Error processing product:', error);
      addBotMessage("Something went wrong. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleViewDealDetails = (deal, navigate) => {
    if (deal.dealType === 'groupWish') {
      navigate('/groupWishProgress', {
        state: {
          wish: deal.wish,
          progressData: {
            is_favorite: deal.is_favorite,
            totalJoined: deal.totalJoined,
            verifiedUsers: deal.verifiedUsers,
            unverifiedUsers: deal.unverifiedUsers,
            totalQty: deal.totalQty,
            verifiedQty: deal.verifiedQty,
            unverifiedQty: deal.unverifiedQty,
            progress: deal.progress,
            upvotes: deal.upvotes,
            downvotes: deal.downvotes,
            averageRating: deal.averageRating,
            user_already_joined: deal.user_already_joined
          },
          joined: deal.user_already_joined || false,
          is_favorite: deal.is_favorite,
        }
      });
    } else if (deal.dealType === 'combo') {
      navigate('/comboProgress', {
        state: {
          comboData: deal.combo
        }
      });
    }
  };

  const handleBrandSubmit = () => {
    if (brandPreference.trim()) {
      addUserMessage(brandPreference);
    } else {
      addUserMessage("No specific brand preference");
    }
    
    addBotMessage("Great! Now let's confirm some details about your wish.");
    setActiveView('confirm');
  };

  const handleSkipBrand = () => {
    setBrandPreference('');
    addUserMessage("No specific brand preference");
    addBotMessage("Great! Now let's confirm some details about your wish.");
    setActiveView('confirm');
  };

  const handleQuantityChange = (e) => {
    const numericValue = e.target.value.replace(/[^0-9]/g, '');
    setQuantity(numericValue === '0' ? '1' : numericValue);
  };

  // Updated to handle both group wishes and combos
  const MatchingDealsDisplay = memo(({ deals, onViewDeal }) => {
    if (!deals || deals.length === 0) return null;

    return (
      <MatchingDealsContainer>
        <MatchingDealsTitle>Recommended deals based on your wish:</MatchingDealsTitle>
        <MatchingDealsList>
          {deals.map((deal, index) => {
            // Check if this is a combo or a group wish
            const isCombo = deal.combo !== undefined;
            
            if (isCombo) {
              // Render combo deal
              const combo = deal.combo;
              return (
                <MatchingDealItem key={index}>
                  <MatchingDealInfo>
                    <MatchingDealName>{combo.title}</MatchingDealName>
                    <MatchingDealCategory>Combo Deal</MatchingDealCategory>
                    <MatchingDealPrice>
                      List Price: ${parseFloat(combo.bundle_mrp).toFixed(2)}
                      <MatchingDealSavings> (Save up to {Math.round((1 - combo.bundle_rate / combo.bundle_mrp) * 100)}%)</MatchingDealSavings>
                    </MatchingDealPrice>
                  </MatchingDealInfo>
                  <ViewDealButton 
                    onClick={() => onViewDeal({
                      dealType: 'combo',
                      combo: combo
                    })}
                  >
                    View Deal
                  </ViewDealButton>
                </MatchingDealItem>
              );
            } else {
              // Render group wish
              return (
                <MatchingDealItem key={index}>
                  <MatchingDealInfo>
                    <MatchingDealName>{deal.wish.product_name}</MatchingDealName>
                    <MatchingDealCategory>
                      {deal.wish.category && Array.isArray(deal.wish.category) 
                        ? deal.wish.category.join(', ') 
                        : 'Category not available'}
                    </MatchingDealCategory>
                    <MatchingDealPrice>
                      List Price: ${parseFloat(deal.wish.list_price || 0).toFixed(2)}
                      {deal.wish.price_ranges && deal.wish.price_ranges.length > 0 && (
                        <MatchingDealSavings> (Save up to {deal.wish.price_ranges[0].savings_percentage}%)</MatchingDealSavings>
                      )}
                    </MatchingDealPrice>
                  </MatchingDealInfo>
                  <ViewDealButton 
                    onClick={() => onViewDeal({
                      dealType: 'groupWish',
                      wish: deal.wish,
                      is_favorite: deal.is_favorite,
                      user_already_joined: deal.user_already_joined,
                      totalJoined: deal.totalJoined || 0,
                      verifiedUsers: deal.verifiedUsers || 0,
                      unverifiedUsers: deal.unverifiedUsers || 0,
                      totalQty: deal.totalQty || 0,
                      verifiedQty: deal.verifiedQty || 0,
                      unverifiedQty: deal.unverifiedQty || 0,
                      progress: deal.progress || 0,
                      upvotes: deal.upvotes || 0,
                      downvotes: deal.downvotes || 0,
                      averageRating: deal.averageRating || 0
                    })}
                  >
                    View Deal
                  </ViewDealButton>
                </MatchingDealItem>
              );
            }
          })}
        </MatchingDealsList>
      </MatchingDealsContainer>
    );
  });
  
  const confirmWish = async () => {
    setIsLoading(true);
    if (!isValidZipCode) {
      setZipCodeError('Please enter a valid ZIP code');
      setIsLoading(false);
      return;
    }        
    try {            
      const addAIWishURL = buildUrl('addAIWish', queryLoginParam);  
      const requestBody = {
        category,
        subcategory: subCategory,
        productType,
        product_name: productName,
        brand_preference: brandPreference,
        quantity,
        zipCode,
        deliveryCity,
        state,
        additional_details: additionalDetails.slice(0, 100)
      };      
      const response = await postApi(addAIWishURL, requestBody); 
      addBotMessage(`Your wish for "${productName}" has been added successfully!`); 
      let hasDeals = false; 
      if (response.data && response.data.matching_group_wishes && response.data.matching_group_wishes.length > 0) {
        addBotMessage("We found some deals that might interest you!"); 
        setMatchingDeals(response.data.matching_group_wishes);
        setShowDealsSection(true);
        hasDeals = true;
      }  
      else if (response.data && response.data.matching_combos && response.data.matching_combos.length > 0) {
        addBotMessage("We found some combo deals that might interest you!");
        setMatchingDeals(response.data.matching_combos);
        setShowDealsSection(true);
        hasDeals = true;
      } 
      else if (response.data && response.data.matching_deals && response.data.matching_deals.length > 0) {
        addBotMessage("Click here to view deals for your wish"); 
        setTimeout(() => {
          navigate('/deal-dashboard', { 
            state: { 
              category, 
              fromAIWish: true, 
              matching_deals: response.data.matching_deals 
            }
          });
        }, 1000);
        hasDeals = true;
      }
      
      if (!hasDeals) {
        addBotMessage(`We'll notify you when deals become available.`);
      } 
      if (remainingWishes > 1) {    
        addBotMessage(`Would you like to make another wish? You have ${remainingWishes-1} wishes remaining today.`);
      } else {
        setWishLimitReached(true);
        addBotMessage("You've used all your wishes for today. Come back tomorrow for more!");
      }
    } catch (error) {
      console.error('Error submitting wish:', error);
      addBotMessage("Sorry, we couldn't process your wish. Please try again.");
    } finally {
      setIsLoading(false);
      setActiveView('input');
    }
  };

  const classifyProduct = async (product) => {
    setIsLoading(true);
    try {
      const classifyURL = buildUrl('classifyProduct', queryLoginParam); 
      const response = await postApi(classifyURL, { productName: product });
      
      if (response && response.ok) {
        return {
          category: response.data.category,
          subCategory: response.data.subCategory,  
          productType: response.data.productType  
        };
      }
      
      // Log specific error from backend if available
      if (response && response.error) {
        console.error('Classification error:', response.error);
      }
      
      return null;
    } catch (error) {
      console.error('Error classifying product:', error);
      return null;
    } finally {
      setIsLoading(false);
    }
  };

  const checkForInappropriateContent = async (text) => { 
    setIsLoading(true);
    try {
      setKnownWish(false);
      const moderationURL = buildUrl('moderateContent', queryLoginParam);
      const response = await postApi(moderationURL, { text });
      
      if (response && response.ok) {
        if (response.data.is_duplicate === true) {
          const productName = response.data.wish_details?.product_name || text;
          setKnownWish(true);
          addBotMessage(`You've already shared a wish for "${productName}". Please share something new!`);
          return true;  
        }
        return response.data.is_inappropriate;
      }
      return false;
    } catch (error) {
      console.error('Error checking content:', error);
      return false;
    } finally {
      setIsLoading(false);
    }
  };

  const addUserMessage = (text) => {
    setMessages(prev => [...prev, { type: 'user', text }]);
  };

  const addBotMessage = (message) => {
    setMessages(prev => [...prev, { type: 'bot', text: message }]);
  };

  const scrollToLatestMessage = () => {
    if (scrollViewRef.current) {
      scrollViewRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const toggleHistoryView = () => {
    if (activeView === 'history') {
      setActiveView('input');
    } else {
      fetchWishHistory();
      setActiveView('history');
    }
  };

  if (!isOpen) return null;

  return (
    <ModalOverlay>
      <ModalContent>
        <ModalHeader>
          <ModalTitle>Tell a Wish</ModalTitle>
          <ButtonGroup>
            <IconButton onClick={toggleHistoryView} title="Wish History">
              <FontAwesomeIcon icon={faHistory} />
            </IconButton>
            <IconButton onClick={onClose} title="Close">
              <FontAwesomeIcon icon={faTimes} />
            </IconButton>
          </ButtonGroup>
        </ModalHeader>
        
        <ModalBody>
          <NoteContainer>
            <NoteText>
              You can make up to 3 wishes in the next 24 hours. ({remainingWishes} remaining)
            </NoteText>
          </NoteContainer>
          
          {activeView === 'history' ? (
            <WishHistoryContainer>
              <HistoryTitle>Your Recent Wishes</HistoryTitle>
              {recentWishes.length === 0 ? (
                <EmptyHistoryMessage>No wishes made in the last 24 hours.</EmptyHistoryMessage>
              ) : (
                recentWishes.map((wish, index) => (
                  <WishItem key={index}>
                    <WishItemTitle>{wish.product_name}</WishItemTitle>
                    {wish.brand_preference && <WishItemDetail>Brand: {wish.brand_preference}</WishItemDetail>}
                    <WishItemDetail>Category: {wish.category}</WishItemDetail>
                    <WishItemDetail>Quantity: {wish.quantity}</WishItemDetail>
                    <WishItemDate>{new Date(wish.created_at).toLocaleString()}</WishItemDate>
                    {wish.has_deals && (
                      <ViewDealsButton onClick={() => navigate('/deal-dashboard', { 
                        state: { 
                          category: wish.category, 
                          fromAIWish: true, 
                          matching_deals: wish.matching_deals || [] 
                        }
                      })}>
                        View Deals
                      </ViewDealsButton>
                    )}
                  </WishItem>
                ))
              )}
              <ButtonContainer>
                <Button onClick={() => setActiveView('input')}>
                  Back to Wishes
                </Button>
              </ButtonContainer>
            </WishHistoryContainer>
          ) : (
            <>
              <ChatContainer>
                <ChatHistory>
                  {messages.length === 0 ? (
                    <EmptyChatMessage>Start sharing your wish by entering a product below.</EmptyChatMessage>
                  ) : (
                    messages.map((message, index) => (
                      <MessageWrapper key={index} type={message.type}>
                        <MessageContainer type={message.type}>
                          {typeof message.text === 'string' ? (
                            <MessageText type={message.type}>{message.text}</MessageText>
                          ) : (
                            message.text
                          )}
                        </MessageContainer>
                      </MessageWrapper>
                    ))
                  )}
                  <div ref={scrollViewRef} />
                </ChatHistory>
              </ChatContainer>
              
              {/* Refactored to use the MatchingDealsDisplay component */}
              {showDealsSection && (
                <MatchingDealsDisplay deals={matchingDeals} onViewDeal={(deal) => handleViewDealDetails(deal, navigate)} />
              )}

              {activeView === 'confirm' && (
                <ConfirmationContainer>
                  <ConfirmationHeadingText>Confirming your wish:</ConfirmationHeadingText>
                  
                  <ConfirmationRow>
                    <ConfirmationSubHeadingText>Product:</ConfirmationSubHeadingText>
                    <ConfirmationText>{productName}</ConfirmationText>
                  </ConfirmationRow>
                  
                  {brandPreference && (
                    <ConfirmationRow>
                      <ConfirmationSubHeadingText>Brand:</ConfirmationSubHeadingText>
                      <ConfirmationText>{brandPreference}</ConfirmationText>
                    </ConfirmationRow>
                  )}
                  
                  <ConfirmationRow>
                    <ConfirmationSubHeadingText>Category:</ConfirmationSubHeadingText>
                    <ConfirmationText>{category}</ConfirmationText>
                  </ConfirmationRow>
                  
                  <ConfirmationRow>
                    <ConfirmationSubHeadingText>Subcategory:</ConfirmationSubHeadingText>
                    <ConfirmationText>{subCategory}</ConfirmationText>
                  </ConfirmationRow>
                  
                  <QuantityContainer>  
                    <ConfirmationSubHeadingText>Quantity:</ConfirmationSubHeadingText>
                    <QuantityInput
                      type="number"
                      value={quantity}
                      onChange={handleQuantityChange}
                      min="1"
                      max="999"
                    />
                  </QuantityContainer>
                  
                  <QuantityContainer>
                    <ConfirmationSubHeadingText>Ship To Zipcode:</ConfirmationSubHeadingText>
                    <ZipCodeSelector
                      googleApiKey={Config.googleMapsAPIKEY}
                      currentValue={zipCode}
                      onSelect={(locationData) => {
                        setZipCode(locationData.zipCode);
                        setDeliveryCity(locationData.city);
                        setStateAbbreviation(locationData.state);
                        setIsValidZipCode(locationData.isValid);
                        setShowZipCodeMessage(!locationData.isValid);
                      }}
                      countryCode={countryRestriction}
                    />
                  </QuantityContainer>
                  
                  {zipCodeError && <ErrorText>{zipCodeError}</ErrorText>}
                  {showZipCodeMessage && <ErrorText>Invalid ZIP code</ErrorText>}
                  
                  <AdditionalDetailsInput
                    placeholder="Additional Details (up to 100 characters)"
                    value={additionalDetails}
                    onChange={(e) => setAdditionalDetails(e.target.value.slice(0, 100))}
                    maxLength={100}
                  />
                  
                  <CharactersRemaining>
                    {100 - additionalDetails.length} characters remaining
                  </CharactersRemaining>
                  
                  <ButtonContainer>
                    <CancelButton onClick={() => setActiveView('input')} disabled={isLoading}>
                      Back
                    </CancelButton>
                    <ConfirmButton onClick={confirmWish} disabled={isLoading || !isValidZipCode}>
                      Confirm Wish
                    </ConfirmButton>
                  </ButtonContainer>
                </ConfirmationContainer>
              )}
              
              {!wishLimitReached && activeView === 'input' && (
                <SubmitSection>
                  {activeStep === 1 && (
                    <InputContainer>
                      <ChatInput 
                        ref={inputRef}
                        type="text" 
                        placeholder="Enter product you want (e.g., 'Air Fryer')" 
                        value={productName} 
                        onChange={(e) => setProductName(e.target.value.slice(0, 25))}
                        maxLength={25}
                        onKeyPress={(e) => e.key === 'Enter' && handleProductSubmit()}
                      />
                      <SubmitButton onClick={handleProductSubmit} disabled={isLoading}>
                        {isLoading ? 'Processing...' : 'Send'}
                      </SubmitButton>
                    </InputContainer>
                  )}
                  
                  {productNameError && <ErrorText>{productNameError}</ErrorText>}
                  
                  {activeStep === 2 && (
                    <StepContainer>
                      <InputContainer>
                        <ChatInput 
                          ref={inputRef}
                          type="text" 
                          placeholder="Enter brand (e.g., 'Samsung')" 
                          value={brandPreference} 
                          onChange={(e) => setBrandPreference(e.target.value.slice(0, 25))}
                          maxLength={25}
                          onKeyPress={(e) => e.key === 'Enter' && handleBrandSubmit()}
                        />
                        <SubmitButton onClick={handleBrandSubmit} disabled={isLoading}>
                          {isLoading ? 'Processing...' : 'Send'}
                        </SubmitButton>
                      </InputContainer>
                      <SkipButton onClick={handleSkipBrand} disabled={isLoading}>
                        Skip (Any Brand)
                      </SkipButton>
                    </StepContainer>
                  )}
                </SubmitSection>
              )}
            </>
          )}
        </ModalBody>
      </ModalContent>
      
      {isLoading && (
        <LoadingOverlay>
          <LoadingSpinner />
        </LoadingOverlay>
      )}
    </ModalOverlay>
  );
};

// Styled Components
const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background-color: ${Color.colorLightBeige};
  border-radius: 8px;
  width: 90%;
  max-width: 600px;
  max-height: 90vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  background-color: ${Color.colorLightBeige};
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom: 1px solid ${Color.colorMixGrey};
`;

const ModalTitle = styled.h2`
  margin: 0;
  color: ${Color.colorDarkGrey};
  text-align: center;
  margin: auto;
`;

const MessageWrapper = styled.div`
  display: flex;
  justify-content: ${props => props.type === 'user' ? 'flex-end' : 'flex-start'};
  margin-bottom: 12px;
`;

const MessageContainer = styled.div`
  max-width: 80%;
  padding: 10px 14px;
  border-radius: 12px;
  background-color: ${props => props.type === 'bot' ? Color.colorLightBeige : Color.colorPeacockBlue};
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
`;

const MessageText = styled.p`
  font-size: 16px;
  color: ${props => props.type === 'bot' ? Color.colorRoseGoldText : Color.colorLightBeige};
  margin: 0;
  line-height: 1.4;
`;

const SubmitSection = styled.div`
  background-color: ${Color.colorLightBeige};
  padding: 16px 0 0;
`;

const StepContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const InputContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const ChatInput = styled.input`
  flex: 1;
  border: 1px solid ${Color.colorMixGrey};
  border-radius: 8px;
  padding: 12px 16px;
  font-size: 16px;
  background-color: ${Color.colorWhite};
  
  &:focus {
    outline: none;
    border-color: ${Color.colorRoseGoldText};
    box-shadow: 0 0 0 2px rgba(173, 123, 123, 0.1);
  }
`;

const Button = styled.button`
  background-color: ${Color.colorPeacockBlue};
  border: none;
  border-radius: 8px;
  padding: 12px 20px;
  color: ${Color.colorWhite};
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.2s;
  
  &:hover {
    background-color: ${Color.colorPeacockBlueDark || '#1E5F74'};
  }
  
  &:disabled {
    background-color: ${Color.colorMixGrey};
    cursor: not-allowed;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 10px;
`;

const IconButton = styled.button`
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
  color: ${Color.colorRoseGoldText};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  transition: background-color 0.2s;
  
  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
`;

const ModalBody = styled.div`
  flex: 1;
  padding: 16px;
  overflow-y: auto;
`;

const NoteContainer = styled.div`
  background-color: ${Color.colorLightBeige};
  padding: 8px 0;
  margin-bottom: 16px;
  border-radius: 8px;
`;

const NoteText = styled.p`
  font-size: 14px;
  color: ${Color.colorDarkGrey};
  margin: 0;
  text-align: center;
`;

const ChatContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${Color.colorFillBeige};
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 16px;
  max-height: 400px;
`;

const ChatHistory = styled.div`
  padding: 16px;
  overflow-y: auto;
  min-height: 200px;
  max-height: 400px;
`;
  
const EmptyChatMessage = styled.p`
  color: ${Color.colorDarkGrey};
  text-align: center;
  margin: auto;
`;

const SubmitButton = styled(Button)`
  min-width: 80px;
`;

const SkipButton = styled(Button)`
  background-color: transparent;
  color: ${Color.colorPeacockBlue};
  border: 1px solid ${Color.colorPeacockBlue};
  
  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
  
  &:disabled {
    border-color: ${Color.colorMixGrey};
    color: ${Color.colorMixGrey};
  }
`;

const ConfirmationContainer = styled.div`
  background-color: ${Color.colorWhite};
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 16px;
  border: 1px solid ${Color.colorMixGrey};
`;

const ConfirmationHeadingText = styled.h3`
  font-size: 18px;
  font-weight: 600;
  color: ${Color.colorPeacockBlue};
  margin-bottom: 16px;
  border-bottom: 1px solid ${Color.colorMixGrey};
  padding-bottom: 8px;
`;

const ConfirmationRow = styled.div`
  display: flex;
  margin-bottom: 12px;
`;

const ConfirmationSubHeadingText = styled.p`
  font-size: 15px;
  color: ${Color.colorDarkGrey};
  margin: 0;
  width: 120px;
  font-weight: 500;
`;

const ConfirmationText = styled.p`
  font-size: 15px;
  color: ${Color.colorDarkGrey};
  margin: 0;
  flex: 1;
`;

const QuantityContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
`;

const QuantityInput = styled.input`
  border: 1px solid ${Color.colorMixGrey};
  border-radius: 8px;
  padding: 8px 12px;
  margin-left: 10px;
  background-color: ${Color.colorLightestBeige};
  width: 60px;
  text-align: center;
`;

const AdditionalDetailsInput = styled.textarea`
  width: 100%;
  height: 80px;
  border: 1px solid ${Color.colorMixGrey};
  border-radius: 8px;
  padding: 12px;
  margin-top: 16px;
  background-color: ${Color.colorWhite};
  resize: none;
  font-family: inherit;
  
  &:focus {
    outline: none;
    border-color: ${Color.colorRoseGoldText};
    box-shadow: 0 0 0 2px rgba(173, 123, 123, 0.1);
  }
`;

const CharactersRemaining = styled.div`
  font-size: 12px;
  color: ${Color.colorDarkGrey};
  text-align: right;
  margin-top: 4px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

const CancelButton = styled(Button)`
  background-color: ${Color.colorMixGrey};
  
  &:hover {
    background-color: #999999;
  }
`;

const ConfirmButton = styled(Button)`
  background-color: ${Color.colorRoseGoldText};
  
  &:hover {
    background-color: ${Color.colorRoseGoldDark || '#8D5D5D'};
  }
`;

const DealLink = styled.button`
  background-color: ${Color.colorPeacockBlue};
  color: ${Color.colorWhite};
  border: none;
  border-radius: 6px;
  padding: 8px 12px;
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
  white-space: nowrap;
  
  &:hover {
    background-color: ${Color.colorPeacockBlueDark || '#1D7A8C'};
  }
`;

const ErrorText = styled.p`
  color: ${Color.colorRed};
  font-size: 14px;
  margin: 4px 0 8px;
`;

const LoadingOverlay = styled.div`
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1100;
`;

const LoadingSpinner = styled.div`
  animation: spin 1s linear infinite;
  border: 3px solid #f3f3f3;
  border-top: 3px solid ${Color.colorRoseGoldText};
  border-radius: 50%;
  width: 40px;
  height: 40px;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

// Wish History Components
const WishHistoryContainer = styled.div`
  background-color: ${Color.colorWhite};
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 16px;
  max-height: 450px;
  overflow-y: auto;
  border: 1px solid ${Color.colorMixGrey};
`;

const HistoryTitle = styled.h3`
  font-size: 18px;
  font-weight: 600;
  color: ${Color.colorPeacockBlue};
  margin-bottom: 16px;
  border-bottom: 1px solid ${Color.colorMixGrey};
  padding-bottom: 8px;
`;

const EmptyHistoryMessage = styled.p`
  color: ${Color.colorDarkGrey};
  text-align: center;
  margin: 40px 0;
  font-style: italic;
`;

const WishItem = styled.div`
  background-color: ${Color.colorLightestBeige};
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 12px;
  border-left: 4px solid ${Color.colorRoseGoldText};
`;

const WishItemTitle = styled.h4`
  font-size: 16px;
  font-weight: 600;
  color: ${Color.colorPeacockBlue};
  margin: 0 0 8px;
`;

const WishItemDetail = styled.p`
  font-size: 14px;
  color: ${Color.colorDarkGrey};
  margin: 4px 0;
`;

const WishItemDate = styled.p`
  font-size: 12px;
  color: ${Color.colorMixGrey};
  margin: 8px 0;
  font-style: italic;
`;

const ViewDealsButton = styled.button`
  background-color: ${Color.colorPurple};
  border: none;
  border-radius: 6px;
  padding: 8px 12px;
  color: ${Color.colorWhite};
  font-size: 14px;
  cursor: pointer;
  margin-top: 8px;
  transition: background-color 0.2s;
  
  &:hover {
    background-color: ${Color.colorPurpleDark || '#6A2C70'};
  }
`;
const MatchingDealsContainer = styled.div`
  background-color: ${Color.colorLightestBeige || '#f8f5f0'};
  border-radius: 8px;
  padding: 12px;
  margin-top: 10px;
  border: 1px solid ${Color.colorMixGrey || '#e0e0e0'};
`;

const MatchingDealsTitle = styled.p`
  font-size: 15px;
  font-weight: 600;
  color: ${Color.colorPeacockBlue || '#2E8B9A'};
  margin-bottom: 10px;
`;

const MatchingDealsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const MatchingDealItem = styled.div`
  background-color: ${Color.colorWhite || '#ffffff'};
  border-radius: 8px;
  padding: 12px;
  border: 1px solid ${Color.colorMixGrey || '#e0e0e0'};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const MatchingDealInfo = styled.div`
  flex: 1;
`;

const MatchingDealName = styled.p`
  font-size: 14px;
  font-weight: 600;
  color: ${Color.colorDarkGrey || '#555555'};
  margin: 0 0 5px 0;
`;

const MatchingDealCategory = styled.p`
  font-size: 12px;
  color: ${Color.colorDarkGrey || '#555555'};
  margin: 0 0 5px 0;
`;

const MatchingDealPrice = styled.p`
  font-size: 13px;
  color: ${Color.colorDarkGrey || '#555555'};
  margin: 0;
`;

const MatchingDealSavings = styled.span`
  color: #4CAF50;
  font-weight: 500;
`;

const ViewDealButton = styled.button`
  background-color: ${Color.colorPeacockBlue || '#2E8B9A'};
  border: none;
  border-radius: 6px;
  padding: 8px 12px;
  color: ${Color.colorWhite || '#ffffff'};
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
  white-space: nowrap;
  transition: background-color 0.2s;
  
  &:hover {
    background-color: ${Color.colorPeacockBlueDark || '#1D7A8C'};
  }
`;
// Combo card styling
const ComboCard = styled.div`
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
`;

const ComboHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 12px;
`;

const ComboTitle = styled.h3`
  font-size: 18px;
  margin: 0;
  flex: 1;
`;

const ComboPricing = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const OriginalPrice = styled.span`
  text-decoration: line-through;
  color: #999;
  font-size: 14px;
`;

const DiscountedPrice = styled.span`
  font-weight: bold;
  color: #e53935;
  font-size: 18px;
`;

const DiscountPercentage = styled.span`
  background-color: #e53935;
  color: white;
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 12px;
  margin-top: 4px;
`;

const ComboDescription = styled.p`
  color: #666;
  margin-bottom: 16px;
`;

const ComboItems = styled.div`
  margin-bottom: 16px;
`;

const ComboItemsHeader = styled.h4`
  margin-top: 0;
  margin-bottom: 8px;
  font-size: 16px;
`;

const ComboItemsList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const ComboItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

const ItemThumbnail = styled.img`
  width: 48px;
  height: 48px;
  object-fit: cover;
  border-radius: 4px;
  margin-right: 12px;
`;

const ComboActions = styled.div`
  display: flex;
  gap: 12px;
`;

const ViewDetailsButton = styled.button`
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  background-color: transparent;
  border: 1px solid #0066cc;
  color: #0066cc;
`;

const AddToWishlistButton = styled.button`
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  background-color: #0066cc;
  border: none;
  color: white;
`;
 
export default AddWishModal;