import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom"; 
import { useAuth } from '../../Common/InitialGears/AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faChartLine, faPlusCircle, faTachometerAlt, faSearch, faCheck, faPlus, faArrowLeft, faBars, faTimes, faHome, faUser, faCog, faSignOutAlt, faEnvelope, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { Color } from "../../../GlobalStyles";
import * as styles1 from '../../../GlobalStyles'; 
import CategoryItem from "../Wishes/CategoryItem";
import { TopMenuBar } from './TopMenuBar';
import newImage from '../../../assets/images/new-employee.png';
import cargoImage from '../../../assets/images/cargo.png';
import bonusImage from '../../../assets/images/bonus.png'; 
import shootingStarImage from '../../../assets/images/shooting-star.png';
import meetingImage from '../../../assets/images/meeting.png'; 
import completedImage from '../../../assets/images/idealistic.png'; 
import newWishAIRequestImage from '../../../assets/images/think-out-of-the-box.png';
import trendingImage from '../../../assets/images/trending.png';
import RightDashboardSection from "./DashBoardRightSection"; 
import {  postApi, buildUrl } from "../../Common/BackendCalls/apiUtils";
import { 
  useWishListCache, 
  useCategoriesCache, 
  useGroupWishesCache,
  useWishedCategoriesCache,
  useWishTrendsCache 
} from '../GroupWishes/GroupWishCache';
import { useGroupWishCombosCache } from '../GroupWishes/GroupWishCache';

library.add(faStar, faChartLine, faPlusCircle, faTachometerAlt, faSearch, faCheck, faPlus, faArrowLeft, faBars, faTimes, faHome, faUser, faCog, faSignOutAlt, faEnvelope, faUserPlus);

const WishesDashboardPage = () => {
  const navigate = useNavigate();
  const { queryLoginParam, accountType,logOutRequested,isBacksAppStaff, firstName } = useAuth(); 
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [categories, setCategories] = useState([]);
  const [wishlistData, setWishlistData] = useState([]);
  const [trendsData, setTrendsData] = useState([]);
  const [existingWishes, setExistingWishes] = useState([]);
  const [categoriesList, setCategoriesList] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [showStarFor, setShowStarFor] = useState(null); 
  const [groupWishes, setGroupWishes] = useState({
    userGroupWishes: [],
    connectionGroupWishes: [],
    latestGroupWishes: []
  });
  const [isGroupWishesLoading, setIsGroupWishesLoading] = useState(true);
  const [combos, setCombos] = useState({
    userRelevantCombos: [],
    connectionCombos: [],
    recommendedCombos: []
  });
  const [isCombosLoading, setIsCombosLoading] = useState(true);
  const { loadGroupWishCombos, invalidateCache: invalidateCombos } = useGroupWishCombosCache(queryLoginParam);
 
  const { loadWishList } = useWishListCache(queryLoginParam);
  const { loadCategories } = useCategoriesCache(queryLoginParam);
  const { loadWishTrends } = useWishTrendsCache(queryLoginParam);
  const { loadGroupWishes } = useGroupWishesCache(queryLoginParam);
  const { loadWishedCategories } = useWishedCategoriesCache(queryLoginParam);
  const { invalidateCache: invalidateGroupWishes } = useGroupWishesCache(queryLoginParam);
  const { invalidateCache: invalidateWishList } = useWishListCache(queryLoginParam);
  const { invalidateCache: invalidateWishedCategories } = useWishedCategoriesCache(queryLoginParam);
 
  const addtoWishList = async (category) => {
    setIsGroupWishesLoading(true);
    try { 
      const wishURL = buildUrl('addToMyWish', queryLoginParam);
      const requestBody = {
        category: category,
        manufacturer_name: '',
        seller_name: '',
        brand_name: '',
        product_name: '',
        wait_period: '',
        personal_use_or_professional: 'personal',
        additional_comments: '',
      };
      await postApi(wishURL, requestBody);            
      invalidateGroupWishes();
      invalidateWishList();
      invalidateWishedCategories();
      
      await loadGroupWishes();
    } catch (error) { 
      setErrorMessage('Failed to add wish. Please try again.');
    } finally {
      setIsGroupWishesLoading(false);
    }
  };
 

  const handleAddWish = async (title) => {
    setShowStarFor(title);
    try {
      await addtoWishList(title);
      setExistingWishes(prev => [...prev, title]);
    } catch (error) { 
      setErrorMessage('Failed to add wish. Please try again.');
    }
    setTimeout(() => setShowStarFor(null), 10000);
  };

  const handleSearch = (query) => {
    setSearchQuery(query);
    const filteredData = categoriesList.filter(category =>
      category.name.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredCategories(filteredData);
  };

  const handleRefreshData = useCallback(async () => {
    try {
      setIsGroupWishesLoading(true);
      setIsCombosLoading(true);
      const [groupWishesData] = await Promise.all([
        loadGroupWishes() 
      ]);
      const [ combosData] = await Promise.all([ 
        loadGroupWishCombos()
      ]);       
      setGroupWishes(groupWishesData); 
      setCombos(combosData);
    } catch (error) { 
      setErrorMessage('Failed to refresh group wishes. Please try again.');
    } finally {
      setIsGroupWishesLoading(false);
      setIsCombosLoading(false);
    }
  }, [loadGroupWishes, loadGroupWishCombos]);
 
  useEffect(() => {
    const fetchData = async () => {
      if (!logOutRequested) {
        try {
          setIsLoading(true);
          setIsCombosLoading(true);
           
          const categoriesData = await loadCategories();
          if (Array.isArray(categoriesData)) {
            const filteredData = categoriesData.filter(category => {
              if (accountType === 'PERSONAL') {
                return category.target_user_type === 'CONSUMER' || category.target_user_type === 'BOTH';
              } else if (accountType === 'BUSINESS') {
                return category.target_user_type === 'BUSINESS' || category.target_user_type === 'BOTH';
              }
              return false;
            });
            setCategories(filteredData);
            setFilteredCategories(filteredData);
            setCategoriesList(filteredData);
          }

          const [
            groupWishesData,
            combosData,
            wishListData,
            wishTrendsData,
            wishedCategoriesData
          ] = await Promise.all([
            loadGroupWishes(),
            loadGroupWishCombos(),
            loadWishList(),
            loadWishTrends(),
            loadWishedCategories()
          ]);

          setGroupWishes(groupWishesData); 
          setCombos(combosData); 
          
          if (wishListData?.wish_lists) {
            setWishlistData(wishListData.wish_lists);
            setExistingWishes(wishListData.categories || []);
          }

          if (wishTrendsData) {
            setTrendsData(wishTrendsData.map(item => ({ 
              name: item.name, 
              count: item.count 
            })));
          }

        } catch (error) {          
          setErrorMessage("An error occurred while loading data. Please try again.");
        } finally {
          setIsLoading(false);
          setIsGroupWishesLoading(false);
          setIsCombosLoading(false);
        }
      }
    };

    fetchData();
  }, [
    logOutRequested,
    loadGroupWishes,
    loadGroupWishCombos,
    loadWishList,
    loadWishTrends,
    loadCategories,
    loadWishedCategories,
    accountType
  ]);
  return (
    <> 
    <div style={styles1.mainContainer}>
    <TopMenuBar showBackButton={false} showHomeButton={false} showMenuButton={true}  showUserName={true} userName={firstName} showNotifications={true} showSearch={false} showAddress={false} /> 
      <div style={styles.container}>
        <div style={styles.leftSection}>
          <h2 style={styles.leftSectionTitle}>Wish Preferences</h2>
          {isLoading ? (
            <div style={styles.loadingContainer}>
              <div style={styles.spinner}></div>
            </div>
          ) : (
            <>
              <div style={styles.searchBox}>
                <input type="text" placeholder="Search ..." value={searchQuery}  onChange={(e) => handleSearch(e.target.value)} style={styles.searchInput}/>
                <button style={styles.searchButton}>
                  <FontAwesomeIcon icon="search" color={Color.colorBaseBeige}/>
                </button>
              </div>
              <div style={styles.categoriesContainer}>
                {filteredCategories.map((item, index) => (
                  <CategoryItem key={index} item={item} onAddWish={handleAddWish} existingWishes={existingWishes} onWishAdded={handleAddWish} />
                ))}
                <div style={styles.bottomPadding} />
              </div>
            </>
          )}
        </div>
        <div style={styles.rightSection}>
        <RightDashboardSection
          groupWishes={groupWishes}
          combos={combos} 
          onRefresh={handleRefreshData}
          isBacksAppStaff={isBacksAppStaff}
          queryLoginParam={queryLoginParam}
          navigate={navigate}
          isGroupWishesLoading={isGroupWishesLoading}
          isCombosLoading={isCombosLoading} 
          imageUrls={{
            newImage,
            cargoImage,
            bonusImage,
            meetingImage,
            completedImage,
            shootingStarImage,
            trendingImage,
            newWishAIRequestImage
          }}
        />
 
        </div>
      </div>
    </div>
    </>
  );
};
 
  const styles = {
     mainContainer: {
      display: 'flex',
      flexDirection: 'column',
      height: '200vh',
    },
    container: {
      display: 'flex', 
      backgroundColor: Color.colorWhite,
    },  
    leftSection: {
      width: '20%', 
      height: 'calc(100vh - 20px)', 
      padding: '30px 20px',
      background: Color.colorLightBeigeStrong, 
      borderRadius: '10px',
      margin: '10px', 
      boxShadow: '2px 0 10px rgba(0,0,0,0.1)',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      overflowY: 'hidden',
      position: 'sticky',
      top: '10px',
      minWidth: '250px',
    },
    leftSectionTitle: {
      fontSize: '24px',
      fontWeight: 'bold',
      color: Color.colorRoseGoldText,
      marginBottom: '30px',
      textAlign: 'center',
    },
    rightSection: {
      width: '80%',
      padding: '20px',
      backgroundColor: styles1.Color.colorLightBeigeStrong,
      borderRadius: '10px',
      display: 'flex',
      flexDirection: 'column',
      gap: '24px'  
    },
    
    buttonContainerStyle: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
      gap: '15px',
      padding: '20px',
      backgroundColor: styles1.Color.colorWhite,
      borderRadius: '8px',
      marginBottom: '24px'
    },
    searchBox: {
      display: 'flex',
      marginBottom: '20px',
      borderRadius: '8px',
      width: '100%',
      maxWidth: '100%', 
      position: 'relative',  
    },
    searchInput: {
      flex: 1,
      padding: '10px',
      fontSize: '16px',
      border: '1px solid #ddd',
      borderRadius: '8px 0 0 8px',
      width: '100%',  
      minWidth: 0,
    },
 
    searchButton: {
      padding: '3% 8%',
      backgroundColor: Color.colorCoolBeige,
      color: Color.colorBaseBeige,
      border: 'none',
      borderRadius: '0 8px 8px 0',
      cursor: 'pointer',
    },
    categoriesContainer: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fill, minmax(150px, 1fr))',
      gap: '20px',
      width: '100%',
      overflowY: 'auto', // This enables scrolling
      maxHeight: 'calc(100vh - 200px)', // Adjust based on your layout
      paddingRight: '10px', 
    },
    categoryItem: {
      backgroundColor: Color.colorWhite,
      borderRadius: '8px',
      padding: '10px',
      textAlign: 'center',
      boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-between',
      transition: 'transform 0.2s',
      cursor: 'pointer',
      height: '175px',
      ':hover': {
        transform: 'translateY(-5px)',
        boxShadow: '0 6px 12px rgba(0,0,0,0.15)',
      },
    },
    categoryImage: {
      width: '100px',
      height: '100px',
      objectFit: 'cover',
      borderRadius: '10%',
      marginBottom: '1px',
    }, 
    checkIcon: {
      color: Color.colorBaseBeige,
      fontSize: '20px',
    },
    starIcon: {
      color: 'gold',
      fontSize: '20px',
    },
    addWishButton: {
      backgroundColor: Color.colorBaseBeige,
      color: 'white',
      border: 'none',
      borderRadius: '50%',
      width: '30px',
      height: '30px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
    },
    loadingContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
    },
    spinner: {
      border: `4px solid ${Color.colorRoseGoldText}`,
      borderTop: `4px solid ${Color.colorBaseBeige}`,
      borderRadius: '50%',
      width: '40px',
      height: '40px',
      animation: 'spin 1s linear infinite',
    },
  };
  
  export default WishesDashboardPage;