import React, { useState, useEffect } from 'react'; 
import { getApi, postApi, buildUrl } from '../../Common/BackendCalls/apiUtils';
import * as style from "../../../GlobalStyles";

const ProductSpecifications = ({ groupId, queryLoginParam, onSave, isEditMode, existingSpecifications }) => {
  const [specs, setSpecs] = useState({
    product_length: existingSpecifications?.product_length || '',
    product_width: existingSpecifications?.product_width || '',
    product_height: existingSpecifications?.product_height || '',
    product_weight: existingSpecifications?.product_weight || '',
    package_length: existingSpecifications?.package_length || '',
    package_width: existingSpecifications?.package_width || '',
    package_height: existingSpecifications?.package_height || '',
    package_weight: existingSpecifications?.package_weight || '',
    dimension_unit: existingSpecifications?.dimension_unit || 'IN',
    weight_unit: existingSpecifications?.weight_unit || 'LB',
    is_fragile: existingSpecifications?.is_fragile || false,
    has_batteries: existingSpecifications?.has_batteries || false,
    is_hazardous: existingSpecifications?.is_hazardous || false,
    storage_requirements: existingSpecifications?.storage_requirements || ''
  });

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    if (groupId && !existingSpecifications) {
      fetchProductSpecs();
    }
  }, [groupId,queryLoginParam, existingSpecifications]);

  const fetchProductSpecs = async () => {
    setIsLoading(true);
    try {
      const url = buildUrl(`getProductSpecs/${groupId}/`, queryLoginParam);
      const response = await getApi(url);
      if (response.ok && response.data) {
        setSpecs(response.data);
      }
    } catch (error) {
      setError('Failed to fetch product specifications');
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (name, value) => {
    setSpecs(prevSpecs => ({
      ...prevSpecs,
      [name]: value
    }));
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    setError(null);
    setSuccessMessage('');

    try {
      const url = buildUrl(`updateProductSpecs/${groupId}/`, queryLoginParam);
      const response = await postApi(url, specs);
      
      if (response.ok) {
        setSuccessMessage('Product specifications updated successfully');
        if (onSave) onSave(response.data);
      } else {
        setError('Failed to update product specifications');
      }
    } catch (error) {
      setError('An error occurred while updating specifications');
    } finally {
      setIsLoading(false);
    }
  };

  const dimensionUnitOptions = [
    { value: 'IN', label: 'Inches' },
    { value: 'CM', label: 'Centimeters' },
    { value: 'M', label: 'Meters' }
  ];

  const weightUnitOptions = [
    { value: 'LB', label: 'Pounds' },
    { value: 'KG', label: 'Kilograms' },
    { value: 'G', label: 'Grams' }
  ];

  if (isLoading) {
    return <div className="loading">Loading...</div>;
  }

  return (
    <div className="specs-container">
      <h3 className="specs-title">Product Specifications</h3>
      
      {(error || successMessage) && (
        <div className={`message ${error ? 'error' : 'success'}`}>
          {error || successMessage}
        </div>
      )}

      <div className="specs-content">
        {/* Units Selection */}
        <div className="units-section">
          <div className="select-group">
            <label>Dimension Unit</label>
            <select
              value={specs.dimension_unit}
              onChange={(e) => handleInputChange('dimension_unit', e.target.value)}
            >
              {dimensionUnitOptions.map(option => (
                <option key={option.value} value={option.value}>{option.label}</option>
              ))}
            </select>
          </div>
          <div className="select-group">
            <label>Weight Unit</label>
            <select
              value={specs.weight_unit}
              onChange={(e) => handleInputChange('weight_unit', e.target.value)}
            >
              {weightUnitOptions.map(option => (
                <option key={option.value} value={option.value}>{option.label}</option>
              ))}
            </select>
          </div>
        </div>
        {/* Product Dimensions */}
        <div className="dimension-section">
          <h4 className="section-title">Product Dimensions (without packaging)</h4>
          <div className="dimensions-grid">
            <div className="input-group">
              <label>Length</label>
              <input
                type="number"
                value={specs.product_length}
                onChange={(e) => handleInputChange('product_length', e.target.value)}
                step="0.01"
              />
            </div>
            <div className="input-group">
              <label>Width</label>
              <input
                type="number"
                value={specs.product_width}
                onChange={(e) => handleInputChange('product_width', e.target.value)}
                step="0.01"
              />
            </div>
            <div className="input-group">
              <label>Height</label>
              <input
                type="number"
                value={specs.product_height}
                onChange={(e) => handleInputChange('product_height', e.target.value)}
                step="0.01"
              />
            </div>
            <div className="input-group">
              <label>Weight</label>
              <input
                type="number"
                value={specs.product_weight}
                onChange={(e) => handleInputChange('product_weight', e.target.value)}
                step="0.01"
              />
            </div>
          </div>
        </div>

        {/* Package Dimensions */}
        <div className="dimension-section">
          <h4 className="section-title">Package Dimensions (with packaging)</h4>
          <div className="dimensions-grid">
            <div className="input-group">
              <label>Length</label>
              <input
                type="number"
                value={specs.package_length}
                onChange={(e) => handleInputChange('package_length', e.target.value)}
                step="0.01"
              />
            </div>
            <div className="input-group">
              <label>Width</label>
              <input
                type="number"
                value={specs.package_width}
                onChange={(e) => handleInputChange('package_width', e.target.value)}
                step="0.01"
              />
            </div>
            <div className="input-group">
              <label>Height</label>
              <input
                type="number"
                value={specs.package_height}
                onChange={(e) => handleInputChange('package_height', e.target.value)}
                step="0.01"
              />
            </div>
            <div className="input-group">
              <label>Weight</label>
              <input
                type="number"
                value={specs.package_weight}
                onChange={(e) => handleInputChange('package_weight', e.target.value)}
                step="0.01"
              />
            </div>
          </div>
        </div>

        

        {/* Additional Specifications */}
        <div className="additional-specs">
          <h4 className="section-title">Additional Specifications</h4>
          <div className="checkbox-group">
            <label>
              <input
                type="checkbox"
                checked={specs.is_fragile}
                onChange={(e) => handleInputChange('is_fragile', e.target.checked)}
              />
              <span>Fragile</span>
            </label>
            <label>
              <input
                type="checkbox"
                checked={specs.has_batteries}
                onChange={(e) => handleInputChange('has_batteries', e.target.checked)}
              />
              <span>Contains Batteries</span>
            </label>
            <label>
              <input
                type="checkbox"
                checked={specs.is_hazardous}
                onChange={(e) => handleInputChange('is_hazardous', e.target.checked)}
              />
              <span>Hazardous Materials</span>
            </label>
          </div>
          <div className="textarea-group">
            <label>Storage Requirements</label>
            <textarea
              value={specs.storage_requirements}
              onChange={(e) => handleInputChange('storage_requirements', e.target.value)}
              rows="3"
              placeholder="Enter any special storage or handling requirements"
            />
          </div>
        </div>

        <button onClick={handleSubmit} disabled={isLoading} className="submit-button" style={style.RoseGoldButton}>
          {isLoading ? 'Saving...' : 'Save Specifications'}
        </button>
      </div>
    </div>
  );
};

const styles = `
.specs-container {
  background-color: ${style.Color.colorWhite};
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 24px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.specs-title {
  font-size: 24px;
  color: ${style.Color.colorRoyalBlueText};
  margin-bottom: 20px;
  font-weight: bold;
}

.specs-content {
  color: ${style.Color.colorDarkGrey};
  background-color: ${style.Color.colorLightestBeige};
  padding: 20px;
  border-radius: 8px;
}

.dimension-section {
  background-color: ${style.Color.colorWhite};
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
}

.section-title {
  color: ${style.Color.colorPurple};
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 15px;
}

.dimensions-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
}

.input-group, .select-group {
  margin-bottom: 15px;
}

.input-group label, .select-group label {
  display: block;
  margin-bottom: 5px;
  color: ${style.Color.colorDarkGrey};
  font-size: 14px;
}

.input-group input, .select-group select {
  width: 100%;
  padding: 8px;
  border: 1px solid ${style.Color.colorLightBeige};
  border-radius: 8px;
  color: ${style.Color.colorDarkGrey};
}

.units-section {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  margin-bottom: 20px;
}

.additional-specs {
  background-color: ${style.Color.colorWhite};
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
}

.checkbox-group {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 15px;
}

.checkbox-group label {
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${style.Color.colorDarkGrey};
}

.textarea-group textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid ${style.Color.colorLightBeige};
  border-radius: 8px;
  resize: vertical;
}

.submit-button {
  background-color: ${style.Color.colorPurple};
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  width: 200px;
  margin-top: 20px;
}

.submit-button:hover {
  opacity: 0.9;
}

.submit-button:disabled {
  background-color: ${style.Color.colorLightGrey};
  cursor: not-allowed;
}

.message {
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 20px;
}

.error {
  background-color: #fee2e2;
  color: ${style.Color.colorRed};
}

.success {
  background-color: #dcfce7;
  color: #166534;
}

.loading {
  text-align: center;
  padding: 20px;
  color: ${style.Color.colorDarkGrey};
}
`;

// Add styles to the document
const styleSheet = document.createElement("style");
styleSheet.type = "text/css";
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);

export default ProductSpecifications;