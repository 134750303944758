import React, { useState, useEffect, useRef } from 'react';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { loadGoogleMapsScript } from './googleMapsLoader';
import Config from '../../config';
import {Color, RoseGoldButton} from '../../GlobalStyles';

function AddressSection({ 
  address, 
  isPrimaryAddress, 
  handleAddressSave, 
  onCancel, 
  isNewAddress, 
  callFromProfiles = true 
}) {
    const [modalVisible, setModalVisible] = useState(false);
    const [localAddress, setLocalAddress] = useState(address);
    const [isLoading, setIsLoading] = useState(false);
    const [googlePlacesKey, setGooglePlacesKey] = useState(0);
    const googlePlacesRef = useRef(null);
    const [scriptLoaded, setScriptLoaded] = useState(false);

    useEffect(() => { 
        if (window.google && window.google.maps) {
            setScriptLoaded(true);
            return;
        }
        
        loadGoogleMapsScript()
            .then(() => {
                setScriptLoaded(true); 
            })
            .catch((error) => {
                console.error('Error loading Google Maps script:', error);
            });
    }, []);

    useEffect(() => {
        setLocalAddress(address);
    }, [address]);

    useEffect(() => {
        if (!callFromProfiles) {
            setModalVisible(true);
        }
    }, [callFromProfiles]);

    const handleGooglePlacesSelect = async (data) => { 
        if (data && data.value && data.value.place_id) {
            const { description, place_id } = data.value; 

            setIsLoading(true);
            try {
                const placeDetails = await getPlaceDetails(place_id);                 
                const newAddress = extractAddressComponents(placeDetails); 

                setModalVisible(false);
                setLocalAddress(newAddress);
                handleAddressSave(newAddress);
            } catch (error) {
                console.error("Error fetching place details:", error); 
            } finally {
                setIsLoading(false);
                setGooglePlacesKey(prevKey => prevKey + 1);
            }
        }
    };
    function getPlaceDetails(placeId) {
        return new Promise((resolve, reject) => {
            const service = new window.google.maps.places.PlacesService(document.createElement('div'));
            service.getDetails(
                { 
                    placeId: placeId,
                    fields: ['address_component']
                }, 
                (place, status) => {
                    if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                        resolve(place);
                    } else {
                        reject(new Error(`Place details request failed: ${status}`));
                    }
                }
            );
        });
    }

    function extractAddressComponents(place) {
        const addressComponents = place.address_components;
        const newAddress = {
            address1: '',
            address2: '',
            city: '',
            state: '',
            postal_code: '',
            country: '',
            primary_residence: isPrimaryAddress,
        };

        for (const component of addressComponents) {
            const types = component.types;
            if (types.includes('street_number')) {
                newAddress.address1 = component.long_name + ' ';
            } else if (types.includes('route')) {
                newAddress.address1 += component.long_name;
            } else if (types.includes('locality')) {
                newAddress.city = component.long_name;
            } else if (types.includes('administrative_area_level_1')) {
                newAddress.state = component.short_name;
            } else if (types.includes('postal_code')) {
                newAddress.postal_code = component.long_name;
            } else if (types.includes('country')) {
                newAddress.country = component.long_name;
            }
        }

        return newAddress;
    }

    function formatAddress(address) {
        if (!address) return "No address provided.";
        
        const parts = [
            address.address1,
            address.address2,
            address.city,
            address.state,
            address.postal_code,
            address.country
        ].filter(Boolean);

        return parts.join(", ");
    }
    
    return (
        <div style={styles.container}>
            {callFromProfiles && (
                <div style={styles.profilesContainer}>
                    {isLoading ? (
                        <div style={styles.loadingContainer}>
                            <div>Loading...</div>
                            <p style={styles.loadingText}>Updating address...</p>
                        </div>
                    ) : (
                        <div 
                            onClick={() => setModalVisible(true)}
                            style={styles.addressDisplay}
                        >
                            <p style={styles.addressText}>
                                {formatAddress(localAddress)}
                            </p>
                            {isPrimaryAddress && (
                                <span style={styles.primaryBadge}>Primary</span>
                            )}
                        </div>
                    )}
                </div>
            )}

            {modalVisible && (
                <div style={styles.modalOverlay}>
                    <div style={styles.modalContent}>
                        <div style={styles.modalHeader}>
                            <h2 style={styles.modalTitle}>
                                {isPrimaryAddress ? 'Update Primary Address' : 'Enter New Address'}
                            </h2>
                            <button 
                                onClick={() => setModalVisible(false)} 
                                style={{...RoseGoldButton, ...styles.closeButton}}
                                aria-label="Close"
                            >
                                ×
                            </button>
                        </div>

                        {scriptLoaded ? (
                            <GooglePlacesAutocomplete
                                apiKey={Config.googleMapsAPIKEY}
                                selectProps={{
                                    placeholder: 'Search for an address',
                                    onChange: handleGooglePlacesSelect,
                                }}
                                ref={googlePlacesRef}
                                autocompletionRequest={{
                                    types: ['address']
                                }}
                            />
                        ) : (
                            <div style={styles.loadingContainer}>
                                <p style={styles.loadingText}>Loading Google Maps...</p>
                            </div>
                        )}

                        <div style={styles.modalFooter}>
                            {onCancel && (
                                <button onClick={onCancel} style={styles.cancelButton}>
                                    Cancel
                                </button>
                            )}
                            <button onClick={() => setModalVisible(false)} style={{...RoseGoldButton, ...styles.closeModalButton}} >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

const styles = {
    container: {
        width: '100%'
    },
    profilesContainer: {
        padding: '16px'
    },
    loadingContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '16px'
    },
    loadingText: {
        color: '#718096',
        fontSize: '14px'
    },
    addressDisplay: {
        backgroundColor: Color.colorLightBeige,
        borderRadius: '8px',
        boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
        padding: '16px',
        cursor: 'pointer'
    },
    addressText: {
        color: Color.colorPeacockBlueDark,
    },
    primaryBadge: {
        color: '#48BB78',
        fontWeight: '600',
        marginTop: '8px',
        display: 'inline-block'
    },
    modalOverlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '16px',
        zIndex: 1000
    },
    modalContent: {
        backgroundColor: 'white',
        borderRadius: '8px',
        padding: '24px',
        width: '100%',
        maxWidth: '32rem'
    },
    modalHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '16px'
    },
    modalTitle: {
        fontSize: '20px',
        color: '#718096',
        fontWeight: '600'
    },
    closeButton: {
        color: Color.colorLightBeige,
        background: 'none',
        border: 'none',
        cursor: 'pointer',
        maxHeight: '3%',
        fontSize: '24px'
    },
    modalFooter: {
        marginTop: '16px',
        display: 'flex',
        justifyContent: 'flex-end',
        gap: '8px'
    },
    cancelButton: {
        padding: '8px 16px',
        color: '#718096',
        background: 'none',
        border: 'none',
        cursor: 'pointer'
    },
    closeModalButton: {
        padding: '8px 16px',
        backgroundColor: '#3182CE',
        color: 'white',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer'
    }
};

export default AddressSection;